import React, { PureComponent } from 'react';

export default class RpC extends PureComponent {

render() {
      return (

	  <div style={{ margin:"auto", maxWidth:"580px", width:"100%" }}>
		<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 41.7 69.3" style={{enableBackground:"new 0 0 41.7 69.3"}} >
		<g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,3.1C2.2,3,2.1,4.8,2.3,5.3c0.1,0.3,0.7,0.3,0.8,0C3.2,4.8,3.1,3.1,2.7,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,3.9C1.7,3.6,1.8,2.6,2.6,2.5s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,3.1C3.9,3,3.8,4.8,4,5.3c0.1,0.3,0.7,0.3,0.8,0C4.9,4.8,4.8,3.1,4.4,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,3.9C3.4,3.6,3.5,2.6,4.3,2.5s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,3.1C5.6,3,5.5,4.8,5.7,5.3c0.1,0.3,0.7,0.3,0.8,0C6.6,4.8,6.5,3.1,6,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,3.9C5.1,3.6,5.2,2.6,6,2.5s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,3.1C7.3,3,7.2,4.8,7.4,5.3c0.1,0.3,0.7,0.3,0.8,0C8.3,4.8,8.2,3.1,7.7,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,3.9C6.8,3.6,6.9,2.6,7.7,2.5s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,3.1C9,3,8.9,4.8,9,5.3c0.1,0.3,0.7,0.3,0.8,0C10,4.8,9.9,3.1,9.4,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,3.9C8.5,3.6,8.6,2.6,9.4,2.5c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,4.8,11.6,3.1,11.1,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,4.8,13.2,3.1,12.8,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,3.1C14.1,3,14,4.8,14.1,5.3c0.1,0.3,0.7,0.3,0.8,0C15,4.8,14.9,3.1,14.5,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,4.8,16.6,3.1,16.2,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,4.8,18.3,3.1,17.9,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,3.1C19.1,3,19,4.8,19.2,5.3c0.1,0.3,0.7,0.3,0.8,0C20.1,4.8,20,3.1,19.6,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,4.8,21.7,3.1,21.3,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,4.8,23.4,3.1,22.9,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,4.8,25.1,3.1,24.6,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,4.8,26.8,3.1,26.3,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,4.8,28.4,3.1,28,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,4.8,30.1,3.1,29.7,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,3.1C31,3,30.9,4.8,31,5.3c0.1,0.3,0.7,0.3,0.8,0C31.9,4.8,31.8,3.1,31.4,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,4.8,33.5,3.1,33.1,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,3.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,4.8,35.2,3.1,34.8,3.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,3.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,5.2C3.1,5.1,3,6.9,3.1,7.4c0.1,0.3,0.7,0.3,0.8,0C4.1,6.9,3.9,5.1,3.5,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,5.9C2.6,5.7,2.7,4.7,3.5,4.6s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,5.2C4.8,5.1,4.7,6.9,4.8,7.4c0.1,0.3,0.7,0.3,0.8,0C5.7,6.9,5.6,5.1,5.2,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,5.9C4.3,5.7,4.4,4.7,5.1,4.6c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,5.2C6.5,5.1,6.4,6.9,6.5,7.4c0.1,0.3,0.7,0.3,0.8,0C7.4,6.9,7.3,5.1,6.9,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,5.9C6,5.7,6.1,4.7,6.8,4.6c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,5.2C8.1,5.1,8.1,6.9,8.2,7.4c0.1,0.3,0.7,0.3,0.8,0C9.1,6.9,9,5.1,8.6,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,5.9C7.7,5.7,7.8,4.7,8.5,4.6c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,5.2C9.8,5.1,9.8,6.9,9.9,7.4c0.1,0.3,0.7,0.3,0.8,0C10.8,6.9,10.7,5.1,10.3,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,6.9,12.4,5.1,12,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,6.9,14.1,5.1,13.7,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,6.9,15.8,5.1,15.3,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,6.9,17.5,5.1,17,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,6.9,19.2,5.1,18.7,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,5.2C20,5.1,19.9,6.9,20,7.4c0.1,0.3,0.7,0.3,0.8,0C20.9,6.9,20.8,5.1,20.4,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,6.9,22.5,5.1,22.1,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,6.9,24.2,5.1,23.8,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,5.2C25,5.1,25,6.9,25.1,7.4c0.1,0.3,0.7,0.3,0.8,0C26,6.9,25.9,5.1,25.5,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,6.9,27.6,5.1,27.2,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,6.9,29.3,5.1,28.9,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,6.9,31,5.1,30.5,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,6.9,32.7,5.1,32.2,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,6.9,34.4,5.1,33.9,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,5.2c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,6.9,36,5.1,35.6,5.2z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,5.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,7.1C2.2,7,2.1,8.8,2.3,9.3c0.1,0.3,0.7,0.3,0.8,0C3.2,8.8,3.1,7,2.7,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,7.8C1.7,7.6,1.8,6.5,2.6,6.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,7.1C3.9,7,3.8,8.8,4,9.3c0.1,0.3,0.7,0.3,0.8,0C4.9,8.8,4.8,7,4.4,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,7.8C3.4,7.6,3.5,6.5,4.3,6.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,7.1C5.6,7,5.5,8.8,5.7,9.3c0.1,0.3,0.7,0.3,0.8,0C6.6,8.8,6.5,7,6,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,7.8C5.1,7.6,5.2,6.5,6,6.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,7.1C7.3,7,7.2,8.8,7.4,9.3c0.1,0.3,0.7,0.3,0.8,0C8.3,8.8,8.2,7,7.7,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,7.8C6.8,7.6,6.9,6.5,7.7,6.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,7.1C9,7,8.9,8.8,9,9.3c0.1,0.3,0.7,0.3,0.8,0C10,8.8,9.9,7,9.4,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,7.8C8.5,7.6,8.6,6.5,9.4,6.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,8.8,11.6,7,11.1,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,8.8,13.2,7,12.8,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,7.1C14.1,7,14,8.8,14.1,9.3c0.1,0.3,0.7,0.3,0.8,0C15,8.8,14.9,7,14.5,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,8.8,16.6,7,16.2,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,8.8,18.3,7,17.9,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,7.1C19.1,7,19,8.8,19.2,9.3c0.1,0.3,0.7,0.3,0.8,0C20.1,8.8,20,7,19.6,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,8.8,21.7,7,21.3,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,8.8,23.4,7,22.9,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,8.8,25.1,7,24.6,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,8.8,26.8,7,26.3,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,8.8,28.4,7,28,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,8.8,30.1,7,29.7,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,7.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,7.1C31,7,30.9,8.8,31,9.3c0.1,0.3,0.7,0.3,0.8,0C31.9,8.8,31.8,7,31.4,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,8.8,33.5,7,33.1,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,7.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,8.8,35.2,7,34.8,7.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,7.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,9.1C3.1,9,3,10.9,3.1,11.3c0.1,0.3,0.7,0.3,0.8,0C4.1,10.9,3.9,9.1,3.5,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,9.9C2.6,9.6,2.7,8.6,3.5,8.5s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,9.1C4.8,9,4.7,10.9,4.8,11.3c0.1,0.3,0.7,0.3,0.8,0C5.7,10.9,5.6,9.1,5.2,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,9.9C4.3,9.6,4.4,8.6,5.1,8.5c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,9.1C6.5,9,6.4,10.9,6.5,11.3c0.1,0.3,0.7,0.3,0.8,0C7.4,10.9,7.3,9.1,6.9,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,9.9C6,9.6,6.1,8.6,6.8,8.5c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,9.1C8.1,9,8.1,10.9,8.2,11.3c0.1,0.3,0.7,0.3,0.8,0C9.1,10.9,9,9.1,8.6,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,9.9C7.7,9.6,7.8,8.6,8.5,8.5c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,9.1C9.8,9,9.8,10.9,9.9,11.3c0.1,0.3,0.7,0.3,0.8,0C10.8,10.9,10.7,9.1,10.3,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,10.9,12.4,9.1,12,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,10.9,14.1,9.1,13.7,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,10.9,15.8,9.1,15.3,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,10.9,17.5,9.1,17,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,10.9,19.2,9.1,18.7,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,9.1C20,9,19.9,10.9,20,11.3c0.1,0.3,0.7,0.3,0.8,0C20.9,10.9,20.8,9.1,20.4,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,10.9,22.5,9.1,22.1,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,10.9,24.2,9.1,23.8,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,9.1C25,9,25,10.9,25.1,11.3c0.1,0.3,0.7,0.3,0.8,0C26,10.9,25.9,9.1,25.5,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,10.9,27.6,9.1,27.2,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,10.9,29.3,9.1,28.9,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,10.9,31,9.1,30.5,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,10.9,32.7,9.1,32.2,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,10.9,34.4,9.1,33.9,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,9.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,10.9,36,9.1,35.6,9.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,9.9c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,12.7,3.1,11,2.7,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,11C4,10.9,3.9,12.7,4,13.2c0.1,0.3,0.7,0.3,0.8,0C4.9,12.7,4.8,11,4.4,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,12.7,6.5,11,6,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,12.7,8.2,11,7.7,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,11C9,10.9,8.9,12.7,9,13.2c0.1,0.3,0.7,0.3,0.8,0C10,12.7,9.9,11,9.4,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,12.7,11.6,11,11.1,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,12.7,13.2,11,12.8,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,12.7,14.9,11,14.5,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,12.7,16.6,11,16.2,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,12.7,18.3,11,17.9,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,12.7,20,11,19.6,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,12.7,21.7,11,21.3,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,12.7,23.4,11,22.9,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,12.7,25.1,11,24.6,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,12.7,26.8,11,26.3,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,12.7,28.4,11,28,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,12.7,30.1,11,29.7,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,12.7,31.8,11,31.4,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,12.7,33.5,11,33.1,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,11c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,12.7,35.2,11,34.8,11z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,11.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,13.1C3.1,13,3,14.8,3.1,15.3c0.1,0.3,0.7,0.3,0.8,0C4.1,14.8,3.9,13,3.5,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,14.8,5.6,13,5.2,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,14.8,7.3,13,6.9,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,14.8,9,13,8.6,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,14.8,10.7,13,10.3,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,14.8,12.4,13,12,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,14.8,14.1,13,13.7,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,14.8,15.8,13,15.3,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,14.8,17.5,13,17,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,14.8,19.2,13,18.7,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,13.1C20,13,19.9,14.8,20,15.3c0.1,0.3,0.7,0.3,0.8,0C20.9,14.8,20.8,13,20.4,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,14.8,22.5,13,22.1,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,14.8,24.2,13,23.8,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,13.1C25,13,25,14.8,25.1,15.3c0.1,0.3,0.7,0.3,0.8,0C26,14.8,25.9,13,25.5,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,14.8,27.6,13,27.2,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,14.8,29.3,13,28.9,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,14.8,31,13,30.5,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,14.8,32.7,13,32.2,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,14.8,34.4,13,33.9,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,13.1c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,14.8,36,13,35.6,13.1z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,13.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,16.7,3.1,14.9,2.7,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,15C4,14.9,3.9,16.7,4,17.2c0.1,0.3,0.7,0.3,0.8,0C4.9,16.7,4.8,14.9,4.4,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,16.7,6.5,14.9,6,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,16.7,8.2,14.9,7.7,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,15C9,14.8,8.9,16.7,9,17.2c0.1,0.3,0.7,0.3,0.8,0C10,16.7,9.9,14.9,9.4,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,16.7,11.6,14.9,11.1,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,16.7,13.2,14.9,12.8,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,16.7,14.9,14.9,14.5,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,16.7,16.6,14.9,16.2,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,16.7,18.3,14.9,17.9,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,16.7,20,14.9,19.6,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,16.7,21.7,14.9,21.3,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,16.7,23.4,14.9,22.9,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,16.7,25.1,14.9,24.6,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,16.7,26.8,14.9,26.3,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,16.7,28.4,14.9,28,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,16.7,30.1,14.9,29.7,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,16.7,31.8,14.9,31.4,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,16.7,33.5,14.9,33.1,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,15c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,16.7,35.2,14.9,34.8,15z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,15.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,18.8,3.9,17,3.5,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,18.8,5.6,17,5.2,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,18.8,7.3,17,6.9,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,18.8,9,17,8.6,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,18.8,10.7,17,10.3,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,18.8,12.4,17,12,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,18.8,14.1,17,13.7,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,18.8,15.8,17,15.3,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,18.8,17.5,17,17,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,18.8,19.2,17,18.7,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,18.8,20.8,17,20.4,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,18.8,22.5,17,22.1,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,18.8,24.2,17,23.8,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,18.8,25.9,17,25.5,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,18.8,27.6,17,27.2,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,18.8,29.3,17,28.9,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,18.8,31,17,30.5,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,18.8,32.7,17,32.2,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,17.8c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,18.8,34.4,17,33.9,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,17c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,18.8,36,17,35.6,17z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,17.8c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,20.6,3.1,18.8,2.7,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,18.9C4,18.8,3.9,20.6,4,21.1c0.1,0.3,0.7,0.3,0.8,0C4.9,20.6,4.8,18.8,4.4,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,20.6,6.5,18.8,6,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,20.6,8.2,18.8,7.7,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,18.9C9,18.8,8.9,20.6,9,21.1c0.1,0.3,0.7,0.3,0.8,0C10,20.6,9.9,18.8,9.4,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,20.6,11.6,18.8,11.1,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,20.6,13.2,18.8,12.8,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,20.6,14.9,18.8,14.5,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,20.6,16.6,18.8,16.2,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,20.6,18.3,18.8,17.9,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,20.6,20,18.8,19.6,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,20.6,21.7,18.8,21.3,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,20.6,23.4,18.8,22.9,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,20.6,25.1,18.8,24.6,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,20.6,26.8,18.8,26.3,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,20.6,28.4,18.8,28,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,20.6,30.1,18.8,29.7,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,19.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,20.6,31.8,18.8,31.4,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,20.6,33.5,18.8,33.1,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,18.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,20.6,35.2,18.8,34.8,18.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,19.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,22.7,3.9,20.9,3.5,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,22.7,5.6,20.9,5.2,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,22.7,7.3,20.9,6.9,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,22.7,9,20.9,8.6,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,22.7,10.7,20.9,10.3,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,22.7,12.4,20.9,12,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,22.7,14.1,20.9,13.7,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,22.7,15.8,20.9,15.3,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,22.7,17.5,20.9,17,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,22.7,19.2,20.9,18.7,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,22.7,20.8,20.9,20.4,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,22.7,22.5,20.9,22.1,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,22.7,24.2,20.9,23.8,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,22.7,25.9,20.9,25.5,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,22.7,27.6,20.9,27.2,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,22.7,29.3,20.9,28.9,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,22.7,31,20.9,30.5,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,22.7,32.7,20.9,32.2,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,21.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,22.7,34.4,20.9,33.9,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,21c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,22.7,36,20.9,35.6,21z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,21.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,24.6,3.1,22.8,2.7,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,22.8C3.9,22.7,3.8,24.6,4,25c0.1,0.3,0.7,0.3,0.8,0C4.9,24.6,4.8,22.8,4.4,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,24.6,6.5,22.8,6,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,24.6,8.2,22.8,7.7,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,22.8C9,22.7,8.9,24.6,9,25c0.1,0.3,0.7,0.3,0.8,0C10,24.6,9.9,22.8,9.4,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,24.6,11.6,22.8,11.1,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,24.6,13.2,22.8,12.8,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,24.6,14.9,22.8,14.5,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,24.6,16.6,22.8,16.2,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,24.6,18.3,22.8,17.9,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,24.6,20,22.8,19.6,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,24.6,21.7,22.8,21.3,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,24.6,23.4,22.8,22.9,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,24.6,25.1,22.8,24.6,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,24.6,26.8,22.8,26.3,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,24.6,28.4,22.8,28,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,24.6,30.1,22.8,29.7,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,23.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,22.8C31,22.7,30.9,24.6,31,25c0.1,0.3,0.7,0.3,0.8,0C31.9,24.6,31.8,22.8,31.4,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,24.6,33.5,22.8,33.1,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,22.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,24.6,35.2,22.8,34.8,22.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,23.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,26.6,3.9,24.9,3.5,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,26.6,5.6,24.9,5.2,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,26.6,7.3,24.9,6.9,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,26.6,9,24.9,8.6,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,26.6,10.7,24.9,10.3,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,26.6,12.4,24.9,12,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,26.6,14.1,24.9,13.7,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,26.6,15.8,24.9,15.3,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,26.6,17.5,24.9,17,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,26.6,19.2,24.9,18.7,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,26.6,20.8,24.9,20.4,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,26.6,22.5,24.9,22.1,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,26.6,24.2,24.9,23.8,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,26.6,25.9,24.9,25.5,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,26.6,27.6,24.9,27.2,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,26.6,29.3,24.9,28.9,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,26.6,31,24.9,30.5,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,26.6,32.7,24.9,32.2,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,25.7c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,26.6,34.4,24.9,33.9,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,24.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,26.6,36,24.9,35.6,24.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,25.7c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,28.5,3.1,26.7,2.7,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,26.8C3.9,26.7,3.8,28.5,4,29c0.1,0.3,0.7,0.3,0.8,0C4.9,28.5,4.8,26.7,4.4,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,28.5,6.5,26.7,6,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,28.5,8.2,26.7,7.7,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,26.8C9,26.7,8.9,28.5,9,29c0.1,0.3,0.7,0.3,0.8,0C10,28.5,9.9,26.7,9.4,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,28.5,11.6,26.7,11.1,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,28.5,13.2,26.7,12.8,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,28.5,14.9,26.7,14.5,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,28.5,16.6,26.7,16.2,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,28.5,18.3,26.7,17.9,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,28.5,20,26.7,19.6,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,28.5,21.7,26.7,21.3,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,28.5,23.4,26.7,22.9,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,28.5,25.1,26.7,24.6,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,28.5,26.8,26.7,26.3,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,28.5,28.4,26.7,28,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,28.5,30.1,26.7,29.7,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,27.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,26.8C31,26.7,30.9,28.5,31,29c0.1,0.3,0.7,0.3,0.8,0C31.9,28.5,31.8,26.7,31.4,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,28.5,33.5,26.7,33.1,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,26.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,28.5,35.2,26.7,34.8,26.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,27.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,30.6,3.9,28.8,3.5,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,30.6,5.6,28.8,5.2,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,30.6,7.3,28.8,6.9,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,30.6,9,28.8,8.6,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,30.6,10.7,28.8,10.3,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,30.6,12.4,28.8,12,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,30.6,14.1,28.8,13.7,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,30.6,15.8,28.8,15.3,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,30.6,17.5,28.8,17,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,30.6,19.2,28.8,18.7,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,30.6,20.8,28.8,20.4,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,30.6,22.5,28.8,22.1,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,30.6,24.2,28.8,23.8,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,30.6,25.9,28.8,25.5,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,30.6,27.6,28.8,27.2,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,30.6,29.3,28.8,28.9,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,30.6,31,28.8,30.5,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,30.6,32.7,28.8,32.2,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,29.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,30.6,34.4,28.8,33.9,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,28.9c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,30.6,36,28.8,35.6,28.9z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,29.6c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,32.5,3.1,30.7,2.7,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,30.7C4,30.6,3.9,32.4,4,32.9c0.1,0.3,0.7,0.3,0.8,0C4.9,32.5,4.8,30.7,4.4,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,32.5,6.5,30.7,6,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,32.5,8.2,30.7,7.7,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,30.7C9,30.6,8.9,32.5,9,32.9c0.1,0.3,0.7,0.3,0.8,0C10,32.5,9.9,30.7,9.4,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,32.5,11.6,30.7,11.1,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,32.5,13.2,30.7,12.8,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,32.5,14.9,30.7,14.5,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,32.5,16.6,30.7,16.2,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,32.5,18.3,30.7,17.9,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,32.5,20,30.7,19.6,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,32.5,21.7,30.7,21.3,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,32.5,23.4,30.7,22.9,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,32.5,25.1,30.7,24.6,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,32.5,26.8,30.7,26.3,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,32.5,28.4,30.7,28,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,32.5,30.1,30.7,29.7,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,31.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,32.5,31.8,30.7,31.4,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,32.5,33.5,30.7,33.1,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,30.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,32.5,35.2,30.7,34.8,30.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,31.5c-0.7-0.2-0.6-1.3,0.2-1.4s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,32.8C3.1,32.7,3,34.5,3.1,35c0.1,0.3,0.7,0.3,0.8,0C4.1,34.5,3.9,32.8,3.5,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,34.5,5.6,32.8,5.2,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,34.5,7.3,32.8,6.9,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,34.5,9,32.8,8.6,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,34.5,10.7,32.8,10.3,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,34.5,12.4,32.8,12,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,34.5,14.1,32.8,13.7,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,34.5,15.8,32.8,15.3,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,34.5,17.5,32.8,17,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,34.5,19.2,32.8,18.7,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,32.8C20,32.7,19.9,34.5,20,35c0.1,0.3,0.7,0.3,0.8,0C20.9,34.5,20.8,32.8,20.4,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,34.5,22.5,32.8,22.1,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,34.5,24.2,32.8,23.8,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,32.8C25,32.7,25,34.5,25.1,35c0.1,0.3,0.7,0.3,0.8,0C26,34.5,25.9,32.8,25.5,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,34.5,27.6,32.8,27.2,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,34.5,29.3,32.8,28.9,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,34.5,31,32.8,30.5,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,34.5,32.7,32.8,32.2,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,34.5,34.4,32.8,33.9,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,32.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,34.5,36,32.8,35.6,32.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,33.6c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,36.4,3.1,34.6,2.7,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,34.7C4,34.6,3.9,36.4,4,36.9c0.1,0.3,0.7,0.3,0.8,0C4.9,36.4,4.8,34.6,4.4,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,36.4,6.5,34.6,6,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,35.4C5.1,35.2,5.2,34.1,6,34c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,36.4,8.2,34.6,7.7,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,34.7C9,34.6,8.9,36.4,9,36.9c0.1,0.3,0.7,0.3,0.8,0C10,36.4,9.9,34.6,9.4,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,36.4,11.6,34.6,11.1,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,36.4,13.2,34.6,12.8,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,36.4,14.9,34.6,14.5,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,36.4,16.6,34.6,16.2,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,36.4,18.3,34.6,17.9,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,36.4,20,34.6,19.6,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,36.4,21.7,34.6,21.3,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,36.4,23.4,34.6,22.9,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,36.4,25.1,34.6,24.6,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,36.4,26.8,34.6,26.3,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,36.4,28.4,34.6,28,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,36.4,30.1,34.6,29.7,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,36.4,31.8,34.6,31.4,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,36.4,33.5,34.6,33.1,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,34.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,36.4,35.2,34.6,34.8,34.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,35.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,36.8C3.1,36.6,3,38.5,3.1,39c0.1,0.3,0.7,0.3,0.8,0C4.1,38.5,3.9,36.7,3.5,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,38.5,5.6,36.7,5.2,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,38.5,7.3,36.7,6.9,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,38.5,9,36.7,8.6,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,38.5,10.7,36.7,10.3,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,38.5,12.4,36.7,12,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,38.5,14.1,36.7,13.7,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,38.5,15.8,36.7,15.3,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,38.5,17.5,36.7,17,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,38.5,19.2,36.7,18.7,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,36.8C20,36.6,19.9,38.5,20,39c0.1,0.3,0.7,0.3,0.8,0C20.9,38.5,20.8,36.7,20.4,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,38.5,22.5,36.7,22.1,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,38.5,24.2,36.7,23.8,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,36.8C25,36.6,25,38.5,25.1,39c0.1,0.3,0.7,0.3,0.8,0C26,38.5,25.9,36.7,25.5,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,38.5,27.6,36.7,27.2,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,38.5,29.3,36.7,28.9,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,38.5,31,36.7,30.5,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,38.5,32.7,36.7,32.2,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,38.5,34.4,36.7,33.9,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,36.8c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,38.5,36,36.7,35.6,36.8z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,37.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,40.3,3.1,38.6,2.7,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,38.6C4,38.5,3.9,40.3,4,40.8c0.1,0.3,0.7,0.3,0.8,0C4.9,40.3,4.8,38.6,4.4,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,40.3,6.5,38.6,6,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,39.4C5.1,39.1,5.2,38.1,6,38s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,40.3,8.2,38.6,7.7,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,38.6C9,38.5,8.9,40.3,9,40.8c0.1,0.3,0.7,0.3,0.8,0C10,40.3,9.9,38.6,9.4,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,40.3,11.6,38.6,11.1,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,40.3,13.2,38.6,12.8,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,40.3,14.9,38.6,14.5,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,40.3,16.6,38.6,16.2,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,40.3,18.3,38.6,17.9,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,40.3,20,38.6,19.6,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,40.3,21.7,38.6,21.3,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,40.3,23.4,38.6,22.9,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,40.3,25.1,38.6,24.6,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,40.3,26.8,38.6,26.3,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,40.3,28.4,38.6,28,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,40.3,30.1,38.6,29.7,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,40.3,31.8,38.6,31.4,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,40.3,33.5,38.6,33.1,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,38.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,40.3,35.2,38.6,34.8,38.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,39.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,42.4,3.9,40.6,3.5,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,42.4,5.6,40.6,5.2,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,42.4,7.3,40.6,6.9,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,42.4,9,40.6,8.6,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,42.4,10.7,40.6,10.3,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,42.4,12.4,40.6,12,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,42.4,14.1,40.6,13.7,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,42.4,15.8,40.6,15.3,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,42.4,17.5,40.6,17,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,42.4,19.2,40.6,18.7,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,42.4,20.8,40.6,20.4,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,42.4,22.5,40.6,22.1,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,42.4,24.2,40.6,23.8,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,42.4,25.9,40.6,25.5,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,42.4,27.6,40.6,27.2,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,42.4,29.3,40.6,28.9,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,42.4,31,40.6,30.5,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,42.4,32.7,40.6,32.2,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,42.4,34.4,40.6,33.9,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,40.7c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,42.4,36,40.6,35.6,40.7z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,41.5c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,42.6c-0.4-0.1-0.5,1.7-0.4,2.2C2.4,45,3,45,3.1,44.8C3.2,44.3,3.1,42.5,2.7,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,42.6C4,42.5,3.9,44.3,4,44.8c0.1,0.3,0.7,0.3,0.8,0C4.9,44.3,4.8,42.5,4.4,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,44.3,6.5,42.5,6,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,43.3C5.1,43.1,5.2,42.1,6,42s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,42.6c-0.4-0.1-0.5,1.7-0.4,2.2C7.4,45,8,45,8.1,44.8C8.3,44.3,8.2,42.5,7.7,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,42.6C9,42.5,8.9,44.3,9,44.8c0.1,0.3,0.7,0.3,0.8,0C10,44.3,9.9,42.5,9.4,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,44.3,11.6,42.5,11.1,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,44.3,13.2,42.5,12.8,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,44.3,14.9,42.5,14.5,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,44.3,16.6,42.5,16.2,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,44.3,18.3,42.5,17.9,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,44.3,20,42.5,19.6,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,44.3,21.7,42.5,21.3,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,44.3,23.4,42.5,22.9,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,44.3,25.1,42.5,24.6,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,44.3,26.8,42.5,26.3,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,44.3,28.4,42.5,28,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,44.3,30.1,42.5,29.7,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,44.3,31.8,42.5,31.4,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,44.3,33.5,42.5,33.1,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,42.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,44.3,35.2,42.5,34.8,42.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,43.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,46.4,3.9,44.6,3.5,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,46.4,5.6,44.6,5.2,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,46.4,7.3,44.6,6.9,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,45.4C6,45.2,6.1,44.1,6.8,44c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,46.4,9,44.6,8.6,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,46.4,10.7,44.6,10.3,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,46.4,12.4,44.6,12,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,46.4,14.1,44.6,13.7,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,46.4,15.8,44.6,15.3,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,46.4,17.5,44.6,17,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,46.4,19.2,44.6,18.7,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,46.4,20.8,44.6,20.4,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,46.4,22.5,44.6,22.1,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,46.4,24.2,44.6,23.8,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,46.4,25.9,44.6,25.5,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,46.4,27.6,44.6,27.2,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,46.4,29.3,44.6,28.9,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,46.4,31,44.6,30.5,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,46.4,32.7,44.6,32.2,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,46.4,34.4,44.6,33.9,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,44.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,46.4,36,44.6,35.6,44.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,45.4c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,46.5c-0.4-0.1-0.5,1.7-0.4,2.2C2.4,49,3,49,3.1,48.7C3.2,48.2,3.1,46.5,2.7,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,47.3C1.7,47,1.8,46,2.6,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,46.5C4,46.4,3.9,48.2,4,48.7c0.1,0.3,0.7,0.3,0.8,0C4.9,48.2,4.8,46.5,4.4,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,47.3C3.4,47,3.5,46,4.3,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,48.2,6.5,46.5,6,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,47.3C5.1,47,5.2,46,6,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,46.5c-0.4-0.1-0.5,1.7-0.4,2.2C7.4,49,8,49,8.1,48.7C8.3,48.2,8.2,46.5,7.7,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,47.3C6.8,47,6.9,46,7.7,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,46.5C9,46.4,8.9,48.2,9,48.7c0.1,0.3,0.7,0.3,0.8,0C10,48.2,9.9,46.5,9.4,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,47.3C8.5,47,8.6,46,9.4,45.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,48.2,11.6,46.5,11.1,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,48.2,13.2,46.5,12.8,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,47.3C11.9,47,12,46,12.7,45.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,48.2,14.9,46.5,14.5,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,48.2,16.6,46.5,16.2,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,48.2,18.3,46.5,17.9,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,48.2,20,46.5,19.6,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,48.2,21.7,46.5,21.3,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,48.2,23.4,46.5,22.9,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,47.3C22,47,22.1,46,22.9,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,48.2,25.1,46.5,24.6,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,48.2,26.8,46.5,26.3,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,48.2,28.4,46.5,28,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,47.3C27.1,47,27.2,46,28,45.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,48.2,30.1,46.5,29.7,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,48.2,31.8,46.5,31.4,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,48.2,33.5,46.5,33.1,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,47.3C32.2,47,32.3,46,33,45.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,46.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,48.2,35.2,46.5,34.8,46.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,47.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,50.3,3.9,48.5,3.5,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,50.3,5.6,48.5,5.2,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,50.3,7.3,48.5,6.9,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,49.3C6,49.1,6.1,48.1,6.8,48c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,50.3,9,48.5,8.6,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,50.3,10.7,48.5,10.3,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,50.3,12.4,48.5,12,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,50.3,14.1,48.5,13.7,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,50.3,15.8,48.5,15.3,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,50.3,17.5,48.5,17,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,50.3,19.2,48.5,18.7,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,50.3,20.8,48.5,20.4,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,50.3,22.5,48.5,22.1,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,50.3,24.2,48.5,23.8,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,50.3,25.9,48.5,25.5,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,50.3,27.6,48.5,27.2,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,50.3,29.3,48.5,28.9,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,50.3,31,48.5,30.5,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,50.3,32.7,48.5,32.2,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,50.3,34.4,48.5,33.9,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,48.6c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,50.3,36,48.5,35.6,48.6z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,49.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,50.5c-0.4-0.1-0.5,1.7-0.4,2.2C2.4,53,3,53,3.1,52.7C3.2,52.2,3.1,50.4,2.7,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,50.5C4,50.4,3.9,52.2,4,52.7c0.1,0.3,0.7,0.3,0.8,0C4.9,52.2,4.8,50.4,4.4,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,52.2,6.5,50.4,6,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,51.2C5.1,51,5.2,49.9,6,49.8c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,50.5c-0.4-0.1-0.5,1.7-0.4,2.2C7.4,53,8,53,8.1,52.7C8.3,52.2,8.2,50.4,7.7,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,50.5C9,50.4,8.9,52.2,9,52.7c0.1,0.3,0.7,0.3,0.8,0C10,52.2,9.9,50.4,9.4,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,52.2,11.6,50.4,11.1,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,52.2,13.2,50.4,12.8,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,52.2,14.9,50.4,14.5,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,52.2,16.6,50.4,16.2,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.9,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C18.4,52.2,18.3,50.4,17.9,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17.6,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,52.2,20,50.4,19.6,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,52.2,21.7,50.4,21.3,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,52.2,23.4,50.4,22.9,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,52.2,25.1,50.4,24.6,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,52.2,26.8,50.4,26.3,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,52.2,28.4,50.4,28,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,52.2,30.1,50.4,29.7,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,52.2,31.8,50.4,31.4,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,52.2,33.5,50.4,33.1,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,50.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,52.2,35.2,50.4,34.8,50.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,51.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,54.3,3.9,52.5,3.5,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,53.3C2.6,53,2.7,52,3.5,51.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,54.3,5.6,52.5,5.2,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M5,53.3C4.3,53,4.4,52,5.1,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,54.3,7.3,52.5,6.9,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,53.3C6,53,6.1,52,6.8,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,52.5c-0.4-0.1-0.5,1.7-0.4,2.2C8.3,55,8.9,55,9,54.7C9.1,54.3,9,52.5,8.6,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,53.3C7.7,53,7.8,52,8.5,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,54.3,10.7,52.5,10.3,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10,53.3C9.3,53,9.4,52,10.2,51.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,54.3,12.4,52.5,12,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,53.3C11,53,11.1,52,11.9,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,54.3,14.1,52.5,13.7,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,54.3,15.8,52.5,15.3,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M17,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C17.6,54.3,17.5,52.5,17,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.8,53.3C16.1,53,16.2,52,17,51.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.7,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C19.3,54.3,19.2,52.5,18.7,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M18.5,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,54.3,20.8,52.5,20.4,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,54.3,22.5,52.5,22.1,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,53.3C21.2,53,21.3,52,22,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,54.3,24.2,52.5,23.8,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,53.3C22.9,53,23,52,23.7,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,54.3,25.9,52.5,25.5,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,54.3,27.6,52.5,27.2,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,54.3,29.3,52.5,28.9,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,53.3C27.9,53,28,52,28.8,51.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,54.3,31,52.5,30.5,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,54.3,32.7,52.5,32.2,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.9,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,54.3,34.4,52.5,33.9,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.7,53.3C33,53,33.1,52,33.9,51.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.6,52.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,54.3,36,52.5,35.6,52.5z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M35.4,53.3c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,56.1,3.1,54.4,2.7,54.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,54.4C4,54.3,3.9,56.1,4,56.6c0.1,0.3,0.7,0.3,0.8,0C4.9,56.1,4.8,54.4,4.4,54.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,56.1,6.5,54.4,6,54.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,55.2C5.1,55,5.2,53.9,6,53.8c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,56.1,8.2,54.4,7.7,54.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,54.4C9,54.3,8.9,56.1,9,56.6c0.1,0.3,0.7,0.3,0.8,0C10,56.1,9.9,54.4,9.4,54.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,56.1,11.6,54.4,11.1,54.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,56.1,13.2,54.4,12.8,54.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,56.1,14.9,54.4,14.5,54.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M16.2,54.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C16.7,56.1,16.6,54.4,16.2,54.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9,55.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,60.1,21.7,58.3,21.3,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.9,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,60.1,23.4,58.3,22.9,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.7,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.6,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,60.1,25.1,58.3,24.6,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M24.4,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.3,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,60.1,26.8,58.3,26.3,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.1,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,60.1,28.4,58.3,28,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.8,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.7,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,60.1,30.1,58.3,29.7,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M29.5,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.4,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.9,60.1,31.8,58.3,31.4,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M31.1,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M33.1,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,60.1,33.5,58.3,33.1,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.8,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.8,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,60.1,35.2,58.3,34.8,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M34.5,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M36.5,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C37,60.1,36.9,58.3,36.5,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M36.2,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M38.1,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C38.7,60.1,38.6,58.3,38.1,58.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M37.9,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,58.2,3.9,56.4,3.5,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,57.2C2.6,57,2.7,56,3.5,55.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,58.2,5.6,56.4,5.2,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5,57.2C4.3,57,4.4,56,5.1,55.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,58.2,7.3,56.4,6.9,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,57.2C6,57,6.1,56,6.8,55.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,56.5c-0.4-0.1-0.5,1.7-0.4,2.2C8.3,59,8.9,59,9,58.7C9.1,58.2,9,56.4,8.6,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,57.2C7.7,57,7.8,56,8.5,55.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,58.2,10.7,56.4,10.3,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M10,57.2C9.3,57,9.4,56,10.2,55.9s1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M12,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,58.2,12.4,56.4,12,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,57.2C11,57,11.1,56,11.9,55.9c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,58.2,14.1,56.4,13.7,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,57.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,56.5c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,58.2,15.8,56.4,15.3,56.5z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,57.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,62.2,20.8,60.4,20.4,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,62.2,22.5,60.4,22.1,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.8,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,62.2,24.2,60.4,23.8,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M23.5,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.5,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,62.2,25.9,60.4,25.5,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M25.2,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M27.2,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,62.2,27.6,60.4,27.2,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M26.9,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.9,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,62.2,29.3,60.4,28.9,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M28.6,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.5,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,62.2,31,60.4,30.5,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M30.3,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32.2,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,62.2,32.7,60.4,32.2,60.4z"/>
				<path fill="#FFFFFF" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M32,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M33.9,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,62.2,34.4,60.4,33.9,60.4z"/>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M33.7,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M35.6,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,62.2,36,60.4,35.6,60.4z"/>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M35.4,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M37.3,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C37.8,62.2,37.7,60.4,37.3,60.4z"/>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M37.1,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C39.5,62.2,39.4,60.4,39,60.4z"/>
				<path fill="#F5C51D" stroke="#947701" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M38.7,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M2.7,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C3.2,60.1,3.1,58.3,2.7,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M2.4,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M4.4,58.4C4,58.3,3.9,60.1,4,60.6c0.1,0.3,0.7,0.3,0.8,0C4.9,60.1,4.8,58.3,4.4,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M4.1,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C6.6,60.1,6.5,58.3,6,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5.8,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M7.7,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C8.3,60.1,8.2,58.3,7.7,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M7.5,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M9.4,58.4C9,58.2,8.9,60.1,9,60.6c0.1,0.3,0.7,0.3,0.8,0C10,60.1,9.9,58.3,9.4,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M9.2,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M11.1,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C11.7,60.1,11.6,58.3,11.1,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M10.9,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M12.8,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C13.3,60.1,13.2,58.3,12.8,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M12.6,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M14.5,58.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15,60.1,14.9,58.3,14.5,58.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M14.3,59.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DE5510" stroke="#872D00" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.6,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.1,64,20,62.2,19.6,62.3z"/>
				<path fill="#DE5510" stroke="#872D00" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M19.3,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DE5510" stroke="#872D00" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.3,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C21.8,64,21.7,62.2,21.3,62.3z"/>
				<path fill="#DE5510" stroke="#872D00" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M22.9,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C23.5,64,23.4,62.2,22.9,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M22.7,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M24.6,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C25.2,64,25.1,62.2,24.6,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M24.4,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M26.3,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26.9,64,26.8,62.2,26.3,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M26.1,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M28,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C28.5,64,28.4,62.2,28,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M27.8,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M29.7,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C30.2,64,30.1,62.2,29.7,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M29.5,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M31.4,62.3C31,62.2,30.9,64,31,64.5c0.1,0.3,0.7,0.3,0.8,0C31.9,64,31.8,62.2,31.4,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M31.1,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M33.1,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C33.6,64,33.5,62.2,33.1,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M32.8,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M34.8,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C35.3,64,35.2,62.2,34.8,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M34.5,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M36.5,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C37,64,36.9,62.2,36.5,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M36.2,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M38.1,62.3c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C38.7,64,38.6,62.2,38.1,62.3z"/>
				<path fill="#0566AB" stroke="#003B6B" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M37.9,63.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M3.5,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C4.1,62.2,3.9,60.4,3.5,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M3.3,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5.2,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C5.7,62.2,5.6,60.4,5.2,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M5,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6.9,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C7.4,62.2,7.3,60.4,6.9,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M6.6,61.2C5.9,61,6,59.9,6.8,59.8c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M8.6,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C9.1,62.2,9,60.4,8.6,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M8.3,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M10.3,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C10.8,62.2,10.7,60.4,10.3,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M10,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M12,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C12.5,62.2,12.4,60.4,12,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M11.7,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M13.7,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C14.2,62.2,14.1,60.4,13.7,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M13.4,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M15.3,60.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C15.9,62.2,15.8,60.4,15.3,60.4z"/>
				<path fill="#61468C" stroke="#34294E" strokeWidth="0.25" strokeLinecap="round" strokeMiterlimit="10" d="M15.1,61.2c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#003C8C" stroke="#FBC700" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.4,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C20.9,66.1,20.8,64.3,20.4,64.4z"/>
				<path fill="#003C8C" stroke="#FBC700" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M20.2,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#003C8C" stroke="#FBC700" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M22.1,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C22.6,66.1,22.5,64.3,22.1,64.4z"/>
				<path fill="#003C8C" stroke="#FBC700" strokeWidth="0.2" strokeLinecap="round" strokeMiterlimit="10" d="M21.9,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M23.8,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C24.3,66.1,24.2,64.3,23.8,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M23.5,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M25.5,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C26,66.1,25.9,64.3,25.5,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M25.2,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M27.2,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C27.7,66.1,27.6,64.3,27.2,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M26.9,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M28.9,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C29.4,66.1,29.3,64.3,28.9,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M28.6,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M30.5,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C31.1,66.1,31,64.3,30.5,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M30.3,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M32.2,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C32.8,66.1,32.7,64.3,32.2,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M32,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M33.9,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C34.5,66.1,34.4,64.3,33.9,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M33.7,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M35.6,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C36.1,66.1,36,64.3,35.6,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M35.4,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M37.3,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C37.8,66.1,37.7,64.3,37.3,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M37.1,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
			<g>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M39,64.4c-0.4-0.1-0.5,1.7-0.4,2.2c0.1,0.3,0.7,0.3,0.8,0C39.5,66.1,39.4,64.3,39,64.4z"/>
				<path fill="#DF436A" stroke="#891F3E" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M38.7,65.1c-0.7-0.2-0.6-1.3,0.2-1.4c0.8-0.1,1,1,0.4,1.3"/>
			</g>
		</g>
		<text x="20.5" y="57" style={{fill:"#000", fontSize:"0.8px", strokeWidth:"0"}}>비례대표</text>
		<text x="1.9" y="64.3" style={{fill:"#000", fontSize:"0.8px", strokeWidth:"0"}}>지역구</text>
		</svg>
	  </div>
	  );
	}
}

	