import React, { PureComponent } from 'react';

export default class RpD extends PureComponent {

render() {
      return (

	  <div style={{ margin:"auto", maxWidth:"680px", width:"100%" }}>

<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 210 297" style={{enableBackground:"new 0 0 210 297"}}>


<rect x="7.2" y="58.5" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="7.1"/>
<rect x="35.6" y="21.7" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="43.9"/>
<rect x="63.9" y="20.5" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="45.1"/>
<rect x="92.2" y="11.8" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="53.9"/>
<rect x="120.6" y="9.8" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="55.8"/>
<rect x="148.9" y="24.2" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="41.4"/>
<rect x="177.3" y="29.6" opacity="0.2" fill="#C0BFBF" stroke="#737373" strokeWidth="0.2" strokeMiterlimit="10" width="25.5" height="36"/>
<line fill="none" stroke="#000000" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="65.6" x2="206.9" y2="65.6"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="93" x2="206.9" y2="93"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="109" x2="206.9" y2="109"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="119.3" x2="206.9" y2="119.3"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,177.6,65.6,174.8,65,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.6,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C68.4,177.6,68.2,174.8,67.6,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.2,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M70.1,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C71,177.6,70.8,174.8,70.1,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M69.8,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,177.6,94.1,174.8,93.5,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,180.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,182.8,94.1,180.1,93.5,180.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,181.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,177.6,96.7,174.8,96,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.6,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.4,177.6,99.3,174.8,98.6,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.2,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M101.2,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C102,177.6,101.9,174.8,101.2,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M100.8,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.8,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C104.6,177.6,104.4,174.8,103.8,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.4,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M106.3,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C107.2,177.6,107,174.8,106.3,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M106,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M108.9,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C109.8,177.6,109.6,174.8,108.9,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M108.5,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M111.5,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C112.3,177.6,112.2,174.8,111.5,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M111.1,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M114.1,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C114.9,177.6,114.8,174.8,114.1,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M113.7,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M116.7,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C117.5,177.6,117.3,174.8,116.7,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M116.3,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,177.6,122.4,174.8,121.7,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,177.6,125,174.8,124.3,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,177.6,127.6,174.8,126.9,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,177.6,130.1,174.8,129.5,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,177.6,132.7,174.8,132,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,177.6,135.3,174.8,134.6,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,177.6,137.9,174.8,137.2,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,177.6,140.5,174.8,139.8,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,177.6,143,174.8,142.4,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,177.6,145.6,174.8,144.9,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,183,122.4,180.3,121.7,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,183,125,180.3,124.3,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,183,127.6,180.3,126.9,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,183,130.1,180.3,129.5,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,183,132.7,180.3,132,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,183,135.3,180.3,134.6,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,183,137.9,180.3,137.2,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,183,140.5,180.3,139.8,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,183,143,180.3,142.4,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,180.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,183,145.6,180.3,144.9,180.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,181.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,177.6,150.8,174.8,150.1,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,177.6,153.3,174.8,152.7,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,177.6,155.9,174.8,155.2,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,177.6,158.5,174.8,157.8,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.4,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.2,177.6,161.1,174.8,160.4,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M163,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C163.8,177.6,163.6,174.8,163,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M162.6,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.6,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C166.4,177.6,166.2,174.8,165.6,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.2,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M168.1,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C169,177.6,168.8,174.8,168.1,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M167.8,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M170.7,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C171.5,177.6,171.4,174.8,170.7,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M170.3,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M173.3,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C174.1,177.6,174,174.8,173.3,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M172.9,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,188.5,122.4,185.7,121.7,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,188.5,125,185.7,124.3,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,188.5,127.6,185.7,126.9,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,188.5,130.1,185.7,129.5,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,191.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,193.9,122.4,191.2,121.7,191.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,192.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,191.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,193.9,125,191.2,124.3,191.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,192.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,191.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,193.9,127.6,191.2,126.9,191.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,192.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,191.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,193.9,130.1,191.2,129.5,191.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,192.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,188.5,132.7,185.7,132,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,188.5,135.3,185.7,134.6,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,188.5,137.9,185.7,137.2,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,188.5,140.5,185.7,139.8,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,188.5,143,185.7,142.4,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,185.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,188.5,145.6,185.7,144.9,185.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,187c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178.4,174.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C179.2,177.6,179,174.8,178.4,174.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178,176.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,77.9,65.6,75.2,65,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.6,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C68.4,77.9,68.2,75.2,67.6,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.2,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,77.9,94.1,75.2,93.5,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,77.9,96.7,75.2,96,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.6,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.4,77.9,99.3,75.2,98.6,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.2,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M101.2,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C102,77.9,101.9,75.2,101.2,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M100.8,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.8,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C104.6,77.9,104.4,75.2,103.8,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.4,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M106.3,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C107.2,77.9,107,75.2,106.3,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M106,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M108.9,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C109.8,77.9,109.6,75.2,108.9,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M108.5,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,77.9,122.4,75.2,121.7,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,77.9,125,75.2,124.3,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,77.9,127.6,75.2,126.9,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,77.9,130.1,75.2,129.5,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,77.9,132.7,75.2,132,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,77.9,135.3,75.2,134.6,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,77.9,137.9,75.2,137.2,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,77.9,140.5,75.2,139.8,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,77.9,143,75.2,142.4,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,77.9,145.6,75.2,144.9,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,83.4,122.4,80.7,121.7,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,83.4,125,80.7,124.3,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,83.4,127.6,80.7,126.9,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,83.4,130.1,80.7,129.5,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,83.4,132.7,80.7,132,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,83.4,135.3,80.7,134.6,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,83.4,137.9,80.7,137.2,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,83.4,140.5,80.7,139.8,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,83.4,143,80.7,142.4,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,83.4,145.6,80.7,144.9,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,77.9,150.8,75.2,150.1,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,77.9,153.3,75.2,152.7,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,77.9,155.9,75.2,155.2,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,77.9,158.5,75.2,157.8,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.4,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.2,77.9,161.1,75.2,160.4,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M163,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C163.8,77.9,163.6,75.2,163,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M162.6,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.6,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C166.4,77.9,166.2,75.2,165.6,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.2,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M168.1,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C169,77.9,168.8,75.2,168.1,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M167.8,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M170.7,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C171.5,77.9,171.4,75.2,170.7,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M170.3,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M173.3,75.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C174.1,77.9,174,75.2,173.3,75.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M172.9,76.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,83.4,150.8,80.7,150.1,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,80.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,83.4,153.3,80.7,152.7,80.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,81.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,88.9,122.4,86.1,121.7,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,88.9,125,86.1,124.3,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,88.9,127.6,86.1,126.9,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,88.9,130.1,86.1,129.5,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,88.9,132.7,86.1,132,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,88.9,135.3,86.1,134.6,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,88.9,137.9,86.1,137.2,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,86.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,88.9,140.5,86.1,139.8,86.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,87.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,115.4,122.4,112.7,121.7,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,115.4,125,112.7,124.3,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,115.4,127.6,112.7,126.9,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,115.4,130.1,112.7,129.5,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,115.4,132.7,112.7,132,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,115.4,135.3,112.7,134.6,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,115.4,137.9,112.7,137.2,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,115.4,150.8,112.7,150.1,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,115.4,153.3,112.7,152.7,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,115.4,155.9,112.7,155.2,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,115.4,158.5,112.7,157.8,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.4,112.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.2,115.4,161.1,112.7,160.4,112.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160,113.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="218.5" x2="206.9" y2="218.5"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="249.4" x2="206.9" y2="249.4"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="275.8" x2="206.9" y2="275.8"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,279.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,282.3,122.4,279.5,121.7,279.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,280.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,279.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,282.3,125,279.5,124.3,279.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,280.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,279.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,282.3,127.6,279.5,126.9,279.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,280.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,224.9,94.1,222.2,93.5,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,224.9,96.7,222.2,96,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,224.9,122.4,222.2,121.7,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,224.9,125,222.2,124.3,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,224.9,127.6,222.2,126.9,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,224.9,130.1,222.2,129.5,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,224.9,132.7,222.2,132,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,224.9,135.3,222.2,134.6,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,224.9,137.9,222.2,137.2,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,224.9,150.8,222.2,150.1,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178.4,222.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C179.2,224.9,179,222.2,178.4,222.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178,223.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="208.2" x2="206.9" y2="208.2"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178.4,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C179.2,214.6,179,211.9,178.4,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M178,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,214.6,122.4,211.9,121.7,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,214.6,125,211.9,124.3,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,214.6,150.8,211.9,150.1,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,214.6,153.3,211.9,152.7,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,214.6,155.9,211.9,155.2,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,214.6,158.5,211.9,157.8,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.4,212c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.2,214.6,161.1,211.9,160.4,212z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160,213.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="239.1" x2="206.9" y2="239.1"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,255.9,94.1,253.1,93.5,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,255.9,96.7,253.1,96,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.6,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.5,255.9,99.3,253.1,98.6,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.3,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,255.9,122.4,253.1,121.7,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,255.9,125,253.1,124.3,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,255.9,127.6,253.1,126.9,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,255.9,130.1,253.1,129.5,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,255.9,132.7,253.1,132,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,255.9,135.3,253.1,134.6,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,255.9,137.9,253.1,137.2,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,255.9,150.8,253.1,150.1,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,255.9,153.3,253.1,152.7,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,253.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,255.9,155.9,253.1,155.2,253.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,254.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="150.2" x2="206.9" y2="150.2"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,156.7,122.4,153.9,121.7,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,156.7,125,153.9,124.3,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,156.7,150.8,153.9,150.1,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,156.7,153.3,153.9,152.7,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,156.7,155.9,153.9,155.2,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,154c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,156.7,158.5,153.9,157.8,154z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,155.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="160.6" x2="206.9" y2="160.6"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,164.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,167,122.4,164.3,121.7,164.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,165.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,164.3c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,167,125,164.3,124.3,164.3z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,165.5c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,99.7,94.1,96.9,93.5,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,99.7,96.7,96.9,96,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.6,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.4,99.7,99.3,96.9,98.6,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.2,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,99.7,122.4,96.9,121.7,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,99.7,125,96.9,124.3,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,99.7,127.6,96.9,126.9,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,99.7,130.1,96.9,129.5,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,99.7,132.7,96.9,132,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,99.7,135.3,96.9,134.6,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,99.7,137.9,96.9,137.2,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,99.7,140.5,96.9,139.8,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,99.7,143,96.9,142.4,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,99.7,145.6,96.9,144.9,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,102.5c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,105.1,122.4,102.4,121.7,102.5z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,103.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,102.5c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,105.1,125,102.4,124.3,102.5z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,103.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,102.5c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,105.1,127.6,102.4,126.9,102.5z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,103.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,99.7,150.8,96.9,150.1,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,97c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,99.7,153.3,96.9,152.7,97z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,98.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="259.7" x2="206.9" y2="259.7"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,266.4,155.9,263.7,155.2,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,266.4,158.5,263.7,157.8,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,266.4,94.1,263.7,93.5,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,266.4,122.4,263.7,121.7,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,266.4,125,263.7,124.3,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,266.4,127.6,263.7,126.9,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,266.4,130.1,263.7,129.5,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,266.4,132.7,263.7,132,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,266.4,135.3,263.7,134.6,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,266.4,137.9,263.7,137.2,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,266.4,140.5,263.7,139.8,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,266.4,143,263.7,142.4,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,266.4,145.6,263.7,144.9,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,269.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,271.9,122.4,269.1,121.7,269.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,270.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,266.4,150.8,263.7,150.1,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,263.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,266.4,153.3,263.7,152.7,263.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,264.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="170.9" x2="206.9" y2="170.9"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,125.7,94.1,123,93.5,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,125.7,122.4,123,121.7,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,125.7,125,123,124.3,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,125.7,127.6,123,126.9,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,125.7,130.1,123,129.5,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,125.7,132.7,123,132,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,125.7,135.3,123,134.6,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,125.7,137.9,123,137.2,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,125.7,140.5,123,139.8,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,125.7,143,123,142.4,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.9,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C145.8,125.7,145.6,123,144.9,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M144.6,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,125.7,150.8,123,150.1,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,123.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,125.7,153.3,123,152.7,123.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,124.3c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="228.8" x2="206.9" y2="228.8"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,245.6,122.4,242.8,121.7,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,245.6,125,242.8,124.3,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,245.6,150.8,242.8,150.1,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,245.6,153.3,242.8,152.7,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.2,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,245.6,155.9,242.8,155.2,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.8,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.6,245.6,158.5,242.8,157.8,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.4,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.4,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.2,245.6,161.1,242.8,160.4,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,245.6,127.6,242.8,126.9,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,245.6,130.1,242.8,129.5,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,242.9c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,245.6,132.7,242.8,132,242.9z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,244.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,235.2,122.4,232.5,121.7,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,235.2,125,232.5,124.3,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,235.2,127.6,232.5,126.9,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,235.2,130.1,232.5,129.5,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,235.2,132.7,232.5,132,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,235.2,135.3,232.5,134.6,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,235.2,137.9,232.5,137.2,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,235.2,140.5,232.5,139.8,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142.4,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C143.2,235.2,143,232.5,142.4,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M142,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,232.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,235.2,150.8,232.5,150.1,232.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,233.7c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="197.8" x2="206.9" y2="197.8"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,204.3,122.4,201.6,121.7,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,204.3,125,201.6,124.3,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,204.3,127.6,201.6,126.9,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,204.3,130.1,201.6,129.5,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,204.3,132.7,201.6,132,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,204.3,135.3,201.6,134.6,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,204.3,150.8,201.6,150.1,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,201.6c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,204.3,153.3,201.6,152.7,201.6z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,202.8c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="139.9" x2="206.9" y2="139.9"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,146.4,65.6,143.6,65,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,146.4,122.4,143.6,121.7,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,146.4,125,143.6,124.3,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,146.4,127.6,143.6,126.9,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,146.4,130.1,143.6,129.5,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,146.4,150.8,143.6,150.1,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,143.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,146.4,153.3,143.6,152.7,143.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,144.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="129.6" x2="206.9" y2="129.6"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,136.1,122.4,133.3,121.7,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,136.1,125,133.3,124.3,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,136.1,127.6,133.3,126.9,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,136.1,130.1,133.3,129.5,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,136.1,132.7,133.3,132,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,136.1,135.3,133.3,134.6,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,136.1,137.9,133.3,137.2,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,133.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,136.1,150.8,133.3,150.1,133.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" stroke-width="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,134.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<text x="3.1" y="82.2" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>서울</text>
	<text x="3.1" y="100.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>부산</text>
	<text x="3.1" y="115.5" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>대구</text>
	<text x="3.1" y="125.9" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>인천</text>
	<text x="3.1" y="136.2" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>광주</text>
	<text x="3.1" y="146.5" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>대전</text>
	<text x="3.1" y="156.8" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>울산</text>
	<text x="3.1" y="167.1" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>세종</text>
	<text x="3.1" y="185.5" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>경기</text>
	<text x="3.1" y="204.4" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>강원</text>
	<text x="3.1" y="214.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>충북</text>
	<text x="3.1" y="224.8" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>충남</text>
	<text x="3.1" y="235.3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>전북</text>
	<text x="3.1" y="245.6" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>전남</text>
	<text x="3.1" y="255.8" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>경북</text>
	<text x="3.1" y="268.9" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>경남</text>
	<text x="3.1" y="282.2" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>제주</text>
</g>
<g>
    <text text-anchor="middle" x="20" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>1,153,662명</text>
	<text text-anchor="middle" x="20" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>2.62%</text>
	<text text-anchor="middle" x="48.3" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>6,796,623명</text>
	<text text-anchor="middle" x="48.3" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>15.46%</text>
	<text text-anchor="middle" x="76.7" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>6,994,134명</text>
	<text text-anchor="middle" x="76.7" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>15.91%</text>
	<text text-anchor="middle" x="105" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>8,357,423명</text>
	<text text-anchor="middle" x="105" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>19.01%</text>
	<text text-anchor="middle" x="133.3" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>8,649,821명</text>
	<text text-anchor="middle" x="133.3" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>19.68%</text>
	<text text-anchor="middle" x="161.7" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>6,439,959명</text>
	<text text-anchor="middle" x="161.7" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>14.65%</text>
	<text text-anchor="middle" x="190" y="64" style={{fill:"rgb(150,150,150)", fontSize:"3px", strokeWidth:"0"}}>5,569,535명</text>
	<text text-anchor="middle" x="190" y="69.5" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>12.67%</text>
</g>
<g>
	<text text-anchor="middle" x="20" y="5.7" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>18~19세</text>
	<text text-anchor="middle" x="48.3" y="5.7" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>20대</text>
	<text text-anchor="middle" x="76.6" y="5.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>30대</text>
	<text text-anchor="middle" x="105" y="5.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>40대</text>
	<text text-anchor="middle" x="133.3" y="5.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>50대</text>
	<text text-anchor="middle" x="161.7" y="5.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>60대</text>
	<text text-anchor="middle" x="190" y="5.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>70세 이상</text>
</g>
</svg>
</div> 
	  );
	  }
	}

