import React, { PureComponent } from 'react';
import styled from "@emotion/styled";

const Covidtable = styled.div`

.covtable { width:100%; max-width:680px; margin:auto; margin-bottom:48px;}
.covtable > tr > th { padding-bottom: 14px; }
.covtable > tr > td { font-weight:200; font-size:1em; text-align:center; }
.em1 { font-weight:800; color:#9F0B00; }
.em2 { font-weight:800; color:#D10B00; }
.rem1 { font-weight:800; color:#018DFF; }
.rem1a { font-weight:800; color:#01B6FF; }
.rem1b { font-weight:800; color:#0154FF; }
.rem1c { font-weight:800; color:#0122FF; }
.rem2 { font-weight:800; color:#47C500; }
.rem2a { font-weight:800; color:#1DA700; }
.rem2b { font-weight:800; color:#7BE6FF; }

@media screen and (max-width: 600px) {

} 
`;

export default class Table extends PureComponent {
    render() {
        return (
            <Covidtable>
                <table className="covtable">
                    <tr>
                        <th>순위</th>
                        <th>1월</th>
                        <th>2월</th>
                        <th>3월</th>
                        <th>4월</th>
                        <th>5월</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>신종</td>
                        <td>신종</td>
                        <td><span className="rem1a">확진</span></td>
                        <td><span className="rem1b">극복</span></td>
                        <td><span className="rem1a">확진</span></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><span className="em1">우한</span></td>
                        <td><span className="rem1a">확진</span></td>
                        <td><span className="rem1b">극복</span></td>
                        <td><span className="rem2a">지원</span></td>
                        <td><span className="rem2a">포스트</span></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>중국</td>
                        <td>중국</td>
                        <td><span className="rem1">마스크</span></td>
                        <td><span className="rem1a">확진</span></td>
                        <td><span className="rem1b">극복</span></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>폐렴</td>
                        <td>확산</td>
                        <td><span className="rem2a">지원</span></td>
                        <td>한국</td>
                        <td><span className="rem2a">지원</span></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><span className="rem1a">확진</span></td>
                        <td><span className="em2">대구</span></td>
                        <td><span className="em2">대구</span></td>
                        <td>정부</td>
                        <td>이태원</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>바이러스</td>
                        <td><span className="rem1">마스크</span></td>
                        <td>확산</td>
                        <td>위기</td>
                        <td>시대</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>확산</td>
                        <td>감염</td>
                        <td>기부</td>
                        <td>총선</td>
                        <td><span className="rem2">대통령</span></td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>감염</td>
                        <td><span className="em1">우한</span></td>
                        <td>대응</td>
                        <td>온라인</td>
                        <td><span className="rem2">경제</span></td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>대응</td>
                        <td>환자</td>
                        <td><span className="rem1c">방역</span></td>
                        <td><span className="rem2">경제</span></td>
                        <td>감염</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td><span className="rem1">마스크</span></td>
                        <td>대응</td>
                        <td>신천지</td>
                        <td><span className="rem2">대통령</span></td>
                        <td>한국</td>
                    </tr>
                    <br />
                    <tr>
                        <td>11</td>
                        <td>비상</td>
                        <td><span className="rem2a">지원</span></td>
                        <td>성금</td>
                        <td><span className="rem1">마스크</span></td>
                        <td>정부</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>발생</td>
                        <td>정부</td>
                        <td>정부</td>
                        <td>대응</td>
                        <td>클럽</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>국내</td>
                        <td>사망</td>
                        <td>감염</td>
                        <td>1분기</td>
                        <td><span className="rem1c">방역</span></td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>환자</td>
                        <td>바이러스</td>
                        <td>한국</td>
                        <td><span className="em2">대구</span></td>
                        <td>등교</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>정부</td>
                        <td>신천지</td>
                        <td>연기</td>
                        <td>감염</td>
                        <td>위기</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>교민</td>
                        <td><span className="rem1c">방역</span></td>
                        <td><span className="em1">우한</span></td>
                        <td><span className="rem1c">방역</span></td>
                        <td>개최</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>전세기</td>
                        <td>연기</td>
                        <td>피해</td>
                        <td>피해</td>
                        <td>대응</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>공포</td>
                        <td>추가</td>
                        <td>전달</td>
                        <td>기부</td>
                        <td>온라인</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>우려</td>
                        <td>한국</td>
                        <td>사망</td>
                        <td>트럼프</td>
                        <td>확산</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>WHO</td>
                        <td>취소</td>
                        <td>위기</td>
                        <td>사망</td>
                        <td>1분기</td>
                    </tr>
                    <br />
                    <tr>
                        <td>21</td>
                        <td>사망</td>
                        <td>피해</td>
                        <td>추경</td>
                        <td>긴급</td>
                        <td><span className="rem1">마스크</span></td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>연기</td>
                        <td>발생</td>
                        <td>추가</td>
                        <td>성금</td>
                        <td>트럼프</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>입국</td>
                        <td>입국</td>
                        <td>긴급</td>
                        <td>지급</td>
                        <td>서울</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>격리</td>
                        <td>지역</td>
                        <td>트럼프</td>
                        <td>세계</td>
                        <td>중국</td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>취소</td>
                        <td>격리</td>
                        <td>환자</td>
                        <td>전달</td>
                        <td>이후</td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td><span className="rem2">대통령</span></td>
                        <td>비상</td>
                        <td><span className="rem2">대통령</span></td>
                        <td>사회적</td>
                        <td>기업</td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>한국</td>
                        <td>방문</td>
                        <td>비상</td>
                        <td>이후</td>
                        <td>추진</td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>긴급</td>
                        <td><span className="rem2">대통령</span></td>
                        <td>서울</td>
                        <td><span className="rem2a">포스트</span></td>
                        <td>기부</td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td><span className="rem1c">방역</span></td>
                        <td>우려</td>
                        <td>중국</td>
                        <td>위한</td>
                        <td>세계</td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>중단</td>
                        <td>국내</td>
                        <td>발생</td>
                        <td>기업</td>
                        <td>지역</td>
                    </tr>
                </table>
            </Covidtable>
        );
    }
}