import React, { PureComponent } from 'react';
import styled from "@emotion/styled";

const SvgDivs = styled.div`

@media screen and (max-width: 600px) {
  .overflowcheck { overflow: hidden; }
  .mobile { width:150%; margin-left:-25%; margin-right:-25%; }

} 
`;
export default class RpB extends PureComponent {

render() {
      return (
	  <SvgDivs>
	  <div className="overflowcheck" style={{ margin:"auto", maxWidth:"680px", width:"100%" }}>

		<svg className="mobile" version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 210 492.8" style={{enableBackground:"new 0 0 210 492.8"}} >

		<line fill="none" stroke="#EABDB4" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="2" x1="82" y1="349.2" x2="97.7" y2="349.2"/>
		<line fill="none" stroke="#EABDB4" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="2" x1="82" y1="317.4" x2="96.9" y2="317.4"/>
		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="121.9" y1="25.3" x2="131.5" y2="25.3"/>
		<text x="132" y="26.3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>스웨덴 47.3%</text>
		<text x="132" y="31.3" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>2위 / 5위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="119.3" y1="56.6" x2="131.5" y2="56.6"/>
		<text x="132" y="57.6" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>프랑스 39.7%</text>
		<text x="132" y="62.6" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>7위 / 17위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="116.7" y1="119.1" x2="131.5" y2="119.1"/>
		<text x="132" y="120.1" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>영국 32.0%</text>
		<text x="132" y="125.1" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>15위 / 39위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="116.3" y1="141.6" x2="131.5" y2="141.6"/>
		<text x="132" y="142.6" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>독일 30.9%</text>
		<text x="132" y="147.6" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>18위 / 47위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="114.9" y1="181.3" x2="131.5" y2="181.3"/>
		<text x="132" y="182.3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>캐나다 26.9%</text>
		<text x="132" y="187.3" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>23위 / 61위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="113.8" y1="223.8" x2="131.5" y2="223.8"/>
		<text x="132" y="224.8" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>미국 23.6%</text>
		<text x="132" y="229.8" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>26위 / 76위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="112.6" y1="294.7" x2="131.5" y2="294.7"/>
		<text x="132" y="295.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>슬로바키아 20.0%</text>
		<text x="132" y="300.7" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>31위 / 101위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="112.3" y1="317.4" x2="131.5" y2="317.4"/>
		<text x="132" y="318.4" style={{fontSize:"3.6px", fill:"#EA2B06", strokeWidth:"0"}}>2020 대한민국 19.0%</text>
		<text x="132" y="323.4" style={{fontSize:"3px", fill:"#EA2B06", strokeWidth:"0"}}>32위 / 109위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="111.7" y1="348.8" x2="131.5" y2="348.8"/>
		<text x="132" y="349.8" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>2019 대한민국 17.1%</text>
		<text x="132" y="354.8" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>34위 / 120위</text>

		<line fill="none" stroke="#969696" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="109.3" y1="473.6" x2="131.5" y2="473.6"/>
		<text x="132" y="474.6" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>일본 10.2%</text>
		<text x="132" y="479.6" style={{fill:"#000", fontSize:"3px", strokeWidth:"0"}}>36위 / 164위</text>

		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="215.3" r="10.4"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="201.2" r="10.6"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="42.9" r="17.6"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="45.3" r="17.5"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="48.2" r="17.3"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="51" r="17"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="71" r="16.2"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="73.5" r="16.2"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="82" r="15.9"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="84.9" r="15.8"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="96.4" r="15.2"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="113.4" r="13.8"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="127.6" r="13.3"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="135.9" r="13.2"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="147.3" r="12.8"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="161.5" r="12.4"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="164.4" r="12.4"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="167.1" r="12.2"/>
		<line fill="none" stroke="#DCDDDD" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="105.1" y1="11.4" x2="105.1" y2="488.9"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="22.8" r="20.5"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="25.6" r="20.1"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="56.6" r="16.9"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="119.1" r="13.6"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="141.6" r="13.1"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="181.3" r="11.4"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="223.8" r="10"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="243.7" r="9.6"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="246.5" r="9.6"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="252.2" r="9.4"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="269.2" r="9.1"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="294.7" r="8.5"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="323" r="8"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="345.8" r="7.4"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="348.8" r="7.3"/>
		<circle fill="#EA5506" stroke="#EA2B06" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="317.4" r="8.1"/>
		<circle opacity="0.15" fill="#BFBFBF" stroke="#737373" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" enableBackground="new" cx="105" cy="428.3" r="5.4"/>
		<circle fill="#61468C" stroke="#601986" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="105" cy="473.6" r="4.3"/>
		<g>
			<g>
				<line fill="none" stroke="#EA2B06" strokeWidth="0.4" strokeLinecap="round" strokeLinejoin="round" x1="85.7" y1="346.5" x2="85.7" y2="320.9"/>
				<text x="64" y="333.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>OECD 내</text>
				<text x="64" y="338.7" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>2개 순위 상승</text>
			</g>
		</g>
		<polygon fill="#EA5506" stroke="#EA2B06" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="84.8,320.9 85.7,320 86.6,320.9 "/>
		</svg>
	  </div>
	  </SvgDivs>
);
}
}
