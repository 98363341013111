import React, { PureComponent } from 'react';
import styled from "@emotion/styled";

const SvgDiv = styled.div`
.st0{fill:#D9D9D9;}
	.st1{fill:#4E256D;}
	.st2{fill:#D0BBD7;}
	.st3{fill:#FFFFFF;}
	.st4{fill:none;stroke:#4E256D;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}
	.text-year { font-size: 3px; }

	@media screen and (max-width: 600px) {
		.text-year { font-size: 5px !important; transform: translateX(-1.8px); }
		.text-title, .text-title-secondrow { font-size: 5px !important; }
		.text-title-secondrow { transform:translateY(2px); }
		.text-info-1a,.text-info-1b,.text-info-2a,.text-info-2b,.text-info-3a,.text-info-3b,.text-info-4a,.text-info-4b { font-size:5px !important; }       
		.text-info-1a,.text-info-2a,.text-info-3a,.text-info-4a { transform: translate(-60px, 27.3px); }
		.text-info-1b,.text-info-2b,.text-info-3b,.text-info-4b { transform: translate(32px, 20px); }
		.poly { visibility: hidden; }
	}

`;


export default class Example extends PureComponent {
    render() {
      return (
        <SvgDiv>
<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 170.4794159 297.0000305">
<g>
    <text class="text-title" x="6" y="10" style={{fontSize:"3px"}}>비례대표</text>
    <text class="text-title-secondrow" x="6" y="13.5" style={{fontSize:"3px"}}>여성비율</text>
    <text class="text-title" x="101" y="10" style={{fontSize:"3px"}}>비례대표+지역구의원</text>
    <text class="text-title-secondrow" x="101" y="13.5" style={{fontSize:"3px"}}>여성비율</text>

	<path class="st0" d="M161.8571472,47.1831474c-4.9321136-11.2762756-16.1806488-19.1590729-29.2756958-19.1590729
		c-17.6427689,0-31.9450455,14.3022728-31.9450455,31.9450378h32.348793L161.8571472,47.1831474z"/>
	<path class="st1" d="M134.9225311,59.9691124h29.6039581c0-4.269619-0.8441467-8.3402443-2.3640442-12.0632172
		L134.9225311,59.9691124z"/>
	<path class="st1" d="M38.2867165,60.240551h31.5555611c0-17.642601-14.3021736-31.9448185-31.9447746-31.9448185
		c-0.3891678,0-0.7745857,0.0155087-1.1603851,0.0293121L38.2867165,60.240551z"/>
	<path class="st0" d="M35.9606323,28.3595734C19.2205849,29.3616352,5.952682,43.2488365,5.952682,60.240551h31.5558434
		L35.9606323,28.3595734z"/>
	<path class="st2" d="M134.9225311,59.9691124h29.603714c0-1.1244774-0.0596161-2.2349663-0.1729736-3.3294716
		L134.9225311,59.9691124z"/>
	<rect x="5.952682" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="8.2498617" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="10.5470419" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="12.8442221" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="15.1414022" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="17.4385815" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="19.7357616" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="22.0329418" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="24.330122" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="26.6273022" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="28.9244823" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="31.2216625" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="33.5188446" y="62.1042023" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="35.8160248" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="38.1556854" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="40.4528656" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="42.7500458" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="45.047226" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="47.3444061" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="49.6415863" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="51.9387665" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="54.2359467" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="56.5331268" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="58.830307" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="61.1274872" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="63.4246674" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="65.7218475" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="68.0190277" y="62.1042023" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="5.952682" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="8.2498617" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="10.5470419" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="12.8442221" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="15.1414022" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="17.4385815" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="19.7357616" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="22.0329418" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="24.330122" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="26.6273022" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="28.9244823" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="31.2216625" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="33.5188446" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="35.8160248" y="64.5449066" class="st0" width="1.8232473" height="1.8232473"/>
	<rect x="38.1556854" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="40.4528656" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="42.7500458" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="45.047226" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="47.3444061" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="49.6415863" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="51.9387665" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="54.2359467" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="56.5331268" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="58.830307" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="61.1274872" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="63.4246674" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="65.7218475" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<rect x="68.0190277" y="64.5449066" class="st1" width="1.8232473" height="1.8232473"/>
	<g>
		<rect x="100.6366501" y="62.1225128" class="st3" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="66.345047" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="67.7525558" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="69.1600647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="62.1225128" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="62.1225128" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="62.1225128" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="62.1225128" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="62.1225128" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="62.1225128" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="62.1225128" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="62.1225128" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="63.5300217" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="63.5300217" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="63.5300217" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="63.5300217" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="63.5300217" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="63.5300217" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="63.5300217" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="63.5300217" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="64.9375381" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="64.9375381" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="64.9375381" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="64.9375381" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="64.9375381" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="64.9375381" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="64.9375381" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="64.9375381" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="66.276123" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="66.276123" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="66.276123" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="66.276123" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="66.276123" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="66.276123" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="67.6836319" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="67.6836319" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="67.6836319" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="67.6836319" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="67.6836319" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="67.6836319" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="69.0911407" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="69.0911407" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="69.0911407" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="69.0911407" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="69.0911407" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="69.0911407" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="66.276123" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="67.6836319" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="69.0911407" class="st0" width="0.9802521" height="0.9802521"/>
	</g>
	<circle class="st1" cx="85.2397079" cy="44.2681427" r="0.4895037"/>
    <text class="text-year" x="82.2397079" y="40.2681427" style={{fontSize:"3px"}}>17대</text>

	<line class="st4" x1="74.6812134" y1="44.2681427" x2="95.7981949" y2="44.2681427"/>
</g>
<g>
	<path class="st0" d="M161.6231232,112.7608719c-5.0468292-10.9985657-16.1483002-18.6413574-29.0416718-18.6413574
		c-17.6427689,0-31.9450455,14.302269-31.9450455,31.9450378h32.3052902L161.6231232,112.7608719z"/>
	<path class="st1" d="M134.8047943,126.0645523h29.7216949c0-4.4710159-0.9217987-8.7257385-2.5804291-12.5893173
		L134.8047943,126.0645523z"/>
	<path class="st2" d="M134.8047943,126.0645523l29.7214508-0.0001068c0-1.6556549-0.1260681-3.2819061-0.3689117-4.8696365
		L134.8047943,126.0645523z"/>
	<path class="st1" d="M38.2861481,126.4900818h31.5561295c0-17.5122604-14.0934334-31.7257843-31.5561295-31.9349976V126.4900818z"
		/>
	<path class="st0" d="M37.5090485,94.5550842c-17.4627953,0.2091141-31.556366,14.4226913-31.556366,31.9349976h31.556366
		V94.5550842z"/>
	<rect x="5.952682" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="8.3355389" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="10.7183952" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="13.1012526" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="15.4841089" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="17.8669662" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="20.2498226" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="22.632679" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="25.0155373" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="27.3983936" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="29.78125" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="32.1641083" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="34.5469627" y="128.3966675" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="36.929821" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="39.3567467" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="41.7396011" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="44.1224594" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="46.5053139" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="48.8881721" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="51.2710304" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="53.6538849" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="56.0367432" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="58.4196014" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="60.8024559" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="63.1853142" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="65.5681686" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="67.9510269" y="128.3966675" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="5.952682" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="8.3355389" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="10.7183952" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="13.1012526" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="15.4841089" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="17.8669662" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="20.2498226" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="22.632679" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="25.0155373" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="27.3983936" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="29.78125" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="32.1641083" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="34.5469627" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="36.929821" y="130.9284058" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="39.3567467" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="41.7396011" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="44.1224594" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="46.5053139" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="48.8881721" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="51.2710304" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="53.6538849" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="56.0367432" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="58.4196014" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="60.8024559" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="63.1853142" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="65.5681686" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="67.9510269" y="130.9284058" class="st1" width="1.8912481" height="1.8912481"/>
	<g>
		<rect x="101.9205246" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="127.9263306" class="st3" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="132.1488647" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="133.5563812" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="134.9638824" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="127.9263306" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="127.9263306" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="127.9263306" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="127.9263306" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="127.9263306" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="127.9263306" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="127.9263306" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="127.9263306" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="129.333847" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="129.333847" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="129.333847" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="129.333847" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="129.333847" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="129.333847" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="129.333847" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="129.333847" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="130.7413635" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="130.7413635" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="130.7413635" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="130.7413635" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="130.7413635" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="130.7413635" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="130.7413635" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="130.7413635" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="132.0799408" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="132.0799408" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="132.0799408" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="132.0799408" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="132.0799408" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="132.0799408" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="132.0799408" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="133.4874573" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="133.4874573" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="133.4874573" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="133.4874573" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="133.4874573" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="133.4874573" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="133.4874573" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="134.8949585" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="134.8949585" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="134.8949585" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="134.8949585" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="134.8949585" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="134.8949585" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="132.0799408" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="133.4874573" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="134.8949585" class="st0" width="0.9802521" height="0.9802521"/>
	</g>
	<circle class="st1" cx="85.2397079" cy="110.0920334" r="0.4895037"/>
    <text class="text-year" x="82.2397079" y="106.0920334" style={{fontSize:"3px"}}>18대</text>

	<line class="st4" x1="74.6812134" y1="110.0920334" x2="95.7981949" y2="110.0920334"/>
</g>
<g>
	<path class="st0" d="M160.6913605,177.2634277c-5.4031067-9.97966-15.9645386-16.7581329-28.1099091-16.7581329
		c-17.6427689,0-31.9450455,14.3022614-31.9450455,31.9450378h31.6565475L160.6913605,177.2634277z"/>
	<path class="st1" d="M133.9552307,192.4503326h30.5712585c0-5.2180634-1.2591553-10.1390991-3.4777069-14.4891052
		L133.9552307,192.4503326z"/>
	<path class="st2" d="M133.9552307,192.4503326l30.5704498-0.0084686c-0.0195923-2.2951202-0.2266541-4.5296631-0.7050323-6.6836548
		L133.9552307,192.4503326z"/>
	<path class="st0" d="M35.8164444,161.1280823C19.1447906,162.2012482,5.952682,176.0554352,5.952682,192.9981079h31.5268631
		L35.8164444,161.1280823z"/>
	<path class="st1" d="M38.2580223,192.9981079h31.5842552c0-17.6425934-14.3021736-31.9448242-31.9447746-31.9448242
		c-0.4375954,0-0.8714409,0.015564-1.3047638,0.0329742L38.2580223,192.9981079z"/>
	<rect x="5.952682" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="8.3355389" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="10.7183952" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="13.1012526" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="15.4841089" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="17.8669662" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="20.2498226" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="22.632679" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="25.0155373" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="27.3983936" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="29.78125" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="32.1641083" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="34.5469627" y="194.8617554" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="36.929821" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="39.3567467" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="41.7396011" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="44.1224594" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="46.5053139" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="48.8881721" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="51.2710304" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="53.6538849" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="56.0367432" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="58.4196014" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="60.8024559" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="63.1853142" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="65.5681686" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="67.9510269" y="194.8617554" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="5.952682" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="8.3355389" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="10.7183952" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="13.1012526" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="15.4841089" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="17.8669662" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="20.2498226" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="22.632679" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="25.0155373" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="27.3983936" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="29.78125" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="32.1641083" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="34.5469627" y="197.3934937" class="st0" width="1.8912481" height="1.8912481"/>
	<rect x="36.929821" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="39.3567467" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="41.7396011" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="44.1224594" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="46.5053139" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="48.8881721" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="51.2710304" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="53.6538849" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="56.0367432" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="58.4196014" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="60.8024559" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="63.1853142" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="65.5681686" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<rect x="67.9510269" y="197.3934937" class="st1" width="1.8912481" height="1.8912481"/>
	<g>
		<rect x="101.9205246" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="198.6749878" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="200.0825043" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="201.4900055" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="194.4524536" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="194.4524536" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="194.4524536" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="194.4524536" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="194.4524536" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="194.4524536" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="194.4524536" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="194.4524536" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="194.4524536" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="195.8599701" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="195.8599701" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="195.8599701" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="195.8599701" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="195.8599701" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="195.8599701" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="195.8599701" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="195.8599701" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="195.8599701" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="197.2674713" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="197.2674713" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="197.2674713" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="197.2674713" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="197.2674713" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="197.2674713" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="197.2674713" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="197.2674713" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="197.2674713" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="198.6060638" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="198.6060638" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="198.6060638" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="198.6060638" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="198.6060638" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="198.6060638" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="198.6060638" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="198.6060638" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="200.0135803" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="200.0135803" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="200.0135803" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="200.0135803" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="200.0135803" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="200.0135803" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="200.0135803" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="200.0135803" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="201.4210815" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="201.4210815" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="201.4210815" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="201.4210815" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="201.4210815" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="201.4210815" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="201.4210815" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="198.6060638" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="200.0135803" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="201.4210815" class="st0" width="0.9802521" height="0.9802521"/>
	</g>
	<circle class="st1" cx="85.2397079" cy="175.9159241" r="0.4895037"/>
    <text class="text-year" x="82.2397079" y="171.9159241" style={{fontSize:"3px"}}>19대</text>

	<line class="st4" x1="74.6812134" y1="175.9159241" x2="95.7981949" y2="175.9159241"/>
</g>
<g>
	<path class="st0" d="M160.1016083,242.7418823c-5.5578918-9.4115601-15.7989349-15.7285614-27.5204468-15.7285614
		c-17.6427689,0-31.9450378,14.3022614-31.9450378,31.9450226h32.2541656L160.1016083,242.7418823z"/>
	<path class="st1" d="M134.4237976,258.9583435h30.1024017c0-5.6406708-1.4675751-10.9363251-4.0332489-15.5358276
		L134.4237976,258.9583435z"/>
	<path class="st2" d="M134.4237976,258.9583435l30.1018829-0.0004272c-0.0273285-3.2022247-0.4457703-6.2879181-1.3474731-9.1969604
		L134.4237976,258.9583435z"/>
	<path class="st1" d="M38.2025261,258.821991h31.6397514c0-17.6425934-14.3021736-31.944809-31.9447746-31.944809
		c-0.8786964,0-1.7463875,0.0451965-2.6071548,0.1147766L38.2025261,258.821991z"/>
	<path class="st0" d="M34.5157127,227.0568085C18.4632645,228.7462769,5.952682,242.3219604,5.952682,258.821991h31.4692841
		L34.5157127,227.0568085z"/>
	<rect x="5.952682" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="8.635745" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="11.3188086" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="14.0018721" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="16.6849346" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="19.3679981" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="22.0510616" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="24.7341251" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="27.4171886" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="30.1002502" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="32.7833138" y="260.6856689" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="38.1990623" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="40.882122" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="43.5651855" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="46.2482491" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="48.9313126" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="51.6143761" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="54.2974396" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="56.9805031" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="59.6635666" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="62.3466301" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="65.0296936" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="67.7127533" y="260.6856689" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="5.952682" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="8.635745" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="11.3188086" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="14.0018721" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="16.6849346" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="19.3679981" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="22.0510616" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="24.7341251" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="27.4171886" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="30.1002502" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="32.7833138" y="263.5363464" class="st0" width="2.1295187" height="2.1295187"/>
	<rect x="35.4663773" y="262.1109924" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="38.1990623" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="40.882122" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="43.5651855" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="46.2482491" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="48.9313126" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="51.6143761" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="54.2974396" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="56.9805031" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="59.6635666" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="62.3466301" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="65.0296936" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<rect x="67.7127533" y="263.5363464" class="st1" width="2.1295187" height="2.1295187"/>
	<g>
		<rect x="101.9205246" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="265.1806641" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="266.5881958" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="100.6366501" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="101.9205246" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="103.2043991" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="104.4882736" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="105.7721481" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="107.0560226" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="108.3398972" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="109.6237717" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="110.9076385" y="267.995697" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="260.9581299" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="260.9581299" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="260.9581299" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="260.9581299" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="260.9581299" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="260.9581299" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="260.9581299" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="260.9581299" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="260.9581299" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="260.9581299" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="262.3656616" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="262.3656616" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="262.3656616" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="262.3656616" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="262.3656616" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="262.3656616" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="262.3656616" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="262.3656616" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="262.3656616" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="262.3656616" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="263.7731628" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="263.7731628" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="263.7731628" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="263.7731628" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="263.7731628" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="263.7731628" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="263.7731628" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="263.7731628" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="263.7731628" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="263.7731628" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="125.0302582" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="126.3141327" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="127.5980072" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="128.8818817" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="130.1657562" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="131.4496307" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="132.7335052" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="134.0173798" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="135.3012543" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="136.5851288" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="137.8690033" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="139.1528778" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="140.4367371" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="141.7206116" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="143.0044861" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="144.2883606" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="145.5722351" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="146.8561096" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="148.1399841" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="149.4238586" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="150.7077332" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="265.1117554" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="265.1117554" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="265.1117554" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="265.1117554" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="265.1117554" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="265.1117554" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="265.1117554" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="265.1117554" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="266.5192566" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="266.5192566" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="266.5192566" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="266.5192566" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="266.5192566" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="266.5192566" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="266.5192566" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="266.5192566" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="151.9916077" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="153.2754822" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="154.5593567" y="267.9267883" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="155.8432312" y="267.9267883" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="157.1271057" y="267.9267883" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="158.4109802" y="267.9267883" class="st2" width="0.9802521" height="0.9802521"/>
		<rect x="159.6948547" y="267.9267883" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="160.9787292" y="267.9267883" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="162.2626038" y="267.9267883" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="163.5464783" y="267.9267883" class="st1" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="265.1117554" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="266.5192566" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="112.1915131" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="113.4753876" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="114.7592621" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="116.0431366" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="117.3270111" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="118.6108856" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="119.8947601" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="121.1786346" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="122.4625092" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
		<rect x="123.7463837" y="267.9267883" class="st0" width="0.9802521" height="0.9802521"/>
	</g>
	<circle class="st1" cx="85.2397079" cy="241.7398071" r="0.4895037"/>
    <text class="text-year" x="82.2397079" y="237.7398071" style={{fontSize:"3px"}}>20대</text>

	<line class="st4" x1="74.6812134" y1="241.7398071" x2="95.7981949" y2="241.7398071"/>
</g>
<polygon   class="st1 poly" points="74.6812134,48.7409096 73.9518204,49.2523079 74.6812134,49.76371 "/>
<text class="text-info-1a" x="75.3449631" y="49.9523155" style={{fontSize:"2px"}}>51.79%, 56명 중 29명</text>

<polygon   class="st1 poly" points="95.9966202,56.2003059 96.7260056,56.7117043 95.9966202,57.2231064 "/>
<text class="text-info-1b" x="75.3449631" y="57.223114" style={{fontSize:"2px"}}>13.04%, 299명 중 39명</text>

<polygon   class="st1 poly" points="74.6812134,114.5328827 73.9518204,115.044281 74.6812134,115.5556793 "/>
<text class="text-info-2a" x="75.3449631" y="115.5556793" style={{fontSize:"2px"}}>50.00%, 54명 중 27명</text>

<polygon   class="st1 poly" points="95.4335022,121.9922867 96.1628876,122.503685 95.4335022,123.0150833 "/>
<text class="text-info-2b" x="75.3449631" y="123.0150833" style={{fontSize:"2px"}}>13.71%, 299명 중 41명</text>

<polygon   class="st1 poly" points="74.6812134,180.356781 73.9518204,180.8681793 74.6812134,181.3795776 "/>
<text class="text-info-3a" x="75.3449631" y="181.3795776" style={{fontSize:"2px"}}>51.85%, 54명 중 28명</text>

<polygon   class="st1 poly" points="95.9966202,187.8161774 96.7260056,188.3275757 95.9966202,188.8389893 "/>
<text class="text-info-3b" x="75.3449631" y="188.8389893" style={{fontSize:"2px"}}>15.67%, 300명 중 47명</text>

<polygon   class="st1 poly" points="74.6812134,246.1806641 73.9518204,246.6920624 74.6812134,247.2034607 "/>
<text class="text-info-4a" x="75.3449631" y="247.2034607" style={{fontSize:"2px"}}>53.19%, 47명 중 25명</text>

<polygon   class="st1 poly" points="95.4335022,253.6400604 96.1628876,254.1514587 95.4335022,254.6628723 "/>
<text class="text-info-4b" x="75.3449631" y="254.6628723" style={{fontSize:"2px"}}>17.00%, 300명 중 51명</text>
</svg>

        </SvgDiv>
      );
    }
  }
  