import React, { PureComponent } from 'react';
import styled from "@emotion/styled";

const SvgDiv = styled.div`
.st0{fill:none;stroke:#DCDDDD;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}
.st1{fill:#D9D9D9;}
.st2{fill:#A1A0A1;}
.st3{fill:#D4BDDB;}
.st4{fill:#6B4B9C;}
.st5{fill:none;stroke:#898989;stroke-width:0.75;stroke-linecap:round;stroke-miterlimit:10;}
.st6{fill:#898989;stroke:#898989;stroke-width:0.75;stroke-linecap:round;stroke-miterlimit:10;}
.st7,.st8,.st9{fill:#9d9d9d;}

@media screen and (max-width: 600px) {
  .hideit { visibility: hidden; }
  .text-unit { transform: translateY(-1px); }
  .text-unit, .text-mob { font-size: 5px !important; }
  .leg-1, .leg-2, .leg-3, .leg-4 { font-size: 5px !important; }
  .leg-1 { transform: translateX(-15px); }
  .leg-2 { transform: translateX(-5px); }
  .leg-3 { transform: translateX(5px); }
  .leg-4 { transform: translateX(15px); }
  .legend-wrapper { transform: translateX(-8px); }
  .year-mobile { transform: translateY(3px); font-size: 5px !important; }
  .text-info-a, .text-info-b { font-size: 6px !important; }
  .text-info-b { transform: translateY(6px); }
} 
`;


export default class Example extends PureComponent {
    render() {
      return (
        <SvgDiv>
           <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px"y="0px" viewBox="0 0 261.7872009 1270.3579102" >
        <g>
            <line class="st0" x1="14.5067482" y1="9.848464" x2="246.283905" y2="9.848464"/>
            <text class="st8 text-unit" x="2.2189312" y="4.848464" style={{fontSize:"3px"}}>(단위: 명)</text>
            <text class="st8 text-mob" x="2.2189312" y="10.848464" style={{fontSize:"3px"}}>2,300</text>
            <line class="st0" x1="14.5067482" y1="170.8119812" x2="246.283905" y2="170.8119812"/>
            <text class="st8 text-mob" x="2.2189312" y="171.8119812" style={{fontSize:"3px"}}>2,000</text>
            <line class="st0" x1="14.5067482" y1="277.6654663" x2="246.283905" y2="277.6654663"/>
            <text class="st8 text-mob" x="2.2189312" y="278.6654663" style={{fontSize:"3px"}}>1,800</text>
            <line class="st0" x1="14.5067482" y1="438.5201416" x2="246.283905" y2="438.5201416"/>
            <text class="st8 text-mob" x="2.2189312" y="439.5201416" style={{fontSize:"3px"}}>1,500</text>
            <line class="st0" x1="14.5067482" y1="760.1908569" x2="246.283905" y2="760.1908569"/>
            <text class="st8 text-mob" x="2.2189312" y="761.1908569" style={{fontSize:"3px"}}>900</text>
            <line class="st0" x1="14.5067482" y1="921.0668335" x2="246.283905" y2="921.0668335"/>
            <text class="st8 text-mob" x="2.2189312" y="922.0668335" style={{fontSize:"3px"}}>600</text>
            <line class="st0" x1="14.5067482" y1="1081.769043" x2="246.283905" y2="1081.769043"/>
            <text class="st8 text-mob" x="2.2189312" y="1082.769043" style={{fontSize:"3px"}}>300</text>
            <line class="st0" x1="14.5067482" y1="1162.3413086" x2="246.283905" y2="1162.3413086"/>
            <text class="st8 text-mob" x="2.2189312" y="1163.3413086" style={{fontSize:"3px"}}>150</text>
            <line class="st0" x1="14.5067482" y1="1189.0748291" x2="246.283905" y2="1189.0748291"/>
            <text class="st8 text-mob" x="2.2189312" y="1190.0748291" style={{fontSize:"3px"}}>100</text>
            <line class="st0" x1="14.5067482" y1="1202.5631104" x2="246.283905" y2="1202.5631104"/>
            <text class="st8 text-mob" x="2.2189312" y="1203.5631104" style={{fontSize:"3px"}}>75</text>
            <line class="st0" x1="14.5067482" y1="1215.8964844" x2="246.283905" y2="1215.8964844"/>
            <text class="st8 text-mob" x="2.2189312" y="1216.8964844" style={{fontSize:"3px"}}>50</text>
            <line class="st0" x1="14.5067482" y1="1229.3966064" x2="246.283905" y2="1229.3966064"/>
            <text class="st8 text-mob" x="2.2189312" y="1230.3966064" style={{fontSize:"3px"}}>25</text>
            <line class="st0" x1="14.5067482" y1="1242.6914062" x2="246.283905" y2="1242.6914062"/>
            <text class="st10 hideit" x="2.2189312" y="1243.6914062" style={{fontSize:"3px"}}>국회선거</text>
        </g>
        <g>
            <rect x="16.2189312" y="734.6367188" class="st1" width="2.4531479" height="508.0547485"/>
            <rect x="149.1370087" y="683.7327271" class="st1" width="2.4531479" height="558.9587402"/>
            <rect x="159.1002197" y="679.397522" class="st1" width="2.4531479" height="563.2939453"/>
            <rect x="175.7035828" y="499.8787842" class="st1" width="2.4531479" height="742.8126221"/>
            <rect x="187.7599487" y="686.3591309" class="st1" width="2.4531479" height="556.3322754"/>
            <rect x="215.5530853" y="646.1965942" class="st1" width="2.4531479" height="596.494812"/>
            <rect x="202.2694702" y="617.2633667" class="st1" width="2.4531479" height="625.4281006"/>
            <rect x="49.440979" y="776.5338745" class="st1" width="2.4531479" height="466.1575623"/>
            <rect x="228.8367004" y="759.3013306" class="st1" width="2.4531479" height="483.3901367"/>
            <rect x="242.1203156" y="742.1107178" class="st1" width="2.4531479" height="500.5806885"/>
            <rect x="65.9838028" y="788.7432861" class="st1" width="2.4531479" height="453.9481506"/>
            <rect x="79.3041229" y="866.5264893" class="st1" width="2.4531479" height="376.1649475"/>
            <rect x="92.6244354" y="935.1195068" class="st1" width="2.4531479" height="307.5718994"/>
            <rect x="99.2688293" y="1061.6712646" class="st1" width="2.4531479" height="181.0201416"/>
            <rect x="115.8393555" y="989.7476196" class="st1" width="2.4531479" height="252.9438477"/>
            <rect x="138.8229218" y="1006.8283081" class="st1" width="2.4531479" height="235.8630981"/>
            <rect x="125.9373779" y="902.9126587" class="st1" width="2.4531479" height="339.7787781"/>
            <rect x="36.1514931" y="551.2998047" class="st1" width="2.4531479" height="691.3916626"/>
            <rect x="56.084053" y="407.1160278" class="st1" width="2.4531479" height="835.5754395"/>
            <rect x="22.8633404" y="59.8201675" class="st1" width="2.4531479" height="1182.8712158"/>
            <rect x="22.8633404" y="1134.5009766" class="st2" width="2.4531479" height="108.190506"/>
            <rect x="65.9838028" y="1173.0661621" class="st2" width="2.4531479" height="69.625267"/>
            <rect x="79.3041229" y="1172.5222168" class="st2" width="2.4531479" height="70.16922"/>
            <rect x="92.6244354" y="1160.6795654" class="st2" width="2.4531479" height="82.0118179"/>
            <rect x="99.2688293" y="1165.4671631" class="st2" width="2.4531479" height="77.2242889"/>
            <rect x="115.8393555" y="1160.2484131" class="st2" width="2.4531479" height="82.4430237"/>
            <rect x="125.9373779" y="1144.6942139" class="st2" width="2.4531479" height="97.9972458"/>
            <rect x="138.8229218" y="1145.2198486" class="st2" width="2.4531479" height="97.4715729"/>
            <rect x="187.7599487" y="1123.7768555" class="st2" width="2.4531479" height="118.9146118"/>
            <rect x="202.2694702" y="1117.9118652" class="st2" width="2.4531479" height="124.7795486"/>
            <rect x="215.5530853" y="1118.946167" class="st2" width="2.4531479" height="123.7452774"/>
            <rect x="228.8367004" y="1121.0876465" class="st2" width="2.4531479" height="121.6038055"/>
            <rect x="242.1203156" y="1121.0876465" class="st2" width="2.4531479" height="121.6038055"/>
            <rect x="149.1370087" y="1122.6132812" class="st2" width="2.4531479" height="120.0781174"/>
            <rect x="159.1002197" y="1115.6765137" class="st2" width="2.4531479" height="127.014946"/>
            <rect x="175.7035828" y="1108.1920166" class="st2" width="2.4531479" height="134.4994202"/>
            <rect x="36.1514931" y="1134.5009766" class="st2" width="2.4531479" height="108.190506"/>
            <rect x="49.440979" y="1119.9388428" class="st2" width="2.4531479" height="122.7526169"/>
            <rect x="56.084053" y="1118.3713379" class="st2" width="2.4531479" height="124.320137"/>
            <rect x="16.2189312" y="1135.4581299" class="st2" width="2.4531479" height="107.2333069"/>
            <rect x="16.2189312" y="1232.496582" class="st3" width="2.4531479" height="10.1949005"/>
            <text class="st9 year-mobile" x="15.2189312" y="1246.6914062" style={{fontSize:"3px"}}>1대</text>
            <rect x="22.8633404" y="1238.3986816" class="st3" width="2.4531479" height="4.2927189"/>
            <rect x="22.8633404" y="1241.6082764" class="st4" width="2.4531479" height="1.0832082"/>
            <rect x="36.1514931" y="1238.3986816" class="st3" width="2.4531479" height="4.2927189"/>
            <rect x="36.1514931" y="1242.1497803" class="st4" width="2.4531479" height="0.5416041"/>
            <rect x="49.440979" y="1239.5443115" class="st3" width="2.4531479" height="3.1470957"/>
            <rect x="49.440979" y="1241.1159668" class="st4" width="2.4531479" height="1.575532"/>
            <rect x="56.084053" y="1240.0075684" class="st3" width="2.4531479" height="2.6838903"/>
            <text class="st9 year-mobile" x="55.084053" y="1246.6914062" style={{fontSize:"3px"}}>5대</text>
            <rect x="56.084053" y="1242.1497803" class="st4" width="2.4531479" height="0.5416041"/>
            <rect x="65.9838028" y="1241.1151123" class="st3" width="2.4531479" height="1.5762696"/>
            <rect x="65.9838028" y="1242.1497803" class="st4" width="2.4531479" height="0.5416041"/>
            <rect x="79.3041229" y="1240.5695801" class="st3" width="2.4531479" height="2.1218071"/>
            <rect x="92.6244354" y="1242.234375" class="st3" width="2.4531479" height="0.4570701"/>
            <rect x="99.2688293" y="1241.6794434" class="st3" width="2.4531479" height="1.0119727"/>
            <rect x="99.2688293" y="1241.6673584" class="st4" width="2.4531479" height="1.0240982"/>
            <rect x="115.8393555" y="1240.5471191" class="st3" width="2.4531479" height="2.1443596"/>
            <text class="st9 year-mobile" x="114.8393555" y="1246.6914062" style={{fontSize:"3px"}}>10대</text>
            <rect x="125.9373779" y="1237.8806152" class="st3" width="2.4531479" height="4.8107624"/>
            <rect x="125.9373779" y="1242.1497803" class="st4" width="2.4531479" height="0.5416041"/>
            <rect x="138.8229218" y="1239.5800781" class="st3" width="2.4531479" height="3.1113527"/>
            <rect x="138.8229218" y="1241.6673584" class="st4" width="2.4531479" height="1.0240982"/>
            <rect x="149.1370087" y="1235.2177734" class="st3" width="2.4531479" height="7.4736938"/>
            <rect x="159.1002197" y="1232.4906006" class="st3" width="2.4531479" height="10.200861"/>
            <rect x="175.7035828" y="1231.5367432" class="st3" width="2.4531479" height="11.1546431"/>
            <text class="st9 year-mobile" x="174.7035828" y="1246.6914062" style={{fontSize:"3px"}}>15대</text>
            <rect x="175.7035828" y="1241.6673584" class="st4" width="2.4531479" height="1.0240982"/>
            <rect x="187.7599487" y="1225.008667" class="st3" width="2.4531479" height="17.6828289"/>
            <rect x="187.7599487" y="1240.0119629" class="st4" width="2.4531479" height="2.6794152"/>
            <rect x="202.2694702" y="1207.8684082" class="st3" width="2.4531479" height="34.822998"/>
            <rect x="202.2694702" y="1237.3430176" class="st4" width="2.4531479" height="5.3483763"/>
            <rect x="215.5530853" y="1171.9840088" class="st3" width="2.4531479" height="70.7074509"/>
            <rect x="215.5530853" y="1235.2280273" class="st4" width="2.4531479" height="7.4634252"/>
            <rect x="228.8367004" y="1208.9760742" class="st3" width="2.4531479" height="33.7154045"/>
            <rect x="228.8367004" y="1232.5140381" class="st4" width="2.4531479" height="10.177371"/>
            <rect x="242.1203156" y="1190.2376709" class="st3" width="2.4531479" height="52.4537239"/>
            <text class="st9 year-mobile" x="241.1203156" y="1246.6914062" style={{fontSize:"3px"}}>20대</text>
            <rect x="242.1203156" y="1228.78479" class="st4" width="2.4531479" height="13.906683"/>
            <g class="hideit">
                <line class="st5" x1="16.2189312" y1="1252.2043457" x2="245.5682526" y2="1252.2043457"/>
                <text class="st10" x="2.2189312" y="1253.2043457" style={{fontSize:"3px"}}>정권</text>
                <circle class="st6" cx="16.2189312" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="13.2189312" y="1258.2043457" style={{fontSize:"3px"}}>'48</text>
                <circle class="st6" cx="56.084053" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="53.084053" y="1258.2043457" style={{fontSize:"3px"}}>'60</text>
                <circle class="st6" cx="59.4729729" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="58.4729729" y="1258.2043457" style={{fontSize:"3px"}}>'61</text>
                <circle class="st6" cx="65.9838028" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="63.9838028" y="1258.2043457" style={{fontSize:"3px"}}>'63</text>
                <circle class="st6" cx="95.9547195" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="93.9547195" y="1258.2043457" style={{fontSize:"3px"}}>'72</text>
                <circle class="st6" cx="119.1543579" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="115.1543579" y="1258.2043457" style={{fontSize:"3px"}}>'79</text>
                <circle class="st6" cx="122.5458679" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="120.5458679" y="1258.2043457" style={{fontSize:"3px"}}>'80</text>
                <circle class="st6" cx="125.9373779" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="125.9373779" y="1258.2043457" style={{fontSize:"3px"}}>'81</text>
                <circle class="st6" cx="149.1370087" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="147.1370087" y="1258.2043457" style={{fontSize:"3px"}}>'88</text>
                <circle class="st6" cx="165.7410278" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="163.7410278" y="1258.2043457" style={{fontSize:"3px"}}>'93</text>
                <circle class="st6" cx="182.345047" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="180.345047" y="1258.2043457" style={{fontSize:"3px"}}>'98</text>
                <circle class="st6" cx="198.9490662" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="196.9490662" y="1258.2043457" style={{fontSize:"3px"}}>'03</text>
                <circle class="st6" cx="215.5530853" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="213.5530853" y="1258.2043457" style={{fontSize:"3px"}}>'08</text>
                <circle class="st6" cx="232.1571045" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="230.1571045" y="1258.2043457" style={{fontSize:"3px"}}>'13</text>
                <circle class="st6" cx="245.5682526" cy="1252.2043457" r="0.7156528"/>
                <text class="st7" x="243.5682526" y="1258.2043457" style={{fontSize:"3px"}}>'17</text>
            </g>
        </g>
        <g>
	<path d="M28.4023438,55.972168c-2.2277222,0-3.7039185,1.1074219-4.2855225,3.1943359
		c-0.0092163-0.0004883-0.0176392-0.0029297-0.0269775-0.0029297c-0.3623047,0-0.6567383,0.2944336-0.6567383,0.6567383
		s0.2944336,0.6567383,0.6567383,0.6567383s0.6567383-0.2944336,0.6567383-0.6567383
		c0-0.2598877-0.1600342-0.4848633-0.3883667-0.5895996c0.5549316-1.9926758,1.9116211-3.0085449,4.0441284-3.0085449
		c0.0688477,0,0.125-0.0561523,0.125-0.125S28.4711914,55.972168,28.4023438,55.972168z M24.496582,59.8203125
		c0,0.2241211-0.1826172,0.4067383-0.4067383,0.4067383s-0.4067383-0.1826172-0.4067383-0.4067383
		s0.1826172-0.4067383,0.4067383-0.4067383c0.0302734,0,0.0595703,0.0029297,0.0874023,0.0092773
		C24.3623047,59.4628906,24.496582,59.6303711,24.496582,59.8203125z"/>
    <text class="text-info-a" x="29" y="53" style={{fontSize:"2.5px"}}>2대 선거에 2,207명으로</text>
    <text class="text-info-b" x="29" y="56.5" style={{fontSize:"2.5px"}}>가장 많은 후보자가 등록</text>

	<path class="hideit" d="M219.2705078,1168.625c-1.2172852,0.4572754-2.0343628,1.4300537-2.4429932,2.8745117
		c-0.0099487-0.0004883-0.020813-0.003418-0.0306396-0.003418c0,0-0.0009766,0-0.0019531,0
		c-0.1738281,0-0.3378906,0.0673828-0.4619141,0.1904297c-0.125,0.1230469-0.1933594,0.2880859-0.1943359,0.4628906
		c-0.0009766,0.1757812,0.0664062,0.3408203,0.1894531,0.4658203c0.1240234,0.125,0.2890625,0.1933594,0.4648438,0.1943359
		c0,0,0.0009766,0,0.0019531,0c0.3603516,0,0.6552734-0.2929688,0.6572266-0.6533203v-0.0009766
		c0.0007935-0.2578125-0.1560669-0.4821777-0.3834229-0.5891113c0.3857422-1.3635254,1.1515503-2.2794189,2.2896729-2.7067871
		c0.0644531-0.0244141,0.0976562-0.0966797,0.0732422-0.1611328S219.3359375,1168.6035156,219.2705078,1168.625z
		 M217.2021484,1172.1542969c-0.0019531,0.2236328-0.1845703,0.4052734-0.4072266,0.4052734c-0.0009766,0-0.0009766,0-0.0019531,0
		c-0.1083984,0-0.2109375-0.0429688-0.2871094-0.1201172s-0.1181641-0.1796875-0.1171875-0.2890625
		c0-0.1083984,0.0429688-0.2099609,0.1201172-0.2861328s0.1787109-0.1181641,0.2871094-0.1181641h0.0009766
		c0.0273438,0,0.0566406,0.0029297,0.0869141,0.0097656C217.0693359,1171.796875,217.203125,1171.9648438,217.2021484,1172.1542969z
		"/>
    <text class="hideit" x="103.5" y="1234.5" style={{fontSize:"2.5px"}}>9대, 2명</text>
    <text class="hideit" x="103.5" y="1238" style={{fontSize:"2.5px"}}>모두 당선</text>
	<path class="hideit" d="M102.9550781,1238.1972656c-1.2182007,0.4591064-2.034729,1.4310303-2.4434204,2.8745117
		c-0.0102539-0.0006104-0.019104-0.0031738-0.0297241-0.003418c-0.0009766,0-0.0014648,0-0.0024414,0
		c-0.1743164,0-0.3388672,0.0673828-0.4628906,0.1904297c-0.1245117,0.1240234-0.1933594,0.2890625-0.1938477,0.4648438
		c-0.0004883,0.1748047,0.0668945,0.3398438,0.1904297,0.4638672c0.1235352,0.125,0.2885742,0.1933594,0.4633789,0.1943359
		c0.0014648,0,0.0029297,0,0.0043945,0c0.1733398,0,0.3364258-0.0673828,0.4599609-0.1894531
		c0.125-0.1230469,0.1943359-0.2880859,0.1953125-0.4638672v-0.0009766c0.0007935-0.2570801-0.15625-0.4814453-0.3834839-0.5888672
		c0.3856812-1.362793,1.151123-2.2788086,2.2902222-2.7070312c0.0644531-0.0244141,0.097168-0.0966797,0.0732422-0.1611328
		C103.0917969,1238.2060547,103.0200195,1238.1748047,102.9550781,1238.1972656z M100.8862305,1241.7265625
		c-0.0004883,0.109375-0.043457,0.2119141-0.1210938,0.2880859c-0.0761719,0.0761719-0.1767578,0.1171875-0.2841797,0.1171875
		c-0.121582,0.0048828-0.2128906-0.0419922-0.2900391-0.1201172c-0.0766602-0.0771484-0.1186523-0.1796875-0.1181641-0.2880859
		s0.0429688-0.2099609,0.1201172-0.2871094c0.0771484-0.0761719,0.1787109-0.1181641,0.2841797-0.1181641
		c0.0004883,0,0.0009766,0,0.0014648,0c0.0307617,0.0009766,0.0615234,0.0039062,0.0893555,0.0097656
		C100.7529297,1241.3701172,100.8867188,1241.5371094,100.8862305,1241.7265625z"/>
    <text class="hideit" x="219.7" y="1166.5" style={{fontSize:"2.5px"}}>132명</text>
    <text class="hideit" x="219.7" y="1170" style={{fontSize:"2.5px"}}>여성후보</text>
    <text class="hideit" x="219.7" y="1173.5" style={{fontSize:"2.5px"}}>최다등록</text>
	<path class="hideit" d="M243.3701172,1228.1035156c0,0-0.0009766,0-0.0019531,0c-0.0094604,0-0.0200806,0.0029297-0.0297241,0.0032959
		c-0.4086914-1.4443359-1.225708-2.4172363-2.4439087-2.8743896c-0.0673828-0.0234375-0.1376953,0.0087891-0.1611328,0.0732422
		c-0.0244141,0.0644531,0.0087891,0.1367188,0.0732422,0.1611328c1.138855,0.4272461,1.9046021,1.3430176,2.2904053,2.7061768
		c-0.2266846,0.1063232-0.3839722,0.331543-0.3831787,0.5897217c0.0009766,0.1757812,0.0693359,0.3398438,0.1943359,0.4638672
		c0.1240234,0.1230469,0.2880859,0.1904297,0.4619141,0.1904297c0.0009766,0,0.0019531,0,0.0019531,0
		c0.1757812-0.0009766,0.3408203-0.0693359,0.4648438-0.1943359c0.1230469-0.1240234,0.1914062-0.2900391,0.1904297-0.4648438
		C244.0263672,1228.3964844,243.7314453,1228.1035156,243.3701172,1228.1035156z M243.3720703,1229.1669922
		c-0.0009766,0-0.0009766,0-0.0019531,0c-0.1074219,0-0.2089844-0.0419922-0.2861328-0.1181641
		s-0.1201172-0.1787109-0.1201172-0.2861328c-0.0009766-0.1904297,0.1328125-0.3583984,0.3173828-0.3994141
		c0.03125-0.0068359,0.0703125-0.0039062,0.0888672-0.0097656c0.2236328,0,0.40625,0.1816406,0.4072266,0.4042969
		C243.7783203,1228.9824219,243.5957031,1229.1660156,243.3720703,1229.1669922z"/>
    <text class="hideit" x="232" y="1222" style={{fontSize:"2.5px"}}>26명</text>
    <text class="hideit" x="232" y="1226" style={{fontSize:"2.5px"}}>최다당선</text>
</g>
<g class="legend-wrapper">
<rect x="85.5067482" y="1266.2043457" class="st1 leg-1" width="4.1299424" height="2.2524774"/>
<text class="leg-1" x="91.5067482" y="1268.4043457" style={{fontSize:"3px"}}>전체 후보자</text>

<rect x="111.4623718" y="1266.2043457" class="st2 leg-2" width="4.1299424" height="2.2524774"/>
<text class="leg-2" x="117.4623718" y="1268.4043457" style={{fontSize:"3px"}}>전체 당선자</text>

<rect x="137.4179955" y="1266.2043457" class="st3 leg-3" width="4.1299424" height="2.2524774"/>
<text class="leg-3" x="143.4179955" y="1268.4043457" style={{fontSize:"3px"}}>여성 후보자</text>

<rect x="163.3736229" y="1266.2043457" class="st4 leg-4" width="4.1299424" height="2.2524774"/>
<text class="leg-4" x="169.3736229" y="1268.4043457" style={{fontSize:"3px"}}>여성 당선자</text>
</g>
        </svg>
        </SvgDiv>
      );
    }
  }
  