import Sig from "../../../../content/posts/2020-05-07-21st-assembly/Sigmap.js";
import RpB from "../../../../content/posts/2020-05-07-21st-assembly/5reportb.js";
import RpC from "../../../../content/posts/2020-05-07-21st-assembly/5reportc.js";
import RpD from "../../../../content/posts/2020-05-07-21st-assembly/5reportd.js";
import RpE from "../../../../content/posts/2020-05-07-21st-assembly/5reporte.js";
import React from 'react';
export default {
  Sig,
  RpB,
  RpC,
  RpD,
  RpE,
  React
};