import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell
} from 'recharts';

const data02 = [
  { name: '경력', value: 1430 },
  { name: '신입', value: 641 },
  { name: '인턴', value: 154 },
];

const COLORS = ['#f15a22', '#f8a185', '#f9cbbe'];

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/k9jkog04/';

  render() {
    return (
      <ResponsiveContainer width='100%' height={300}>
      <PieChart width={400} height={400}>
        <Pie dataKey="value" data={data02} 
        innerRadius={40} outerRadius={80} label fill="#ffc658">
          {data02.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
        </Pie>
        <Legend></Legend>
        <Tooltip />
      </PieChart>
      </ResponsiveContainer>
    );
  }
}
