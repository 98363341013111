import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell
} from 'recharts';

const data = [
                    
  {   
      name: "서울",
      data: 301,
      color: "#f15a22"
  },
  {
      name: "경기",
      data: 24,
      color: "#f7a48a"
  },
  {
      name: "제주",
      data: 6,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "부산",
      data: 3,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "전라",
      data: 3,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "인천",
      data: 2,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "대전",
      data: 2,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "충청",
      data: 1,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "경상",
      data: 1,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "대구",
      data: 1,
      color: "rgba(55,55,55,0.2)"
  }
];

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width='100%' height={340}>
      <BarChart data={data}  margin={{top: 5, right: 30, left: 20, bottom: 5}} layout="vertical">
       <CartesianGrid strokeDasharray="3 3"/>
       <YAxis dataKey="name" type="category"/>
       <XAxis type="number"/>
       <Tooltip/>
       <Bar dataKey="data" fill="#f15a22" barSize={12}>
        {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry['color']} />)} 
        </Bar> />
      </BarChart>
      </ResponsiveContainer>
    );
  }
}
