import { Bar, Line } from 'react-chartjs-2';
import MonthlyAreaChart from "../../../../content/posts/2020-02-07-social-jobs-2019/MonthlyAreaChart.js";
import CareerPieChart from "../../../../content/posts/2020-02-07-social-jobs-2019/CareerPieChart.js";
import SeoulMap from "../../../../content/posts/2020-02-07-social-jobs-2019/SeoulMap.js";
import KoreaBarChart from "../../../../content/posts/2020-02-07-social-jobs-2019/KoreaBarChart.js";
import JobTreeMap from "../../../../content/posts/2020-02-07-social-jobs-2019/JobTreeMap.js";
import JobBarChart from "../../../../content/posts/2020-02-07-social-jobs-2019/JobBarChart.js";
import React from 'react';
export default {
  Bar,
  Line,
  MonthlyAreaChart,
  CareerPieChart,
  SeoulMap,
  KoreaBarChart,
  JobTreeMap,
  JobBarChart,
  React
};