import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell
} from 'recharts';

const data = [
                    
  {   
      name: "경력",
      data: 360,
      color: "#f15a22"
  },
  {
      name: "신입/경력",
      data: 338,
      color: "#f7a48a"
  },
  {
      name: "인턴",
      data: 64,
      color: "#f7a48a"
  },
  {
      name: "신입",
      data: 12,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "인턴/신입",
      data: 2,
      color: "rgba(55,55,55,0.2)"
  },
  {
      name: "인턴/신입/경력",
      data: 2,
      color: "rgba(55,55,55,0.2)"
  }
];

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width='100%' height={340}>
      <BarChart data={data}  margin={{top: 5, right: 30, left: 20, bottom: 5}} layout="vertical">
       <CartesianGrid strokeDasharray="3 3"/>
       <YAxis dataKey="name" type="category"/>
       <XAxis type="number"/>
       <Tooltip/>
       <Bar dataKey="data" fill="#f15a22" barSize={12}>
        {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry['color']} />)} 
        </Bar> />
      </BarChart>
      </ResponsiveContainer>
    );
  }
}
