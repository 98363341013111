import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

const data = [
  {name: 'Jan', count: 86, },
  {name: 'Feb', count: 32, },
  {name: 'Mar', count: 80, },
  {name: 'Apr', count: 68, },
  {name: 'May', count: 52, },
  {name: 'Jun', count: 85, },
  {name: 'Jul', count: 115, },
  {name: 'Aug', count: 52, },
  {name: 'Sep', count: 54, },
  {name: 'Oct', count: 31, },
  {name: 'Nov', count: 65, },
  {name: 'Dec', count: 56, }
];

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width='100%' height={300}>
        <AreaChart data={data}
          margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="count" stroke="#f15a22" fill="#f9cbbe" />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
