
import React, { PureComponent } from 'react';

export default class RpE extends PureComponent {

render() {
      return (

	  <div style={{ margin:"auto", maxWidth:"680px", width:"100%" }}>
<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 210 67.1" style={{enableBackground:"new 0 0 210 67.1"}} >


<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" enableBackground="new" x1="3.1" y1="41.9" x2="206.9" y2="41.9"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" enableBackground="new" x1="3.1" y1="52.4" x2="206.9" y2="52.4"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,56.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,58.8,122.4,56.1,121.7,56.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,57.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,56.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,58.8,125,56.1,124.3,56.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,57.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,56.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,58.8,150.8,56.1,150.1,56.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,57.2c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.5,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.3,17.4,94.1,14.8,93.5,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.1,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M96.1,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.9,17.4,96.7,14.8,96.1,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.7,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.7,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.5,17.4,99.3,14.8,98.7,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.3,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,17.4,65.6,14.8,65,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.7,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C68.5,17.4,68.3,14.8,67.7,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.3,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.7,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.6,17.4,122.4,14.8,121.7,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.4,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.3,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125.1,17.4,125,14.8,124.3,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.9,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.9,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.7,17.4,127.6,14.8,126.9,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.5,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.5,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.3,17.4,130.1,14.8,129.5,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.1,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.9,17.4,132.7,14.8,132,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.7,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.5,17.4,135.3,14.8,134.6,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.3,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,17.4,150.8,14.8,150.1,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.7,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C153.5,17.4,153.3,14.8,152.7,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M152.3,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M155.3,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C156.1,17.4,156,14.8,155.3,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M154.9,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.9,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C158.7,17.4,158.5,14.8,157.9,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M157.5,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.5,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C161.3,17.4,161.2,14.8,160.5,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M160.1,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M163.1,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C163.9,17.4,163.7,14.8,163.1,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M162.7,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.7,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C166.5,17.4,166.4,14.8,165.7,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M165.3,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M168.3,14.8c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C169.1,17.4,168.9,14.8,168.3,14.8z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M167.9,16.1c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,27.8,65.6,25.1,65,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.6,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C68.4,27.8,68.2,25.1,67.6,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M67.2,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M36.5,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C37.3,27.8,37.1,25.1,36.5,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M36.1,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.2,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.1,27.8,93.9,25.1,93.2,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M92.9,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.8,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C96.6,27.8,96.5,25.1,95.8,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M95.4,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98.4,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C99.2,27.8,99.1,25.1,98.4,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M98,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M101,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C101.8,27.8,101.6,25.1,101,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M100.6,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.5,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C104.4,27.8,104.2,25.1,103.5,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M103.2,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.6,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.4,27.8,122.3,25.1,121.6,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.2,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.2,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125,27.8,124.8,25.1,124.2,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.8,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.8,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C127.6,27.8,127.5,25.1,126.8,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M126.4,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129.4,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C130.2,27.8,130,25.1,129.4,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M129,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M132,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C132.8,27.8,132.7,25.1,132,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M131.6,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.6,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C135.4,27.8,135.2,25.1,134.6,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M134.2,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M137.2,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C138,27.8,137.9,25.1,137.2,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M136.8,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.8,25.2c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C140.6,27.8,140.4,25.1,139.8,25.2z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M139.4,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M65,35.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C65.8,38,65.6,35.3,65,35.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M64.6,36.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M36.5,35.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C37.3,38,37.1,35.3,36.5,35.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M36.1,36.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.2,35.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.1,38,93.9,35.3,93.2,35.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M92.9,36.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.6,35.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.4,38,122.3,35.3,121.6,35.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.2,36.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.2,35.4c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125,38,124.8,35.3,124.2,35.4z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.8,36.6c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M93.2,45.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C94.1,48.3,93.9,45.6,93.2,45.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M92.9,46.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.6,45.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C122.4,48.3,122.3,45.6,121.6,45.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M121.2,46.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M124.2,45.7c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C125,48.3,124.8,45.6,124.2,45.7z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M123.8,46.9c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" enableBackground="new" x1="3.1" y1="31.6" x2="206.9" y2="31.6"/>
<line opacity="0.2" fill="none" stroke="#C0BFBF" strokeWidth="0.2" strokeMiterlimit="10" enableBackground="new" x1="3.1" y1="21.4" x2="206.9" y2="21.4"/>
<g>
	<g>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M150.1,25.1c-0.7-0.2-0.8,2.7-0.6,3.4c0.1,0.4,1.1,0.4,1.2,0C150.9,27.9,150.8,25.1,150.1,25.1z"/>
		<path fill="#FFFFFF" stroke="#bbbbbb" strokeWidth="0.3" strokeLinecap="round" strokeMiterlimit="10" d="M149.7,26.4c-1.1-0.4-0.9-2,0.3-2.1c1.2-0.2,1.6,1.6,0.7,2"/>
	</g>
</g>
<text x="3.1" y="16.2" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>미래한국당</text>
<text x="3.1" y="28" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>더불어시민당</text>
<text x="3.1" y="38.3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>정의당</text>
<text x="3.1" y="48.5" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>국민의당</text>
<text x="3.1" y="58.9" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>열린민주당</text>
<line fill="none" stroke="#000000" strokeWidth="0.2" strokeMiterlimit="10" x1="3.1" y1="5.2" x2="206.9" y2="5.2"/>
<g>
	<text text-anchor="middle" x="20" y="3" style={{fill:"rgb(150,150,150)", fontSize:"3.6px", strokeWidth:"0"}}>18~19세</text>
	<text text-anchor="middle" x="48.3" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>20대</text>
	<text text-anchor="middle" x="76.6" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>30대</text>
	<text text-anchor="middle" x="105" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>40대</text>
	<text text-anchor="middle" x="133.3" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>50대</text>
	<text text-anchor="middle" x="161.7" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>60대</text>
	<text text-anchor="middle" x="190" y="3" style={{fill:"#000", fontSize:"3.6px", strokeWidth:"0"}}>70세 이상</text>
</g>
</svg>
</div>
	  );
	  }
	}