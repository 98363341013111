import React, { PureComponent } from 'react';
import styled from "@emotion/styled";

const SvgDiv = styled.div`
.st0{fill:#FFFFFF;stroke:#B5B5B6;stroke-width:0.25;stroke-miterlimit:10;}
	.st1{fill:#FFFFFF;stroke:#B5B5B6;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}
	.st2{fill:#654893;stroke:#35294F;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}

.graph_mobile{display:none;}
.graph_desktop{display:block;}
@media screen and (max-width: 600px) {
	.graph_mobile{display:block;}
	.graph_desktop{display:none;} 
	.st40{fill:#FFFFFF;stroke:#DCDDDD;stroke-width:0.25;stroke-miterlimit:10;}
.st41{fill:#FFFFFF;stroke:#DCDDDD;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}
.st42{fill:#654893;stroke:#35294F;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}

	  }
`;


export default class Example extends PureComponent {
    render() {
      return (
        <SvgDiv>
<div class="graph_mobile">
<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 69.7959366 297.0000305" >
<g>
	<g>
		<path class="st40" d="M66,127.9c-0.8-0.2-0.8,3,0,2.8C66.9,130.9,66.9,127.7,66,127.9z"/>
		<path class="st41" d="M65.7,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,127.9c-0.8-0.2-0.8,3,0,2.8C64.9,130.9,64.9,127.7,64,127.9z"/>
		<path class="st41" d="M63.7,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,127.9c-0.8-0.2-0.8,3,0,2.8C62.9,130.9,62.9,127.7,62,127.9z"/>
		<path class="st41" d="M61.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,127.9c-0.8-0.2-0.8,3,0,2.8C60.9,130.9,60.9,127.7,60.1,127.9z"/>
		<path class="st41" d="M59.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,127.9c-0.8-0.2-0.8,3,0,2.8C58.9,130.9,58.9,127.7,58.1,127.9z"/>
		<path class="st41" d="M57.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,127.9c-0.8-0.2-0.8,3,0,2.8C56.9,130.9,56.9,127.7,56.1,127.9z"/>
		<path class="st41" d="M55.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,127.9c-0.8-0.2-0.8,3,0,2.8C54.9,130.9,54.9,127.7,54.1,127.9z"/>
		<path class="st41" d="M53.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,127.9c-0.8-0.2-0.8,3,0,2.8C52.9,130.9,52.9,127.7,52.1,127.9z"/>
		<path class="st41" d="M51.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,127.9c-0.8-0.2-0.8,3,0,2.8C51,130.9,51,127.7,50.1,127.9z"/>
		<path class="st41" d="M49.8,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,127.9c-0.8-0.2-0.8,3,0,2.8C49,130.9,49,127.7,48.1,127.9z"/>
		<path class="st41" d="M47.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,127.9c-0.8-0.2-0.8,3,0,2.8C47,130.9,47,127.7,46.2,127.9z"/>
		<path class="st41" d="M45.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,127.9c-0.8-0.2-0.8,3,0,2.8C45,130.9,45,127.7,44.2,127.9z"/>
		<path class="st41" d="M43.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,127.9c-0.8-0.2-0.8,3,0,2.8C43,130.9,43,127.7,42.2,127.9z"/>
		<path class="st41" d="M41.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,127.9c-0.8-0.2-0.8,3,0,2.8C41,130.9,41,127.7,40.2,127.9z"/>
		<path class="st41" d="M39.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,127.9c-0.8-0.2-0.8,3,0,2.8C39,130.9,39,127.7,38.2,127.9z"/>
		<path class="st41" d="M37.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,127.9c-0.8-0.2-0.8,3,0,2.8C37,130.9,37,127.7,36.2,127.9z"/>
		<path class="st41" d="M35.9,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,127.9c-0.8-0.2-0.8,3,0,2.8C35.2,130.9,35.2,127.7,34.3,127.9z"/>
		<path class="st41" d="M34.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,127.9c-0.8-0.2-0.8,3,0,2.8C33.2,130.9,33.2,127.7,32.4,127.9z"/>
		<path class="st41" d="M32.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,127.9c-0.8-0.2-0.8,3,0,2.8C31.2,130.9,31.2,127.7,30.4,127.9z"/>
		<path class="st41" d="M30.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,127.9c-0.8-0.2-0.8,3,0,2.8C29.2,130.9,29.2,127.7,28.4,127.9z"/>
		<path class="st41" d="M28.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,127.9c-0.8-0.2-0.8,3,0,2.8C27.2,130.9,27.2,127.7,26.4,127.9z"/>
		<path class="st41" d="M26.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,127.9c-0.8-0.2-0.8,3,0,2.8C25.2,130.9,25.2,127.7,24.4,127.9z"/>
		<path class="st41" d="M24.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,127.9c-0.8-0.2-0.8,3,0,2.8C23.3,130.9,23.3,127.7,22.4,127.9z"/>
		<path class="st41" d="M22.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,127.9c-0.8-0.2-0.8,3,0,2.8C21.3,130.9,21.3,127.7,20.4,127.9z"/>
		<path class="st41" d="M20.1,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,127.9c-0.8-0.2-0.8,3,0,2.8C19.3,130.9,19.3,127.7,18.4,127.9z"/>
		<path class="st41" d="M18.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,127.9c-0.8-0.2-0.8,3,0,2.8C17.3,130.9,17.3,127.7,16.5,127.9z"/>
		<path class="st41" d="M16.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,127.9c-0.8-0.2-0.8,3,0,2.8C15.3,130.9,15.3,127.7,14.5,127.9z"/>
		<path class="st41" d="M14.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,127.9c-0.8-0.2-0.8,3,0,2.8C13.3,130.9,13.3,127.7,12.5,127.9z"/>
		<path class="st41" d="M12.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,127.9c-0.8-0.2-0.8,3,0,2.8C11.3,130.9,11.3,127.7,10.5,127.9z"/>
		<path class="st41" d="M10.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,127.9c-0.8-0.2-0.8,3,0,2.8C9.3,130.9,9.3,127.7,8.5,127.9z"/>
		<path class="st41" d="M8.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,127.9c-0.8-0.2-0.8,3,0,2.8C7.4,130.9,7.4,127.7,6.5,127.9z"/>
		<path class="st41" d="M6.2,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,127.2c-0.8-0.2-0.8,3,0,2.8C4.3,130.2,4.3,127.1,3.5,127.2z"/>
		<path class="st41" d="M3.2,128.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,127.9c-0.8-0.2-0.8,3,0,2.8C5.4,130.9,5.4,127.7,4.5,127.9z"/>
		<path class="st41" d="M4.3,128.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,128.6c-0.8-0.2-0.8,3,0,2.8C65.8,131.7,65.8,128.5,65,128.6z"/>
		<path class="st41" d="M64.7,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,129.3c-0.8-0.2-0.8,3,0,2.8C66.9,132.3,66.9,129.2,66,129.3z"/>
		<path class="st41" d="M65.7,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,128.6c-0.8-0.2-0.8,3,0,2.8C63.8,131.7,63.8,128.5,63,128.6z"/>
		<path class="st41" d="M62.7,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,129.3c-0.8-0.2-0.8,3,0,2.8C64.9,132.3,64.9,129.2,64,129.3z"/>
		<path class="st41" d="M63.7,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,128.6c-0.8-0.2-0.8,3,0,2.8C61.8,131.7,61.8,128.5,61,128.6z"/>
		<path class="st41" d="M60.7,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,129.3c-0.8-0.2-0.8,3,0,2.8C62.9,132.3,62.9,129.2,62,129.3z"/>
		<path class="st41" d="M61.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,128.6c-0.8-0.2-0.8,3,0,2.8C59.8,131.7,59.8,128.5,59,128.6z"/>
		<path class="st41" d="M58.7,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,129.3c-0.8-0.2-0.8,3,0,2.8C60.9,132.3,60.9,129.2,60.1,129.3z"/>
		<path class="st41" d="M59.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,128.6c-0.8-0.2-0.8,3,0,2.8C57.9,131.7,57.9,128.5,57,128.6z"/>
		<path class="st41" d="M56.7,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,129.3c-0.8-0.2-0.8,3,0,2.8C58.9,132.3,58.9,129.2,58.1,129.3z"/>
		<path class="st41" d="M57.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,128.6c-0.8-0.2-0.8,3,0,2.8C55.9,131.7,55.9,128.5,55,128.6z"/>
		<path class="st41" d="M54.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,129.3c-0.8-0.2-0.8,3,0,2.8C56.9,132.3,56.9,129.2,56.1,129.3z"/>
		<path class="st41" d="M55.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,128.6c-0.8-0.2-0.8,3,0,2.8C53.9,131.7,53.9,128.5,53.1,128.6z"/>
		<path class="st41" d="M52.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,129.3c-0.8-0.2-0.8,3,0,2.8C54.9,132.3,54.9,129.2,54.1,129.3z"/>
		<path class="st41" d="M53.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,128.6c-0.8-0.2-0.8,3,0,2.8C51.9,131.7,51.9,128.5,51.1,128.6z"/>
		<path class="st41" d="M50.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,129.3c-0.8-0.2-0.8,3,0,2.8C52.9,132.3,52.9,129.2,52.1,129.3z"/>
		<path class="st41" d="M51.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,128.6c-0.8-0.2-0.8,3,0,2.8C49.9,131.7,49.9,128.5,49.1,128.6z"/>
		<path class="st41" d="M48.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,129.3c-0.8-0.2-0.8,3,0,2.8C51,132.3,51,129.2,50.1,129.3z"/>
		<path class="st41" d="M49.8,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,128.6c-0.8-0.2-0.8,3,0,2.8C47.9,131.7,47.9,128.5,47.1,128.6z"/>
		<path class="st41" d="M46.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,129.3c-0.8-0.2-0.8,3,0,2.8C49,132.3,49,129.2,48.1,129.3z"/>
		<path class="st41" d="M47.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,128.6c-0.8-0.2-0.8,3,0,2.8C45.9,131.7,45.9,128.5,45.1,128.6z"/>
		<path class="st41" d="M44.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,129.3c-0.8-0.2-0.8,3,0,2.8C47,132.3,47,129.2,46.2,129.3z"/>
		<path class="st41" d="M45.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,128.6c-0.8-0.2-0.8,3,0,2.8C43.9,131.7,43.9,128.5,43.1,128.6z"/>
		<path class="st41" d="M42.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,129.3c-0.8-0.2-0.8,3,0,2.8C45,132.3,45,129.2,44.2,129.3z"/>
		<path class="st41" d="M43.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,128.6c-0.8-0.2-0.8,3,0,2.8C42,131.7,42,128.5,41.1,128.6z"/>
		<path class="st41" d="M40.8,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,129.3c-0.8-0.2-0.8,3,0,2.8C43,132.3,43,129.2,42.2,129.3z"/>
		<path class="st41" d="M41.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,128.6c-0.8-0.2-0.8,3,0,2.8C40,131.7,40,128.5,39.1,128.6z"/>
		<path class="st41" d="M38.9,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,129.3c-0.8-0.2-0.8,3,0,2.8C41,132.3,41,129.2,40.2,129.3z"/>
		<path class="st41" d="M39.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,128.6c-0.8-0.2-0.8,3,0,2.8C38,131.7,38,128.5,37.2,128.6z"/>
		<path class="st41" d="M36.9,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,129.3c-0.8-0.2-0.8,3,0,2.8C39,132.3,39,129.2,38.2,129.3z"/>
		<path class="st41" d="M37.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,128.6c-0.8-0.2-0.8,3,0,2.8C36,131.7,36,128.5,35.2,128.6z"/>
		<path class="st41" d="M34.9,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,129.3c-0.8-0.2-0.8,3,0,2.8C37,132.3,37,129.2,36.2,129.3z"/>
		<path class="st41" d="M35.9,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,128.6c-0.8-0.2-0.8,3,0,2.8C34.1,131.7,34.1,128.5,33.3,128.6z"/>
		<path class="st41" d="M33,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,129.3c-0.8-0.2-0.8,3,0,2.8C35.2,132.3,35.2,129.2,34.3,129.3z"/>
		<path class="st41" d="M34.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,128.6c-0.8-0.2-0.8,3,0,2.8C32.1,131.7,32.1,128.5,31.3,128.6z"/>
		<path class="st41" d="M31,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,129.3c-0.8-0.2-0.8,3,0,2.8C33.2,132.3,33.2,129.2,32.4,129.3z"/>
		<path class="st41" d="M32.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,128.6c-0.8-0.2-0.8,3,0,2.8C30.1,131.7,30.2,128.5,29.3,128.6z"/>
		<path class="st41" d="M29,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,129.3c-0.8-0.2-0.8,3,0,2.8C31.2,132.3,31.2,129.2,30.4,129.3z"/>
		<path class="st41" d="M30.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,128.6c-0.8-0.2-0.8,3,0,2.8C28.2,131.7,28.2,128.5,27.3,128.6z"/>
		<path class="st41" d="M27,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,129.3c-0.8-0.2-0.8,3,0,2.8C29.2,132.3,29.2,129.2,28.4,129.3z"/>
		<path class="st41" d="M28.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,128.6c-0.8-0.2-0.8,3,0,2.8C26.2,131.7,26.2,128.5,25.3,128.6z"/>
		<path class="st41" d="M25.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,129.3c-0.8-0.2-0.8,3,0,2.8C27.2,132.3,27.2,129.2,26.4,129.3z"/>
		<path class="st41" d="M26.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,128.6c-0.8-0.2-0.8,3,0,2.8C24.2,131.7,24.2,128.5,23.4,128.6z"/>
		<path class="st41" d="M23.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,129.3c-0.8-0.2-0.8,3,0,2.8C25.2,132.3,25.2,129.2,24.4,129.3z"/>
		<path class="st41" d="M24.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,128.6c-0.8-0.2-0.8,3,0,2.8C22.2,131.7,22.2,128.5,21.4,128.6z"/>
		<path class="st41" d="M21.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,129.3c-0.8-0.2-0.8,3,0,2.8C23.3,132.3,23.3,129.2,22.4,129.3z"/>
		<path class="st41" d="M22.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,128.6c-0.8-0.2-0.8,3,0,2.8C20.2,131.7,20.2,128.5,19.4,128.6z"/>
		<path class="st41" d="M19.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,129.3c-0.8-0.2-0.8,3,0,2.8C21.3,132.3,21.3,129.2,20.4,129.3z"/>
		<path class="st41" d="M20.1,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,128.6c-0.8-0.2-0.8,3,0,2.8C18.2,131.7,18.2,128.5,17.4,128.6z"/>
		<path class="st41" d="M17.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,129.3c-0.8-0.2-0.8,3,0,2.8C19.3,132.3,19.3,129.2,18.4,129.3z"/>
		<path class="st41" d="M18.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,128.6c-0.8-0.2-0.8,3,0,2.8C16.2,131.7,16.2,128.5,15.4,128.6z"/>
		<path class="st41" d="M15.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,129.3c-0.8-0.2-0.8,3,0,2.8C17.3,132.3,17.3,129.2,16.5,129.3z"/>
		<path class="st41" d="M16.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,128.6c-0.8-0.2-0.8,3,0,2.8C14.3,131.7,14.3,128.5,13.4,128.6z"/>
		<path class="st41" d="M13.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,129.3c-0.8-0.2-0.8,3,0,2.8C15.3,132.3,15.3,129.2,14.5,129.3z"/>
		<path class="st41" d="M14.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,128.6c-0.8-0.2-0.8,3,0,2.8C12.3,131.7,12.3,128.5,11.4,128.6z"/>
		<path class="st41" d="M11.1,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,129.3c-0.8-0.2-0.8,3,0,2.8C13.3,132.3,13.3,129.2,12.5,129.3z"/>
		<path class="st41" d="M12.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,128.6c-0.8-0.2-0.8,3,0,2.8C10.3,131.7,10.3,128.5,9.5,128.6z"/>
		<path class="st41" d="M9.2,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,129.3c-0.8-0.2-0.8,3,0,2.8C11.3,132.3,11.3,129.2,10.5,129.3z"/>
		<path class="st41" d="M10.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,128.6c-0.8-0.2-0.8,3,0,2.8C8.3,131.7,8.3,128.5,7.5,128.6z"/>
		<path class="st41" d="M7.2,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,129.3c-0.8-0.2-0.8,3,0,2.8C9.3,132.3,9.3,129.2,8.5,129.3z"/>
		<path class="st41" d="M8.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,128.6c-0.8-0.2-0.8,3,0,2.8C6.3,131.7,6.3,128.5,5.5,128.6z"/>
		<path class="st41" d="M5.2,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,129.3c-0.8-0.2-0.8,3,0,2.8C7.4,132.3,7.4,129.2,6.5,129.3z"/>
		<path class="st41" d="M6.2,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,128.6c-0.8-0.2-0.8,3,0,2.8C4.3,131.7,4.3,128.5,3.5,128.6z"/>
		<path class="st41" d="M3.2,129.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,129.3c-0.8-0.2-0.8,3,0,2.8C5.4,132.3,5.4,129.2,4.5,129.3z"/>
		<path class="st41" d="M4.3,130.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,130.2c-0.8-0.2-0.8,3,0,2.8C65.8,133.2,65.8,130,65,130.2z"/>
		<path class="st41" d="M64.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,130.8c-0.8-0.2-0.8,3,0,2.8C66.9,133.8,66.9,130.7,66,130.8z"/>
		<path class="st41" d="M65.7,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,130.2c-0.8-0.2-0.8,3,0,2.8C63.8,133.2,63.8,130,63,130.2z"/>
		<path class="st41" d="M62.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,130.8c-0.8-0.2-0.8,3,0,2.8C64.9,133.8,64.9,130.7,64,130.8z"/>
		<path class="st41" d="M63.7,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,130.2c-0.8-0.2-0.8,3,0,2.8C61.8,133.2,61.8,130,61,130.2z"/>
		<path class="st41" d="M60.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,130.8c-0.8-0.2-0.8,3,0,2.8C62.9,133.8,62.9,130.7,62,130.8z"/>
		<path class="st41" d="M61.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,130.2c-0.8-0.2-0.8,3,0,2.8C59.8,133.2,59.8,130,59,130.2z"/>
		<path class="st41" d="M58.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,130.8c-0.8-0.2-0.8,3,0,2.8C60.9,133.8,60.9,130.7,60.1,130.8z"/>
		<path class="st41" d="M59.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,130.2c-0.8-0.2-0.8,3,0,2.8C57.9,133.2,57.9,130,57,130.2z"/>
		<path class="st41" d="M56.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,130.8c-0.8-0.2-0.8,3,0,2.8C58.9,133.8,58.9,130.7,58.1,130.8z"/>
		<path class="st41" d="M57.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,130.2c-0.8-0.2-0.8,3,0,2.8C55.9,133.2,55.9,130,55,130.2z"/>
		<path class="st41" d="M54.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,130.8c-0.8-0.2-0.8,3,0,2.8C56.9,133.8,56.9,130.7,56.1,130.8z"/>
		<path class="st41" d="M55.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,130.2c-0.8-0.2-0.8,3,0,2.8C53.9,133.2,53.9,130,53.1,130.2z"/>
		<path class="st41" d="M52.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,130.8c-0.8-0.2-0.8,3,0,2.8C54.9,133.8,54.9,130.7,54.1,130.8z"/>
		<path class="st41" d="M53.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,130.2c-0.8-0.2-0.8,3,0,2.8C51.9,133.2,51.9,130,51.1,130.2z"/>
		<path class="st41" d="M50.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,130.8c-0.8-0.2-0.8,3,0,2.8C52.9,133.8,52.9,130.7,52.1,130.8z"/>
		<path class="st41" d="M51.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,130.2c-0.8-0.2-0.8,3,0,2.8C49.9,133.2,49.9,130,49.1,130.2z"/>
		<path class="st41" d="M48.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,130.8c-0.8-0.2-0.8,3,0,2.8C51,133.8,51,130.7,50.1,130.8z"/>
		<path class="st41" d="M49.8,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,130.2c-0.8-0.2-0.8,3,0,2.8C47.9,133.2,47.9,130,47.1,130.2z"/>
		<path class="st41" d="M46.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,130.8c-0.8-0.2-0.8,3,0,2.8C49,133.8,49,130.7,48.1,130.8z"/>
		<path class="st41" d="M47.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,130.2c-0.8-0.2-0.8,3,0,2.8C45.9,133.2,45.9,130,45.1,130.2z"/>
		<path class="st41" d="M44.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,130.8c-0.8-0.2-0.8,3,0,2.8C47,133.8,47,130.7,46.2,130.8z"/>
		<path class="st41" d="M45.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,130.2c-0.8-0.2-0.8,3,0,2.8C43.9,133.2,43.9,130,43.1,130.2z"/>
		<path class="st41" d="M42.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,130.8c-0.8-0.2-0.8,3,0,2.8C45,133.8,45,130.7,44.2,130.8z"/>
		<path class="st41" d="M43.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,130.2c-0.8-0.2-0.8,3,0,2.8C42,133.2,42,130,41.1,130.2z"/>
		<path class="st41" d="M40.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,130.8c-0.8-0.2-0.8,3,0,2.8C43,133.8,43,130.7,42.2,130.8z"/>
		<path class="st41" d="M41.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,130.2c-0.8-0.2-0.8,3,0,2.8C40,133.2,40,130,39.1,130.2z"/>
		<path class="st41" d="M38.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,130.8c-0.8-0.2-0.8,3,0,2.8C41,133.8,41,130.7,40.2,130.8z"/>
		<path class="st41" d="M39.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,130.2c-0.8-0.2-0.8,3,0,2.8C38,133.2,38,130,37.2,130.2z"/>
		<path class="st41" d="M36.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,130.8c-0.8-0.2-0.8,3,0,2.8C39,133.8,39,130.7,38.2,130.8z"/>
		<path class="st41" d="M37.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,130.2c-0.8-0.2-0.8,3,0,2.8C36,133.2,36,130,35.2,130.2z"/>
		<path class="st41" d="M34.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,130.8c-0.8-0.2-0.8,3,0,2.8C37,133.8,37,130.7,36.2,130.8z"/>
		<path class="st41" d="M35.9,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,130.2c-0.8-0.2-0.8,3,0,2.8C34.1,133.2,34.1,130,33.3,130.2z"/>
		<path class="st41" d="M33,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,130.8c-0.8-0.2-0.8,3,0,2.8C35.2,133.8,35.2,130.7,34.3,130.8z"/>
		<path class="st41" d="M34.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,130.2c-0.8-0.2-0.8,3,0,2.8C32.1,133.2,32.1,130,31.3,130.2z"/>
		<path class="st41" d="M31,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,130.8c-0.8-0.2-0.8,3,0,2.8C33.2,133.8,33.2,130.7,32.4,130.8z"/>
		<path class="st41" d="M32.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,130.2c-0.8-0.2-0.8,3,0,2.8C30.1,133.2,30.2,130,29.3,130.2z"/>
		<path class="st41" d="M29,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,130.8c-0.8-0.2-0.8,3,0,2.8C31.2,133.8,31.2,130.7,30.4,130.8z"/>
		<path class="st41" d="M30.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,130.2c-0.8-0.2-0.8,3,0,2.8C28.2,133.2,28.2,130,27.3,130.2z"/>
		<path class="st41" d="M27,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,130.8c-0.8-0.2-0.8,3,0,2.8C29.2,133.8,29.2,130.7,28.4,130.8z"/>
		<path class="st41" d="M28.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,130.2c-0.8-0.2-0.8,3,0,2.8C26.2,133.2,26.2,130,25.3,130.2z"/>
		<path class="st41" d="M25.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,130.8c-0.8-0.2-0.8,3,0,2.8C27.2,133.8,27.2,130.7,26.4,130.8z"/>
		<path class="st41" d="M26.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,130.2c-0.8-0.2-0.8,3,0,2.8C24.2,133.2,24.2,130,23.4,130.2z"/>
		<path class="st41" d="M23.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,130.8c-0.8-0.2-0.8,3,0,2.8C25.2,133.8,25.2,130.7,24.4,130.8z"/>
		<path class="st41" d="M24.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,130.2c-0.8-0.2-0.8,3,0,2.8C22.2,133.2,22.2,130,21.4,130.2z"/>
		<path class="st41" d="M21.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,130.8c-0.8-0.2-0.8,3,0,2.8C23.3,133.8,23.3,130.7,22.4,130.8z"/>
		<path class="st41" d="M22.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,130.2c-0.8-0.2-0.8,3,0,2.8C20.2,133.2,20.2,130,19.4,130.2z"/>
		<path class="st41" d="M19.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,130.8c-0.8-0.2-0.8,3,0,2.8C21.3,133.8,21.3,130.7,20.4,130.8z"/>
		<path class="st41" d="M20.1,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,130.2c-0.8-0.2-0.8,3,0,2.8C18.2,133.2,18.2,130,17.4,130.2z"/>
		<path class="st41" d="M17.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,130.8c-0.8-0.2-0.8,3,0,2.8C19.3,133.8,19.3,130.7,18.4,130.8z"/>
		<path class="st41" d="M18.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,130.2c-0.8-0.2-0.8,3,0,2.8C16.2,133.2,16.2,130,15.4,130.2z"/>
		<path class="st41" d="M15.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,130.8c-0.8-0.2-0.8,3,0,2.8C17.3,133.8,17.3,130.7,16.5,130.8z"/>
		<path class="st41" d="M16.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,130.2c-0.8-0.2-0.8,3,0,2.8C14.3,133.2,14.3,130,13.4,130.2z"/>
		<path class="st41" d="M13.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,130.8c-0.8-0.2-0.8,3,0,2.8C15.3,133.8,15.3,130.7,14.5,130.8z"/>
		<path class="st41" d="M14.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,130.2c-0.8-0.2-0.8,3,0,2.8C12.3,133.2,12.3,130,11.4,130.2z"/>
		<path class="st41" d="M11.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,130.8c-0.8-0.2-0.8,3,0,2.8C13.3,133.8,13.3,130.7,12.5,130.8z"/>
		<path class="st41" d="M12.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,130.2c-0.8-0.2-0.8,3,0,2.8C10.3,133.2,10.3,130,9.5,130.2z"/>
		<path class="st41" d="M9.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,130.8c-0.8-0.2-0.8,3,0,2.8C11.3,133.8,11.3,130.7,10.5,130.8z"/>
		<path class="st41" d="M10.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,130.2c-0.8-0.2-0.8,3,0,2.8C8.3,133.2,8.3,130,7.5,130.2z"/>
		<path class="st41" d="M7.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,130.8c-0.8-0.2-0.8,3,0,2.8C9.3,133.8,9.3,130.7,8.5,130.8z"/>
		<path class="st41" d="M8.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,130.2c-0.8-0.2-0.8,3,0,2.8C6.3,133.2,6.3,130,5.5,130.2z"/>
		<path class="st41" d="M5.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,130.8c-0.8-0.2-0.8,3,0,2.8C7.4,133.8,7.4,130.7,6.5,130.8z"/>
		<path class="st41" d="M6.2,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,130.2c-0.8-0.2-0.8,3,0,2.8C4.3,133.2,4.3,130,3.5,130.2z"/>
		<path class="st41" d="M3.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,130.8c-0.8-0.2-0.8,3,0,2.8C5.4,133.8,5.4,130.7,4.5,130.8z"/>
		<path class="st41" d="M4.3,131.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,131.6c-0.8-0.2-0.8,3,0,2.8C65.8,134.6,65.8,131.4,65,131.6z"/>
		<path class="st41" d="M64.7,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,132.3c-0.8-0.2-0.8,3,0,2.8C66.9,135.3,66.9,132.1,66,132.3z"/>
		<path class="st41" d="M65.7,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,131.6c-0.8-0.2-0.8,3,0,2.8C63.8,134.6,63.8,131.4,63,131.6z"/>
		<path class="st41" d="M62.7,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,132.3c-0.8-0.2-0.8,3,0,2.8C64.9,135.3,64.9,132.1,64,132.3z"/>
		<path class="st41" d="M63.7,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,131.6c-0.8-0.2-0.8,3,0,2.8C61.8,134.6,61.8,131.4,61,131.6z"/>
		<path class="st41" d="M60.7,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,132.3c-0.8-0.2-0.8,3,0,2.8C62.9,135.3,62.9,132.1,62,132.3z"/>
		<path class="st41" d="M61.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,131.6c-0.8-0.2-0.8,3,0,2.8C59.8,134.6,59.8,131.4,59,131.6z"/>
		<path class="st41" d="M58.7,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,132.3c-0.8-0.2-0.8,3,0,2.8C60.9,135.3,60.9,132.1,60.1,132.3z"/>
		<path class="st41" d="M59.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,131.6c-0.8-0.2-0.8,3,0,2.8C57.9,134.6,57.9,131.4,57,131.6z"/>
		<path class="st41" d="M56.7,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,132.3c-0.8-0.2-0.8,3,0,2.8C58.9,135.3,58.9,132.1,58.1,132.3z"/>
		<path class="st41" d="M57.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,131.6c-0.8-0.2-0.8,3,0,2.8C55.9,134.6,55.9,131.4,55,131.6z"/>
		<path class="st41" d="M54.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,132.3c-0.8-0.2-0.8,3,0,2.8C56.9,135.3,56.9,132.1,56.1,132.3z"/>
		<path class="st41" d="M55.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,131.6c-0.8-0.2-0.8,3,0,2.8C53.9,134.6,53.9,131.4,53.1,131.6z"/>
		<path class="st41" d="M52.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,132.3c-0.8-0.2-0.8,3,0,2.8C54.9,135.3,54.9,132.1,54.1,132.3z"/>
		<path class="st41" d="M53.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,131.6c-0.8-0.2-0.8,3,0,2.8C51.9,134.6,51.9,131.4,51.1,131.6z"/>
		<path class="st41" d="M50.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,132.3c-0.8-0.2-0.8,3,0,2.8C52.9,135.3,52.9,132.1,52.1,132.3z"/>
		<path class="st41" d="M51.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,131.6c-0.8-0.2-0.8,3,0,2.8C49.9,134.6,49.9,131.4,49.1,131.6z"/>
		<path class="st41" d="M48.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,132.3c-0.8-0.2-0.8,3,0,2.8C51,135.3,51,132.1,50.1,132.3z"/>
		<path class="st41" d="M49.8,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,131.6c-0.8-0.2-0.8,3,0,2.8C47.9,134.6,47.9,131.4,47.1,131.6z"/>
		<path class="st41" d="M46.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,132.3c-0.8-0.2-0.8,3,0,2.8C49,135.3,49,132.1,48.1,132.3z"/>
		<path class="st41" d="M47.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,131.6c-0.8-0.2-0.8,3,0,2.8C45.9,134.6,45.9,131.4,45.1,131.6z"/>
		<path class="st41" d="M44.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,132.3c-0.8-0.2-0.8,3,0,2.8C47,135.3,47,132.1,46.2,132.3z"/>
		<path class="st41" d="M45.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,131.6c-0.8-0.2-0.8,3,0,2.8C43.9,134.6,43.9,131.4,43.1,131.6z"/>
		<path class="st41" d="M42.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,132.3c-0.8-0.2-0.8,3,0,2.8C45,135.3,45,132.1,44.2,132.3z"/>
		<path class="st41" d="M43.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,131.6c-0.8-0.2-0.8,3,0,2.8C42,134.6,42,131.4,41.1,131.6z"/>
		<path class="st41" d="M40.8,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,132.3c-0.8-0.2-0.8,3,0,2.8C43,135.3,43,132.1,42.2,132.3z"/>
		<path class="st41" d="M41.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,131.6c-0.8-0.2-0.8,3,0,2.8C40,134.6,40,131.4,39.1,131.6z"/>
		<path class="st41" d="M38.9,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,132.3c-0.8-0.2-0.8,3,0,2.8C41,135.3,41,132.1,40.2,132.3z"/>
		<path class="st41" d="M39.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,131.6c-0.8-0.2-0.8,3,0,2.8C38,134.6,38,131.4,37.2,131.6z"/>
		<path class="st41" d="M36.9,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,132.3c-0.8-0.2-0.8,3,0,2.8C39,135.3,39,132.1,38.2,132.3z"/>
		<path class="st41" d="M37.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,131.6c-0.8-0.2-0.8,3,0,2.8C36,134.6,36,131.4,35.2,131.6z"/>
		<path class="st41" d="M34.9,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,132.3c-0.8-0.2-0.8,3,0,2.8C37,135.3,37,132.1,36.2,132.3z"/>
		<path class="st41" d="M35.9,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,131.6c-0.8-0.2-0.8,3,0,2.8C34.1,134.6,34.1,131.4,33.3,131.6z"/>
		<path class="st41" d="M33,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,132.3c-0.8-0.2-0.8,3,0,2.8C35.2,135.3,35.2,132.1,34.3,132.3z"/>
		<path class="st41" d="M34.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,131.6c-0.8-0.2-0.8,3,0,2.8C32.1,134.6,32.1,131.4,31.3,131.6z"/>
		<path class="st41" d="M31,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,132.3c-0.8-0.2-0.8,3,0,2.8C33.2,135.3,33.2,132.1,32.4,132.3z"/>
		<path class="st41" d="M32.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,131.6c-0.8-0.2-0.8,3,0,2.8C30.1,134.6,30.2,131.4,29.3,131.6z"/>
		<path class="st41" d="M29,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,132.3c-0.8-0.2-0.8,3,0,2.8C31.2,135.3,31.2,132.1,30.4,132.3z"/>
		<path class="st41" d="M30.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,131.6c-0.8-0.2-0.8,3,0,2.8C28.2,134.6,28.2,131.4,27.3,131.6z"/>
		<path class="st41" d="M27,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,132.3c-0.8-0.2-0.8,3,0,2.8C29.2,135.3,29.2,132.1,28.4,132.3z"/>
		<path class="st41" d="M28.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,131.6c-0.8-0.2-0.8,3,0,2.8C26.2,134.6,26.2,131.4,25.3,131.6z"/>
		<path class="st41" d="M25.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,132.3c-0.8-0.2-0.8,3,0,2.8C27.2,135.3,27.2,132.1,26.4,132.3z"/>
		<path class="st41" d="M26.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,131.6c-0.8-0.2-0.8,3,0,2.8C24.2,134.6,24.2,131.4,23.4,131.6z"/>
		<path class="st41" d="M23.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,132.3c-0.8-0.2-0.8,3,0,2.8C25.2,135.3,25.2,132.1,24.4,132.3z"/>
		<path class="st41" d="M24.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,131.6c-0.8-0.2-0.8,3,0,2.8C22.2,134.6,22.2,131.4,21.4,131.6z"/>
		<path class="st41" d="M21.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,132.3c-0.8-0.2-0.8,3,0,2.8C23.3,135.3,23.3,132.1,22.4,132.3z"/>
		<path class="st41" d="M22.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,131.6c-0.8-0.2-0.8,3,0,2.8C20.2,134.6,20.2,131.4,19.4,131.6z"/>
		<path class="st41" d="M19.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,132.3c-0.8-0.2-0.8,3,0,2.8C21.3,135.3,21.3,132.1,20.4,132.3z"/>
		<path class="st41" d="M20.1,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,131.6c-0.8-0.2-0.8,3,0,2.8C18.2,134.6,18.2,131.4,17.4,131.6z"/>
		<path class="st41" d="M17.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,132.3c-0.8-0.2-0.8,3,0,2.8C19.3,135.3,19.3,132.1,18.4,132.3z"/>
		<path class="st41" d="M18.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,131.6c-0.8-0.2-0.8,3,0,2.8C16.2,134.6,16.2,131.4,15.4,131.6z"/>
		<path class="st41" d="M15.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,132.3c-0.8-0.2-0.8,3,0,2.8C17.3,135.3,17.3,132.1,16.5,132.3z"/>
		<path class="st41" d="M16.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,131.6c-0.8-0.2-0.8,3,0,2.8C14.3,134.6,14.3,131.4,13.4,131.6z"/>
		<path class="st41" d="M13.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,132.3c-0.8-0.2-0.8,3,0,2.8C15.3,135.3,15.3,132.1,14.5,132.3z"/>
		<path class="st41" d="M14.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,131.6c-0.8-0.2-0.8,3,0,2.8C12.3,134.6,12.3,131.4,11.4,131.6z"/>
		<path class="st41" d="M11.1,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,132.3c-0.8-0.2-0.8,3,0,2.8C13.3,135.3,13.3,132.1,12.5,132.3z"/>
		<path class="st41" d="M12.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,131.6c-0.8-0.2-0.8,3,0,2.8C10.3,134.6,10.3,131.4,9.5,131.6z"/>
		<path class="st41" d="M9.2,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,132.3c-0.8-0.2-0.8,3,0,2.8C11.3,135.3,11.3,132.1,10.5,132.3z"/>
		<path class="st41" d="M10.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,131.6c-0.8-0.2-0.8,3,0,2.8C8.3,134.6,8.3,131.4,7.5,131.6z"/>
		<path class="st41" d="M7.2,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,132.3c-0.8-0.2-0.8,3,0,2.8C9.3,135.3,9.3,132.1,8.5,132.3z"/>
		<path class="st41" d="M8.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,131.6c-0.8-0.2-0.8,3,0,2.8C6.3,134.6,6.3,131.4,5.5,131.6z"/>
		<path class="st41" d="M5.2,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,132.3c-0.8-0.2-0.8,3,0,2.8C7.4,135.3,7.4,132.1,6.5,132.3z"/>
		<path class="st41" d="M6.2,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,131.6c-0.8-0.2-0.8,3,0,2.8C4.3,134.6,4.3,131.4,3.5,131.6z"/>
		<path class="st41" d="M3.2,132.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,132.3c-0.8-0.2-0.8,3,0,2.8C5.4,135.3,5.4,132.1,4.5,132.3z"/>
		<path class="st41" d="M4.3,133.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,133.1c-0.8-0.2-0.8,3,0,2.8C65.8,136.1,65.8,132.9,65,133.1z"/>
		<path class="st41" d="M64.7,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,133.8c-0.8-0.2-0.8,3,0,2.8C66.9,136.8,66.9,133.6,66,133.8z"/>
		<path class="st41" d="M65.7,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,133.1c-0.8-0.2-0.8,3,0,2.8C63.8,136.1,63.8,132.9,63,133.1z"/>
		<path class="st41" d="M62.7,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,133.8c-0.8-0.2-0.8,3,0,2.8C64.9,136.8,64.9,133.6,64,133.8z"/>
		<path class="st41" d="M63.7,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,133.1c-0.8-0.2-0.8,3,0,2.8C61.8,136.1,61.8,132.9,61,133.1z"/>
		<path class="st41" d="M60.7,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,133.8c-0.8-0.2-0.8,3,0,2.8C62.9,136.8,62.9,133.6,62,133.8z"/>
		<path class="st41" d="M61.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,133.1c-0.8-0.2-0.8,3,0,2.8C59.8,136.1,59.8,132.9,59,133.1z"/>
		<path class="st41" d="M58.7,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,133.8c-0.8-0.2-0.8,3,0,2.8C60.9,136.8,60.9,133.6,60.1,133.8z"/>
		<path class="st41" d="M59.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,133.1c-0.8-0.2-0.8,3,0,2.8C57.9,136.1,57.9,132.9,57,133.1z"/>
		<path class="st41" d="M56.7,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,133.8c-0.8-0.2-0.8,3,0,2.8C58.9,136.8,58.9,133.6,58.1,133.8z"/>
		<path class="st41" d="M57.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,133.1c-0.8-0.2-0.8,3,0,2.8C55.9,136.1,55.9,132.9,55,133.1z"/>
		<path class="st41" d="M54.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,133.8c-0.8-0.2-0.8,3,0,2.8C56.9,136.8,56.9,133.6,56.1,133.8z"/>
		<path class="st41" d="M55.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,133.1c-0.8-0.2-0.8,3,0,2.8C53.9,136.1,53.9,132.9,53.1,133.1z"/>
		<path class="st41" d="M52.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,133.8c-0.8-0.2-0.8,3,0,2.8C54.9,136.8,54.9,133.6,54.1,133.8z"/>
		<path class="st41" d="M53.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,133.1c-0.8-0.2-0.8,3,0,2.8C51.9,136.1,51.9,132.9,51.1,133.1z"/>
		<path class="st41" d="M50.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,133.8c-0.8-0.2-0.8,3,0,2.8C52.9,136.8,52.9,133.6,52.1,133.8z"/>
		<path class="st41" d="M51.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,133.1c-0.8-0.2-0.8,3,0,2.8C49.9,136.1,49.9,132.9,49.1,133.1z"/>
		<path class="st41" d="M48.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,133.8c-0.8-0.2-0.8,3,0,2.8C51,136.8,51,133.6,50.1,133.8z"/>
		<path class="st41" d="M49.8,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,133.1c-0.8-0.2-0.8,3,0,2.8C47.9,136.1,47.9,132.9,47.1,133.1z"/>
		<path class="st41" d="M46.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,133.8c-0.8-0.2-0.8,3,0,2.8C49,136.8,49,133.6,48.1,133.8z"/>
		<path class="st41" d="M47.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,133.1c-0.8-0.2-0.8,3,0,2.8C45.9,136.1,45.9,132.9,45.1,133.1z"/>
		<path class="st41" d="M44.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,133.8c-0.8-0.2-0.8,3,0,2.8C47,136.8,47,133.6,46.2,133.8z"/>
		<path class="st41" d="M45.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,133.1c-0.8-0.2-0.8,3,0,2.8C43.9,136.1,43.9,132.9,43.1,133.1z"/>
		<path class="st41" d="M42.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,133.8c-0.8-0.2-0.8,3,0,2.8C45,136.8,45,133.6,44.2,133.8z"/>
		<path class="st41" d="M43.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,133.1c-0.8-0.2-0.8,3,0,2.8C42,136.1,42,132.9,41.1,133.1z"/>
		<path class="st41" d="M40.8,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,133.8c-0.8-0.2-0.8,3,0,2.8C43,136.8,43,133.6,42.2,133.8z"/>
		<path class="st41" d="M41.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,133.1c-0.8-0.2-0.8,3,0,2.8C40,136.1,40,132.9,39.1,133.1z"/>
		<path class="st41" d="M38.9,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,133.8c-0.8-0.2-0.8,3,0,2.8C41,136.8,41,133.6,40.2,133.8z"/>
		<path class="st41" d="M39.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,133.1c-0.8-0.2-0.8,3,0,2.8C38,136.1,38,132.9,37.2,133.1z"/>
		<path class="st41" d="M36.9,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,133.8c-0.8-0.2-0.8,3,0,2.8C39,136.8,39,133.6,38.2,133.8z"/>
		<path class="st41" d="M37.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,133.1c-0.8-0.2-0.8,3,0,2.8C36,136.1,36,132.9,35.2,133.1z"/>
		<path class="st41" d="M34.9,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,133.8c-0.8-0.2-0.8,3,0,2.8C37,136.8,37,133.6,36.2,133.8z"/>
		<path class="st41" d="M35.9,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,133.1c-0.8-0.2-0.8,3,0,2.8C34.1,136.1,34.1,132.9,33.3,133.1z"/>
		<path class="st41" d="M33,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,133.8c-0.8-0.2-0.8,3,0,2.8C35.2,136.8,35.2,133.6,34.3,133.8z"/>
		<path class="st41" d="M34.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,133.1c-0.8-0.2-0.8,3,0,2.8C32.1,136.1,32.1,132.9,31.3,133.1z"/>
		<path class="st41" d="M31,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,133.8c-0.8-0.2-0.8,3,0,2.8C33.2,136.8,33.2,133.6,32.4,133.8z"/>
		<path class="st41" d="M32.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,133.1c-0.8-0.2-0.8,3,0,2.8C30.1,136.1,30.2,132.9,29.3,133.1z"/>
		<path class="st41" d="M29,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,133.8c-0.8-0.2-0.8,3,0,2.8C31.2,136.8,31.2,133.6,30.4,133.8z"/>
		<path class="st41" d="M30.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,133.1c-0.8-0.2-0.8,3,0,2.8C28.2,136.1,28.2,132.9,27.3,133.1z"/>
		<path class="st41" d="M27,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,133.8c-0.8-0.2-0.8,3,0,2.8C29.2,136.8,29.2,133.6,28.4,133.8z"/>
		<path class="st41" d="M28.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,133.1c-0.8-0.2-0.8,3,0,2.8C26.2,136.1,26.2,132.9,25.3,133.1z"/>
		<path class="st41" d="M25.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,133.8c-0.8-0.2-0.8,3,0,2.8C27.2,136.8,27.2,133.6,26.4,133.8z"/>
		<path class="st41" d="M26.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,133.1c-0.8-0.2-0.8,3,0,2.8C24.2,136.1,24.2,132.9,23.4,133.1z"/>
		<path class="st41" d="M23.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,133.8c-0.8-0.2-0.8,3,0,2.8C25.2,136.8,25.2,133.6,24.4,133.8z"/>
		<path class="st41" d="M24.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,133.1c-0.8-0.2-0.8,3,0,2.8C22.2,136.1,22.2,132.9,21.4,133.1z"/>
		<path class="st41" d="M21.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,133.8c-0.8-0.2-0.8,3,0,2.8C23.3,136.8,23.3,133.6,22.4,133.8z"/>
		<path class="st41" d="M22.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,133.1c-0.8-0.2-0.8,3,0,2.8C20.2,136.1,20.2,132.9,19.4,133.1z"/>
		<path class="st41" d="M19.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,133.8c-0.8-0.2-0.8,3,0,2.8C21.3,136.8,21.3,133.6,20.4,133.8z"/>
		<path class="st41" d="M20.1,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,133.1c-0.8-0.2-0.8,3,0,2.8C18.2,136.1,18.2,132.9,17.4,133.1z"/>
		<path class="st41" d="M17.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,133.8c-0.8-0.2-0.8,3,0,2.8C19.3,136.8,19.3,133.6,18.4,133.8z"/>
		<path class="st41" d="M18.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,133.1c-0.8-0.2-0.8,3,0,2.8C16.2,136.1,16.2,132.9,15.4,133.1z"/>
		<path class="st41" d="M15.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,133.8c-0.8-0.2-0.8,3,0,2.8C17.3,136.8,17.3,133.6,16.5,133.8z"/>
		<path class="st41" d="M16.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,133.1c-0.8-0.2-0.8,3,0,2.8C14.3,136.1,14.3,132.9,13.4,133.1z"/>
		<path class="st41" d="M13.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,133.8c-0.8-0.2-0.8,3,0,2.8C15.3,136.8,15.3,133.6,14.5,133.8z"/>
		<path class="st41" d="M14.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,133.1c-0.8-0.2-0.8,3,0,2.8C12.3,136.1,12.3,132.9,11.4,133.1z"/>
		<path class="st41" d="M11.1,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,133.8c-0.8-0.2-0.8,3,0,2.8C13.3,136.8,13.3,133.6,12.5,133.8z"/>
		<path class="st41" d="M12.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,133.1c-0.8-0.2-0.8,3,0,2.8C10.3,136.1,10.3,132.9,9.5,133.1z"/>
		<path class="st41" d="M9.2,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,133.8c-0.8-0.2-0.8,3,0,2.8C11.3,136.8,11.3,133.6,10.5,133.8z"/>
		<path class="st41" d="M10.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,133.1c-0.8-0.2-0.8,3,0,2.8C8.3,136.1,8.3,132.9,7.5,133.1z"/>
		<path class="st41" d="M7.2,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,133.8c-0.8-0.2-0.8,3,0,2.8C9.3,136.8,9.3,133.6,8.5,133.8z"/>
		<path class="st41" d="M8.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,133.1c-0.8-0.2-0.8,3,0,2.8C6.3,136.1,6.3,132.9,5.5,133.1z"/>
		<path class="st41" d="M5.2,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,133.8c-0.8-0.2-0.8,3,0,2.8C7.4,136.8,7.4,133.6,6.5,133.8z"/>
		<path class="st41" d="M6.2,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,133.1c-0.8-0.2-0.8,3,0,2.8C4.3,136.1,4.3,132.9,3.5,133.1z"/>
		<path class="st41" d="M3.2,134c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,133.8c-0.8-0.2-0.8,3,0,2.8C5.4,136.8,5.4,133.6,4.5,133.8z"/>
		<path class="st41" d="M4.3,134.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,134.5c-0.8-0.2-0.8,3,0,2.8C65.8,137.5,65.8,134.4,65,134.5z"/>
		<path class="st41" d="M64.7,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,135.2c-0.8-0.2-0.8,3,0,2.8C66.9,138.2,66.9,135,66,135.2z"/>
		<path class="st41" d="M65.7,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,134.5c-0.8-0.2-0.8,3,0,2.8C63.8,137.5,63.8,134.4,63,134.5z"/>
		<path class="st41" d="M62.7,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,135.2c-0.8-0.2-0.8,3,0,2.8C64.9,138.2,64.9,135,64,135.2z"/>
		<path class="st41" d="M63.7,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,134.5c-0.8-0.2-0.8,3,0,2.8C61.8,137.5,61.8,134.4,61,134.5z"/>
		<path class="st41" d="M60.7,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,135.2c-0.8-0.2-0.8,3,0,2.8C62.9,138.2,62.9,135,62,135.2z"/>
		<path class="st41" d="M61.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,134.5c-0.8-0.2-0.8,3,0,2.8C59.8,137.5,59.8,134.4,59,134.5z"/>
		<path class="st41" d="M58.7,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,135.2c-0.8-0.2-0.8,3,0,2.8C60.9,138.2,60.9,135,60.1,135.2z"/>
		<path class="st41" d="M59.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,134.5c-0.8-0.2-0.8,3,0,2.8C57.9,137.5,57.9,134.4,57,134.5z"/>
		<path class="st41" d="M56.7,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,135.2c-0.8-0.2-0.8,3,0,2.8C58.9,138.2,58.9,135,58.1,135.2z"/>
		<path class="st41" d="M57.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,134.5c-0.8-0.2-0.8,3,0,2.8C55.9,137.5,55.9,134.4,55,134.5z"/>
		<path class="st41" d="M54.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,135.2c-0.8-0.2-0.8,3,0,2.8C56.9,138.2,56.9,135,56.1,135.2z"/>
		<path class="st41" d="M55.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,134.5c-0.8-0.2-0.8,3,0,2.8C53.9,137.5,53.9,134.4,53.1,134.5z"/>
		<path class="st41" d="M52.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,135.2c-0.8-0.2-0.8,3,0,2.8C54.9,138.2,54.9,135,54.1,135.2z"/>
		<path class="st41" d="M53.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,134.5c-0.8-0.2-0.8,3,0,2.8C51.9,137.5,51.9,134.4,51.1,134.5z"/>
		<path class="st41" d="M50.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,135.2c-0.8-0.2-0.8,3,0,2.8C52.9,138.2,52.9,135,52.1,135.2z"/>
		<path class="st41" d="M51.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,134.5c-0.8-0.2-0.8,3,0,2.8C49.9,137.5,49.9,134.4,49.1,134.5z"/>
		<path class="st41" d="M48.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,135.2c-0.8-0.2-0.8,3,0,2.8C51,138.2,51,135,50.1,135.2z"/>
		<path class="st41" d="M49.8,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,134.5c-0.8-0.2-0.8,3,0,2.8C47.9,137.5,47.9,134.4,47.1,134.5z"/>
		<path class="st41" d="M46.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,135.2c-0.8-0.2-0.8,3,0,2.8C49,138.2,49,135,48.1,135.2z"/>
		<path class="st41" d="M47.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,134.5c-0.8-0.2-0.8,3,0,2.8C45.9,137.5,45.9,134.4,45.1,134.5z"/>
		<path class="st41" d="M44.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,135.2c-0.8-0.2-0.8,3,0,2.8C47,138.2,47,135,46.2,135.2z"/>
		<path class="st41" d="M45.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,134.5c-0.8-0.2-0.8,3,0,2.8C43.9,137.5,43.9,134.4,43.1,134.5z"/>
		<path class="st41" d="M42.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,135.2c-0.8-0.2-0.8,3,0,2.8C45,138.2,45,135,44.2,135.2z"/>
		<path class="st41" d="M43.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,134.5c-0.8-0.2-0.8,3,0,2.8C42,137.5,42,134.4,41.1,134.5z"/>
		<path class="st41" d="M40.8,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,135.2c-0.8-0.2-0.8,3,0,2.8C43,138.2,43,135,42.2,135.2z"/>
		<path class="st41" d="M41.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,134.5c-0.8-0.2-0.8,3,0,2.8C40,137.5,40,134.4,39.1,134.5z"/>
		<path class="st41" d="M38.9,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,135.2c-0.8-0.2-0.8,3,0,2.8C41,138.2,41,135,40.2,135.2z"/>
		<path class="st41" d="M39.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,134.5c-0.8-0.2-0.8,3,0,2.8C38,137.5,38,134.4,37.2,134.5z"/>
		<path class="st41" d="M36.9,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,135.2c-0.8-0.2-0.8,3,0,2.8C39,138.2,39,135,38.2,135.2z"/>
		<path class="st41" d="M37.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,134.5c-0.8-0.2-0.8,3,0,2.8C36,137.5,36,134.4,35.2,134.5z"/>
		<path class="st41" d="M34.9,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,135.2c-0.8-0.2-0.8,3,0,2.8C37,138.2,37,135,36.2,135.2z"/>
		<path class="st41" d="M35.9,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,134.5c-0.8-0.2-0.8,3,0,2.8C34.1,137.5,34.1,134.4,33.3,134.5z"/>
		<path class="st41" d="M33,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,135.2c-0.8-0.2-0.8,3,0,2.8C35.2,138.2,35.2,135,34.3,135.2z"/>
		<path class="st41" d="M34.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,134.5c-0.8-0.2-0.8,3,0,2.8C32.1,137.5,32.1,134.4,31.3,134.5z"/>
		<path class="st41" d="M31,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,135.2c-0.8-0.2-0.8,3,0,2.8C33.2,138.2,33.2,135,32.4,135.2z"/>
		<path class="st41" d="M32.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,134.5c-0.8-0.2-0.8,3,0,2.8C30.1,137.5,30.2,134.4,29.3,134.5z"/>
		<path class="st41" d="M29,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,135.2c-0.8-0.2-0.8,3,0,2.8C31.2,138.2,31.2,135,30.4,135.2z"/>
		<path class="st41" d="M30.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,134.5c-0.8-0.2-0.8,3,0,2.8C28.2,137.5,28.2,134.4,27.3,134.5z"/>
		<path class="st41" d="M27,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,135.2c-0.8-0.2-0.8,3,0,2.8C29.2,138.2,29.2,135,28.4,135.2z"/>
		<path class="st41" d="M28.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,134.5c-0.8-0.2-0.8,3,0,2.8C26.2,137.5,26.2,134.4,25.3,134.5z"/>
		<path class="st41" d="M25.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,135.2c-0.8-0.2-0.8,3,0,2.8C27.2,138.2,27.2,135,26.4,135.2z"/>
		<path class="st41" d="M26.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,134.5c-0.8-0.2-0.8,3,0,2.8C24.2,137.5,24.2,134.4,23.4,134.5z"/>
		<path class="st41" d="M23.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,135.2c-0.8-0.2-0.8,3,0,2.8C25.2,138.2,25.2,135,24.4,135.2z"/>
		<path class="st41" d="M24.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,134.5c-0.8-0.2-0.8,3,0,2.8C22.2,137.5,22.2,134.4,21.4,134.5z"/>
		<path class="st41" d="M21.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,135.2c-0.8-0.2-0.8,3,0,2.8C23.3,138.2,23.3,135,22.4,135.2z"/>
		<path class="st41" d="M22.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,134.5c-0.8-0.2-0.8,3,0,2.8C20.2,137.5,20.2,134.4,19.4,134.5z"/>
		<path class="st41" d="M19.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,135.2c-0.8-0.2-0.8,3,0,2.8C21.3,138.2,21.3,135,20.4,135.2z"/>
		<path class="st41" d="M20.1,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,134.5c-0.8-0.2-0.8,3,0,2.8C18.2,137.5,18.2,134.4,17.4,134.5z"/>
		<path class="st41" d="M17.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,135.2c-0.8-0.2-0.8,3,0,2.8C19.3,138.2,19.3,135,18.4,135.2z"/>
		<path class="st41" d="M18.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,134.5c-0.8-0.2-0.8,3,0,2.8C16.2,137.5,16.2,134.4,15.4,134.5z"/>
		<path class="st41" d="M15.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,135.2c-0.8-0.2-0.8,3,0,2.8C17.3,138.2,17.3,135,16.5,135.2z"/>
		<path class="st41" d="M16.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,134.5c-0.8-0.2-0.8,3,0,2.8C14.3,137.5,14.3,134.4,13.4,134.5z"/>
		<path class="st41" d="M13.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,135.2c-0.8-0.2-0.8,3,0,2.8C15.3,138.2,15.3,135,14.5,135.2z"/>
		<path class="st41" d="M14.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,134.5c-0.8-0.2-0.8,3,0,2.8C12.3,137.5,12.3,134.4,11.4,134.5z"/>
		<path class="st41" d="M11.1,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,135.2c-0.8-0.2-0.8,3,0,2.8C13.3,138.2,13.3,135,12.5,135.2z"/>
		<path class="st41" d="M12.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,134.5c-0.8-0.2-0.8,3,0,2.8C10.3,137.5,10.3,134.4,9.5,134.5z"/>
		<path class="st41" d="M9.2,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,135.2c-0.8-0.2-0.8,3,0,2.8C11.3,138.2,11.3,135,10.5,135.2z"/>
		<path class="st41" d="M10.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,134.5c-0.8-0.2-0.8,3,0,2.8C8.3,137.5,8.3,134.4,7.5,134.5z"/>
		<path class="st41" d="M7.2,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,135.2c-0.8-0.2-0.8,3,0,2.8C9.3,138.2,9.3,135,8.5,135.2z"/>
		<path class="st41" d="M8.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,134.5c-0.8-0.2-0.8,3,0,2.8C6.3,137.5,6.3,134.4,5.5,134.5z"/>
		<path class="st41" d="M5.2,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,135.2c-0.8-0.2-0.8,3,0,2.8C7.4,138.2,7.4,135,6.5,135.2z"/>
		<path class="st41" d="M6.2,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,134.5c-0.8-0.2-0.8,3,0,2.8C4.3,137.5,4.3,134.4,3.5,134.5z"/>
		<path class="st41" d="M3.2,135.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,135.2c-0.8-0.2-0.8,3,0,2.8C5.4,138.2,5.4,135,4.5,135.2z"/>
		<path class="st41" d="M4.3,136.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,136c-0.8-0.2-0.8,3,0,2.8C65.8,139,65.8,135.9,65,136z"/>
		<path class="st41" d="M64.7,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,136c-0.8-0.2-0.8,3,0,2.8C63.8,139,63.8,135.9,63,136z"/>
		<path class="st41" d="M62.7,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,136.7c-0.8-0.2-0.8,3,0,2.8C64.9,139.7,64.9,136.5,64,136.7z"/>
		<path class="st41" d="M63.7,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,136c-0.8-0.2-0.8,3,0,2.8C61.8,139,61.8,135.9,61,136z"/>
		<path class="st41" d="M60.7,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,136.7c-0.8-0.2-0.8,3,0,2.8C62.9,139.7,62.9,136.5,62,136.7z"/>
		<path class="st41" d="M61.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,136c-0.8-0.2-0.8,3,0,2.8C59.8,139,59.8,135.9,59,136z"/>
		<path class="st41" d="M58.7,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,136.7c-0.8-0.2-0.8,3,0,2.8C60.9,139.7,60.9,136.5,60.1,136.7z"/>
		<path class="st41" d="M59.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,136c-0.8-0.2-0.8,3,0,2.8C57.9,139,57.9,135.9,57,136z"/>
		<path class="st41" d="M56.7,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,136.7c-0.8-0.2-0.8,3,0,2.8C58.9,139.7,58.9,136.5,58.1,136.7z"/>
		<path class="st41" d="M57.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,136c-0.8-0.2-0.8,3,0,2.8C55.9,139,55.9,135.9,55,136z"/>
		<path class="st41" d="M54.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,136.7c-0.8-0.2-0.8,3,0,2.8C56.9,139.7,56.9,136.5,56.1,136.7z"/>
		<path class="st41" d="M55.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,136c-0.8-0.2-0.8,3,0,2.8C53.9,139,53.9,135.9,53.1,136z"/>
		<path class="st41" d="M52.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,136.7c-0.8-0.2-0.8,3,0,2.8C54.9,139.7,54.9,136.5,54.1,136.7z"/>
		<path class="st41" d="M53.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,136c-0.8-0.2-0.8,3,0,2.8C51.9,139,51.9,135.9,51.1,136z"/>
		<path class="st41" d="M50.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,136.7c-0.8-0.2-0.8,3,0,2.8C52.9,139.7,52.9,136.5,52.1,136.7z"/>
		<path class="st41" d="M51.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,136c-0.8-0.2-0.8,3,0,2.8C49.9,139,49.9,135.9,49.1,136z"/>
		<path class="st41" d="M48.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,136.7c-0.8-0.2-0.8,3,0,2.8C51,139.7,51,136.5,50.1,136.7z"/>
		<path class="st41" d="M49.8,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,136c-0.8-0.2-0.8,3,0,2.8C47.9,139,47.9,135.9,47.1,136z"/>
		<path class="st41" d="M46.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,136.7c-0.8-0.2-0.8,3,0,2.8C49,139.7,49,136.5,48.1,136.7z"/>
		<path class="st41" d="M47.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,136c-0.8-0.2-0.8,3,0,2.8C45.9,139,45.9,135.9,45.1,136z"/>
		<path class="st41" d="M44.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,136.7c-0.8-0.2-0.8,3,0,2.8C47,139.7,47,136.5,46.2,136.7z"/>
		<path class="st41" d="M45.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,136c-0.8-0.2-0.8,3,0,2.8C43.9,139,43.9,135.9,43.1,136z"/>
		<path class="st41" d="M42.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,136.7c-0.8-0.2-0.8,3,0,2.8C45,139.7,45,136.5,44.2,136.7z"/>
		<path class="st41" d="M43.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,136c-0.8-0.2-0.8,3,0,2.8C42,139,42,135.9,41.1,136z"/>
		<path class="st41" d="M40.8,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,136.7c-0.8-0.2-0.8,3,0,2.8C43,139.7,43,136.5,42.2,136.7z"/>
		<path class="st41" d="M41.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,136c-0.8-0.2-0.8,3,0,2.8C40,139,40,135.9,39.1,136z"/>
		<path class="st41" d="M38.9,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,136.7c-0.8-0.2-0.8,3,0,2.8C41,139.7,41,136.5,40.2,136.7z"/>
		<path class="st41" d="M39.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,136c-0.8-0.2-0.8,3,0,2.8C38,139,38,135.9,37.2,136z"/>
		<path class="st41" d="M36.9,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,136.7c-0.8-0.2-0.8,3,0,2.8C39,139.7,39,136.5,38.2,136.7z"/>
		<path class="st41" d="M37.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,136c-0.8-0.2-0.8,3,0,2.8C36,139,36,135.9,35.2,136z"/>
		<path class="st41" d="M34.9,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,136.7c-0.8-0.2-0.8,3,0,2.8C37,139.7,37,136.5,36.2,136.7z"/>
		<path class="st41" d="M35.9,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,136c-0.8-0.2-0.8,3,0,2.8C34.1,139,34.1,135.9,33.3,136z"/>
		<path class="st41" d="M33,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,136.7c-0.8-0.2-0.8,3,0,2.8C35.2,139.7,35.2,136.5,34.3,136.7z"/>
		<path class="st41" d="M34.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,136c-0.8-0.2-0.8,3,0,2.8C32.1,139,32.1,135.9,31.3,136z"/>
		<path class="st41" d="M31,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,136.7c-0.8-0.2-0.8,3,0,2.8C33.2,139.7,33.2,136.5,32.4,136.7z"/>
		<path class="st41" d="M32.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,136c-0.8-0.2-0.8,3,0,2.8C30.1,139,30.2,135.9,29.3,136z"/>
		<path class="st41" d="M29,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,136.7c-0.8-0.2-0.8,3,0,2.8C31.2,139.7,31.2,136.5,30.4,136.7z"/>
		<path class="st41" d="M30.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,136c-0.8-0.2-0.8,3,0,2.8C28.2,139,28.2,135.9,27.3,136z"/>
		<path class="st41" d="M27,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,136.7c-0.8-0.2-0.8,3,0,2.8C29.2,139.7,29.2,136.5,28.4,136.7z"/>
		<path class="st41" d="M28.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,136c-0.8-0.2-0.8,3,0,2.8C26.2,139,26.2,135.9,25.3,136z"/>
		<path class="st41" d="M25.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,136.7c-0.8-0.2-0.8,3,0,2.8C27.2,139.7,27.2,136.5,26.4,136.7z"/>
		<path class="st41" d="M26.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,136c-0.8-0.2-0.8,3,0,2.8C24.2,139,24.2,135.9,23.4,136z"/>
		<path class="st41" d="M23.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,136.7c-0.8-0.2-0.8,3,0,2.8C25.2,139.7,25.2,136.5,24.4,136.7z"/>
		<path class="st41" d="M24.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,136c-0.8-0.2-0.8,3,0,2.8C22.2,139,22.2,135.9,21.4,136z"/>
		<path class="st41" d="M21.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,136.7c-0.8-0.2-0.8,3,0,2.8C23.3,139.7,23.3,136.5,22.4,136.7z"/>
		<path class="st41" d="M22.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,136c-0.8-0.2-0.8,3,0,2.8C20.2,139,20.2,135.9,19.4,136z"/>
		<path class="st41" d="M19.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,136.7c-0.8-0.2-0.8,3,0,2.8C21.3,139.7,21.3,136.5,20.4,136.7z"/>
		<path class="st41" d="M20.1,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,136c-0.8-0.2-0.8,3,0,2.8C18.2,139,18.2,135.9,17.4,136z"/>
		<path class="st41" d="M17.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,136.7c-0.8-0.2-0.8,3,0,2.8C19.3,139.7,19.3,136.5,18.4,136.7z"/>
		<path class="st41" d="M18.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,136c-0.8-0.2-0.8,3,0,2.8C16.2,139,16.2,135.9,15.4,136z"/>
		<path class="st41" d="M15.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,136.7c-0.8-0.2-0.8,3,0,2.8C17.3,139.7,17.3,136.5,16.5,136.7z"/>
		<path class="st41" d="M16.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,136c-0.8-0.2-0.8,3,0,2.8C14.3,139,14.3,135.9,13.4,136z"/>
		<path class="st41" d="M13.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,136.7c-0.8-0.2-0.8,3,0,2.8C15.3,139.7,15.3,136.5,14.5,136.7z"/>
		<path class="st41" d="M14.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,136c-0.8-0.2-0.8,3,0,2.8C12.3,139,12.3,135.9,11.4,136z"/>
		<path class="st41" d="M11.1,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,136.7c-0.8-0.2-0.8,3,0,2.8C13.3,139.7,13.3,136.5,12.5,136.7z"/>
		<path class="st41" d="M12.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,136c-0.8-0.2-0.8,3,0,2.8C10.3,139,10.3,135.9,9.5,136z"/>
		<path class="st41" d="M9.2,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,136.7c-0.8-0.2-0.8,3,0,2.8C11.3,139.7,11.3,136.5,10.5,136.7z"/>
		<path class="st41" d="M10.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,136c-0.8-0.2-0.8,3,0,2.8C8.3,139,8.3,135.9,7.5,136z"/>
		<path class="st41" d="M7.2,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,136.7c-0.8-0.2-0.8,3,0,2.8C9.3,139.7,9.3,136.5,8.5,136.7z"/>
		<path class="st41" d="M8.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,136c-0.8-0.2-0.8,3,0,2.8C6.3,139,6.3,135.9,5.5,136z"/>
		<path class="st41" d="M5.2,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,136.7c-0.8-0.2-0.8,3,0,2.8C7.4,139.7,7.4,136.5,6.5,136.7z"/>
		<path class="st41" d="M6.2,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,136c-0.8-0.2-0.8,3,0,2.8C4.3,139,4.3,135.9,3.5,136z"/>
		<path class="st41" d="M3.2,136.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,136.7c-0.8-0.2-0.8,3,0,2.8C5.4,139.7,5.4,136.5,4.5,136.7z"/>
		<path class="st41" d="M4.3,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,137.5c-0.8-0.2-0.8,3,0,2.8C4.3,140.5,4.3,137.3,3.5,137.5z"/>
		<path class="st42" d="M3.2,138.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M66,136.7c-0.8-0.2-0.8,3,0,2.8C66.9,139.7,66.9,136.5,66,136.7z"/>
		<path class="st41" d="M65.7,137.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65.1,137.4c-0.8-0.2-0.8,3,0,2.8C65.9,140.4,65.9,137.3,65.1,137.4z"/>
		<path class="st41" d="M64.8,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63.1,137.4c-0.8-0.2-0.8,3,0,2.8C63.9,140.4,63.9,137.3,63.1,137.4z"/>
		<path class="st41" d="M62.8,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61.1,137.4c-0.8-0.2-0.8,3,0,2.8C61.9,140.4,61.9,137.3,61.1,137.4z"/>
		<path class="st41" d="M60.8,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59.1,137.4c-0.8-0.2-0.8,3,0,2.8C59.9,140.4,59.9,137.3,59.1,137.4z"/>
		<path class="st41" d="M58.8,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57.1,137.4c-0.8-0.2-0.8,3,0,2.8C58,140.4,58,137.3,57.1,137.4z"/>
		<path class="st41" d="M56.8,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55.1,137.4c-0.8-0.2-0.8,3,0,2.8C56,140.4,56,137.3,55.1,137.4z"/>
		<path class="st41" d="M54.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.2,137.4c-0.8-0.2-0.8,3,0,2.8C54,140.4,54,137.3,53.2,137.4z"/>
		<path class="st41" d="M52.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.2,137.4c-0.8-0.2-0.8,3,0,2.8C52,140.4,52,137.3,51.2,137.4z"/>
		<path class="st41" d="M50.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.2,137.4c-0.8-0.2-0.8,3,0,2.8C50,140.4,50,137.3,49.2,137.4z"/>
		<path class="st41" d="M48.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.2,137.4c-0.8-0.2-0.8,3,0,2.8C48,140.4,48,137.3,47.2,137.4z"/>
		<path class="st41" d="M46.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.2,137.4c-0.8-0.2-0.8,3,0,2.8C46,140.4,46,137.3,45.2,137.4z"/>
		<path class="st41" d="M44.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.2,137.4c-0.8-0.2-0.8,3,0,2.8C44.1,140.4,44.1,137.3,43.2,137.4z"/>
		<path class="st41" d="M42.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.2,137.4c-0.8-0.2-0.8,3,0,2.8C42.1,140.4,42.1,137.3,41.2,137.4z"/>
		<path class="st41" d="M40.9,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.2,137.4c-0.8-0.2-0.8,3,0,2.8C40.1,140.4,40.1,137.3,39.2,137.4z"/>
		<path class="st41" d="M39,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.3,137.4c-0.8-0.2-0.8,3,0,2.8C38.1,140.4,38.1,137.3,37.3,137.4z"/>
		<path class="st41" d="M37,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.3,137.4c-0.8-0.2-0.8,3,0,2.8C36.1,140.4,36.1,137.3,35.3,137.4z"/>
		<path class="st41" d="M35,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.4,137.4c-0.8-0.2-0.8,3,0,2.8C34.2,140.4,34.2,137.3,33.4,137.4z"/>
		<path class="st41" d="M33.1,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.4,137.4c-0.8-0.2-0.8,3,0,2.8C32.2,140.4,32.2,137.3,31.4,137.4z"/>
		<path class="st41" d="M31.1,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.4,137.4c-0.8-0.2-0.8,3,0,2.8C30.3,140.4,30.3,137.3,29.4,137.4z"/>
		<path class="st41" d="M29.1,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.4,137.4c-0.8-0.2-0.8,3,0,2.8C28.3,140.4,28.3,137.3,27.4,137.4z"/>
		<path class="st41" d="M27.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.5,137.4c-0.8-0.2-0.8,3,0,2.8C26.3,140.4,26.3,137.3,25.5,137.4z"/>
		<path class="st41" d="M25.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.5,137.4c-0.8-0.2-0.8,3,0,2.8C24.3,140.4,24.3,137.3,23.5,137.4z"/>
		<path class="st41" d="M23.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.5,137.4c-0.8-0.2-0.8,3,0,2.8C22.3,140.4,22.3,137.3,21.5,137.4z"/>
		<path class="st41" d="M21.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.5,137.4c-0.8-0.2-0.8,3,0,2.8C20.3,140.4,20.3,137.3,19.5,137.4z"/>
		<path class="st41" d="M19.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.5,137.4c-0.8-0.2-0.8,3,0,2.8C18.3,140.4,18.3,137.3,17.5,137.4z"/>
		<path class="st41" d="M17.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.5,137.4c-0.8-0.2-0.8,3,0,2.8C16.3,140.4,16.3,137.3,15.5,137.4z"/>
		<path class="st41" d="M15.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.5,137.4c-0.8-0.2-0.8,3,0,2.8C14.4,140.4,14.4,137.3,13.5,137.4z"/>
		<path class="st41" d="M13.2,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.5,137.4c-0.8-0.2-0.8,3,0,2.8C12.4,140.4,12.4,137.3,11.5,137.4z"/>
		<path class="st41" d="M11.3,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.6,137.4c-0.8-0.2-0.8,3,0,2.8C10.4,140.4,10.4,137.3,9.6,137.4z"/>
		<path class="st41" d="M9.3,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.6,137.4c-0.8-0.2-0.8,3,0,2.8C8.4,140.4,8.4,137.3,7.6,137.4z"/>
		<path class="st41" d="M7.3,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.6,137.4c-0.8-0.2-0.8,3,0,2.8C6.4,140.4,6.4,137.3,5.6,137.4z"/>
		<path class="st42" d="M5.3,138.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st41" d="M24.4,149.5c-0.8-0.2-0.8,3,0,2.8C25.2,152.5,25.2,149.3,24.4,149.5z"/>
		<path class="st41" d="M24.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M20.4,149.5c-0.8-0.2-0.8,3,0,2.8C21.3,152.5,21.3,149.3,20.4,149.5z"/>
		<path class="st41" d="M20.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M18.4,149.5c-0.8-0.2-0.8,3,0,2.8C19.3,152.5,19.3,149.3,18.4,149.5z"/>
		<path class="st41" d="M18.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M16.5,149.5c-0.8-0.2-0.8,3,0,2.8C17.3,152.5,17.3,149.3,16.5,149.5z"/>
		<path class="st41" d="M16.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M14.5,149.5c-0.8-0.2-0.8,3,0,2.8C15.3,152.5,15.3,149.3,14.5,149.5z"/>
		<path class="st41" d="M14.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M12.5,149.5c-0.8-0.2-0.8,3,0,2.8C13.3,152.5,13.3,149.3,12.5,149.5z"/>
		<path class="st41" d="M12.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M10.5,149.5c-0.8-0.2-0.8,3,0,2.8C11.3,152.5,11.3,149.3,10.5,149.5z"/>
		<path class="st41" d="M10.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M8.5,149.5c-0.8-0.2-0.8,3,0,2.8C9.3,152.5,9.3,149.3,8.5,149.5z"/>
		<path class="st41" d="M8.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M6.5,149.5c-0.8-0.2-0.8,3,0,2.8C7.4,152.5,7.4,149.3,6.5,149.5z"/>
		<path class="st41" d="M6.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M4.5,149.5c-0.8-0.2-0.8,3,0,2.8C5.4,152.5,5.4,149.3,4.5,149.5z"/>
		<path class="st41" d="M4.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M22.4,149.5c-0.8-0.2-0.8,3,0,2.8C23.3,152.5,23.3,149.3,22.4,149.5z"/>
		<path class="st41" d="M22.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,150.2c-0.8-0.2-0.8,3,0,2.8C65.8,153.2,65.8,150.1,65,150.2z"/>
		<path class="st41" d="M64.7,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,150.9c-0.8-0.2-0.8,3,0,2.8C66.9,153.9,66.9,150.7,66,150.9z"/>
		<path class="st41" d="M65.7,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,150.2c-0.8-0.2-0.8,3,0,2.8C63.8,153.2,63.8,150.1,63,150.2z"/>
		<path class="st41" d="M62.7,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,150.9c-0.8-0.2-0.8,3,0,2.8C64.9,153.9,64.9,150.7,64,150.9z"/>
		<path class="st41" d="M63.7,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,150.2c-0.8-0.2-0.8,3,0,2.8C61.8,153.2,61.8,150.1,61,150.2z"/>
		<path class="st41" d="M60.7,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,150.9c-0.8-0.2-0.8,3,0,2.8C62.9,153.9,62.9,150.7,62,150.9z"/>
		<path class="st41" d="M61.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,150.2c-0.8-0.2-0.8,3,0,2.8C59.8,153.2,59.8,150.1,59,150.2z"/>
		<path class="st41" d="M58.7,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,150.9c-0.8-0.2-0.8,3,0,2.8C60.9,153.9,60.9,150.7,60.1,150.9z"/>
		<path class="st41" d="M59.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,150.2c-0.8-0.2-0.8,3,0,2.8C57.9,153.2,57.9,150.1,57,150.2z"/>
		<path class="st41" d="M56.7,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,150.9c-0.8-0.2-0.8,3,0,2.8C58.9,153.9,58.9,150.7,58.1,150.9z"/>
		<path class="st41" d="M57.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,150.2c-0.8-0.2-0.8,3,0,2.8C55.9,153.2,55.9,150.1,55,150.2z"/>
		<path class="st41" d="M54.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,150.9c-0.8-0.2-0.8,3,0,2.8C56.9,153.9,56.9,150.7,56.1,150.9z"/>
		<path class="st41" d="M55.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,150.2c-0.8-0.2-0.8,3,0,2.8C53.9,153.2,53.9,150.1,53.1,150.2z"/>
		<path class="st41" d="M52.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,150.9c-0.8-0.2-0.8,3,0,2.8C54.9,153.9,54.9,150.7,54.1,150.9z"/>
		<path class="st41" d="M53.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,150.2c-0.8-0.2-0.8,3,0,2.8C51.9,153.2,51.9,150.1,51.1,150.2z"/>
		<path class="st41" d="M50.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,150.9c-0.8-0.2-0.8,3,0,2.8C52.9,153.9,52.9,150.7,52.1,150.9z"/>
		<path class="st41" d="M51.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,150.2c-0.8-0.2-0.8,3,0,2.8C49.9,153.2,49.9,150.1,49.1,150.2z"/>
		<path class="st41" d="M48.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,150.9c-0.8-0.2-0.8,3,0,2.8C51,153.9,51,150.7,50.1,150.9z"/>
		<path class="st41" d="M49.8,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,150.2c-0.8-0.2-0.8,3,0,2.8C47.9,153.2,47.9,150.1,47.1,150.2z"/>
		<path class="st41" d="M46.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,150.9c-0.8-0.2-0.8,3,0,2.8C49,153.9,49,150.7,48.1,150.9z"/>
		<path class="st41" d="M47.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,150.2c-0.8-0.2-0.8,3,0,2.8C45.9,153.2,45.9,150.1,45.1,150.2z"/>
		<path class="st41" d="M44.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,150.9c-0.8-0.2-0.8,3,0,2.8C47,153.9,47,150.7,46.2,150.9z"/>
		<path class="st41" d="M45.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,150.2c-0.8-0.2-0.8,3,0,2.8C43.9,153.2,43.9,150.1,43.1,150.2z"/>
		<path class="st41" d="M42.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,150.9c-0.8-0.2-0.8,3,0,2.8C45,153.9,45,150.7,44.2,150.9z"/>
		<path class="st41" d="M43.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,150.2c-0.8-0.2-0.8,3,0,2.8C42,153.2,42,150.1,41.1,150.2z"/>
		<path class="st41" d="M40.8,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,150.9c-0.8-0.2-0.8,3,0,2.8C43,153.9,43,150.7,42.2,150.9z"/>
		<path class="st41" d="M41.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,150.2c-0.8-0.2-0.8,3,0,2.8C40,153.2,40,150.1,39.1,150.2z"/>
		<path class="st41" d="M38.9,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,150.9c-0.8-0.2-0.8,3,0,2.8C41,153.9,41,150.7,40.2,150.9z"/>
		<path class="st41" d="M39.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,150.2c-0.8-0.2-0.8,3,0,2.8C38,153.2,38,150.1,37.2,150.2z"/>
		<path class="st41" d="M36.9,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,150.9c-0.8-0.2-0.8,3,0,2.8C39,153.9,39,150.7,38.2,150.9z"/>
		<path class="st41" d="M37.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,150.2c-0.8-0.2-0.8,3,0,2.8C36,153.2,36,150.1,35.2,150.2z"/>
		<path class="st41" d="M34.9,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,150.9c-0.8-0.2-0.8,3,0,2.8C37,153.9,37,150.7,36.2,150.9z"/>
		<path class="st41" d="M35.9,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,150.2c-0.8-0.2-0.8,3,0,2.8C34.1,153.2,34.1,150.1,33.3,150.2z"/>
		<path class="st41" d="M33,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,150.9c-0.8-0.2-0.8,3,0,2.8C35.2,153.9,35.2,150.7,34.3,150.9z"/>
		<path class="st41" d="M34.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,150.2c-0.8-0.2-0.8,3,0,2.8C32.1,153.2,32.1,150.1,31.3,150.2z"/>
		<path class="st41" d="M31,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,150.9c-0.8-0.2-0.8,3,0,2.8C33.2,153.9,33.2,150.7,32.4,150.9z"/>
		<path class="st41" d="M32.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,150.2c-0.8-0.2-0.8,3,0,2.8C30.1,153.2,30.2,150.1,29.3,150.2z"/>
		<path class="st41" d="M29,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,150.9c-0.8-0.2-0.8,3,0,2.8C31.2,153.9,31.2,150.7,30.4,150.9z"/>
		<path class="st41" d="M30.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,150.2c-0.8-0.2-0.8,3,0,2.8C28.2,153.2,28.2,150.1,27.3,150.2z"/>
		<path class="st41" d="M27,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,150.9c-0.8-0.2-0.8,3,0,2.8C29.2,153.9,29.2,150.7,28.4,150.9z"/>
		<path class="st41" d="M28.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,150.2c-0.8-0.2-0.8,3,0,2.8C26.2,153.2,26.2,150.1,25.3,150.2z"/>
		<path class="st41" d="M25.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,150.9c-0.8-0.2-0.8,3,0,2.8C27.2,153.9,27.2,150.7,26.4,150.9z"/>
		<path class="st41" d="M26.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,150.2c-0.8-0.2-0.8,3,0,2.8C24.2,153.2,24.2,150.1,23.4,150.2z"/>
		<path class="st41" d="M23.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,150.9c-0.8-0.2-0.8,3,0,2.8C25.2,153.9,25.2,150.7,24.4,150.9z"/>
		<path class="st41" d="M24.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,150.2c-0.8-0.2-0.8,3,0,2.8C22.2,153.2,22.2,150.1,21.4,150.2z"/>
		<path class="st41" d="M21.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,150.9c-0.8-0.2-0.8,3,0,2.8C23.3,153.9,23.3,150.7,22.4,150.9z"/>
		<path class="st41" d="M22.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,150.2c-0.8-0.2-0.8,3,0,2.8C20.2,153.2,20.2,150.1,19.4,150.2z"/>
		<path class="st41" d="M19.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,150.9c-0.8-0.2-0.8,3,0,2.8C21.3,153.9,21.3,150.7,20.4,150.9z"/>
		<path class="st41" d="M20.1,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,150.2c-0.8-0.2-0.8,3,0,2.8C18.2,153.2,18.2,150.1,17.4,150.2z"/>
		<path class="st41" d="M17.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,150.9c-0.8-0.2-0.8,3,0,2.8C19.3,153.9,19.3,150.7,18.4,150.9z"/>
		<path class="st41" d="M18.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,150.2c-0.8-0.2-0.8,3,0,2.8C16.2,153.2,16.2,150.1,15.4,150.2z"/>
		<path class="st41" d="M15.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,150.9c-0.8-0.2-0.8,3,0,2.8C17.3,153.9,17.3,150.7,16.5,150.9z"/>
		<path class="st41" d="M16.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,150.2c-0.8-0.2-0.8,3,0,2.8C14.3,153.2,14.3,150.1,13.4,150.2z"/>
		<path class="st41" d="M13.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,150.9c-0.8-0.2-0.8,3,0,2.8C15.3,153.9,15.3,150.7,14.5,150.9z"/>
		<path class="st41" d="M14.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,150.2c-0.8-0.2-0.8,3,0,2.8C12.3,153.2,12.3,150.1,11.4,150.2z"/>
		<path class="st41" d="M11.1,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,150.9c-0.8-0.2-0.8,3,0,2.8C13.3,153.9,13.3,150.7,12.5,150.9z"/>
		<path class="st41" d="M12.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,150.2c-0.8-0.2-0.8,3,0,2.8C10.3,153.2,10.3,150.1,9.5,150.2z"/>
		<path class="st41" d="M9.2,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,150.9c-0.8-0.2-0.8,3,0,2.8C11.3,153.9,11.3,150.7,10.5,150.9z"/>
		<path class="st41" d="M10.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,150.2c-0.8-0.2-0.8,3,0,2.8C8.3,153.2,8.3,150.1,7.5,150.2z"/>
		<path class="st41" d="M7.2,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,150.9c-0.8-0.2-0.8,3,0,2.8C9.3,153.9,9.3,150.7,8.5,150.9z"/>
		<path class="st41" d="M8.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,150.2c-0.8-0.2-0.8,3,0,2.8C6.3,153.2,6.3,150.1,5.5,150.2z"/>
		<path class="st41" d="M5.2,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,150.9c-0.8-0.2-0.8,3,0,2.8C7.4,153.9,7.4,150.7,6.5,150.9z"/>
		<path class="st41" d="M6.2,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,150.2c-0.8-0.2-0.8,3,0,2.8C4.3,153.2,4.3,150.1,3.5,150.2z"/>
		<path class="st41" d="M3.2,151.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,150.9c-0.8-0.2-0.8,3,0,2.8C5.4,153.9,5.4,150.7,4.5,150.9z"/>
		<path class="st41" d="M4.3,151.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,151.6c-0.8-0.2-0.8,3,0,2.8C65.8,154.6,65.8,151.5,65,151.6z"/>
		<path class="st41" d="M64.7,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,152.3c-0.8-0.2-0.8,3,0,2.8C66.9,155.3,66.9,152.1,66,152.3z"/>
		<path class="st41" d="M65.7,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,151.6c-0.8-0.2-0.8,3,0,2.8C63.8,154.6,63.8,151.5,63,151.6z"/>
		<path class="st41" d="M62.7,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,152.3c-0.8-0.2-0.8,3,0,2.8C64.9,155.3,64.9,152.1,64,152.3z"/>
		<path class="st41" d="M63.7,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,151.6c-0.8-0.2-0.8,3,0,2.8C61.8,154.6,61.8,151.5,61,151.6z"/>
		<path class="st41" d="M60.7,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,152.3c-0.8-0.2-0.8,3,0,2.8C62.9,155.3,62.9,152.1,62,152.3z"/>
		<path class="st41" d="M61.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,151.6c-0.8-0.2-0.8,3,0,2.8C59.8,154.6,59.8,151.5,59,151.6z"/>
		<path class="st41" d="M58.7,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,152.3c-0.8-0.2-0.8,3,0,2.8C60.9,155.3,60.9,152.1,60.1,152.3z"/>
		<path class="st41" d="M59.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,151.6c-0.8-0.2-0.8,3,0,2.8C57.9,154.6,57.9,151.5,57,151.6z"/>
		<path class="st41" d="M56.7,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,152.3c-0.8-0.2-0.8,3,0,2.8C58.9,155.3,58.9,152.1,58.1,152.3z"/>
		<path class="st41" d="M57.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,151.6c-0.8-0.2-0.8,3,0,2.8C55.9,154.6,55.9,151.5,55,151.6z"/>
		<path class="st41" d="M54.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,152.3c-0.8-0.2-0.8,3,0,2.8C56.9,155.3,56.9,152.1,56.1,152.3z"/>
		<path class="st41" d="M55.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,151.6c-0.8-0.2-0.8,3,0,2.8C53.9,154.6,53.9,151.5,53.1,151.6z"/>
		<path class="st41" d="M52.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,152.3c-0.8-0.2-0.8,3,0,2.8C54.9,155.3,54.9,152.1,54.1,152.3z"/>
		<path class="st41" d="M53.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,151.6c-0.8-0.2-0.8,3,0,2.8C51.9,154.6,51.9,151.5,51.1,151.6z"/>
		<path class="st41" d="M50.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,152.3c-0.8-0.2-0.8,3,0,2.8C52.9,155.3,52.9,152.1,52.1,152.3z"/>
		<path class="st41" d="M51.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,151.6c-0.8-0.2-0.8,3,0,2.8C49.9,154.6,49.9,151.5,49.1,151.6z"/>
		<path class="st41" d="M48.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,152.3c-0.8-0.2-0.8,3,0,2.8C51,155.3,51,152.1,50.1,152.3z"/>
		<path class="st41" d="M49.8,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,151.6c-0.8-0.2-0.8,3,0,2.8C47.9,154.6,47.9,151.5,47.1,151.6z"/>
		<path class="st41" d="M46.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,152.3c-0.8-0.2-0.8,3,0,2.8C49,155.3,49,152.1,48.1,152.3z"/>
		<path class="st41" d="M47.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,151.6c-0.8-0.2-0.8,3,0,2.8C45.9,154.6,45.9,151.5,45.1,151.6z"/>
		<path class="st41" d="M44.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,152.3c-0.8-0.2-0.8,3,0,2.8C47,155.3,47,152.1,46.2,152.3z"/>
		<path class="st41" d="M45.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,151.6c-0.8-0.2-0.8,3,0,2.8C43.9,154.6,43.9,151.5,43.1,151.6z"/>
		<path class="st41" d="M42.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,152.3c-0.8-0.2-0.8,3,0,2.8C45,155.3,45,152.1,44.2,152.3z"/>
		<path class="st41" d="M43.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,151.6c-0.8-0.2-0.8,3,0,2.8C42,154.6,42,151.5,41.1,151.6z"/>
		<path class="st41" d="M40.8,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,152.3c-0.8-0.2-0.8,3,0,2.8C43,155.3,43,152.1,42.2,152.3z"/>
		<path class="st41" d="M41.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,151.6c-0.8-0.2-0.8,3,0,2.8C40,154.6,40,151.5,39.1,151.6z"/>
		<path class="st41" d="M38.9,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,152.3c-0.8-0.2-0.8,3,0,2.8C41,155.3,41,152.1,40.2,152.3z"/>
		<path class="st41" d="M39.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,151.6c-0.8-0.2-0.8,3,0,2.8C38,154.6,38,151.5,37.2,151.6z"/>
		<path class="st41" d="M36.9,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,152.3c-0.8-0.2-0.8,3,0,2.8C39,155.3,39,152.1,38.2,152.3z"/>
		<path class="st41" d="M37.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,151.6c-0.8-0.2-0.8,3,0,2.8C36,154.6,36,151.5,35.2,151.6z"/>
		<path class="st41" d="M34.9,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,152.3c-0.8-0.2-0.8,3,0,2.8C37,155.3,37,152.1,36.2,152.3z"/>
		<path class="st41" d="M35.9,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,151.6c-0.8-0.2-0.8,3,0,2.8C34.1,154.6,34.1,151.5,33.3,151.6z"/>
		<path class="st41" d="M33,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,152.3c-0.8-0.2-0.8,3,0,2.8C35.2,155.3,35.2,152.1,34.3,152.3z"/>
		<path class="st41" d="M34.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,151.6c-0.8-0.2-0.8,3,0,2.8C32.1,154.6,32.1,151.5,31.3,151.6z"/>
		<path class="st41" d="M31,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,152.3c-0.8-0.2-0.8,3,0,2.8C33.2,155.3,33.2,152.1,32.4,152.3z"/>
		<path class="st41" d="M32.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,151.6c-0.8-0.2-0.8,3,0,2.8C30.1,154.6,30.2,151.5,29.3,151.6z"/>
		<path class="st41" d="M29,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,152.3c-0.8-0.2-0.8,3,0,2.8C31.2,155.3,31.2,152.1,30.4,152.3z"/>
		<path class="st41" d="M30.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,151.6c-0.8-0.2-0.8,3,0,2.8C28.2,154.6,28.2,151.5,27.3,151.6z"/>
		<path class="st41" d="M27,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,152.3c-0.8-0.2-0.8,3,0,2.8C29.2,155.3,29.2,152.1,28.4,152.3z"/>
		<path class="st41" d="M28.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,151.6c-0.8-0.2-0.8,3,0,2.8C26.2,154.6,26.2,151.5,25.3,151.6z"/>
		<path class="st41" d="M25.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,152.3c-0.8-0.2-0.8,3,0,2.8C27.2,155.3,27.2,152.1,26.4,152.3z"/>
		<path class="st41" d="M26.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,151.6c-0.8-0.2-0.8,3,0,2.8C24.2,154.6,24.2,151.5,23.4,151.6z"/>
		<path class="st41" d="M23.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,152.3c-0.8-0.2-0.8,3,0,2.8C25.2,155.3,25.2,152.1,24.4,152.3z"/>
		<path class="st41" d="M24.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,151.6c-0.8-0.2-0.8,3,0,2.8C22.2,154.6,22.2,151.5,21.4,151.6z"/>
		<path class="st41" d="M21.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,152.3c-0.8-0.2-0.8,3,0,2.8C23.3,155.3,23.3,152.1,22.4,152.3z"/>
		<path class="st41" d="M22.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,151.6c-0.8-0.2-0.8,3,0,2.8C20.2,154.6,20.2,151.5,19.4,151.6z"/>
		<path class="st41" d="M19.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,152.3c-0.8-0.2-0.8,3,0,2.8C21.3,155.3,21.3,152.1,20.4,152.3z"/>
		<path class="st41" d="M20.1,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,151.6c-0.8-0.2-0.8,3,0,2.8C18.2,154.6,18.2,151.5,17.4,151.6z"/>
		<path class="st41" d="M17.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,152.3c-0.8-0.2-0.8,3,0,2.8C19.3,155.3,19.3,152.1,18.4,152.3z"/>
		<path class="st41" d="M18.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,151.6c-0.8-0.2-0.8,3,0,2.8C16.2,154.6,16.2,151.5,15.4,151.6z"/>
		<path class="st41" d="M15.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,152.3c-0.8-0.2-0.8,3,0,2.8C17.3,155.3,17.3,152.1,16.5,152.3z"/>
		<path class="st41" d="M16.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,151.6c-0.8-0.2-0.8,3,0,2.8C14.3,154.6,14.3,151.5,13.4,151.6z"/>
		<path class="st41" d="M13.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,152.3c-0.8-0.2-0.8,3,0,2.8C15.3,155.3,15.3,152.1,14.5,152.3z"/>
		<path class="st41" d="M14.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,151.6c-0.8-0.2-0.8,3,0,2.8C12.3,154.6,12.3,151.5,11.4,151.6z"/>
		<path class="st41" d="M11.1,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,152.3c-0.8-0.2-0.8,3,0,2.8C13.3,155.3,13.3,152.1,12.5,152.3z"/>
		<path class="st41" d="M12.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,151.6c-0.8-0.2-0.8,3,0,2.8C10.3,154.6,10.3,151.5,9.5,151.6z"/>
		<path class="st41" d="M9.2,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,152.3c-0.8-0.2-0.8,3,0,2.8C11.3,155.3,11.3,152.1,10.5,152.3z"/>
		<path class="st41" d="M10.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,151.6c-0.8-0.2-0.8,3,0,2.8C8.3,154.6,8.3,151.5,7.5,151.6z"/>
		<path class="st41" d="M7.2,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,152.3c-0.8-0.2-0.8,3,0,2.8C9.3,155.3,9.3,152.1,8.5,152.3z"/>
		<path class="st41" d="M8.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,151.6c-0.8-0.2-0.8,3,0,2.8C6.3,154.6,6.3,151.5,5.5,151.6z"/>
		<path class="st41" d="M5.2,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,152.3c-0.8-0.2-0.8,3,0,2.8C7.4,155.3,7.4,152.1,6.5,152.3z"/>
		<path class="st41" d="M6.2,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,151.6c-0.8-0.2-0.8,3,0,2.8C4.3,154.6,4.3,151.5,3.5,151.6z"/>
		<path class="st41" d="M3.2,152.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,152.3c-0.8-0.2-0.8,3,0,2.8C5.4,155.3,5.4,152.1,4.5,152.3z"/>
		<path class="st41" d="M4.3,153.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,153c-0.8-0.2-0.8,3,0,2.8C65.8,156,65.8,152.8,65,153z"/>
		<path class="st41" d="M64.7,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,153.7c-0.8-0.2-0.8,3,0,2.8C66.9,156.7,66.9,153.5,66,153.7z"/>
		<path class="st41" d="M65.7,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,153c-0.8-0.2-0.8,3,0,2.8C63.8,156,63.8,152.8,63,153z"/>
		<path class="st41" d="M62.7,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,153.7c-0.8-0.2-0.8,3,0,2.8C64.9,156.7,64.9,153.5,64,153.7z"/>
		<path class="st41" d="M63.7,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,153c-0.8-0.2-0.8,3,0,2.8C61.8,156,61.8,152.8,61,153z"/>
		<path class="st41" d="M60.7,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,153.7c-0.8-0.2-0.8,3,0,2.8C62.9,156.7,62.9,153.5,62,153.7z"/>
		<path class="st41" d="M61.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,153c-0.8-0.2-0.8,3,0,2.8C59.8,156,59.8,152.8,59,153z"/>
		<path class="st41" d="M58.7,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,153.7c-0.8-0.2-0.8,3,0,2.8C60.9,156.7,60.9,153.5,60.1,153.7z"/>
		<path class="st41" d="M59.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,153c-0.8-0.2-0.8,3,0,2.8C57.9,156,57.9,152.8,57,153z"/>
		<path class="st41" d="M56.7,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,153.7c-0.8-0.2-0.8,3,0,2.8C58.9,156.7,58.9,153.5,58.1,153.7z"/>
		<path class="st41" d="M57.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,153c-0.8-0.2-0.8,3,0,2.8C55.9,156,55.9,152.8,55,153z"/>
		<path class="st41" d="M54.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,153.7c-0.8-0.2-0.8,3,0,2.8C56.9,156.7,56.9,153.5,56.1,153.7z"/>
		<path class="st41" d="M55.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,153c-0.8-0.2-0.8,3,0,2.8C53.9,156,53.9,152.8,53.1,153z"/>
		<path class="st41" d="M52.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,153.7c-0.8-0.2-0.8,3,0,2.8C54.9,156.7,54.9,153.5,54.1,153.7z"/>
		<path class="st41" d="M53.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,153c-0.8-0.2-0.8,3,0,2.8C51.9,156,51.9,152.8,51.1,153z"/>
		<path class="st41" d="M50.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,153.7c-0.8-0.2-0.8,3,0,2.8C52.9,156.7,52.9,153.5,52.1,153.7z"/>
		<path class="st41" d="M51.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,153c-0.8-0.2-0.8,3,0,2.8C49.9,156,49.9,152.8,49.1,153z"/>
		<path class="st41" d="M48.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,153.7c-0.8-0.2-0.8,3,0,2.8C51,156.7,51,153.5,50.1,153.7z"/>
		<path class="st41" d="M49.8,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,153c-0.8-0.2-0.8,3,0,2.8C47.9,156,47.9,152.8,47.1,153z"/>
		<path class="st41" d="M46.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,153.7c-0.8-0.2-0.8,3,0,2.8C49,156.7,49,153.5,48.1,153.7z"/>
		<path class="st41" d="M47.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,153c-0.8-0.2-0.8,3,0,2.8C45.9,156,45.9,152.8,45.1,153z"/>
		<path class="st41" d="M44.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,153.7c-0.8-0.2-0.8,3,0,2.8C47,156.7,47,153.5,46.2,153.7z"/>
		<path class="st41" d="M45.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,153c-0.8-0.2-0.8,3,0,2.8C43.9,156,43.9,152.8,43.1,153z"/>
		<path class="st41" d="M42.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,153.7c-0.8-0.2-0.8,3,0,2.8C45,156.7,45,153.5,44.2,153.7z"/>
		<path class="st41" d="M43.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,153c-0.8-0.2-0.8,3,0,2.8C42,156,42,152.8,41.1,153z"/>
		<path class="st41" d="M40.8,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,153.7c-0.8-0.2-0.8,3,0,2.8C43,156.7,43,153.5,42.2,153.7z"/>
		<path class="st41" d="M41.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,153c-0.8-0.2-0.8,3,0,2.8C40,156,40,152.8,39.1,153z"/>
		<path class="st41" d="M38.9,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,153.7c-0.8-0.2-0.8,3,0,2.8C41,156.7,41,153.5,40.2,153.7z"/>
		<path class="st41" d="M39.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,153c-0.8-0.2-0.8,3,0,2.8C38,156,38,152.8,37.2,153z"/>
		<path class="st41" d="M36.9,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,153.7c-0.8-0.2-0.8,3,0,2.8C39,156.7,39,153.5,38.2,153.7z"/>
		<path class="st41" d="M37.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,153c-0.8-0.2-0.8,3,0,2.8C36,156,36,152.8,35.2,153z"/>
		<path class="st41" d="M34.9,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,153.7c-0.8-0.2-0.8,3,0,2.8C37,156.7,37,153.5,36.2,153.7z"/>
		<path class="st41" d="M35.9,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,153c-0.8-0.2-0.8,3,0,2.8C34.1,156,34.1,152.8,33.3,153z"/>
		<path class="st41" d="M33,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,153.7c-0.8-0.2-0.8,3,0,2.8C35.2,156.7,35.2,153.5,34.3,153.7z"/>
		<path class="st41" d="M34.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,153c-0.8-0.2-0.8,3,0,2.8C32.1,156,32.1,152.8,31.3,153z"/>
		<path class="st41" d="M31,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,153.7c-0.8-0.2-0.8,3,0,2.8C33.2,156.7,33.2,153.5,32.4,153.7z"/>
		<path class="st41" d="M32.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,153c-0.8-0.2-0.8,3,0,2.8C30.1,156,30.2,152.8,29.3,153z"/>
		<path class="st41" d="M29,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,153.7c-0.8-0.2-0.8,3,0,2.8C31.2,156.7,31.2,153.5,30.4,153.7z"/>
		<path class="st41" d="M30.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,153c-0.8-0.2-0.8,3,0,2.8C28.2,156,28.2,152.8,27.3,153z"/>
		<path class="st41" d="M27,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,153.7c-0.8-0.2-0.8,3,0,2.8C29.2,156.7,29.2,153.5,28.4,153.7z"/>
		<path class="st41" d="M28.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,153c-0.8-0.2-0.8,3,0,2.8C26.2,156,26.2,152.8,25.3,153z"/>
		<path class="st41" d="M25.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,153.7c-0.8-0.2-0.8,3,0,2.8C27.2,156.7,27.2,153.5,26.4,153.7z"/>
		<path class="st41" d="M26.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,153c-0.8-0.2-0.8,3,0,2.8C24.2,156,24.2,152.8,23.4,153z"/>
		<path class="st41" d="M23.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,153.7c-0.8-0.2-0.8,3,0,2.8C25.2,156.7,25.2,153.5,24.4,153.7z"/>
		<path class="st41" d="M24.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,153c-0.8-0.2-0.8,3,0,2.8C22.2,156,22.2,152.8,21.4,153z"/>
		<path class="st41" d="M21.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,153.7c-0.8-0.2-0.8,3,0,2.8C23.3,156.7,23.3,153.5,22.4,153.7z"/>
		<path class="st41" d="M22.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,153c-0.8-0.2-0.8,3,0,2.8C20.2,156,20.2,152.8,19.4,153z"/>
		<path class="st41" d="M19.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,153.7c-0.8-0.2-0.8,3,0,2.8C21.3,156.7,21.3,153.5,20.4,153.7z"/>
		<path class="st41" d="M20.1,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,153c-0.8-0.2-0.8,3,0,2.8C18.2,156,18.2,152.8,17.4,153z"/>
		<path class="st41" d="M17.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,153.7c-0.8-0.2-0.8,3,0,2.8C19.3,156.7,19.3,153.5,18.4,153.7z"/>
		<path class="st41" d="M18.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,153c-0.8-0.2-0.8,3,0,2.8C16.2,156,16.2,152.8,15.4,153z"/>
		<path class="st41" d="M15.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,153.7c-0.8-0.2-0.8,3,0,2.8C17.3,156.7,17.3,153.5,16.5,153.7z"/>
		<path class="st41" d="M16.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,153c-0.8-0.2-0.8,3,0,2.8C14.3,156,14.3,152.8,13.4,153z"/>
		<path class="st41" d="M13.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,153.7c-0.8-0.2-0.8,3,0,2.8C15.3,156.7,15.3,153.5,14.5,153.7z"/>
		<path class="st41" d="M14.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,153c-0.8-0.2-0.8,3,0,2.8C12.3,156,12.3,152.8,11.4,153z"/>
		<path class="st41" d="M11.1,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,153.7c-0.8-0.2-0.8,3,0,2.8C13.3,156.7,13.3,153.5,12.5,153.7z"/>
		<path class="st41" d="M12.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,153c-0.8-0.2-0.8,3,0,2.8C10.3,156,10.3,152.8,9.5,153z"/>
		<path class="st41" d="M9.2,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,153.7c-0.8-0.2-0.8,3,0,2.8C11.3,156.7,11.3,153.5,10.5,153.7z"/>
		<path class="st41" d="M10.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,153c-0.8-0.2-0.8,3,0,2.8C8.3,156,8.3,152.8,7.5,153z"/>
		<path class="st41" d="M7.2,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,153.7c-0.8-0.2-0.8,3,0,2.8C9.3,156.7,9.3,153.5,8.5,153.7z"/>
		<path class="st41" d="M8.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,153c-0.8-0.2-0.8,3,0,2.8C6.3,156,6.3,152.8,5.5,153z"/>
		<path class="st41" d="M5.2,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,153.7c-0.8-0.2-0.8,3,0,2.8C7.4,156.7,7.4,153.5,6.5,153.7z"/>
		<path class="st41" d="M6.2,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,153c-0.8-0.2-0.8,3,0,2.8C4.3,156,4.3,152.8,3.5,153z"/>
		<path class="st41" d="M3.2,153.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,153.7c-0.8-0.2-0.8,3,0,2.8C5.4,156.7,5.4,153.5,4.5,153.7z"/>
		<path class="st41" d="M4.3,154.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,154.4c-0.8-0.2-0.8,3,0,2.8C65.8,157.4,65.8,154.3,65,154.4z"/>
		<path class="st41" d="M64.7,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,155.1c-0.8-0.2-0.8,3,0,2.8C66.9,158.1,66.9,154.9,66,155.1z"/>
		<path class="st41" d="M65.7,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,154.4c-0.8-0.2-0.8,3,0,2.8C63.8,157.4,63.8,154.3,63,154.4z"/>
		<path class="st41" d="M62.7,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,155.1c-0.8-0.2-0.8,3,0,2.8C64.9,158.1,64.9,154.9,64,155.1z"/>
		<path class="st41" d="M63.7,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,154.4c-0.8-0.2-0.8,3,0,2.8C61.8,157.4,61.8,154.3,61,154.4z"/>
		<path class="st41" d="M60.7,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,155.1c-0.8-0.2-0.8,3,0,2.8C62.9,158.1,62.9,154.9,62,155.1z"/>
		<path class="st41" d="M61.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,154.4c-0.8-0.2-0.8,3,0,2.8C59.8,157.4,59.8,154.3,59,154.4z"/>
		<path class="st41" d="M58.7,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,155.1c-0.8-0.2-0.8,3,0,2.8C60.9,158.1,60.9,154.9,60.1,155.1z"/>
		<path class="st41" d="M59.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,154.4c-0.8-0.2-0.8,3,0,2.8C57.9,157.4,57.9,154.3,57,154.4z"/>
		<path class="st41" d="M56.7,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,155.1c-0.8-0.2-0.8,3,0,2.8C58.9,158.1,58.9,154.9,58.1,155.1z"/>
		<path class="st41" d="M57.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,154.4c-0.8-0.2-0.8,3,0,2.8C55.9,157.4,55.9,154.3,55,154.4z"/>
		<path class="st41" d="M54.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,155.1c-0.8-0.2-0.8,3,0,2.8C56.9,158.1,56.9,154.9,56.1,155.1z"/>
		<path class="st41" d="M55.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,154.4c-0.8-0.2-0.8,3,0,2.8C53.9,157.4,53.9,154.3,53.1,154.4z"/>
		<path class="st41" d="M52.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,155.1c-0.8-0.2-0.8,3,0,2.8C54.9,158.1,54.9,154.9,54.1,155.1z"/>
		<path class="st41" d="M53.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,154.4c-0.8-0.2-0.8,3,0,2.8C51.9,157.4,51.9,154.3,51.1,154.4z"/>
		<path class="st41" d="M50.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,155.1c-0.8-0.2-0.8,3,0,2.8C52.9,158.1,52.9,154.9,52.1,155.1z"/>
		<path class="st41" d="M51.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,154.4c-0.8-0.2-0.8,3,0,2.8C49.9,157.4,49.9,154.3,49.1,154.4z"/>
		<path class="st41" d="M48.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,155.1c-0.8-0.2-0.8,3,0,2.8C51,158.1,51,154.9,50.1,155.1z"/>
		<path class="st41" d="M49.8,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,154.4c-0.8-0.2-0.8,3,0,2.8C47.9,157.4,47.9,154.3,47.1,154.4z"/>
		<path class="st41" d="M46.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,155.1c-0.8-0.2-0.8,3,0,2.8C49,158.1,49,154.9,48.1,155.1z"/>
		<path class="st41" d="M47.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,154.4c-0.8-0.2-0.8,3,0,2.8C45.9,157.4,45.9,154.3,45.1,154.4z"/>
		<path class="st41" d="M44.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,155.1c-0.8-0.2-0.8,3,0,2.8C47,158.1,47,154.9,46.2,155.1z"/>
		<path class="st41" d="M45.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,154.4c-0.8-0.2-0.8,3,0,2.8C43.9,157.4,43.9,154.3,43.1,154.4z"/>
		<path class="st41" d="M42.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,155.1c-0.8-0.2-0.8,3,0,2.8C45,158.1,45,154.9,44.2,155.1z"/>
		<path class="st41" d="M43.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,154.4c-0.8-0.2-0.8,3,0,2.8C42,157.4,42,154.3,41.1,154.4z"/>
		<path class="st41" d="M40.8,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,155.1c-0.8-0.2-0.8,3,0,2.8C43,158.1,43,154.9,42.2,155.1z"/>
		<path class="st41" d="M41.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,154.4c-0.8-0.2-0.8,3,0,2.8C40,157.4,40,154.3,39.1,154.4z"/>
		<path class="st41" d="M38.9,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,155.1c-0.8-0.2-0.8,3,0,2.8C41,158.1,41,154.9,40.2,155.1z"/>
		<path class="st41" d="M39.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,154.4c-0.8-0.2-0.8,3,0,2.8C38,157.4,38,154.3,37.2,154.4z"/>
		<path class="st41" d="M36.9,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,155.1c-0.8-0.2-0.8,3,0,2.8C39,158.1,39,154.9,38.2,155.1z"/>
		<path class="st41" d="M37.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,154.4c-0.8-0.2-0.8,3,0,2.8C36,157.4,36,154.3,35.2,154.4z"/>
		<path class="st41" d="M34.9,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,155.1c-0.8-0.2-0.8,3,0,2.8C37,158.1,37,154.9,36.2,155.1z"/>
		<path class="st41" d="M35.9,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,154.4c-0.8-0.2-0.8,3,0,2.8C34.1,157.4,34.1,154.3,33.3,154.4z"/>
		<path class="st41" d="M33,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,155.1c-0.8-0.2-0.8,3,0,2.8C35.2,158.1,35.2,154.9,34.3,155.1z"/>
		<path class="st41" d="M34.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,154.4c-0.8-0.2-0.8,3,0,2.8C32.1,157.4,32.1,154.3,31.3,154.4z"/>
		<path class="st41" d="M31,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,155.1c-0.8-0.2-0.8,3,0,2.8C33.2,158.1,33.2,154.9,32.4,155.1z"/>
		<path class="st41" d="M32.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,154.4c-0.8-0.2-0.8,3,0,2.8C30.1,157.4,30.2,154.3,29.3,154.4z"/>
		<path class="st41" d="M29,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,155.1c-0.8-0.2-0.8,3,0,2.8C31.2,158.1,31.2,154.9,30.4,155.1z"/>
		<path class="st41" d="M30.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,154.4c-0.8-0.2-0.8,3,0,2.8C28.2,157.4,28.2,154.3,27.3,154.4z"/>
		<path class="st41" d="M27,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,155.1c-0.8-0.2-0.8,3,0,2.8C29.2,158.1,29.2,154.9,28.4,155.1z"/>
		<path class="st41" d="M28.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,154.4c-0.8-0.2-0.8,3,0,2.8C26.2,157.4,26.2,154.3,25.3,154.4z"/>
		<path class="st41" d="M25.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,155.1c-0.8-0.2-0.8,3,0,2.8C27.2,158.1,27.2,154.9,26.4,155.1z"/>
		<path class="st41" d="M26.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,154.4c-0.8-0.2-0.8,3,0,2.8C24.2,157.4,24.2,154.3,23.4,154.4z"/>
		<path class="st41" d="M23.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,155.1c-0.8-0.2-0.8,3,0,2.8C25.2,158.1,25.2,154.9,24.4,155.1z"/>
		<path class="st41" d="M24.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,154.4c-0.8-0.2-0.8,3,0,2.8C22.2,157.4,22.2,154.3,21.4,154.4z"/>
		<path class="st41" d="M21.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,155.1c-0.8-0.2-0.8,3,0,2.8C23.3,158.1,23.3,154.9,22.4,155.1z"/>
		<path class="st41" d="M22.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,154.4c-0.8-0.2-0.8,3,0,2.8C20.2,157.4,20.2,154.3,19.4,154.4z"/>
		<path class="st41" d="M19.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,155.1c-0.8-0.2-0.8,3,0,2.8C21.3,158.1,21.3,154.9,20.4,155.1z"/>
		<path class="st41" d="M20.1,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,154.4c-0.8-0.2-0.8,3,0,2.8C18.2,157.4,18.2,154.3,17.4,154.4z"/>
		<path class="st41" d="M17.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,155.1c-0.8-0.2-0.8,3,0,2.8C19.3,158.1,19.3,154.9,18.4,155.1z"/>
		<path class="st41" d="M18.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,154.4c-0.8-0.2-0.8,3,0,2.8C16.2,157.4,16.2,154.3,15.4,154.4z"/>
		<path class="st41" d="M15.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,155.1c-0.8-0.2-0.8,3,0,2.8C17.3,158.1,17.3,154.9,16.5,155.1z"/>
		<path class="st41" d="M16.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,154.4c-0.8-0.2-0.8,3,0,2.8C14.3,157.4,14.3,154.3,13.4,154.4z"/>
		<path class="st41" d="M13.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,155.1c-0.8-0.2-0.8,3,0,2.8C15.3,158.1,15.3,154.9,14.5,155.1z"/>
		<path class="st41" d="M14.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,154.4c-0.8-0.2-0.8,3,0,2.8C12.3,157.4,12.3,154.3,11.4,154.4z"/>
		<path class="st41" d="M11.1,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,155.1c-0.8-0.2-0.8,3,0,2.8C13.3,158.1,13.3,154.9,12.5,155.1z"/>
		<path class="st41" d="M12.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,154.4c-0.8-0.2-0.8,3,0,2.8C10.3,157.4,10.3,154.3,9.5,154.4z"/>
		<path class="st41" d="M9.2,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,155.1c-0.8-0.2-0.8,3,0,2.8C11.3,158.1,11.3,154.9,10.5,155.1z"/>
		<path class="st41" d="M10.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,154.4c-0.8-0.2-0.8,3,0,2.8C8.3,157.4,8.3,154.3,7.5,154.4z"/>
		<path class="st41" d="M7.2,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,155.1c-0.8-0.2-0.8,3,0,2.8C9.3,158.1,9.3,154.9,8.5,155.1z"/>
		<path class="st41" d="M8.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,154.4c-0.8-0.2-0.8,3,0,2.8C6.3,157.4,6.3,154.3,5.5,154.4z"/>
		<path class="st41" d="M5.2,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,155.1c-0.8-0.2-0.8,3,0,2.8C7.4,158.1,7.4,154.9,6.5,155.1z"/>
		<path class="st41" d="M6.2,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,154.4c-0.8-0.2-0.8,3,0,2.8C4.3,157.4,4.3,154.3,3.5,154.4z"/>
		<path class="st41" d="M3.2,155.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,155.1c-0.8-0.2-0.8,3,0,2.8C5.4,158.1,5.4,154.9,4.5,155.1z"/>
		<path class="st41" d="M4.3,156c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,155.8c-0.8-0.2-0.8,3,0,2.8C65.8,158.8,65.8,155.6,65,155.8z"/>
		<path class="st41" d="M64.7,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,155.8c-0.8-0.2-0.8,3,0,2.8C63.8,158.8,63.8,155.6,63,155.8z"/>
		<path class="st41" d="M62.7,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,155.8c-0.8-0.2-0.8,3,0,2.8C61.8,158.8,61.8,155.6,61,155.8z"/>
		<path class="st41" d="M60.7,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,155.8c-0.8-0.2-0.8,3,0,2.8C59.8,158.8,59.8,155.6,59,155.8z"/>
		<path class="st41" d="M58.7,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,155.8c-0.8-0.2-0.8,3,0,2.8C57.9,158.8,57.9,155.6,57,155.8z"/>
		<path class="st41" d="M56.7,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,155.8c-0.8-0.2-0.8,3,0,2.8C55.9,158.8,55.9,155.6,55,155.8z"/>
		<path class="st41" d="M54.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,155.8c-0.8-0.2-0.8,3,0,2.8C53.9,158.8,53.9,155.6,53.1,155.8z"/>
		<path class="st41" d="M52.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,155.8c-0.8-0.2-0.8,3,0,2.8C51.9,158.8,51.9,155.6,51.1,155.8z"/>
		<path class="st41" d="M50.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,155.8c-0.8-0.2-0.8,3,0,2.8C49.9,158.8,49.9,155.6,49.1,155.8z"/>
		<path class="st41" d="M48.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,155.8c-0.8-0.2-0.8,3,0,2.8C47.9,158.8,47.9,155.6,47.1,155.8z"/>
		<path class="st41" d="M46.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,155.8c-0.8-0.2-0.8,3,0,2.8C45.9,158.8,45.9,155.6,45.1,155.8z"/>
		<path class="st41" d="M44.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,155.8c-0.8-0.2-0.8,3,0,2.8C43.9,158.8,43.9,155.6,43.1,155.8z"/>
		<path class="st41" d="M42.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,155.8c-0.8-0.2-0.8,3,0,2.8C42,158.8,42,155.6,41.1,155.8z"/>
		<path class="st41" d="M40.8,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,155.8c-0.8-0.2-0.8,3,0,2.8C40,158.8,40,155.6,39.1,155.8z"/>
		<path class="st41" d="M38.9,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,155.8c-0.8-0.2-0.8,3,0,2.8C38,158.8,38,155.6,37.2,155.8z"/>
		<path class="st41" d="M36.9,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,155.8c-0.8-0.2-0.8,3,0,2.8C36,158.8,36,155.6,35.2,155.8z"/>
		<path class="st41" d="M34.9,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,155.8c-0.8-0.2-0.8,3,0,2.8C34.1,158.8,34.1,155.6,33.3,155.8z"/>
		<path class="st41" d="M33,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,155.8c-0.8-0.2-0.8,3,0,2.8C32.1,158.8,32.1,155.6,31.3,155.8z"/>
		<path class="st41" d="M31,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,155.8c-0.8-0.2-0.8,3,0,2.8C30.1,158.8,30.2,155.6,29.3,155.8z"/>
		<path class="st41" d="M29,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,155.8c-0.8-0.2-0.8,3,0,2.8C28.2,158.8,28.2,155.6,27.3,155.8z"/>
		<path class="st41" d="M27,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,155.8c-0.8-0.2-0.8,3,0,2.8C26.2,158.8,26.2,155.6,25.3,155.8z"/>
		<path class="st41" d="M25.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,155.8c-0.8-0.2-0.8,3,0,2.8C24.2,158.8,24.2,155.6,23.4,155.8z"/>
		<path class="st41" d="M23.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,155.8c-0.8-0.2-0.8,3,0,2.8C22.2,158.8,22.2,155.6,21.4,155.8z"/>
		<path class="st41" d="M21.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,155.8c-0.8-0.2-0.8,3,0,2.8C20.2,158.8,20.2,155.6,19.4,155.8z"/>
		<path class="st41" d="M19.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,155.8c-0.8-0.2-0.8,3,0,2.8C18.2,158.8,18.2,155.6,17.4,155.8z"/>
		<path class="st41" d="M17.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,155.8c-0.8-0.2-0.8,3,0,2.8C16.2,158.8,16.2,155.6,15.4,155.8z"/>
		<path class="st41" d="M15.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,155.8c-0.8-0.2-0.8,3,0,2.8C14.3,158.8,14.3,155.6,13.4,155.8z"/>
		<path class="st41" d="M13.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,155.8c-0.8-0.2-0.8,3,0,2.8C12.3,158.8,12.3,155.6,11.4,155.8z"/>
		<path class="st41" d="M11.1,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,155.8c-0.8-0.2-0.8,3,0,2.8C10.3,158.8,10.3,155.6,9.5,155.8z"/>
		<path class="st41" d="M9.2,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,155.8c-0.8-0.2-0.8,3,0,2.8C8.3,158.8,8.3,155.6,7.5,155.8z"/>
		<path class="st41" d="M7.2,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,155.8c-0.8-0.2-0.8,3,0,2.8C6.3,158.8,6.3,155.6,5.5,155.8z"/>
		<path class="st42" d="M5.2,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,155.8c-0.8-0.2-0.8,3,0,2.8C4.3,158.8,4.3,155.6,3.5,155.8z"/>
		<path class="st42" d="M3.2,156.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M66,184.4c-0.8-0.2-0.8,3,0,2.8C66.9,187.4,66.9,184.2,66,184.4z"/>
		<path class="st41" d="M65.7,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,184.4c-0.8-0.2-0.8,3,0,2.8C64.9,187.4,64.9,184.2,64,184.4z"/>
		<path class="st41" d="M63.7,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,184.4c-0.8-0.2-0.8,3,0,2.8C62.9,187.4,62.9,184.2,62,184.4z"/>
		<path class="st41" d="M61.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,184.4c-0.8-0.2-0.8,3,0,2.8C60.9,187.4,60.9,184.2,60.1,184.4z"/>
		<path class="st41" d="M59.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,184.4c-0.8-0.2-0.8,3,0,2.8C58.9,187.4,58.9,184.2,58.1,184.4z"/>
		<path class="st41" d="M57.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,184.4c-0.8-0.2-0.8,3,0,2.8C56.9,187.4,56.9,184.2,56.1,184.4z"/>
		<path class="st41" d="M55.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,183.7c-0.8-0.2-0.8,3,0,2.8C53.9,186.7,53.9,183.6,53.1,183.7z"/>
		<path class="st41" d="M52.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,184.4c-0.8-0.2-0.8,3,0,2.8C54.9,187.4,54.9,184.2,54.1,184.4z"/>
		<path class="st41" d="M53.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,183.7c-0.8-0.2-0.8,3,0,2.8C51.9,186.7,51.9,183.6,51.1,183.7z"/>
		<path class="st41" d="M50.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,184.4c-0.8-0.2-0.8,3,0,2.8C52.9,187.4,52.9,184.2,52.1,184.4z"/>
		<path class="st41" d="M51.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,183.7c-0.8-0.2-0.8,3,0,2.8C49.9,186.7,49.9,183.6,49.1,183.7z"/>
		<path class="st41" d="M48.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,184.4c-0.8-0.2-0.8,3,0,2.8C51,187.4,51,184.2,50.1,184.4z"/>
		<path class="st41" d="M49.8,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,183.7c-0.8-0.2-0.8,3,0,2.8C47.9,186.7,47.9,183.6,47.1,183.7z"/>
		<path class="st41" d="M46.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,184.4c-0.8-0.2-0.8,3,0,2.8C49,187.4,49,184.2,48.1,184.4z"/>
		<path class="st41" d="M47.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,183.7c-0.8-0.2-0.8,3,0,2.8C45.9,186.7,45.9,183.6,45.1,183.7z"/>
		<path class="st41" d="M44.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,184.4c-0.8-0.2-0.8,3,0,2.8C47,187.4,47,184.2,46.2,184.4z"/>
		<path class="st41" d="M45.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,183.7c-0.8-0.2-0.8,3,0,2.8C43.9,186.7,43.9,183.6,43.1,183.7z"/>
		<path class="st41" d="M42.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,184.4c-0.8-0.2-0.8,3,0,2.8C45,187.4,45,184.2,44.2,184.4z"/>
		<path class="st41" d="M43.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,183.7c-0.8-0.2-0.8,3,0,2.8C42,186.7,42,183.6,41.1,183.7z"/>
		<path class="st41" d="M40.8,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,184.4c-0.8-0.2-0.8,3,0,2.8C43,187.4,43,184.2,42.2,184.4z"/>
		<path class="st41" d="M41.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,183.7c-0.8-0.2-0.8,3,0,2.8C40,186.7,40,183.6,39.1,183.7z"/>
		<path class="st41" d="M38.9,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,184.4c-0.8-0.2-0.8,3,0,2.8C41,187.4,41,184.2,40.2,184.4z"/>
		<path class="st41" d="M39.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,183.7c-0.8-0.2-0.8,3,0,2.8C38,186.7,38,183.6,37.2,183.7z"/>
		<path class="st41" d="M36.9,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,184.4c-0.8-0.2-0.8,3,0,2.8C39,187.4,39,184.2,38.2,184.4z"/>
		<path class="st41" d="M37.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,183.7c-0.8-0.2-0.8,3,0,2.8C36,186.7,36,183.6,35.2,183.7z"/>
		<path class="st41" d="M34.9,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,184.4c-0.8-0.2-0.8,3,0,2.8C37,187.4,37,184.2,36.2,184.4z"/>
		<path class="st41" d="M35.9,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,183.7c-0.8-0.2-0.8,3,0,2.8C34.1,186.7,34.1,183.6,33.3,183.7z"/>
		<path class="st41" d="M33,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,184.4c-0.8-0.2-0.8,3,0,2.8C35.2,187.4,35.2,184.2,34.3,184.4z"/>
		<path class="st41" d="M34.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,183.7c-0.8-0.2-0.8,3,0,2.8C32.1,186.7,32.1,183.6,31.3,183.7z"/>
		<path class="st41" d="M31,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,184.4c-0.8-0.2-0.8,3,0,2.8C33.2,187.4,33.2,184.2,32.4,184.4z"/>
		<path class="st41" d="M32.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,183.7c-0.8-0.2-0.8,3,0,2.8C30.1,186.7,30.2,183.6,29.3,183.7z"/>
		<path class="st41" d="M29,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,184.4c-0.8-0.2-0.8,3,0,2.8C31.2,187.4,31.2,184.2,30.4,184.4z"/>
		<path class="st41" d="M30.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,183.7c-0.8-0.2-0.8,3,0,2.8C28.2,186.7,28.2,183.6,27.3,183.7z"/>
		<path class="st41" d="M27,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,184.4c-0.8-0.2-0.8,3,0,2.8C29.2,187.4,29.2,184.2,28.4,184.4z"/>
		<path class="st41" d="M28.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,183.7c-0.8-0.2-0.8,3,0,2.8C26.2,186.7,26.2,183.6,25.3,183.7z"/>
		<path class="st41" d="M25.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,184.4c-0.8-0.2-0.8,3,0,2.8C27.2,187.4,27.2,184.2,26.4,184.4z"/>
		<path class="st41" d="M26.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,183.7c-0.8-0.2-0.8,3,0,2.8C24.2,186.7,24.2,183.6,23.4,183.7z"/>
		<path class="st41" d="M23.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,184.4c-0.8-0.2-0.8,3,0,2.8C25.2,187.4,25.2,184.2,24.4,184.4z"/>
		<path class="st41" d="M24.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,183.7c-0.8-0.2-0.8,3,0,2.8C22.2,186.7,22.2,183.6,21.4,183.7z"/>
		<path class="st41" d="M21.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,184.4c-0.8-0.2-0.8,3,0,2.8C23.3,187.4,23.3,184.2,22.4,184.4z"/>
		<path class="st41" d="M22.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,183.7c-0.8-0.2-0.8,3,0,2.8C20.2,186.7,20.2,183.6,19.4,183.7z"/>
		<path class="st41" d="M19.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,184.4c-0.8-0.2-0.8,3,0,2.8C21.3,187.4,21.3,184.2,20.4,184.4z"/>
		<path class="st41" d="M20.1,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,183.7c-0.8-0.2-0.8,3,0,2.8C18.2,186.7,18.2,183.6,17.4,183.7z"/>
		<path class="st41" d="M17.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,184.4c-0.8-0.2-0.8,3,0,2.8C19.3,187.4,19.3,184.2,18.4,184.4z"/>
		<path class="st41" d="M18.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,183.7c-0.8-0.2-0.8,3,0,2.8C16.2,186.7,16.2,183.6,15.4,183.7z"/>
		<path class="st41" d="M15.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,184.4c-0.8-0.2-0.8,3,0,2.8C17.3,187.4,17.3,184.2,16.5,184.4z"/>
		<path class="st41" d="M16.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,183.7c-0.8-0.2-0.8,3,0,2.8C14.3,186.7,14.3,183.6,13.4,183.7z"/>
		<path class="st41" d="M13.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,184.4c-0.8-0.2-0.8,3,0,2.8C15.3,187.4,15.3,184.2,14.5,184.4z"/>
		<path class="st41" d="M14.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,183.7c-0.8-0.2-0.8,3,0,2.8C12.3,186.7,12.3,183.6,11.4,183.7z"/>
		<path class="st41" d="M11.1,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,184.4c-0.8-0.2-0.8,3,0,2.8C13.3,187.4,13.3,184.2,12.5,184.4z"/>
		<path class="st41" d="M12.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,183.7c-0.8-0.2-0.8,3,0,2.8C10.3,186.7,10.3,183.6,9.5,183.7z"/>
		<path class="st41" d="M9.2,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,184.4c-0.8-0.2-0.8,3,0,2.8C11.3,187.4,11.3,184.2,10.5,184.4z"/>
		<path class="st41" d="M10.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,183.7c-0.8-0.2-0.8,3,0,2.8C8.3,186.7,8.3,183.6,7.5,183.7z"/>
		<path class="st41" d="M7.2,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,184.4c-0.8-0.2-0.8,3,0,2.8C9.3,187.4,9.3,184.2,8.5,184.4z"/>
		<path class="st41" d="M8.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,183.7c-0.8-0.2-0.8,3,0,2.8C6.3,186.7,6.3,183.6,5.5,183.7z"/>
		<path class="st41" d="M5.2,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,184.4c-0.8-0.2-0.8,3,0,2.8C7.4,187.4,7.4,184.2,6.5,184.4z"/>
		<path class="st41" d="M6.2,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,183.7c-0.8-0.2-0.8,3,0,2.8C4.3,186.7,4.3,183.6,3.5,183.7z"/>
		<path class="st41" d="M3.2,184.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,184.4c-0.8-0.2-0.8,3,0,2.8C5.4,187.4,5.4,184.2,4.5,184.4z"/>
		<path class="st41" d="M4.3,185.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,185.1c-0.8-0.2-0.8,3,0,2.8C65.8,188.2,65.8,185,65,185.1z"/>
		<path class="st41" d="M64.7,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,185.8c-0.8-0.2-0.8,3,0,2.8C66.9,188.8,66.9,185.7,66,185.8z"/>
		<path class="st41" d="M65.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,185.1c-0.8-0.2-0.8,3,0,2.8C63.8,188.2,63.8,185,63,185.1z"/>
		<path class="st41" d="M62.7,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,185.8c-0.8-0.2-0.8,3,0,2.8C64.9,188.8,64.9,185.7,64,185.8z"/>
		<path class="st41" d="M63.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,185.1c-0.8-0.2-0.8,3,0,2.8C61.8,188.2,61.8,185,61,185.1z"/>
		<path class="st41" d="M60.7,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,185.8c-0.8-0.2-0.8,3,0,2.8C62.9,188.8,62.9,185.7,62,185.8z"/>
		<path class="st41" d="M61.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,185.1c-0.8-0.2-0.8,3,0,2.8C59.8,188.2,59.8,185,59,185.1z"/>
		<path class="st41" d="M58.7,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,185.8c-0.8-0.2-0.8,3,0,2.8C60.9,188.8,60.9,185.7,60.1,185.8z"/>
		<path class="st41" d="M59.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,185.1c-0.8-0.2-0.8,3,0,2.8C57.9,188.2,57.9,185,57,185.1z"/>
		<path class="st41" d="M56.7,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,185.8c-0.8-0.2-0.8,3,0,2.8C58.9,188.8,58.9,185.7,58.1,185.8z"/>
		<path class="st41" d="M57.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,185.1c-0.8-0.2-0.8,3,0,2.8C55.9,188.2,55.9,185,55,185.1z"/>
		<path class="st41" d="M54.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,185.8c-0.8-0.2-0.8,3,0,2.8C56.9,188.8,56.9,185.7,56.1,185.8z"/>
		<path class="st41" d="M55.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,185.1c-0.8-0.2-0.8,3,0,2.8C53.9,188.2,53.9,185,53.1,185.1z"/>
		<path class="st41" d="M52.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,185.8c-0.8-0.2-0.8,3,0,2.8C54.9,188.8,54.9,185.7,54.1,185.8z"/>
		<path class="st41" d="M53.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,185.1c-0.8-0.2-0.8,3,0,2.8C51.9,188.2,51.9,185,51.1,185.1z"/>
		<path class="st41" d="M50.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,185.8c-0.8-0.2-0.8,3,0,2.8C52.9,188.8,52.9,185.7,52.1,185.8z"/>
		<path class="st41" d="M51.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,185.1c-0.8-0.2-0.8,3,0,2.8C49.9,188.2,49.9,185,49.1,185.1z"/>
		<path class="st41" d="M48.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,185.8c-0.8-0.2-0.8,3,0,2.8C51,188.8,51,185.7,50.1,185.8z"/>
		<path class="st41" d="M49.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,185.1c-0.8-0.2-0.8,3,0,2.8C47.9,188.2,47.9,185,47.1,185.1z"/>
		<path class="st41" d="M46.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,185.8c-0.8-0.2-0.8,3,0,2.8C49,188.8,49,185.7,48.1,185.8z"/>
		<path class="st41" d="M47.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,185.1c-0.8-0.2-0.8,3,0,2.8C45.9,188.2,45.9,185,45.1,185.1z"/>
		<path class="st41" d="M44.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,185.8c-0.8-0.2-0.8,3,0,2.8C47,188.8,47,185.7,46.2,185.8z"/>
		<path class="st41" d="M45.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,185.1c-0.8-0.2-0.8,3,0,2.8C43.9,188.2,43.9,185,43.1,185.1z"/>
		<path class="st41" d="M42.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,185.8c-0.8-0.2-0.8,3,0,2.8C45,188.8,45,185.7,44.2,185.8z"/>
		<path class="st41" d="M43.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,185.1c-0.8-0.2-0.8,3,0,2.8C42,188.2,42,185,41.1,185.1z"/>
		<path class="st41" d="M40.8,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,185.8c-0.8-0.2-0.8,3,0,2.8C43,188.8,43,185.7,42.2,185.8z"/>
		<path class="st41" d="M41.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,185.1c-0.8-0.2-0.8,3,0,2.8C40,188.2,40,185,39.1,185.1z"/>
		<path class="st41" d="M38.9,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,185.8c-0.8-0.2-0.8,3,0,2.8C41,188.8,41,185.7,40.2,185.8z"/>
		<path class="st41" d="M39.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,185.1c-0.8-0.2-0.8,3,0,2.8C38,188.2,38,185,37.2,185.1z"/>
		<path class="st41" d="M36.9,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,185.8c-0.8-0.2-0.8,3,0,2.8C39,188.8,39,185.7,38.2,185.8z"/>
		<path class="st41" d="M37.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,185.1c-0.8-0.2-0.8,3,0,2.8C36,188.2,36,185,35.2,185.1z"/>
		<path class="st41" d="M34.9,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,185.8c-0.8-0.2-0.8,3,0,2.8C37,188.8,37,185.7,36.2,185.8z"/>
		<path class="st41" d="M35.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,185.1c-0.8-0.2-0.8,3,0,2.8C34.1,188.2,34.1,185,33.3,185.1z"/>
		<path class="st41" d="M33,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,185.8c-0.8-0.2-0.8,3,0,2.8C35.2,188.8,35.2,185.7,34.3,185.8z"/>
		<path class="st41" d="M34.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,185.1c-0.8-0.2-0.8,3,0,2.8C32.1,188.2,32.1,185,31.3,185.1z"/>
		<path class="st41" d="M31,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,185.8c-0.8-0.2-0.8,3,0,2.8C33.2,188.8,33.2,185.7,32.4,185.8z"/>
		<path class="st41" d="M32.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,185.1c-0.8-0.2-0.8,3,0,2.8C30.1,188.2,30.2,185,29.3,185.1z"/>
		<path class="st41" d="M29,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,185.8c-0.8-0.2-0.8,3,0,2.8C31.2,188.8,31.2,185.7,30.4,185.8z"/>
		<path class="st41" d="M30.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,185.1c-0.8-0.2-0.8,3,0,2.8C28.2,188.2,28.2,185,27.3,185.1z"/>
		<path class="st41" d="M27,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,185.8c-0.8-0.2-0.8,3,0,2.8C29.2,188.8,29.2,185.7,28.4,185.8z"/>
		<path class="st41" d="M28.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,185.1c-0.8-0.2-0.8,3,0,2.8C26.2,188.2,26.2,185,25.3,185.1z"/>
		<path class="st41" d="M25.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,185.8c-0.8-0.2-0.8,3,0,2.8C27.2,188.8,27.2,185.7,26.4,185.8z"/>
		<path class="st41" d="M26.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,185.1c-0.8-0.2-0.8,3,0,2.8C24.2,188.2,24.2,185,23.4,185.1z"/>
		<path class="st41" d="M23.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,185.8c-0.8-0.2-0.8,3,0,2.8C25.2,188.8,25.2,185.7,24.4,185.8z"/>
		<path class="st41" d="M24.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,185.1c-0.8-0.2-0.8,3,0,2.8C22.2,188.2,22.2,185,21.4,185.1z"/>
		<path class="st41" d="M21.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,185.8c-0.8-0.2-0.8,3,0,2.8C23.3,188.8,23.3,185.7,22.4,185.8z"/>
		<path class="st41" d="M22.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,185.1c-0.8-0.2-0.8,3,0,2.8C20.2,188.2,20.2,185,19.4,185.1z"/>
		<path class="st41" d="M19.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,185.8c-0.8-0.2-0.8,3,0,2.8C21.3,188.8,21.3,185.7,20.4,185.8z"/>
		<path class="st41" d="M20.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,185.1c-0.8-0.2-0.8,3,0,2.8C18.2,188.2,18.2,185,17.4,185.1z"/>
		<path class="st41" d="M17.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,185.8c-0.8-0.2-0.8,3,0,2.8C19.3,188.8,19.3,185.7,18.4,185.8z"/>
		<path class="st41" d="M18.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,185.1c-0.8-0.2-0.8,3,0,2.8C16.2,188.2,16.2,185,15.4,185.1z"/>
		<path class="st41" d="M15.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,185.8c-0.8-0.2-0.8,3,0,2.8C17.3,188.8,17.3,185.7,16.5,185.8z"/>
		<path class="st41" d="M16.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,185.1c-0.8-0.2-0.8,3,0,2.8C14.3,188.2,14.3,185,13.4,185.1z"/>
		<path class="st41" d="M13.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,185.8c-0.8-0.2-0.8,3,0,2.8C15.3,188.8,15.3,185.7,14.5,185.8z"/>
		<path class="st41" d="M14.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,185.1c-0.8-0.2-0.8,3,0,2.8C12.3,188.2,12.3,185,11.4,185.1z"/>
		<path class="st41" d="M11.1,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,185.8c-0.8-0.2-0.8,3,0,2.8C13.3,188.8,13.3,185.7,12.5,185.8z"/>
		<path class="st41" d="M12.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,185.1c-0.8-0.2-0.8,3,0,2.8C10.3,188.2,10.3,185,9.5,185.1z"/>
		<path class="st41" d="M9.2,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,185.8c-0.8-0.2-0.8,3,0,2.8C11.3,188.8,11.3,185.7,10.5,185.8z"/>
		<path class="st41" d="M10.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,185.1c-0.8-0.2-0.8,3,0,2.8C8.3,188.2,8.3,185,7.5,185.1z"/>
		<path class="st41" d="M7.2,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,185.8c-0.8-0.2-0.8,3,0,2.8C9.3,188.8,9.3,185.7,8.5,185.8z"/>
		<path class="st41" d="M8.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,185.1c-0.8-0.2-0.8,3,0,2.8C6.3,188.2,6.3,185,5.5,185.1z"/>
		<path class="st41" d="M5.2,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,185.8c-0.8-0.2-0.8,3,0,2.8C7.4,188.8,7.4,185.7,6.5,185.8z"/>
		<path class="st41" d="M6.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,185.1c-0.8-0.2-0.8,3,0,2.8C4.3,188.2,4.3,185,3.5,185.1z"/>
		<path class="st41" d="M3.2,186c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,185.8c-0.8-0.2-0.8,3,0,2.8C5.4,188.8,5.4,185.7,4.5,185.8z"/>
		<path class="st41" d="M4.3,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,186.7c-0.8-0.2-0.8,3,0,2.8C65.8,189.7,65.8,186.5,65,186.7z"/>
		<path class="st41" d="M64.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,187.3c-0.8-0.2-0.8,3,0,2.8C66.9,190.3,66.9,187.2,66,187.3z"/>
		<path class="st41" d="M65.7,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,186.7c-0.8-0.2-0.8,3,0,2.8C63.8,189.7,63.8,186.5,63,186.7z"/>
		<path class="st41" d="M62.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,187.3c-0.8-0.2-0.8,3,0,2.8C64.9,190.3,64.9,187.2,64,187.3z"/>
		<path class="st41" d="M63.7,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,186.7c-0.8-0.2-0.8,3,0,2.8C61.8,189.7,61.8,186.5,61,186.7z"/>
		<path class="st41" d="M60.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,187.3c-0.8-0.2-0.8,3,0,2.8C62.9,190.3,62.9,187.2,62,187.3z"/>
		<path class="st41" d="M61.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,186.7c-0.8-0.2-0.8,3,0,2.8C59.8,189.7,59.8,186.5,59,186.7z"/>
		<path class="st41" d="M58.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,187.3c-0.8-0.2-0.8,3,0,2.8C60.9,190.3,60.9,187.2,60.1,187.3z"/>
		<path class="st41" d="M59.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,186.7c-0.8-0.2-0.8,3,0,2.8C57.9,189.7,57.9,186.5,57,186.7z"/>
		<path class="st41" d="M56.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,187.3c-0.8-0.2-0.8,3,0,2.8C58.9,190.3,58.9,187.2,58.1,187.3z"/>
		<path class="st41" d="M57.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,186.7c-0.8-0.2-0.8,3,0,2.8C55.9,189.7,55.9,186.5,55,186.7z"/>
		<path class="st41" d="M54.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,187.3c-0.8-0.2-0.8,3,0,2.8C56.9,190.3,56.9,187.2,56.1,187.3z"/>
		<path class="st41" d="M55.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,186.7c-0.8-0.2-0.8,3,0,2.8C53.9,189.7,53.9,186.5,53.1,186.7z"/>
		<path class="st41" d="M52.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,187.3c-0.8-0.2-0.8,3,0,2.8C54.9,190.3,54.9,187.2,54.1,187.3z"/>
		<path class="st41" d="M53.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,186.7c-0.8-0.2-0.8,3,0,2.8C51.9,189.7,51.9,186.5,51.1,186.7z"/>
		<path class="st41" d="M50.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,187.3c-0.8-0.2-0.8,3,0,2.8C52.9,190.3,52.9,187.2,52.1,187.3z"/>
		<path class="st41" d="M51.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,186.7c-0.8-0.2-0.8,3,0,2.8C49.9,189.7,49.9,186.5,49.1,186.7z"/>
		<path class="st41" d="M48.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,187.3c-0.8-0.2-0.8,3,0,2.8C51,190.3,51,187.2,50.1,187.3z"/>
		<path class="st41" d="M49.8,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,186.7c-0.8-0.2-0.8,3,0,2.8C47.9,189.7,47.9,186.5,47.1,186.7z"/>
		<path class="st41" d="M46.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,187.3c-0.8-0.2-0.8,3,0,2.8C49,190.3,49,187.2,48.1,187.3z"/>
		<path class="st41" d="M47.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,186.7c-0.8-0.2-0.8,3,0,2.8C45.9,189.7,45.9,186.5,45.1,186.7z"/>
		<path class="st41" d="M44.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,187.3c-0.8-0.2-0.8,3,0,2.8C47,190.3,47,187.2,46.2,187.3z"/>
		<path class="st41" d="M45.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,186.7c-0.8-0.2-0.8,3,0,2.8C43.9,189.7,43.9,186.5,43.1,186.7z"/>
		<path class="st41" d="M42.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,187.3c-0.8-0.2-0.8,3,0,2.8C45,190.3,45,187.2,44.2,187.3z"/>
		<path class="st41" d="M43.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,186.7c-0.8-0.2-0.8,3,0,2.8C42,189.7,42,186.5,41.1,186.7z"/>
		<path class="st41" d="M40.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,187.3c-0.8-0.2-0.8,3,0,2.8C43,190.3,43,187.2,42.2,187.3z"/>
		<path class="st41" d="M41.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,186.7c-0.8-0.2-0.8,3,0,2.8C40,189.7,40,186.5,39.1,186.7z"/>
		<path class="st41" d="M38.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,187.3c-0.8-0.2-0.8,3,0,2.8C41,190.3,41,187.2,40.2,187.3z"/>
		<path class="st41" d="M39.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,186.7c-0.8-0.2-0.8,3,0,2.8C38,189.7,38,186.5,37.2,186.7z"/>
		<path class="st41" d="M36.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,187.3c-0.8-0.2-0.8,3,0,2.8C39,190.3,39,187.2,38.2,187.3z"/>
		<path class="st41" d="M37.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,186.7c-0.8-0.2-0.8,3,0,2.8C36,189.7,36,186.5,35.2,186.7z"/>
		<path class="st41" d="M34.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,187.3c-0.8-0.2-0.8,3,0,2.8C37,190.3,37,187.2,36.2,187.3z"/>
		<path class="st41" d="M35.9,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,186.7c-0.8-0.2-0.8,3,0,2.8C34.1,189.7,34.1,186.5,33.3,186.7z"/>
		<path class="st41" d="M33,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,187.3c-0.8-0.2-0.8,3,0,2.8C35.2,190.3,35.2,187.2,34.3,187.3z"/>
		<path class="st41" d="M34.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,186.7c-0.8-0.2-0.8,3,0,2.8C32.1,189.7,32.1,186.5,31.3,186.7z"/>
		<path class="st41" d="M31,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,187.3c-0.8-0.2-0.8,3,0,2.8C33.2,190.3,33.2,187.2,32.4,187.3z"/>
		<path class="st41" d="M32.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,186.7c-0.8-0.2-0.8,3,0,2.8C30.1,189.7,30.2,186.5,29.3,186.7z"/>
		<path class="st41" d="M29,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,187.3c-0.8-0.2-0.8,3,0,2.8C31.2,190.3,31.2,187.2,30.4,187.3z"/>
		<path class="st41" d="M30.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,186.7c-0.8-0.2-0.8,3,0,2.8C28.2,189.7,28.2,186.5,27.3,186.7z"/>
		<path class="st41" d="M27,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,187.3c-0.8-0.2-0.8,3,0,2.8C29.2,190.3,29.2,187.2,28.4,187.3z"/>
		<path class="st41" d="M28.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,186.7c-0.8-0.2-0.8,3,0,2.8C26.2,189.7,26.2,186.5,25.3,186.7z"/>
		<path class="st41" d="M25.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,187.3c-0.8-0.2-0.8,3,0,2.8C27.2,190.3,27.2,187.2,26.4,187.3z"/>
		<path class="st41" d="M26.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,186.7c-0.8-0.2-0.8,3,0,2.8C24.2,189.7,24.2,186.5,23.4,186.7z"/>
		<path class="st41" d="M23.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,187.3c-0.8-0.2-0.8,3,0,2.8C25.2,190.3,25.2,187.2,24.4,187.3z"/>
		<path class="st41" d="M24.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,186.7c-0.8-0.2-0.8,3,0,2.8C22.2,189.7,22.2,186.5,21.4,186.7z"/>
		<path class="st41" d="M21.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,187.3c-0.8-0.2-0.8,3,0,2.8C23.3,190.3,23.3,187.2,22.4,187.3z"/>
		<path class="st41" d="M22.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,186.7c-0.8-0.2-0.8,3,0,2.8C20.2,189.7,20.2,186.5,19.4,186.7z"/>
		<path class="st41" d="M19.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,187.3c-0.8-0.2-0.8,3,0,2.8C21.3,190.3,21.3,187.2,20.4,187.3z"/>
		<path class="st41" d="M20.1,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,186.7c-0.8-0.2-0.8,3,0,2.8C18.2,189.7,18.2,186.5,17.4,186.7z"/>
		<path class="st41" d="M17.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,187.3c-0.8-0.2-0.8,3,0,2.8C19.3,190.3,19.3,187.2,18.4,187.3z"/>
		<path class="st41" d="M18.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,186.7c-0.8-0.2-0.8,3,0,2.8C16.2,189.7,16.2,186.5,15.4,186.7z"/>
		<path class="st41" d="M15.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,187.3c-0.8-0.2-0.8,3,0,2.8C17.3,190.3,17.3,187.2,16.5,187.3z"/>
		<path class="st41" d="M16.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,186.7c-0.8-0.2-0.8,3,0,2.8C14.3,189.7,14.3,186.5,13.4,186.7z"/>
		<path class="st41" d="M13.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,187.3c-0.8-0.2-0.8,3,0,2.8C15.3,190.3,15.3,187.2,14.5,187.3z"/>
		<path class="st41" d="M14.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,186.7c-0.8-0.2-0.8,3,0,2.8C12.3,189.7,12.3,186.5,11.4,186.7z"/>
		<path class="st41" d="M11.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,187.3c-0.8-0.2-0.8,3,0,2.8C13.3,190.3,13.3,187.2,12.5,187.3z"/>
		<path class="st41" d="M12.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,186.7c-0.8-0.2-0.8,3,0,2.8C10.3,189.7,10.3,186.5,9.5,186.7z"/>
		<path class="st41" d="M9.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,187.3c-0.8-0.2-0.8,3,0,2.8C11.3,190.3,11.3,187.2,10.5,187.3z"/>
		<path class="st41" d="M10.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,186.7c-0.8-0.2-0.8,3,0,2.8C8.3,189.7,8.3,186.5,7.5,186.7z"/>
		<path class="st41" d="M7.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,187.3c-0.8-0.2-0.8,3,0,2.8C9.3,190.3,9.3,187.2,8.5,187.3z"/>
		<path class="st41" d="M8.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,186.7c-0.8-0.2-0.8,3,0,2.8C6.3,189.7,6.3,186.5,5.5,186.7z"/>
		<path class="st41" d="M5.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,187.3c-0.8-0.2-0.8,3,0,2.8C7.4,190.3,7.4,187.2,6.5,187.3z"/>
		<path class="st41" d="M6.2,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,186.7c-0.8-0.2-0.8,3,0,2.8C4.3,189.7,4.3,186.5,3.5,186.7z"/>
		<path class="st41" d="M3.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,187.3c-0.8-0.2-0.8,3,0,2.8C5.4,190.3,5.4,187.2,4.5,187.3z"/>
		<path class="st41" d="M4.3,188.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,188.1c-0.8-0.2-0.8,3,0,2.8C65.8,191.1,65.8,187.9,65,188.1z"/>
		<path class="st41" d="M64.7,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,188.8c-0.8-0.2-0.8,3,0,2.8C66.9,191.8,66.9,188.6,66,188.8z"/>
		<path class="st41" d="M65.7,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,188.1c-0.8-0.2-0.8,3,0,2.8C63.8,191.1,63.8,187.9,63,188.1z"/>
		<path class="st41" d="M62.7,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,188.8c-0.8-0.2-0.8,3,0,2.8C64.9,191.8,64.9,188.6,64,188.8z"/>
		<path class="st41" d="M63.7,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,188.1c-0.8-0.2-0.8,3,0,2.8C61.8,191.1,61.8,187.9,61,188.1z"/>
		<path class="st41" d="M60.7,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,188.8c-0.8-0.2-0.8,3,0,2.8C62.9,191.8,62.9,188.6,62,188.8z"/>
		<path class="st41" d="M61.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,188.1c-0.8-0.2-0.8,3,0,2.8C59.8,191.1,59.8,187.9,59,188.1z"/>
		<path class="st41" d="M58.7,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,188.8c-0.8-0.2-0.8,3,0,2.8C60.9,191.8,60.9,188.6,60.1,188.8z"/>
		<path class="st41" d="M59.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,188.1c-0.8-0.2-0.8,3,0,2.8C57.9,191.1,57.9,187.9,57,188.1z"/>
		<path class="st41" d="M56.7,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,188.8c-0.8-0.2-0.8,3,0,2.8C58.9,191.8,58.9,188.6,58.1,188.8z"/>
		<path class="st41" d="M57.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,188.1c-0.8-0.2-0.8,3,0,2.8C55.9,191.1,55.9,187.9,55,188.1z"/>
		<path class="st41" d="M54.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,188.8c-0.8-0.2-0.8,3,0,2.8C56.9,191.8,56.9,188.6,56.1,188.8z"/>
		<path class="st41" d="M55.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,188.1c-0.8-0.2-0.8,3,0,2.8C53.9,191.1,53.9,187.9,53.1,188.1z"/>
		<path class="st41" d="M52.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,188.8c-0.8-0.2-0.8,3,0,2.8C54.9,191.8,54.9,188.6,54.1,188.8z"/>
		<path class="st41" d="M53.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,188.1c-0.8-0.2-0.8,3,0,2.8C51.9,191.1,51.9,187.9,51.1,188.1z"/>
		<path class="st41" d="M50.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,188.8c-0.8-0.2-0.8,3,0,2.8C52.9,191.8,52.9,188.6,52.1,188.8z"/>
		<path class="st41" d="M51.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,188.1c-0.8-0.2-0.8,3,0,2.8C49.9,191.1,49.9,187.9,49.1,188.1z"/>
		<path class="st41" d="M48.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,188.8c-0.8-0.2-0.8,3,0,2.8C51,191.8,51,188.6,50.1,188.8z"/>
		<path class="st41" d="M49.8,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,188.1c-0.8-0.2-0.8,3,0,2.8C47.9,191.1,47.9,187.9,47.1,188.1z"/>
		<path class="st41" d="M46.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,188.8c-0.8-0.2-0.8,3,0,2.8C49,191.8,49,188.6,48.1,188.8z"/>
		<path class="st41" d="M47.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,188.1c-0.8-0.2-0.8,3,0,2.8C45.9,191.1,45.9,187.9,45.1,188.1z"/>
		<path class="st41" d="M44.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,188.8c-0.8-0.2-0.8,3,0,2.8C47,191.8,47,188.6,46.2,188.8z"/>
		<path class="st41" d="M45.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,188.1c-0.8-0.2-0.8,3,0,2.8C43.9,191.1,43.9,187.9,43.1,188.1z"/>
		<path class="st41" d="M42.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,188.8c-0.8-0.2-0.8,3,0,2.8C45,191.8,45,188.6,44.2,188.8z"/>
		<path class="st41" d="M43.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,188.1c-0.8-0.2-0.8,3,0,2.8C42,191.1,42,187.9,41.1,188.1z"/>
		<path class="st41" d="M40.8,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,188.8c-0.8-0.2-0.8,3,0,2.8C43,191.8,43,188.6,42.2,188.8z"/>
		<path class="st41" d="M41.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,188.1c-0.8-0.2-0.8,3,0,2.8C40,191.1,40,187.9,39.1,188.1z"/>
		<path class="st41" d="M38.9,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,188.8c-0.8-0.2-0.8,3,0,2.8C41,191.8,41,188.6,40.2,188.8z"/>
		<path class="st41" d="M39.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,188.1c-0.8-0.2-0.8,3,0,2.8C38,191.1,38,187.9,37.2,188.1z"/>
		<path class="st41" d="M36.9,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,188.8c-0.8-0.2-0.8,3,0,2.8C39,191.8,39,188.6,38.2,188.8z"/>
		<path class="st41" d="M37.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,188.1c-0.8-0.2-0.8,3,0,2.8C36,191.1,36,187.9,35.2,188.1z"/>
		<path class="st41" d="M34.9,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,188.8c-0.8-0.2-0.8,3,0,2.8C37,191.8,37,188.6,36.2,188.8z"/>
		<path class="st41" d="M35.9,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,188.1c-0.8-0.2-0.8,3,0,2.8C34.1,191.1,34.1,187.9,33.3,188.1z"/>
		<path class="st41" d="M33,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,188.8c-0.8-0.2-0.8,3,0,2.8C35.2,191.8,35.2,188.6,34.3,188.8z"/>
		<path class="st41" d="M34.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,188.1c-0.8-0.2-0.8,3,0,2.8C32.1,191.1,32.1,187.9,31.3,188.1z"/>
		<path class="st41" d="M31,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,188.8c-0.8-0.2-0.8,3,0,2.8C33.2,191.8,33.2,188.6,32.4,188.8z"/>
		<path class="st41" d="M32.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,188.1c-0.8-0.2-0.8,3,0,2.8C30.1,191.1,30.2,187.9,29.3,188.1z"/>
		<path class="st41" d="M29,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,188.8c-0.8-0.2-0.8,3,0,2.8C31.2,191.8,31.2,188.6,30.4,188.8z"/>
		<path class="st41" d="M30.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,188.1c-0.8-0.2-0.8,3,0,2.8C28.2,191.1,28.2,187.9,27.3,188.1z"/>
		<path class="st41" d="M27,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,188.8c-0.8-0.2-0.8,3,0,2.8C29.2,191.8,29.2,188.6,28.4,188.8z"/>
		<path class="st41" d="M28.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,188.1c-0.8-0.2-0.8,3,0,2.8C26.2,191.1,26.2,187.9,25.3,188.1z"/>
		<path class="st41" d="M25.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,188.8c-0.8-0.2-0.8,3,0,2.8C27.2,191.8,27.2,188.6,26.4,188.8z"/>
		<path class="st41" d="M26.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,188.1c-0.8-0.2-0.8,3,0,2.8C24.2,191.1,24.2,187.9,23.4,188.1z"/>
		<path class="st41" d="M23.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,188.8c-0.8-0.2-0.8,3,0,2.8C25.2,191.8,25.2,188.6,24.4,188.8z"/>
		<path class="st41" d="M24.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,188.1c-0.8-0.2-0.8,3,0,2.8C22.2,191.1,22.2,187.9,21.4,188.1z"/>
		<path class="st41" d="M21.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,188.8c-0.8-0.2-0.8,3,0,2.8C23.3,191.8,23.3,188.6,22.4,188.8z"/>
		<path class="st41" d="M22.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,188.1c-0.8-0.2-0.8,3,0,2.8C20.2,191.1,20.2,187.9,19.4,188.1z"/>
		<path class="st41" d="M19.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,188.8c-0.8-0.2-0.8,3,0,2.8C21.3,191.8,21.3,188.6,20.4,188.8z"/>
		<path class="st41" d="M20.1,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,188.1c-0.8-0.2-0.8,3,0,2.8C18.2,191.1,18.2,187.9,17.4,188.1z"/>
		<path class="st41" d="M17.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,188.8c-0.8-0.2-0.8,3,0,2.8C19.3,191.8,19.3,188.6,18.4,188.8z"/>
		<path class="st41" d="M18.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,188.1c-0.8-0.2-0.8,3,0,2.8C16.2,191.1,16.2,187.9,15.4,188.1z"/>
		<path class="st41" d="M15.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,188.8c-0.8-0.2-0.8,3,0,2.8C17.3,191.8,17.3,188.6,16.5,188.8z"/>
		<path class="st41" d="M16.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,188.1c-0.8-0.2-0.8,3,0,2.8C14.3,191.1,14.3,187.9,13.4,188.1z"/>
		<path class="st41" d="M13.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,188.8c-0.8-0.2-0.8,3,0,2.8C15.3,191.8,15.3,188.6,14.5,188.8z"/>
		<path class="st41" d="M14.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,188.1c-0.8-0.2-0.8,3,0,2.8C12.3,191.1,12.3,187.9,11.4,188.1z"/>
		<path class="st41" d="M11.1,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,188.8c-0.8-0.2-0.8,3,0,2.8C13.3,191.8,13.3,188.6,12.5,188.8z"/>
		<path class="st41" d="M12.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,188.1c-0.8-0.2-0.8,3,0,2.8C10.3,191.1,10.3,187.9,9.5,188.1z"/>
		<path class="st41" d="M9.2,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,188.8c-0.8-0.2-0.8,3,0,2.8C11.3,191.8,11.3,188.6,10.5,188.8z"/>
		<path class="st41" d="M10.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,188.1c-0.8-0.2-0.8,3,0,2.8C8.3,191.1,8.3,187.9,7.5,188.1z"/>
		<path class="st41" d="M7.2,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,188.8c-0.8-0.2-0.8,3,0,2.8C9.3,191.8,9.3,188.6,8.5,188.8z"/>
		<path class="st41" d="M8.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,188.1c-0.8-0.2-0.8,3,0,2.8C6.3,191.1,6.3,187.9,5.5,188.1z"/>
		<path class="st41" d="M5.2,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,188.8c-0.8-0.2-0.8,3,0,2.8C7.4,191.8,7.4,188.6,6.5,188.8z"/>
		<path class="st41" d="M6.2,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,188.1c-0.8-0.2-0.8,3,0,2.8C4.3,191.1,4.3,187.9,3.5,188.1z"/>
		<path class="st41" d="M3.2,189c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,188.8c-0.8-0.2-0.8,3,0,2.8C5.4,191.8,5.4,188.6,4.5,188.8z"/>
		<path class="st41" d="M4.3,189.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,189.6c-0.8-0.2-0.8,3,0,2.8C65.8,192.6,65.8,189.4,65,189.6z"/>
		<path class="st41" d="M64.7,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,190.3c-0.8-0.2-0.8,3,0,2.8C66.9,193.3,66.9,190.1,66,190.3z"/>
		<path class="st41" d="M65.7,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,189.6c-0.8-0.2-0.8,3,0,2.8C63.8,192.6,63.8,189.4,63,189.6z"/>
		<path class="st41" d="M62.7,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,190.3c-0.8-0.2-0.8,3,0,2.8C64.9,193.3,64.9,190.1,64,190.3z"/>
		<path class="st41" d="M63.7,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,189.6c-0.8-0.2-0.8,3,0,2.8C61.8,192.6,61.8,189.4,61,189.6z"/>
		<path class="st41" d="M60.7,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,190.3c-0.8-0.2-0.8,3,0,2.8C62.9,193.3,62.9,190.1,62,190.3z"/>
		<path class="st41" d="M61.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,189.6c-0.8-0.2-0.8,3,0,2.8C59.8,192.6,59.8,189.4,59,189.6z"/>
		<path class="st41" d="M58.7,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,190.3c-0.8-0.2-0.8,3,0,2.8C60.9,193.3,60.9,190.1,60.1,190.3z"/>
		<path class="st41" d="M59.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,189.6c-0.8-0.2-0.8,3,0,2.8C57.9,192.6,57.9,189.4,57,189.6z"/>
		<path class="st41" d="M56.7,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,190.3c-0.8-0.2-0.8,3,0,2.8C58.9,193.3,58.9,190.1,58.1,190.3z"/>
		<path class="st41" d="M57.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,189.6c-0.8-0.2-0.8,3,0,2.8C55.9,192.6,55.9,189.4,55,189.6z"/>
		<path class="st41" d="M54.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,190.3c-0.8-0.2-0.8,3,0,2.8C56.9,193.3,56.9,190.1,56.1,190.3z"/>
		<path class="st41" d="M55.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,189.6c-0.8-0.2-0.8,3,0,2.8C53.9,192.6,53.9,189.4,53.1,189.6z"/>
		<path class="st41" d="M52.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,190.3c-0.8-0.2-0.8,3,0,2.8C54.9,193.3,54.9,190.1,54.1,190.3z"/>
		<path class="st41" d="M53.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,189.6c-0.8-0.2-0.8,3,0,2.8C51.9,192.6,51.9,189.4,51.1,189.6z"/>
		<path class="st41" d="M50.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,190.3c-0.8-0.2-0.8,3,0,2.8C52.9,193.3,52.9,190.1,52.1,190.3z"/>
		<path class="st41" d="M51.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,189.6c-0.8-0.2-0.8,3,0,2.8C49.9,192.6,49.9,189.4,49.1,189.6z"/>
		<path class="st41" d="M48.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,190.3c-0.8-0.2-0.8,3,0,2.8C51,193.3,51,190.1,50.1,190.3z"/>
		<path class="st41" d="M49.8,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,189.6c-0.8-0.2-0.8,3,0,2.8C47.9,192.6,47.9,189.4,47.1,189.6z"/>
		<path class="st41" d="M46.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,190.3c-0.8-0.2-0.8,3,0,2.8C49,193.3,49,190.1,48.1,190.3z"/>
		<path class="st41" d="M47.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,189.6c-0.8-0.2-0.8,3,0,2.8C45.9,192.6,45.9,189.4,45.1,189.6z"/>
		<path class="st41" d="M44.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,190.3c-0.8-0.2-0.8,3,0,2.8C47,193.3,47,190.1,46.2,190.3z"/>
		<path class="st41" d="M45.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,189.6c-0.8-0.2-0.8,3,0,2.8C43.9,192.6,43.9,189.4,43.1,189.6z"/>
		<path class="st41" d="M42.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,190.3c-0.8-0.2-0.8,3,0,2.8C45,193.3,45,190.1,44.2,190.3z"/>
		<path class="st41" d="M43.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,189.6c-0.8-0.2-0.8,3,0,2.8C42,192.6,42,189.4,41.1,189.6z"/>
		<path class="st41" d="M40.8,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,190.3c-0.8-0.2-0.8,3,0,2.8C43,193.3,43,190.1,42.2,190.3z"/>
		<path class="st41" d="M41.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,189.6c-0.8-0.2-0.8,3,0,2.8C40,192.6,40,189.4,39.1,189.6z"/>
		<path class="st41" d="M38.9,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,190.3c-0.8-0.2-0.8,3,0,2.8C41,193.3,41,190.1,40.2,190.3z"/>
		<path class="st41" d="M39.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,189.6c-0.8-0.2-0.8,3,0,2.8C38,192.6,38,189.4,37.2,189.6z"/>
		<path class="st41" d="M36.9,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,190.3c-0.8-0.2-0.8,3,0,2.8C39,193.3,39,190.1,38.2,190.3z"/>
		<path class="st41" d="M37.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,189.6c-0.8-0.2-0.8,3,0,2.8C36,192.6,36,189.4,35.2,189.6z"/>
		<path class="st41" d="M34.9,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,190.3c-0.8-0.2-0.8,3,0,2.8C37,193.3,37,190.1,36.2,190.3z"/>
		<path class="st41" d="M35.9,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,189.6c-0.8-0.2-0.8,3,0,2.8C34.1,192.6,34.1,189.4,33.3,189.6z"/>
		<path class="st41" d="M33,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,190.3c-0.8-0.2-0.8,3,0,2.8C35.2,193.3,35.2,190.1,34.3,190.3z"/>
		<path class="st41" d="M34.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,189.6c-0.8-0.2-0.8,3,0,2.8C32.1,192.6,32.1,189.4,31.3,189.6z"/>
		<path class="st41" d="M31,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,190.3c-0.8-0.2-0.8,3,0,2.8C33.2,193.3,33.2,190.1,32.4,190.3z"/>
		<path class="st41" d="M32.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,189.6c-0.8-0.2-0.8,3,0,2.8C30.1,192.6,30.2,189.4,29.3,189.6z"/>
		<path class="st41" d="M29,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,190.3c-0.8-0.2-0.8,3,0,2.8C31.2,193.3,31.2,190.1,30.4,190.3z"/>
		<path class="st41" d="M30.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,189.6c-0.8-0.2-0.8,3,0,2.8C28.2,192.6,28.2,189.4,27.3,189.6z"/>
		<path class="st41" d="M27,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,190.3c-0.8-0.2-0.8,3,0,2.8C29.2,193.3,29.2,190.1,28.4,190.3z"/>
		<path class="st41" d="M28.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,189.6c-0.8-0.2-0.8,3,0,2.8C26.2,192.6,26.2,189.4,25.3,189.6z"/>
		<path class="st41" d="M25.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,190.3c-0.8-0.2-0.8,3,0,2.8C27.2,193.3,27.2,190.1,26.4,190.3z"/>
		<path class="st41" d="M26.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,189.6c-0.8-0.2-0.8,3,0,2.8C24.2,192.6,24.2,189.4,23.4,189.6z"/>
		<path class="st41" d="M23.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,190.3c-0.8-0.2-0.8,3,0,2.8C25.2,193.3,25.2,190.1,24.4,190.3z"/>
		<path class="st41" d="M24.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,189.6c-0.8-0.2-0.8,3,0,2.8C22.2,192.6,22.2,189.4,21.4,189.6z"/>
		<path class="st41" d="M21.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,190.3c-0.8-0.2-0.8,3,0,2.8C23.3,193.3,23.3,190.1,22.4,190.3z"/>
		<path class="st41" d="M22.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,189.6c-0.8-0.2-0.8,3,0,2.8C20.2,192.6,20.2,189.4,19.4,189.6z"/>
		<path class="st41" d="M19.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,190.3c-0.8-0.2-0.8,3,0,2.8C21.3,193.3,21.3,190.1,20.4,190.3z"/>
		<path class="st41" d="M20.1,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,189.6c-0.8-0.2-0.8,3,0,2.8C18.2,192.6,18.2,189.4,17.4,189.6z"/>
		<path class="st41" d="M17.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,190.3c-0.8-0.2-0.8,3,0,2.8C19.3,193.3,19.3,190.1,18.4,190.3z"/>
		<path class="st41" d="M18.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,189.6c-0.8-0.2-0.8,3,0,2.8C16.2,192.6,16.2,189.4,15.4,189.6z"/>
		<path class="st41" d="M15.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,190.3c-0.8-0.2-0.8,3,0,2.8C17.3,193.3,17.3,190.1,16.5,190.3z"/>
		<path class="st41" d="M16.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,189.6c-0.8-0.2-0.8,3,0,2.8C14.3,192.6,14.3,189.4,13.4,189.6z"/>
		<path class="st41" d="M13.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,190.3c-0.8-0.2-0.8,3,0,2.8C15.3,193.3,15.3,190.1,14.5,190.3z"/>
		<path class="st41" d="M14.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,189.6c-0.8-0.2-0.8,3,0,2.8C12.3,192.6,12.3,189.4,11.4,189.6z"/>
		<path class="st41" d="M11.1,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,190.3c-0.8-0.2-0.8,3,0,2.8C13.3,193.3,13.3,190.1,12.5,190.3z"/>
		<path class="st41" d="M12.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,189.6c-0.8-0.2-0.8,3,0,2.8C10.3,192.6,10.3,189.4,9.5,189.6z"/>
		<path class="st41" d="M9.2,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,190.3c-0.8-0.2-0.8,3,0,2.8C11.3,193.3,11.3,190.1,10.5,190.3z"/>
		<path class="st41" d="M10.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,189.6c-0.8-0.2-0.8,3,0,2.8C8.3,192.6,8.3,189.4,7.5,189.6z"/>
		<path class="st41" d="M7.2,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,190.3c-0.8-0.2-0.8,3,0,2.8C9.3,193.3,9.3,190.1,8.5,190.3z"/>
		<path class="st41" d="M8.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,189.6c-0.8-0.2-0.8,3,0,2.8C6.3,192.6,6.3,189.4,5.5,189.6z"/>
		<path class="st41" d="M5.2,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,190.3c-0.8-0.2-0.8,3,0,2.8C7.4,193.3,7.4,190.1,6.5,190.3z"/>
		<path class="st41" d="M6.2,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,189.6c-0.8-0.2-0.8,3,0,2.8C4.3,192.6,4.3,189.4,3.5,189.6z"/>
		<path class="st41" d="M3.2,190.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,190.3c-0.8-0.2-0.8,3,0,2.8C5.4,193.3,5.4,190.1,4.5,190.3z"/>
		<path class="st41" d="M4.3,191.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,191c-0.8-0.2-0.8,3,0,2.8C65.8,194,65.8,190.9,65,191z"/>
		<path class="st41" d="M64.7,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,191.7c-0.8-0.2-0.8,3,0,2.8C66.9,194.7,66.9,191.5,66,191.7z"/>
		<path class="st41" d="M65.7,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,191c-0.8-0.2-0.8,3,0,2.8C63.8,194,63.8,190.9,63,191z"/>
		<path class="st41" d="M62.7,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,191.7c-0.8-0.2-0.8,3,0,2.8C64.9,194.7,64.9,191.5,64,191.7z"/>
		<path class="st41" d="M63.7,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,191c-0.8-0.2-0.8,3,0,2.8C61.8,194,61.8,190.9,61,191z"/>
		<path class="st41" d="M60.7,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,191.7c-0.8-0.2-0.8,3,0,2.8C62.9,194.7,62.9,191.5,62,191.7z"/>
		<path class="st41" d="M61.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,191c-0.8-0.2-0.8,3,0,2.8C59.8,194,59.8,190.9,59,191z"/>
		<path class="st41" d="M58.7,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,191.7c-0.8-0.2-0.8,3,0,2.8C60.9,194.7,60.9,191.5,60.1,191.7z"/>
		<path class="st41" d="M59.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,191c-0.8-0.2-0.8,3,0,2.8C57.9,194,57.9,190.9,57,191z"/>
		<path class="st41" d="M56.7,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,191.7c-0.8-0.2-0.8,3,0,2.8C58.9,194.7,58.9,191.5,58.1,191.7z"/>
		<path class="st41" d="M57.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,191c-0.8-0.2-0.8,3,0,2.8C55.9,194,55.9,190.9,55,191z"/>
		<path class="st41" d="M54.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,191.7c-0.8-0.2-0.8,3,0,2.8C56.9,194.7,56.9,191.5,56.1,191.7z"/>
		<path class="st41" d="M55.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,191c-0.8-0.2-0.8,3,0,2.8C53.9,194,53.9,190.9,53.1,191z"/>
		<path class="st41" d="M52.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,191.7c-0.8-0.2-0.8,3,0,2.8C54.9,194.7,54.9,191.5,54.1,191.7z"/>
		<path class="st41" d="M53.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,191c-0.8-0.2-0.8,3,0,2.8C51.9,194,51.9,190.9,51.1,191z"/>
		<path class="st41" d="M50.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,191.7c-0.8-0.2-0.8,3,0,2.8C52.9,194.7,52.9,191.5,52.1,191.7z"/>
		<path class="st41" d="M51.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,191c-0.8-0.2-0.8,3,0,2.8C49.9,194,49.9,190.9,49.1,191z"/>
		<path class="st41" d="M48.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,191.7c-0.8-0.2-0.8,3,0,2.8C51,194.7,51,191.5,50.1,191.7z"/>
		<path class="st41" d="M49.8,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,191c-0.8-0.2-0.8,3,0,2.8C47.9,194,47.9,190.9,47.1,191z"/>
		<path class="st41" d="M46.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,191.7c-0.8-0.2-0.8,3,0,2.8C49,194.7,49,191.5,48.1,191.7z"/>
		<path class="st41" d="M47.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,191c-0.8-0.2-0.8,3,0,2.8C45.9,194,45.9,190.9,45.1,191z"/>
		<path class="st41" d="M44.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,191.7c-0.8-0.2-0.8,3,0,2.8C47,194.7,47,191.5,46.2,191.7z"/>
		<path class="st41" d="M45.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,191c-0.8-0.2-0.8,3,0,2.8C43.9,194,43.9,190.9,43.1,191z"/>
		<path class="st41" d="M42.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,191.7c-0.8-0.2-0.8,3,0,2.8C45,194.7,45,191.5,44.2,191.7z"/>
		<path class="st41" d="M43.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,191c-0.8-0.2-0.8,3,0,2.8C42,194,42,190.9,41.1,191z"/>
		<path class="st41" d="M40.8,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,191.7c-0.8-0.2-0.8,3,0,2.8C43,194.7,43,191.5,42.2,191.7z"/>
		<path class="st41" d="M41.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,191c-0.8-0.2-0.8,3,0,2.8C40,194,40,190.9,39.1,191z"/>
		<path class="st41" d="M38.9,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,191.7c-0.8-0.2-0.8,3,0,2.8C41,194.7,41,191.5,40.2,191.7z"/>
		<path class="st41" d="M39.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,191c-0.8-0.2-0.8,3,0,2.8C38,194,38,190.9,37.2,191z"/>
		<path class="st41" d="M36.9,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,191.7c-0.8-0.2-0.8,3,0,2.8C39,194.7,39,191.5,38.2,191.7z"/>
		<path class="st41" d="M37.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,191c-0.8-0.2-0.8,3,0,2.8C36,194,36,190.9,35.2,191z"/>
		<path class="st41" d="M34.9,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,191.7c-0.8-0.2-0.8,3,0,2.8C37,194.7,37,191.5,36.2,191.7z"/>
		<path class="st41" d="M35.9,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,191c-0.8-0.2-0.8,3,0,2.8C34.1,194,34.1,190.9,33.3,191z"/>
		<path class="st41" d="M33,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,191.7c-0.8-0.2-0.8,3,0,2.8C35.2,194.7,35.2,191.5,34.3,191.7z"/>
		<path class="st41" d="M34.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,191c-0.8-0.2-0.8,3,0,2.8C32.1,194,32.1,190.9,31.3,191z"/>
		<path class="st41" d="M31,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,191.7c-0.8-0.2-0.8,3,0,2.8C33.2,194.7,33.2,191.5,32.4,191.7z"/>
		<path class="st41" d="M32.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,191c-0.8-0.2-0.8,3,0,2.8C30.1,194,30.2,190.9,29.3,191z"/>
		<path class="st41" d="M29,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,191.7c-0.8-0.2-0.8,3,0,2.8C31.2,194.7,31.2,191.5,30.4,191.7z"/>
		<path class="st41" d="M30.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,191c-0.8-0.2-0.8,3,0,2.8C28.2,194,28.2,190.9,27.3,191z"/>
		<path class="st41" d="M27,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,191.7c-0.8-0.2-0.8,3,0,2.8C29.2,194.7,29.2,191.5,28.4,191.7z"/>
		<path class="st41" d="M28.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,191c-0.8-0.2-0.8,3,0,2.8C26.2,194,26.2,190.9,25.3,191z"/>
		<path class="st41" d="M25.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,191.7c-0.8-0.2-0.8,3,0,2.8C27.2,194.7,27.2,191.5,26.4,191.7z"/>
		<path class="st41" d="M26.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,191c-0.8-0.2-0.8,3,0,2.8C24.2,194,24.2,190.9,23.4,191z"/>
		<path class="st41" d="M23.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,191.7c-0.8-0.2-0.8,3,0,2.8C25.2,194.7,25.2,191.5,24.4,191.7z"/>
		<path class="st41" d="M24.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,191c-0.8-0.2-0.8,3,0,2.8C22.2,194,22.2,190.9,21.4,191z"/>
		<path class="st41" d="M21.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,191.7c-0.8-0.2-0.8,3,0,2.8C23.3,194.7,23.3,191.5,22.4,191.7z"/>
		<path class="st41" d="M22.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,191c-0.8-0.2-0.8,3,0,2.8C20.2,194,20.2,190.9,19.4,191z"/>
		<path class="st41" d="M19.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,191.7c-0.8-0.2-0.8,3,0,2.8C21.3,194.7,21.3,191.5,20.4,191.7z"/>
		<path class="st41" d="M20.1,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,191c-0.8-0.2-0.8,3,0,2.8C18.2,194,18.2,190.9,17.4,191z"/>
		<path class="st41" d="M17.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,191.7c-0.8-0.2-0.8,3,0,2.8C19.3,194.7,19.3,191.5,18.4,191.7z"/>
		<path class="st41" d="M18.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,191c-0.8-0.2-0.8,3,0,2.8C16.2,194,16.2,190.9,15.4,191z"/>
		<path class="st41" d="M15.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,191.7c-0.8-0.2-0.8,3,0,2.8C17.3,194.7,17.3,191.5,16.5,191.7z"/>
		<path class="st41" d="M16.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,191c-0.8-0.2-0.8,3,0,2.8C14.3,194,14.3,190.9,13.4,191z"/>
		<path class="st41" d="M13.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,191.7c-0.8-0.2-0.8,3,0,2.8C15.3,194.7,15.3,191.5,14.5,191.7z"/>
		<path class="st41" d="M14.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,191c-0.8-0.2-0.8,3,0,2.8C12.3,194,12.3,190.9,11.4,191z"/>
		<path class="st41" d="M11.1,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,191.7c-0.8-0.2-0.8,3,0,2.8C13.3,194.7,13.3,191.5,12.5,191.7z"/>
		<path class="st41" d="M12.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,191c-0.8-0.2-0.8,3,0,2.8C10.3,194,10.3,190.9,9.5,191z"/>
		<path class="st41" d="M9.2,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,191.7c-0.8-0.2-0.8,3,0,2.8C11.3,194.7,11.3,191.5,10.5,191.7z"/>
		<path class="st41" d="M10.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,191c-0.8-0.2-0.8,3,0,2.8C8.3,194,8.3,190.9,7.5,191z"/>
		<path class="st41" d="M7.2,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,191.7c-0.8-0.2-0.8,3,0,2.8C9.3,194.7,9.3,191.5,8.5,191.7z"/>
		<path class="st41" d="M8.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,191c-0.8-0.2-0.8,3,0,2.8C6.3,194,6.3,190.9,5.5,191z"/>
		<path class="st41" d="M5.2,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,191.7c-0.8-0.2-0.8,3,0,2.8C7.4,194.7,7.4,191.5,6.5,191.7z"/>
		<path class="st41" d="M6.2,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,191c-0.8-0.2-0.8,3,0,2.8C4.3,194,4.3,190.9,3.5,191z"/>
		<path class="st41" d="M3.2,191.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,191.7c-0.8-0.2-0.8,3,0,2.8C5.4,194.7,5.4,191.5,4.5,191.7z"/>
		<path class="st41" d="M4.3,192.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,192.4c-0.8-0.2-0.8,3,0,2.8C51.9,195.4,51.9,192.2,51.1,192.4z"/>
		<path class="st41" d="M50.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,192.4c-0.8-0.2-0.8,3,0,2.8C49.9,195.4,49.9,192.2,49.1,192.4z"/>
		<path class="st41" d="M48.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,192.4c-0.8-0.2-0.8,3,0,2.8C47.9,195.4,47.9,192.2,47.1,192.4z"/>
		<path class="st41" d="M46.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,192.4c-0.8-0.2-0.8,3,0,2.8C45.9,195.4,45.9,192.2,45.1,192.4z"/>
		<path class="st41" d="M44.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,192.4c-0.8-0.2-0.8,3,0,2.8C43.9,195.4,43.9,192.2,43.1,192.4z"/>
		<path class="st41" d="M42.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,192.4c-0.8-0.2-0.8,3,0,2.8C42,195.4,42,192.2,41.1,192.4z"/>
		<path class="st41" d="M40.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,192.4c-0.8-0.2-0.8,3,0,2.8C40,195.4,40,192.2,39.1,192.4z"/>
		<path class="st41" d="M38.9,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,192.4c-0.8-0.2-0.8,3,0,2.8C38,195.4,38,192.2,37.2,192.4z"/>
		<path class="st41" d="M36.9,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,192.4c-0.8-0.2-0.8,3,0,2.8C36,195.4,36,192.2,35.2,192.4z"/>
		<path class="st41" d="M34.9,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,192.4c-0.8-0.2-0.8,3,0,2.8C34.1,195.4,34.1,192.2,33.3,192.4z"/>
		<path class="st41" d="M33,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,192.4c-0.8-0.2-0.8,3,0,2.8C32.1,195.4,32.1,192.2,31.3,192.4z"/>
		<path class="st41" d="M31,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,192.4c-0.8-0.2-0.8,3,0,2.8C30.1,195.4,30.2,192.2,29.3,192.4z"/>
		<path class="st41" d="M29,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,192.4c-0.8-0.2-0.8,3,0,2.8C28.2,195.4,28.2,192.2,27.3,192.4z"/>
		<path class="st41" d="M27,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,192.4c-0.8-0.2-0.8,3,0,2.8C26.2,195.4,26.2,192.2,25.3,192.4z"/>
		<path class="st41" d="M25.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,192.4c-0.8-0.2-0.8,3,0,2.8C24.2,195.4,24.2,192.2,23.4,192.4z"/>
		<path class="st41" d="M23.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,192.4c-0.8-0.2-0.8,3,0,2.8C22.2,195.4,22.2,192.2,21.4,192.4z"/>
		<path class="st41" d="M21.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,192.4c-0.8-0.2-0.8,3,0,2.8C20.2,195.4,20.2,192.2,19.4,192.4z"/>
		<path class="st41" d="M19.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,192.4c-0.8-0.2-0.8,3,0,2.8C18.2,195.4,18.2,192.2,17.4,192.4z"/>
		<path class="st41" d="M17.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,192.4c-0.8-0.2-0.8,3,0,2.8C16.2,195.4,16.2,192.2,15.4,192.4z"/>
		<path class="st41" d="M15.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,192.4c-0.8-0.2-0.8,3,0,2.8C14.3,195.4,14.3,192.2,13.4,192.4z"/>
		<path class="st41" d="M13.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,192.4c-0.8-0.2-0.8,3,0,2.8C12.3,195.4,12.3,192.2,11.4,192.4z"/>
		<path class="st41" d="M11.1,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,192.4c-0.8-0.2-0.8,3,0,2.8C10.3,195.4,10.3,192.2,9.5,192.4z"/>
		<path class="st41" d="M9.2,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,192.4c-0.8-0.2-0.8,3,0,2.8C8.3,195.4,8.3,192.2,7.5,192.4z"/>
		<path class="st41" d="M7.2,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,192.4c-0.8-0.2-0.8,3,0,2.8C6.3,195.4,6.3,192.2,5.5,192.4z"/>
		<path class="st41" d="M5.2,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,192.4c-0.8-0.2-0.8,3,0,2.8C4.3,195.4,4.3,192.2,3.5,192.4z"/>
		<path class="st42" d="M3.2,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M53,192.4c-0.8-0.2-0.8,3,0,2.8C53.9,195.4,53.9,192.2,53,192.4z"/>
		<path class="st41" d="M52.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.5,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,192.4c-0.8-0.2-0.8,3,0,2.8C65.8,195.4,65.8,192.2,65,192.4z"/>
		<path class="st41" d="M64.7,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,192.4c-0.8-0.2-0.8,3,0,2.8C63.8,195.4,63.8,192.2,63,192.4z"/>
		<path class="st41" d="M62.7,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,192.4c-0.8-0.2-0.8,3,0,2.8C61.8,195.4,61.8,192.2,61,192.4z"/>
		<path class="st41" d="M60.7,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,192.4c-0.8-0.2-0.8,3,0,2.8C59.8,195.4,59.8,192.2,59,192.4z"/>
		<path class="st41" d="M58.7,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,192.4c-0.8-0.2-0.8,3,0,2.8C57.9,195.4,57.9,192.2,57,192.4z"/>
		<path class="st41" d="M56.7,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,192.4c-0.8-0.2-0.8,3,0,2.8C55.9,195.4,55.9,192.2,55,192.4z"/>
		<path class="st41" d="M54.8,193.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M65,236.3c-0.8-0.2-0.8,3,0,2.8C65.8,239.3,65.8,236.1,65,236.3z"/>
		<path class="st41" d="M64.7,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,237c-0.8-0.2-0.8,3,0,2.8C66.9,240,66.9,236.8,66,237z"/>
		<path class="st41" d="M65.7,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,236.3c-0.8-0.2-0.8,3,0,2.8C63.8,239.3,63.8,236.1,63,236.3z"/>
		<path class="st41" d="M62.7,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,237c-0.8-0.2-0.8,3,0,2.8C64.9,240,64.9,236.8,64,237z"/>
		<path class="st41" d="M63.7,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,236.3c-0.8-0.2-0.8,3,0,2.8C61.8,239.3,61.8,236.1,61,236.3z"/>
		<path class="st41" d="M60.7,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,237c-0.8-0.2-0.8,3,0,2.8C62.9,240,62.9,236.8,62,237z"/>
		<path class="st41" d="M61.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,236.3c-0.8-0.2-0.8,3,0,2.8C59.8,239.3,59.8,236.1,59,236.3z"/>
		<path class="st41" d="M58.7,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,237c-0.8-0.2-0.8,3,0,2.8C60.9,240,60.9,236.8,60.1,237z"/>
		<path class="st41" d="M59.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,236.3c-0.8-0.2-0.8,3,0,2.8C57.9,239.3,57.9,236.1,57,236.3z"/>
		<path class="st41" d="M56.7,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,237c-0.8-0.2-0.8,3,0,2.8C58.9,240,58.9,236.8,58.1,237z"/>
		<path class="st41" d="M57.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,236.3c-0.8-0.2-0.8,3,0,2.8C55.9,239.3,55.9,236.1,55,236.3z"/>
		<path class="st41" d="M54.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,237c-0.8-0.2-0.8,3,0,2.8C56.9,240,56.9,236.8,56.1,237z"/>
		<path class="st41" d="M55.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,236.3c-0.8-0.2-0.8,3,0,2.8C53.9,239.3,53.9,236.1,53.1,236.3z"/>
		<path class="st41" d="M52.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,237c-0.8-0.2-0.8,3,0,2.8C54.9,240,54.9,236.8,54.1,237z"/>
		<path class="st41" d="M53.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,236.3c-0.8-0.2-0.8,3,0,2.8C51.9,239.3,51.9,236.1,51.1,236.3z"/>
		<path class="st41" d="M50.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,237c-0.8-0.2-0.8,3,0,2.8C52.9,240,52.9,236.8,52.1,237z"/>
		<path class="st41" d="M51.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,236.3c-0.8-0.2-0.8,3,0,2.8C49.9,239.3,49.9,236.1,49.1,236.3z"/>
		<path class="st41" d="M48.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,237c-0.8-0.2-0.8,3,0,2.8C51,240,51,236.8,50.1,237z"/>
		<path class="st41" d="M49.8,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,236.3c-0.8-0.2-0.8,3,0,2.8C47.9,239.3,47.9,236.1,47.1,236.3z"/>
		<path class="st41" d="M46.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,237c-0.8-0.2-0.8,3,0,2.8C49,240,49,236.8,48.1,237z"/>
		<path class="st41" d="M47.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,236.3c-0.8-0.2-0.8,3,0,2.8C45.9,239.3,45.9,236.1,45.1,236.3z"/>
		<path class="st41" d="M44.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,237c-0.8-0.2-0.8,3,0,2.8C47,240,47,236.8,46.2,237z"/>
		<path class="st41" d="M45.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,236.3c-0.8-0.2-0.8,3,0,2.8C43.9,239.3,43.9,236.1,43.1,236.3z"/>
		<path class="st41" d="M42.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,237c-0.8-0.2-0.8,3,0,2.8C45,240,45,236.8,44.2,237z"/>
		<path class="st41" d="M43.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,236.3c-0.8-0.2-0.8,3,0,2.8C42,239.3,42,236.1,41.1,236.3z"/>
		<path class="st41" d="M40.8,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,237c-0.8-0.2-0.8,3,0,2.8C43,240,43,236.8,42.2,237z"/>
		<path class="st41" d="M41.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,236.3c-0.8-0.2-0.8,3,0,2.8C40,239.3,40,236.1,39.1,236.3z"/>
		<path class="st41" d="M38.9,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,237c-0.8-0.2-0.8,3,0,2.8C41,240,41,236.8,40.2,237z"/>
		<path class="st41" d="M39.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,236.3c-0.8-0.2-0.8,3,0,2.8C38,239.3,38,236.1,37.2,236.3z"/>
		<path class="st41" d="M36.9,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,237c-0.8-0.2-0.8,3,0,2.8C39,240,39,236.8,38.2,237z"/>
		<path class="st41" d="M37.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,236.3c-0.8-0.2-0.8,3,0,2.8C36,239.3,36,236.1,35.2,236.3z"/>
		<path class="st41" d="M34.9,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,237c-0.8-0.2-0.8,3,0,2.8C37,240,37,236.8,36.2,237z"/>
		<path class="st41" d="M35.9,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,236.3c-0.8-0.2-0.8,3,0,2.8C34.1,239.3,34.1,236.1,33.3,236.3z"/>
		<path class="st41" d="M33,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,237c-0.8-0.2-0.8,3,0,2.8C35.2,240,35.2,236.8,34.3,237z"/>
		<path class="st41" d="M34.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,236.3c-0.8-0.2-0.8,3,0,2.8C32.1,239.3,32.1,236.1,31.3,236.3z"/>
		<path class="st41" d="M31,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,237c-0.8-0.2-0.8,3,0,2.8C33.2,240,33.2,236.8,32.4,237z"/>
		<path class="st41" d="M32.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,236.3c-0.8-0.2-0.8,3,0,2.8C30.1,239.3,30.2,236.1,29.3,236.3z"/>
		<path class="st41" d="M29,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,237c-0.8-0.2-0.8,3,0,2.8C31.2,240,31.2,236.8,30.4,237z"/>
		<path class="st41" d="M30.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,236.3c-0.8-0.2-0.8,3,0,2.8C28.2,239.3,28.2,236.1,27.3,236.3z"/>
		<path class="st41" d="M27,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,237c-0.8-0.2-0.8,3,0,2.8C29.2,240,29.2,236.8,28.4,237z"/>
		<path class="st41" d="M28.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,236.3c-0.8-0.2-0.8,3,0,2.8C26.2,239.3,26.2,236.1,25.3,236.3z"/>
		<path class="st41" d="M25.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,237c-0.8-0.2-0.8,3,0,2.8C27.2,240,27.2,236.8,26.4,237z"/>
		<path class="st41" d="M26.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,236.3c-0.8-0.2-0.8,3,0,2.8C24.2,239.3,24.2,236.1,23.4,236.3z"/>
		<path class="st41" d="M23.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,237c-0.8-0.2-0.8,3,0,2.8C25.2,240,25.2,236.8,24.4,237z"/>
		<path class="st41" d="M24.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,236.3c-0.8-0.2-0.8,3,0,2.8C22.2,239.3,22.2,236.1,21.4,236.3z"/>
		<path class="st41" d="M21.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,237c-0.8-0.2-0.8,3,0,2.8C23.3,240,23.3,236.8,22.4,237z"/>
		<path class="st41" d="M22.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,236.3c-0.8-0.2-0.8,3,0,2.8C20.2,239.3,20.2,236.1,19.4,236.3z"/>
		<path class="st41" d="M19.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,237c-0.8-0.2-0.8,3,0,2.8C21.3,240,21.3,236.8,20.4,237z"/>
		<path class="st41" d="M20.1,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,236.3c-0.8-0.2-0.8,3,0,2.8C18.2,239.3,18.2,236.1,17.4,236.3z"/>
		<path class="st41" d="M17.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,237c-0.8-0.2-0.8,3,0,2.8C19.3,240,19.3,236.8,18.4,237z"/>
		<path class="st41" d="M18.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,236.3c-0.8-0.2-0.8,3,0,2.8C16.2,239.3,16.2,236.1,15.4,236.3z"/>
		<path class="st41" d="M15.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,237c-0.8-0.2-0.8,3,0,2.8C17.3,240,17.3,236.8,16.5,237z"/>
		<path class="st41" d="M16.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,236.3c-0.8-0.2-0.8,3,0,2.8C14.3,239.3,14.3,236.1,13.4,236.3z"/>
		<path class="st41" d="M13.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,237c-0.8-0.2-0.8,3,0,2.8C15.3,240,15.3,236.8,14.5,237z"/>
		<path class="st41" d="M14.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,236.3c-0.8-0.2-0.8,3,0,2.8C12.3,239.3,12.3,236.1,11.4,236.3z"/>
		<path class="st41" d="M11.1,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,237c-0.8-0.2-0.8,3,0,2.8C13.3,240,13.3,236.8,12.5,237z"/>
		<path class="st41" d="M12.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,236.3c-0.8-0.2-0.8,3,0,2.8C10.3,239.3,10.3,236.1,9.5,236.3z"/>
		<path class="st41" d="M9.2,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,237c-0.8-0.2-0.8,3,0,2.8C11.3,240,11.3,236.8,10.5,237z"/>
		<path class="st41" d="M10.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,236.3c-0.8-0.2-0.8,3,0,2.8C8.3,239.3,8.3,236.1,7.5,236.3z"/>
		<path class="st41" d="M7.2,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,237c-0.8-0.2-0.8,3,0,2.8C9.3,240,9.3,236.8,8.5,237z"/>
		<path class="st41" d="M8.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,236.3c-0.8-0.2-0.8,3,0,2.8C6.3,239.3,6.3,236.1,5.5,236.3z"/>
		<path class="st41" d="M5.2,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,237c-0.8-0.2-0.8,3,0,2.8C7.4,240,7.4,236.8,6.5,237z"/>
		<path class="st41" d="M6.2,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,236.3c-0.8-0.2-0.8,3,0,2.8C4.3,239.3,4.3,236.1,3.5,236.3z"/>
		<path class="st41" d="M3.2,237.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,237c-0.8-0.2-0.8,3,0,2.8C5.4,240,5.4,236.8,4.5,237z"/>
		<path class="st41" d="M4.3,237.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,237.7c-0.8-0.2-0.8,3,0,2.8C51.9,240.7,51.9,237.6,51.1,237.7z"/>
		<path class="st41" d="M50.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,237.7c-0.8-0.2-0.8,3,0,2.8C49.9,240.7,49.9,237.6,49.1,237.7z"/>
		<path class="st41" d="M48.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,237.7c-0.8-0.2-0.8,3,0,2.8C47.9,240.7,47.9,237.6,47.1,237.7z"/>
		<path class="st41" d="M46.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,237.7c-0.8-0.2-0.8,3,0,2.8C45.9,240.7,45.9,237.6,45.1,237.7z"/>
		<path class="st41" d="M44.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,237.7c-0.8-0.2-0.8,3,0,2.8C43.9,240.7,43.9,237.6,43.1,237.7z"/>
		<path class="st41" d="M42.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,237.7c-0.8-0.2-0.8,3,0,2.8C42,240.7,42,237.6,41.1,237.7z"/>
		<path class="st41" d="M40.8,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,237.7c-0.8-0.2-0.8,3,0,2.8C40,240.7,40,237.6,39.1,237.7z"/>
		<path class="st41" d="M38.9,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,237.7c-0.8-0.2-0.8,3,0,2.8C38,240.7,38,237.6,37.2,237.7z"/>
		<path class="st41" d="M36.9,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,237.7c-0.8-0.2-0.8,3,0,2.8C36,240.7,36,237.6,35.2,237.7z"/>
		<path class="st41" d="M34.9,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,237.7c-0.8-0.2-0.8,3,0,2.8C34.1,240.7,34.1,237.6,33.3,237.7z"/>
		<path class="st41" d="M33,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,237.7c-0.8-0.2-0.8,3,0,2.8C32.1,240.7,32.1,237.6,31.3,237.7z"/>
		<path class="st41" d="M31,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,237.7c-0.8-0.2-0.8,3,0,2.8C30.1,240.7,30.2,237.6,29.3,237.7z"/>
		<path class="st41" d="M29,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,237.7c-0.8-0.2-0.8,3,0,2.8C28.2,240.7,28.2,237.6,27.3,237.7z"/>
		<path class="st41" d="M27,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,237.7c-0.8-0.2-0.8,3,0,2.8C26.2,240.7,26.2,237.6,25.3,237.7z"/>
		<path class="st41" d="M25.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,237.7c-0.8-0.2-0.8,3,0,2.8C24.2,240.7,24.2,237.6,23.4,237.7z"/>
		<path class="st41" d="M23.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,237.7c-0.8-0.2-0.8,3,0,2.8C22.2,240.7,22.2,237.6,21.4,237.7z"/>
		<path class="st41" d="M21.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,237.7c-0.8-0.2-0.8,3,0,2.8C20.2,240.7,20.2,237.6,19.4,237.7z"/>
		<path class="st41" d="M19.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,237.7c-0.8-0.2-0.8,3,0,2.8C18.2,240.7,18.2,237.6,17.4,237.7z"/>
		<path class="st41" d="M17.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,237.7c-0.8-0.2-0.8,3,0,2.8C16.2,240.7,16.2,237.6,15.4,237.7z"/>
		<path class="st41" d="M15.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,237.7c-0.8-0.2-0.8,3,0,2.8C14.3,240.7,14.3,237.6,13.4,237.7z"/>
		<path class="st41" d="M13.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,237.7c-0.8-0.2-0.8,3,0,2.8C12.3,240.7,12.3,237.6,11.4,237.7z"/>
		<path class="st41" d="M11.1,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,237.7c-0.8-0.2-0.8,3,0,2.8C10.3,240.7,10.3,237.6,9.5,237.7z"/>
		<path class="st41" d="M9.2,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,237.7c-0.8-0.2-0.8,3,0,2.8C8.3,240.7,8.3,237.6,7.5,237.7z"/>
		<path class="st41" d="M7.2,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,237.7c-0.8-0.2-0.8,3,0,2.8C6.3,240.7,6.3,237.6,5.5,237.7z"/>
		<path class="st41" d="M5.2,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,237.7c-0.8-0.2-0.8,3,0,2.8C4.3,240.7,4.3,237.6,3.5,237.7z"/>
		<path class="st41" d="M3.2,238.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M65,262.4c-0.8-0.2-0.8,3,0,2.8C65.8,265.4,65.8,262.2,65,262.4z"/>
		<path class="st41" d="M64.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,262.4c-0.8-0.2-0.8,3,0,2.8C63.8,265.4,63.8,262.2,63,262.4z"/>
		<path class="st41" d="M62.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,262.4c-0.8-0.2-0.8,3,0,2.8C61.8,265.4,61.8,262.2,61,262.4z"/>
		<path class="st41" d="M60.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,262.4c-0.8-0.2-0.8,3,0,2.8C59.8,265.4,59.8,262.2,59,262.4z"/>
		<path class="st41" d="M58.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,262.4c-0.8-0.2-0.8,3,0,2.8C57.9,265.4,57.9,262.2,57,262.4z"/>
		<path class="st41" d="M56.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,262.4c-0.8-0.2-0.8,3,0,2.8C55.9,265.4,55.9,262.2,55,262.4z"/>
		<path class="st41" d="M54.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,262.4c-0.8-0.2-0.8,3,0,2.8C53.9,265.4,53.9,262.2,53.1,262.4z"/>
		<path class="st41" d="M52.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,262.4c-0.8-0.2-0.8,3,0,2.8C51.9,265.4,51.9,262.2,51.1,262.4z"/>
		<path class="st41" d="M50.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,262.4c-0.8-0.2-0.8,3,0,2.8C49.9,265.4,49.9,262.2,49.1,262.4z"/>
		<path class="st41" d="M48.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,262.4c-0.8-0.2-0.8,3,0,2.8C47.9,265.4,47.9,262.2,47.1,262.4z"/>
		<path class="st41" d="M46.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,262.4c-0.8-0.2-0.8,3,0,2.8C45.9,265.4,45.9,262.2,45.1,262.4z"/>
		<path class="st41" d="M44.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,262.4c-0.8-0.2-0.8,3,0,2.8C43.9,265.4,43.9,262.2,43.1,262.4z"/>
		<path class="st41" d="M42.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,262.4c-0.8-0.2-0.8,3,0,2.8C42,265.4,42,262.2,41.1,262.4z"/>
		<path class="st41" d="M40.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,262.4c-0.8-0.2-0.8,3,0,2.8C40,265.4,40,262.2,39.1,262.4z"/>
		<path class="st41" d="M38.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,262.4c-0.8-0.2-0.8,3,0,2.8C38,265.4,38,262.2,37.2,262.4z"/>
		<path class="st41" d="M36.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,262.4c-0.8-0.2-0.8,3,0,2.8C36,265.4,36,262.2,35.2,262.4z"/>
		<path class="st41" d="M34.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,262.4c-0.8-0.2-0.8,3,0,2.8C34.1,265.4,34.1,262.2,33.3,262.4z"/>
		<path class="st41" d="M33,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,262.4c-0.8-0.2-0.8,3,0,2.8C32.1,265.4,32.1,262.2,31.3,262.4z"/>
		<path class="st41" d="M31,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,262.4c-0.8-0.2-0.8,3,0,2.8C30.1,265.4,30.2,262.2,29.3,262.4z"/>
		<path class="st41" d="M29,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,262.4c-0.8-0.2-0.8,3,0,2.8C28.2,265.4,28.2,262.2,27.3,262.4z"/>
		<path class="st41" d="M27,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,262.4c-0.8-0.2-0.8,3,0,2.8C26.2,265.4,26.2,262.2,25.3,262.4z"/>
		<path class="st41" d="M25.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,262.4c-0.8-0.2-0.8,3,0,2.8C24.2,265.4,24.2,262.2,23.4,262.4z"/>
		<path class="st41" d="M23.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,263.1c-0.8-0.2-0.8,3,0,2.8C25.2,266.1,25.2,262.9,24.4,263.1z"/>
		<path class="st41" d="M24.1,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,262.4c-0.8-0.2-0.8,3,0,2.8C22.2,265.4,22.2,262.2,21.4,262.4z"/>
		<path class="st41" d="M21.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,263.1c-0.8-0.2-0.8,3,0,2.8C23.3,266.1,23.3,262.9,22.4,263.1z"/>
		<path class="st41" d="M22.1,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,262.4c-0.8-0.2-0.8,3,0,2.8C20.2,265.4,20.2,262.2,19.4,262.4z"/>
		<path class="st41" d="M19.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,263.1c-0.8-0.2-0.8,3,0,2.8C21.3,266.1,21.3,262.9,20.4,263.1z"/>
		<path class="st41" d="M20.1,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,262.4c-0.8-0.2-0.8,3,0,2.8C18.2,265.4,18.2,262.2,17.4,262.4z"/>
		<path class="st41" d="M17.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,263.1c-0.8-0.2-0.8,3,0,2.8C19.3,266.1,19.3,262.9,18.4,263.1z"/>
		<path class="st41" d="M18.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,262.4c-0.8-0.2-0.8,3,0,2.8C16.2,265.4,16.2,262.2,15.4,262.4z"/>
		<path class="st41" d="M15.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,263.1c-0.8-0.2-0.8,3,0,2.8C17.3,266.1,17.3,262.9,16.5,263.1z"/>
		<path class="st41" d="M16.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,262.4c-0.8-0.2-0.8,3,0,2.8C14.3,265.4,14.3,262.2,13.4,262.4z"/>
		<path class="st41" d="M13.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,263.1c-0.8-0.2-0.8,3,0,2.8C15.3,266.1,15.3,262.9,14.5,263.1z"/>
		<path class="st41" d="M14.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,262.4c-0.8-0.2-0.8,3,0,2.8C12.3,265.4,12.3,262.2,11.4,262.4z"/>
		<path class="st41" d="M11.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,263.1c-0.8-0.2-0.8,3,0,2.8C13.3,266.1,13.3,262.9,12.5,263.1z"/>
		<path class="st41" d="M12.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,262.4c-0.8-0.2-0.8,3,0,2.8C10.3,265.4,10.3,262.2,9.5,262.4z"/>
		<path class="st41" d="M9.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,263.1c-0.8-0.2-0.8,3,0,2.8C11.3,266.1,11.3,262.9,10.5,263.1z"/>
		<path class="st41" d="M10.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,262.4c-0.8-0.2-0.8,3,0,2.8C8.3,265.4,8.3,262.2,7.5,262.4z"/>
		<path class="st41" d="M7.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,263.1c-0.8-0.2-0.8,3,0,2.8C9.3,266.1,9.3,262.9,8.5,263.1z"/>
		<path class="st41" d="M8.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,262.4c-0.8-0.2-0.8,3,0,2.8C6.3,265.4,6.3,262.2,5.5,262.4z"/>
		<path class="st41" d="M5.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,263.1c-0.8-0.2-0.8,3,0,2.8C7.4,266.1,7.4,262.9,6.5,263.1z"/>
		<path class="st41" d="M6.2,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,262.4c-0.8-0.2-0.8,3,0,2.8C4.3,265.4,4.3,262.2,3.5,262.4z"/>
		<path class="st41" d="M3.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,263.1c-0.8-0.2-0.8,3,0,2.8C5.4,266.1,5.4,262.9,4.5,263.1z"/>
		<path class="st41" d="M4.3,263.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M59,275c-0.8-0.2-0.8,3,0,2.8C59.8,278,59.8,274.8,59,275z"/>
		<path class="st41" d="M58.7,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,275c-0.8-0.2-0.8,3,0,2.8C57.9,278,57.9,274.8,57,275z"/>
		<path class="st41" d="M56.7,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,275c-0.8-0.2-0.8,3,0,2.8C55.9,278,55.9,274.8,55,275z"/>
		<path class="st41" d="M54.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,275c-0.8-0.2-0.8,3,0,2.8C53.9,278,53.9,274.8,53.1,275z"/>
		<path class="st41" d="M52.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,275c-0.8-0.2-0.8,3,0,2.8C51.9,278,51.9,274.8,51.1,275z"/>
		<path class="st41" d="M50.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,275c-0.8-0.2-0.8,3,0,2.8C49.9,278,49.9,274.8,49.1,275z"/>
		<path class="st41" d="M48.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,275c-0.8-0.2-0.8,3,0,2.8C47.9,278,47.9,274.8,47.1,275z"/>
		<path class="st41" d="M46.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,275c-0.8-0.2-0.8,3,0,2.8C45.9,278,45.9,274.8,45.1,275z"/>
		<path class="st41" d="M44.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,275c-0.8-0.2-0.8,3,0,2.8C43.9,278,43.9,274.8,43.1,275z"/>
		<path class="st41" d="M42.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,275c-0.8-0.2-0.8,3,0,2.8C42,278,42,274.8,41.1,275z"/>
		<path class="st41" d="M40.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,275c-0.8-0.2-0.8,3,0,2.8C40,278,40,274.8,39.1,275z"/>
		<path class="st41" d="M38.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,275c-0.8-0.2-0.8,3,0,2.8C38,278,38,274.8,37.2,275z"/>
		<path class="st41" d="M36.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,275c-0.8-0.2-0.8,3,0,2.8C36,278,36,274.8,35.2,275z"/>
		<path class="st41" d="M34.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,275c-0.8-0.2-0.8,3,0,2.8C34.1,278,34.1,274.8,33.3,275z"/>
		<path class="st41" d="M33,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,275c-0.8-0.2-0.8,3,0,2.8C32.1,278,32.1,274.8,31.3,275z"/>
		<path class="st41" d="M31,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,275c-0.8-0.2-0.8,3,0,2.8C30.1,278,30.2,274.8,29.3,275z"/>
		<path class="st41" d="M29,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,275c-0.8-0.2-0.8,3,0,2.8C28.2,278,28.2,274.8,27.3,275z"/>
		<path class="st41" d="M27,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,275c-0.8-0.2-0.8,3,0,2.8C26.2,278,26.2,274.8,25.3,275z"/>
		<path class="st41" d="M25.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,275c-0.8-0.2-0.8,3,0,2.8C24.2,278,24.2,274.8,23.4,275z"/>
		<path class="st41" d="M23.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,275c-0.8-0.2-0.8,3,0,2.8C22.2,278,22.2,274.8,21.4,275z"/>
		<path class="st41" d="M21.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,275c-0.8-0.2-0.8,3,0,2.8C20.2,278,20.2,274.8,19.4,275z"/>
		<path class="st41" d="M19.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,275c-0.8-0.2-0.8,3,0,2.8C18.2,278,18.2,274.8,17.4,275z"/>
		<path class="st41" d="M17.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,275c-0.8-0.2-0.8,3,0,2.8C16.2,278,16.2,274.8,15.4,275z"/>
		<path class="st41" d="M15.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,275c-0.8-0.2-0.8,3,0,2.8C14.3,278,14.3,274.8,13.4,275z"/>
		<path class="st41" d="M13.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,275c-0.8-0.2-0.8,3,0,2.8C12.3,278,12.3,274.8,11.4,275z"/>
		<path class="st41" d="M11.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,275c-0.8-0.2-0.8,3,0,2.8C10.3,278,10.3,274.8,9.5,275z"/>
		<path class="st41" d="M9.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,275c-0.8-0.2-0.8,3,0,2.8C8.3,278,8.3,274.8,7.5,275z"/>
		<path class="st41" d="M7.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,275c-0.8-0.2-0.8,3,0,2.8C6.3,278,6.3,274.8,5.5,275z"/>
		<path class="st41" d="M5.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,275c-0.8-0.2-0.8,3,0,2.8C4.3,278,4.3,274.8,3.5,275z"/>
		<path class="st41" d="M3.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M5.5,286.9c-0.8-0.2-0.8,3,0,2.8C6.3,290,6.3,286.8,5.5,286.9z"/>
		<path class="st41" d="M5.2,287.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,286.9c-0.8-0.2-0.8,3,0,2.8C4.3,290,4.3,286.8,3.5,286.9z"/>
		<path class="st41" d="M3.2,287.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M65,248.9c-0.8-0.2-0.8,3,0,2.8C65.8,251.9,65.8,248.8,65,248.9z"/>
		<path class="st41" d="M64.7,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,248.9c-0.8-0.2-0.8,3,0,2.8C63.8,251.9,63.8,248.8,63,248.9z"/>
		<path class="st41" d="M62.7,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,248.9c-0.8-0.2-0.8,3,0,2.8C61.8,251.9,61.8,248.8,61,248.9z"/>
		<path class="st41" d="M60.7,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,248.9c-0.8-0.2-0.8,3,0,2.8C59.8,251.9,59.8,248.8,59,248.9z"/>
		<path class="st41" d="M58.7,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,248.9c-0.8-0.2-0.8,3,0,2.8C57.9,251.9,57.9,248.8,57,248.9z"/>
		<path class="st41" d="M56.7,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,248.9c-0.8-0.2-0.8,3,0,2.8C55.9,251.9,55.9,248.8,55,248.9z"/>
		<path class="st41" d="M54.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,248.9c-0.8-0.2-0.8,3,0,2.8C53.9,251.9,53.9,248.8,53.1,248.9z"/>
		<path class="st41" d="M52.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,248.9c-0.8-0.2-0.8,3,0,2.8C51.9,251.9,51.9,248.8,51.1,248.9z"/>
		<path class="st41" d="M50.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,248.9c-0.8-0.2-0.8,3,0,2.8C49.9,251.9,49.9,248.8,49.1,248.9z"/>
		<path class="st41" d="M48.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,248.9c-0.8-0.2-0.8,3,0,2.8C47.9,251.9,47.9,248.8,47.1,248.9z"/>
		<path class="st41" d="M46.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,248.9c-0.8-0.2-0.8,3,0,2.8C45.9,251.9,45.9,248.8,45.1,248.9z"/>
		<path class="st41" d="M44.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,248.9c-0.8-0.2-0.8,3,0,2.8C43.9,251.9,43.9,248.8,43.1,248.9z"/>
		<path class="st41" d="M42.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,249.6c-0.8-0.2-0.8,3,0,2.8C45,252.6,45,249.4,44.2,249.6z"/>
		<path class="st41" d="M43.9,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,248.9c-0.8-0.2-0.8,3,0,2.8C42,251.9,42,248.8,41.1,248.9z"/>
		<path class="st41" d="M40.8,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,249.6c-0.8-0.2-0.8,3,0,2.8C43,252.6,43,249.4,42.2,249.6z"/>
		<path class="st41" d="M41.9,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,248.9c-0.8-0.2-0.8,3,0,2.8C40,251.9,40,248.8,39.1,248.9z"/>
		<path class="st41" d="M38.9,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,249.6c-0.8-0.2-0.8,3,0,2.8C41,252.6,41,249.4,40.2,249.6z"/>
		<path class="st41" d="M39.9,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,248.9c-0.8-0.2-0.8,3,0,2.8C38,251.9,38,248.8,37.2,248.9z"/>
		<path class="st41" d="M36.9,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,249.6c-0.8-0.2-0.8,3,0,2.8C39,252.6,39,249.4,38.2,249.6z"/>
		<path class="st41" d="M37.9,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,248.9c-0.8-0.2-0.8,3,0,2.8C36,251.9,36,248.8,35.2,248.9z"/>
		<path class="st41" d="M34.9,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,249.6c-0.8-0.2-0.8,3,0,2.8C37,252.6,37,249.4,36.2,249.6z"/>
		<path class="st41" d="M35.9,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,248.9c-0.8-0.2-0.8,3,0,2.8C34.1,251.9,34.1,248.8,33.3,248.9z"/>
		<path class="st41" d="M33,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,249.6c-0.8-0.2-0.8,3,0,2.8C35.2,252.6,35.2,249.4,34.3,249.6z"/>
		<path class="st41" d="M34.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,248.9c-0.8-0.2-0.8,3,0,2.8C32.1,251.9,32.1,248.8,31.3,248.9z"/>
		<path class="st41" d="M31,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,249.6c-0.8-0.2-0.8,3,0,2.8C33.2,252.6,33.2,249.4,32.4,249.6z"/>
		<path class="st41" d="M32.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,248.9c-0.8-0.2-0.8,3,0,2.8C30.1,251.9,30.2,248.8,29.3,248.9z"/>
		<path class="st41" d="M29,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,249.6c-0.8-0.2-0.8,3,0,2.8C31.2,252.6,31.2,249.4,30.4,249.6z"/>
		<path class="st41" d="M30.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,248.9c-0.8-0.2-0.8,3,0,2.8C28.2,251.9,28.2,248.8,27.3,248.9z"/>
		<path class="st41" d="M27,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,249.6c-0.8-0.2-0.8,3,0,2.8C29.2,252.6,29.2,249.4,28.4,249.6z"/>
		<path class="st41" d="M28.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,248.9c-0.8-0.2-0.8,3,0,2.8C26.2,251.9,26.2,248.8,25.3,248.9z"/>
		<path class="st41" d="M25.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,249.6c-0.8-0.2-0.8,3,0,2.8C27.2,252.6,27.2,249.4,26.4,249.6z"/>
		<path class="st41" d="M26.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,248.9c-0.8-0.2-0.8,3,0,2.8C24.2,251.9,24.2,248.8,23.4,248.9z"/>
		<path class="st41" d="M23.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,249.6c-0.8-0.2-0.8,3,0,2.8C25.2,252.6,25.2,249.4,24.4,249.6z"/>
		<path class="st41" d="M24.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,248.9c-0.8-0.2-0.8,3,0,2.8C22.2,251.9,22.2,248.8,21.4,248.9z"/>
		<path class="st41" d="M21.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,249.6c-0.8-0.2-0.8,3,0,2.8C23.3,252.6,23.3,249.4,22.4,249.6z"/>
		<path class="st41" d="M22.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,248.9c-0.8-0.2-0.8,3,0,2.8C20.2,251.9,20.2,248.8,19.4,248.9z"/>
		<path class="st41" d="M19.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,249.6c-0.8-0.2-0.8,3,0,2.8C21.3,252.6,21.3,249.4,20.4,249.6z"/>
		<path class="st41" d="M20.1,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,248.9c-0.8-0.2-0.8,3,0,2.8C18.2,251.9,18.2,248.8,17.4,248.9z"/>
		<path class="st41" d="M17.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,249.6c-0.8-0.2-0.8,3,0,2.8C19.3,252.6,19.3,249.4,18.4,249.6z"/>
		<path class="st41" d="M18.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,248.9c-0.8-0.2-0.8,3,0,2.8C16.2,251.9,16.2,248.8,15.4,248.9z"/>
		<path class="st41" d="M15.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,249.6c-0.8-0.2-0.8,3,0,2.8C17.3,252.6,17.3,249.4,16.5,249.6z"/>
		<path class="st41" d="M16.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,248.9c-0.8-0.2-0.8,3,0,2.8C14.3,251.9,14.3,248.8,13.4,248.9z"/>
		<path class="st41" d="M13.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,249.6c-0.8-0.2-0.8,3,0,2.8C15.3,252.6,15.3,249.4,14.5,249.6z"/>
		<path class="st41" d="M14.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,248.9c-0.8-0.2-0.8,3,0,2.8C12.3,251.9,12.3,248.8,11.4,248.9z"/>
		<path class="st41" d="M11.1,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,249.6c-0.8-0.2-0.8,3,0,2.8C13.3,252.6,13.3,249.4,12.5,249.6z"/>
		<path class="st41" d="M12.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,248.9c-0.8-0.2-0.8,3,0,2.8C10.3,251.9,10.3,248.8,9.5,248.9z"/>
		<path class="st41" d="M9.2,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,249.6c-0.8-0.2-0.8,3,0,2.8C11.3,252.6,11.3,249.4,10.5,249.6z"/>
		<path class="st41" d="M10.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,248.9c-0.8-0.2-0.8,3,0,2.8C8.3,251.9,8.3,248.8,7.5,248.9z"/>
		<path class="st41" d="M7.2,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,249.6c-0.8-0.2-0.8,3,0,2.8C9.3,252.6,9.3,249.4,8.5,249.6z"/>
		<path class="st41" d="M8.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,248.9c-0.8-0.2-0.8,3,0,2.8C6.3,251.9,6.3,248.8,5.5,248.9z"/>
		<path class="st41" d="M5.2,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,249.6c-0.8-0.2-0.8,3,0,2.8C7.4,252.6,7.4,249.4,6.5,249.6z"/>
		<path class="st41" d="M6.2,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,248.9c-0.8-0.2-0.8,3,0,2.8C4.3,251.9,4.3,248.8,3.5,248.9z"/>
		<path class="st41" d="M3.2,249.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,249.6c-0.8-0.2-0.8,3,0,2.8C5.4,252.6,5.4,249.4,4.5,249.6z"/>
		<path class="st41" d="M4.3,250.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M65,221c-0.8-0.2-0.8,3,0,2.8C65.8,224,65.8,220.8,65,221z"/>
		<path class="st41" d="M64.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,221.7c-0.8-0.2-0.8,3,0,2.8C66.9,224.7,66.9,221.5,66,221.7z"/>
		<path class="st41" d="M65.7,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,221c-0.8-0.2-0.8,3,0,2.8C63.8,224,63.8,220.8,63,221z"/>
		<path class="st41" d="M62.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,221.7c-0.8-0.2-0.8,3,0,2.8C64.9,224.7,64.9,221.5,64,221.7z"/>
		<path class="st41" d="M63.7,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,221c-0.8-0.2-0.8,3,0,2.8C61.8,224,61.8,220.8,61,221z"/>
		<path class="st41" d="M60.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,221.7c-0.8-0.2-0.8,3,0,2.8C62.9,224.7,62.9,221.5,62,221.7z"/>
		<path class="st41" d="M61.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,221c-0.8-0.2-0.8,3,0,2.8C59.8,224,59.8,220.8,59,221z"/>
		<path class="st41" d="M58.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,221.7c-0.8-0.2-0.8,3,0,2.8C60.9,224.7,60.9,221.5,60.1,221.7z"/>
		<path class="st41" d="M59.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,221c-0.8-0.2-0.8,3,0,2.8C57.9,224,57.9,220.8,57,221z"/>
		<path class="st41" d="M56.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,221.7c-0.8-0.2-0.8,3,0,2.8C58.9,224.7,58.9,221.5,58.1,221.7z"/>
		<path class="st41" d="M57.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,221c-0.8-0.2-0.8,3,0,2.8C55.9,224,55.9,220.8,55,221z"/>
		<path class="st41" d="M54.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,221.7c-0.8-0.2-0.8,3,0,2.8C56.9,224.7,56.9,221.5,56.1,221.7z"/>
		<path class="st41" d="M55.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,221c-0.8-0.2-0.8,3,0,2.8C53.9,224,53.9,220.8,53.1,221z"/>
		<path class="st41" d="M52.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,221.7c-0.8-0.2-0.8,3,0,2.8C54.9,224.7,54.9,221.5,54.1,221.7z"/>
		<path class="st41" d="M53.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,221c-0.8-0.2-0.8,3,0,2.8C51.9,224,51.9,220.8,51.1,221z"/>
		<path class="st41" d="M50.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,221.7c-0.8-0.2-0.8,3,0,2.8C52.9,224.7,52.9,221.5,52.1,221.7z"/>
		<path class="st41" d="M51.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,221c-0.8-0.2-0.8,3,0,2.8C49.9,224,49.9,220.8,49.1,221z"/>
		<path class="st41" d="M48.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,221.7c-0.8-0.2-0.8,3,0,2.8C51,224.7,51,221.5,50.1,221.7z"/>
		<path class="st41" d="M49.8,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,221c-0.8-0.2-0.8,3,0,2.8C47.9,224,47.9,220.8,47.1,221z"/>
		<path class="st41" d="M46.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,221.7c-0.8-0.2-0.8,3,0,2.8C49,224.7,49,221.5,48.1,221.7z"/>
		<path class="st41" d="M47.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,221c-0.8-0.2-0.8,3,0,2.8C45.9,224,45.9,220.8,45.1,221z"/>
		<path class="st41" d="M44.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,221.7c-0.8-0.2-0.8,3,0,2.8C47,224.7,47,221.5,46.2,221.7z"/>
		<path class="st41" d="M45.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,221c-0.8-0.2-0.8,3,0,2.8C43.9,224,43.9,220.8,43.1,221z"/>
		<path class="st41" d="M42.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,221.7c-0.8-0.2-0.8,3,0,2.8C45,224.7,45,221.5,44.2,221.7z"/>
		<path class="st41" d="M43.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,221c-0.8-0.2-0.8,3,0,2.8C42,224,42,220.8,41.1,221z"/>
		<path class="st41" d="M40.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,221.7c-0.8-0.2-0.8,3,0,2.8C43,224.7,43,221.5,42.2,221.7z"/>
		<path class="st41" d="M41.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,221c-0.8-0.2-0.8,3,0,2.8C40,224,40,220.8,39.1,221z"/>
		<path class="st41" d="M38.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,221.7c-0.8-0.2-0.8,3,0,2.8C41,224.7,41,221.5,40.2,221.7z"/>
		<path class="st41" d="M39.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,221c-0.8-0.2-0.8,3,0,2.8C38,224,38,220.8,37.2,221z"/>
		<path class="st41" d="M36.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,221.7c-0.8-0.2-0.8,3,0,2.8C39,224.7,39,221.5,38.2,221.7z"/>
		<path class="st41" d="M37.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,221c-0.8-0.2-0.8,3,0,2.8C36,224,36,220.8,35.2,221z"/>
		<path class="st41" d="M34.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,221.7c-0.8-0.2-0.8,3,0,2.8C37,224.7,37,221.5,36.2,221.7z"/>
		<path class="st41" d="M35.9,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,221c-0.8-0.2-0.8,3,0,2.8C34.1,224,34.1,220.8,33.3,221z"/>
		<path class="st41" d="M33,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,221.7c-0.8-0.2-0.8,3,0,2.8C35.2,224.7,35.2,221.5,34.3,221.7z"/>
		<path class="st41" d="M34.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,221c-0.8-0.2-0.8,3,0,2.8C32.1,224,32.1,220.8,31.3,221z"/>
		<path class="st41" d="M31,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,221.7c-0.8-0.2-0.8,3,0,2.8C33.2,224.7,33.2,221.5,32.4,221.7z"/>
		<path class="st41" d="M32.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,221c-0.8-0.2-0.8,3,0,2.8C30.1,224,30.2,220.8,29.3,221z"/>
		<path class="st41" d="M29,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,221.7c-0.8-0.2-0.8,3,0,2.8C31.2,224.7,31.2,221.5,30.4,221.7z"/>
		<path class="st41" d="M30.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,221c-0.8-0.2-0.8,3,0,2.8C28.2,224,28.2,220.8,27.3,221z"/>
		<path class="st41" d="M27,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,221.7c-0.8-0.2-0.8,3,0,2.8C29.2,224.7,29.2,221.5,28.4,221.7z"/>
		<path class="st41" d="M28.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,221c-0.8-0.2-0.8,3,0,2.8C26.2,224,26.2,220.8,25.3,221z"/>
		<path class="st41" d="M25.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,221.7c-0.8-0.2-0.8,3,0,2.8C27.2,224.7,27.2,221.5,26.4,221.7z"/>
		<path class="st41" d="M26.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,221c-0.8-0.2-0.8,3,0,2.8C24.2,224,24.2,220.8,23.4,221z"/>
		<path class="st41" d="M23.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,221.7c-0.8-0.2-0.8,3,0,2.8C25.2,224.7,25.2,221.5,24.4,221.7z"/>
		<path class="st41" d="M24.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,221c-0.8-0.2-0.8,3,0,2.8C22.2,224,22.2,220.8,21.4,221z"/>
		<path class="st41" d="M21.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,221.7c-0.8-0.2-0.8,3,0,2.8C23.3,224.7,23.3,221.5,22.4,221.7z"/>
		<path class="st41" d="M22.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,221c-0.8-0.2-0.8,3,0,2.8C20.2,224,20.2,220.8,19.4,221z"/>
		<path class="st41" d="M19.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,221.7c-0.8-0.2-0.8,3,0,2.8C21.3,224.7,21.3,221.5,20.4,221.7z"/>
		<path class="st41" d="M20.1,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,221c-0.8-0.2-0.8,3,0,2.8C18.2,224,18.2,220.8,17.4,221z"/>
		<path class="st41" d="M17.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,221.7c-0.8-0.2-0.8,3,0,2.8C19.3,224.7,19.3,221.5,18.4,221.7z"/>
		<path class="st41" d="M18.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,221c-0.8-0.2-0.8,3,0,2.8C16.2,224,16.2,220.8,15.4,221z"/>
		<path class="st41" d="M15.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,221.7c-0.8-0.2-0.8,3,0,2.8C17.3,224.7,17.3,221.5,16.5,221.7z"/>
		<path class="st41" d="M16.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,221c-0.8-0.2-0.8,3,0,2.8C14.3,224,14.3,220.8,13.4,221z"/>
		<path class="st41" d="M13.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,221.7c-0.8-0.2-0.8,3,0,2.8C15.3,224.7,15.3,221.5,14.5,221.7z"/>
		<path class="st41" d="M14.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,221c-0.8-0.2-0.8,3,0,2.8C12.3,224,12.3,220.8,11.4,221z"/>
		<path class="st41" d="M11.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,221.7c-0.8-0.2-0.8,3,0,2.8C13.3,224.7,13.3,221.5,12.5,221.7z"/>
		<path class="st41" d="M12.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,221c-0.8-0.2-0.8,3,0,2.8C10.3,224,10.3,220.8,9.5,221z"/>
		<path class="st41" d="M9.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,221.7c-0.8-0.2-0.8,3,0,2.8C11.3,224.7,11.3,221.5,10.5,221.7z"/>
		<path class="st41" d="M10.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,221c-0.8-0.2-0.8,3,0,2.8C8.3,224,8.3,220.8,7.5,221z"/>
		<path class="st41" d="M7.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,221.7c-0.8-0.2-0.8,3,0,2.8C9.3,224.7,9.3,221.5,8.5,221.7z"/>
		<path class="st41" d="M8.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,221c-0.8-0.2-0.8,3,0,2.8C6.3,224,6.3,220.8,5.5,221z"/>
		<path class="st41" d="M5.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,221.7c-0.8-0.2-0.8,3,0,2.8C7.4,224.7,7.4,221.5,6.5,221.7z"/>
		<path class="st41" d="M6.2,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,221c-0.8-0.2-0.8,3,0,2.8C4.3,224,4.3,220.8,3.5,221z"/>
		<path class="st41" d="M3.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,221.7c-0.8-0.2-0.8,3,0,2.8C5.4,224.7,5.4,221.5,4.5,221.7z"/>
		<path class="st41" d="M4.3,222.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,222.4c-0.8-0.2-0.8,3,0,2.8C65.8,225.4,65.8,222.3,65,222.4z"/>
		<path class="st41" d="M64.7,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,223.1c-0.8-0.2-0.8,3,0,2.8C66.9,226.1,66.9,222.9,66,223.1z"/>
		<path class="st41" d="M65.7,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,222.4c-0.8-0.2-0.8,3,0,2.8C63.8,225.4,63.8,222.3,63,222.4z"/>
		<path class="st41" d="M62.7,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,223.1c-0.8-0.2-0.8,3,0,2.8C64.9,226.1,64.9,222.9,64,223.1z"/>
		<path class="st41" d="M63.7,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,222.4c-0.8-0.2-0.8,3,0,2.8C61.8,225.4,61.8,222.3,61,222.4z"/>
		<path class="st41" d="M60.7,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,223.1c-0.8-0.2-0.8,3,0,2.8C62.9,226.1,62.9,222.9,62,223.1z"/>
		<path class="st41" d="M61.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,222.4c-0.8-0.2-0.8,3,0,2.8C59.8,225.4,59.8,222.3,59,222.4z"/>
		<path class="st41" d="M58.7,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,223.1c-0.8-0.2-0.8,3,0,2.8C60.9,226.1,60.9,222.9,60.1,223.1z"/>
		<path class="st41" d="M59.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,222.4c-0.8-0.2-0.8,3,0,2.8C57.9,225.4,57.9,222.3,57,222.4z"/>
		<path class="st41" d="M56.7,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,223.1c-0.8-0.2-0.8,3,0,2.8C58.9,226.1,58.9,222.9,58.1,223.1z"/>
		<path class="st41" d="M57.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,222.4c-0.8-0.2-0.8,3,0,2.8C55.9,225.4,55.9,222.3,55,222.4z"/>
		<path class="st41" d="M54.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,223.1c-0.8-0.2-0.8,3,0,2.8C56.9,226.1,56.9,222.9,56.1,223.1z"/>
		<path class="st41" d="M55.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,222.4c-0.8-0.2-0.8,3,0,2.8C53.9,225.4,53.9,222.3,53.1,222.4z"/>
		<path class="st41" d="M52.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,223.1c-0.8-0.2-0.8,3,0,2.8C54.9,226.1,54.9,222.9,54.1,223.1z"/>
		<path class="st41" d="M53.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,222.4c-0.8-0.2-0.8,3,0,2.8C51.9,225.4,51.9,222.3,51.1,222.4z"/>
		<path class="st41" d="M50.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,223.1c-0.8-0.2-0.8,3,0,2.8C52.9,226.1,52.9,222.9,52.1,223.1z"/>
		<path class="st41" d="M51.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,222.4c-0.8-0.2-0.8,3,0,2.8C49.9,225.4,49.9,222.3,49.1,222.4z"/>
		<path class="st41" d="M48.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,223.1c-0.8-0.2-0.8,3,0,2.8C51,226.1,51,222.9,50.1,223.1z"/>
		<path class="st41" d="M49.8,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,222.4c-0.8-0.2-0.8,3,0,2.8C47.9,225.4,47.9,222.3,47.1,222.4z"/>
		<path class="st41" d="M46.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,223.1c-0.8-0.2-0.8,3,0,2.8C49,226.1,49,222.9,48.1,223.1z"/>
		<path class="st41" d="M47.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,222.4c-0.8-0.2-0.8,3,0,2.8C45.9,225.4,45.9,222.3,45.1,222.4z"/>
		<path class="st41" d="M44.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,223.1c-0.8-0.2-0.8,3,0,2.8C47,226.1,47,222.9,46.2,223.1z"/>
		<path class="st41" d="M45.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,222.4c-0.8-0.2-0.8,3,0,2.8C43.9,225.4,43.9,222.3,43.1,222.4z"/>
		<path class="st41" d="M42.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,223.1c-0.8-0.2-0.8,3,0,2.8C45,226.1,45,222.9,44.2,223.1z"/>
		<path class="st41" d="M43.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,222.4c-0.8-0.2-0.8,3,0,2.8C42,225.4,42,222.3,41.1,222.4z"/>
		<path class="st41" d="M40.8,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,223.1c-0.8-0.2-0.8,3,0,2.8C43,226.1,43,222.9,42.2,223.1z"/>
		<path class="st41" d="M41.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,222.4c-0.8-0.2-0.8,3,0,2.8C40,225.4,40,222.3,39.1,222.4z"/>
		<path class="st41" d="M38.9,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,223.1c-0.8-0.2-0.8,3,0,2.8C41,226.1,41,222.9,40.2,223.1z"/>
		<path class="st41" d="M39.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,222.4c-0.8-0.2-0.8,3,0,2.8C38,225.4,38,222.3,37.2,222.4z"/>
		<path class="st41" d="M36.9,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,223.1c-0.8-0.2-0.8,3,0,2.8C39,226.1,39,222.9,38.2,223.1z"/>
		<path class="st41" d="M37.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,222.4c-0.8-0.2-0.8,3,0,2.8C36,225.4,36,222.3,35.2,222.4z"/>
		<path class="st41" d="M34.9,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,223.1c-0.8-0.2-0.8,3,0,2.8C37,226.1,37,222.9,36.2,223.1z"/>
		<path class="st41" d="M35.9,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,222.4c-0.8-0.2-0.8,3,0,2.8C34.1,225.4,34.1,222.3,33.3,222.4z"/>
		<path class="st41" d="M33,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,223.1c-0.8-0.2-0.8,3,0,2.8C35.2,226.1,35.2,222.9,34.3,223.1z"/>
		<path class="st41" d="M34.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,222.4c-0.8-0.2-0.8,3,0,2.8C32.1,225.4,32.1,222.3,31.3,222.4z"/>
		<path class="st41" d="M31,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,223.1c-0.8-0.2-0.8,3,0,2.8C33.2,226.1,33.2,222.9,32.4,223.1z"/>
		<path class="st41" d="M32.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,222.4c-0.8-0.2-0.8,3,0,2.8C30.1,225.4,30.2,222.3,29.3,222.4z"/>
		<path class="st41" d="M29,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,223.1c-0.8-0.2-0.8,3,0,2.8C31.2,226.1,31.2,222.9,30.4,223.1z"/>
		<path class="st41" d="M30.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,222.4c-0.8-0.2-0.8,3,0,2.8C28.2,225.4,28.2,222.3,27.3,222.4z"/>
		<path class="st41" d="M27,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,223.1c-0.8-0.2-0.8,3,0,2.8C29.2,226.1,29.2,222.9,28.4,223.1z"/>
		<path class="st41" d="M28.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,222.4c-0.8-0.2-0.8,3,0,2.8C26.2,225.4,26.2,222.3,25.3,222.4z"/>
		<path class="st41" d="M25.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,223.1c-0.8-0.2-0.8,3,0,2.8C27.2,226.1,27.2,222.9,26.4,223.1z"/>
		<path class="st41" d="M26.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,222.4c-0.8-0.2-0.8,3,0,2.8C24.2,225.4,24.2,222.3,23.4,222.4z"/>
		<path class="st41" d="M23.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,223.1c-0.8-0.2-0.8,3,0,2.8C25.2,226.1,25.2,222.9,24.4,223.1z"/>
		<path class="st41" d="M24.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,222.4c-0.8-0.2-0.8,3,0,2.8C22.2,225.4,22.2,222.3,21.4,222.4z"/>
		<path class="st41" d="M21.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,223.1c-0.8-0.2-0.8,3,0,2.8C23.3,226.1,23.3,222.9,22.4,223.1z"/>
		<path class="st41" d="M22.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,222.4c-0.8-0.2-0.8,3,0,2.8C20.2,225.4,20.2,222.3,19.4,222.4z"/>
		<path class="st41" d="M19.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,223.1c-0.8-0.2-0.8,3,0,2.8C21.3,226.1,21.3,222.9,20.4,223.1z"/>
		<path class="st41" d="M20.1,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,222.4c-0.8-0.2-0.8,3,0,2.8C18.2,225.4,18.2,222.3,17.4,222.4z"/>
		<path class="st41" d="M17.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,223.1c-0.8-0.2-0.8,3,0,2.8C19.3,226.1,19.3,222.9,18.4,223.1z"/>
		<path class="st41" d="M18.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,222.4c-0.8-0.2-0.8,3,0,2.8C16.2,225.4,16.2,222.3,15.4,222.4z"/>
		<path class="st41" d="M15.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,223.1c-0.8-0.2-0.8,3,0,2.8C17.3,226.1,17.3,222.9,16.5,223.1z"/>
		<path class="st41" d="M16.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,222.4c-0.8-0.2-0.8,3,0,2.8C14.3,225.4,14.3,222.3,13.4,222.4z"/>
		<path class="st41" d="M13.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,223.1c-0.8-0.2-0.8,3,0,2.8C15.3,226.1,15.3,222.9,14.5,223.1z"/>
		<path class="st41" d="M14.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,222.4c-0.8-0.2-0.8,3,0,2.8C12.3,225.4,12.3,222.3,11.4,222.4z"/>
		<path class="st41" d="M11.1,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,223.1c-0.8-0.2-0.8,3,0,2.8C13.3,226.1,13.3,222.9,12.5,223.1z"/>
		<path class="st41" d="M12.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,222.4c-0.8-0.2-0.8,3,0,2.8C10.3,225.4,10.3,222.3,9.5,222.4z"/>
		<path class="st41" d="M9.2,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,223.1c-0.8-0.2-0.8,3,0,2.8C11.3,226.1,11.3,222.9,10.5,223.1z"/>
		<path class="st41" d="M10.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,222.4c-0.8-0.2-0.8,3,0,2.8C8.3,225.4,8.3,222.3,7.5,222.4z"/>
		<path class="st41" d="M7.2,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,223.1c-0.8-0.2-0.8,3,0,2.8C9.3,226.1,9.3,222.9,8.5,223.1z"/>
		<path class="st41" d="M8.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,222.4c-0.8-0.2-0.8,3,0,2.8C6.3,225.4,6.3,222.3,5.5,222.4z"/>
		<path class="st41" d="M5.2,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,223.1c-0.8-0.2-0.8,3,0,2.8C7.4,226.1,7.4,222.9,6.5,223.1z"/>
		<path class="st41" d="M6.2,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,222.4c-0.8-0.2-0.8,3,0,2.8C4.3,225.4,4.3,222.3,3.5,222.4z"/>
		<path class="st41" d="M3.2,223.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,223.1c-0.8-0.2-0.8,3,0,2.8C5.4,226.1,5.4,222.9,4.5,223.1z"/>
		<path class="st41" d="M4.3,224c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,223.8c-0.8-0.2-0.8,3,0,2.8C65.8,226.8,65.8,223.6,65,223.8z"/>
		<path class="st41" d="M64.7,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,223.8c-0.8-0.2-0.8,3,0,2.8C63.8,226.8,63.8,223.6,63,223.8z"/>
		<path class="st41" d="M62.7,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,223.8c-0.8-0.2-0.8,3,0,2.8C61.8,226.8,61.8,223.6,61,223.8z"/>
		<path class="st41" d="M60.7,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,223.8c-0.8-0.2-0.8,3,0,2.8C59.8,226.8,59.8,223.6,59,223.8z"/>
		<path class="st41" d="M58.7,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,223.8c-0.8-0.2-0.8,3,0,2.8C57.9,226.8,57.9,223.6,57,223.8z"/>
		<path class="st41" d="M56.7,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,223.8c-0.8-0.2-0.8,3,0,2.8C55.9,226.8,55.9,223.6,55,223.8z"/>
		<path class="st41" d="M54.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,223.8c-0.8-0.2-0.8,3,0,2.8C53.9,226.8,53.9,223.6,53.1,223.8z"/>
		<path class="st41" d="M52.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,223.8c-0.8-0.2-0.8,3,0,2.8C51.9,226.8,51.9,223.6,51.1,223.8z"/>
		<path class="st41" d="M50.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,223.8c-0.8-0.2-0.8,3,0,2.8C49.9,226.8,49.9,223.6,49.1,223.8z"/>
		<path class="st41" d="M48.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,223.8c-0.8-0.2-0.8,3,0,2.8C47.9,226.8,47.9,223.6,47.1,223.8z"/>
		<path class="st41" d="M46.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,224.5c-0.8-0.2-0.8,3,0,2.8C49,227.5,49,224.3,48.1,224.5z"/>
		<path class="st41" d="M47.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,223.8c-0.8-0.2-0.8,3,0,2.8C45.9,226.8,45.9,223.6,45.1,223.8z"/>
		<path class="st41" d="M44.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,224.5c-0.8-0.2-0.8,3,0,2.8C47,227.5,47,224.3,46.2,224.5z"/>
		<path class="st41" d="M45.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,223.8c-0.8-0.2-0.8,3,0,2.8C43.9,226.8,43.9,223.6,43.1,223.8z"/>
		<path class="st41" d="M42.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,224.5c-0.8-0.2-0.8,3,0,2.8C45,227.5,45,224.3,44.2,224.5z"/>
		<path class="st41" d="M43.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,223.8c-0.8-0.2-0.8,3,0,2.8C42,226.8,42,223.6,41.1,223.8z"/>
		<path class="st41" d="M40.8,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,224.5c-0.8-0.2-0.8,3,0,2.8C43,227.5,43,224.3,42.2,224.5z"/>
		<path class="st41" d="M41.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,223.8c-0.8-0.2-0.8,3,0,2.8C40,226.8,40,223.6,39.1,223.8z"/>
		<path class="st41" d="M38.9,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,224.5c-0.8-0.2-0.8,3,0,2.8C41,227.5,41,224.3,40.2,224.5z"/>
		<path class="st41" d="M39.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,223.8c-0.8-0.2-0.8,3,0,2.8C38,226.8,38,223.6,37.2,223.8z"/>
		<path class="st41" d="M36.9,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,224.5c-0.8-0.2-0.8,3,0,2.8C39,227.5,39,224.3,38.2,224.5z"/>
		<path class="st41" d="M37.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,223.8c-0.8-0.2-0.8,3,0,2.8C36,226.8,36,223.6,35.2,223.8z"/>
		<path class="st41" d="M34.9,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,224.5c-0.8-0.2-0.8,3,0,2.8C37,227.5,37,224.3,36.2,224.5z"/>
		<path class="st41" d="M35.9,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,223.8c-0.8-0.2-0.8,3,0,2.8C34.1,226.8,34.1,223.6,33.3,223.8z"/>
		<path class="st41" d="M33,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,224.5c-0.8-0.2-0.8,3,0,2.8C35.2,227.5,35.2,224.3,34.3,224.5z"/>
		<path class="st41" d="M34.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,223.8c-0.8-0.2-0.8,3,0,2.8C32.1,226.8,32.1,223.6,31.3,223.8z"/>
		<path class="st41" d="M31,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,224.5c-0.8-0.2-0.8,3,0,2.8C33.2,227.5,33.2,224.3,32.4,224.5z"/>
		<path class="st41" d="M32.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,223.8c-0.8-0.2-0.8,3,0,2.8C30.1,226.8,30.2,223.6,29.3,223.8z"/>
		<path class="st41" d="M29,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,224.5c-0.8-0.2-0.8,3,0,2.8C31.2,227.5,31.2,224.3,30.4,224.5z"/>
		<path class="st41" d="M30.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,223.8c-0.8-0.2-0.8,3,0,2.8C28.2,226.8,28.2,223.6,27.3,223.8z"/>
		<path class="st41" d="M27,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,224.5c-0.8-0.2-0.8,3,0,2.8C29.2,227.5,29.2,224.3,28.4,224.5z"/>
		<path class="st41" d="M28.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,223.8c-0.8-0.2-0.8,3,0,2.8C26.2,226.8,26.2,223.6,25.3,223.8z"/>
		<path class="st41" d="M25.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,224.5c-0.8-0.2-0.8,3,0,2.8C27.2,227.5,27.2,224.3,26.4,224.5z"/>
		<path class="st41" d="M26.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,223.8c-0.8-0.2-0.8,3,0,2.8C24.2,226.8,24.2,223.6,23.4,223.8z"/>
		<path class="st41" d="M23.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,224.5c-0.8-0.2-0.8,3,0,2.8C25.2,227.5,25.2,224.3,24.4,224.5z"/>
		<path class="st41" d="M24.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,223.8c-0.8-0.2-0.8,3,0,2.8C22.2,226.8,22.2,223.6,21.4,223.8z"/>
		<path class="st41" d="M21.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,224.5c-0.8-0.2-0.8,3,0,2.8C23.3,227.5,23.3,224.3,22.4,224.5z"/>
		<path class="st41" d="M22.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,223.8c-0.8-0.2-0.8,3,0,2.8C20.2,226.8,20.2,223.6,19.4,223.8z"/>
		<path class="st41" d="M19.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,224.5c-0.8-0.2-0.8,3,0,2.8C21.3,227.5,21.3,224.3,20.4,224.5z"/>
		<path class="st41" d="M20.1,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,223.8c-0.8-0.2-0.8,3,0,2.8C18.2,226.8,18.2,223.6,17.4,223.8z"/>
		<path class="st41" d="M17.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,224.5c-0.8-0.2-0.8,3,0,2.8C19.3,227.5,19.3,224.3,18.4,224.5z"/>
		<path class="st41" d="M18.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,223.8c-0.8-0.2-0.8,3,0,2.8C16.2,226.8,16.2,223.6,15.4,223.8z"/>
		<path class="st41" d="M15.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,224.5c-0.8-0.2-0.8,3,0,2.8C17.3,227.5,17.3,224.3,16.5,224.5z"/>
		<path class="st41" d="M16.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,223.8c-0.8-0.2-0.8,3,0,2.8C14.3,226.8,14.3,223.6,13.4,223.8z"/>
		<path class="st41" d="M13.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,224.5c-0.8-0.2-0.8,3,0,2.8C15.3,227.5,15.3,224.3,14.5,224.5z"/>
		<path class="st41" d="M14.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,223.8c-0.8-0.2-0.8,3,0,2.8C12.3,226.8,12.3,223.6,11.4,223.8z"/>
		<path class="st41" d="M11.1,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,224.5c-0.8-0.2-0.8,3,0,2.8C13.3,227.5,13.3,224.3,12.5,224.5z"/>
		<path class="st41" d="M12.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,223.8c-0.8-0.2-0.8,3,0,2.8C10.3,226.8,10.3,223.6,9.5,223.8z"/>
		<path class="st41" d="M9.2,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,224.5c-0.8-0.2-0.8,3,0,2.8C11.3,227.5,11.3,224.3,10.5,224.5z"/>
		<path class="st41" d="M10.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,223.8c-0.8-0.2-0.8,3,0,2.8C8.3,226.8,8.3,223.6,7.5,223.8z"/>
		<path class="st41" d="M7.2,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,224.5c-0.8-0.2-0.8,3,0,2.8C9.3,227.5,9.3,224.3,8.5,224.5z"/>
		<path class="st41" d="M8.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,223.8c-0.8-0.2-0.8,3,0,2.8C6.3,226.8,6.3,223.6,5.5,223.8z"/>
		<path class="st41" d="M5.2,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,224.5c-0.8-0.2-0.8,3,0,2.8C7.4,227.5,7.4,224.3,6.5,224.5z"/>
		<path class="st41" d="M6.2,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,223.8c-0.8-0.2-0.8,3,0,2.8C4.3,226.8,4.3,223.6,3.5,223.8z"/>
		<path class="st41" d="M3.2,224.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,224.5c-0.8-0.2-0.8,3,0,2.8C5.4,227.5,5.4,224.3,4.5,224.5z"/>
		<path class="st41" d="M4.3,225.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st41" d="M4.5,100.6c-0.8-0.2-0.8,3,0,2.8C5.4,103.6,5.4,100.4,4.5,100.6z"/>
		<path class="st41" d="M4.2,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M65,101.2c-0.8-0.2-0.8,3,0,2.8C65.8,104.2,65.8,101.1,65,101.2z"/>
		<path class="st41" d="M64.7,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M63,101.2c-0.8-0.2-0.8,3,0,2.8C63.8,104.2,63.8,101.1,63,101.2z"/>
		<path class="st41" d="M62.7,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M61,101.2c-0.8-0.2-0.8,3,0,2.8C61.8,104.2,61.8,101.1,61,101.2z"/>
		<path class="st41" d="M60.7,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M59,101.2c-0.8-0.2-0.8,3,0,2.8C59.8,104.2,59.8,101.1,59,101.2z"/>
		<path class="st41" d="M58.7,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M57,101.2c-0.8-0.2-0.8,3,0,2.8C57.9,104.2,57.9,101.1,57,101.2z"/>
		<path class="st41" d="M56.7,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M55,101.2c-0.8-0.2-0.8,3,0,2.8C55.9,104.2,55.9,101.1,55,101.2z"/>
		<path class="st41" d="M54.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M53.1,101.2c-0.8-0.2-0.8,3,0,2.8C53.9,104.2,53.9,101.1,53.1,101.2z"/>
		<path class="st41" d="M52.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M51.1,101.2c-0.8-0.2-0.8,3,0,2.8C51.9,104.2,51.9,101.1,51.1,101.2z"/>
		<path class="st41" d="M50.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M49.1,101.2c-0.8-0.2-0.8,3,0,2.8C49.9,104.2,49.9,101.1,49.1,101.2z"/>
		<path class="st41" d="M48.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M47.1,101.2c-0.8-0.2-0.8,3,0,2.8C47.9,104.2,47.9,101.1,47.1,101.2z"/>
		<path class="st41" d="M46.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M45.1,101.2c-0.8-0.2-0.8,3,0,2.8C45.9,104.2,45.9,101.1,45.1,101.2z"/>
		<path class="st41" d="M44.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M43.1,101.2c-0.8-0.2-0.8,3,0,2.8C43.9,104.2,43.9,101.1,43.1,101.2z"/>
		<path class="st41" d="M42.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M41.1,101.2c-0.8-0.2-0.8,3,0,2.8C42,104.2,42,101.1,41.1,101.2z"/>
		<path class="st41" d="M40.8,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M39.1,101.2c-0.8-0.2-0.8,3,0,2.8C40,104.2,40,101.1,39.1,101.2z"/>
		<path class="st41" d="M38.9,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M37.2,101.2c-0.8-0.2-0.8,3,0,2.8C38,104.2,38,101.1,37.2,101.2z"/>
		<path class="st41" d="M36.9,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M35.2,101.2c-0.8-0.2-0.8,3,0,2.8C36,104.2,36,101.1,35.2,101.2z"/>
		<path class="st41" d="M34.9,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M33.3,101.2c-0.8-0.2-0.8,3,0,2.8C34.1,104.2,34.1,101.1,33.3,101.2z"/>
		<path class="st41" d="M33,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M31.3,101.2c-0.8-0.2-0.8,3,0,2.8C32.1,104.2,32.1,101.1,31.3,101.2z"/>
		<path class="st41" d="M31,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M29.3,101.2c-0.8-0.2-0.8,3,0,2.8C30.1,104.2,30.2,101.1,29.3,101.2z"/>
		<path class="st41" d="M29,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M27.3,101.2c-0.8-0.2-0.8,3,0,2.8C28.2,104.2,28.2,101.1,27.3,101.2z"/>
		<path class="st41" d="M27,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M25.3,101.2c-0.8-0.2-0.8,3,0,2.8C26.2,104.2,26.2,101.1,25.3,101.2z"/>
		<path class="st41" d="M25.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M23.4,101.2c-0.8-0.2-0.8,3,0,2.8C24.2,104.2,24.2,101.1,23.4,101.2z"/>
		<path class="st41" d="M23.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M21.4,101.2c-0.8-0.2-0.8,3,0,2.8C22.2,104.2,22.2,101.1,21.4,101.2z"/>
		<path class="st41" d="M21.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M19.4,101.2c-0.8-0.2-0.8,3,0,2.8C20.2,104.2,20.2,101.1,19.4,101.2z"/>
		<path class="st41" d="M19.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M17.4,101.2c-0.8-0.2-0.8,3,0,2.8C18.2,104.2,18.2,101.1,17.4,101.2z"/>
		<path class="st41" d="M17.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M15.4,101.2c-0.8-0.2-0.8,3,0,2.8C16.2,104.2,16.2,101.1,15.4,101.2z"/>
		<path class="st41" d="M15.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M13.4,101.2c-0.8-0.2-0.8,3,0,2.8C14.3,104.2,14.3,101.1,13.4,101.2z"/>
		<path class="st41" d="M13.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M11.4,101.2c-0.8-0.2-0.8,3,0,2.8C12.3,104.2,12.3,101.1,11.4,101.2z"/>
		<path class="st41" d="M11.1,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M9.5,101.2c-0.8-0.2-0.8,3,0,2.8C10.3,104.2,10.3,101.1,9.5,101.2z"/>
		<path class="st41" d="M9.2,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M7.5,101.2c-0.8-0.2-0.8,3,0,2.8C8.3,104.2,8.3,101.1,7.5,101.2z"/>
		<path class="st41" d="M7.2,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M5.5,101.2c-0.8-0.2-0.8,3,0,2.8C6.3,104.2,6.3,101.1,5.5,101.2z"/>
		<path class="st41" d="M5.2,102.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,102c-0.8-0.2-0.8,3,0,2.8C66.9,105,66.9,101.8,66,102z"/>
		<path class="st41" d="M65.7,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,102c-0.8-0.2-0.8,3,0,2.8C64.9,105,64.9,101.8,64,102z"/>
		<path class="st41" d="M63.7,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,102c-0.8-0.2-0.8,3,0,2.8C62.9,105,62.9,101.8,62,102z"/>
		<path class="st41" d="M61.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,102c-0.8-0.2-0.8,3,0,2.8C60.9,105,60.9,101.8,60.1,102z"/>
		<path class="st41" d="M59.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,102c-0.8-0.2-0.8,3,0,2.8C58.9,105,58.9,101.8,58.1,102z"/>
		<path class="st41" d="M57.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,102c-0.8-0.2-0.8,3,0,2.8C56.9,105,56.9,101.8,56.1,102z"/>
		<path class="st41" d="M55.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,102c-0.8-0.2-0.8,3,0,2.8C54.9,105,54.9,101.8,54.1,102z"/>
		<path class="st41" d="M53.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,102c-0.8-0.2-0.8,3,0,2.8C52.9,105,52.9,101.8,52.1,102z"/>
		<path class="st41" d="M51.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,102c-0.8-0.2-0.8,3,0,2.8C51,105,51,101.8,50.1,102z"/>
		<path class="st41" d="M49.8,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,102c-0.8-0.2-0.8,3,0,2.8C49,105,49,101.8,48.1,102z"/>
		<path class="st41" d="M47.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,102c-0.8-0.2-0.8,3,0,2.8C47,105,47,101.8,46.2,102z"/>
		<path class="st41" d="M45.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,102c-0.8-0.2-0.8,3,0,2.8C45,105,45,101.8,44.2,102z"/>
		<path class="st41" d="M43.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,102c-0.8-0.2-0.8,3,0,2.8C43,105,43,101.8,42.2,102z"/>
		<path class="st41" d="M41.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,102c-0.8-0.2-0.8,3,0,2.8C41,105,41,101.8,40.2,102z"/>
		<path class="st41" d="M39.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,102c-0.8-0.2-0.8,3,0,2.8C39,105,39,101.8,38.2,102z"/>
		<path class="st41" d="M37.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,102c-0.8-0.2-0.8,3,0,2.8C37,105,37,101.8,36.2,102z"/>
		<path class="st41" d="M35.9,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,102c-0.8-0.2-0.8,3,0,2.8C35.2,105,35.2,101.8,34.3,102z"/>
		<path class="st41" d="M34.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,102c-0.8-0.2-0.8,3,0,2.8C33.2,105,33.2,101.8,32.4,102z"/>
		<path class="st41" d="M32.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,102c-0.8-0.2-0.8,3,0,2.8C31.2,105,31.2,101.8,30.4,102z"/>
		<path class="st41" d="M30.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,102c-0.8-0.2-0.8,3,0,2.8C29.2,105,29.2,101.8,28.4,102z"/>
		<path class="st41" d="M28.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,102c-0.8-0.2-0.8,3,0,2.8C27.2,105,27.2,101.8,26.4,102z"/>
		<path class="st41" d="M26.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,102c-0.8-0.2-0.8,3,0,2.8C25.2,105,25.2,101.8,24.4,102z"/>
		<path class="st41" d="M24.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,102c-0.8-0.2-0.8,3,0,2.8C23.3,105,23.3,101.8,22.4,102z"/>
		<path class="st41" d="M22.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,102c-0.8-0.2-0.8,3,0,2.8C21.3,105,21.3,101.8,20.4,102z"/>
		<path class="st41" d="M20.1,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,102c-0.8-0.2-0.8,3,0,2.8C19.3,105,19.3,101.8,18.4,102z"/>
		<path class="st41" d="M18.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,102c-0.8-0.2-0.8,3,0,2.8C17.3,105,17.3,101.8,16.5,102z"/>
		<path class="st41" d="M16.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,102c-0.8-0.2-0.8,3,0,2.8C15.3,105,15.3,101.8,14.5,102z"/>
		<path class="st41" d="M14.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,102c-0.8-0.2-0.8,3,0,2.8C13.3,105,13.3,101.8,12.5,102z"/>
		<path class="st41" d="M12.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,102c-0.8-0.2-0.8,3,0,2.8C11.3,105,11.3,101.8,10.5,102z"/>
		<path class="st41" d="M10.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,102c-0.8-0.2-0.8,3,0,2.8C9.3,105,9.3,101.8,8.5,102z"/>
		<path class="st41" d="M8.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,102c-0.8-0.2-0.8,3,0,2.8C7.4,105,7.4,101.8,6.5,102z"/>
		<path class="st41" d="M6.2,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,101.3c-0.8-0.2-0.8,3,0,2.8C4.3,104.3,4.3,101.1,3.5,101.3z"/>
		<path class="st41" d="M3.2,102.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,102c-0.8-0.2-0.8,3,0,2.8C5.4,105,5.4,101.8,4.5,102z"/>
		<path class="st41" d="M4.3,102.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M65,102.7c-0.8-0.2-0.8,3,0,2.8C65.8,105.7,65.8,102.6,65,102.7z"/>
		<path class="st41" d="M64.7,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M63,102.7c-0.8-0.2-0.8,3,0,2.8C63.8,105.7,63.8,102.6,63,102.7z"/>
		<path class="st41" d="M62.7,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,102.7c-0.8-0.2-0.8,3,0,2.8C59.8,105.7,59.8,102.6,59,102.7z"/>
		<path class="st41" d="M58.7,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,102.7c-0.8-0.2-0.8,3,0,2.8C57.9,105.7,57.9,102.6,57,102.7z"/>
		<path class="st41" d="M56.7,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,102.7c-0.8-0.2-0.8,3,0,2.8C55.9,105.7,55.9,102.6,55,102.7z"/>
		<path class="st41" d="M54.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,102.7c-0.8-0.2-0.8,3,0,2.8C53.9,105.7,53.9,102.6,53.1,102.7z"/>
		<path class="st41" d="M52.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,102.7c-0.8-0.2-0.8,3,0,2.8C51.9,105.7,51.9,102.6,51.1,102.7z"/>
		<path class="st41" d="M50.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,102.7c-0.8-0.2-0.8,3,0,2.8C49.9,105.7,49.9,102.6,49.1,102.7z"/>
		<path class="st41" d="M48.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,102.7c-0.8-0.2-0.8,3,0,2.8C47.9,105.7,47.9,102.6,47.1,102.7z"/>
		<path class="st41" d="M46.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,102.7c-0.8-0.2-0.8,3,0,2.8C45.9,105.7,45.9,102.6,45.1,102.7z"/>
		<path class="st41" d="M44.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,102.7c-0.8-0.2-0.8,3,0,2.8C43.9,105.7,43.9,102.6,43.1,102.7z"/>
		<path class="st41" d="M42.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,102.7c-0.8-0.2-0.8,3,0,2.8C42,105.7,42,102.6,41.1,102.7z"/>
		<path class="st41" d="M40.8,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,102.7c-0.8-0.2-0.8,3,0,2.8C40,105.7,40,102.6,39.1,102.7z"/>
		<path class="st41" d="M38.9,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,102.7c-0.8-0.2-0.8,3,0,2.8C38,105.7,38,102.6,37.2,102.7z"/>
		<path class="st41" d="M36.9,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,102.7c-0.8-0.2-0.8,3,0,2.8C36,105.7,36,102.6,35.2,102.7z"/>
		<path class="st41" d="M34.9,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,102.7c-0.8-0.2-0.8,3,0,2.8C34.1,105.7,34.1,102.6,33.3,102.7z"/>
		<path class="st41" d="M33,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,102.7c-0.8-0.2-0.8,3,0,2.8C32.1,105.7,32.1,102.6,31.3,102.7z"/>
		<path class="st41" d="M31,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,102.7c-0.8-0.2-0.8,3,0,2.8C30.1,105.7,30.2,102.6,29.3,102.7z"/>
		<path class="st41" d="M29,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,102.7c-0.8-0.2-0.8,3,0,2.8C28.2,105.7,28.2,102.6,27.3,102.7z"/>
		<path class="st41" d="M27,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,102.7c-0.8-0.2-0.8,3,0,2.8C26.2,105.7,26.2,102.6,25.3,102.7z"/>
		<path class="st41" d="M25.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,102.7c-0.8-0.2-0.8,3,0,2.8C24.2,105.7,24.2,102.6,23.4,102.7z"/>
		<path class="st41" d="M23.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,102.7c-0.8-0.2-0.8,3,0,2.8C22.2,105.7,22.2,102.6,21.4,102.7z"/>
		<path class="st41" d="M21.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,102.7c-0.8-0.2-0.8,3,0,2.8C20.2,105.7,20.2,102.6,19.4,102.7z"/>
		<path class="st41" d="M19.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,102.7c-0.8-0.2-0.8,3,0,2.8C18.2,105.7,18.2,102.6,17.4,102.7z"/>
		<path class="st41" d="M17.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,102.7c-0.8-0.2-0.8,3,0,2.8C16.2,105.7,16.2,102.6,15.4,102.7z"/>
		<path class="st41" d="M15.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,102.7c-0.8-0.2-0.8,3,0,2.8C14.3,105.7,14.3,102.6,13.4,102.7z"/>
		<path class="st41" d="M13.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,102.7c-0.8-0.2-0.8,3,0,2.8C12.3,105.7,12.3,102.6,11.4,102.7z"/>
		<path class="st41" d="M11.1,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M9.5,102.7c-0.8-0.2-0.8,3,0,2.8C10.3,105.7,10.3,102.6,9.5,102.7z"/>
		<path class="st42" d="M9.2,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.5,102.7c-0.8-0.2-0.8,3,0,2.8C8.3,105.7,8.3,102.6,7.5,102.7z"/>
		<path class="st42" d="M7.2,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,102.7c-0.8-0.2-0.8,3,0,2.8C6.3,105.7,6.3,102.6,5.5,102.7z"/>
		<path class="st42" d="M5.2,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,102.7c-0.8-0.2-0.8,3,0,2.8C4.3,105.7,4.3,102.6,3.5,102.7z"/>
		<path class="st42" d="M3.2,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M61,102.7c-0.8-0.2-0.8,3,0,2.8C61.8,105.7,61.8,102.6,61,102.7z"/>
		<path class="st41" d="M60.7,103.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st41" d="M16.4,59.4c-0.8-0.2-0.8,3,0,2.8C17.2,62.4,17.2,59.2,16.4,59.4z"/>
		<path class="st41" d="M16.1,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M14.4,59.4c-0.8-0.2-0.8,3,0,2.8C15.2,62.4,15.2,59.2,14.4,59.4z"/>
		<path class="st41" d="M14.1,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M12.4,59.4c-0.8-0.2-0.8,3,0,2.8C13.2,62.4,13.2,59.2,12.4,59.4z"/>
		<path class="st41" d="M12.1,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M10.4,59.4c-0.8-0.2-0.8,3,0,2.8C11.3,62.4,11.3,59.2,10.4,59.4z"/>
		<path class="st41" d="M10.1,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M6.5,59.4c-0.8-0.2-0.8,3,0,2.8C7.3,62.4,7.3,59.2,6.5,59.4z"/>
		<path class="st41" d="M6.2,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M4.5,59.4c-0.8-0.2-0.8,3,0,2.8C5.3,62.4,5.3,59.2,4.5,59.4z"/>
		<path class="st41" d="M4.2,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M8.4,59.4c-0.8-0.2-0.8,3,0,2.8C9.3,62.4,9.3,59.2,8.4,59.4z"/>
		<path class="st41" d="M8.2,60.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,60c-0.8-0.2-0.8,3,0,2.8C65.8,63,65.8,59.9,65,60z"/>
		<path class="st41" d="M64.7,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,60c-0.8-0.2-0.8,3,0,2.8C63.8,63,63.8,59.9,63,60z"/>
		<path class="st41" d="M62.7,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,60c-0.8-0.2-0.8,3,0,2.8C61.8,63,61.8,59.9,61,60z"/>
		<path class="st41" d="M60.7,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,60c-0.8-0.2-0.8,3,0,2.8C59.8,63,59.8,59.9,59,60z"/>
		<path class="st41" d="M58.7,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,60c-0.8-0.2-0.8,3,0,2.8C57.9,63,57.9,59.9,57,60z"/>
		<path class="st41" d="M56.7,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,60c-0.8-0.2-0.8,3,0,2.8C55.9,63,55.9,59.9,55,60z"/>
		<path class="st41" d="M54.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,60c-0.8-0.2-0.8,3,0,2.8C53.9,63,53.9,59.9,53.1,60z"/>
		<path class="st41" d="M52.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,60c-0.8-0.2-0.8,3,0,2.8C51.9,63,51.9,59.9,51.1,60z"/>
		<path class="st41" d="M50.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,60c-0.8-0.2-0.8,3,0,2.8C49.9,63,49.9,59.9,49.1,60z"/>
		<path class="st41" d="M48.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,60c-0.8-0.2-0.8,3,0,2.8C47.9,63,47.9,59.9,47.1,60z"/>
		<path class="st41" d="M46.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,60c-0.8-0.2-0.8,3,0,2.8C45.9,63,45.9,59.9,45.1,60z"/>
		<path class="st41" d="M44.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,60c-0.8-0.2-0.8,3,0,2.8C43.9,63,43.9,59.9,43.1,60z"/>
		<path class="st41" d="M42.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,60c-0.8-0.2-0.8,3,0,2.8C42,63,42,59.9,41.1,60z"/>
		<path class="st41" d="M40.8,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,60c-0.8-0.2-0.8,3,0,2.8C40,63,40,59.9,39.1,60z"/>
		<path class="st41" d="M38.9,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,60c-0.8-0.2-0.8,3,0,2.8C38,63,38,59.9,37.2,60z"/>
		<path class="st41" d="M36.9,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,60c-0.8-0.2-0.8,3,0,2.8C36,63,36,59.9,35.2,60z"/>
		<path class="st41" d="M34.9,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,60c-0.8-0.2-0.8,3,0,2.8C34.1,63,34.1,59.9,33.3,60z"/>
		<path class="st41" d="M33,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,60c-0.8-0.2-0.8,3,0,2.8C32.1,63,32.1,59.9,31.3,60z"/>
		<path class="st41" d="M31,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,60c-0.8-0.2-0.8,3,0,2.8C30.1,63,30.2,59.9,29.3,60z"/>
		<path class="st41" d="M29,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,60c-0.8-0.2-0.8,3,0,2.8C28.2,63,28.2,59.9,27.3,60z"/>
		<path class="st41" d="M27,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,60c-0.8-0.2-0.8,3,0,2.8C26.2,63,26.2,59.9,25.3,60z"/>
		<path class="st41" d="M25.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,60c-0.8-0.2-0.8,3,0,2.8C24.2,63,24.2,59.9,23.4,60z"/>
		<path class="st41" d="M23.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,60c-0.8-0.2-0.8,3,0,2.8C22.2,63,22.2,59.9,21.4,60z"/>
		<path class="st41" d="M21.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,60c-0.8-0.2-0.8,3,0,2.8C20.2,63,20.2,59.9,19.4,60z"/>
		<path class="st41" d="M19.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,60c-0.8-0.2-0.8,3,0,2.8C18.2,63,18.2,59.9,17.4,60z"/>
		<path class="st41" d="M17.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,60.7c-0.8-0.2-0.8,3,0,2.8C66.9,63.7,66.9,60.5,66,60.7z"/>
		<path class="st41" d="M65.7,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,60.7c-0.8-0.2-0.8,3,0,2.8C64.9,63.7,64.9,60.5,64,60.7z"/>
		<path class="st41" d="M63.7,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,60.7c-0.8-0.2-0.8,3,0,2.8C62.9,63.7,62.9,60.5,62,60.7z"/>
		<path class="st41" d="M61.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,60.7c-0.8-0.2-0.8,3,0,2.8C60.9,63.7,60.9,60.5,60.1,60.7z"/>
		<path class="st41" d="M59.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,60.7c-0.8-0.2-0.8,3,0,2.8C58.9,63.7,58.9,60.5,58.1,60.7z"/>
		<path class="st41" d="M57.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,60.7c-0.8-0.2-0.8,3,0,2.8C56.9,63.7,56.9,60.5,56.1,60.7z"/>
		<path class="st41" d="M55.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,60.7c-0.8-0.2-0.8,3,0,2.8C54.9,63.7,54.9,60.5,54.1,60.7z"/>
		<path class="st41" d="M53.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,60.7c-0.8-0.2-0.8,3,0,2.8C52.9,63.7,52.9,60.5,52.1,60.7z"/>
		<path class="st41" d="M51.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,60.7c-0.8-0.2-0.8,3,0,2.8C51,63.7,51,60.5,50.1,60.7z"/>
		<path class="st41" d="M49.8,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,60.7c-0.8-0.2-0.8,3,0,2.8C49,63.7,49,60.5,48.1,60.7z"/>
		<path class="st41" d="M47.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,60.7c-0.8-0.2-0.8,3,0,2.8C47,63.7,47,60.5,46.2,60.7z"/>
		<path class="st41" d="M45.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,60.7c-0.8-0.2-0.8,3,0,2.8C45,63.7,45,60.5,44.2,60.7z"/>
		<path class="st41" d="M43.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,60.7c-0.8-0.2-0.8,3,0,2.8C43,63.7,43,60.5,42.2,60.7z"/>
		<path class="st41" d="M41.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,60.7c-0.8-0.2-0.8,3,0,2.8C41,63.7,41,60.5,40.2,60.7z"/>
		<path class="st41" d="M39.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,60.7c-0.8-0.2-0.8,3,0,2.8C39,63.7,39,60.5,38.2,60.7z"/>
		<path class="st41" d="M37.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,60.7c-0.8-0.2-0.8,3,0,2.8C37,63.7,37,60.5,36.2,60.7z"/>
		<path class="st41" d="M35.9,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,60.7c-0.8-0.2-0.8,3,0,2.8C35.2,63.7,35.2,60.5,34.3,60.7z"/>
		<path class="st41" d="M34.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,60.7c-0.8-0.2-0.8,3,0,2.8C33.2,63.7,33.2,60.5,32.4,60.7z"/>
		<path class="st41" d="M32.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,60.7c-0.8-0.2-0.8,3,0,2.8C31.2,63.7,31.2,60.5,30.4,60.7z"/>
		<path class="st41" d="M30.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,60.7c-0.8-0.2-0.8,3,0,2.8C29.2,63.7,29.2,60.5,28.4,60.7z"/>
		<path class="st41" d="M28.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,60.7c-0.8-0.2-0.8,3,0,2.8C27.2,63.7,27.2,60.5,26.4,60.7z"/>
		<path class="st41" d="M26.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,60.7c-0.8-0.2-0.8,3,0,2.8C25.2,63.7,25.2,60.5,24.4,60.7z"/>
		<path class="st41" d="M24.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,60.7c-0.8-0.2-0.8,3,0,2.8C23.3,63.7,23.3,60.5,22.4,60.7z"/>
		<path class="st41" d="M22.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,60.7c-0.8-0.2-0.8,3,0,2.8C21.3,63.7,21.3,60.5,20.4,60.7z"/>
		<path class="st41" d="M20.1,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,60.7c-0.8-0.2-0.8,3,0,2.8C19.3,63.7,19.3,60.5,18.4,60.7z"/>
		<path class="st41" d="M18.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,60c-0.8-0.2-0.8,3,0,2.8C16.2,63,16.2,59.9,15.4,60z"/>
		<path class="st41" d="M15.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,60.7c-0.8-0.2-0.8,3,0,2.8C17.3,63.7,17.3,60.5,16.5,60.7z"/>
		<path class="st41" d="M16.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,60c-0.8-0.2-0.8,3,0,2.8C14.3,63,14.3,59.9,13.4,60z"/>
		<path class="st41" d="M13.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,60.7c-0.8-0.2-0.8,3,0,2.8C15.3,63.7,15.3,60.5,14.5,60.7z"/>
		<path class="st41" d="M14.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,60c-0.8-0.2-0.8,3,0,2.8C12.3,63,12.3,59.9,11.4,60z"/>
		<path class="st41" d="M11.1,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,60.7c-0.8-0.2-0.8,3,0,2.8C13.3,63.7,13.3,60.5,12.5,60.7z"/>
		<path class="st41" d="M12.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,60c-0.8-0.2-0.8,3,0,2.8C10.3,63,10.3,59.9,9.5,60z"/>
		<path class="st41" d="M9.2,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,60.7c-0.8-0.2-0.8,3,0,2.8C11.3,63.7,11.3,60.5,10.5,60.7z"/>
		<path class="st41" d="M10.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,60c-0.8-0.2-0.8,3,0,2.8C8.3,63,8.3,59.9,7.5,60z"/>
		<path class="st41" d="M7.2,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,60.7c-0.8-0.2-0.8,3,0,2.8C9.3,63.7,9.3,60.5,8.5,60.7z"/>
		<path class="st41" d="M8.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,60c-0.8-0.2-0.8,3,0,2.8C6.3,63,6.3,59.9,5.5,60z"/>
		<path class="st41" d="M5.2,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,60.7c-0.8-0.2-0.8,3,0,2.8C7.4,63.7,7.4,60.5,6.5,60.7z"/>
		<path class="st41" d="M6.2,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,60c-0.8-0.2-0.8,3,0,2.8C4.3,63,4.3,59.9,3.5,60z"/>
		<path class="st41" d="M3.2,60.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,60.7c-0.8-0.2-0.8,3,0,2.8C5.4,63.7,5.4,60.5,4.5,60.7z"/>
		<path class="st41" d="M4.3,61.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,61.4c-0.8-0.2-0.8,3,0,2.8C65.8,64.4,65.8,61.3,65,61.4z"/>
		<path class="st41" d="M64.7,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,62.1c-0.8-0.2-0.8,3,0,2.8C66.9,65.1,66.9,61.9,66,62.1z"/>
		<path class="st41" d="M65.7,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,61.4c-0.8-0.2-0.8,3,0,2.8C63.8,64.4,63.8,61.3,63,61.4z"/>
		<path class="st41" d="M62.7,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,62.1c-0.8-0.2-0.8,3,0,2.8C64.9,65.1,64.9,61.9,64,62.1z"/>
		<path class="st41" d="M63.7,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,61.4c-0.8-0.2-0.8,3,0,2.8C61.8,64.4,61.8,61.3,61,61.4z"/>
		<path class="st41" d="M60.7,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,62.1c-0.8-0.2-0.8,3,0,2.8C62.9,65.1,62.9,61.9,62,62.1z"/>
		<path class="st41" d="M61.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,61.4c-0.8-0.2-0.8,3,0,2.8C59.8,64.4,59.8,61.3,59,61.4z"/>
		<path class="st41" d="M58.7,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,62.1c-0.8-0.2-0.8,3,0,2.8C60.9,65.1,60.9,61.9,60.1,62.1z"/>
		<path class="st41" d="M59.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,61.4c-0.8-0.2-0.8,3,0,2.8C57.9,64.4,57.9,61.3,57,61.4z"/>
		<path class="st41" d="M56.7,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,62.1c-0.8-0.2-0.8,3,0,2.8C58.9,65.1,58.9,61.9,58.1,62.1z"/>
		<path class="st41" d="M57.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,61.4c-0.8-0.2-0.8,3,0,2.8C55.9,64.4,55.9,61.3,55,61.4z"/>
		<path class="st41" d="M54.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,62.1c-0.8-0.2-0.8,3,0,2.8C56.9,65.1,56.9,61.9,56.1,62.1z"/>
		<path class="st41" d="M55.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,61.4c-0.8-0.2-0.8,3,0,2.8C53.9,64.4,53.9,61.3,53.1,61.4z"/>
		<path class="st41" d="M52.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,62.1c-0.8-0.2-0.8,3,0,2.8C54.9,65.1,54.9,61.9,54.1,62.1z"/>
		<path class="st41" d="M53.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,61.4c-0.8-0.2-0.8,3,0,2.8C51.9,64.4,51.9,61.3,51.1,61.4z"/>
		<path class="st41" d="M50.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,62.1c-0.8-0.2-0.8,3,0,2.8C52.9,65.1,52.9,61.9,52.1,62.1z"/>
		<path class="st41" d="M51.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,61.4c-0.8-0.2-0.8,3,0,2.8C49.9,64.4,49.9,61.3,49.1,61.4z"/>
		<path class="st41" d="M48.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,62.1c-0.8-0.2-0.8,3,0,2.8C51,65.1,51,61.9,50.1,62.1z"/>
		<path class="st41" d="M49.8,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,61.4c-0.8-0.2-0.8,3,0,2.8C47.9,64.4,47.9,61.3,47.1,61.4z"/>
		<path class="st41" d="M46.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,62.1c-0.8-0.2-0.8,3,0,2.8C49,65.1,49,61.9,48.1,62.1z"/>
		<path class="st41" d="M47.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,61.4c-0.8-0.2-0.8,3,0,2.8C45.9,64.4,45.9,61.3,45.1,61.4z"/>
		<path class="st41" d="M44.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,62.1c-0.8-0.2-0.8,3,0,2.8C47,65.1,47,61.9,46.2,62.1z"/>
		<path class="st41" d="M45.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,61.4c-0.8-0.2-0.8,3,0,2.8C43.9,64.4,43.9,61.3,43.1,61.4z"/>
		<path class="st41" d="M42.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,62.1c-0.8-0.2-0.8,3,0,2.8C45,65.1,45,61.9,44.2,62.1z"/>
		<path class="st41" d="M43.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,61.4c-0.8-0.2-0.8,3,0,2.8C42,64.4,42,61.3,41.1,61.4z"/>
		<path class="st41" d="M40.8,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,62.1c-0.8-0.2-0.8,3,0,2.8C43,65.1,43,61.9,42.2,62.1z"/>
		<path class="st41" d="M41.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,61.4c-0.8-0.2-0.8,3,0,2.8C40,64.4,40,61.3,39.1,61.4z"/>
		<path class="st41" d="M38.9,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,62.1c-0.8-0.2-0.8,3,0,2.8C41,65.1,41,61.9,40.2,62.1z"/>
		<path class="st41" d="M39.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,61.4c-0.8-0.2-0.8,3,0,2.8C38,64.4,38,61.3,37.2,61.4z"/>
		<path class="st41" d="M36.9,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,62.1c-0.8-0.2-0.8,3,0,2.8C39,65.1,39,61.9,38.2,62.1z"/>
		<path class="st41" d="M37.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,61.4c-0.8-0.2-0.8,3,0,2.8C36,64.4,36,61.3,35.2,61.4z"/>
		<path class="st41" d="M34.9,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,62.1c-0.8-0.2-0.8,3,0,2.8C37,65.1,37,61.9,36.2,62.1z"/>
		<path class="st41" d="M35.9,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,61.4c-0.8-0.2-0.8,3,0,2.8C34.1,64.4,34.1,61.3,33.3,61.4z"/>
		<path class="st41" d="M33,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,62.1c-0.8-0.2-0.8,3,0,2.8C35.2,65.1,35.2,61.9,34.3,62.1z"/>
		<path class="st41" d="M34.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,61.4c-0.8-0.2-0.8,3,0,2.8C32.1,64.4,32.1,61.3,31.3,61.4z"/>
		<path class="st41" d="M31,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,62.1c-0.8-0.2-0.8,3,0,2.8C33.2,65.1,33.2,61.9,32.4,62.1z"/>
		<path class="st41" d="M32.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,61.4c-0.8-0.2-0.8,3,0,2.8C30.1,64.4,30.2,61.3,29.3,61.4z"/>
		<path class="st41" d="M29,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,62.1c-0.8-0.2-0.8,3,0,2.8C31.2,65.1,31.2,61.9,30.4,62.1z"/>
		<path class="st41" d="M30.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,61.4c-0.8-0.2-0.8,3,0,2.8C28.2,64.4,28.2,61.3,27.3,61.4z"/>
		<path class="st41" d="M27,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,62.1c-0.8-0.2-0.8,3,0,2.8C29.2,65.1,29.2,61.9,28.4,62.1z"/>
		<path class="st41" d="M28.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,61.4c-0.8-0.2-0.8,3,0,2.8C26.2,64.4,26.2,61.3,25.3,61.4z"/>
		<path class="st41" d="M25.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,62.1c-0.8-0.2-0.8,3,0,2.8C27.2,65.1,27.2,61.9,26.4,62.1z"/>
		<path class="st41" d="M26.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,61.4c-0.8-0.2-0.8,3,0,2.8C24.2,64.4,24.2,61.3,23.4,61.4z"/>
		<path class="st41" d="M23.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,62.1c-0.8-0.2-0.8,3,0,2.8C25.2,65.1,25.2,61.9,24.4,62.1z"/>
		<path class="st41" d="M24.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,61.4c-0.8-0.2-0.8,3,0,2.8C22.2,64.4,22.2,61.3,21.4,61.4z"/>
		<path class="st41" d="M21.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,62.1c-0.8-0.2-0.8,3,0,2.8C23.3,65.1,23.3,61.9,22.4,62.1z"/>
		<path class="st41" d="M22.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,61.4c-0.8-0.2-0.8,3,0,2.8C20.2,64.4,20.2,61.3,19.4,61.4z"/>
		<path class="st41" d="M19.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,62.1c-0.8-0.2-0.8,3,0,2.8C21.3,65.1,21.3,61.9,20.4,62.1z"/>
		<path class="st41" d="M20.1,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,61.4c-0.8-0.2-0.8,3,0,2.8C18.2,64.4,18.2,61.3,17.4,61.4z"/>
		<path class="st41" d="M17.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,62.1c-0.8-0.2-0.8,3,0,2.8C19.3,65.1,19.3,61.9,18.4,62.1z"/>
		<path class="st41" d="M18.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,61.4c-0.8-0.2-0.8,3,0,2.8C16.2,64.4,16.2,61.3,15.4,61.4z"/>
		<path class="st41" d="M15.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,62.1c-0.8-0.2-0.8,3,0,2.8C17.3,65.1,17.3,61.9,16.5,62.1z"/>
		<path class="st41" d="M16.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,61.4c-0.8-0.2-0.8,3,0,2.8C14.3,64.4,14.3,61.3,13.4,61.4z"/>
		<path class="st41" d="M13.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,62.1c-0.8-0.2-0.8,3,0,2.8C15.3,65.1,15.3,61.9,14.5,62.1z"/>
		<path class="st41" d="M14.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,61.4c-0.8-0.2-0.8,3,0,2.8C12.3,64.4,12.3,61.3,11.4,61.4z"/>
		<path class="st41" d="M11.1,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,62.1c-0.8-0.2-0.8,3,0,2.8C13.3,65.1,13.3,61.9,12.5,62.1z"/>
		<path class="st41" d="M12.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,61.4c-0.8-0.2-0.8,3,0,2.8C10.3,64.4,10.3,61.3,9.5,61.4z"/>
		<path class="st41" d="M9.2,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,62.1c-0.8-0.2-0.8,3,0,2.8C11.3,65.1,11.3,61.9,10.5,62.1z"/>
		<path class="st41" d="M10.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,61.4c-0.8-0.2-0.8,3,0,2.8C8.3,64.4,8.3,61.3,7.5,61.4z"/>
		<path class="st41" d="M7.2,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,62.1c-0.8-0.2-0.8,3,0,2.8C9.3,65.1,9.3,61.9,8.5,62.1z"/>
		<path class="st41" d="M8.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,61.4c-0.8-0.2-0.8,3,0,2.8C6.3,64.4,6.3,61.3,5.5,61.4z"/>
		<path class="st41" d="M5.2,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,62.1c-0.8-0.2-0.8,3,0,2.8C7.4,65.1,7.4,61.9,6.5,62.1z"/>
		<path class="st41" d="M6.2,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,61.4c-0.8-0.2-0.8,3,0,2.8C4.3,64.4,4.3,61.3,3.5,61.4z"/>
		<path class="st41" d="M3.2,62.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,62.1c-0.8-0.2-0.8,3,0,2.8C5.4,65.1,5.4,61.9,4.5,62.1z"/>
		<path class="st41" d="M4.3,63c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,62.9c-0.8-0.2-0.8,3,0,2.8C65.8,65.9,65.8,62.7,65,62.9z"/>
		<path class="st41" d="M64.7,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,63.6c-0.8-0.2-0.8,3,0,2.8C66.9,66.6,66.9,63.4,66,63.6z"/>
		<path class="st41" d="M65.7,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,62.9c-0.8-0.2-0.8,3,0,2.8C63.8,65.9,63.8,62.7,63,62.9z"/>
		<path class="st41" d="M62.7,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,63.6c-0.8-0.2-0.8,3,0,2.8C64.9,66.6,64.9,63.4,64,63.6z"/>
		<path class="st41" d="M63.7,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,62.9c-0.8-0.2-0.8,3,0,2.8C61.8,65.9,61.8,62.7,61,62.9z"/>
		<path class="st41" d="M60.7,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,63.6c-0.8-0.2-0.8,3,0,2.8C62.9,66.6,62.9,63.4,62,63.6z"/>
		<path class="st41" d="M61.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,62.9c-0.8-0.2-0.8,3,0,2.8C59.8,65.9,59.8,62.7,59,62.9z"/>
		<path class="st41" d="M58.7,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,63.6c-0.8-0.2-0.8,3,0,2.8C60.9,66.6,60.9,63.4,60.1,63.6z"/>
		<path class="st41" d="M59.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,62.9c-0.8-0.2-0.8,3,0,2.8C57.9,65.9,57.9,62.7,57,62.9z"/>
		<path class="st41" d="M56.7,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,63.6c-0.8-0.2-0.8,3,0,2.8C58.9,66.6,58.9,63.4,58.1,63.6z"/>
		<path class="st41" d="M57.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,62.9c-0.8-0.2-0.8,3,0,2.8C55.9,65.9,55.9,62.7,55,62.9z"/>
		<path class="st41" d="M54.8,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,63.6c-0.8-0.2-0.8,3,0,2.8C56.9,66.6,56.9,63.4,56.1,63.6z"/>
		<path class="st41" d="M55.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,62.9c-0.8-0.2-0.8,3,0,2.8C53.9,65.9,53.9,62.7,53.1,62.9z"/>
		<path class="st41" d="M52.8,63.8C51.8,63.5,52,62,52.9,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,63.6c-0.8-0.2-0.8,3,0,2.8C54.9,66.6,54.9,63.4,54.1,63.6z"/>
		<path class="st41" d="M53.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,62.9c-0.8-0.2-0.8,3,0,2.8C51.9,65.9,51.9,62.7,51.1,62.9z"/>
		<path class="st41" d="M50.8,63.8C49.8,63.5,50,62,51,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,63.6c-0.8-0.2-0.8,3,0,2.8C52.9,66.6,52.9,63.4,52.1,63.6z"/>
		<path class="st41" d="M51.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,62.9c-0.8-0.2-0.8,3,0,2.8C49.9,65.9,49.9,62.7,49.1,62.9z"/>
		<path class="st41" d="M48.8,63.8C47.9,63.5,48,62,49,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,63.6c-0.8-0.2-0.8,3,0,2.8C51,66.6,51,63.4,50.1,63.6z"/>
		<path class="st41" d="M49.8,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,62.9c-0.8-0.2-0.8,3,0,2.8C47.9,65.9,47.9,62.7,47.1,62.9z"/>
		<path class="st41" d="M46.8,63.8C45.9,63.5,46,62,47,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,63.6c-0.8-0.2-0.8,3,0,2.8C49,66.6,49,63.4,48.1,63.6z"/>
		<path class="st41" d="M47.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,62.9c-0.8-0.2-0.8,3,0,2.8C45.9,65.9,45.9,62.7,45.1,62.9z"/>
		<path class="st41" d="M44.8,63.8C43.9,63.5,44,62,45,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,63.6c-0.8-0.2-0.8,3,0,2.8C47,66.6,47,63.4,46.2,63.6z"/>
		<path class="st41" d="M45.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,62.9c-0.8-0.2-0.8,3,0,2.8C43.9,65.9,43.9,62.7,43.1,62.9z"/>
		<path class="st41" d="M42.8,63.8C41.9,63.5,42,62,43,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,63.6c-0.8-0.2-0.8,3,0,2.8C45,66.6,45,63.4,44.2,63.6z"/>
		<path class="st41" d="M43.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,62.9c-0.8-0.2-0.8,3,0,2.8C42,65.9,42,62.7,41.1,62.9z"/>
		<path class="st41" d="M40.8,63.8C39.9,63.5,40,62,41,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,63.6c-0.8-0.2-0.8,3,0,2.8C43,66.6,43,63.4,42.2,63.6z"/>
		<path class="st41" d="M41.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,62.9c-0.8-0.2-0.8,3,0,2.8C40,65.9,40,62.7,39.1,62.9z"/>
		<path class="st41" d="M38.9,63.8C37.9,63.5,38.1,62,39,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,63.6c-0.8-0.2-0.8,3,0,2.8C41,66.6,41,63.4,40.2,63.6z"/>
		<path class="st41" d="M39.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,62.9c-0.8-0.2-0.8,3,0,2.8C38,65.9,38,62.7,37.2,62.9z"/>
		<path class="st41" d="M36.9,63.8C35.9,63.5,36.1,62,37,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,63.6c-0.8-0.2-0.8,3,0,2.8C39,66.6,39,63.4,38.2,63.6z"/>
		<path class="st41" d="M37.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,62.9c-0.8-0.2-0.8,3,0,2.8C36,65.9,36,62.7,35.2,62.9z"/>
		<path class="st41" d="M34.9,63.8C34,63.5,34.1,62,35.1,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,63.6c-0.8-0.2-0.8,3,0,2.8C37,66.6,37,63.4,36.2,63.6z"/>
		<path class="st41" d="M35.9,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,62.9c-0.8-0.2-0.8,3,0,2.8C34.1,65.9,34.1,62.7,33.3,62.9z"/>
		<path class="st41" d="M33,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,63.6c-0.8-0.2-0.8,3,0,2.8C35.2,66.6,35.2,63.4,34.3,63.6z"/>
		<path class="st41" d="M34.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,62.9c-0.8-0.2-0.8,3,0,2.8C32.1,65.9,32.1,62.7,31.3,62.9z"/>
		<path class="st41" d="M31,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,63.6c-0.8-0.2-0.8,3,0,2.8C33.2,66.6,33.2,63.4,32.4,63.6z"/>
		<path class="st41" d="M32.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,62.9c-0.8-0.2-0.8,3,0,2.8C30.1,65.9,30.2,62.7,29.3,62.9z"/>
		<path class="st41" d="M29,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,63.6c-0.8-0.2-0.8,3,0,2.8C31.2,66.6,31.2,63.4,30.4,63.6z"/>
		<path class="st41" d="M30.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,62.9c-0.8-0.2-0.8,3,0,2.8C28.2,65.9,28.2,62.7,27.3,62.9z"/>
		<path class="st41" d="M27,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,63.6c-0.8-0.2-0.8,3,0,2.8C29.2,66.6,29.2,63.4,28.4,63.6z"/>
		<path class="st41" d="M28.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,62.9c-0.8-0.2-0.8,3,0,2.8C26.2,65.9,26.2,62.7,25.3,62.9z"/>
		<path class="st41" d="M25.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,63.6c-0.8-0.2-0.8,3,0,2.8C27.2,66.6,27.2,63.4,26.4,63.6z"/>
		<path class="st41" d="M26.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,62.9c-0.8-0.2-0.8,3,0,2.8C24.2,65.9,24.2,62.7,23.4,62.9z"/>
		<path class="st41" d="M23.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,63.6c-0.8-0.2-0.8,3,0,2.8C25.2,66.6,25.2,63.4,24.4,63.6z"/>
		<path class="st41" d="M24.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,62.9c-0.8-0.2-0.8,3,0,2.8C22.2,65.9,22.2,62.7,21.4,62.9z"/>
		<path class="st41" d="M21.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,63.6c-0.8-0.2-0.8,3,0,2.8C23.3,66.6,23.3,63.4,22.4,63.6z"/>
		<path class="st41" d="M22.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,62.9c-0.8-0.2-0.8,3,0,2.8C20.2,65.9,20.2,62.7,19.4,62.9z"/>
		<path class="st41" d="M19.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,63.6c-0.8-0.2-0.8,3,0,2.8C21.3,66.6,21.3,63.4,20.4,63.6z"/>
		<path class="st41" d="M20.1,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,62.9c-0.8-0.2-0.8,3,0,2.8C18.2,65.9,18.2,62.7,17.4,62.9z"/>
		<path class="st41" d="M17.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,63.6c-0.8-0.2-0.8,3,0,2.8C19.3,66.6,19.3,63.4,18.4,63.6z"/>
		<path class="st41" d="M18.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,62.9c-0.8-0.2-0.8,3,0,2.8C16.2,65.9,16.2,62.7,15.4,62.9z"/>
		<path class="st41" d="M15.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,63.6c-0.8-0.2-0.8,3,0,2.8C17.3,66.6,17.3,63.4,16.5,63.6z"/>
		<path class="st41" d="M16.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,62.9c-0.8-0.2-0.8,3,0,2.8C14.3,65.9,14.3,62.7,13.4,62.9z"/>
		<path class="st41" d="M13.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,63.6c-0.8-0.2-0.8,3,0,2.8C15.3,66.6,15.3,63.4,14.5,63.6z"/>
		<path class="st41" d="M14.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,62.9c-0.8-0.2-0.8,3,0,2.8C12.3,65.9,12.3,62.7,11.4,62.9z"/>
		<path class="st41" d="M11.1,63.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,63.6c-0.8-0.2-0.8,3,0,2.8C13.3,66.6,13.3,63.4,12.5,63.6z"/>
		<path class="st41" d="M12.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,62.9c-0.8-0.2-0.8,3,0,2.8C10.3,65.9,10.3,62.7,9.5,62.9z"/>
		<path class="st41" d="M9.2,63.8C8.2,63.5,8.4,62,9.3,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,63.6c-0.8-0.2-0.8,3,0,2.8C11.3,66.6,11.3,63.4,10.5,63.6z"/>
		<path class="st41" d="M10.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,62.9c-0.8-0.2-0.8,3,0,2.8C8.3,65.9,8.3,62.7,7.5,62.9z"/>
		<path class="st41" d="M7.2,63.8C6.2,63.5,6.4,62,7.4,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,63.6c-0.8-0.2-0.8,3,0,2.8C9.3,66.6,9.3,63.4,8.5,63.6z"/>
		<path class="st41" d="M8.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,62.9c-0.8-0.2-0.8,3,0,2.8C6.3,65.9,6.3,62.7,5.5,62.9z"/>
		<path class="st41" d="M5.2,63.8C4.3,63.5,4.4,62,5.4,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,63.6c-0.8-0.2-0.8,3,0,2.8C7.4,66.6,7.4,63.4,6.5,63.6z"/>
		<path class="st41" d="M6.2,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,62.9c-0.8-0.2-0.8,3,0,2.8C4.3,65.9,4.3,62.7,3.5,62.9z"/>
		<path class="st41" d="M3.2,63.8C2.3,63.5,2.4,62,3.4,62c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,63.6c-0.8-0.2-0.8,3,0,2.8C5.4,66.6,5.4,63.4,4.5,63.6z"/>
		<path class="st41" d="M4.3,64.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,64.3c-0.8-0.2-0.8,3,0,2.8C65.8,67.3,65.8,64.1,65,64.3z"/>
		<path class="st41" d="M64.7,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,65c-0.8-0.2-0.8,3,0,2.8C66.9,68,66.9,64.8,66,65z"/>
		<path class="st41" d="M65.7,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,64.3c-0.8-0.2-0.8,3,0,2.8C63.8,67.3,63.8,64.1,63,64.3z"/>
		<path class="st41" d="M62.7,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,65c-0.8-0.2-0.8,3,0,2.8C64.9,68,64.9,64.8,64,65z"/>
		<path class="st41" d="M63.7,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,64.3c-0.8-0.2-0.8,3,0,2.8C61.8,67.3,61.8,64.1,61,64.3z"/>
		<path class="st41" d="M60.7,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,65c-0.8-0.2-0.8,3,0,2.8C62.9,68,62.9,64.8,62,65z"/>
		<path class="st41" d="M61.8,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,64.3c-0.8-0.2-0.8,3,0,2.8C59.8,67.3,59.8,64.1,59,64.3z"/>
		<path class="st41" d="M58.7,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,65c-0.8-0.2-0.8,3,0,2.8C60.9,68,60.9,64.8,60.1,65z"/>
		<path class="st41" d="M59.8,65.9C58.8,65.6,59,64.1,60,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,64.3c-0.8-0.2-0.8,3,0,2.8C57.9,67.3,57.9,64.1,57,64.3z"/>
		<path class="st41" d="M56.7,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,65c-0.8-0.2-0.8,3,0,2.8C58.9,68,58.9,64.8,58.1,65z"/>
		<path class="st41" d="M57.8,65.9C56.9,65.6,57,64.1,58,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,64.3c-0.8-0.2-0.8,3,0,2.8C55.9,67.3,55.9,64.1,55,64.3z"/>
		<path class="st41" d="M54.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,65c-0.8-0.2-0.8,3,0,2.8C56.9,68,56.9,64.8,56.1,65z"/>
		<path class="st41" d="M55.8,65.9C54.9,65.6,55,64.1,56,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,64.3c-0.8-0.2-0.8,3,0,2.8C53.9,67.3,53.9,64.1,53.1,64.3z"/>
		<path class="st41" d="M52.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,65c-0.8-0.2-0.8,3,0,2.8C54.9,68,54.9,64.8,54.1,65z"/>
		<path class="st41" d="M53.8,65.9C52.9,65.6,53,64.1,54,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,64.3c-0.8-0.2-0.8,3,0,2.8C51.9,67.3,51.9,64.1,51.1,64.3z"/>
		<path class="st41" d="M50.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,65c-0.8-0.2-0.8,3,0,2.8C52.9,68,52.9,64.8,52.1,65z"/>
		<path class="st41" d="M51.8,65.9C50.9,65.6,51,64.1,52,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,64.3c-0.8-0.2-0.8,3,0,2.8C49.9,67.3,49.9,64.1,49.1,64.3z"/>
		<path class="st41" d="M48.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,65c-0.8-0.2-0.8,3,0,2.8C51,68,51,64.8,50.1,65z"/>
		<path class="st41" d="M49.8,65.9C48.9,65.6,49,64.1,50,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,64.3c-0.8-0.2-0.8,3,0,2.8C47.9,67.3,47.9,64.1,47.1,64.3z"/>
		<path class="st41" d="M46.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,65c-0.8-0.2-0.8,3,0,2.8C49,68,49,64.8,48.1,65z"/>
		<path class="st41" d="M47.9,65.9C46.9,65.6,47,64.1,48,64c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,64.3c-0.8-0.2-0.8,3,0,2.8C45.9,67.3,45.9,64.1,45.1,64.3z"/>
		<path class="st41" d="M44.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,65c-0.8-0.2-0.8,3,0,2.8C47,68,47,64.8,46.2,65z"/>
		<path class="st41" d="M45.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,64.3c-0.8-0.2-0.8,3,0,2.8C43.9,67.3,43.9,64.1,43.1,64.3z"/>
		<path class="st41" d="M42.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,65c-0.8-0.2-0.8,3,0,2.8C45,68,45,64.8,44.2,65z"/>
		<path class="st41" d="M43.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,64.3c-0.8-0.2-0.8,3,0,2.8C42,67.3,42,64.1,41.1,64.3z"/>
		<path class="st41" d="M40.8,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,65c-0.8-0.2-0.8,3,0,2.8C43,68,43,64.8,42.2,65z"/>
		<path class="st41" d="M41.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,64.3c-0.8-0.2-0.8,3,0,2.8C40,67.3,40,64.1,39.1,64.3z"/>
		<path class="st41" d="M38.9,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,65c-0.8-0.2-0.8,3,0,2.8C41,68,41,64.8,40.2,65z"/>
		<path class="st41" d="M39.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,64.3c-0.8-0.2-0.8,3,0,2.8C38,67.3,38,64.1,37.2,64.3z"/>
		<path class="st41" d="M36.9,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,65c-0.8-0.2-0.8,3,0,2.8C39,68,39,64.8,38.2,65z"/>
		<path class="st41" d="M37.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,64.3c-0.8-0.2-0.8,3,0,2.8C36,67.3,36,64.1,35.2,64.3z"/>
		<path class="st41" d="M34.9,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,65c-0.8-0.2-0.8,3,0,2.8C37,68,37,64.8,36.2,65z"/>
		<path class="st41" d="M35.9,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,64.3c-0.8-0.2-0.8,3,0,2.8C34.1,67.3,34.1,64.1,33.3,64.3z"/>
		<path class="st41" d="M33,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,65c-0.8-0.2-0.8,3,0,2.8C35.2,68,35.2,64.8,34.3,65z"/>
		<path class="st41" d="M34.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,64.3c-0.8-0.2-0.8,3,0,2.8C32.1,67.3,32.1,64.1,31.3,64.3z"/>
		<path class="st41" d="M31,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,65c-0.8-0.2-0.8,3,0,2.8C33.2,68,33.2,64.8,32.4,65z"/>
		<path class="st41" d="M32.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,64.3c-0.8-0.2-0.8,3,0,2.8C30.1,67.3,30.2,64.1,29.3,64.3z"/>
		<path class="st41" d="M29,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,65c-0.8-0.2-0.8,3,0,2.8C31.2,68,31.2,64.8,30.4,65z"/>
		<path class="st41" d="M30.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,64.3c-0.8-0.2-0.8,3,0,2.8C28.2,67.3,28.2,64.1,27.3,64.3z"/>
		<path class="st41" d="M27,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,65c-0.8-0.2-0.8,3,0,2.8C29.2,68,29.2,64.8,28.4,65z"/>
		<path class="st41" d="M28.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,64.3c-0.8-0.2-0.8,3,0,2.8C26.2,67.3,26.2,64.1,25.3,64.3z"/>
		<path class="st41" d="M25.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,65c-0.8-0.2-0.8,3,0,2.8C27.2,68,27.2,64.8,26.4,65z"/>
		<path class="st41" d="M26.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,64.3c-0.8-0.2-0.8,3,0,2.8C24.2,67.3,24.2,64.1,23.4,64.3z"/>
		<path class="st41" d="M23.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,65c-0.8-0.2-0.8,3,0,2.8C25.2,68,25.2,64.8,24.4,65z"/>
		<path class="st41" d="M24.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,64.3c-0.8-0.2-0.8,3,0,2.8C22.2,67.3,22.2,64.1,21.4,64.3z"/>
		<path class="st41" d="M21.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,65c-0.8-0.2-0.8,3,0,2.8C23.3,68,23.3,64.8,22.4,65z"/>
		<path class="st41" d="M22.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,64.3c-0.8-0.2-0.8,3,0,2.8C20.2,67.3,20.2,64.1,19.4,64.3z"/>
		<path class="st41" d="M19.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,65c-0.8-0.2-0.8,3,0,2.8C21.3,68,21.3,64.8,20.4,65z"/>
		<path class="st41" d="M20.1,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,64.3c-0.8-0.2-0.8,3,0,2.8C18.2,67.3,18.2,64.1,17.4,64.3z"/>
		<path class="st41" d="M17.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,65c-0.8-0.2-0.8,3,0,2.8C19.3,68,19.3,64.8,18.4,65z"/>
		<path class="st41" d="M18.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,64.3c-0.8-0.2-0.8,3,0,2.8C16.2,67.3,16.2,64.1,15.4,64.3z"/>
		<path class="st41" d="M15.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,65c-0.8-0.2-0.8,3,0,2.8C17.3,68,17.3,64.8,16.5,65z"/>
		<path class="st41" d="M16.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,64.3c-0.8-0.2-0.8,3,0,2.8C14.3,67.3,14.3,64.1,13.4,64.3z"/>
		<path class="st41" d="M13.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,65c-0.8-0.2-0.8,3,0,2.8C15.3,68,15.3,64.8,14.5,65z"/>
		<path class="st41" d="M14.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,64.3c-0.8-0.2-0.8,3,0,2.8C12.3,67.3,12.3,64.1,11.4,64.3z"/>
		<path class="st41" d="M11.1,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,65c-0.8-0.2-0.8,3,0,2.8C13.3,68,13.3,64.8,12.5,65z"/>
		<path class="st41" d="M12.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,64.3c-0.8-0.2-0.8,3,0,2.8C10.3,67.3,10.3,64.1,9.5,64.3z"/>
		<path class="st41" d="M9.2,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,65c-0.8-0.2-0.8,3,0,2.8C11.3,68,11.3,64.8,10.5,65z"/>
		<path class="st41" d="M10.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,64.3c-0.8-0.2-0.8,3,0,2.8C8.3,67.3,8.3,64.1,7.5,64.3z"/>
		<path class="st41" d="M7.2,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,65c-0.8-0.2-0.8,3,0,2.8C9.3,68,9.3,64.8,8.5,65z"/>
		<path class="st41" d="M8.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,64.3c-0.8-0.2-0.8,3,0,2.8C6.3,67.3,6.3,64.1,5.5,64.3z"/>
		<path class="st41" d="M5.2,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,65c-0.8-0.2-0.8,3,0,2.8C7.4,68,7.4,64.8,6.5,65z"/>
		<path class="st41" d="M6.2,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,64.3c-0.8-0.2-0.8,3,0,2.8C4.3,67.3,4.3,64.1,3.5,64.3z"/>
		<path class="st41" d="M3.2,65.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,65c-0.8-0.2-0.8,3,0,2.8C5.4,68,5.4,64.8,4.5,65z"/>
		<path class="st41" d="M4.3,65.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,65.9c-0.8-0.2-0.8,3,0,2.8C65.8,68.9,65.8,65.7,65,65.9z"/>
		<path class="st41" d="M64.7,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,65.9c-0.8-0.2-0.8,3,0,2.8C63.8,68.9,63.8,65.7,63,65.9z"/>
		<path class="st41" d="M62.7,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,65.9c-0.8-0.2-0.8,3,0,2.8C61.8,68.9,61.8,65.7,61,65.9z"/>
		<path class="st41" d="M60.7,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,65.9c-0.8-0.2-0.8,3,0,2.8C59.8,68.9,59.8,65.7,59,65.9z"/>
		<path class="st41" d="M58.7,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,65.9c-0.8-0.2-0.8,3,0,2.8C57.9,68.9,57.9,65.7,57,65.9z"/>
		<path class="st41" d="M56.7,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,65.9c-0.8-0.2-0.8,3,0,2.8C55.9,68.9,55.9,65.7,55,65.9z"/>
		<path class="st41" d="M54.8,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,65.9c-0.8-0.2-0.8,3,0,2.8C53.9,68.9,53.9,65.7,53.1,65.9z"/>
		<path class="st41" d="M52.8,66.8C51.8,66.5,52,65,52.9,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,65.9c-0.8-0.2-0.8,3,0,2.8C51.9,68.9,51.9,65.7,51.1,65.9z"/>
		<path class="st41" d="M50.8,66.8C49.8,66.5,50,65,51,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,65.9c-0.8-0.2-0.8,3,0,2.8C49.9,68.9,49.9,65.7,49.1,65.9z"/>
		<path class="st41" d="M48.8,66.8C47.9,66.5,48,65,49,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,65.9c-0.8-0.2-0.8,3,0,2.8C47.9,68.9,47.9,65.7,47.1,65.9z"/>
		<path class="st41" d="M46.8,66.8C45.9,66.5,46,65,47,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,65.9c-0.8-0.2-0.8,3,0,2.8C45.9,68.9,45.9,65.7,45.1,65.9z"/>
		<path class="st41" d="M44.8,66.8C43.9,66.5,44,65,45,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,65.9c-0.8-0.2-0.8,3,0,2.8C43.9,68.9,43.9,65.7,43.1,65.9z"/>
		<path class="st41" d="M42.8,66.8C41.9,66.5,42,65,43,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,65.9c-0.8-0.2-0.8,3,0,2.8C42,68.9,42,65.7,41.1,65.9z"/>
		<path class="st41" d="M40.8,66.8C39.9,66.5,40,65,41,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,65.9c-0.8-0.2-0.8,3,0,2.8C40,68.9,40,65.7,39.1,65.9z"/>
		<path class="st41" d="M38.9,66.8C37.9,66.5,38.1,65,39,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,65.9c-0.8-0.2-0.8,3,0,2.8C38,68.9,38,65.7,37.2,65.9z"/>
		<path class="st41" d="M36.9,66.8C35.9,66.5,36.1,65,37,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,65.9c-0.8-0.2-0.8,3,0,2.8C36,68.9,36,65.7,35.2,65.9z"/>
		<path class="st41" d="M34.9,66.8C34,66.5,34.1,65,35.1,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,65.9c-0.8-0.2-0.8,3,0,2.8C34.1,68.9,34.1,65.7,33.3,65.9z"/>
		<path class="st41" d="M33,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,65.9c-0.8-0.2-0.8,3,0,2.8C32.1,68.9,32.1,65.7,31.3,65.9z"/>
		<path class="st41" d="M31,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,65.9c-0.8-0.2-0.8,3,0,2.8C30.1,68.9,30.2,65.7,29.3,65.9z"/>
		<path class="st41" d="M29,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,65.9c-0.8-0.2-0.8,3,0,2.8C28.2,68.9,28.2,65.7,27.3,65.9z"/>
		<path class="st41" d="M27,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,65.9c-0.8-0.2-0.8,3,0,2.8C26.2,68.9,26.2,65.7,25.3,65.9z"/>
		<path class="st41" d="M25.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,65.9c-0.8-0.2-0.8,3,0,2.8C24.2,68.9,24.2,65.7,23.4,65.9z"/>
		<path class="st41" d="M23.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,65.9c-0.8-0.2-0.8,3,0,2.8C22.2,68.9,22.2,65.7,21.4,65.9z"/>
		<path class="st41" d="M21.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,65.9c-0.8-0.2-0.8,3,0,2.8C20.2,68.9,20.2,65.7,19.4,65.9z"/>
		<path class="st41" d="M19.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,65.9c-0.8-0.2-0.8,3,0,2.8C18.2,68.9,18.2,65.7,17.4,65.9z"/>
		<path class="st41" d="M17.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,65.9c-0.8-0.2-0.8,3,0,2.8C16.2,68.9,16.2,65.7,15.4,65.9z"/>
		<path class="st41" d="M15.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,65.9c-0.8-0.2-0.8,3,0,2.8C14.3,68.9,14.3,65.7,13.4,65.9z"/>
		<path class="st41" d="M13.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M11.4,65.9c-0.8-0.2-0.8,3,0,2.8C12.3,68.9,12.3,65.7,11.4,65.9z"/>
		<path class="st42" d="M11.1,66.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M9.5,65.9c-0.8-0.2-0.8,3,0,2.8C10.3,68.9,10.3,65.7,9.5,65.9z"/>
		<path class="st42" d="M9.2,66.8C8.2,66.5,8.4,65,9.3,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.5,65.9c-0.8-0.2-0.8,3,0,2.8C8.3,68.9,8.3,65.7,7.5,65.9z"/>
		<path class="st42" d="M7.2,66.8C6.2,66.5,6.4,65,7.4,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,65.9c-0.8-0.2-0.8,3,0,2.8C6.3,68.9,6.3,65.7,5.5,65.9z"/>
		<path class="st42" d="M5.2,66.8C4.3,66.5,4.4,65,5.4,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,65.9c-0.8-0.2-0.8,3,0,2.8C4.3,68.9,4.3,65.7,3.5,65.9z"/>
		<path class="st42" d="M3.2,66.8C2.3,66.5,2.4,65,3.4,65c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M66,78.7c-0.8-0.2-0.8,3,0,2.8C66.9,81.7,66.9,78.6,66,78.7z"/>
		<path class="st41" d="M65.7,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,78.7c-0.8-0.2-0.8,3,0,2.8C64.9,81.7,64.9,78.6,64,78.7z"/>
		<path class="st41" d="M63.7,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,78.7c-0.8-0.2-0.8,3,0,2.8C62.9,81.7,62.9,78.6,62,78.7z"/>
		<path class="st41" d="M61.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,78.7c-0.8-0.2-0.8,3,0,2.8C60.9,81.7,60.9,78.6,60.1,78.7z"/>
		<path class="st41" d="M59.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,78.7c-0.8-0.2-0.8,3,0,2.8C58.9,81.7,58.9,78.6,58.1,78.7z"/>
		<path class="st41" d="M57.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,78.7c-0.8-0.2-0.8,3,0,2.8C56.9,81.7,56.9,78.6,56.1,78.7z"/>
		<path class="st41" d="M55.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,78.7c-0.8-0.2-0.8,3,0,2.8C54.9,81.7,54.9,78.6,54.1,78.7z"/>
		<path class="st41" d="M53.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,78.7c-0.8-0.2-0.8,3,0,2.8C52.9,81.7,52.9,78.6,52.1,78.7z"/>
		<path class="st41" d="M51.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,78.7c-0.8-0.2-0.8,3,0,2.8C51,81.7,51,78.6,50.1,78.7z"/>
		<path class="st41" d="M49.8,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,78.7c-0.8-0.2-0.8,3,0,2.8C49,81.7,49,78.6,48.1,78.7z"/>
		<path class="st41" d="M47.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,78.7c-0.8-0.2-0.8,3,0,2.8C47,81.7,47,78.6,46.2,78.7z"/>
		<path class="st41" d="M45.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,78.7c-0.8-0.2-0.8,3,0,2.8C45,81.7,45,78.6,44.2,78.7z"/>
		<path class="st41" d="M43.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,78.7c-0.8-0.2-0.8,3,0,2.8C43,81.7,43,78.6,42.2,78.7z"/>
		<path class="st41" d="M41.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,78.7c-0.8-0.2-0.8,3,0,2.8C41,81.7,41,78.6,40.2,78.7z"/>
		<path class="st41" d="M39.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,78.1c-0.8-0.2-0.8,3,0,2.8C38,81.1,38,77.9,37.2,78.1z"/>
		<path class="st41" d="M36.9,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,78.7c-0.8-0.2-0.8,3,0,2.8C39,81.7,39,78.6,38.2,78.7z"/>
		<path class="st41" d="M37.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,78.1c-0.8-0.2-0.8,3,0,2.8C36,81.1,36,77.9,35.2,78.1z"/>
		<path class="st41" d="M34.9,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,78.7c-0.8-0.2-0.8,3,0,2.8C37,81.7,37,78.6,36.2,78.7z"/>
		<path class="st41" d="M35.9,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,78.1c-0.8-0.2-0.8,3,0,2.8C34.1,81.1,34.1,77.9,33.3,78.1z"/>
		<path class="st41" d="M33,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,78.7c-0.8-0.2-0.8,3,0,2.8C35.2,81.7,35.2,78.6,34.3,78.7z"/>
		<path class="st41" d="M34.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,78.1c-0.8-0.2-0.8,3,0,2.8C32.1,81.1,32.1,77.9,31.3,78.1z"/>
		<path class="st41" d="M31,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,78.7c-0.8-0.2-0.8,3,0,2.8C33.2,81.7,33.2,78.6,32.4,78.7z"/>
		<path class="st41" d="M32.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,78.1c-0.8-0.2-0.8,3,0,2.8C30.1,81.1,30.2,77.9,29.3,78.1z"/>
		<path class="st41" d="M29,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,78.7c-0.8-0.2-0.8,3,0,2.8C31.2,81.7,31.2,78.6,30.4,78.7z"/>
		<path class="st41" d="M30.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,78.1c-0.8-0.2-0.8,3,0,2.8C28.2,81.1,28.2,77.9,27.3,78.1z"/>
		<path class="st41" d="M27,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,78.7c-0.8-0.2-0.8,3,0,2.8C29.2,81.7,29.2,78.6,28.4,78.7z"/>
		<path class="st41" d="M28.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,78.1c-0.8-0.2-0.8,3,0,2.8C26.2,81.1,26.2,77.9,25.3,78.1z"/>
		<path class="st41" d="M25.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,78.7c-0.8-0.2-0.8,3,0,2.8C27.2,81.7,27.2,78.6,26.4,78.7z"/>
		<path class="st41" d="M26.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,78.1c-0.8-0.2-0.8,3,0,2.8C24.2,81.1,24.2,77.9,23.4,78.1z"/>
		<path class="st41" d="M23.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,78.7c-0.8-0.2-0.8,3,0,2.8C25.2,81.7,25.2,78.6,24.4,78.7z"/>
		<path class="st41" d="M24.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,78.1c-0.8-0.2-0.8,3,0,2.8C22.2,81.1,22.2,77.9,21.4,78.1z"/>
		<path class="st41" d="M21.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,78.7c-0.8-0.2-0.8,3,0,2.8C23.3,81.7,23.3,78.6,22.4,78.7z"/>
		<path class="st41" d="M22.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,78.1c-0.8-0.2-0.8,3,0,2.8C20.2,81.1,20.2,77.9,19.4,78.1z"/>
		<path class="st41" d="M19.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,78.7c-0.8-0.2-0.8,3,0,2.8C21.3,81.7,21.3,78.6,20.4,78.7z"/>
		<path class="st41" d="M20.1,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,78.1c-0.8-0.2-0.8,3,0,2.8C18.2,81.1,18.2,77.9,17.4,78.1z"/>
		<path class="st41" d="M17.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,78.7c-0.8-0.2-0.8,3,0,2.8C19.3,81.7,19.3,78.6,18.4,78.7z"/>
		<path class="st41" d="M18.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,78.1c-0.8-0.2-0.8,3,0,2.8C16.2,81.1,16.2,77.9,15.4,78.1z"/>
		<path class="st41" d="M15.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,78.7c-0.8-0.2-0.8,3,0,2.8C17.3,81.7,17.3,78.6,16.5,78.7z"/>
		<path class="st41" d="M16.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,78.1c-0.8-0.2-0.8,3,0,2.8C14.3,81.1,14.3,77.9,13.4,78.1z"/>
		<path class="st41" d="M13.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,78.7c-0.8-0.2-0.8,3,0,2.8C15.3,81.7,15.3,78.6,14.5,78.7z"/>
		<path class="st41" d="M14.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,78.1c-0.8-0.2-0.8,3,0,2.8C12.3,81.1,12.3,77.9,11.4,78.1z"/>
		<path class="st41" d="M11.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,78.7c-0.8-0.2-0.8,3,0,2.8C13.3,81.7,13.3,78.6,12.5,78.7z"/>
		<path class="st41" d="M12.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,78.1c-0.8-0.2-0.8,3,0,2.8C10.3,81.1,10.3,77.9,9.5,78.1z"/>
		<path class="st41" d="M9.2,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,78.7c-0.8-0.2-0.8,3,0,2.8C11.3,81.7,11.3,78.6,10.5,78.7z"/>
		<path class="st41" d="M10.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,78.1c-0.8-0.2-0.8,3,0,2.8C8.3,81.1,8.3,77.9,7.5,78.1z"/>
		<path class="st41" d="M7.2,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,78.7c-0.8-0.2-0.8,3,0,2.8C9.3,81.7,9.3,78.6,8.5,78.7z"/>
		<path class="st41" d="M8.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,78.1c-0.8-0.2-0.8,3,0,2.8C6.3,81.1,6.3,77.9,5.5,78.1z"/>
		<path class="st41" d="M5.2,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,78.7c-0.8-0.2-0.8,3,0,2.8C7.4,81.7,7.4,78.6,6.5,78.7z"/>
		<path class="st41" d="M6.2,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,78.1c-0.8-0.2-0.8,3,0,2.8C4.3,81.1,4.3,77.9,3.5,78.1z"/>
		<path class="st41" d="M3.2,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,78.7c-0.8-0.2-0.8,3,0,2.8C5.4,81.7,5.4,78.6,4.5,78.7z"/>
		<path class="st41" d="M4.3,79.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,79.5c-0.8-0.2-0.8,3,0,2.8C65.8,82.5,65.8,79.3,65,79.5z"/>
		<path class="st41" d="M64.7,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,80.2c-0.8-0.2-0.8,3,0,2.8C66.9,83.2,66.9,80,66,80.2z"/>
		<path class="st41" d="M65.7,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,79.5c-0.8-0.2-0.8,3,0,2.8C63.8,82.5,63.8,79.3,63,79.5z"/>
		<path class="st41" d="M62.7,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,80.2c-0.8-0.2-0.8,3,0,2.8C64.9,83.2,64.9,80,64,80.2z"/>
		<path class="st41" d="M63.7,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,79.5c-0.8-0.2-0.8,3,0,2.8C61.8,82.5,61.8,79.3,61,79.5z"/>
		<path class="st41" d="M60.7,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,80.2c-0.8-0.2-0.8,3,0,2.8C62.9,83.2,62.9,80,62,80.2z"/>
		<path class="st41" d="M61.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,79.5c-0.8-0.2-0.8,3,0,2.8C59.8,82.5,59.8,79.3,59,79.5z"/>
		<path class="st41" d="M58.7,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,80.2c-0.8-0.2-0.8,3,0,2.8C60.9,83.2,60.9,80,60.1,80.2z"/>
		<path class="st41" d="M59.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,79.5c-0.8-0.2-0.8,3,0,2.8C57.9,82.5,57.9,79.3,57,79.5z"/>
		<path class="st41" d="M56.7,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,80.2c-0.8-0.2-0.8,3,0,2.8C58.9,83.2,58.9,80,58.1,80.2z"/>
		<path class="st41" d="M57.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,79.5c-0.8-0.2-0.8,3,0,2.8C55.9,82.5,55.9,79.3,55,79.5z"/>
		<path class="st41" d="M54.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,80.2c-0.8-0.2-0.8,3,0,2.8C56.9,83.2,56.9,80,56.1,80.2z"/>
		<path class="st41" d="M55.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,79.5c-0.8-0.2-0.8,3,0,2.8C53.9,82.5,53.9,79.3,53.1,79.5z"/>
		<path class="st41" d="M52.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,80.2c-0.8-0.2-0.8,3,0,2.8C54.9,83.2,54.9,80,54.1,80.2z"/>
		<path class="st41" d="M53.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,79.5c-0.8-0.2-0.8,3,0,2.8C51.9,82.5,51.9,79.3,51.1,79.5z"/>
		<path class="st41" d="M50.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,80.2c-0.8-0.2-0.8,3,0,2.8C52.9,83.2,52.9,80,52.1,80.2z"/>
		<path class="st41" d="M51.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,79.5c-0.8-0.2-0.8,3,0,2.8C49.9,82.5,49.9,79.3,49.1,79.5z"/>
		<path class="st41" d="M48.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,80.2c-0.8-0.2-0.8,3,0,2.8C51,83.2,51,80,50.1,80.2z"/>
		<path class="st41" d="M49.8,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,79.5c-0.8-0.2-0.8,3,0,2.8C47.9,82.5,47.9,79.3,47.1,79.5z"/>
		<path class="st41" d="M46.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,80.2c-0.8-0.2-0.8,3,0,2.8C49,83.2,49,80,48.1,80.2z"/>
		<path class="st41" d="M47.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,79.5c-0.8-0.2-0.8,3,0,2.8C45.9,82.5,45.9,79.3,45.1,79.5z"/>
		<path class="st41" d="M44.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,80.2c-0.8-0.2-0.8,3,0,2.8C47,83.2,47,80,46.2,80.2z"/>
		<path class="st41" d="M45.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,79.5c-0.8-0.2-0.8,3,0,2.8C43.9,82.5,43.9,79.3,43.1,79.5z"/>
		<path class="st41" d="M42.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,80.2c-0.8-0.2-0.8,3,0,2.8C45,83.2,45,80,44.2,80.2z"/>
		<path class="st41" d="M43.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,79.5c-0.8-0.2-0.8,3,0,2.8C42,82.5,42,79.3,41.1,79.5z"/>
		<path class="st41" d="M40.8,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,80.2c-0.8-0.2-0.8,3,0,2.8C43,83.2,43,80,42.2,80.2z"/>
		<path class="st41" d="M41.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,79.5c-0.8-0.2-0.8,3,0,2.8C40,82.5,40,79.3,39.1,79.5z"/>
		<path class="st41" d="M38.9,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,80.2c-0.8-0.2-0.8,3,0,2.8C41,83.2,41,80,40.2,80.2z"/>
		<path class="st41" d="M39.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,79.5c-0.8-0.2-0.8,3,0,2.8C38,82.5,38,79.3,37.2,79.5z"/>
		<path class="st41" d="M36.9,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,80.2c-0.8-0.2-0.8,3,0,2.8C39,83.2,39,80,38.2,80.2z"/>
		<path class="st41" d="M37.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,79.5c-0.8-0.2-0.8,3,0,2.8C36,82.5,36,79.3,35.2,79.5z"/>
		<path class="st41" d="M34.9,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,80.2c-0.8-0.2-0.8,3,0,2.8C37,83.2,37,80,36.2,80.2z"/>
		<path class="st41" d="M35.9,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,79.5c-0.8-0.2-0.8,3,0,2.8C34.1,82.5,34.1,79.3,33.3,79.5z"/>
		<path class="st41" d="M33,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,80.2c-0.8-0.2-0.8,3,0,2.8C35.2,83.2,35.2,80,34.3,80.2z"/>
		<path class="st41" d="M34.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,79.5c-0.8-0.2-0.8,3,0,2.8C32.1,82.5,32.1,79.3,31.3,79.5z"/>
		<path class="st41" d="M31,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,80.2c-0.8-0.2-0.8,3,0,2.8C33.2,83.2,33.2,80,32.4,80.2z"/>
		<path class="st41" d="M32.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,79.5c-0.8-0.2-0.8,3,0,2.8C30.1,82.5,30.2,79.3,29.3,79.5z"/>
		<path class="st41" d="M29,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,80.2c-0.8-0.2-0.8,3,0,2.8C31.2,83.2,31.2,80,30.4,80.2z"/>
		<path class="st41" d="M30.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,79.5c-0.8-0.2-0.8,3,0,2.8C28.2,82.5,28.2,79.3,27.3,79.5z"/>
		<path class="st41" d="M27,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,80.2c-0.8-0.2-0.8,3,0,2.8C29.2,83.2,29.2,80,28.4,80.2z"/>
		<path class="st41" d="M28.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,79.5c-0.8-0.2-0.8,3,0,2.8C26.2,82.5,26.2,79.3,25.3,79.5z"/>
		<path class="st41" d="M25.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,80.2c-0.8-0.2-0.8,3,0,2.8C27.2,83.2,27.2,80,26.4,80.2z"/>
		<path class="st41" d="M26.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,79.5c-0.8-0.2-0.8,3,0,2.8C24.2,82.5,24.2,79.3,23.4,79.5z"/>
		<path class="st41" d="M23.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,80.2c-0.8-0.2-0.8,3,0,2.8C25.2,83.2,25.2,80,24.4,80.2z"/>
		<path class="st41" d="M24.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,79.5c-0.8-0.2-0.8,3,0,2.8C22.2,82.5,22.2,79.3,21.4,79.5z"/>
		<path class="st41" d="M21.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,80.2c-0.8-0.2-0.8,3,0,2.8C23.3,83.2,23.3,80,22.4,80.2z"/>
		<path class="st41" d="M22.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,79.5c-0.8-0.2-0.8,3,0,2.8C20.2,82.5,20.2,79.3,19.4,79.5z"/>
		<path class="st41" d="M19.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,80.2c-0.8-0.2-0.8,3,0,2.8C21.3,83.2,21.3,80,20.4,80.2z"/>
		<path class="st41" d="M20.1,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,79.5c-0.8-0.2-0.8,3,0,2.8C18.2,82.5,18.2,79.3,17.4,79.5z"/>
		<path class="st41" d="M17.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,80.2c-0.8-0.2-0.8,3,0,2.8C19.3,83.2,19.3,80,18.4,80.2z"/>
		<path class="st41" d="M18.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,79.5c-0.8-0.2-0.8,3,0,2.8C16.2,82.5,16.2,79.3,15.4,79.5z"/>
		<path class="st41" d="M15.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,80.2c-0.8-0.2-0.8,3,0,2.8C17.3,83.2,17.3,80,16.5,80.2z"/>
		<path class="st41" d="M16.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,79.5c-0.8-0.2-0.8,3,0,2.8C14.3,82.5,14.3,79.3,13.4,79.5z"/>
		<path class="st41" d="M13.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,80.2c-0.8-0.2-0.8,3,0,2.8C15.3,83.2,15.3,80,14.5,80.2z"/>
		<path class="st41" d="M14.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,79.5c-0.8-0.2-0.8,3,0,2.8C12.3,82.5,12.3,79.3,11.4,79.5z"/>
		<path class="st41" d="M11.1,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,80.2c-0.8-0.2-0.8,3,0,2.8C13.3,83.2,13.3,80,12.5,80.2z"/>
		<path class="st41" d="M12.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,79.5c-0.8-0.2-0.8,3,0,2.8C10.3,82.5,10.3,79.3,9.5,79.5z"/>
		<path class="st41" d="M9.2,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,80.2c-0.8-0.2-0.8,3,0,2.8C11.3,83.2,11.3,80,10.5,80.2z"/>
		<path class="st41" d="M10.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,79.5c-0.8-0.2-0.8,3,0,2.8C8.3,82.5,8.3,79.3,7.5,79.5z"/>
		<path class="st41" d="M7.2,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,80.2c-0.8-0.2-0.8,3,0,2.8C9.3,83.2,9.3,80,8.5,80.2z"/>
		<path class="st41" d="M8.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,79.5c-0.8-0.2-0.8,3,0,2.8C6.3,82.5,6.3,79.3,5.5,79.5z"/>
		<path class="st41" d="M5.2,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,80.2c-0.8-0.2-0.8,3,0,2.8C7.4,83.2,7.4,80,6.5,80.2z"/>
		<path class="st41" d="M6.2,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,79.5c-0.8-0.2-0.8,3,0,2.8C4.3,82.5,4.3,79.3,3.5,79.5z"/>
		<path class="st41" d="M3.2,80.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,80.2c-0.8-0.2-0.8,3,0,2.8C5.4,83.2,5.4,80,4.5,80.2z"/>
		<path class="st41" d="M4.3,81c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,81.1c-0.8-0.2-0.8,3,0,2.8C65.8,84.1,65.8,80.9,65,81.1z"/>
		<path class="st41" d="M64.7,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,81.8c-0.8-0.2-0.8,3,0,2.8C66.9,84.8,66.9,81.6,66,81.8z"/>
		<path class="st41" d="M65.7,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,81.1c-0.8-0.2-0.8,3,0,2.8C63.8,84.1,63.8,80.9,63,81.1z"/>
		<path class="st41" d="M62.7,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,81.8c-0.8-0.2-0.8,3,0,2.8C64.9,84.8,64.9,81.6,64,81.8z"/>
		<path class="st41" d="M63.7,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,81.1c-0.8-0.2-0.8,3,0,2.8C61.8,84.1,61.8,80.9,61,81.1z"/>
		<path class="st41" d="M60.7,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,81.8c-0.8-0.2-0.8,3,0,2.8C62.9,84.8,62.9,81.6,62,81.8z"/>
		<path class="st41" d="M61.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,81.1c-0.8-0.2-0.8,3,0,2.8C59.8,84.1,59.8,80.9,59,81.1z"/>
		<path class="st41" d="M58.7,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,81.8c-0.8-0.2-0.8,3,0,2.8C60.9,84.8,60.9,81.6,60.1,81.8z"/>
		<path class="st41" d="M59.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,81.1c-0.8-0.2-0.8,3,0,2.8C57.9,84.1,57.9,80.9,57,81.1z"/>
		<path class="st41" d="M56.7,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,81.8c-0.8-0.2-0.8,3,0,2.8C58.9,84.8,58.9,81.6,58.1,81.8z"/>
		<path class="st41" d="M57.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,81.1c-0.8-0.2-0.8,3,0,2.8C55.9,84.1,55.9,80.9,55,81.1z"/>
		<path class="st41" d="M54.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,81.8c-0.8-0.2-0.8,3,0,2.8C56.9,84.8,56.9,81.6,56.1,81.8z"/>
		<path class="st41" d="M55.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,81.1c-0.8-0.2-0.8,3,0,2.8C53.9,84.1,53.9,80.9,53.1,81.1z"/>
		<path class="st41" d="M52.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,81.8c-0.8-0.2-0.8,3,0,2.8C54.9,84.8,54.9,81.6,54.1,81.8z"/>
		<path class="st41" d="M53.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,81.1c-0.8-0.2-0.8,3,0,2.8C51.9,84.1,51.9,80.9,51.1,81.1z"/>
		<path class="st41" d="M50.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,81.8c-0.8-0.2-0.8,3,0,2.8C52.9,84.8,52.9,81.6,52.1,81.8z"/>
		<path class="st41" d="M51.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,81.1c-0.8-0.2-0.8,3,0,2.8C49.9,84.1,49.9,80.9,49.1,81.1z"/>
		<path class="st41" d="M48.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,81.8c-0.8-0.2-0.8,3,0,2.8C51,84.8,51,81.6,50.1,81.8z"/>
		<path class="st41" d="M49.8,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,81.1c-0.8-0.2-0.8,3,0,2.8C47.9,84.1,47.9,80.9,47.1,81.1z"/>
		<path class="st41" d="M46.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,81.8c-0.8-0.2-0.8,3,0,2.8C49,84.8,49,81.6,48.1,81.8z"/>
		<path class="st41" d="M47.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,81.1c-0.8-0.2-0.8,3,0,2.8C45.9,84.1,45.9,80.9,45.1,81.1z"/>
		<path class="st41" d="M44.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,81.8c-0.8-0.2-0.8,3,0,2.8C47,84.8,47,81.6,46.2,81.8z"/>
		<path class="st41" d="M45.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,81.1c-0.8-0.2-0.8,3,0,2.8C43.9,84.1,43.9,80.9,43.1,81.1z"/>
		<path class="st41" d="M42.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,81.8c-0.8-0.2-0.8,3,0,2.8C45,84.8,45,81.6,44.2,81.8z"/>
		<path class="st41" d="M43.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,81.1c-0.8-0.2-0.8,3,0,2.8C42,84.1,42,80.9,41.1,81.1z"/>
		<path class="st41" d="M40.8,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,81.8c-0.8-0.2-0.8,3,0,2.8C43,84.8,43,81.6,42.2,81.8z"/>
		<path class="st41" d="M41.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,81.1c-0.8-0.2-0.8,3,0,2.8C40,84.1,40,80.9,39.1,81.1z"/>
		<path class="st41" d="M38.9,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,81.8c-0.8-0.2-0.8,3,0,2.8C41,84.8,41,81.6,40.2,81.8z"/>
		<path class="st41" d="M39.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,81.1c-0.8-0.2-0.8,3,0,2.8C38,84.1,38,80.9,37.2,81.1z"/>
		<path class="st41" d="M36.9,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,81.8c-0.8-0.2-0.8,3,0,2.8C39,84.8,39,81.6,38.2,81.8z"/>
		<path class="st41" d="M37.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,81.1c-0.8-0.2-0.8,3,0,2.8C36,84.1,36,80.9,35.2,81.1z"/>
		<path class="st41" d="M34.9,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,81.8c-0.8-0.2-0.8,3,0,2.8C37,84.8,37,81.6,36.2,81.8z"/>
		<path class="st41" d="M35.9,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,81.1c-0.8-0.2-0.8,3,0,2.8C34.1,84.1,34.1,80.9,33.3,81.1z"/>
		<path class="st41" d="M33,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,81.8c-0.8-0.2-0.8,3,0,2.8C35.2,84.8,35.2,81.6,34.3,81.8z"/>
		<path class="st41" d="M34.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,81.1c-0.8-0.2-0.8,3,0,2.8C32.1,84.1,32.1,80.9,31.3,81.1z"/>
		<path class="st41" d="M31,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,81.8c-0.8-0.2-0.8,3,0,2.8C33.2,84.8,33.2,81.6,32.4,81.8z"/>
		<path class="st41" d="M32.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,81.1c-0.8-0.2-0.8,3,0,2.8C30.1,84.1,30.2,80.9,29.3,81.1z"/>
		<path class="st41" d="M29,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,81.8c-0.8-0.2-0.8,3,0,2.8C31.2,84.8,31.2,81.6,30.4,81.8z"/>
		<path class="st41" d="M30.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,81.1c-0.8-0.2-0.8,3,0,2.8C28.2,84.1,28.2,80.9,27.3,81.1z"/>
		<path class="st41" d="M27,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,81.8c-0.8-0.2-0.8,3,0,2.8C29.2,84.8,29.2,81.6,28.4,81.8z"/>
		<path class="st41" d="M28.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,81.1c-0.8-0.2-0.8,3,0,2.8C26.2,84.1,26.2,80.9,25.3,81.1z"/>
		<path class="st41" d="M25.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,81.8c-0.8-0.2-0.8,3,0,2.8C27.2,84.8,27.2,81.6,26.4,81.8z"/>
		<path class="st41" d="M26.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,81.1c-0.8-0.2-0.8,3,0,2.8C24.2,84.1,24.2,80.9,23.4,81.1z"/>
		<path class="st41" d="M23.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,81.8c-0.8-0.2-0.8,3,0,2.8C25.2,84.8,25.2,81.6,24.4,81.8z"/>
		<path class="st41" d="M24.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,81.1c-0.8-0.2-0.8,3,0,2.8C22.2,84.1,22.2,80.9,21.4,81.1z"/>
		<path class="st41" d="M21.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,81.8c-0.8-0.2-0.8,3,0,2.8C23.3,84.8,23.3,81.6,22.4,81.8z"/>
		<path class="st41" d="M22.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,81.1c-0.8-0.2-0.8,3,0,2.8C20.2,84.1,20.2,80.9,19.4,81.1z"/>
		<path class="st41" d="M19.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,81.8c-0.8-0.2-0.8,3,0,2.8C21.3,84.8,21.3,81.6,20.4,81.8z"/>
		<path class="st41" d="M20.1,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,81.1c-0.8-0.2-0.8,3,0,2.8C18.2,84.1,18.2,80.9,17.4,81.1z"/>
		<path class="st41" d="M17.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,81.8c-0.8-0.2-0.8,3,0,2.8C19.3,84.8,19.3,81.6,18.4,81.8z"/>
		<path class="st41" d="M18.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,81.1c-0.8-0.2-0.8,3,0,2.8C16.2,84.1,16.2,80.9,15.4,81.1z"/>
		<path class="st41" d="M15.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,81.8c-0.8-0.2-0.8,3,0,2.8C17.3,84.8,17.3,81.6,16.5,81.8z"/>
		<path class="st41" d="M16.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,81.1c-0.8-0.2-0.8,3,0,2.8C14.3,84.1,14.3,80.9,13.4,81.1z"/>
		<path class="st41" d="M13.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,81.8c-0.8-0.2-0.8,3,0,2.8C15.3,84.8,15.3,81.6,14.5,81.8z"/>
		<path class="st41" d="M14.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,81.1c-0.8-0.2-0.8,3,0,2.8C12.3,84.1,12.3,80.9,11.4,81.1z"/>
		<path class="st41" d="M11.1,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,81.8c-0.8-0.2-0.8,3,0,2.8C13.3,84.8,13.3,81.6,12.5,81.8z"/>
		<path class="st41" d="M12.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,81.1c-0.8-0.2-0.8,3,0,2.8C10.3,84.1,10.3,80.9,9.5,81.1z"/>
		<path class="st41" d="M9.2,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,81.8c-0.8-0.2-0.8,3,0,2.8C11.3,84.8,11.3,81.6,10.5,81.8z"/>
		<path class="st41" d="M10.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,81.1c-0.8-0.2-0.8,3,0,2.8C8.3,84.1,8.3,80.9,7.5,81.1z"/>
		<path class="st41" d="M7.2,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,81.8c-0.8-0.2-0.8,3,0,2.8C9.3,84.8,9.3,81.6,8.5,81.8z"/>
		<path class="st41" d="M8.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,81.1c-0.8-0.2-0.8,3,0,2.8C6.3,84.1,6.3,80.9,5.5,81.1z"/>
		<path class="st41" d="M5.2,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,81.8c-0.8-0.2-0.8,3,0,2.8C7.4,84.8,7.4,81.6,6.5,81.8z"/>
		<path class="st41" d="M6.2,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,81.1c-0.8-0.2-0.8,3,0,2.8C4.3,84.1,4.3,80.9,3.5,81.1z"/>
		<path class="st41" d="M3.2,82c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,81.8c-0.8-0.2-0.8,3,0,2.8C5.4,84.8,5.4,81.6,4.5,81.8z"/>
		<path class="st41" d="M4.3,82.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,82.5c-0.8-0.2-0.8,3,0,2.8C65.8,85.5,65.8,82.4,65,82.5z"/>
		<path class="st41" d="M64.7,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,83.2c-0.8-0.2-0.8,3,0,2.8C66.9,86.2,66.9,83,66,83.2z"/>
		<path class="st41" d="M65.7,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,82.5c-0.8-0.2-0.8,3,0,2.8C63.8,85.5,63.8,82.4,63,82.5z"/>
		<path class="st41" d="M62.7,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,83.2c-0.8-0.2-0.8,3,0,2.8C64.9,86.2,64.9,83,64,83.2z"/>
		<path class="st41" d="M63.7,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,82.5c-0.8-0.2-0.8,3,0,2.8C61.8,85.5,61.8,82.4,61,82.5z"/>
		<path class="st41" d="M60.7,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,83.2c-0.8-0.2-0.8,3,0,2.8C62.9,86.2,62.9,83,62,83.2z"/>
		<path class="st41" d="M61.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,82.5c-0.8-0.2-0.8,3,0,2.8C59.8,85.5,59.8,82.4,59,82.5z"/>
		<path class="st41" d="M58.7,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,83.2c-0.8-0.2-0.8,3,0,2.8C60.9,86.2,60.9,83,60.1,83.2z"/>
		<path class="st41" d="M59.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,82.5c-0.8-0.2-0.8,3,0,2.8C57.9,85.5,57.9,82.4,57,82.5z"/>
		<path class="st41" d="M56.7,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,83.2c-0.8-0.2-0.8,3,0,2.8C58.9,86.2,58.9,83,58.1,83.2z"/>
		<path class="st41" d="M57.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,82.5c-0.8-0.2-0.8,3,0,2.8C55.9,85.5,55.9,82.4,55,82.5z"/>
		<path class="st41" d="M54.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,83.2c-0.8-0.2-0.8,3,0,2.8C56.9,86.2,56.9,83,56.1,83.2z"/>
		<path class="st41" d="M55.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,82.5c-0.8-0.2-0.8,3,0,2.8C53.9,85.5,53.9,82.4,53.1,82.5z"/>
		<path class="st41" d="M52.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,83.2c-0.8-0.2-0.8,3,0,2.8C54.9,86.2,54.9,83,54.1,83.2z"/>
		<path class="st41" d="M53.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,82.5c-0.8-0.2-0.8,3,0,2.8C51.9,85.5,51.9,82.4,51.1,82.5z"/>
		<path class="st41" d="M50.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,83.2c-0.8-0.2-0.8,3,0,2.8C52.9,86.2,52.9,83,52.1,83.2z"/>
		<path class="st41" d="M51.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,82.5c-0.8-0.2-0.8,3,0,2.8C49.9,85.5,49.9,82.4,49.1,82.5z"/>
		<path class="st41" d="M48.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,83.2c-0.8-0.2-0.8,3,0,2.8C51,86.2,51,83,50.1,83.2z"/>
		<path class="st41" d="M49.8,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,82.5c-0.8-0.2-0.8,3,0,2.8C47.9,85.5,47.9,82.4,47.1,82.5z"/>
		<path class="st41" d="M46.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,83.2c-0.8-0.2-0.8,3,0,2.8C49,86.2,49,83,48.1,83.2z"/>
		<path class="st41" d="M47.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,82.5c-0.8-0.2-0.8,3,0,2.8C45.9,85.5,45.9,82.4,45.1,82.5z"/>
		<path class="st41" d="M44.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,83.2c-0.8-0.2-0.8,3,0,2.8C47,86.2,47,83,46.2,83.2z"/>
		<path class="st41" d="M45.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,82.5c-0.8-0.2-0.8,3,0,2.8C43.9,85.5,43.9,82.4,43.1,82.5z"/>
		<path class="st41" d="M42.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,83.2c-0.8-0.2-0.8,3,0,2.8C45,86.2,45,83,44.2,83.2z"/>
		<path class="st41" d="M43.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,82.5c-0.8-0.2-0.8,3,0,2.8C42,85.5,42,82.4,41.1,82.5z"/>
		<path class="st41" d="M40.8,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,83.2c-0.8-0.2-0.8,3,0,2.8C43,86.2,43,83,42.2,83.2z"/>
		<path class="st41" d="M41.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,82.5c-0.8-0.2-0.8,3,0,2.8C40,85.5,40,82.4,39.1,82.5z"/>
		<path class="st41" d="M38.9,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,83.2c-0.8-0.2-0.8,3,0,2.8C41,86.2,41,83,40.2,83.2z"/>
		<path class="st41" d="M39.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,82.5c-0.8-0.2-0.8,3,0,2.8C38,85.5,38,82.4,37.2,82.5z"/>
		<path class="st41" d="M36.9,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,83.2c-0.8-0.2-0.8,3,0,2.8C39,86.2,39,83,38.2,83.2z"/>
		<path class="st41" d="M37.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,82.5c-0.8-0.2-0.8,3,0,2.8C36,85.5,36,82.4,35.2,82.5z"/>
		<path class="st41" d="M34.9,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,83.2c-0.8-0.2-0.8,3,0,2.8C37,86.2,37,83,36.2,83.2z"/>
		<path class="st41" d="M35.9,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,82.5c-0.8-0.2-0.8,3,0,2.8C34.1,85.5,34.1,82.4,33.3,82.5z"/>
		<path class="st41" d="M33,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,83.2c-0.8-0.2-0.8,3,0,2.8C35.2,86.2,35.2,83,34.3,83.2z"/>
		<path class="st41" d="M34.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,82.5c-0.8-0.2-0.8,3,0,2.8C32.1,85.5,32.1,82.4,31.3,82.5z"/>
		<path class="st41" d="M31,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,83.2c-0.8-0.2-0.8,3,0,2.8C33.2,86.2,33.2,83,32.4,83.2z"/>
		<path class="st41" d="M32.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,82.5c-0.8-0.2-0.8,3,0,2.8C30.1,85.5,30.2,82.4,29.3,82.5z"/>
		<path class="st41" d="M29,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,83.2c-0.8-0.2-0.8,3,0,2.8C31.2,86.2,31.2,83,30.4,83.2z"/>
		<path class="st41" d="M30.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,82.5c-0.8-0.2-0.8,3,0,2.8C28.2,85.5,28.2,82.4,27.3,82.5z"/>
		<path class="st41" d="M27,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,83.2c-0.8-0.2-0.8,3,0,2.8C29.2,86.2,29.2,83,28.4,83.2z"/>
		<path class="st41" d="M28.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,82.5c-0.8-0.2-0.8,3,0,2.8C26.2,85.5,26.2,82.4,25.3,82.5z"/>
		<path class="st41" d="M25.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,83.2c-0.8-0.2-0.8,3,0,2.8C27.2,86.2,27.2,83,26.4,83.2z"/>
		<path class="st41" d="M26.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,82.5c-0.8-0.2-0.8,3,0,2.8C24.2,85.5,24.2,82.4,23.4,82.5z"/>
		<path class="st41" d="M23.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,83.2c-0.8-0.2-0.8,3,0,2.8C25.2,86.2,25.2,83,24.4,83.2z"/>
		<path class="st41" d="M24.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,82.5c-0.8-0.2-0.8,3,0,2.8C22.2,85.5,22.2,82.4,21.4,82.5z"/>
		<path class="st41" d="M21.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,83.2c-0.8-0.2-0.8,3,0,2.8C23.3,86.2,23.3,83,22.4,83.2z"/>
		<path class="st41" d="M22.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,82.5c-0.8-0.2-0.8,3,0,2.8C20.2,85.5,20.2,82.4,19.4,82.5z"/>
		<path class="st41" d="M19.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,83.2c-0.8-0.2-0.8,3,0,2.8C21.3,86.2,21.3,83,20.4,83.2z"/>
		<path class="st41" d="M20.1,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,82.5c-0.8-0.2-0.8,3,0,2.8C18.2,85.5,18.2,82.4,17.4,82.5z"/>
		<path class="st41" d="M17.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,83.2c-0.8-0.2-0.8,3,0,2.8C19.3,86.2,19.3,83,18.4,83.2z"/>
		<path class="st41" d="M18.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,82.5c-0.8-0.2-0.8,3,0,2.8C16.2,85.5,16.2,82.4,15.4,82.5z"/>
		<path class="st41" d="M15.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,83.2c-0.8-0.2-0.8,3,0,2.8C17.3,86.2,17.3,83,16.5,83.2z"/>
		<path class="st41" d="M16.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,82.5c-0.8-0.2-0.8,3,0,2.8C14.3,85.5,14.3,82.4,13.4,82.5z"/>
		<path class="st41" d="M13.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,83.2c-0.8-0.2-0.8,3,0,2.8C15.3,86.2,15.3,83,14.5,83.2z"/>
		<path class="st41" d="M14.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,82.5c-0.8-0.2-0.8,3,0,2.8C12.3,85.5,12.3,82.4,11.4,82.5z"/>
		<path class="st41" d="M11.1,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,83.2c-0.8-0.2-0.8,3,0,2.8C13.3,86.2,13.3,83,12.5,83.2z"/>
		<path class="st41" d="M12.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,82.5c-0.8-0.2-0.8,3,0,2.8C10.3,85.5,10.3,82.4,9.5,82.5z"/>
		<path class="st41" d="M9.2,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,83.2c-0.8-0.2-0.8,3,0,2.8C11.3,86.2,11.3,83,10.5,83.2z"/>
		<path class="st41" d="M10.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,82.5c-0.8-0.2-0.8,3,0,2.8C8.3,85.5,8.3,82.4,7.5,82.5z"/>
		<path class="st41" d="M7.2,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,83.2c-0.8-0.2-0.8,3,0,2.8C9.3,86.2,9.3,83,8.5,83.2z"/>
		<path class="st41" d="M8.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,82.5c-0.8-0.2-0.8,3,0,2.8C6.3,85.5,6.3,82.4,5.5,82.5z"/>
		<path class="st41" d="M5.2,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,83.2c-0.8-0.2-0.8,3,0,2.8C7.4,86.2,7.4,83,6.5,83.2z"/>
		<path class="st41" d="M6.2,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,82.5c-0.8-0.2-0.8,3,0,2.8C4.3,85.5,4.3,82.4,3.5,82.5z"/>
		<path class="st41" d="M3.2,83.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,83.2c-0.8-0.2-0.8,3,0,2.8C5.4,86.2,5.4,83,4.5,83.2z"/>
		<path class="st41" d="M4.3,84.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,84.1c-0.8-0.2-0.8,3,0,2.8C65.8,87.1,65.8,84,65,84.1z"/>
		<path class="st41" d="M64.7,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,84.8c-0.8-0.2-0.8,3,0,2.8C66.9,87.8,66.9,84.6,66,84.8z"/>
		<path class="st41" d="M65.7,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,84.1c-0.8-0.2-0.8,3,0,2.8C63.8,87.1,63.8,84,63,84.1z"/>
		<path class="st41" d="M62.7,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,84.8c-0.8-0.2-0.8,3,0,2.8C64.9,87.8,64.9,84.6,64,84.8z"/>
		<path class="st41" d="M63.7,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,84.1c-0.8-0.2-0.8,3,0,2.8C61.8,87.1,61.8,84,61,84.1z"/>
		<path class="st41" d="M60.7,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,84.8c-0.8-0.2-0.8,3,0,2.8C62.9,87.8,62.9,84.6,62,84.8z"/>
		<path class="st41" d="M61.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,84.1c-0.8-0.2-0.8,3,0,2.8C59.8,87.1,59.8,84,59,84.1z"/>
		<path class="st41" d="M58.7,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,84.8c-0.8-0.2-0.8,3,0,2.8C60.9,87.8,60.9,84.6,60.1,84.8z"/>
		<path class="st41" d="M59.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,84.1c-0.8-0.2-0.8,3,0,2.8C57.9,87.1,57.9,84,57,84.1z"/>
		<path class="st41" d="M56.7,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,84.8c-0.8-0.2-0.8,3,0,2.8C58.9,87.8,58.9,84.6,58.1,84.8z"/>
		<path class="st41" d="M57.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,84.1c-0.8-0.2-0.8,3,0,2.8C55.9,87.1,55.9,84,55,84.1z"/>
		<path class="st41" d="M54.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,84.8c-0.8-0.2-0.8,3,0,2.8C56.9,87.8,56.9,84.6,56.1,84.8z"/>
		<path class="st41" d="M55.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,84.1c-0.8-0.2-0.8,3,0,2.8C53.9,87.1,53.9,84,53.1,84.1z"/>
		<path class="st41" d="M52.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,84.8c-0.8-0.2-0.8,3,0,2.8C54.9,87.8,54.9,84.6,54.1,84.8z"/>
		<path class="st41" d="M53.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,84.1c-0.8-0.2-0.8,3,0,2.8C51.9,87.1,51.9,84,51.1,84.1z"/>
		<path class="st41" d="M50.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,84.8c-0.8-0.2-0.8,3,0,2.8C52.9,87.8,52.9,84.6,52.1,84.8z"/>
		<path class="st41" d="M51.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,84.1c-0.8-0.2-0.8,3,0,2.8C49.9,87.1,49.9,84,49.1,84.1z"/>
		<path class="st41" d="M48.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,84.8c-0.8-0.2-0.8,3,0,2.8C51,87.8,51,84.6,50.1,84.8z"/>
		<path class="st41" d="M49.8,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,84.1c-0.8-0.2-0.8,3,0,2.8C47.9,87.1,47.9,84,47.1,84.1z"/>
		<path class="st41" d="M46.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,84.8c-0.8-0.2-0.8,3,0,2.8C49,87.8,49,84.6,48.1,84.8z"/>
		<path class="st41" d="M47.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,84.1c-0.8-0.2-0.8,3,0,2.8C45.9,87.1,45.9,84,45.1,84.1z"/>
		<path class="st41" d="M44.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,84.8c-0.8-0.2-0.8,3,0,2.8C47,87.8,47,84.6,46.2,84.8z"/>
		<path class="st41" d="M45.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,84.1c-0.8-0.2-0.8,3,0,2.8C43.9,87.1,43.9,84,43.1,84.1z"/>
		<path class="st41" d="M42.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,84.8c-0.8-0.2-0.8,3,0,2.8C45,87.8,45,84.6,44.2,84.8z"/>
		<path class="st41" d="M43.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,84.1c-0.8-0.2-0.8,3,0,2.8C42,87.1,42,84,41.1,84.1z"/>
		<path class="st41" d="M40.8,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,84.8c-0.8-0.2-0.8,3,0,2.8C43,87.8,43,84.6,42.2,84.8z"/>
		<path class="st41" d="M41.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,84.1c-0.8-0.2-0.8,3,0,2.8C40,87.1,40,84,39.1,84.1z"/>
		<path class="st41" d="M38.9,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,84.8c-0.8-0.2-0.8,3,0,2.8C41,87.8,41,84.6,40.2,84.8z"/>
		<path class="st41" d="M39.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,84.1c-0.8-0.2-0.8,3,0,2.8C38,87.1,38,84,37.2,84.1z"/>
		<path class="st41" d="M36.9,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,84.8c-0.8-0.2-0.8,3,0,2.8C39,87.8,39,84.6,38.2,84.8z"/>
		<path class="st41" d="M37.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,84.1c-0.8-0.2-0.8,3,0,2.8C36,87.1,36,84,35.2,84.1z"/>
		<path class="st41" d="M34.9,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,84.8c-0.8-0.2-0.8,3,0,2.8C37,87.8,37,84.6,36.2,84.8z"/>
		<path class="st41" d="M35.9,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,84.1c-0.8-0.2-0.8,3,0,2.8C34.1,87.1,34.1,84,33.3,84.1z"/>
		<path class="st41" d="M33,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,84.8c-0.8-0.2-0.8,3,0,2.8C35.2,87.8,35.2,84.6,34.3,84.8z"/>
		<path class="st41" d="M34.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,84.1c-0.8-0.2-0.8,3,0,2.8C32.1,87.1,32.1,84,31.3,84.1z"/>
		<path class="st41" d="M31,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,84.8c-0.8-0.2-0.8,3,0,2.8C33.2,87.8,33.2,84.6,32.4,84.8z"/>
		<path class="st41" d="M32.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,84.1c-0.8-0.2-0.8,3,0,2.8C30.1,87.1,30.2,84,29.3,84.1z"/>
		<path class="st41" d="M29,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,84.8c-0.8-0.2-0.8,3,0,2.8C31.2,87.8,31.2,84.6,30.4,84.8z"/>
		<path class="st41" d="M30.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,84.1c-0.8-0.2-0.8,3,0,2.8C28.2,87.1,28.2,84,27.3,84.1z"/>
		<path class="st41" d="M27,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,84.8c-0.8-0.2-0.8,3,0,2.8C29.2,87.8,29.2,84.6,28.4,84.8z"/>
		<path class="st41" d="M28.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,84.1c-0.8-0.2-0.8,3,0,2.8C26.2,87.1,26.2,84,25.3,84.1z"/>
		<path class="st41" d="M25.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,84.8c-0.8-0.2-0.8,3,0,2.8C27.2,87.8,27.2,84.6,26.4,84.8z"/>
		<path class="st41" d="M26.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,84.1c-0.8-0.2-0.8,3,0,2.8C24.2,87.1,24.2,84,23.4,84.1z"/>
		<path class="st41" d="M23.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,84.8c-0.8-0.2-0.8,3,0,2.8C25.2,87.8,25.2,84.6,24.4,84.8z"/>
		<path class="st41" d="M24.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,84.1c-0.8-0.2-0.8,3,0,2.8C22.2,87.1,22.2,84,21.4,84.1z"/>
		<path class="st41" d="M21.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,84.8c-0.8-0.2-0.8,3,0,2.8C23.3,87.8,23.3,84.6,22.4,84.8z"/>
		<path class="st41" d="M22.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,84.1c-0.8-0.2-0.8,3,0,2.8C20.2,87.1,20.2,84,19.4,84.1z"/>
		<path class="st41" d="M19.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,84.8c-0.8-0.2-0.8,3,0,2.8C21.3,87.8,21.3,84.6,20.4,84.8z"/>
		<path class="st41" d="M20.1,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,84.1c-0.8-0.2-0.8,3,0,2.8C18.2,87.1,18.2,84,17.4,84.1z"/>
		<path class="st41" d="M17.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,84.8c-0.8-0.2-0.8,3,0,2.8C19.3,87.8,19.3,84.6,18.4,84.8z"/>
		<path class="st41" d="M18.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,84.1c-0.8-0.2-0.8,3,0,2.8C16.2,87.1,16.2,84,15.4,84.1z"/>
		<path class="st41" d="M15.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,84.8c-0.8-0.2-0.8,3,0,2.8C17.3,87.8,17.3,84.6,16.5,84.8z"/>
		<path class="st41" d="M16.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,84.1c-0.8-0.2-0.8,3,0,2.8C14.3,87.1,14.3,84,13.4,84.1z"/>
		<path class="st41" d="M13.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,84.8c-0.8-0.2-0.8,3,0,2.8C15.3,87.8,15.3,84.6,14.5,84.8z"/>
		<path class="st41" d="M14.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,84.1c-0.8-0.2-0.8,3,0,2.8C12.3,87.1,12.3,84,11.4,84.1z"/>
		<path class="st41" d="M11.1,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,84.8c-0.8-0.2-0.8,3,0,2.8C13.3,87.8,13.3,84.6,12.5,84.8z"/>
		<path class="st41" d="M12.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,84.1c-0.8-0.2-0.8,3,0,2.8C10.3,87.1,10.3,84,9.5,84.1z"/>
		<path class="st41" d="M9.2,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,84.8c-0.8-0.2-0.8,3,0,2.8C11.3,87.8,11.3,84.6,10.5,84.8z"/>
		<path class="st41" d="M10.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,84.1c-0.8-0.2-0.8,3,0,2.8C8.3,87.1,8.3,84,7.5,84.1z"/>
		<path class="st41" d="M7.2,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,84.8c-0.8-0.2-0.8,3,0,2.8C9.3,87.8,9.3,84.6,8.5,84.8z"/>
		<path class="st41" d="M8.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,84.1c-0.8-0.2-0.8,3,0,2.8C6.3,87.1,6.3,84,5.5,84.1z"/>
		<path class="st41" d="M5.2,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,84.8c-0.8-0.2-0.8,3,0,2.8C7.4,87.8,7.4,84.6,6.5,84.8z"/>
		<path class="st41" d="M6.2,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,84.1c-0.8-0.2-0.8,3,0,2.8C4.3,87.1,4.3,84,3.5,84.1z"/>
		<path class="st41" d="M3.2,85c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,84.8c-0.8-0.2-0.8,3,0,2.8C5.4,87.8,5.4,84.6,4.5,84.8z"/>
		<path class="st41" d="M4.3,85.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,85.5c-0.8-0.2-0.8,3,0,2.8C65.8,88.6,65.8,85.4,65,85.5z"/>
		<path class="st41" d="M64.7,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,86.2c-0.8-0.2-0.8,3,0,2.8C66.9,89.2,66.9,86.1,66,86.2z"/>
		<path class="st41" d="M65.7,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,85.5c-0.8-0.2-0.8,3,0,2.8C63.8,88.6,63.8,85.4,63,85.5z"/>
		<path class="st41" d="M62.7,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,86.2c-0.8-0.2-0.8,3,0,2.8C64.9,89.2,64.9,86.1,64,86.2z"/>
		<path class="st41" d="M63.7,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,85.5c-0.8-0.2-0.8,3,0,2.8C61.8,88.6,61.8,85.4,61,85.5z"/>
		<path class="st41" d="M60.7,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,86.2c-0.8-0.2-0.8,3,0,2.8C62.9,89.2,62.9,86.1,62,86.2z"/>
		<path class="st41" d="M61.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,85.5c-0.8-0.2-0.8,3,0,2.8C59.8,88.6,59.8,85.4,59,85.5z"/>
		<path class="st41" d="M58.7,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,86.2c-0.8-0.2-0.8,3,0,2.8C60.9,89.2,60.9,86.1,60.1,86.2z"/>
		<path class="st41" d="M59.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,85.5c-0.8-0.2-0.8,3,0,2.8C57.9,88.6,57.9,85.4,57,85.5z"/>
		<path class="st41" d="M56.7,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,86.2c-0.8-0.2-0.8,3,0,2.8C58.9,89.2,58.9,86.1,58.1,86.2z"/>
		<path class="st41" d="M57.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,85.5c-0.8-0.2-0.8,3,0,2.8C55.9,88.6,55.9,85.4,55,85.5z"/>
		<path class="st41" d="M54.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,86.2c-0.8-0.2-0.8,3,0,2.8C56.9,89.2,56.9,86.1,56.1,86.2z"/>
		<path class="st41" d="M55.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,85.5c-0.8-0.2-0.8,3,0,2.8C53.9,88.6,53.9,85.4,53.1,85.5z"/>
		<path class="st41" d="M52.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,86.2c-0.8-0.2-0.8,3,0,2.8C54.9,89.2,54.9,86.1,54.1,86.2z"/>
		<path class="st41" d="M53.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,85.5c-0.8-0.2-0.8,3,0,2.8C51.9,88.6,51.9,85.4,51.1,85.5z"/>
		<path class="st41" d="M50.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,86.2c-0.8-0.2-0.8,3,0,2.8C52.9,89.2,52.9,86.1,52.1,86.2z"/>
		<path class="st41" d="M51.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,85.5c-0.8-0.2-0.8,3,0,2.8C49.9,88.6,49.9,85.4,49.1,85.5z"/>
		<path class="st41" d="M48.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,86.2c-0.8-0.2-0.8,3,0,2.8C51,89.2,51,86.1,50.1,86.2z"/>
		<path class="st41" d="M49.8,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,85.5c-0.8-0.2-0.8,3,0,2.8C47.9,88.6,47.9,85.4,47.1,85.5z"/>
		<path class="st41" d="M46.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,86.2c-0.8-0.2-0.8,3,0,2.8C49,89.2,49,86.1,48.1,86.2z"/>
		<path class="st41" d="M47.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,85.5c-0.8-0.2-0.8,3,0,2.8C45.9,88.6,45.9,85.4,45.1,85.5z"/>
		<path class="st41" d="M44.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,86.2c-0.8-0.2-0.8,3,0,2.8C47,89.2,47,86.1,46.2,86.2z"/>
		<path class="st41" d="M45.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,85.5c-0.8-0.2-0.8,3,0,2.8C43.9,88.6,43.9,85.4,43.1,85.5z"/>
		<path class="st41" d="M42.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,86.2c-0.8-0.2-0.8,3,0,2.8C45,89.2,45,86.1,44.2,86.2z"/>
		<path class="st41" d="M43.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,85.5c-0.8-0.2-0.8,3,0,2.8C42,88.6,42,85.4,41.1,85.5z"/>
		<path class="st41" d="M40.8,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,86.2c-0.8-0.2-0.8,3,0,2.8C43,89.2,43,86.1,42.2,86.2z"/>
		<path class="st41" d="M41.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,85.5c-0.8-0.2-0.8,3,0,2.8C40,88.6,40,85.4,39.1,85.5z"/>
		<path class="st41" d="M38.9,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,86.2c-0.8-0.2-0.8,3,0,2.8C41,89.2,41,86.1,40.2,86.2z"/>
		<path class="st41" d="M39.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,85.5c-0.8-0.2-0.8,3,0,2.8C38,88.6,38,85.4,37.2,85.5z"/>
		<path class="st41" d="M36.9,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,86.2c-0.8-0.2-0.8,3,0,2.8C39,89.2,39,86.1,38.2,86.2z"/>
		<path class="st41" d="M37.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,85.5c-0.8-0.2-0.8,3,0,2.8C36,88.6,36,85.4,35.2,85.5z"/>
		<path class="st41" d="M34.9,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,86.2c-0.8-0.2-0.8,3,0,2.8C37,89.2,37,86.1,36.2,86.2z"/>
		<path class="st41" d="M35.9,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,85.5c-0.8-0.2-0.8,3,0,2.8C34.1,88.6,34.1,85.4,33.3,85.5z"/>
		<path class="st41" d="M33,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,86.2c-0.8-0.2-0.8,3,0,2.8C35.2,89.2,35.2,86.1,34.3,86.2z"/>
		<path class="st41" d="M34.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,85.5c-0.8-0.2-0.8,3,0,2.8C32.1,88.6,32.1,85.4,31.3,85.5z"/>
		<path class="st41" d="M31,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,86.2c-0.8-0.2-0.8,3,0,2.8C33.2,89.2,33.2,86.1,32.4,86.2z"/>
		<path class="st41" d="M32.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,85.5c-0.8-0.2-0.8,3,0,2.8C30.1,88.6,30.2,85.4,29.3,85.5z"/>
		<path class="st41" d="M29,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,86.2c-0.8-0.2-0.8,3,0,2.8C31.2,89.2,31.2,86.1,30.4,86.2z"/>
		<path class="st41" d="M30.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,85.5c-0.8-0.2-0.8,3,0,2.8C28.2,88.6,28.2,85.4,27.3,85.5z"/>
		<path class="st41" d="M27,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,86.2c-0.8-0.2-0.8,3,0,2.8C29.2,89.2,29.2,86.1,28.4,86.2z"/>
		<path class="st41" d="M28.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,85.5c-0.8-0.2-0.8,3,0,2.8C26.2,88.6,26.2,85.4,25.3,85.5z"/>
		<path class="st41" d="M25.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,86.2c-0.8-0.2-0.8,3,0,2.8C27.2,89.2,27.2,86.1,26.4,86.2z"/>
		<path class="st41" d="M26.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,85.5c-0.8-0.2-0.8,3,0,2.8C24.2,88.6,24.2,85.4,23.4,85.5z"/>
		<path class="st41" d="M23.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,86.2c-0.8-0.2-0.8,3,0,2.8C25.2,89.2,25.2,86.1,24.4,86.2z"/>
		<path class="st41" d="M24.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,85.5c-0.8-0.2-0.8,3,0,2.8C22.2,88.6,22.2,85.4,21.4,85.5z"/>
		<path class="st41" d="M21.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,86.2c-0.8-0.2-0.8,3,0,2.8C23.3,89.2,23.3,86.1,22.4,86.2z"/>
		<path class="st41" d="M22.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,85.5c-0.8-0.2-0.8,3,0,2.8C20.2,88.6,20.2,85.4,19.4,85.5z"/>
		<path class="st41" d="M19.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,86.2c-0.8-0.2-0.8,3,0,2.8C21.3,89.2,21.3,86.1,20.4,86.2z"/>
		<path class="st41" d="M20.1,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,85.5c-0.8-0.2-0.8,3,0,2.8C18.2,88.6,18.2,85.4,17.4,85.5z"/>
		<path class="st41" d="M17.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,86.2c-0.8-0.2-0.8,3,0,2.8C19.3,89.2,19.3,86.1,18.4,86.2z"/>
		<path class="st41" d="M18.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,85.5c-0.8-0.2-0.8,3,0,2.8C16.2,88.6,16.2,85.4,15.4,85.5z"/>
		<path class="st41" d="M15.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,86.2c-0.8-0.2-0.8,3,0,2.8C17.3,89.2,17.3,86.1,16.5,86.2z"/>
		<path class="st41" d="M16.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,85.5c-0.8-0.2-0.8,3,0,2.8C14.3,88.6,14.3,85.4,13.4,85.5z"/>
		<path class="st41" d="M13.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,86.2c-0.8-0.2-0.8,3,0,2.8C15.3,89.2,15.3,86.1,14.5,86.2z"/>
		<path class="st41" d="M14.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,85.5c-0.8-0.2-0.8,3,0,2.8C12.3,88.6,12.3,85.4,11.4,85.5z"/>
		<path class="st41" d="M11.1,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,86.2c-0.8-0.2-0.8,3,0,2.8C13.3,89.2,13.3,86.1,12.5,86.2z"/>
		<path class="st41" d="M12.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,85.5c-0.8-0.2-0.8,3,0,2.8C10.3,88.6,10.3,85.4,9.5,85.5z"/>
		<path class="st41" d="M9.2,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,86.2c-0.8-0.2-0.8,3,0,2.8C11.3,89.2,11.3,86.1,10.5,86.2z"/>
		<path class="st41" d="M10.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,85.5c-0.8-0.2-0.8,3,0,2.8C8.3,88.6,8.3,85.4,7.5,85.5z"/>
		<path class="st41" d="M7.2,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,86.2c-0.8-0.2-0.8,3,0,2.8C9.3,89.2,9.3,86.1,8.5,86.2z"/>
		<path class="st41" d="M8.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,85.5c-0.8-0.2-0.8,3,0,2.8C6.3,88.6,6.3,85.4,5.5,85.5z"/>
		<path class="st41" d="M5.2,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,86.2c-0.8-0.2-0.8,3,0,2.8C7.4,89.2,7.4,86.1,6.5,86.2z"/>
		<path class="st41" d="M6.2,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,85.5c-0.8-0.2-0.8,3,0,2.8C4.3,88.6,4.3,85.4,3.5,85.5z"/>
		<path class="st41" d="M3.2,86.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,86.2c-0.8-0.2-0.8,3,0,2.8C5.4,89.2,5.4,86.1,4.5,86.2z"/>
		<path class="st41" d="M4.3,87.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,87.2c-0.8-0.2-0.8,3,0,2.8C65.8,90.2,65.8,87,65,87.2z"/>
		<path class="st41" d="M64.7,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,87.8c-0.8-0.2-0.8,3,0,2.8C66.9,90.8,66.9,87.7,66,87.8z"/>
		<path class="st41" d="M65.7,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,87.2c-0.8-0.2-0.8,3,0,2.8C63.8,90.2,63.8,87,63,87.2z"/>
		<path class="st41" d="M62.7,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,87.8c-0.8-0.2-0.8,3,0,2.8C64.9,90.8,64.9,87.7,64,87.8z"/>
		<path class="st41" d="M63.7,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,87.2c-0.8-0.2-0.8,3,0,2.8C61.8,90.2,61.8,87,61,87.2z"/>
		<path class="st41" d="M60.7,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,87.8c-0.8-0.2-0.8,3,0,2.8C62.9,90.8,62.9,87.7,62,87.8z"/>
		<path class="st41" d="M61.8,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,87.2c-0.8-0.2-0.8,3,0,2.8C59.8,90.2,59.8,87,59,87.2z"/>
		<path class="st41" d="M58.7,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,87.8c-0.8-0.2-0.8,3,0,2.8C60.9,90.8,60.9,87.7,60.1,87.8z"/>
		<path class="st41" d="M59.8,88.7C58.8,88.4,59,87,60,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,87.2c-0.8-0.2-0.8,3,0,2.8C57.9,90.2,57.9,87,57,87.2z"/>
		<path class="st41" d="M56.7,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,87.8c-0.8-0.2-0.8,3,0,2.8C58.9,90.8,58.9,87.7,58.1,87.8z"/>
		<path class="st41" d="M57.8,88.7C56.9,88.4,57,87,58,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,87.2c-0.8-0.2-0.8,3,0,2.8C55.9,90.2,55.9,87,55,87.2z"/>
		<path class="st41" d="M54.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,87.8c-0.8-0.2-0.8,3,0,2.8C56.9,90.8,56.9,87.7,56.1,87.8z"/>
		<path class="st41" d="M55.8,88.7C54.9,88.4,55,87,56,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,87.2c-0.8-0.2-0.8,3,0,2.8C53.9,90.2,53.9,87,53.1,87.2z"/>
		<path class="st41" d="M52.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,87.8c-0.8-0.2-0.8,3,0,2.8C54.9,90.8,54.9,87.7,54.1,87.8z"/>
		<path class="st41" d="M53.8,88.7C52.9,88.4,53,87,54,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,87.2c-0.8-0.2-0.8,3,0,2.8C51.9,90.2,51.9,87,51.1,87.2z"/>
		<path class="st41" d="M50.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,87.8c-0.8-0.2-0.8,3,0,2.8C52.9,90.8,52.9,87.7,52.1,87.8z"/>
		<path class="st41" d="M51.8,88.7C50.9,88.4,51,87,52,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,87.2c-0.8-0.2-0.8,3,0,2.8C49.9,90.2,49.9,87,49.1,87.2z"/>
		<path class="st41" d="M48.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,87.8c-0.8-0.2-0.8,3,0,2.8C51,90.8,51,87.7,50.1,87.8z"/>
		<path class="st41" d="M49.8,88.7C48.9,88.4,49,87,50,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,87.2c-0.8-0.2-0.8,3,0,2.8C47.9,90.2,47.9,87,47.1,87.2z"/>
		<path class="st41" d="M46.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,87.8c-0.8-0.2-0.8,3,0,2.8C49,90.8,49,87.7,48.1,87.8z"/>
		<path class="st41" d="M47.9,88.7C46.9,88.4,47,87,48,86.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,87.2c-0.8-0.2-0.8,3,0,2.8C45.9,90.2,45.9,87,45.1,87.2z"/>
		<path class="st41" d="M44.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,87.8c-0.8-0.2-0.8,3,0,2.8C47,90.8,47,87.7,46.2,87.8z"/>
		<path class="st41" d="M45.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,87.2c-0.8-0.2-0.8,3,0,2.8C43.9,90.2,43.9,87,43.1,87.2z"/>
		<path class="st41" d="M42.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,87.8c-0.8-0.2-0.8,3,0,2.8C45,90.8,45,87.7,44.2,87.8z"/>
		<path class="st41" d="M43.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,87.2c-0.8-0.2-0.8,3,0,2.8C42,90.2,42,87,41.1,87.2z"/>
		<path class="st41" d="M40.8,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,87.8c-0.8-0.2-0.8,3,0,2.8C43,90.8,43,87.7,42.2,87.8z"/>
		<path class="st41" d="M41.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,87.2c-0.8-0.2-0.8,3,0,2.8C40,90.2,40,87,39.1,87.2z"/>
		<path class="st41" d="M38.9,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,87.8c-0.8-0.2-0.8,3,0,2.8C41,90.8,41,87.7,40.2,87.8z"/>
		<path class="st41" d="M39.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,87.2c-0.8-0.2-0.8,3,0,2.8C38,90.2,38,87,37.2,87.2z"/>
		<path class="st41" d="M36.9,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,87.8c-0.8-0.2-0.8,3,0,2.8C39,90.8,39,87.7,38.2,87.8z"/>
		<path class="st41" d="M37.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,87.2c-0.8-0.2-0.8,3,0,2.8C36,90.2,36,87,35.2,87.2z"/>
		<path class="st41" d="M34.9,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,87.8c-0.8-0.2-0.8,3,0,2.8C37,90.8,37,87.7,36.2,87.8z"/>
		<path class="st41" d="M35.9,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,87.2c-0.8-0.2-0.8,3,0,2.8C34.1,90.2,34.1,87,33.3,87.2z"/>
		<path class="st41" d="M33,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,87.8c-0.8-0.2-0.8,3,0,2.8C35.2,90.8,35.2,87.7,34.3,87.8z"/>
		<path class="st41" d="M34.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,87.2c-0.8-0.2-0.8,3,0,2.8C32.1,90.2,32.1,87,31.3,87.2z"/>
		<path class="st41" d="M31,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,87.8c-0.8-0.2-0.8,3,0,2.8C33.2,90.8,33.2,87.7,32.4,87.8z"/>
		<path class="st41" d="M32.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,87.2c-0.8-0.2-0.8,3,0,2.8C30.1,90.2,30.2,87,29.3,87.2z"/>
		<path class="st41" d="M29,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,87.8c-0.8-0.2-0.8,3,0,2.8C31.2,90.8,31.2,87.7,30.4,87.8z"/>
		<path class="st41" d="M30.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,87.2c-0.8-0.2-0.8,3,0,2.8C28.2,90.2,28.2,87,27.3,87.2z"/>
		<path class="st41" d="M27,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,87.8c-0.8-0.2-0.8,3,0,2.8C29.2,90.8,29.2,87.7,28.4,87.8z"/>
		<path class="st41" d="M28.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,87.2c-0.8-0.2-0.8,3,0,2.8C26.2,90.2,26.2,87,25.3,87.2z"/>
		<path class="st41" d="M25.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,87.8c-0.8-0.2-0.8,3,0,2.8C27.2,90.8,27.2,87.7,26.4,87.8z"/>
		<path class="st41" d="M26.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,87.2c-0.8-0.2-0.8,3,0,2.8C24.2,90.2,24.2,87,23.4,87.2z"/>
		<path class="st41" d="M23.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,87.8c-0.8-0.2-0.8,3,0,2.8C25.2,90.8,25.2,87.7,24.4,87.8z"/>
		<path class="st41" d="M24.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,87.2c-0.8-0.2-0.8,3,0,2.8C22.2,90.2,22.2,87,21.4,87.2z"/>
		<path class="st41" d="M21.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,87.8c-0.8-0.2-0.8,3,0,2.8C23.3,90.8,23.3,87.7,22.4,87.8z"/>
		<path class="st41" d="M22.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,87.2c-0.8-0.2-0.8,3,0,2.8C20.2,90.2,20.2,87,19.4,87.2z"/>
		<path class="st41" d="M19.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,87.8c-0.8-0.2-0.8,3,0,2.8C21.3,90.8,21.3,87.7,20.4,87.8z"/>
		<path class="st41" d="M20.1,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,87.2c-0.8-0.2-0.8,3,0,2.8C18.2,90.2,18.2,87,17.4,87.2z"/>
		<path class="st41" d="M17.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,87.8c-0.8-0.2-0.8,3,0,2.8C19.3,90.8,19.3,87.7,18.4,87.8z"/>
		<path class="st41" d="M18.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,87.2c-0.8-0.2-0.8,3,0,2.8C16.2,90.2,16.2,87,15.4,87.2z"/>
		<path class="st41" d="M15.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,87.8c-0.8-0.2-0.8,3,0,2.8C17.3,90.8,17.3,87.7,16.5,87.8z"/>
		<path class="st41" d="M16.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,87.2c-0.8-0.2-0.8,3,0,2.8C14.3,90.2,14.3,87,13.4,87.2z"/>
		<path class="st41" d="M13.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,87.8c-0.8-0.2-0.8,3,0,2.8C15.3,90.8,15.3,87.7,14.5,87.8z"/>
		<path class="st41" d="M14.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,87.2c-0.8-0.2-0.8,3,0,2.8C12.3,90.2,12.3,87,11.4,87.2z"/>
		<path class="st41" d="M11.1,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,87.8c-0.8-0.2-0.8,3,0,2.8C13.3,90.8,13.3,87.7,12.5,87.8z"/>
		<path class="st41" d="M12.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,87.2c-0.8-0.2-0.8,3,0,2.8C10.3,90.2,10.3,87,9.5,87.2z"/>
		<path class="st41" d="M9.2,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,87.8c-0.8-0.2-0.8,3,0,2.8C11.3,90.8,11.3,87.7,10.5,87.8z"/>
		<path class="st41" d="M10.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,87.2c-0.8-0.2-0.8,3,0,2.8C8.3,90.2,8.3,87,7.5,87.2z"/>
		<path class="st41" d="M7.2,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,87.8c-0.8-0.2-0.8,3,0,2.8C9.3,90.8,9.3,87.7,8.5,87.8z"/>
		<path class="st41" d="M8.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,87.2c-0.8-0.2-0.8,3,0,2.8C6.3,90.2,6.3,87,5.5,87.2z"/>
		<path class="st41" d="M5.2,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,87.8c-0.8-0.2-0.8,3,0,2.8C7.4,90.8,7.4,87.7,6.5,87.8z"/>
		<path class="st41" d="M6.2,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,87.2c-0.8-0.2-0.8,3,0,2.8C4.3,90.2,4.3,87,3.5,87.2z"/>
		<path class="st41" d="M3.2,88c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,87.8c-0.8-0.2-0.8,3,0,2.8C5.4,90.8,5.4,87.7,4.5,87.8z"/>
		<path class="st41" d="M4.3,88.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M37.2,88.6c-0.8-0.2-0.8,3,0,2.8C38,91.6,38,88.4,37.2,88.6z"/>
		<path class="st41" d="M36.9,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M35.2,88.6c-0.8-0.2-0.8,3,0,2.8C36,91.6,36,88.4,35.2,88.6z"/>
		<path class="st41" d="M34.9,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M33.3,88.6c-0.8-0.2-0.8,3,0,2.8C34.1,91.6,34.1,88.4,33.3,88.6z"/>
		<path class="st41" d="M33,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,88.6c-0.8-0.2-0.8,3,0,2.8C30.1,91.6,30.2,88.4,29.3,88.6z"/>
		<path class="st41" d="M29,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,88.6c-0.8-0.2-0.8,3,0,2.8C28.2,91.6,28.2,88.4,27.3,88.6z"/>
		<path class="st41" d="M27,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,88.6c-0.8-0.2-0.8,3,0,2.8C26.2,91.6,26.2,88.4,25.3,88.6z"/>
		<path class="st41" d="M25.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,88.6c-0.8-0.2-0.8,3,0,2.8C24.2,91.6,24.2,88.4,23.4,88.6z"/>
		<path class="st41" d="M23.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,88.6c-0.8-0.2-0.8,3,0,2.8C22.2,91.6,22.2,88.4,21.4,88.6z"/>
		<path class="st41" d="M21.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,88.6c-0.8-0.2-0.8,3,0,2.8C20.2,91.6,20.2,88.4,19.4,88.6z"/>
		<path class="st41" d="M19.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,88.6c-0.8-0.2-0.8,3,0,2.8C18.2,91.6,18.2,88.4,17.4,88.6z"/>
		<path class="st41" d="M17.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,88.6c-0.8-0.2-0.8,3,0,2.8C16.2,91.6,16.2,88.4,15.4,88.6z"/>
		<path class="st41" d="M15.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,88.6c-0.8-0.2-0.8,3,0,2.8C14.3,91.6,14.3,88.4,13.4,88.6z"/>
		<path class="st41" d="M13.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,88.6c-0.8-0.2-0.8,3,0,2.8C12.3,91.6,12.3,88.4,11.4,88.6z"/>
		<path class="st41" d="M11.1,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M9.5,88.6c-0.8-0.2-0.8,3,0,2.8C10.3,91.6,10.3,88.4,9.5,88.6z"/>
		<path class="st42" d="M9.2,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.5,88.6c-0.8-0.2-0.8,3,0,2.8C8.3,91.6,8.3,88.4,7.5,88.6z"/>
		<path class="st42" d="M7.2,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,88.6c-0.8-0.2-0.8,3,0,2.8C6.3,91.6,6.3,88.4,5.5,88.6z"/>
		<path class="st42" d="M5.2,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,88.6c-0.8-0.2-0.8,3,0,2.8C4.3,91.6,4.3,88.4,3.5,88.6z"/>
		<path class="st42" d="M3.2,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M31.3,88.6c-0.8-0.2-0.8,3,0,2.8C32.1,91.6,32.1,88.4,31.3,88.6z"/>
		<path class="st41" d="M31,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,88.6c-0.8-0.2-0.8,3,0,2.8C65.8,91.6,65.8,88.4,65,88.6z"/>
		<path class="st41" d="M64.7,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,88.6c-0.8-0.2-0.8,3,0,2.8C63.8,91.6,63.8,88.4,63,88.6z"/>
		<path class="st41" d="M62.7,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,88.6c-0.8-0.2-0.8,3,0,2.8C61.8,91.6,61.8,88.4,61,88.6z"/>
		<path class="st41" d="M60.7,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,88.6c-0.8-0.2-0.8,3,0,2.8C59.8,91.6,59.8,88.4,59,88.6z"/>
		<path class="st41" d="M58.7,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,88.6c-0.8-0.2-0.8,3,0,2.8C57.9,91.6,57.9,88.4,57,88.6z"/>
		<path class="st41" d="M56.7,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,88.6c-0.8-0.2-0.8,3,0,2.8C55.9,91.6,55.9,88.4,55,88.6z"/>
		<path class="st41" d="M54.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,88.6c-0.8-0.2-0.8,3,0,2.8C53.9,91.6,53.9,88.4,53.1,88.6z"/>
		<path class="st41" d="M52.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,88.6c-0.8-0.2-0.8,3,0,2.8C51.9,91.6,51.9,88.4,51.1,88.6z"/>
		<path class="st41" d="M50.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,88.6c-0.8-0.2-0.8,3,0,2.8C49.9,91.6,49.9,88.4,49.1,88.6z"/>
		<path class="st41" d="M48.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,88.6c-0.8-0.2-0.8,3,0,2.8C47.9,91.6,47.9,88.4,47.1,88.6z"/>
		<path class="st41" d="M46.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,88.6c-0.8-0.2-0.8,3,0,2.8C45.9,91.6,45.9,88.4,45.1,88.6z"/>
		<path class="st41" d="M44.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,88.6c-0.8-0.2-0.8,3,0,2.8C43.9,91.6,43.9,88.4,43.1,88.6z"/>
		<path class="st41" d="M42.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,88.6c-0.8-0.2-0.8,3,0,2.8C42,91.6,42,88.4,41.1,88.6z"/>
		<path class="st41" d="M40.8,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,88.6c-0.8-0.2-0.8,3,0,2.8C40,91.6,40,88.4,39.1,88.6z"/>
		<path class="st41" d="M38.9,89.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M46.2,114.5c-0.8-0.2-0.8,3,0,2.8C47,117.5,47,114.3,46.2,114.5z"/>
		<path class="st40" d="M45.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,114.5c-0.8-0.2-0.8,3,0,2.8C45,117.5,45,114.3,44.2,114.5z"/>
		<path class="st40" d="M43.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,114.5c-0.8-0.2-0.8,3,0,2.8C41,117.5,41,114.3,40.2,114.5z"/>
		<path class="st40" d="M39.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,114.5c-0.8-0.2-0.8,3,0,2.8C39,117.5,39,114.3,38.2,114.5z"/>
		<path class="st40" d="M37.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,114.5c-0.8-0.2-0.8,3,0,2.8C37,117.5,37,114.3,36.2,114.5z"/>
		<path class="st40" d="M35.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,114.5c-0.8-0.2-0.8,3,0,2.8C35.2,117.5,35.2,114.3,34.3,114.5z"/>
		<path class="st40" d="M34.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,114.5c-0.8-0.2-0.8,3,0,2.8C33.2,117.5,33.2,114.3,32.4,114.5z"/>
		<path class="st40" d="M32.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,114.5c-0.8-0.2-0.8,3,0,2.8C31.2,117.5,31.2,114.3,30.4,114.5z"/>
		<path class="st40" d="M30.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,114.5c-0.8-0.2-0.8,3,0,2.8C29.2,117.5,29.2,114.3,28.4,114.5z"/>
		<path class="st40" d="M28.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,114.5c-0.8-0.2-0.8,3,0,2.8C27.2,117.5,27.2,114.3,26.4,114.5z"/>
		<path class="st40" d="M26.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,114.5c-0.8-0.2-0.8,3,0,2.8C25.2,117.5,25.2,114.3,24.4,114.5z"/>
		<path class="st40" d="M24.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,114.5c-0.8-0.2-0.8,3,0,2.8C23.3,117.5,23.3,114.3,22.4,114.5z"/>
		<path class="st40" d="M22.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,114.5c-0.8-0.2-0.8,3,0,2.8C21.3,117.5,21.3,114.3,20.4,114.5z"/>
		<path class="st40" d="M20.1,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,114.5c-0.8-0.2-0.8,3,0,2.8C19.3,117.5,19.3,114.3,18.4,114.5z"/>
		<path class="st40" d="M18.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,114.5c-0.8-0.2-0.8,3,0,2.8C17.3,117.5,17.3,114.3,16.5,114.5z"/>
		<path class="st40" d="M16.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,114.5c-0.8-0.2-0.8,3,0,2.8C15.3,117.5,15.3,114.3,14.5,114.5z"/>
		<path class="st40" d="M14.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,114.5c-0.8-0.2-0.8,3,0,2.8C13.3,117.5,13.3,114.3,12.5,114.5z"/>
		<path class="st40" d="M12.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,114.5c-0.8-0.2-0.8,3,0,2.8C11.3,117.5,11.3,114.3,10.5,114.5z"/>
		<path class="st40" d="M10.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,114.5c-0.8-0.2-0.8,3,0,2.8C9.3,117.5,9.3,114.3,8.5,114.5z"/>
		<path class="st40" d="M8.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,114.5c-0.8-0.2-0.8,3,0,2.8C7.4,117.5,7.4,114.3,6.5,114.5z"/>
		<path class="st40" d="M6.2,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,114.5c-0.8-0.2-0.8,3,0,2.8C5.4,117.5,5.4,114.3,4.5,114.5z"/>
		<path class="st40" d="M4.3,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,114.5c-0.8-0.2-0.8,3,0,2.8C43,117.5,43,114.3,42.2,114.5z"/>
		<path class="st40" d="M41.9,115.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,115.2c-0.8-0.2-0.8,3,0,2.8C65.8,118.2,65.8,115.1,65,115.2z"/>
		<path class="st41" d="M64.7,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,115.2c-0.8-0.2-0.8,3,0,2.8C63.8,118.2,63.8,115.1,63,115.2z"/>
		<path class="st41" d="M62.7,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,115.2c-0.8-0.2-0.8,3,0,2.8C61.8,118.2,61.8,115.1,61,115.2z"/>
		<path class="st41" d="M60.7,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,115.2c-0.8-0.2-0.8,3,0,2.8C59.8,118.2,59.8,115.1,59,115.2z"/>
		<path class="st41" d="M58.7,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,115.2c-0.8-0.2-0.8,3,0,2.8C57.9,118.2,57.9,115.1,57,115.2z"/>
		<path class="st41" d="M56.7,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,115.2c-0.8-0.2-0.8,3,0,2.8C55.9,118.2,55.9,115.1,55,115.2z"/>
		<path class="st41" d="M54.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,115.2c-0.8-0.2-0.8,3,0,2.8C53.9,118.2,53.9,115.1,53.1,115.2z"/>
		<path class="st41" d="M52.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,115.2c-0.8-0.2-0.8,3,0,2.8C51.9,118.2,51.9,115.1,51.1,115.2z"/>
		<path class="st41" d="M50.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,115.2c-0.8-0.2-0.8,3,0,2.8C49.9,118.2,49.9,115.1,49.1,115.2z"/>
		<path class="st41" d="M48.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,115.2c-0.8-0.2-0.8,3,0,2.8C47.9,118.2,47.9,115.1,47.1,115.2z"/>
		<path class="st41" d="M46.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,115.2c-0.8-0.2-0.8,3,0,2.8C45.9,118.2,45.9,115.1,45.1,115.2z"/>
		<path class="st41" d="M44.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,115.2c-0.8-0.2-0.8,3,0,2.8C43.9,118.2,43.9,115.1,43.1,115.2z"/>
		<path class="st41" d="M42.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,115.2c-0.8-0.2-0.8,3,0,2.8C42,118.2,42,115.1,41.1,115.2z"/>
		<path class="st41" d="M40.8,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,115.2c-0.8-0.2-0.8,3,0,2.8C40,118.2,40,115.1,39.1,115.2z"/>
		<path class="st41" d="M38.9,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,115.2c-0.8-0.2-0.8,3,0,2.8C38,118.2,38,115.1,37.2,115.2z"/>
		<path class="st41" d="M36.9,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,115.2c-0.8-0.2-0.8,3,0,2.8C36,118.2,36,115.1,35.2,115.2z"/>
		<path class="st41" d="M34.9,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,115.2c-0.8-0.2-0.8,3,0,2.8C34.1,118.2,34.1,115.1,33.3,115.2z"/>
		<path class="st41" d="M33,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,115.2c-0.8-0.2-0.8,3,0,2.8C32.1,118.2,32.1,115.1,31.3,115.2z"/>
		<path class="st41" d="M31,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,115.2c-0.8-0.2-0.8,3,0,2.8C30.1,118.2,30.2,115.1,29.3,115.2z"/>
		<path class="st41" d="M29,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,115.2c-0.8-0.2-0.8,3,0,2.8C28.2,118.2,28.2,115.1,27.3,115.2z"/>
		<path class="st41" d="M27,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,115.2c-0.8-0.2-0.8,3,0,2.8C26.2,118.2,26.2,115.1,25.3,115.2z"/>
		<path class="st41" d="M25.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,115.2c-0.8-0.2-0.8,3,0,2.8C24.2,118.2,24.2,115.1,23.4,115.2z"/>
		<path class="st41" d="M23.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,115.2c-0.8-0.2-0.8,3,0,2.8C22.2,118.2,22.2,115.1,21.4,115.2z"/>
		<path class="st41" d="M21.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,115.2c-0.8-0.2-0.8,3,0,2.8C20.2,118.2,20.2,115.1,19.4,115.2z"/>
		<path class="st41" d="M19.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,115.2c-0.8-0.2-0.8,3,0,2.8C18.2,118.2,18.2,115.1,17.4,115.2z"/>
		<path class="st41" d="M17.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,115.2c-0.8-0.2-0.8,3,0,2.8C16.2,118.2,16.2,115.1,15.4,115.2z"/>
		<path class="st41" d="M15.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,115.2c-0.8-0.2-0.8,3,0,2.8C14.3,118.2,14.3,115.1,13.4,115.2z"/>
		<path class="st41" d="M13.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,115.2c-0.8-0.2-0.8,3,0,2.8C12.3,118.2,12.3,115.1,11.4,115.2z"/>
		<path class="st41" d="M11.1,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,115.2c-0.8-0.2-0.8,3,0,2.8C10.3,118.2,10.3,115.1,9.5,115.2z"/>
		<path class="st41" d="M9.2,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.5,115.2c-0.8-0.2-0.8,3,0,2.8C8.3,118.2,8.3,115.1,7.5,115.2z"/>
		<path class="st42" d="M7.2,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,115.2c-0.8-0.2-0.8,3,0,2.8C6.3,118.2,6.3,115.1,5.5,115.2z"/>
		<path class="st42" d="M5.2,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,115.2c-0.8-0.2-0.8,3,0,2.8C4.3,118.2,4.3,115.1,3.5,115.2z"/>
		<path class="st42" d="M3.2,116.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M66,168.8c-0.8-0.2-0.8,3,0,2.8C66.9,171.8,66.9,168.7,66,168.8z"/>
		<path class="st41" d="M65.7,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,168.8c-0.8-0.2-0.8,3,0,2.8C64.9,171.8,64.9,168.7,64,168.8z"/>
		<path class="st41" d="M63.7,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,168.8c-0.8-0.2-0.8,3,0,2.8C62.9,171.8,62.9,168.7,62,168.8z"/>
		<path class="st41" d="M61.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,168.8c-0.8-0.2-0.8,3,0,2.8C60.9,171.8,60.9,168.7,60.1,168.8z"/>
		<path class="st41" d="M59.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,168.8c-0.8-0.2-0.8,3,0,2.8C58.9,171.8,58.9,168.7,58.1,168.8z"/>
		<path class="st41" d="M57.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,168.8c-0.8-0.2-0.8,3,0,2.8C56.9,171.8,56.9,168.7,56.1,168.8z"/>
		<path class="st41" d="M55.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,168.8c-0.8-0.2-0.8,3,0,2.8C54.9,171.8,54.9,168.7,54.1,168.8z"/>
		<path class="st41" d="M53.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,168.8c-0.8-0.2-0.8,3,0,2.8C52.9,171.8,52.9,168.7,52.1,168.8z"/>
		<path class="st41" d="M51.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,168.8c-0.8-0.2-0.8,3,0,2.8C51,171.8,51,168.7,50.1,168.8z"/>
		<path class="st41" d="M49.8,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,168.8c-0.8-0.2-0.8,3,0,2.8C49,171.8,49,168.7,48.1,168.8z"/>
		<path class="st41" d="M47.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,168.8c-0.8-0.2-0.8,3,0,2.8C47,171.8,47,168.7,46.2,168.8z"/>
		<path class="st41" d="M45.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,168.8c-0.8-0.2-0.8,3,0,2.8C45,171.8,45,168.7,44.2,168.8z"/>
		<path class="st41" d="M43.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,168.8c-0.8-0.2-0.8,3,0,2.8C43,171.8,43,168.7,42.2,168.8z"/>
		<path class="st41" d="M41.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,168.8c-0.8-0.2-0.8,3,0,2.8C41,171.8,41,168.7,40.2,168.8z"/>
		<path class="st41" d="M39.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,168.8c-0.8-0.2-0.8,3,0,2.8C39,171.8,39,168.7,38.2,168.8z"/>
		<path class="st41" d="M37.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,168.8c-0.8-0.2-0.8,3,0,2.8C37,171.8,37,168.7,36.2,168.8z"/>
		<path class="st41" d="M35.9,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,168.8c-0.8-0.2-0.8,3,0,2.8C35.2,171.8,35.2,168.7,34.3,168.8z"/>
		<path class="st41" d="M34.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,168.8c-0.8-0.2-0.8,3,0,2.8C33.2,171.8,33.2,168.7,32.4,168.8z"/>
		<path class="st41" d="M32.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,168.8c-0.8-0.2-0.8,3,0,2.8C31.2,171.8,31.2,168.7,30.4,168.8z"/>
		<path class="st41" d="M30.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,168.2c-0.8-0.2-0.8,3,0,2.8C28.2,171.2,28.2,168,27.3,168.2z"/>
		<path class="st41" d="M27,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,168.8c-0.8-0.2-0.8,3,0,2.8C29.2,171.8,29.2,168.7,28.4,168.8z"/>
		<path class="st41" d="M28.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,168.2c-0.8-0.2-0.8,3,0,2.8C26.2,171.2,26.2,168,25.3,168.2z"/>
		<path class="st41" d="M25.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,168.8c-0.8-0.2-0.8,3,0,2.8C27.2,171.8,27.2,168.7,26.4,168.8z"/>
		<path class="st41" d="M26.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,168.2c-0.8-0.2-0.8,3,0,2.8C24.2,171.2,24.2,168,23.4,168.2z"/>
		<path class="st41" d="M23.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,168.8c-0.8-0.2-0.8,3,0,2.8C25.2,171.8,25.2,168.7,24.4,168.8z"/>
		<path class="st41" d="M24.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,168.2c-0.8-0.2-0.8,3,0,2.8C22.2,171.2,22.2,168,21.4,168.2z"/>
		<path class="st41" d="M21.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,168.8c-0.8-0.2-0.8,3,0,2.8C23.3,171.8,23.3,168.7,22.4,168.8z"/>
		<path class="st41" d="M22.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,168.2c-0.8-0.2-0.8,3,0,2.8C20.2,171.2,20.2,168,19.4,168.2z"/>
		<path class="st41" d="M19.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,168.8c-0.8-0.2-0.8,3,0,2.8C21.3,171.8,21.3,168.7,20.4,168.8z"/>
		<path class="st41" d="M20.1,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,168.2c-0.8-0.2-0.8,3,0,2.8C18.2,171.2,18.2,168,17.4,168.2z"/>
		<path class="st41" d="M17.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,168.8c-0.8-0.2-0.8,3,0,2.8C19.3,171.8,19.3,168.7,18.4,168.8z"/>
		<path class="st41" d="M18.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,168.2c-0.8-0.2-0.8,3,0,2.8C16.2,171.2,16.2,168,15.4,168.2z"/>
		<path class="st41" d="M15.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,168.8c-0.8-0.2-0.8,3,0,2.8C17.3,171.8,17.3,168.7,16.5,168.8z"/>
		<path class="st41" d="M16.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,168.2c-0.8-0.2-0.8,3,0,2.8C14.3,171.2,14.3,168,13.4,168.2z"/>
		<path class="st41" d="M13.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,168.8c-0.8-0.2-0.8,3,0,2.8C15.3,171.8,15.3,168.7,14.5,168.8z"/>
		<path class="st41" d="M14.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,168.2c-0.8-0.2-0.8,3,0,2.8C12.3,171.2,12.3,168,11.4,168.2z"/>
		<path class="st41" d="M11.1,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,168.8c-0.8-0.2-0.8,3,0,2.8C13.3,171.8,13.3,168.7,12.5,168.8z"/>
		<path class="st41" d="M12.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,168.2c-0.8-0.2-0.8,3,0,2.8C10.3,171.2,10.3,168,9.5,168.2z"/>
		<path class="st41" d="M9.2,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,168.8c-0.8-0.2-0.8,3,0,2.8C11.3,171.8,11.3,168.7,10.5,168.8z"/>
		<path class="st41" d="M10.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,168.2c-0.8-0.2-0.8,3,0,2.8C8.3,171.2,8.3,168,7.5,168.2z"/>
		<path class="st41" d="M7.2,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,168.8c-0.8-0.2-0.8,3,0,2.8C9.3,171.8,9.3,168.7,8.5,168.8z"/>
		<path class="st41" d="M8.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,168.2c-0.8-0.2-0.8,3,0,2.8C6.3,171.2,6.3,168,5.5,168.2z"/>
		<path class="st41" d="M5.2,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,168.8c-0.8-0.2-0.8,3,0,2.8C7.4,171.8,7.4,168.7,6.5,168.8z"/>
		<path class="st41" d="M6.2,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,168.2c-0.8-0.2-0.8,3,0,2.8C4.3,171.2,4.3,168,3.5,168.2z"/>
		<path class="st41" d="M3.2,169.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,168.8c-0.8-0.2-0.8,3,0,2.8C5.4,171.8,5.4,168.7,4.5,168.8z"/>
		<path class="st41" d="M4.3,169.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,169.6c-0.8-0.2-0.8,3,0,2.8C65.8,172.6,65.8,169.4,65,169.6z"/>
		<path class="st41" d="M64.7,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,170.3c-0.8-0.2-0.8,3,0,2.8C66.9,173.3,66.9,170.1,66,170.3z"/>
		<path class="st41" d="M65.7,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,169.6c-0.8-0.2-0.8,3,0,2.8C63.8,172.6,63.8,169.4,63,169.6z"/>
		<path class="st41" d="M62.7,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,170.3c-0.8-0.2-0.8,3,0,2.8C64.9,173.3,64.9,170.1,64,170.3z"/>
		<path class="st41" d="M63.7,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,169.6c-0.8-0.2-0.8,3,0,2.8C61.8,172.6,61.8,169.4,61,169.6z"/>
		<path class="st41" d="M60.7,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,170.3c-0.8-0.2-0.8,3,0,2.8C62.9,173.3,62.9,170.1,62,170.3z"/>
		<path class="st41" d="M61.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,169.6c-0.8-0.2-0.8,3,0,2.8C59.8,172.6,59.8,169.4,59,169.6z"/>
		<path class="st41" d="M58.7,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,170.3c-0.8-0.2-0.8,3,0,2.8C60.9,173.3,60.9,170.1,60.1,170.3z"/>
		<path class="st41" d="M59.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,169.6c-0.8-0.2-0.8,3,0,2.8C57.9,172.6,57.9,169.4,57,169.6z"/>
		<path class="st41" d="M56.7,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,170.3c-0.8-0.2-0.8,3,0,2.8C58.9,173.3,58.9,170.1,58.1,170.3z"/>
		<path class="st41" d="M57.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,169.6c-0.8-0.2-0.8,3,0,2.8C55.9,172.6,55.9,169.4,55,169.6z"/>
		<path class="st41" d="M54.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,170.3c-0.8-0.2-0.8,3,0,2.8C56.9,173.3,56.9,170.1,56.1,170.3z"/>
		<path class="st41" d="M55.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,169.6c-0.8-0.2-0.8,3,0,2.8C53.9,172.6,53.9,169.4,53.1,169.6z"/>
		<path class="st41" d="M52.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,170.3c-0.8-0.2-0.8,3,0,2.8C54.9,173.3,54.9,170.1,54.1,170.3z"/>
		<path class="st41" d="M53.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,169.6c-0.8-0.2-0.8,3,0,2.8C51.9,172.6,51.9,169.4,51.1,169.6z"/>
		<path class="st41" d="M50.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,170.3c-0.8-0.2-0.8,3,0,2.8C52.9,173.3,52.9,170.1,52.1,170.3z"/>
		<path class="st41" d="M51.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,169.6c-0.8-0.2-0.8,3,0,2.8C49.9,172.6,49.9,169.4,49.1,169.6z"/>
		<path class="st41" d="M48.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,170.3c-0.8-0.2-0.8,3,0,2.8C51,173.3,51,170.1,50.1,170.3z"/>
		<path class="st41" d="M49.8,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,169.6c-0.8-0.2-0.8,3,0,2.8C47.9,172.6,47.9,169.4,47.1,169.6z"/>
		<path class="st41" d="M46.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,170.3c-0.8-0.2-0.8,3,0,2.8C49,173.3,49,170.1,48.1,170.3z"/>
		<path class="st41" d="M47.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,169.6c-0.8-0.2-0.8,3,0,2.8C45.9,172.6,45.9,169.4,45.1,169.6z"/>
		<path class="st41" d="M44.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,170.3c-0.8-0.2-0.8,3,0,2.8C47,173.3,47,170.1,46.2,170.3z"/>
		<path class="st41" d="M45.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,169.6c-0.8-0.2-0.8,3,0,2.8C43.9,172.6,43.9,169.4,43.1,169.6z"/>
		<path class="st41" d="M42.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,170.3c-0.8-0.2-0.8,3,0,2.8C45,173.3,45,170.1,44.2,170.3z"/>
		<path class="st41" d="M43.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,169.6c-0.8-0.2-0.8,3,0,2.8C42,172.6,42,169.4,41.1,169.6z"/>
		<path class="st41" d="M40.8,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,170.3c-0.8-0.2-0.8,3,0,2.8C43,173.3,43,170.1,42.2,170.3z"/>
		<path class="st41" d="M41.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,169.6c-0.8-0.2-0.8,3,0,2.8C40,172.6,40,169.4,39.1,169.6z"/>
		<path class="st41" d="M38.9,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,170.3c-0.8-0.2-0.8,3,0,2.8C41,173.3,41,170.1,40.2,170.3z"/>
		<path class="st41" d="M39.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,169.6c-0.8-0.2-0.8,3,0,2.8C38,172.6,38,169.4,37.2,169.6z"/>
		<path class="st41" d="M36.9,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,170.3c-0.8-0.2-0.8,3,0,2.8C39,173.3,39,170.1,38.2,170.3z"/>
		<path class="st41" d="M37.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,169.6c-0.8-0.2-0.8,3,0,2.8C36,172.6,36,169.4,35.2,169.6z"/>
		<path class="st41" d="M34.9,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,170.3c-0.8-0.2-0.8,3,0,2.8C37,173.3,37,170.1,36.2,170.3z"/>
		<path class="st41" d="M35.9,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,169.6c-0.8-0.2-0.8,3,0,2.8C34.1,172.6,34.1,169.4,33.3,169.6z"/>
		<path class="st41" d="M33,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,170.3c-0.8-0.2-0.8,3,0,2.8C35.2,173.3,35.2,170.1,34.3,170.3z"/>
		<path class="st41" d="M34.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,169.6c-0.8-0.2-0.8,3,0,2.8C32.1,172.6,32.1,169.4,31.3,169.6z"/>
		<path class="st41" d="M31,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,170.3c-0.8-0.2-0.8,3,0,2.8C33.2,173.3,33.2,170.1,32.4,170.3z"/>
		<path class="st41" d="M32.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,169.6c-0.8-0.2-0.8,3,0,2.8C30.1,172.6,30.2,169.4,29.3,169.6z"/>
		<path class="st41" d="M29,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,170.3c-0.8-0.2-0.8,3,0,2.8C31.2,173.3,31.2,170.1,30.4,170.3z"/>
		<path class="st41" d="M30.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,169.6c-0.8-0.2-0.8,3,0,2.8C28.2,172.6,28.2,169.4,27.3,169.6z"/>
		<path class="st41" d="M27,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,170.3c-0.8-0.2-0.8,3,0,2.8C29.2,173.3,29.2,170.1,28.4,170.3z"/>
		<path class="st41" d="M28.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,169.6c-0.8-0.2-0.8,3,0,2.8C26.2,172.6,26.2,169.4,25.3,169.6z"/>
		<path class="st41" d="M25.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,170.3c-0.8-0.2-0.8,3,0,2.8C27.2,173.3,27.2,170.1,26.4,170.3z"/>
		<path class="st41" d="M26.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,169.6c-0.8-0.2-0.8,3,0,2.8C24.2,172.6,24.2,169.4,23.4,169.6z"/>
		<path class="st41" d="M23.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,170.3c-0.8-0.2-0.8,3,0,2.8C25.2,173.3,25.2,170.1,24.4,170.3z"/>
		<path class="st41" d="M24.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,169.6c-0.8-0.2-0.8,3,0,2.8C22.2,172.6,22.2,169.4,21.4,169.6z"/>
		<path class="st41" d="M21.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,170.3c-0.8-0.2-0.8,3,0,2.8C23.3,173.3,23.3,170.1,22.4,170.3z"/>
		<path class="st41" d="M22.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,169.6c-0.8-0.2-0.8,3,0,2.8C20.2,172.6,20.2,169.4,19.4,169.6z"/>
		<path class="st41" d="M19.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,170.3c-0.8-0.2-0.8,3,0,2.8C21.3,173.3,21.3,170.1,20.4,170.3z"/>
		<path class="st41" d="M20.1,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,169.6c-0.8-0.2-0.8,3,0,2.8C18.2,172.6,18.2,169.4,17.4,169.6z"/>
		<path class="st41" d="M17.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,170.3c-0.8-0.2-0.8,3,0,2.8C19.3,173.3,19.3,170.1,18.4,170.3z"/>
		<path class="st41" d="M18.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,169.6c-0.8-0.2-0.8,3,0,2.8C16.2,172.6,16.2,169.4,15.4,169.6z"/>
		<path class="st41" d="M15.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,170.3c-0.8-0.2-0.8,3,0,2.8C17.3,173.3,17.3,170.1,16.5,170.3z"/>
		<path class="st41" d="M16.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,169.6c-0.8-0.2-0.8,3,0,2.8C14.3,172.6,14.3,169.4,13.4,169.6z"/>
		<path class="st41" d="M13.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,170.3c-0.8-0.2-0.8,3,0,2.8C15.3,173.3,15.3,170.1,14.5,170.3z"/>
		<path class="st41" d="M14.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,169.6c-0.8-0.2-0.8,3,0,2.8C12.3,172.6,12.3,169.4,11.4,169.6z"/>
		<path class="st41" d="M11.1,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,170.3c-0.8-0.2-0.8,3,0,2.8C13.3,173.3,13.3,170.1,12.5,170.3z"/>
		<path class="st41" d="M12.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,169.6c-0.8-0.2-0.8,3,0,2.8C10.3,172.6,10.3,169.4,9.5,169.6z"/>
		<path class="st41" d="M9.2,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,170.3c-0.8-0.2-0.8,3,0,2.8C11.3,173.3,11.3,170.1,10.5,170.3z"/>
		<path class="st41" d="M10.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,169.6c-0.8-0.2-0.8,3,0,2.8C8.3,172.6,8.3,169.4,7.5,169.6z"/>
		<path class="st41" d="M7.2,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,170.3c-0.8-0.2-0.8,3,0,2.8C9.3,173.3,9.3,170.1,8.5,170.3z"/>
		<path class="st41" d="M8.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,169.6c-0.8-0.2-0.8,3,0,2.8C6.3,172.6,6.3,169.4,5.5,169.6z"/>
		<path class="st41" d="M5.2,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,170.3c-0.8-0.2-0.8,3,0,2.8C7.4,173.3,7.4,170.1,6.5,170.3z"/>
		<path class="st41" d="M6.2,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,169.6c-0.8-0.2-0.8,3,0,2.8C4.3,172.6,4.3,169.4,3.5,169.6z"/>
		<path class="st41" d="M3.2,170.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,170.3c-0.8-0.2-0.8,3,0,2.8C5.4,173.3,5.4,170.1,4.5,170.3z"/>
		<path class="st41" d="M4.3,171.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,171c-0.8-0.2-0.8,3,0,2.8C65.8,174,65.8,170.8,65,171z"/>
		<path class="st41" d="M64.7,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,171.7c-0.8-0.2-0.8,3,0,2.8C66.9,174.7,66.9,171.5,66,171.7z"/>
		<path class="st41" d="M65.7,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,171c-0.8-0.2-0.8,3,0,2.8C63.8,174,63.8,170.8,63,171z"/>
		<path class="st41" d="M62.7,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,171.7c-0.8-0.2-0.8,3,0,2.8C64.9,174.7,64.9,171.5,64,171.7z"/>
		<path class="st41" d="M63.7,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,171c-0.8-0.2-0.8,3,0,2.8C61.8,174,61.8,170.8,61,171z"/>
		<path class="st41" d="M60.7,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,171.7c-0.8-0.2-0.8,3,0,2.8C62.9,174.7,62.9,171.5,62,171.7z"/>
		<path class="st41" d="M61.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,171c-0.8-0.2-0.8,3,0,2.8C59.8,174,59.8,170.8,59,171z"/>
		<path class="st41" d="M58.7,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,171.7c-0.8-0.2-0.8,3,0,2.8C60.9,174.7,60.9,171.5,60.1,171.7z"/>
		<path class="st41" d="M59.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,171c-0.8-0.2-0.8,3,0,2.8C57.9,174,57.9,170.8,57,171z"/>
		<path class="st41" d="M56.7,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,171.7c-0.8-0.2-0.8,3,0,2.8C58.9,174.7,58.9,171.5,58.1,171.7z"/>
		<path class="st41" d="M57.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,171c-0.8-0.2-0.8,3,0,2.8C55.9,174,55.9,170.8,55,171z"/>
		<path class="st41" d="M54.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,171.7c-0.8-0.2-0.8,3,0,2.8C56.9,174.7,56.9,171.5,56.1,171.7z"/>
		<path class="st41" d="M55.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,171c-0.8-0.2-0.8,3,0,2.8C53.9,174,53.9,170.8,53.1,171z"/>
		<path class="st41" d="M52.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,171.7c-0.8-0.2-0.8,3,0,2.8C54.9,174.7,54.9,171.5,54.1,171.7z"/>
		<path class="st41" d="M53.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,171c-0.8-0.2-0.8,3,0,2.8C51.9,174,51.9,170.8,51.1,171z"/>
		<path class="st41" d="M50.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,171.7c-0.8-0.2-0.8,3,0,2.8C52.9,174.7,52.9,171.5,52.1,171.7z"/>
		<path class="st41" d="M51.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,171c-0.8-0.2-0.8,3,0,2.8C49.9,174,49.9,170.8,49.1,171z"/>
		<path class="st41" d="M48.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,171.7c-0.8-0.2-0.8,3,0,2.8C51,174.7,51,171.5,50.1,171.7z"/>
		<path class="st41" d="M49.8,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,171c-0.8-0.2-0.8,3,0,2.8C47.9,174,47.9,170.8,47.1,171z"/>
		<path class="st41" d="M46.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,171.7c-0.8-0.2-0.8,3,0,2.8C49,174.7,49,171.5,48.1,171.7z"/>
		<path class="st41" d="M47.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,171c-0.8-0.2-0.8,3,0,2.8C45.9,174,45.9,170.8,45.1,171z"/>
		<path class="st41" d="M44.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,171.7c-0.8-0.2-0.8,3,0,2.8C47,174.7,47,171.5,46.2,171.7z"/>
		<path class="st41" d="M45.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,171c-0.8-0.2-0.8,3,0,2.8C43.9,174,43.9,170.8,43.1,171z"/>
		<path class="st41" d="M42.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,171.7c-0.8-0.2-0.8,3,0,2.8C45,174.7,45,171.5,44.2,171.7z"/>
		<path class="st41" d="M43.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,171c-0.8-0.2-0.8,3,0,2.8C42,174,42,170.8,41.1,171z"/>
		<path class="st41" d="M40.8,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,171.7c-0.8-0.2-0.8,3,0,2.8C43,174.7,43,171.5,42.2,171.7z"/>
		<path class="st41" d="M41.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,171c-0.8-0.2-0.8,3,0,2.8C40,174,40,170.8,39.1,171z"/>
		<path class="st41" d="M38.9,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,171.7c-0.8-0.2-0.8,3,0,2.8C41,174.7,41,171.5,40.2,171.7z"/>
		<path class="st41" d="M39.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,171c-0.8-0.2-0.8,3,0,2.8C38,174,38,170.8,37.2,171z"/>
		<path class="st41" d="M36.9,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,171.7c-0.8-0.2-0.8,3,0,2.8C39,174.7,39,171.5,38.2,171.7z"/>
		<path class="st41" d="M37.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,171c-0.8-0.2-0.8,3,0,2.8C36,174,36,170.8,35.2,171z"/>
		<path class="st41" d="M34.9,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,171.7c-0.8-0.2-0.8,3,0,2.8C37,174.7,37,171.5,36.2,171.7z"/>
		<path class="st41" d="M35.9,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,171c-0.8-0.2-0.8,3,0,2.8C34.1,174,34.1,170.8,33.3,171z"/>
		<path class="st41" d="M33,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,171.7c-0.8-0.2-0.8,3,0,2.8C35.2,174.7,35.2,171.5,34.3,171.7z"/>
		<path class="st41" d="M34.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,171c-0.8-0.2-0.8,3,0,2.8C32.1,174,32.1,170.8,31.3,171z"/>
		<path class="st41" d="M31,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,171.7c-0.8-0.2-0.8,3,0,2.8C33.2,174.7,33.2,171.5,32.4,171.7z"/>
		<path class="st41" d="M32.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,171c-0.8-0.2-0.8,3,0,2.8C30.1,174,30.2,170.8,29.3,171z"/>
		<path class="st41" d="M29,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,171.7c-0.8-0.2-0.8,3,0,2.8C31.2,174.7,31.2,171.5,30.4,171.7z"/>
		<path class="st41" d="M30.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,171c-0.8-0.2-0.8,3,0,2.8C28.2,174,28.2,170.8,27.3,171z"/>
		<path class="st41" d="M27,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,171.7c-0.8-0.2-0.8,3,0,2.8C29.2,174.7,29.2,171.5,28.4,171.7z"/>
		<path class="st41" d="M28.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,171c-0.8-0.2-0.8,3,0,2.8C26.2,174,26.2,170.8,25.3,171z"/>
		<path class="st41" d="M25.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,171.7c-0.8-0.2-0.8,3,0,2.8C27.2,174.7,27.2,171.5,26.4,171.7z"/>
		<path class="st41" d="M26.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,171c-0.8-0.2-0.8,3,0,2.8C24.2,174,24.2,170.8,23.4,171z"/>
		<path class="st41" d="M23.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,171.7c-0.8-0.2-0.8,3,0,2.8C25.2,174.7,25.2,171.5,24.4,171.7z"/>
		<path class="st41" d="M24.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,171c-0.8-0.2-0.8,3,0,2.8C22.2,174,22.2,170.8,21.4,171z"/>
		<path class="st41" d="M21.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,171.7c-0.8-0.2-0.8,3,0,2.8C23.3,174.7,23.3,171.5,22.4,171.7z"/>
		<path class="st41" d="M22.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,171c-0.8-0.2-0.8,3,0,2.8C20.2,174,20.2,170.8,19.4,171z"/>
		<path class="st41" d="M19.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,171.7c-0.8-0.2-0.8,3,0,2.8C21.3,174.7,21.3,171.5,20.4,171.7z"/>
		<path class="st41" d="M20.1,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,171c-0.8-0.2-0.8,3,0,2.8C18.2,174,18.2,170.8,17.4,171z"/>
		<path class="st41" d="M17.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,171.7c-0.8-0.2-0.8,3,0,2.8C19.3,174.7,19.3,171.5,18.4,171.7z"/>
		<path class="st41" d="M18.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,171c-0.8-0.2-0.8,3,0,2.8C16.2,174,16.2,170.8,15.4,171z"/>
		<path class="st41" d="M15.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,171.7c-0.8-0.2-0.8,3,0,2.8C17.3,174.7,17.3,171.5,16.5,171.7z"/>
		<path class="st41" d="M16.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,171c-0.8-0.2-0.8,3,0,2.8C14.3,174,14.3,170.8,13.4,171z"/>
		<path class="st41" d="M13.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,171.7c-0.8-0.2-0.8,3,0,2.8C15.3,174.7,15.3,171.5,14.5,171.7z"/>
		<path class="st41" d="M14.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,171c-0.8-0.2-0.8,3,0,2.8C12.3,174,12.3,170.8,11.4,171z"/>
		<path class="st41" d="M11.1,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,171.7c-0.8-0.2-0.8,3,0,2.8C13.3,174.7,13.3,171.5,12.5,171.7z"/>
		<path class="st41" d="M12.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,171c-0.8-0.2-0.8,3,0,2.8C10.3,174,10.3,170.8,9.5,171z"/>
		<path class="st41" d="M9.2,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,171.7c-0.8-0.2-0.8,3,0,2.8C11.3,174.7,11.3,171.5,10.5,171.7z"/>
		<path class="st41" d="M10.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,171c-0.8-0.2-0.8,3,0,2.8C8.3,174,8.3,170.8,7.5,171z"/>
		<path class="st41" d="M7.2,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,171.7c-0.8-0.2-0.8,3,0,2.8C9.3,174.7,9.3,171.5,8.5,171.7z"/>
		<path class="st41" d="M8.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,171c-0.8-0.2-0.8,3,0,2.8C6.3,174,6.3,170.8,5.5,171z"/>
		<path class="st41" d="M5.2,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,171.7c-0.8-0.2-0.8,3,0,2.8C7.4,174.7,7.4,171.5,6.5,171.7z"/>
		<path class="st41" d="M6.2,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,171c-0.8-0.2-0.8,3,0,2.8C4.3,174,4.3,170.8,3.5,171z"/>
		<path class="st41" d="M3.2,171.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,171.7c-0.8-0.2-0.8,3,0,2.8C5.4,174.7,5.4,171.5,4.5,171.7z"/>
		<path class="st41" d="M4.3,172.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M27.3,172.4c-0.8-0.2-0.8,3,0,2.8C28.2,175.4,28.2,172.2,27.3,172.4z"/>
		<path class="st41" d="M27,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,172.4c-0.8-0.2-0.8,3,0,2.8C24.2,175.4,24.2,172.2,23.4,172.4z"/>
		<path class="st41" d="M23.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,172.4c-0.8-0.2-0.8,3,0,2.8C22.2,175.4,22.2,172.2,21.4,172.4z"/>
		<path class="st41" d="M21.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,172.4c-0.8-0.2-0.8,3,0,2.8C20.2,175.4,20.2,172.2,19.4,172.4z"/>
		<path class="st41" d="M19.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,172.4c-0.8-0.2-0.8,3,0,2.8C18.2,175.4,18.2,172.2,17.4,172.4z"/>
		<path class="st41" d="M17.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,172.4c-0.8-0.2-0.8,3,0,2.8C16.2,175.4,16.2,172.2,15.4,172.4z"/>
		<path class="st41" d="M15.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,172.4c-0.8-0.2-0.8,3,0,2.8C14.3,175.4,14.3,172.2,13.4,172.4z"/>
		<path class="st41" d="M13.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,172.4c-0.8-0.2-0.8,3,0,2.8C12.3,175.4,12.3,172.2,11.4,172.4z"/>
		<path class="st41" d="M11.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,172.4c-0.8-0.2-0.8,3,0,2.8C10.3,175.4,10.3,172.2,9.5,172.4z"/>
		<path class="st41" d="M9.2,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,172.4c-0.8-0.2-0.8,3,0,2.8C8.3,175.4,8.3,172.2,7.5,172.4z"/>
		<path class="st41" d="M7.2,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,172.4c-0.8-0.2-0.8,3,0,2.8C6.3,175.4,6.3,172.2,5.5,172.4z"/>
		<path class="st42" d="M5.2,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,172.4c-0.8-0.2-0.8,3,0,2.8C4.3,175.4,4.3,172.2,3.5,172.4z"/>
		<path class="st42" d="M3.2,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M25.3,172.4c-0.8-0.2-0.8,3,0,2.8C26.2,175.4,26.2,172.2,25.3,172.4z"/>
		<path class="st41" d="M25.1,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,172.4c-0.8-0.2-0.8,3,0,2.8C65.8,175.4,65.8,172.2,65,172.4z"/>
		<path class="st41" d="M64.7,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,172.4c-0.8-0.2-0.8,3,0,2.8C63.8,175.4,63.8,172.2,63,172.4z"/>
		<path class="st41" d="M62.7,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,172.4c-0.8-0.2-0.8,3,0,2.8C61.8,175.4,61.8,172.2,61,172.4z"/>
		<path class="st41" d="M60.7,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,172.4c-0.8-0.2-0.8,3,0,2.8C59.8,175.4,59.8,172.2,59,172.4z"/>
		<path class="st41" d="M58.7,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,172.4c-0.8-0.2-0.8,3,0,2.8C57.9,175.4,57.9,172.2,57,172.4z"/>
		<path class="st41" d="M56.7,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,172.4c-0.8-0.2-0.8,3,0,2.8C55.9,175.4,55.9,172.2,55,172.4z"/>
		<path class="st41" d="M54.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,172.4c-0.8-0.2-0.8,3,0,2.8C53.9,175.4,53.9,172.2,53.1,172.4z"/>
		<path class="st41" d="M52.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,172.4c-0.8-0.2-0.8,3,0,2.8C51.9,175.4,51.9,172.2,51.1,172.4z"/>
		<path class="st41" d="M50.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,172.4c-0.8-0.2-0.8,3,0,2.8C49.9,175.4,49.9,172.2,49.1,172.4z"/>
		<path class="st41" d="M48.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,172.4c-0.8-0.2-0.8,3,0,2.8C47.9,175.4,47.9,172.2,47.1,172.4z"/>
		<path class="st41" d="M46.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,172.4c-0.8-0.2-0.8,3,0,2.8C45.9,175.4,45.9,172.2,45.1,172.4z"/>
		<path class="st41" d="M44.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,172.4c-0.8-0.2-0.8,3,0,2.8C43.9,175.4,43.9,172.2,43.1,172.4z"/>
		<path class="st41" d="M42.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,172.4c-0.8-0.2-0.8,3,0,2.8C42,175.4,42,172.2,41.1,172.4z"/>
		<path class="st41" d="M40.8,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,172.4c-0.8-0.2-0.8,3,0,2.8C40,175.4,40,172.2,39.1,172.4z"/>
		<path class="st41" d="M38.9,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,172.4c-0.8-0.2-0.8,3,0,2.8C38,175.4,38,172.2,37.2,172.4z"/>
		<path class="st41" d="M36.9,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,172.4c-0.8-0.2-0.8,3,0,2.8C36,175.4,36,172.2,35.2,172.4z"/>
		<path class="st41" d="M34.9,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,172.4c-0.8-0.2-0.8,3,0,2.8C34.1,175.4,34.1,172.2,33.3,172.4z"/>
		<path class="st41" d="M33,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,172.4c-0.8-0.2-0.8,3,0,2.8C32.1,175.4,32.1,172.2,31.3,172.4z"/>
		<path class="st41" d="M31,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,172.4c-0.8-0.2-0.8,3,0,2.8C30.1,175.4,30.2,172.2,29.3,172.4z"/>
		<path class="st41" d="M29,173.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M66,34.6c-0.8-0.2-0.8,3,0,2.8C66.9,37.6,66.9,34.4,66,34.6z"/>
		<path class="st41" d="M65.7,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,34.6c-0.8-0.2-0.8,3,0,2.8C64.9,37.6,64.9,34.4,64,34.6z"/>
		<path class="st41" d="M63.7,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,34.6c-0.8-0.2-0.8,3,0,2.8C62.9,37.6,62.9,34.4,62,34.6z"/>
		<path class="st41" d="M61.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,34.6c-0.8-0.2-0.8,3,0,2.8C60.9,37.6,60.9,34.4,60.1,34.6z"/>
		<path class="st41" d="M59.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,34.6c-0.8-0.2-0.8,3,0,2.8C58.9,37.6,58.9,34.4,58.1,34.6z"/>
		<path class="st41" d="M57.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,34.6c-0.8-0.2-0.8,3,0,2.8C56.9,37.6,56.9,34.4,56.1,34.6z"/>
		<path class="st41" d="M55.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,34.6c-0.8-0.2-0.8,3,0,2.8C54.9,37.6,54.9,34.4,54.1,34.6z"/>
		<path class="st41" d="M53.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,34.6c-0.8-0.2-0.8,3,0,2.8C52.9,37.6,52.9,34.4,52.1,34.6z"/>
		<path class="st41" d="M51.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,34.6c-0.8-0.2-0.8,3,0,2.8C51,37.6,51,34.4,50.1,34.6z"/>
		<path class="st41" d="M49.8,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,34.6c-0.8-0.2-0.8,3,0,2.8C49,37.6,49,34.4,48.1,34.6z"/>
		<path class="st41" d="M47.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,34.6c-0.8-0.2-0.8,3,0,2.8C47,37.6,47,34.4,46.2,34.6z"/>
		<path class="st41" d="M45.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,34.6c-0.8-0.2-0.8,3,0,2.8C45,37.6,45,34.4,44.2,34.6z"/>
		<path class="st41" d="M43.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,34.6c-0.8-0.2-0.8,3,0,2.8C43,37.6,43,34.4,42.2,34.6z"/>
		<path class="st41" d="M41.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,34.6c-0.8-0.2-0.8,3,0,2.8C41,37.6,41,34.4,40.2,34.6z"/>
		<path class="st41" d="M39.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,33.9c-0.8-0.2-0.8,3,0,2.8C38,36.9,38,33.7,37.2,33.9z"/>
		<path class="st41" d="M36.9,34.8C35.9,34.5,36.1,33,37,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,34.6c-0.8-0.2-0.8,3,0,2.8C39,37.6,39,34.4,38.2,34.6z"/>
		<path class="st41" d="M37.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,33.9c-0.8-0.2-0.8,3,0,2.8C36,36.9,36,33.7,35.2,33.9z"/>
		<path class="st41" d="M34.9,34.8C34,34.5,34.1,33,35.1,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,34.6c-0.8-0.2-0.8,3,0,2.8C37,37.6,37,34.4,36.2,34.6z"/>
		<path class="st41" d="M35.9,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,33.9c-0.8-0.2-0.8,3,0,2.8C34.1,36.9,34.1,33.7,33.3,33.9z"/>
		<path class="st41" d="M33,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,34.6c-0.8-0.2-0.8,3,0,2.8C35.2,37.6,35.2,34.4,34.3,34.6z"/>
		<path class="st41" d="M34.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,33.9c-0.8-0.2-0.8,3,0,2.8C32.1,36.9,32.1,33.7,31.3,33.9z"/>
		<path class="st41" d="M31,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,34.6c-0.8-0.2-0.8,3,0,2.8C33.2,37.6,33.2,34.4,32.4,34.6z"/>
		<path class="st41" d="M32.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,33.9c-0.8-0.2-0.8,3,0,2.8C30.1,36.9,30.2,33.7,29.3,33.9z"/>
		<path class="st41" d="M29,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,34.6c-0.8-0.2-0.8,3,0,2.8C31.2,37.6,31.2,34.4,30.4,34.6z"/>
		<path class="st41" d="M30.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,33.9c-0.8-0.2-0.8,3,0,2.8C28.2,36.9,28.2,33.7,27.3,33.9z"/>
		<path class="st41" d="M27,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,34.6c-0.8-0.2-0.8,3,0,2.8C29.2,37.6,29.2,34.4,28.4,34.6z"/>
		<path class="st41" d="M28.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,33.9c-0.8-0.2-0.8,3,0,2.8C26.2,36.9,26.2,33.7,25.3,33.9z"/>
		<path class="st41" d="M25.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,34.6c-0.8-0.2-0.8,3,0,2.8C27.2,37.6,27.2,34.4,26.4,34.6z"/>
		<path class="st41" d="M26.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,33.9c-0.8-0.2-0.8,3,0,2.8C24.2,36.9,24.2,33.7,23.4,33.9z"/>
		<path class="st41" d="M23.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,34.6c-0.8-0.2-0.8,3,0,2.8C25.2,37.6,25.2,34.4,24.4,34.6z"/>
		<path class="st41" d="M24.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,33.9c-0.8-0.2-0.8,3,0,2.8C22.2,36.9,22.2,33.7,21.4,33.9z"/>
		<path class="st41" d="M21.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,34.6c-0.8-0.2-0.8,3,0,2.8C23.3,37.6,23.3,34.4,22.4,34.6z"/>
		<path class="st41" d="M22.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,33.9c-0.8-0.2-0.8,3,0,2.8C20.2,36.9,20.2,33.7,19.4,33.9z"/>
		<path class="st41" d="M19.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,34.6c-0.8-0.2-0.8,3,0,2.8C21.3,37.6,21.3,34.4,20.4,34.6z"/>
		<path class="st41" d="M20.1,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,33.9c-0.8-0.2-0.8,3,0,2.8C18.2,36.9,18.2,33.7,17.4,33.9z"/>
		<path class="st41" d="M17.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,34.6c-0.8-0.2-0.8,3,0,2.8C19.3,37.6,19.3,34.4,18.4,34.6z"/>
		<path class="st41" d="M18.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,33.9c-0.8-0.2-0.8,3,0,2.8C16.2,36.9,16.2,33.7,15.4,33.9z"/>
		<path class="st41" d="M15.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,34.6c-0.8-0.2-0.8,3,0,2.8C17.3,37.6,17.3,34.4,16.5,34.6z"/>
		<path class="st41" d="M16.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,33.9c-0.8-0.2-0.8,3,0,2.8C14.3,36.9,14.3,33.7,13.4,33.9z"/>
		<path class="st41" d="M13.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,34.6c-0.8-0.2-0.8,3,0,2.8C15.3,37.6,15.3,34.4,14.5,34.6z"/>
		<path class="st41" d="M14.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,33.9c-0.8-0.2-0.8,3,0,2.8C12.3,36.9,12.3,33.7,11.4,33.9z"/>
		<path class="st41" d="M11.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,34.6c-0.8-0.2-0.8,3,0,2.8C13.3,37.6,13.3,34.4,12.5,34.6z"/>
		<path class="st41" d="M12.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,33.9c-0.8-0.2-0.8,3,0,2.8C10.3,36.9,10.3,33.7,9.5,33.9z"/>
		<path class="st41" d="M9.2,34.8C8.2,34.5,8.4,33,9.3,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,34.6c-0.8-0.2-0.8,3,0,2.8C11.3,37.6,11.3,34.4,10.5,34.6z"/>
		<path class="st41" d="M10.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,33.9c-0.8-0.2-0.8,3,0,2.8C8.3,36.9,8.3,33.7,7.5,33.9z"/>
		<path class="st41" d="M7.2,34.8C6.2,34.5,6.4,33,7.4,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,34.6c-0.8-0.2-0.8,3,0,2.8C9.3,37.6,9.3,34.4,8.5,34.6z"/>
		<path class="st41" d="M8.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,33.9c-0.8-0.2-0.8,3,0,2.8C6.3,36.9,6.3,33.7,5.5,33.9z"/>
		<path class="st41" d="M5.2,34.8C4.3,34.5,4.4,33,5.4,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,34.6c-0.8-0.2-0.8,3,0,2.8C7.4,37.6,7.4,34.4,6.5,34.6z"/>
		<path class="st41" d="M6.2,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,33.9c-0.8-0.2-0.8,3,0,2.8C4.3,36.9,4.3,33.7,3.5,33.9z"/>
		<path class="st41" d="M3.2,34.8C2.3,34.5,2.4,33,3.4,33c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,34.6c-0.8-0.2-0.8,3,0,2.8C5.4,37.6,5.4,34.4,4.5,34.6z"/>
		<path class="st41" d="M4.3,35.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,35.3c-0.8-0.2-0.8,3,0,2.8C65.8,38.3,65.8,35.1,65,35.3z"/>
		<path class="st41" d="M64.7,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,36c-0.8-0.2-0.8,3,0,2.8C66.9,39,66.9,35.8,66,36z"/>
		<path class="st41" d="M65.7,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,35.3c-0.8-0.2-0.8,3,0,2.8C63.8,38.3,63.8,35.1,63,35.3z"/>
		<path class="st41" d="M62.7,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,36c-0.8-0.2-0.8,3,0,2.8C64.9,39,64.9,35.8,64,36z"/>
		<path class="st41" d="M63.7,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,35.3c-0.8-0.2-0.8,3,0,2.8C61.8,38.3,61.8,35.1,61,35.3z"/>
		<path class="st41" d="M60.7,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,36c-0.8-0.2-0.8,3,0,2.8C62.9,39,62.9,35.8,62,36z"/>
		<path class="st41" d="M61.8,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,35.3c-0.8-0.2-0.8,3,0,2.8C59.8,38.3,59.8,35.1,59,35.3z"/>
		<path class="st41" d="M58.7,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,36c-0.8-0.2-0.8,3,0,2.8C60.9,39,60.9,35.8,60.1,36z"/>
		<path class="st41" d="M59.8,36.9C58.8,36.6,59,35.1,60,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,35.3c-0.8-0.2-0.8,3,0,2.8C57.9,38.3,57.9,35.1,57,35.3z"/>
		<path class="st41" d="M56.7,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,36c-0.8-0.2-0.8,3,0,2.8C58.9,39,58.9,35.8,58.1,36z"/>
		<path class="st41" d="M57.8,36.9C56.9,36.6,57,35.1,58,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,35.3c-0.8-0.2-0.8,3,0,2.8C55.9,38.3,55.9,35.1,55,35.3z"/>
		<path class="st41" d="M54.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,36c-0.8-0.2-0.8,3,0,2.8C56.9,39,56.9,35.8,56.1,36z"/>
		<path class="st41" d="M55.8,36.9C54.9,36.6,55,35.1,56,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,35.3c-0.8-0.2-0.8,3,0,2.8C53.9,38.3,53.9,35.1,53.1,35.3z"/>
		<path class="st41" d="M52.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,36c-0.8-0.2-0.8,3,0,2.8C54.9,39,54.9,35.8,54.1,36z"/>
		<path class="st41" d="M53.8,36.9C52.9,36.6,53,35.1,54,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,35.3c-0.8-0.2-0.8,3,0,2.8C51.9,38.3,51.9,35.1,51.1,35.3z"/>
		<path class="st41" d="M50.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,36c-0.8-0.2-0.8,3,0,2.8C52.9,39,52.9,35.8,52.1,36z"/>
		<path class="st41" d="M51.8,36.9C50.9,36.6,51,35.1,52,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,35.3c-0.8-0.2-0.8,3,0,2.8C49.9,38.3,49.9,35.1,49.1,35.3z"/>
		<path class="st41" d="M48.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,36c-0.8-0.2-0.8,3,0,2.8C51,39,51,35.8,50.1,36z"/>
		<path class="st41" d="M49.8,36.9C48.9,36.6,49,35.1,50,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,35.3c-0.8-0.2-0.8,3,0,2.8C47.9,38.3,47.9,35.1,47.1,35.3z"/>
		<path class="st41" d="M46.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,36c-0.8-0.2-0.8,3,0,2.8C49,39,49,35.8,48.1,36z"/>
		<path class="st41" d="M47.9,36.9C46.9,36.6,47,35.1,48,35c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,35.3c-0.8-0.2-0.8,3,0,2.8C45.9,38.3,45.9,35.1,45.1,35.3z"/>
		<path class="st41" d="M44.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,36c-0.8-0.2-0.8,3,0,2.8C47,39,47,35.8,46.2,36z"/>
		<path class="st41" d="M45.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,35.3c-0.8-0.2-0.8,3,0,2.8C43.9,38.3,43.9,35.1,43.1,35.3z"/>
		<path class="st41" d="M42.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,36c-0.8-0.2-0.8,3,0,2.8C45,39,45,35.8,44.2,36z"/>
		<path class="st41" d="M43.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,35.3c-0.8-0.2-0.8,3,0,2.8C42,38.3,42,35.1,41.1,35.3z"/>
		<path class="st41" d="M40.8,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,36c-0.8-0.2-0.8,3,0,2.8C43,39,43,35.8,42.2,36z"/>
		<path class="st41" d="M41.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,35.3c-0.8-0.2-0.8,3,0,2.8C40,38.3,40,35.1,39.1,35.3z"/>
		<path class="st41" d="M38.9,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,36c-0.8-0.2-0.8,3,0,2.8C41,39,41,35.8,40.2,36z"/>
		<path class="st41" d="M39.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,35.3c-0.8-0.2-0.8,3,0,2.8C38,38.3,38,35.1,37.2,35.3z"/>
		<path class="st41" d="M36.9,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,36c-0.8-0.2-0.8,3,0,2.8C39,39,39,35.8,38.2,36z"/>
		<path class="st41" d="M37.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,35.3c-0.8-0.2-0.8,3,0,2.8C36,38.3,36,35.1,35.2,35.3z"/>
		<path class="st41" d="M34.9,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,36c-0.8-0.2-0.8,3,0,2.8C37,39,37,35.8,36.2,36z"/>
		<path class="st41" d="M35.9,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,35.3c-0.8-0.2-0.8,3,0,2.8C34.1,38.3,34.1,35.1,33.3,35.3z"/>
		<path class="st41" d="M33,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,36c-0.8-0.2-0.8,3,0,2.8C35.2,39,35.2,35.8,34.3,36z"/>
		<path class="st41" d="M34.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,35.3c-0.8-0.2-0.8,3,0,2.8C32.1,38.3,32.1,35.1,31.3,35.3z"/>
		<path class="st41" d="M31,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,36c-0.8-0.2-0.8,3,0,2.8C33.2,39,33.2,35.8,32.4,36z"/>
		<path class="st41" d="M32.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,35.3c-0.8-0.2-0.8,3,0,2.8C30.1,38.3,30.2,35.1,29.3,35.3z"/>
		<path class="st41" d="M29,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,36c-0.8-0.2-0.8,3,0,2.8C31.2,39,31.2,35.8,30.4,36z"/>
		<path class="st41" d="M30.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,35.3c-0.8-0.2-0.8,3,0,2.8C28.2,38.3,28.2,35.1,27.3,35.3z"/>
		<path class="st41" d="M27,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,36c-0.8-0.2-0.8,3,0,2.8C29.2,39,29.2,35.8,28.4,36z"/>
		<path class="st41" d="M28.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,35.3c-0.8-0.2-0.8,3,0,2.8C26.2,38.3,26.2,35.1,25.3,35.3z"/>
		<path class="st41" d="M25.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,36c-0.8-0.2-0.8,3,0,2.8C27.2,39,27.2,35.8,26.4,36z"/>
		<path class="st41" d="M26.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,35.3c-0.8-0.2-0.8,3,0,2.8C24.2,38.3,24.2,35.1,23.4,35.3z"/>
		<path class="st41" d="M23.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,36c-0.8-0.2-0.8,3,0,2.8C25.2,39,25.2,35.8,24.4,36z"/>
		<path class="st41" d="M24.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,35.3c-0.8-0.2-0.8,3,0,2.8C22.2,38.3,22.2,35.1,21.4,35.3z"/>
		<path class="st41" d="M21.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,36c-0.8-0.2-0.8,3,0,2.8C23.3,39,23.3,35.8,22.4,36z"/>
		<path class="st41" d="M22.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,35.3c-0.8-0.2-0.8,3,0,2.8C20.2,38.3,20.2,35.1,19.4,35.3z"/>
		<path class="st41" d="M19.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,36c-0.8-0.2-0.8,3,0,2.8C21.3,39,21.3,35.8,20.4,36z"/>
		<path class="st41" d="M20.1,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,35.3c-0.8-0.2-0.8,3,0,2.8C18.2,38.3,18.2,35.1,17.4,35.3z"/>
		<path class="st41" d="M17.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,36c-0.8-0.2-0.8,3,0,2.8C19.3,39,19.3,35.8,18.4,36z"/>
		<path class="st41" d="M18.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,35.3c-0.8-0.2-0.8,3,0,2.8C16.2,38.3,16.2,35.1,15.4,35.3z"/>
		<path class="st41" d="M15.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,36c-0.8-0.2-0.8,3,0,2.8C17.3,39,17.3,35.8,16.5,36z"/>
		<path class="st41" d="M16.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,35.3c-0.8-0.2-0.8,3,0,2.8C14.3,38.3,14.3,35.1,13.4,35.3z"/>
		<path class="st41" d="M13.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,36c-0.8-0.2-0.8,3,0,2.8C15.3,39,15.3,35.8,14.5,36z"/>
		<path class="st41" d="M14.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,35.3c-0.8-0.2-0.8,3,0,2.8C12.3,38.3,12.3,35.1,11.4,35.3z"/>
		<path class="st41" d="M11.1,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,36c-0.8-0.2-0.8,3,0,2.8C13.3,39,13.3,35.8,12.5,36z"/>
		<path class="st41" d="M12.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,35.3c-0.8-0.2-0.8,3,0,2.8C10.3,38.3,10.3,35.1,9.5,35.3z"/>
		<path class="st41" d="M9.2,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,36c-0.8-0.2-0.8,3,0,2.8C11.3,39,11.3,35.8,10.5,36z"/>
		<path class="st41" d="M10.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,35.3c-0.8-0.2-0.8,3,0,2.8C8.3,38.3,8.3,35.1,7.5,35.3z"/>
		<path class="st41" d="M7.2,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,36c-0.8-0.2-0.8,3,0,2.8C9.3,39,9.3,35.8,8.5,36z"/>
		<path class="st41" d="M8.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,35.3c-0.8-0.2-0.8,3,0,2.8C6.3,38.3,6.3,35.1,5.5,35.3z"/>
		<path class="st41" d="M5.2,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,36c-0.8-0.2-0.8,3,0,2.8C7.4,39,7.4,35.8,6.5,36z"/>
		<path class="st41" d="M6.2,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,35.3c-0.8-0.2-0.8,3,0,2.8C4.3,38.3,4.3,35.1,3.5,35.3z"/>
		<path class="st41" d="M3.2,36.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,36c-0.8-0.2-0.8,3,0,2.8C5.4,39,5.4,35.8,4.5,36z"/>
		<path class="st41" d="M4.3,36.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,36.9c-0.8-0.2-0.8,3,0,2.8C65.8,39.9,65.8,36.8,65,36.9z"/>
		<path class="st41" d="M64.7,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,37.6c-0.8-0.2-0.8,3,0,2.8C66.9,40.6,66.9,37.4,66,37.6z"/>
		<path class="st41" d="M65.7,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,36.9c-0.8-0.2-0.8,3,0,2.8C63.8,39.9,63.8,36.8,63,36.9z"/>
		<path class="st41" d="M62.7,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,37.6c-0.8-0.2-0.8,3,0,2.8C64.9,40.6,64.9,37.4,64,37.6z"/>
		<path class="st41" d="M63.7,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,36.9c-0.8-0.2-0.8,3,0,2.8C61.8,39.9,61.8,36.8,61,36.9z"/>
		<path class="st41" d="M60.7,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,37.6c-0.8-0.2-0.8,3,0,2.8C62.9,40.6,62.9,37.4,62,37.6z"/>
		<path class="st41" d="M61.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,36.9c-0.8-0.2-0.8,3,0,2.8C59.8,39.9,59.8,36.8,59,36.9z"/>
		<path class="st41" d="M58.7,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,37.6c-0.8-0.2-0.8,3,0,2.8C60.9,40.6,60.9,37.4,60.1,37.6z"/>
		<path class="st41" d="M59.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,36.9c-0.8-0.2-0.8,3,0,2.8C57.9,39.9,57.9,36.8,57,36.9z"/>
		<path class="st41" d="M56.7,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,37.6c-0.8-0.2-0.8,3,0,2.8C58.9,40.6,58.9,37.4,58.1,37.6z"/>
		<path class="st41" d="M57.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,36.9c-0.8-0.2-0.8,3,0,2.8C55.9,39.9,55.9,36.8,55,36.9z"/>
		<path class="st41" d="M54.8,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,37.6c-0.8-0.2-0.8,3,0,2.8C56.9,40.6,56.9,37.4,56.1,37.6z"/>
		<path class="st41" d="M55.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,36.9c-0.8-0.2-0.8,3,0,2.8C53.9,39.9,53.9,36.8,53.1,36.9z"/>
		<path class="st41" d="M52.8,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,37.6c-0.8-0.2-0.8,3,0,2.8C54.9,40.6,54.9,37.4,54.1,37.6z"/>
		<path class="st41" d="M53.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,36.9c-0.8-0.2-0.8,3,0,2.8C51.9,39.9,51.9,36.8,51.1,36.9z"/>
		<path class="st41" d="M50.8,37.8C49.8,37.5,50,36.1,51,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,37.6c-0.8-0.2-0.8,3,0,2.8C52.9,40.6,52.9,37.4,52.1,37.6z"/>
		<path class="st41" d="M51.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,36.9c-0.8-0.2-0.8,3,0,2.8C49.9,39.9,49.9,36.8,49.1,36.9z"/>
		<path class="st41" d="M48.8,37.8C47.9,37.5,48,36.1,49,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,37.6c-0.8-0.2-0.8,3,0,2.8C51,40.6,51,37.4,50.1,37.6z"/>
		<path class="st41" d="M49.8,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,36.9c-0.8-0.2-0.8,3,0,2.8C47.9,39.9,47.9,36.8,47.1,36.9z"/>
		<path class="st41" d="M46.8,37.8C45.9,37.5,46,36.1,47,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,37.6c-0.8-0.2-0.8,3,0,2.8C49,40.6,49,37.4,48.1,37.6z"/>
		<path class="st41" d="M47.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,36.9c-0.8-0.2-0.8,3,0,2.8C45.9,39.9,45.9,36.8,45.1,36.9z"/>
		<path class="st41" d="M44.8,37.8C43.9,37.5,44,36.1,45,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,37.6c-0.8-0.2-0.8,3,0,2.8C47,40.6,47,37.4,46.2,37.6z"/>
		<path class="st41" d="M45.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,36.9c-0.8-0.2-0.8,3,0,2.8C43.9,39.9,43.9,36.8,43.1,36.9z"/>
		<path class="st41" d="M42.8,37.8C41.9,37.5,42,36.1,43,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,37.6c-0.8-0.2-0.8,3,0,2.8C45,40.6,45,37.4,44.2,37.6z"/>
		<path class="st41" d="M43.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,36.9c-0.8-0.2-0.8,3,0,2.8C42,39.9,42,36.8,41.1,36.9z"/>
		<path class="st41" d="M40.8,37.8C39.9,37.5,40,36.1,41,36c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,37.6c-0.8-0.2-0.8,3,0,2.8C43,40.6,43,37.4,42.2,37.6z"/>
		<path class="st41" d="M41.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,36.9c-0.8-0.2-0.8,3,0,2.8C40,39.9,40,36.8,39.1,36.9z"/>
		<path class="st41" d="M38.9,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,37.6c-0.8-0.2-0.8,3,0,2.8C41,40.6,41,37.4,40.2,37.6z"/>
		<path class="st41" d="M39.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,36.9c-0.8-0.2-0.8,3,0,2.8C38,39.9,38,36.8,37.2,36.9z"/>
		<path class="st41" d="M36.9,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,37.6c-0.8-0.2-0.8,3,0,2.8C39,40.6,39,37.4,38.2,37.6z"/>
		<path class="st41" d="M37.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,36.9c-0.8-0.2-0.8,3,0,2.8C36,39.9,36,36.8,35.2,36.9z"/>
		<path class="st41" d="M34.9,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,37.6c-0.8-0.2-0.8,3,0,2.8C37,40.6,37,37.4,36.2,37.6z"/>
		<path class="st41" d="M35.9,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,36.9c-0.8-0.2-0.8,3,0,2.8C34.1,39.9,34.1,36.8,33.3,36.9z"/>
		<path class="st41" d="M33,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,37.6c-0.8-0.2-0.8,3,0,2.8C35.2,40.6,35.2,37.4,34.3,37.6z"/>
		<path class="st41" d="M34.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,36.9c-0.8-0.2-0.8,3,0,2.8C32.1,39.9,32.1,36.8,31.3,36.9z"/>
		<path class="st41" d="M31,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,37.6c-0.8-0.2-0.8,3,0,2.8C33.2,40.6,33.2,37.4,32.4,37.6z"/>
		<path class="st41" d="M32.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,36.9c-0.8-0.2-0.8,3,0,2.8C30.1,39.9,30.2,36.8,29.3,36.9z"/>
		<path class="st41" d="M29,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,37.6c-0.8-0.2-0.8,3,0,2.8C31.2,40.6,31.2,37.4,30.4,37.6z"/>
		<path class="st41" d="M30.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,36.9c-0.8-0.2-0.8,3,0,2.8C28.2,39.9,28.2,36.8,27.3,36.9z"/>
		<path class="st41" d="M27,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,37.6c-0.8-0.2-0.8,3,0,2.8C29.2,40.6,29.2,37.4,28.4,37.6z"/>
		<path class="st41" d="M28.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,36.9c-0.8-0.2-0.8,3,0,2.8C26.2,39.9,26.2,36.8,25.3,36.9z"/>
		<path class="st41" d="M25.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,37.6c-0.8-0.2-0.8,3,0,2.8C27.2,40.6,27.2,37.4,26.4,37.6z"/>
		<path class="st41" d="M26.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,36.9c-0.8-0.2-0.8,3,0,2.8C24.2,39.9,24.2,36.8,23.4,36.9z"/>
		<path class="st41" d="M23.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,37.6c-0.8-0.2-0.8,3,0,2.8C25.2,40.6,25.2,37.4,24.4,37.6z"/>
		<path class="st41" d="M24.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,36.9c-0.8-0.2-0.8,3,0,2.8C22.2,39.9,22.2,36.8,21.4,36.9z"/>
		<path class="st41" d="M21.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,37.6c-0.8-0.2-0.8,3,0,2.8C23.3,40.6,23.3,37.4,22.4,37.6z"/>
		<path class="st41" d="M22.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,36.9c-0.8-0.2-0.8,3,0,2.8C20.2,39.9,20.2,36.8,19.4,36.9z"/>
		<path class="st41" d="M19.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,37.6c-0.8-0.2-0.8,3,0,2.8C21.3,40.6,21.3,37.4,20.4,37.6z"/>
		<path class="st41" d="M20.1,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,36.9c-0.8-0.2-0.8,3,0,2.8C18.2,39.9,18.2,36.8,17.4,36.9z"/>
		<path class="st41" d="M17.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,37.6c-0.8-0.2-0.8,3,0,2.8C19.3,40.6,19.3,37.4,18.4,37.6z"/>
		<path class="st41" d="M18.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,36.9c-0.8-0.2-0.8,3,0,2.8C16.2,39.9,16.2,36.8,15.4,36.9z"/>
		<path class="st41" d="M15.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,37.6c-0.8-0.2-0.8,3,0,2.8C17.3,40.6,17.3,37.4,16.5,37.6z"/>
		<path class="st41" d="M16.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,36.9c-0.8-0.2-0.8,3,0,2.8C14.3,39.9,14.3,36.8,13.4,36.9z"/>
		<path class="st41" d="M13.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,37.6c-0.8-0.2-0.8,3,0,2.8C15.3,40.6,15.3,37.4,14.5,37.6z"/>
		<path class="st41" d="M14.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,36.9c-0.8-0.2-0.8,3,0,2.8C12.3,39.9,12.3,36.8,11.4,36.9z"/>
		<path class="st41" d="M11.1,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,37.6c-0.8-0.2-0.8,3,0,2.8C13.3,40.6,13.3,37.4,12.5,37.6z"/>
		<path class="st41" d="M12.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,36.9c-0.8-0.2-0.8,3,0,2.8C10.3,39.9,10.3,36.8,9.5,36.9z"/>
		<path class="st41" d="M9.2,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,37.6c-0.8-0.2-0.8,3,0,2.8C11.3,40.6,11.3,37.4,10.5,37.6z"/>
		<path class="st41" d="M10.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,36.9c-0.8-0.2-0.8,3,0,2.8C8.3,39.9,8.3,36.8,7.5,36.9z"/>
		<path class="st41" d="M7.2,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,37.6c-0.8-0.2-0.8,3,0,2.8C9.3,40.6,9.3,37.4,8.5,37.6z"/>
		<path class="st41" d="M8.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,36.9c-0.8-0.2-0.8,3,0,2.8C6.3,39.9,6.3,36.8,5.5,36.9z"/>
		<path class="st41" d="M5.2,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,37.6c-0.8-0.2-0.8,3,0,2.8C7.4,40.6,7.4,37.4,6.5,37.6z"/>
		<path class="st41" d="M6.2,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,36.9c-0.8-0.2-0.8,3,0,2.8C4.3,39.9,4.3,36.8,3.5,36.9z"/>
		<path class="st41" d="M3.2,37.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,37.6c-0.8-0.2-0.8,3,0,2.8C5.4,40.6,5.4,37.4,4.5,37.6z"/>
		<path class="st41" d="M4.3,38.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,38.3c-0.8-0.2-0.8,3,0,2.8C65.8,41.3,65.8,38.2,65,38.3z"/>
		<path class="st41" d="M64.7,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,39c-0.8-0.2-0.8,3,0,2.8C66.9,42,66.9,38.8,66,39z"/>
		<path class="st41" d="M65.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,38.3c-0.8-0.2-0.8,3,0,2.8C63.8,41.3,63.8,38.2,63,38.3z"/>
		<path class="st41" d="M62.7,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,39c-0.8-0.2-0.8,3,0,2.8C64.9,42,64.9,38.8,64,39z"/>
		<path class="st41" d="M63.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,38.3c-0.8-0.2-0.8,3,0,2.8C61.8,41.3,61.8,38.2,61,38.3z"/>
		<path class="st41" d="M60.7,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,39c-0.8-0.2-0.8,3,0,2.8C62.9,42,62.9,38.8,62,39z"/>
		<path class="st41" d="M61.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,38.3c-0.8-0.2-0.8,3,0,2.8C59.8,41.3,59.8,38.2,59,38.3z"/>
		<path class="st41" d="M58.7,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,39c-0.8-0.2-0.8,3,0,2.8C60.9,42,60.9,38.8,60.1,39z"/>
		<path class="st41" d="M59.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,38.3c-0.8-0.2-0.8,3,0,2.8C57.9,41.3,57.9,38.2,57,38.3z"/>
		<path class="st41" d="M56.7,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,39c-0.8-0.2-0.8,3,0,2.8C58.9,42,58.9,38.8,58.1,39z"/>
		<path class="st41" d="M57.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,38.3c-0.8-0.2-0.8,3,0,2.8C55.9,41.3,55.9,38.2,55,38.3z"/>
		<path class="st41" d="M54.8,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,39c-0.8-0.2-0.8,3,0,2.8C56.9,42,56.9,38.8,56.1,39z"/>
		<path class="st41" d="M55.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,38.3c-0.8-0.2-0.8,3,0,2.8C53.9,41.3,53.9,38.2,53.1,38.3z"/>
		<path class="st41" d="M52.8,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,39c-0.8-0.2-0.8,3,0,2.8C54.9,42,54.9,38.8,54.1,39z"/>
		<path class="st41" d="M53.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,38.3c-0.8-0.2-0.8,3,0,2.8C51.9,41.3,51.9,38.2,51.1,38.3z"/>
		<path class="st41" d="M50.8,39.2C49.8,39,50,37.5,51,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,39c-0.8-0.2-0.8,3,0,2.8C52.9,42,52.9,38.8,52.1,39z"/>
		<path class="st41" d="M51.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,38.3c-0.8-0.2-0.8,3,0,2.8C49.9,41.3,49.9,38.2,49.1,38.3z"/>
		<path class="st41" d="M48.8,39.2C47.9,39,48,37.5,49,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,39c-0.8-0.2-0.8,3,0,2.8C51,42,51,38.8,50.1,39z"/>
		<path class="st41" d="M49.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,38.3c-0.8-0.2-0.8,3,0,2.8C47.9,41.3,47.9,38.2,47.1,38.3z"/>
		<path class="st41" d="M46.8,39.2C45.9,39,46,37.5,47,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,39c-0.8-0.2-0.8,3,0,2.8C49,42,49,38.8,48.1,39z"/>
		<path class="st41" d="M47.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,38.3c-0.8-0.2-0.8,3,0,2.8C45.9,41.3,45.9,38.2,45.1,38.3z"/>
		<path class="st41" d="M44.8,39.2C43.9,39,44,37.5,45,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,39c-0.8-0.2-0.8,3,0,2.8C47,42,47,38.8,46.2,39z"/>
		<path class="st41" d="M45.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,38.3c-0.8-0.2-0.8,3,0,2.8C43.9,41.3,43.9,38.2,43.1,38.3z"/>
		<path class="st41" d="M42.8,39.2C41.9,39,42,37.5,43,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,39c-0.8-0.2-0.8,3,0,2.8C45,42,45,38.8,44.2,39z"/>
		<path class="st41" d="M43.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,38.3c-0.8-0.2-0.8,3,0,2.8C42,41.3,42,38.2,41.1,38.3z"/>
		<path class="st41" d="M40.8,39.2C39.9,39,40,37.5,41,37.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,39c-0.8-0.2-0.8,3,0,2.8C43,42,43,38.8,42.2,39z"/>
		<path class="st41" d="M41.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,38.3c-0.8-0.2-0.8,3,0,2.8C40,41.3,40,38.2,39.1,38.3z"/>
		<path class="st41" d="M38.9,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,39c-0.8-0.2-0.8,3,0,2.8C41,42,41,38.8,40.2,39z"/>
		<path class="st41" d="M39.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,38.3c-0.8-0.2-0.8,3,0,2.8C38,41.3,38,38.2,37.2,38.3z"/>
		<path class="st41" d="M36.9,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,39c-0.8-0.2-0.8,3,0,2.8C39,42,39,38.8,38.2,39z"/>
		<path class="st41" d="M37.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,38.3c-0.8-0.2-0.8,3,0,2.8C36,41.3,36,38.2,35.2,38.3z"/>
		<path class="st41" d="M34.9,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,39c-0.8-0.2-0.8,3,0,2.8C37,42,37,38.8,36.2,39z"/>
		<path class="st41" d="M35.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,38.3c-0.8-0.2-0.8,3,0,2.8C34.1,41.3,34.1,38.2,33.3,38.3z"/>
		<path class="st41" d="M33,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,39c-0.8-0.2-0.8,3,0,2.8C35.2,42,35.2,38.8,34.3,39z"/>
		<path class="st41" d="M34.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,38.3c-0.8-0.2-0.8,3,0,2.8C32.1,41.3,32.1,38.2,31.3,38.3z"/>
		<path class="st41" d="M31,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,39c-0.8-0.2-0.8,3,0,2.8C33.2,42,33.2,38.8,32.4,39z"/>
		<path class="st41" d="M32.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,38.3c-0.8-0.2-0.8,3,0,2.8C30.1,41.3,30.2,38.2,29.3,38.3z"/>
		<path class="st41" d="M29,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,39c-0.8-0.2-0.8,3,0,2.8C31.2,42,31.2,38.8,30.4,39z"/>
		<path class="st41" d="M30.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,38.3c-0.8-0.2-0.8,3,0,2.8C28.2,41.3,28.2,38.2,27.3,38.3z"/>
		<path class="st41" d="M27,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,39c-0.8-0.2-0.8,3,0,2.8C29.2,42,29.2,38.8,28.4,39z"/>
		<path class="st41" d="M28.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,38.3c-0.8-0.2-0.8,3,0,2.8C26.2,41.3,26.2,38.2,25.3,38.3z"/>
		<path class="st41" d="M25.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,39c-0.8-0.2-0.8,3,0,2.8C27.2,42,27.2,38.8,26.4,39z"/>
		<path class="st41" d="M26.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,38.3c-0.8-0.2-0.8,3,0,2.8C24.2,41.3,24.2,38.2,23.4,38.3z"/>
		<path class="st41" d="M23.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,39c-0.8-0.2-0.8,3,0,2.8C25.2,42,25.2,38.8,24.4,39z"/>
		<path class="st41" d="M24.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,38.3c-0.8-0.2-0.8,3,0,2.8C22.2,41.3,22.2,38.2,21.4,38.3z"/>
		<path class="st41" d="M21.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,39c-0.8-0.2-0.8,3,0,2.8C23.3,42,23.3,38.8,22.4,39z"/>
		<path class="st41" d="M22.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,38.3c-0.8-0.2-0.8,3,0,2.8C20.2,41.3,20.2,38.2,19.4,38.3z"/>
		<path class="st41" d="M19.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,39c-0.8-0.2-0.8,3,0,2.8C21.3,42,21.3,38.8,20.4,39z"/>
		<path class="st41" d="M20.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,38.3c-0.8-0.2-0.8,3,0,2.8C18.2,41.3,18.2,38.2,17.4,38.3z"/>
		<path class="st41" d="M17.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,39c-0.8-0.2-0.8,3,0,2.8C19.3,42,19.3,38.8,18.4,39z"/>
		<path class="st41" d="M18.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,38.3c-0.8-0.2-0.8,3,0,2.8C16.2,41.3,16.2,38.2,15.4,38.3z"/>
		<path class="st41" d="M15.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,39c-0.8-0.2-0.8,3,0,2.8C17.3,42,17.3,38.8,16.5,39z"/>
		<path class="st41" d="M16.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,38.3c-0.8-0.2-0.8,3,0,2.8C14.3,41.3,14.3,38.2,13.4,38.3z"/>
		<path class="st41" d="M13.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,39c-0.8-0.2-0.8,3,0,2.8C15.3,42,15.3,38.8,14.5,39z"/>
		<path class="st41" d="M14.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,38.3c-0.8-0.2-0.8,3,0,2.8C12.3,41.3,12.3,38.2,11.4,38.3z"/>
		<path class="st41" d="M11.1,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,39c-0.8-0.2-0.8,3,0,2.8C13.3,42,13.3,38.8,12.5,39z"/>
		<path class="st41" d="M12.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,38.3c-0.8-0.2-0.8,3,0,2.8C10.3,41.3,10.3,38.2,9.5,38.3z"/>
		<path class="st41" d="M9.2,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,39c-0.8-0.2-0.8,3,0,2.8C11.3,42,11.3,38.8,10.5,39z"/>
		<path class="st41" d="M10.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,38.3c-0.8-0.2-0.8,3,0,2.8C8.3,41.3,8.3,38.2,7.5,38.3z"/>
		<path class="st41" d="M7.2,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,39c-0.8-0.2-0.8,3,0,2.8C9.3,42,9.3,38.8,8.5,39z"/>
		<path class="st41" d="M8.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,38.3c-0.8-0.2-0.8,3,0,2.8C6.3,41.3,6.3,38.2,5.5,38.3z"/>
		<path class="st41" d="M5.2,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,39c-0.8-0.2-0.8,3,0,2.8C7.4,42,7.4,38.8,6.5,39z"/>
		<path class="st41" d="M6.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,38.3c-0.8-0.2-0.8,3,0,2.8C4.3,41.3,4.3,38.2,3.5,38.3z"/>
		<path class="st41" d="M3.2,39.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,39c-0.8-0.2-0.8,3,0,2.8C5.4,42,5.4,38.8,4.5,39z"/>
		<path class="st41" d="M4.3,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,39.9c-0.8-0.2-0.8,3,0,2.8C65.8,42.9,65.8,39.8,65,39.9z"/>
		<path class="st41" d="M64.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,40.6c-0.8-0.2-0.8,3,0,2.8C66.9,43.6,66.9,40.4,66,40.6z"/>
		<path class="st41" d="M65.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,39.9c-0.8-0.2-0.8,3,0,2.8C63.8,42.9,63.8,39.8,63,39.9z"/>
		<path class="st41" d="M62.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,40.6c-0.8-0.2-0.8,3,0,2.8C64.9,43.6,64.9,40.4,64,40.6z"/>
		<path class="st41" d="M63.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,39.9c-0.8-0.2-0.8,3,0,2.8C61.8,42.9,61.8,39.8,61,39.9z"/>
		<path class="st41" d="M60.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,40.6c-0.8-0.2-0.8,3,0,2.8C62.9,43.6,62.9,40.4,62,40.6z"/>
		<path class="st41" d="M61.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,39.9c-0.8-0.2-0.8,3,0,2.8C59.8,42.9,59.8,39.8,59,39.9z"/>
		<path class="st41" d="M58.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,40.6c-0.8-0.2-0.8,3,0,2.8C60.9,43.6,60.9,40.4,60.1,40.6z"/>
		<path class="st41" d="M59.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,39.9c-0.8-0.2-0.8,3,0,2.8C57.9,42.9,57.9,39.8,57,39.9z"/>
		<path class="st41" d="M56.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,40.6c-0.8-0.2-0.8,3,0,2.8C58.9,43.6,58.9,40.4,58.1,40.6z"/>
		<path class="st41" d="M57.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,39.9c-0.8-0.2-0.8,3,0,2.8C55.9,42.9,55.9,39.8,55,39.9z"/>
		<path class="st41" d="M54.8,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,40.6c-0.8-0.2-0.8,3,0,2.8C56.9,43.6,56.9,40.4,56.1,40.6z"/>
		<path class="st41" d="M55.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,39.9c-0.8-0.2-0.8,3,0,2.8C53.9,42.9,53.9,39.8,53.1,39.9z"/>
		<path class="st41" d="M52.8,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,40.6c-0.8-0.2-0.8,3,0,2.8C54.9,43.6,54.9,40.4,54.1,40.6z"/>
		<path class="st41" d="M53.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,39.9c-0.8-0.2-0.8,3,0,2.8C51.9,42.9,51.9,39.8,51.1,39.9z"/>
		<path class="st41" d="M50.8,40.8C49.8,40.6,50,39.1,51,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,40.6c-0.8-0.2-0.8,3,0,2.8C52.9,43.6,52.9,40.4,52.1,40.6z"/>
		<path class="st41" d="M51.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,39.9c-0.8-0.2-0.8,3,0,2.8C49.9,42.9,49.9,39.8,49.1,39.9z"/>
		<path class="st41" d="M48.8,40.8C47.9,40.6,48,39.1,49,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,40.6c-0.8-0.2-0.8,3,0,2.8C51,43.6,51,40.4,50.1,40.6z"/>
		<path class="st41" d="M49.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,39.9c-0.8-0.2-0.8,3,0,2.8C47.9,42.9,47.9,39.8,47.1,39.9z"/>
		<path class="st41" d="M46.8,40.8C45.9,40.6,46,39.1,47,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,40.6c-0.8-0.2-0.8,3,0,2.8C49,43.6,49,40.4,48.1,40.6z"/>
		<path class="st41" d="M47.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,39.9c-0.8-0.2-0.8,3,0,2.8C45.9,42.9,45.9,39.8,45.1,39.9z"/>
		<path class="st41" d="M44.8,40.8C43.9,40.6,44,39.1,45,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,40.6c-0.8-0.2-0.8,3,0,2.8C47,43.6,47,40.4,46.2,40.6z"/>
		<path class="st41" d="M45.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,39.9c-0.8-0.2-0.8,3,0,2.8C43.9,42.9,43.9,39.8,43.1,39.9z"/>
		<path class="st41" d="M42.8,40.8C41.9,40.6,42,39.1,43,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,40.6c-0.8-0.2-0.8,3,0,2.8C45,43.6,45,40.4,44.2,40.6z"/>
		<path class="st41" d="M43.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,39.9c-0.8-0.2-0.8,3,0,2.8C42,42.9,42,39.8,41.1,39.9z"/>
		<path class="st41" d="M40.8,40.8C39.9,40.6,40,39.1,41,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,40.6c-0.8-0.2-0.8,3,0,2.8C43,43.6,43,40.4,42.2,40.6z"/>
		<path class="st41" d="M41.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,39.9c-0.8-0.2-0.8,3,0,2.8C40,42.9,40,39.8,39.1,39.9z"/>
		<path class="st41" d="M38.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,40.6c-0.8-0.2-0.8,3,0,2.8C41,43.6,41,40.4,40.2,40.6z"/>
		<path class="st41" d="M39.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,39.9c-0.8-0.2-0.8,3,0,2.8C38,42.9,38,39.8,37.2,39.9z"/>
		<path class="st41" d="M36.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,40.6c-0.8-0.2-0.8,3,0,2.8C39,43.6,39,40.4,38.2,40.6z"/>
		<path class="st41" d="M37.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,39.9c-0.8-0.2-0.8,3,0,2.8C36,42.9,36,39.8,35.2,39.9z"/>
		<path class="st41" d="M34.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,40.6c-0.8-0.2-0.8,3,0,2.8C37,43.6,37,40.4,36.2,40.6z"/>
		<path class="st41" d="M35.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,39.9c-0.8-0.2-0.8,3,0,2.8C34.1,42.9,34.1,39.8,33.3,39.9z"/>
		<path class="st41" d="M33,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,40.6c-0.8-0.2-0.8,3,0,2.8C35.2,43.6,35.2,40.4,34.3,40.6z"/>
		<path class="st41" d="M34.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,39.9c-0.8-0.2-0.8,3,0,2.8C32.1,42.9,32.1,39.8,31.3,39.9z"/>
		<path class="st41" d="M31,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,40.6c-0.8-0.2-0.8,3,0,2.8C33.2,43.6,33.2,40.4,32.4,40.6z"/>
		<path class="st41" d="M32.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,39.9c-0.8-0.2-0.8,3,0,2.8C30.1,42.9,30.2,39.8,29.3,39.9z"/>
		<path class="st41" d="M29,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,40.6c-0.8-0.2-0.8,3,0,2.8C31.2,43.6,31.2,40.4,30.4,40.6z"/>
		<path class="st41" d="M30.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,39.9c-0.8-0.2-0.8,3,0,2.8C28.2,42.9,28.2,39.8,27.3,39.9z"/>
		<path class="st41" d="M27,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,40.6c-0.8-0.2-0.8,3,0,2.8C29.2,43.6,29.2,40.4,28.4,40.6z"/>
		<path class="st41" d="M28.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,39.9c-0.8-0.2-0.8,3,0,2.8C26.2,42.9,26.2,39.8,25.3,39.9z"/>
		<path class="st41" d="M25.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,40.6c-0.8-0.2-0.8,3,0,2.8C27.2,43.6,27.2,40.4,26.4,40.6z"/>
		<path class="st41" d="M26.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,39.9c-0.8-0.2-0.8,3,0,2.8C24.2,42.9,24.2,39.8,23.4,39.9z"/>
		<path class="st41" d="M23.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,40.6c-0.8-0.2-0.8,3,0,2.8C25.2,43.6,25.2,40.4,24.4,40.6z"/>
		<path class="st41" d="M24.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,39.9c-0.8-0.2-0.8,3,0,2.8C22.2,42.9,22.2,39.8,21.4,39.9z"/>
		<path class="st41" d="M21.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,40.6c-0.8-0.2-0.8,3,0,2.8C23.3,43.6,23.3,40.4,22.4,40.6z"/>
		<path class="st41" d="M22.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,39.9c-0.8-0.2-0.8,3,0,2.8C20.2,42.9,20.2,39.8,19.4,39.9z"/>
		<path class="st41" d="M19.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,40.6c-0.8-0.2-0.8,3,0,2.8C21.3,43.6,21.3,40.4,20.4,40.6z"/>
		<path class="st41" d="M20.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,39.9c-0.8-0.2-0.8,3,0,2.8C18.2,42.9,18.2,39.8,17.4,39.9z"/>
		<path class="st41" d="M17.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,40.6c-0.8-0.2-0.8,3,0,2.8C19.3,43.6,19.3,40.4,18.4,40.6z"/>
		<path class="st41" d="M18.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,39.9c-0.8-0.2-0.8,3,0,2.8C16.2,42.9,16.2,39.8,15.4,39.9z"/>
		<path class="st41" d="M15.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,40.6c-0.8-0.2-0.8,3,0,2.8C17.3,43.6,17.3,40.4,16.5,40.6z"/>
		<path class="st41" d="M16.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,39.9c-0.8-0.2-0.8,3,0,2.8C14.3,42.9,14.3,39.8,13.4,39.9z"/>
		<path class="st41" d="M13.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,40.6c-0.8-0.2-0.8,3,0,2.8C15.3,43.6,15.3,40.4,14.5,40.6z"/>
		<path class="st41" d="M14.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,39.9c-0.8-0.2-0.8,3,0,2.8C12.3,42.9,12.3,39.8,11.4,39.9z"/>
		<path class="st41" d="M11.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,40.6c-0.8-0.2-0.8,3,0,2.8C13.3,43.6,13.3,40.4,12.5,40.6z"/>
		<path class="st41" d="M12.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,39.9c-0.8-0.2-0.8,3,0,2.8C10.3,42.9,10.3,39.8,9.5,39.9z"/>
		<path class="st41" d="M9.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,40.6c-0.8-0.2-0.8,3,0,2.8C11.3,43.6,11.3,40.4,10.5,40.6z"/>
		<path class="st41" d="M10.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,39.9c-0.8-0.2-0.8,3,0,2.8C8.3,42.9,8.3,39.8,7.5,39.9z"/>
		<path class="st41" d="M7.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,40.6c-0.8-0.2-0.8,3,0,2.8C9.3,43.6,9.3,40.4,8.5,40.6z"/>
		<path class="st41" d="M8.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,39.9c-0.8-0.2-0.8,3,0,2.8C6.3,42.9,6.3,39.8,5.5,39.9z"/>
		<path class="st41" d="M5.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,40.6c-0.8-0.2-0.8,3,0,2.8C7.4,43.6,7.4,40.4,6.5,40.6z"/>
		<path class="st41" d="M6.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,39.9c-0.8-0.2-0.8,3,0,2.8C4.3,42.9,4.3,39.8,3.5,39.9z"/>
		<path class="st41" d="M3.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,40.6c-0.8-0.2-0.8,3,0,2.8C5.4,43.6,5.4,40.4,4.5,40.6z"/>
		<path class="st41" d="M4.3,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,41.4c-0.8-0.2-0.8,3,0,2.8C65.8,44.4,65.8,41.2,65,41.4z"/>
		<path class="st41" d="M64.7,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,42c-0.8-0.2-0.8,3,0,2.8C66.9,45,66.9,41.9,66,42z"/>
		<path class="st41" d="M65.7,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,41.4c-0.8-0.2-0.8,3,0,2.8C63.8,44.4,63.8,41.2,63,41.4z"/>
		<path class="st41" d="M62.7,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,42c-0.8-0.2-0.8,3,0,2.8C64.9,45,64.9,41.9,64,42z"/>
		<path class="st41" d="M63.7,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,41.4c-0.8-0.2-0.8,3,0,2.8C61.8,44.4,61.8,41.2,61,41.4z"/>
		<path class="st41" d="M60.7,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,42c-0.8-0.2-0.8,3,0,2.8C62.9,45,62.9,41.9,62,42z"/>
		<path class="st41" d="M61.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,41.4c-0.8-0.2-0.8,3,0,2.8C59.8,44.4,59.8,41.2,59,41.4z"/>
		<path class="st41" d="M58.7,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,42c-0.8-0.2-0.8,3,0,2.8C60.9,45,60.9,41.9,60.1,42z"/>
		<path class="st41" d="M59.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,41.4c-0.8-0.2-0.8,3,0,2.8C57.9,44.4,57.9,41.2,57,41.4z"/>
		<path class="st41" d="M56.7,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,42c-0.8-0.2-0.8,3,0,2.8C58.9,45,58.9,41.9,58.1,42z"/>
		<path class="st41" d="M57.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,41.4c-0.8-0.2-0.8,3,0,2.8C55.9,44.4,55.9,41.2,55,41.4z"/>
		<path class="st41" d="M54.8,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,42c-0.8-0.2-0.8,3,0,2.8C56.9,45,56.9,41.9,56.1,42z"/>
		<path class="st41" d="M55.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,41.4c-0.8-0.2-0.8,3,0,2.8C53.9,44.4,53.9,41.2,53.1,41.4z"/>
		<path class="st41" d="M52.8,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,42c-0.8-0.2-0.8,3,0,2.8C54.9,45,54.9,41.9,54.1,42z"/>
		<path class="st41" d="M53.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,41.4c-0.8-0.2-0.8,3,0,2.8C51.9,44.4,51.9,41.2,51.1,41.4z"/>
		<path class="st41" d="M50.8,42.3C49.8,42,50,40.5,51,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,42c-0.8-0.2-0.8,3,0,2.8C52.9,45,52.9,41.9,52.1,42z"/>
		<path class="st41" d="M51.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,41.4c-0.8-0.2-0.8,3,0,2.8C49.9,44.4,49.9,41.2,49.1,41.4z"/>
		<path class="st41" d="M48.8,42.3C47.9,42,48,40.5,49,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,42c-0.8-0.2-0.8,3,0,2.8C51,45,51,41.9,50.1,42z"/>
		<path class="st41" d="M49.8,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,41.4c-0.8-0.2-0.8,3,0,2.8C47.9,44.4,47.9,41.2,47.1,41.4z"/>
		<path class="st41" d="M46.8,42.3C45.9,42,46,40.5,47,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,42c-0.8-0.2-0.8,3,0,2.8C49,45,49,41.9,48.1,42z"/>
		<path class="st41" d="M47.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,41.4c-0.8-0.2-0.8,3,0,2.8C45.9,44.4,45.9,41.2,45.1,41.4z"/>
		<path class="st41" d="M44.8,42.3C43.9,42,44,40.5,45,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,42c-0.8-0.2-0.8,3,0,2.8C47,45,47,41.9,46.2,42z"/>
		<path class="st41" d="M45.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,41.4c-0.8-0.2-0.8,3,0,2.8C43.9,44.4,43.9,41.2,43.1,41.4z"/>
		<path class="st41" d="M42.8,42.3C41.9,42,42,40.5,43,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,42c-0.8-0.2-0.8,3,0,2.8C45,45,45,41.9,44.2,42z"/>
		<path class="st41" d="M43.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,41.4c-0.8-0.2-0.8,3,0,2.8C42,44.4,42,41.2,41.1,41.4z"/>
		<path class="st41" d="M40.8,42.3C39.9,42,40,40.5,41,40.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,42c-0.8-0.2-0.8,3,0,2.8C43,45,43,41.9,42.2,42z"/>
		<path class="st41" d="M41.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,41.4c-0.8-0.2-0.8,3,0,2.8C40,44.4,40,41.2,39.1,41.4z"/>
		<path class="st41" d="M38.9,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,42c-0.8-0.2-0.8,3,0,2.8C41,45,41,41.9,40.2,42z"/>
		<path class="st41" d="M39.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,41.4c-0.8-0.2-0.8,3,0,2.8C38,44.4,38,41.2,37.2,41.4z"/>
		<path class="st41" d="M36.9,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,42c-0.8-0.2-0.8,3,0,2.8C39,45,39,41.9,38.2,42z"/>
		<path class="st41" d="M37.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,41.4c-0.8-0.2-0.8,3,0,2.8C36,44.4,36,41.2,35.2,41.4z"/>
		<path class="st41" d="M34.9,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,42c-0.8-0.2-0.8,3,0,2.8C37,45,37,41.9,36.2,42z"/>
		<path class="st41" d="M35.9,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,41.4c-0.8-0.2-0.8,3,0,2.8C34.1,44.4,34.1,41.2,33.3,41.4z"/>
		<path class="st41" d="M33,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,42c-0.8-0.2-0.8,3,0,2.8C35.2,45,35.2,41.9,34.3,42z"/>
		<path class="st41" d="M34.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,41.4c-0.8-0.2-0.8,3,0,2.8C32.1,44.4,32.1,41.2,31.3,41.4z"/>
		<path class="st41" d="M31,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,42c-0.8-0.2-0.8,3,0,2.8C33.2,45,33.2,41.9,32.4,42z"/>
		<path class="st41" d="M32.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,41.4c-0.8-0.2-0.8,3,0,2.8C30.1,44.4,30.2,41.2,29.3,41.4z"/>
		<path class="st41" d="M29,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,42c-0.8-0.2-0.8,3,0,2.8C31.2,45,31.2,41.9,30.4,42z"/>
		<path class="st41" d="M30.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,41.4c-0.8-0.2-0.8,3,0,2.8C28.2,44.4,28.2,41.2,27.3,41.4z"/>
		<path class="st41" d="M27,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,42c-0.8-0.2-0.8,3,0,2.8C29.2,45,29.2,41.9,28.4,42z"/>
		<path class="st41" d="M28.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,41.4c-0.8-0.2-0.8,3,0,2.8C26.2,44.4,26.2,41.2,25.3,41.4z"/>
		<path class="st41" d="M25.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,42c-0.8-0.2-0.8,3,0,2.8C27.2,45,27.2,41.9,26.4,42z"/>
		<path class="st41" d="M26.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,41.4c-0.8-0.2-0.8,3,0,2.8C24.2,44.4,24.2,41.2,23.4,41.4z"/>
		<path class="st41" d="M23.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,42c-0.8-0.2-0.8,3,0,2.8C25.2,45,25.2,41.9,24.4,42z"/>
		<path class="st41" d="M24.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,41.4c-0.8-0.2-0.8,3,0,2.8C22.2,44.4,22.2,41.2,21.4,41.4z"/>
		<path class="st41" d="M21.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,42c-0.8-0.2-0.8,3,0,2.8C23.3,45,23.3,41.9,22.4,42z"/>
		<path class="st41" d="M22.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,41.4c-0.8-0.2-0.8,3,0,2.8C20.2,44.4,20.2,41.2,19.4,41.4z"/>
		<path class="st41" d="M19.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,42c-0.8-0.2-0.8,3,0,2.8C21.3,45,21.3,41.9,20.4,42z"/>
		<path class="st41" d="M20.1,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,41.4c-0.8-0.2-0.8,3,0,2.8C18.2,44.4,18.2,41.2,17.4,41.4z"/>
		<path class="st41" d="M17.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,42c-0.8-0.2-0.8,3,0,2.8C19.3,45,19.3,41.9,18.4,42z"/>
		<path class="st41" d="M18.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,41.4c-0.8-0.2-0.8,3,0,2.8C16.2,44.4,16.2,41.2,15.4,41.4z"/>
		<path class="st41" d="M15.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,42c-0.8-0.2-0.8,3,0,2.8C17.3,45,17.3,41.9,16.5,42z"/>
		<path class="st41" d="M16.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,41.4c-0.8-0.2-0.8,3,0,2.8C14.3,44.4,14.3,41.2,13.4,41.4z"/>
		<path class="st41" d="M13.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,42c-0.8-0.2-0.8,3,0,2.8C15.3,45,15.3,41.9,14.5,42z"/>
		<path class="st41" d="M14.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,41.4c-0.8-0.2-0.8,3,0,2.8C12.3,44.4,12.3,41.2,11.4,41.4z"/>
		<path class="st41" d="M11.1,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,42c-0.8-0.2-0.8,3,0,2.8C13.3,45,13.3,41.9,12.5,42z"/>
		<path class="st41" d="M12.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,41.4c-0.8-0.2-0.8,3,0,2.8C10.3,44.4,10.3,41.2,9.5,41.4z"/>
		<path class="st41" d="M9.2,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,42c-0.8-0.2-0.8,3,0,2.8C11.3,45,11.3,41.9,10.5,42z"/>
		<path class="st41" d="M10.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,41.4c-0.8-0.2-0.8,3,0,2.8C8.3,44.4,8.3,41.2,7.5,41.4z"/>
		<path class="st41" d="M7.2,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,42c-0.8-0.2-0.8,3,0,2.8C9.3,45,9.3,41.9,8.5,42z"/>
		<path class="st41" d="M8.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,41.4c-0.8-0.2-0.8,3,0,2.8C6.3,44.4,6.3,41.2,5.5,41.4z"/>
		<path class="st41" d="M5.2,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,42c-0.8-0.2-0.8,3,0,2.8C7.4,45,7.4,41.9,6.5,42z"/>
		<path class="st41" d="M6.2,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,41.4c-0.8-0.2-0.8,3,0,2.8C4.3,44.4,4.3,41.2,3.5,41.4z"/>
		<path class="st41" d="M3.2,42.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,42c-0.8-0.2-0.8,3,0,2.8C5.4,45,5.4,41.9,4.5,42z"/>
		<path class="st41" d="M4.3,42.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,43c-0.8-0.2-0.8,3,0,2.8C65.8,46,65.8,42.8,65,43z"/>
		<path class="st41" d="M64.7,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,43.6c-0.8-0.2-0.8,3,0,2.8C66.9,46.6,66.9,43.5,66,43.6z"/>
		<path class="st41" d="M65.7,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,43c-0.8-0.2-0.8,3,0,2.8C63.8,46,63.8,42.8,63,43z"/>
		<path class="st41" d="M62.7,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,43.6c-0.8-0.2-0.8,3,0,2.8C64.9,46.6,64.9,43.5,64,43.6z"/>
		<path class="st41" d="M63.7,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,43c-0.8-0.2-0.8,3,0,2.8C61.8,46,61.8,42.8,61,43z"/>
		<path class="st41" d="M60.7,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,43.6c-0.8-0.2-0.8,3,0,2.8C62.9,46.6,62.9,43.5,62,43.6z"/>
		<path class="st41" d="M61.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,43c-0.8-0.2-0.8,3,0,2.8C59.8,46,59.8,42.8,59,43z"/>
		<path class="st41" d="M58.7,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,43.6c-0.8-0.2-0.8,3,0,2.8C60.9,46.6,60.9,43.5,60.1,43.6z"/>
		<path class="st41" d="M59.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,43c-0.8-0.2-0.8,3,0,2.8C57.9,46,57.9,42.8,57,43z"/>
		<path class="st41" d="M56.7,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,43.6c-0.8-0.2-0.8,3,0,2.8C58.9,46.6,58.9,43.5,58.1,43.6z"/>
		<path class="st41" d="M57.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,43c-0.8-0.2-0.8,3,0,2.8C55.9,46,55.9,42.8,55,43z"/>
		<path class="st41" d="M54.8,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,43.6c-0.8-0.2-0.8,3,0,2.8C56.9,46.6,56.9,43.5,56.1,43.6z"/>
		<path class="st41" d="M55.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,43c-0.8-0.2-0.8,3,0,2.8C53.9,46,53.9,42.8,53.1,43z"/>
		<path class="st41" d="M52.8,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,43.6c-0.8-0.2-0.8,3,0,2.8C54.9,46.6,54.9,43.5,54.1,43.6z"/>
		<path class="st41" d="M53.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,43c-0.8-0.2-0.8,3,0,2.8C51.9,46,51.9,42.8,51.1,43z"/>
		<path class="st41" d="M50.8,43.9C49.8,43.6,50,42.1,51,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,43.6c-0.8-0.2-0.8,3,0,2.8C52.9,46.6,52.9,43.5,52.1,43.6z"/>
		<path class="st41" d="M51.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,43c-0.8-0.2-0.8,3,0,2.8C49.9,46,49.9,42.8,49.1,43z"/>
		<path class="st41" d="M48.8,43.9C47.9,43.6,48,42.1,49,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,43.6c-0.8-0.2-0.8,3,0,2.8C51,46.6,51,43.5,50.1,43.6z"/>
		<path class="st41" d="M49.8,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,43c-0.8-0.2-0.8,3,0,2.8C47.9,46,47.9,42.8,47.1,43z"/>
		<path class="st41" d="M46.8,43.9C45.9,43.6,46,42.1,47,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,43.6c-0.8-0.2-0.8,3,0,2.8C49,46.6,49,43.5,48.1,43.6z"/>
		<path class="st41" d="M47.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,43c-0.8-0.2-0.8,3,0,2.8C45.9,46,45.9,42.8,45.1,43z"/>
		<path class="st41" d="M44.8,43.9C43.9,43.6,44,42.1,45,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,43.6c-0.8-0.2-0.8,3,0,2.8C47,46.6,47,43.5,46.2,43.6z"/>
		<path class="st41" d="M45.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,43c-0.8-0.2-0.8,3,0,2.8C43.9,46,43.9,42.8,43.1,43z"/>
		<path class="st41" d="M42.8,43.9C41.9,43.6,42,42.1,43,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,43.6c-0.8-0.2-0.8,3,0,2.8C45,46.6,45,43.5,44.2,43.6z"/>
		<path class="st41" d="M43.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,43c-0.8-0.2-0.8,3,0,2.8C42,46,42,42.8,41.1,43z"/>
		<path class="st41" d="M40.8,43.9C39.9,43.6,40,42.1,41,42c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,43.6c-0.8-0.2-0.8,3,0,2.8C43,46.6,43,43.5,42.2,43.6z"/>
		<path class="st41" d="M41.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,43c-0.8-0.2-0.8,3,0,2.8C40,46,40,42.8,39.1,43z"/>
		<path class="st41" d="M38.9,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,43.6c-0.8-0.2-0.8,3,0,2.8C41,46.6,41,43.5,40.2,43.6z"/>
		<path class="st41" d="M39.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,43c-0.8-0.2-0.8,3,0,2.8C38,46,38,42.8,37.2,43z"/>
		<path class="st41" d="M36.9,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,43.6c-0.8-0.2-0.8,3,0,2.8C39,46.6,39,43.5,38.2,43.6z"/>
		<path class="st41" d="M37.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,43c-0.8-0.2-0.8,3,0,2.8C36,46,36,42.8,35.2,43z"/>
		<path class="st41" d="M34.9,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,43.6c-0.8-0.2-0.8,3,0,2.8C37,46.6,37,43.5,36.2,43.6z"/>
		<path class="st41" d="M35.9,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,43c-0.8-0.2-0.8,3,0,2.8C34.1,46,34.1,42.8,33.3,43z"/>
		<path class="st41" d="M33,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,43.6c-0.8-0.2-0.8,3,0,2.8C35.2,46.6,35.2,43.5,34.3,43.6z"/>
		<path class="st41" d="M34.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,43c-0.8-0.2-0.8,3,0,2.8C32.1,46,32.1,42.8,31.3,43z"/>
		<path class="st41" d="M31,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,43.6c-0.8-0.2-0.8,3,0,2.8C33.2,46.6,33.2,43.5,32.4,43.6z"/>
		<path class="st41" d="M32.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,43c-0.8-0.2-0.8,3,0,2.8C30.1,46,30.2,42.8,29.3,43z"/>
		<path class="st41" d="M29,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,43.6c-0.8-0.2-0.8,3,0,2.8C31.2,46.6,31.2,43.5,30.4,43.6z"/>
		<path class="st41" d="M30.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,43c-0.8-0.2-0.8,3,0,2.8C28.2,46,28.2,42.8,27.3,43z"/>
		<path class="st41" d="M27,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,43.6c-0.8-0.2-0.8,3,0,2.8C29.2,46.6,29.2,43.5,28.4,43.6z"/>
		<path class="st41" d="M28.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,43c-0.8-0.2-0.8,3,0,2.8C26.2,46,26.2,42.8,25.3,43z"/>
		<path class="st41" d="M25.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,43.6c-0.8-0.2-0.8,3,0,2.8C27.2,46.6,27.2,43.5,26.4,43.6z"/>
		<path class="st41" d="M26.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,43c-0.8-0.2-0.8,3,0,2.8C24.2,46,24.2,42.8,23.4,43z"/>
		<path class="st41" d="M23.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,43.6c-0.8-0.2-0.8,3,0,2.8C25.2,46.6,25.2,43.5,24.4,43.6z"/>
		<path class="st41" d="M24.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,43c-0.8-0.2-0.8,3,0,2.8C22.2,46,22.2,42.8,21.4,43z"/>
		<path class="st41" d="M21.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,43.6c-0.8-0.2-0.8,3,0,2.8C23.3,46.6,23.3,43.5,22.4,43.6z"/>
		<path class="st41" d="M22.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,43c-0.8-0.2-0.8,3,0,2.8C20.2,46,20.2,42.8,19.4,43z"/>
		<path class="st41" d="M19.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,43.6c-0.8-0.2-0.8,3,0,2.8C21.3,46.6,21.3,43.5,20.4,43.6z"/>
		<path class="st41" d="M20.1,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,43c-0.8-0.2-0.8,3,0,2.8C18.2,46,18.2,42.8,17.4,43z"/>
		<path class="st41" d="M17.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,43.6c-0.8-0.2-0.8,3,0,2.8C19.3,46.6,19.3,43.5,18.4,43.6z"/>
		<path class="st41" d="M18.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,43c-0.8-0.2-0.8,3,0,2.8C16.2,46,16.2,42.8,15.4,43z"/>
		<path class="st41" d="M15.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,43.6c-0.8-0.2-0.8,3,0,2.8C17.3,46.6,17.3,43.5,16.5,43.6z"/>
		<path class="st41" d="M16.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,43c-0.8-0.2-0.8,3,0,2.8C14.3,46,14.3,42.8,13.4,43z"/>
		<path class="st41" d="M13.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,43.6c-0.8-0.2-0.8,3,0,2.8C15.3,46.6,15.3,43.5,14.5,43.6z"/>
		<path class="st41" d="M14.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,43c-0.8-0.2-0.8,3,0,2.8C12.3,46,12.3,42.8,11.4,43z"/>
		<path class="st41" d="M11.1,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,43.6c-0.8-0.2-0.8,3,0,2.8C13.3,46.6,13.3,43.5,12.5,43.6z"/>
		<path class="st41" d="M12.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,43c-0.8-0.2-0.8,3,0,2.8C10.3,46,10.3,42.8,9.5,43z"/>
		<path class="st41" d="M9.2,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,43.6c-0.8-0.2-0.8,3,0,2.8C11.3,46.6,11.3,43.5,10.5,43.6z"/>
		<path class="st41" d="M10.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,43c-0.8-0.2-0.8,3,0,2.8C8.3,46,8.3,42.8,7.5,43z"/>
		<path class="st41" d="M7.2,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,43.6c-0.8-0.2-0.8,3,0,2.8C9.3,46.6,9.3,43.5,8.5,43.6z"/>
		<path class="st41" d="M8.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,43c-0.8-0.2-0.8,3,0,2.8C6.3,46,6.3,42.8,5.5,43z"/>
		<path class="st41" d="M5.2,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,43.6c-0.8-0.2-0.8,3,0,2.8C7.4,46.6,7.4,43.5,6.5,43.6z"/>
		<path class="st41" d="M6.2,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,43c-0.8-0.2-0.8,3,0,2.8C4.3,46,4.3,42.8,3.5,43z"/>
		<path class="st41" d="M3.2,43.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,43.6c-0.8-0.2-0.8,3,0,2.8C5.4,46.6,5.4,43.5,4.5,43.6z"/>
		<path class="st41" d="M4.3,44.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,44.4c-0.8-0.2-0.8,3,0,2.8C65.8,47.4,65.8,44.2,65,44.4z"/>
		<path class="st41" d="M64.7,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,45.1c-0.8-0.2-0.8,3,0,2.8C66.9,48.1,66.9,44.9,66,45.1z"/>
		<path class="st41" d="M65.7,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,44.4c-0.8-0.2-0.8,3,0,2.8C63.8,47.4,63.8,44.2,63,44.4z"/>
		<path class="st41" d="M62.7,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,45.1c-0.8-0.2-0.8,3,0,2.8C64.9,48.1,64.9,44.9,64,45.1z"/>
		<path class="st41" d="M63.7,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,44.4c-0.8-0.2-0.8,3,0,2.8C61.8,47.4,61.8,44.2,61,44.4z"/>
		<path class="st41" d="M60.7,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,45.1c-0.8-0.2-0.8,3,0,2.8C62.9,48.1,62.9,44.9,62,45.1z"/>
		<path class="st41" d="M61.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,44.4c-0.8-0.2-0.8,3,0,2.8C59.8,47.4,59.8,44.2,59,44.4z"/>
		<path class="st41" d="M58.7,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,45.1c-0.8-0.2-0.8,3,0,2.8C60.9,48.1,60.9,44.9,60.1,45.1z"/>
		<path class="st41" d="M59.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,44.4c-0.8-0.2-0.8,3,0,2.8C57.9,47.4,57.9,44.2,57,44.4z"/>
		<path class="st41" d="M56.7,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,45.1c-0.8-0.2-0.8,3,0,2.8C58.9,48.1,58.9,44.9,58.1,45.1z"/>
		<path class="st41" d="M57.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,44.4c-0.8-0.2-0.8,3,0,2.8C55.9,47.4,55.9,44.2,55,44.4z"/>
		<path class="st41" d="M54.8,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,45.1c-0.8-0.2-0.8,3,0,2.8C56.9,48.1,56.9,44.9,56.1,45.1z"/>
		<path class="st41" d="M55.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,44.4c-0.8-0.2-0.8,3,0,2.8C53.9,47.4,53.9,44.2,53.1,44.4z"/>
		<path class="st41" d="M52.8,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,45.1c-0.8-0.2-0.8,3,0,2.8C54.9,48.1,54.9,44.9,54.1,45.1z"/>
		<path class="st41" d="M53.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,44.4c-0.8-0.2-0.8,3,0,2.8C51.9,47.4,51.9,44.2,51.1,44.4z"/>
		<path class="st41" d="M50.8,45.3C49.8,45,50,43.5,51,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,45.1c-0.8-0.2-0.8,3,0,2.8C52.9,48.1,52.9,44.9,52.1,45.1z"/>
		<path class="st41" d="M51.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,44.4c-0.8-0.2-0.8,3,0,2.8C49.9,47.4,49.9,44.2,49.1,44.4z"/>
		<path class="st41" d="M48.8,45.3C47.9,45,48,43.5,49,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,45.1c-0.8-0.2-0.8,3,0,2.8C51,48.1,51,44.9,50.1,45.1z"/>
		<path class="st41" d="M49.8,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,44.4c-0.8-0.2-0.8,3,0,2.8C47.9,47.4,47.9,44.2,47.1,44.4z"/>
		<path class="st41" d="M46.8,45.3C45.9,45,46,43.5,47,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,45.1c-0.8-0.2-0.8,3,0,2.8C49,48.1,49,44.9,48.1,45.1z"/>
		<path class="st41" d="M47.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,44.4c-0.8-0.2-0.8,3,0,2.8C45.9,47.4,45.9,44.2,45.1,44.4z"/>
		<path class="st41" d="M44.8,45.3C43.9,45,44,43.5,45,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,45.1c-0.8-0.2-0.8,3,0,2.8C47,48.1,47,44.9,46.2,45.1z"/>
		<path class="st41" d="M45.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,44.4c-0.8-0.2-0.8,3,0,2.8C43.9,47.4,43.9,44.2,43.1,44.4z"/>
		<path class="st41" d="M42.8,45.3C41.9,45,42,43.5,43,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,45.1c-0.8-0.2-0.8,3,0,2.8C45,48.1,45,44.9,44.2,45.1z"/>
		<path class="st41" d="M43.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,44.4c-0.8-0.2-0.8,3,0,2.8C42,47.4,42,44.2,41.1,44.4z"/>
		<path class="st41" d="M40.8,45.3C39.9,45,40,43.5,41,43.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,45.1c-0.8-0.2-0.8,3,0,2.8C43,48.1,43,44.9,42.2,45.1z"/>
		<path class="st41" d="M41.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,44.4c-0.8-0.2-0.8,3,0,2.8C40,47.4,40,44.2,39.1,44.4z"/>
		<path class="st41" d="M38.9,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,45.1c-0.8-0.2-0.8,3,0,2.8C41,48.1,41,44.9,40.2,45.1z"/>
		<path class="st41" d="M39.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,44.4c-0.8-0.2-0.8,3,0,2.8C38,47.4,38,44.2,37.2,44.4z"/>
		<path class="st41" d="M36.9,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,45.1c-0.8-0.2-0.8,3,0,2.8C39,48.1,39,44.9,38.2,45.1z"/>
		<path class="st41" d="M37.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,44.4c-0.8-0.2-0.8,3,0,2.8C36,47.4,36,44.2,35.2,44.4z"/>
		<path class="st41" d="M34.9,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,45.1c-0.8-0.2-0.8,3,0,2.8C37,48.1,37,44.9,36.2,45.1z"/>
		<path class="st41" d="M35.9,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,44.4c-0.8-0.2-0.8,3,0,2.8C34.1,47.4,34.1,44.2,33.3,44.4z"/>
		<path class="st41" d="M33,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,45.1c-0.8-0.2-0.8,3,0,2.8C35.2,48.1,35.2,44.9,34.3,45.1z"/>
		<path class="st41" d="M34.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,44.4c-0.8-0.2-0.8,3,0,2.8C32.1,47.4,32.1,44.2,31.3,44.4z"/>
		<path class="st41" d="M31,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,45.1c-0.8-0.2-0.8,3,0,2.8C33.2,48.1,33.2,44.9,32.4,45.1z"/>
		<path class="st41" d="M32.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,44.4c-0.8-0.2-0.8,3,0,2.8C30.1,47.4,30.2,44.2,29.3,44.4z"/>
		<path class="st41" d="M29,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,45.1c-0.8-0.2-0.8,3,0,2.8C31.2,48.1,31.2,44.9,30.4,45.1z"/>
		<path class="st41" d="M30.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,44.4c-0.8-0.2-0.8,3,0,2.8C28.2,47.4,28.2,44.2,27.3,44.4z"/>
		<path class="st41" d="M27,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,45.1c-0.8-0.2-0.8,3,0,2.8C29.2,48.1,29.2,44.9,28.4,45.1z"/>
		<path class="st41" d="M28.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,44.4c-0.8-0.2-0.8,3,0,2.8C26.2,47.4,26.2,44.2,25.3,44.4z"/>
		<path class="st41" d="M25.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,45.1c-0.8-0.2-0.8,3,0,2.8C27.2,48.1,27.2,44.9,26.4,45.1z"/>
		<path class="st41" d="M26.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,44.4c-0.8-0.2-0.8,3,0,2.8C24.2,47.4,24.2,44.2,23.4,44.4z"/>
		<path class="st41" d="M23.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,45.1c-0.8-0.2-0.8,3,0,2.8C25.2,48.1,25.2,44.9,24.4,45.1z"/>
		<path class="st41" d="M24.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,44.4c-0.8-0.2-0.8,3,0,2.8C22.2,47.4,22.2,44.2,21.4,44.4z"/>
		<path class="st41" d="M21.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,45.1c-0.8-0.2-0.8,3,0,2.8C23.3,48.1,23.3,44.9,22.4,45.1z"/>
		<path class="st41" d="M22.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,44.4c-0.8-0.2-0.8,3,0,2.8C20.2,47.4,20.2,44.2,19.4,44.4z"/>
		<path class="st41" d="M19.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,45.1c-0.8-0.2-0.8,3,0,2.8C21.3,48.1,21.3,44.9,20.4,45.1z"/>
		<path class="st41" d="M20.1,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,44.4c-0.8-0.2-0.8,3,0,2.8C18.2,47.4,18.2,44.2,17.4,44.4z"/>
		<path class="st41" d="M17.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,45.1c-0.8-0.2-0.8,3,0,2.8C19.3,48.1,19.3,44.9,18.4,45.1z"/>
		<path class="st41" d="M18.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,44.4c-0.8-0.2-0.8,3,0,2.8C16.2,47.4,16.2,44.2,15.4,44.4z"/>
		<path class="st41" d="M15.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,45.1c-0.8-0.2-0.8,3,0,2.8C17.3,48.1,17.3,44.9,16.5,45.1z"/>
		<path class="st41" d="M16.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,44.4c-0.8-0.2-0.8,3,0,2.8C14.3,47.4,14.3,44.2,13.4,44.4z"/>
		<path class="st41" d="M13.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,45.1c-0.8-0.2-0.8,3,0,2.8C15.3,48.1,15.3,44.9,14.5,45.1z"/>
		<path class="st41" d="M14.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,44.4c-0.8-0.2-0.8,3,0,2.8C12.3,47.4,12.3,44.2,11.4,44.4z"/>
		<path class="st41" d="M11.1,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,45.1c-0.8-0.2-0.8,3,0,2.8C13.3,48.1,13.3,44.9,12.5,45.1z"/>
		<path class="st41" d="M12.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,44.4c-0.8-0.2-0.8,3,0,2.8C10.3,47.4,10.3,44.2,9.5,44.4z"/>
		<path class="st41" d="M9.2,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,45.1c-0.8-0.2-0.8,3,0,2.8C11.3,48.1,11.3,44.9,10.5,45.1z"/>
		<path class="st41" d="M10.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,44.4c-0.8-0.2-0.8,3,0,2.8C8.3,47.4,8.3,44.2,7.5,44.4z"/>
		<path class="st41" d="M7.2,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,45.1c-0.8-0.2-0.8,3,0,2.8C9.3,48.1,9.3,44.9,8.5,45.1z"/>
		<path class="st41" d="M8.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,44.4c-0.8-0.2-0.8,3,0,2.8C6.3,47.4,6.3,44.2,5.5,44.4z"/>
		<path class="st41" d="M5.2,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,45.1c-0.8-0.2-0.8,3,0,2.8C7.4,48.1,7.4,44.9,6.5,45.1z"/>
		<path class="st41" d="M6.2,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,44.4c-0.8-0.2-0.8,3,0,2.8C4.3,47.4,4.3,44.2,3.5,44.4z"/>
		<path class="st41" d="M3.2,45.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,45.1c-0.8-0.2-0.8,3,0,2.8C5.4,48.1,5.4,44.9,4.5,45.1z"/>
		<path class="st41" d="M4.3,46c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,45.8c-0.8-0.2-0.8,3,0,2.8C65.8,48.8,65.8,45.6,65,45.8z"/>
		<path class="st41" d="M64.7,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M66,46.5c-0.8-0.2-0.8,3,0,2.8C66.9,49.5,66.9,46.3,66,46.5z"/>
		<path class="st41" d="M65.7,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,45.8c-0.8-0.2-0.8,3,0,2.8C63.8,48.8,63.8,45.6,63,45.8z"/>
		<path class="st41" d="M62.7,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M64,46.5c-0.8-0.2-0.8,3,0,2.8C64.9,49.5,64.9,46.3,64,46.5z"/>
		<path class="st41" d="M63.7,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,45.8c-0.8-0.2-0.8,3,0,2.8C61.8,48.8,61.8,45.6,61,45.8z"/>
		<path class="st41" d="M60.7,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,45.8c-0.8-0.2-0.8,3,0,2.8C59.8,48.8,59.8,45.6,59,45.8z"/>
		<path class="st41" d="M58.7,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,46.5c-0.8-0.2-0.8,3,0,2.8C60.9,49.5,60.9,46.3,60.1,46.5z"/>
		<path class="st41" d="M59.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,45.8c-0.8-0.2-0.8,3,0,2.8C57.9,48.8,57.9,45.6,57,45.8z"/>
		<path class="st41" d="M56.7,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,46.5c-0.8-0.2-0.8,3,0,2.8C58.9,49.5,58.9,46.3,58.1,46.5z"/>
		<path class="st41" d="M57.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,45.8c-0.8-0.2-0.8,3,0,2.8C55.9,48.8,55.9,45.6,55,45.8z"/>
		<path class="st41" d="M54.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,46.5c-0.8-0.2-0.8,3,0,2.8C56.9,49.5,56.9,46.3,56.1,46.5z"/>
		<path class="st41" d="M55.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,45.8c-0.8-0.2-0.8,3,0,2.8C53.9,48.8,53.9,45.6,53.1,45.8z"/>
		<path class="st41" d="M52.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,46.5c-0.8-0.2-0.8,3,0,2.8C54.9,49.5,54.9,46.3,54.1,46.5z"/>
		<path class="st41" d="M53.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,45.8c-0.8-0.2-0.8,3,0,2.8C51.9,48.8,51.9,45.6,51.1,45.8z"/>
		<path class="st41" d="M50.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,46.5c-0.8-0.2-0.8,3,0,2.8C52.9,49.5,52.9,46.3,52.1,46.5z"/>
		<path class="st41" d="M51.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,45.8c-0.8-0.2-0.8,3,0,2.8C49.9,48.8,49.9,45.6,49.1,45.8z"/>
		<path class="st41" d="M48.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,46.5c-0.8-0.2-0.8,3,0,2.8C51,49.5,51,46.3,50.1,46.5z"/>
		<path class="st41" d="M49.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,45.8c-0.8-0.2-0.8,3,0,2.8C47.9,48.8,47.9,45.6,47.1,45.8z"/>
		<path class="st41" d="M46.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,46.5c-0.8-0.2-0.8,3,0,2.8C49,49.5,49,46.3,48.1,46.5z"/>
		<path class="st41" d="M47.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,45.8c-0.8-0.2-0.8,3,0,2.8C45.9,48.8,45.9,45.6,45.1,45.8z"/>
		<path class="st41" d="M44.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,46.5c-0.8-0.2-0.8,3,0,2.8C47,49.5,47,46.3,46.2,46.5z"/>
		<path class="st41" d="M45.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,45.8c-0.8-0.2-0.8,3,0,2.8C43.9,48.8,43.9,45.6,43.1,45.8z"/>
		<path class="st41" d="M42.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,46.5c-0.8-0.2-0.8,3,0,2.8C45,49.5,45,46.3,44.2,46.5z"/>
		<path class="st41" d="M43.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,45.8c-0.8-0.2-0.8,3,0,2.8C42,48.8,42,45.6,41.1,45.8z"/>
		<path class="st41" d="M40.8,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,46.5c-0.8-0.2-0.8,3,0,2.8C43,49.5,43,46.3,42.2,46.5z"/>
		<path class="st41" d="M41.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,45.8c-0.8-0.2-0.8,3,0,2.8C40,48.8,40,45.6,39.1,45.8z"/>
		<path class="st41" d="M38.9,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,46.5c-0.8-0.2-0.8,3,0,2.8C41,49.5,41,46.3,40.2,46.5z"/>
		<path class="st41" d="M39.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,45.8c-0.8-0.2-0.8,3,0,2.8C38,48.8,38,45.6,37.2,45.8z"/>
		<path class="st41" d="M36.9,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,46.5c-0.8-0.2-0.8,3,0,2.8C39,49.5,39,46.3,38.2,46.5z"/>
		<path class="st41" d="M37.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,45.8c-0.8-0.2-0.8,3,0,2.8C36,48.8,36,45.6,35.2,45.8z"/>
		<path class="st41" d="M34.9,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,46.5c-0.8-0.2-0.8,3,0,2.8C37,49.5,37,46.3,36.2,46.5z"/>
		<path class="st41" d="M35.9,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,45.8c-0.8-0.2-0.8,3,0,2.8C34.1,48.8,34.1,45.6,33.3,45.8z"/>
		<path class="st41" d="M33,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,46.5c-0.8-0.2-0.8,3,0,2.8C35.2,49.5,35.2,46.3,34.3,46.5z"/>
		<path class="st41" d="M34.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,45.8c-0.8-0.2-0.8,3,0,2.8C32.1,48.8,32.1,45.6,31.3,45.8z"/>
		<path class="st41" d="M31,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,46.5c-0.8-0.2-0.8,3,0,2.8C33.2,49.5,33.2,46.3,32.4,46.5z"/>
		<path class="st41" d="M32.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,45.8c-0.8-0.2-0.8,3,0,2.8C30.1,48.8,30.2,45.6,29.3,45.8z"/>
		<path class="st41" d="M29,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,46.5c-0.8-0.2-0.8,3,0,2.8C31.2,49.5,31.2,46.3,30.4,46.5z"/>
		<path class="st41" d="M30.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,45.8c-0.8-0.2-0.8,3,0,2.8C28.2,48.8,28.2,45.6,27.3,45.8z"/>
		<path class="st41" d="M27,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,46.5c-0.8-0.2-0.8,3,0,2.8C29.2,49.5,29.2,46.3,28.4,46.5z"/>
		<path class="st41" d="M28.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,45.8c-0.8-0.2-0.8,3,0,2.8C26.2,48.8,26.2,45.6,25.3,45.8z"/>
		<path class="st41" d="M25.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,46.5c-0.8-0.2-0.8,3,0,2.8C27.2,49.5,27.2,46.3,26.4,46.5z"/>
		<path class="st41" d="M26.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,45.8c-0.8-0.2-0.8,3,0,2.8C24.2,48.8,24.2,45.6,23.4,45.8z"/>
		<path class="st41" d="M23.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,46.5c-0.8-0.2-0.8,3,0,2.8C25.2,49.5,25.2,46.3,24.4,46.5z"/>
		<path class="st41" d="M24.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,45.8c-0.8-0.2-0.8,3,0,2.8C22.2,48.8,22.2,45.6,21.4,45.8z"/>
		<path class="st41" d="M21.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,46.5c-0.8-0.2-0.8,3,0,2.8C23.3,49.5,23.3,46.3,22.4,46.5z"/>
		<path class="st41" d="M22.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,45.8c-0.8-0.2-0.8,3,0,2.8C20.2,48.8,20.2,45.6,19.4,45.8z"/>
		<path class="st41" d="M19.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,46.5c-0.8-0.2-0.8,3,0,2.8C21.3,49.5,21.3,46.3,20.4,46.5z"/>
		<path class="st41" d="M20.1,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,45.8c-0.8-0.2-0.8,3,0,2.8C18.2,48.8,18.2,45.6,17.4,45.8z"/>
		<path class="st41" d="M17.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,46.5c-0.8-0.2-0.8,3,0,2.8C19.3,49.5,19.3,46.3,18.4,46.5z"/>
		<path class="st41" d="M18.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,45.8c-0.8-0.2-0.8,3,0,2.8C16.2,48.8,16.2,45.6,15.4,45.8z"/>
		<path class="st41" d="M15.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,46.5c-0.8-0.2-0.8,3,0,2.8C17.3,49.5,17.3,46.3,16.5,46.5z"/>
		<path class="st41" d="M16.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,45.8c-0.8-0.2-0.8,3,0,2.8C14.3,48.8,14.3,45.6,13.4,45.8z"/>
		<path class="st41" d="M13.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,46.5c-0.8-0.2-0.8,3,0,2.8C15.3,49.5,15.3,46.3,14.5,46.5z"/>
		<path class="st41" d="M14.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,45.8c-0.8-0.2-0.8,3,0,2.8C12.3,48.8,12.3,45.6,11.4,45.8z"/>
		<path class="st41" d="M11.1,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,46.5c-0.8-0.2-0.8,3,0,2.8C13.3,49.5,13.3,46.3,12.5,46.5z"/>
		<path class="st41" d="M12.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,45.8c-0.8-0.2-0.8,3,0,2.8C10.3,48.8,10.3,45.6,9.5,45.8z"/>
		<path class="st41" d="M9.2,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,46.5c-0.8-0.2-0.8,3,0,2.8C11.3,49.5,11.3,46.3,10.5,46.5z"/>
		<path class="st41" d="M10.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,45.8c-0.8-0.2-0.8,3,0,2.8C8.3,48.8,8.3,45.6,7.5,45.8z"/>
		<path class="st41" d="M7.2,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,46.5c-0.8-0.2-0.8,3,0,2.8C9.3,49.5,9.3,46.3,8.5,46.5z"/>
		<path class="st41" d="M8.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,45.8c-0.8-0.2-0.8,3,0,2.8C6.3,48.8,6.3,45.6,5.5,45.8z"/>
		<path class="st41" d="M5.2,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,46.5c-0.8-0.2-0.8,3,0,2.8C7.4,49.5,7.4,46.3,6.5,46.5z"/>
		<path class="st41" d="M6.2,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,45.8c-0.8-0.2-0.8,3,0,2.8C4.3,48.8,4.3,45.6,3.5,45.8z"/>
		<path class="st41" d="M3.2,46.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,46.5c-0.8-0.2-0.8,3,0,2.8C5.4,49.5,5.4,46.3,4.5,46.5z"/>
		<path class="st41" d="M4.3,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M37.2,47.4c-0.8-0.2-0.8,3,0,2.8C38,50.4,38,47.2,37.2,47.4z"/>
		<path class="st42" d="M36.9,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M35.2,47.4c-0.8-0.2-0.8,3,0,2.8C36,50.4,36,47.2,35.2,47.4z"/>
		<path class="st42" d="M34.9,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M33.3,47.4c-0.8-0.2-0.8,3,0,2.8C34.1,50.4,34.1,47.2,33.3,47.4z"/>
		<path class="st42" d="M33,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M31.3,47.4c-0.8-0.2-0.8,3,0,2.8C32.1,50.4,32.1,47.2,31.3,47.4z"/>
		<path class="st42" d="M31,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M29.3,47.4c-0.8-0.2-0.8,3,0,2.8C30.1,50.4,30.2,47.2,29.3,47.4z"/>
		<path class="st42" d="M29,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M27.3,47.4c-0.8-0.2-0.8,3,0,2.8C28.2,50.4,28.2,47.2,27.3,47.4z"/>
		<path class="st42" d="M27,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M25.3,47.4c-0.8-0.2-0.8,3,0,2.8C26.2,50.4,26.2,47.2,25.3,47.4z"/>
		<path class="st42" d="M25.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M23.4,47.4c-0.8-0.2-0.8,3,0,2.8C24.2,50.4,24.2,47.2,23.4,47.4z"/>
		<path class="st42" d="M23.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M21.4,47.4c-0.8-0.2-0.8,3,0,2.8C22.2,50.4,22.2,47.2,21.4,47.4z"/>
		<path class="st42" d="M21.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M19.4,47.4c-0.8-0.2-0.8,3,0,2.8C20.2,50.4,20.2,47.2,19.4,47.4z"/>
		<path class="st42" d="M19.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M17.4,47.4c-0.8-0.2-0.8,3,0,2.8C18.2,50.4,18.2,47.2,17.4,47.4z"/>
		<path class="st42" d="M17.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M15.4,47.4c-0.8-0.2-0.8,3,0,2.8C16.2,50.4,16.2,47.2,15.4,47.4z"/>
		<path class="st42" d="M15.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M13.4,47.4c-0.8-0.2-0.8,3,0,2.8C14.3,50.4,14.3,47.2,13.4,47.4z"/>
		<path class="st42" d="M13.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M11.4,47.4c-0.8-0.2-0.8,3,0,2.8C12.3,50.4,12.3,47.2,11.4,47.4z"/>
		<path class="st42" d="M11.1,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M9.5,47.4c-0.8-0.2-0.8,3,0,2.8C10.3,50.4,10.3,47.2,9.5,47.4z"/>
		<path class="st42" d="M9.2,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.5,47.4c-0.8-0.2-0.8,3,0,2.8C8.3,50.4,8.3,47.2,7.5,47.4z"/>
		<path class="st42" d="M7.2,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.5,47.4c-0.8-0.2-0.8,3,0,2.8C6.3,50.4,6.3,47.2,5.5,47.4z"/>
		<path class="st42" d="M5.2,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,47.4c-0.8-0.2-0.8,3,0,2.8C4.3,50.4,4.3,47.2,3.5,47.4z"/>
		<path class="st42" d="M3.2,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M62,46.5c-0.8-0.2-0.8,3,0,2.8C62.9,49.5,62.9,46.3,62,46.5z"/>
		<path class="st41" d="M61.8,47.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M65,47.4c-0.8-0.2-0.8,3,0,2.8C65.8,50.4,65.8,47.2,65,47.4z"/>
		<path class="st41" d="M64.7,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M63,47.4c-0.8-0.2-0.8,3,0,2.8C63.8,50.4,63.8,47.2,63,47.4z"/>
		<path class="st41" d="M62.7,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M61,47.4c-0.8-0.2-0.8,3,0,2.8C61.8,50.4,61.8,47.2,61,47.4z"/>
		<path class="st41" d="M60.7,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M59,47.4c-0.8-0.2-0.8,3,0,2.8C59.8,50.4,59.8,47.2,59,47.4z"/>
		<path class="st41" d="M58.7,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M57,47.4c-0.8-0.2-0.8,3,0,2.8C57.9,50.4,57.9,47.2,57,47.4z"/>
		<path class="st41" d="M56.7,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M55,47.4c-0.8-0.2-0.8,3,0,2.8C55.9,50.4,55.9,47.2,55,47.4z"/>
		<path class="st41" d="M54.8,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M53.1,47.4c-0.8-0.2-0.8,3,0,2.8C53.9,50.4,53.9,47.2,53.1,47.4z"/>
		<path class="st41" d="M52.8,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M51.1,47.4c-0.8-0.2-0.8,3,0,2.8C51.9,50.4,51.9,47.2,51.1,47.4z"/>
		<path class="st41" d="M50.8,48.3C49.8,48,50,46.5,51,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M49.1,47.4c-0.8-0.2-0.8,3,0,2.8C49.9,50.4,49.9,47.2,49.1,47.4z"/>
		<path class="st41" d="M48.8,48.3C47.9,48,48,46.5,49,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M47.1,47.4c-0.8-0.2-0.8,3,0,2.8C47.9,50.4,47.9,47.2,47.1,47.4z"/>
		<path class="st41" d="M46.8,48.3C45.9,48,46,46.5,47,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M45.1,47.4c-0.8-0.2-0.8,3,0,2.8C45.9,50.4,45.9,47.2,45.1,47.4z"/>
		<path class="st41" d="M44.8,48.3C43.9,48,44,46.5,45,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M43.1,47.4c-0.8-0.2-0.8,3,0,2.8C43.9,50.4,43.9,47.2,43.1,47.4z"/>
		<path class="st42" d="M42.8,48.3C41.9,48,42,46.5,43,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M41.1,47.4c-0.8-0.2-0.8,3,0,2.8C42,50.4,42,47.2,41.1,47.4z"/>
		<path class="st42" d="M40.8,48.3C39.9,48,40,46.5,41,46.4c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M39.1,47.4c-0.8-0.2-0.8,3,0,2.8C40,50.4,40,47.2,39.1,47.4z"/>
		<path class="st42" d="M38.9,48.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st40" d="M66,8.8c-0.8-0.2-0.8,3,0,2.8C66.9,11.8,66.9,8.7,66,8.8z"/>
		<path class="st41" d="M65.7,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,8.8c-0.8-0.2-0.8,3,0,2.8C64.9,11.8,64.9,8.7,64,8.8z"/>
		<path class="st41" d="M63.7,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,8.8c-0.8-0.2-0.8,3,0,2.8C62.9,11.8,62.9,8.7,62,8.8z"/>
		<path class="st41" d="M61.8,9.7C60.8,9.4,61,8,61.9,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,8.8c-0.8-0.2-0.8,3,0,2.8C60.9,11.8,60.9,8.7,60.1,8.8z"/>
		<path class="st41" d="M59.8,9.7C58.8,9.4,59,8,60,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,8.8c-0.8-0.2-0.8,3,0,2.8C58.9,11.8,58.9,8.7,58.1,8.8z"/>
		<path class="st41" d="M57.8,9.7C56.9,9.4,57,8,58,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,8.8c-0.8-0.2-0.8,3,0,2.8C56.9,11.8,56.9,8.7,56.1,8.8z"/>
		<path class="st41" d="M55.8,9.7C54.9,9.4,55,8,56,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,8.8c-0.8-0.2-0.8,3,0,2.8C54.9,11.8,54.9,8.7,54.1,8.8z"/>
		<path class="st41" d="M53.8,9.7C52.9,9.4,53,8,54,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,8.8c-0.8-0.2-0.8,3,0,2.8C52.9,11.8,52.9,8.7,52.1,8.8z"/>
		<path class="st41" d="M51.8,9.7C50.9,9.4,51,8,52,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,8.8c-0.8-0.2-0.8,3,0,2.8C51,11.8,51,8.7,50.1,8.8z"/>
		<path class="st41" d="M49.8,9.7C48.9,9.4,49,8,50,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,8.8c-0.8-0.2-0.8,3,0,2.8C49,11.8,49,8.7,48.1,8.8z"/>
		<path class="st41" d="M47.9,9.7C46.9,9.4,47,8,48,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,8.8c-0.8-0.2-0.8,3,0,2.8C47,11.8,47,8.7,46.2,8.8z"/>
		<path class="st41" d="M45.9,9.7C44.9,9.4,45.1,8,46,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,8.8c-0.8-0.2-0.8,3,0,2.8C45,11.8,45,8.7,44.2,8.8z"/>
		<path class="st41" d="M43.9,9.7C43,9.4,43.1,8,44.1,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,8.8c-0.8-0.2-0.8,3,0,2.8C43,11.8,43,8.7,42.2,8.8z"/>
		<path class="st41" d="M41.9,9.7C41,9.4,41.1,8,42.1,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,8.8c-0.8-0.2-0.8,3,0,2.8C41,11.8,41,8.7,40.2,8.8z"/>
		<path class="st41" d="M39.9,9.7C39,9.4,39.1,8,40.1,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,8.8c-0.8-0.2-0.8,3,0,2.8C39,11.8,39,8.7,38.2,8.8z"/>
		<path class="st41" d="M37.9,9.7C37,9.4,37.1,8,38.1,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,8.8c-0.8-0.2-0.8,3,0,2.8C37,11.8,37,8.7,36.2,8.8z"/>
		<path class="st41" d="M35.9,9.7C35,9.4,35.1,8,36.1,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,8.8c-0.8-0.2-0.8,3,0,2.8C35.2,11.8,35.2,8.7,34.3,8.8z"/>
		<path class="st41" d="M34.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,8.8c-0.8-0.2-0.8,3,0,2.8C33.2,11.8,33.2,8.7,32.4,8.8z"/>
		<path class="st41" d="M32.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,8.8c-0.8-0.2-0.8,3,0,2.8C31.2,11.8,31.2,8.7,30.4,8.8z"/>
		<path class="st41" d="M30.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,8.8c-0.8-0.2-0.8,3,0,2.8C29.2,11.8,29.2,8.7,28.4,8.8z"/>
		<path class="st41" d="M28.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,8.8c-0.8-0.2-0.8,3,0,2.8C27.2,11.8,27.2,8.7,26.4,8.8z"/>
		<path class="st41" d="M26.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,8.8c-0.8-0.2-0.8,3,0,2.8C25.2,11.8,25.2,8.7,24.4,8.8z"/>
		<path class="st41" d="M24.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,8.1c-0.8-0.2-0.8,3,0,2.8C22.2,11.2,22.2,8,21.4,8.1z"/>
		<path class="st41" d="M21.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,8.8c-0.8-0.2-0.8,3,0,2.8C23.3,11.8,23.3,8.7,22.4,8.8z"/>
		<path class="st41" d="M22.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,8.1c-0.8-0.2-0.8,3,0,2.8C20.2,11.2,20.2,8,19.4,8.1z"/>
		<path class="st41" d="M19.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,8.8c-0.8-0.2-0.8,3,0,2.8C21.3,11.8,21.3,8.7,20.4,8.8z"/>
		<path class="st41" d="M20.1,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,8.1c-0.8-0.2-0.8,3,0,2.8C18.2,11.2,18.2,8,17.4,8.1z"/>
		<path class="st41" d="M17.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,8.8c-0.8-0.2-0.8,3,0,2.8C19.3,11.8,19.3,8.7,18.4,8.8z"/>
		<path class="st41" d="M18.2,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,8.1c-0.8-0.2-0.8,3,0,2.8C16.2,11.2,16.2,8,15.4,8.1z"/>
		<path class="st41" d="M15.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,8.8c-0.8-0.2-0.8,3,0,2.8C17.3,11.8,17.3,8.7,16.5,8.8z"/>
		<path class="st41" d="M16.2,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,8.1c-0.8-0.2-0.8,3,0,2.8C14.3,11.2,14.3,8,13.4,8.1z"/>
		<path class="st41" d="M13.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,8.8c-0.8-0.2-0.8,3,0,2.8C15.3,11.8,15.3,8.7,14.5,8.8z"/>
		<path class="st41" d="M14.2,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,8.1c-0.8-0.2-0.8,3,0,2.8C12.3,11.2,12.3,8,11.4,8.1z"/>
		<path class="st41" d="M11.1,9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,8.8c-0.8-0.2-0.8,3,0,2.8C13.3,11.8,13.3,8.7,12.5,8.8z"/>
		<path class="st41" d="M12.2,9.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,8.1c-0.8-0.2-0.8,3,0,2.8C10.3,11.2,10.3,8,9.5,8.1z"/>
		<path class="st41" d="M9.2,9C8.2,8.8,8.4,7.3,9.3,7.2c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,8.8c-0.8-0.2-0.8,3,0,2.8C11.3,11.8,11.3,8.7,10.5,8.8z"/>
		<path class="st41" d="M10.2,9.7C9.3,9.4,9.4,8,10.4,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,8.1c-0.8-0.2-0.8,3,0,2.8C8.3,11.2,8.3,8,7.5,8.1z"/>
		<path class="st41" d="M7.2,9C6.2,8.8,6.4,7.3,7.4,7.2c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,8.8c-0.8-0.2-0.8,3,0,2.8C9.3,11.8,9.3,8.7,8.5,8.8z"/>
		<path class="st41" d="M8.2,9.7C7.3,9.4,7.4,8,8.4,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,8.1c-0.8-0.2-0.8,3,0,2.8C6.3,11.2,6.3,8,5.5,8.1z"/>
		<path class="st41" d="M5.2,9C4.3,8.8,4.4,7.3,5.4,7.2c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,8.8c-0.8-0.2-0.8,3,0,2.8C7.4,11.8,7.4,8.7,6.5,8.8z"/>
		<path class="st41" d="M6.2,9.7C5.3,9.4,5.4,8,6.4,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,8.1c-0.8-0.2-0.8,3,0,2.8C4.3,11.2,4.3,8,3.5,8.1z"/>
		<path class="st41" d="M3.2,9C2.3,8.8,2.4,7.3,3.4,7.2C4.4,7.1,4.8,8.5,4,9"/>
	</g>
	<g>
		<path class="st40" d="M4.5,8.8c-0.8-0.2-0.8,3,0,2.8C5.4,11.8,5.4,8.7,4.5,8.8z"/>
		<path class="st41" d="M4.3,9.7C3.3,9.4,3.4,8,4.4,7.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,9.6c-0.8-0.2-0.8,3,0,2.8C65.8,12.6,65.8,9.4,65,9.6z"/>
		<path class="st41" d="M64.7,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,10.2c-0.8-0.2-0.8,3,0,2.8C66.9,13.2,66.9,10.1,66,10.2z"/>
		<path class="st41" d="M65.7,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,9.6c-0.8-0.2-0.8,3,0,2.8C63.8,12.6,63.8,9.4,63,9.6z"/>
		<path class="st41" d="M62.7,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,10.2c-0.8-0.2-0.8,3,0,2.8C64.9,13.2,64.9,10.1,64,10.2z"/>
		<path class="st41" d="M63.7,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,9.6c-0.8-0.2-0.8,3,0,2.8C61.8,12.6,61.8,9.4,61,9.6z"/>
		<path class="st41" d="M60.7,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,10.2c-0.8-0.2-0.8,3,0,2.8C62.9,13.2,62.9,10.1,62,10.2z"/>
		<path class="st41" d="M61.8,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,9.6c-0.8-0.2-0.8,3,0,2.8C59.8,12.6,59.8,9.4,59,9.6z"/>
		<path class="st41" d="M58.7,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,10.2c-0.8-0.2-0.8,3,0,2.8C60.9,13.2,60.9,10.1,60.1,10.2z"/>
		<path class="st41" d="M59.8,11.1C58.8,10.9,59,9.4,60,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,9.6c-0.8-0.2-0.8,3,0,2.8C57.9,12.6,57.9,9.4,57,9.6z"/>
		<path class="st41" d="M56.7,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,10.2c-0.8-0.2-0.8,3,0,2.8C58.9,13.2,58.9,10.1,58.1,10.2z"/>
		<path class="st41" d="M57.8,11.1C56.9,10.9,57,9.4,58,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,9.6c-0.8-0.2-0.8,3,0,2.8C55.9,12.6,55.9,9.4,55,9.6z"/>
		<path class="st41" d="M54.8,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,10.2c-0.8-0.2-0.8,3,0,2.8C56.9,13.2,56.9,10.1,56.1,10.2z"/>
		<path class="st41" d="M55.8,11.1C54.9,10.9,55,9.4,56,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,9.6c-0.8-0.2-0.8,3,0,2.8C53.9,12.6,53.9,9.4,53.1,9.6z"/>
		<path class="st41" d="M52.8,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,10.2c-0.8-0.2-0.8,3,0,2.8C54.9,13.2,54.9,10.1,54.1,10.2z"/>
		<path class="st41" d="M53.8,11.1C52.9,10.9,53,9.4,54,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,9.6c-0.8-0.2-0.8,3,0,2.8C51.9,12.6,51.9,9.4,51.1,9.6z"/>
		<path class="st41" d="M50.8,10.5C49.8,10.2,50,8.7,51,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,10.2c-0.8-0.2-0.8,3,0,2.8C52.9,13.2,52.9,10.1,52.1,10.2z"/>
		<path class="st41" d="M51.8,11.1C50.9,10.9,51,9.4,52,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,9.6c-0.8-0.2-0.8,3,0,2.8C49.9,12.6,49.9,9.4,49.1,9.6z"/>
		<path class="st41" d="M48.8,10.5C47.9,10.2,48,8.7,49,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,10.2c-0.8-0.2-0.8,3,0,2.8C51,13.2,51,10.1,50.1,10.2z"/>
		<path class="st41" d="M49.8,11.1C48.9,10.9,49,9.4,50,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,9.6c-0.8-0.2-0.8,3,0,2.8C47.9,12.6,47.9,9.4,47.1,9.6z"/>
		<path class="st41" d="M46.8,10.5C45.9,10.2,46,8.7,47,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,10.2c-0.8-0.2-0.8,3,0,2.8C49,13.2,49,10.1,48.1,10.2z"/>
		<path class="st41" d="M47.9,11.1C46.9,10.9,47,9.4,48,9.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,9.6c-0.8-0.2-0.8,3,0,2.8C45.9,12.6,45.9,9.4,45.1,9.6z"/>
		<path class="st41" d="M44.8,10.5C43.9,10.2,44,8.7,45,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,10.2c-0.8-0.2-0.8,3,0,2.8C47,13.2,47,10.1,46.2,10.2z"/>
		<path class="st41" d="M45.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,9.6c-0.8-0.2-0.8,3,0,2.8C43.9,12.6,43.9,9.4,43.1,9.6z"/>
		<path class="st41" d="M42.8,10.5C41.9,10.2,42,8.7,43,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,10.2c-0.8-0.2-0.8,3,0,2.8C45,13.2,45,10.1,44.2,10.2z"/>
		<path class="st41" d="M43.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,9.6c-0.8-0.2-0.8,3,0,2.8C42,12.6,42,9.4,41.1,9.6z"/>
		<path class="st41" d="M40.8,10.5C39.9,10.2,40,8.7,41,8.6c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,10.2c-0.8-0.2-0.8,3,0,2.8C43,13.2,43,10.1,42.2,10.2z"/>
		<path class="st41" d="M41.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,9.6c-0.8-0.2-0.8,3,0,2.8C40,12.6,40,9.4,39.1,9.6z"/>
		<path class="st41" d="M38.9,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,10.2c-0.8-0.2-0.8,3,0,2.8C41,13.2,41,10.1,40.2,10.2z"/>
		<path class="st41" d="M39.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,9.6c-0.8-0.2-0.8,3,0,2.8C38,12.6,38,9.4,37.2,9.6z"/>
		<path class="st41" d="M36.9,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,10.2c-0.8-0.2-0.8,3,0,2.8C39,13.2,39,10.1,38.2,10.2z"/>
		<path class="st41" d="M37.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,9.6c-0.8-0.2-0.8,3,0,2.8C36,12.6,36,9.4,35.2,9.6z"/>
		<path class="st41" d="M34.9,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,10.2c-0.8-0.2-0.8,3,0,2.8C37,13.2,37,10.1,36.2,10.2z"/>
		<path class="st41" d="M35.9,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,9.6c-0.8-0.2-0.8,3,0,2.8C34.1,12.6,34.1,9.4,33.3,9.6z"/>
		<path class="st41" d="M33,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,10.2c-0.8-0.2-0.8,3,0,2.8C35.2,13.2,35.2,10.1,34.3,10.2z"/>
		<path class="st41" d="M34.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,9.6c-0.8-0.2-0.8,3,0,2.8C32.1,12.6,32.1,9.4,31.3,9.6z"/>
		<path class="st41" d="M31,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,10.2c-0.8-0.2-0.8,3,0,2.8C33.2,13.2,33.2,10.1,32.4,10.2z"/>
		<path class="st41" d="M32.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,9.6c-0.8-0.2-0.8,3,0,2.8C30.1,12.6,30.2,9.4,29.3,9.6z"/>
		<path class="st41" d="M29,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,10.2c-0.8-0.2-0.8,3,0,2.8C31.2,13.2,31.2,10.1,30.4,10.2z"/>
		<path class="st41" d="M30.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,9.6c-0.8-0.2-0.8,3,0,2.8C28.2,12.6,28.2,9.4,27.3,9.6z"/>
		<path class="st41" d="M27,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,10.2c-0.8-0.2-0.8,3,0,2.8C29.2,13.2,29.2,10.1,28.4,10.2z"/>
		<path class="st41" d="M28.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,9.6c-0.8-0.2-0.8,3,0,2.8C26.2,12.6,26.2,9.4,25.3,9.6z"/>
		<path class="st41" d="M25.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,10.2c-0.8-0.2-0.8,3,0,2.8C27.2,13.2,27.2,10.1,26.4,10.2z"/>
		<path class="st41" d="M26.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,9.6c-0.8-0.2-0.8,3,0,2.8C24.2,12.6,24.2,9.4,23.4,9.6z"/>
		<path class="st41" d="M23.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,10.2c-0.8-0.2-0.8,3,0,2.8C25.2,13.2,25.2,10.1,24.4,10.2z"/>
		<path class="st41" d="M24.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,9.6c-0.8-0.2-0.8,3,0,2.8C22.2,12.6,22.2,9.4,21.4,9.6z"/>
		<path class="st41" d="M21.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,10.2c-0.8-0.2-0.8,3,0,2.8C23.3,13.2,23.3,10.1,22.4,10.2z"/>
		<path class="st41" d="M22.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,9.6c-0.8-0.2-0.8,3,0,2.8C20.2,12.6,20.2,9.4,19.4,9.6z"/>
		<path class="st41" d="M19.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,10.2c-0.8-0.2-0.8,3,0,2.8C21.3,13.2,21.3,10.1,20.4,10.2z"/>
		<path class="st41" d="M20.1,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,9.6c-0.8-0.2-0.8,3,0,2.8C18.2,12.6,18.2,9.4,17.4,9.6z"/>
		<path class="st41" d="M17.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,10.2c-0.8-0.2-0.8,3,0,2.8C19.3,13.2,19.3,10.1,18.4,10.2z"/>
		<path class="st41" d="M18.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,9.6c-0.8-0.2-0.8,3,0,2.8C16.2,12.6,16.2,9.4,15.4,9.6z"/>
		<path class="st41" d="M15.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,10.2c-0.8-0.2-0.8,3,0,2.8C17.3,13.2,17.3,10.1,16.5,10.2z"/>
		<path class="st41" d="M16.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,9.6c-0.8-0.2-0.8,3,0,2.8C14.3,12.6,14.3,9.4,13.4,9.6z"/>
		<path class="st41" d="M13.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,10.2c-0.8-0.2-0.8,3,0,2.8C15.3,13.2,15.3,10.1,14.5,10.2z"/>
		<path class="st41" d="M14.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,9.6c-0.8-0.2-0.8,3,0,2.8C12.3,12.6,12.3,9.4,11.4,9.6z"/>
		<path class="st41" d="M11.1,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,10.2c-0.8-0.2-0.8,3,0,2.8C13.3,13.2,13.3,10.1,12.5,10.2z"/>
		<path class="st41" d="M12.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,9.6c-0.8-0.2-0.8,3,0,2.8C10.3,12.6,10.3,9.4,9.5,9.6z"/>
		<path class="st41" d="M9.2,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,10.2c-0.8-0.2-0.8,3,0,2.8C11.3,13.2,11.3,10.1,10.5,10.2z"/>
		<path class="st41" d="M10.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,9.6c-0.8-0.2-0.8,3,0,2.8C8.3,12.6,8.3,9.4,7.5,9.6z"/>
		<path class="st41" d="M7.2,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,10.2c-0.8-0.2-0.8,3,0,2.8C9.3,13.2,9.3,10.1,8.5,10.2z"/>
		<path class="st41" d="M8.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,9.6c-0.8-0.2-0.8,3,0,2.8C6.3,12.6,6.3,9.4,5.5,9.6z"/>
		<path class="st41" d="M5.2,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,10.2c-0.8-0.2-0.8,3,0,2.8C7.4,13.2,7.4,10.1,6.5,10.2z"/>
		<path class="st41" d="M6.2,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,9.6c-0.8-0.2-0.8,3,0,2.8C4.3,12.6,4.3,9.4,3.5,9.6z"/>
		<path class="st41" d="M3.2,10.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,10.2c-0.8-0.2-0.8,3,0,2.8C5.4,13.2,5.4,10.1,4.5,10.2z"/>
		<path class="st41" d="M4.3,11.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,11.1c-0.8-0.2-0.8,3,0,2.8C65.8,14.1,65.8,10.9,65,11.1z"/>
		<path class="st41" d="M64.7,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,11.8c-0.8-0.2-0.8,3,0,2.8C66.9,14.8,66.9,11.6,66,11.8z"/>
		<path class="st41" d="M65.7,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,11.1c-0.8-0.2-0.8,3,0,2.8C63.8,14.1,63.8,10.9,63,11.1z"/>
		<path class="st41" d="M62.7,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,11.8c-0.8-0.2-0.8,3,0,2.8C64.9,14.8,64.9,11.6,64,11.8z"/>
		<path class="st41" d="M63.7,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,11.1c-0.8-0.2-0.8,3,0,2.8C61.8,14.1,61.8,10.9,61,11.1z"/>
		<path class="st41" d="M60.7,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,11.8c-0.8-0.2-0.8,3,0,2.8C62.9,14.8,62.9,11.6,62,11.8z"/>
		<path class="st41" d="M61.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,11.1c-0.8-0.2-0.8,3,0,2.8C59.8,14.1,59.8,10.9,59,11.1z"/>
		<path class="st41" d="M58.7,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,11.8c-0.8-0.2-0.8,3,0,2.8C60.9,14.8,60.9,11.6,60.1,11.8z"/>
		<path class="st41" d="M59.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,11.1c-0.8-0.2-0.8,3,0,2.8C57.9,14.1,57.9,10.9,57,11.1z"/>
		<path class="st41" d="M56.7,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,11.8c-0.8-0.2-0.8,3,0,2.8C58.9,14.8,58.9,11.6,58.1,11.8z"/>
		<path class="st41" d="M57.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,11.1c-0.8-0.2-0.8,3,0,2.8C55.9,14.1,55.9,10.9,55,11.1z"/>
		<path class="st41" d="M54.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,11.8c-0.8-0.2-0.8,3,0,2.8C56.9,14.8,56.9,11.6,56.1,11.8z"/>
		<path class="st41" d="M55.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,11.1c-0.8-0.2-0.8,3,0,2.8C53.9,14.1,53.9,10.9,53.1,11.1z"/>
		<path class="st41" d="M52.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,11.8c-0.8-0.2-0.8,3,0,2.8C54.9,14.8,54.9,11.6,54.1,11.8z"/>
		<path class="st41" d="M53.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,11.1c-0.8-0.2-0.8,3,0,2.8C51.9,14.1,51.9,10.9,51.1,11.1z"/>
		<path class="st41" d="M50.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,11.8c-0.8-0.2-0.8,3,0,2.8C52.9,14.8,52.9,11.6,52.1,11.8z"/>
		<path class="st41" d="M51.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,11.1c-0.8-0.2-0.8,3,0,2.8C49.9,14.1,49.9,10.9,49.1,11.1z"/>
		<path class="st41" d="M48.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,11.8c-0.8-0.2-0.8,3,0,2.8C51,14.8,51,11.6,50.1,11.8z"/>
		<path class="st41" d="M49.8,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,11.1c-0.8-0.2-0.8,3,0,2.8C47.9,14.1,47.9,10.9,47.1,11.1z"/>
		<path class="st41" d="M46.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,11.8c-0.8-0.2-0.8,3,0,2.8C49,14.8,49,11.6,48.1,11.8z"/>
		<path class="st41" d="M47.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,11.1c-0.8-0.2-0.8,3,0,2.8C45.9,14.1,45.9,10.9,45.1,11.1z"/>
		<path class="st41" d="M44.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,11.8c-0.8-0.2-0.8,3,0,2.8C47,14.8,47,11.6,46.2,11.8z"/>
		<path class="st41" d="M45.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,11.1c-0.8-0.2-0.8,3,0,2.8C43.9,14.1,43.9,10.9,43.1,11.1z"/>
		<path class="st41" d="M42.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,11.8c-0.8-0.2-0.8,3,0,2.8C45,14.8,45,11.6,44.2,11.8z"/>
		<path class="st41" d="M43.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,11.1c-0.8-0.2-0.8,3,0,2.8C42,14.1,42,10.9,41.1,11.1z"/>
		<path class="st41" d="M40.8,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,11.8c-0.8-0.2-0.8,3,0,2.8C43,14.8,43,11.6,42.2,11.8z"/>
		<path class="st41" d="M41.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,11.1c-0.8-0.2-0.8,3,0,2.8C40,14.1,40,10.9,39.1,11.1z"/>
		<path class="st41" d="M38.9,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,11.8c-0.8-0.2-0.8,3,0,2.8C41,14.8,41,11.6,40.2,11.8z"/>
		<path class="st41" d="M39.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,11.1c-0.8-0.2-0.8,3,0,2.8C38,14.1,38,10.9,37.2,11.1z"/>
		<path class="st41" d="M36.9,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,11.8c-0.8-0.2-0.8,3,0,2.8C39,14.8,39,11.6,38.2,11.8z"/>
		<path class="st41" d="M37.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,11.1c-0.8-0.2-0.8,3,0,2.8C36,14.1,36,10.9,35.2,11.1z"/>
		<path class="st41" d="M34.9,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,11.8c-0.8-0.2-0.8,3,0,2.8C37,14.8,37,11.6,36.2,11.8z"/>
		<path class="st41" d="M35.9,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,11.1c-0.8-0.2-0.8,3,0,2.8C34.1,14.1,34.1,10.9,33.3,11.1z"/>
		<path class="st41" d="M33,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,11.8c-0.8-0.2-0.8,3,0,2.8C35.2,14.8,35.2,11.6,34.3,11.8z"/>
		<path class="st41" d="M34.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,11.1c-0.8-0.2-0.8,3,0,2.8C32.1,14.1,32.1,10.9,31.3,11.1z"/>
		<path class="st41" d="M31,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,11.8c-0.8-0.2-0.8,3,0,2.8C33.2,14.8,33.2,11.6,32.4,11.8z"/>
		<path class="st41" d="M32.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,11.1c-0.8-0.2-0.8,3,0,2.8C30.1,14.1,30.2,10.9,29.3,11.1z"/>
		<path class="st41" d="M29,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,11.8c-0.8-0.2-0.8,3,0,2.8C31.2,14.8,31.2,11.6,30.4,11.8z"/>
		<path class="st41" d="M30.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,11.1c-0.8-0.2-0.8,3,0,2.8C28.2,14.1,28.2,10.9,27.3,11.1z"/>
		<path class="st41" d="M27,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,11.8c-0.8-0.2-0.8,3,0,2.8C29.2,14.8,29.2,11.6,28.4,11.8z"/>
		<path class="st41" d="M28.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,11.1c-0.8-0.2-0.8,3,0,2.8C26.2,14.1,26.2,10.9,25.3,11.1z"/>
		<path class="st41" d="M25.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,11.8c-0.8-0.2-0.8,3,0,2.8C27.2,14.8,27.2,11.6,26.4,11.8z"/>
		<path class="st41" d="M26.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,11.1c-0.8-0.2-0.8,3,0,2.8C24.2,14.1,24.2,10.9,23.4,11.1z"/>
		<path class="st41" d="M23.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,11.8c-0.8-0.2-0.8,3,0,2.8C25.2,14.8,25.2,11.6,24.4,11.8z"/>
		<path class="st41" d="M24.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,11.1c-0.8-0.2-0.8,3,0,2.8C22.2,14.1,22.2,10.9,21.4,11.1z"/>
		<path class="st41" d="M21.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,11.8c-0.8-0.2-0.8,3,0,2.8C23.3,14.8,23.3,11.6,22.4,11.8z"/>
		<path class="st41" d="M22.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,11.1c-0.8-0.2-0.8,3,0,2.8C20.2,14.1,20.2,10.9,19.4,11.1z"/>
		<path class="st41" d="M19.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,11.8c-0.8-0.2-0.8,3,0,2.8C21.3,14.8,21.3,11.6,20.4,11.8z"/>
		<path class="st41" d="M20.1,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,11.1c-0.8-0.2-0.8,3,0,2.8C18.2,14.1,18.2,10.9,17.4,11.1z"/>
		<path class="st41" d="M17.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,11.8c-0.8-0.2-0.8,3,0,2.8C19.3,14.8,19.3,11.6,18.4,11.8z"/>
		<path class="st41" d="M18.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,11.1c-0.8-0.2-0.8,3,0,2.8C16.2,14.1,16.2,10.9,15.4,11.1z"/>
		<path class="st41" d="M15.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,11.8c-0.8-0.2-0.8,3,0,2.8C17.3,14.8,17.3,11.6,16.5,11.8z"/>
		<path class="st41" d="M16.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,11.1c-0.8-0.2-0.8,3,0,2.8C14.3,14.1,14.3,10.9,13.4,11.1z"/>
		<path class="st41" d="M13.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,11.8c-0.8-0.2-0.8,3,0,2.8C15.3,14.8,15.3,11.6,14.5,11.8z"/>
		<path class="st41" d="M14.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,11.1c-0.8-0.2-0.8,3,0,2.8C12.3,14.1,12.3,10.9,11.4,11.1z"/>
		<path class="st41" d="M11.1,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,11.8c-0.8-0.2-0.8,3,0,2.8C13.3,14.8,13.3,11.6,12.5,11.8z"/>
		<path class="st41" d="M12.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,11.1c-0.8-0.2-0.8,3,0,2.8C10.3,14.1,10.3,10.9,9.5,11.1z"/>
		<path class="st41" d="M9.2,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,11.8c-0.8-0.2-0.8,3,0,2.8C11.3,14.8,11.3,11.6,10.5,11.8z"/>
		<path class="st41" d="M10.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,11.1c-0.8-0.2-0.8,3,0,2.8C8.3,14.1,8.3,10.9,7.5,11.1z"/>
		<path class="st41" d="M7.2,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,11.8c-0.8-0.2-0.8,3,0,2.8C9.3,14.8,9.3,11.6,8.5,11.8z"/>
		<path class="st41" d="M8.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,11.1c-0.8-0.2-0.8,3,0,2.8C6.3,14.1,6.3,10.9,5.5,11.1z"/>
		<path class="st41" d="M5.2,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,11.8c-0.8-0.2-0.8,3,0,2.8C7.4,14.8,7.4,11.6,6.5,11.8z"/>
		<path class="st41" d="M6.2,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,11.1c-0.8-0.2-0.8,3,0,2.8C4.3,14.1,4.3,10.9,3.5,11.1z"/>
		<path class="st41" d="M3.2,12c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,11.8c-0.8-0.2-0.8,3,0,2.8C5.4,14.8,5.4,11.6,4.5,11.8z"/>
		<path class="st41" d="M4.3,12.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,12.5c-0.8-0.2-0.8,3,0,2.8C65.8,15.5,65.8,12.3,65,12.5z"/>
		<path class="st41" d="M64.7,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,13.2c-0.8-0.2-0.8,3,0,2.8C66.9,16.2,66.9,13,66,13.2z"/>
		<path class="st41" d="M65.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,12.5c-0.8-0.2-0.8,3,0,2.8C63.8,15.5,63.8,12.3,63,12.5z"/>
		<path class="st41" d="M62.7,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,13.2c-0.8-0.2-0.8,3,0,2.8C64.9,16.2,64.9,13,64,13.2z"/>
		<path class="st41" d="M63.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,12.5c-0.8-0.2-0.8,3,0,2.8C61.8,15.5,61.8,12.3,61,12.5z"/>
		<path class="st41" d="M60.7,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,13.2c-0.8-0.2-0.8,3,0,2.8C62.9,16.2,62.9,13,62,13.2z"/>
		<path class="st41" d="M61.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,12.5c-0.8-0.2-0.8,3,0,2.8C59.8,15.5,59.8,12.3,59,12.5z"/>
		<path class="st41" d="M58.7,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,13.2c-0.8-0.2-0.8,3,0,2.8C60.9,16.2,60.9,13,60.1,13.2z"/>
		<path class="st41" d="M59.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,12.5c-0.8-0.2-0.8,3,0,2.8C57.9,15.5,57.9,12.3,57,12.5z"/>
		<path class="st41" d="M56.7,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,13.2c-0.8-0.2-0.8,3,0,2.8C58.9,16.2,58.9,13,58.1,13.2z"/>
		<path class="st41" d="M57.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,12.5c-0.8-0.2-0.8,3,0,2.8C55.9,15.5,55.9,12.3,55,12.5z"/>
		<path class="st41" d="M54.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,13.2c-0.8-0.2-0.8,3,0,2.8C56.9,16.2,56.9,13,56.1,13.2z"/>
		<path class="st41" d="M55.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,12.5c-0.8-0.2-0.8,3,0,2.8C53.9,15.5,53.9,12.3,53.1,12.5z"/>
		<path class="st41" d="M52.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,13.2c-0.8-0.2-0.8,3,0,2.8C54.9,16.2,54.9,13,54.1,13.2z"/>
		<path class="st41" d="M53.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,12.5c-0.8-0.2-0.8,3,0,2.8C51.9,15.5,51.9,12.3,51.1,12.5z"/>
		<path class="st41" d="M50.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,13.2c-0.8-0.2-0.8,3,0,2.8C52.9,16.2,52.9,13,52.1,13.2z"/>
		<path class="st41" d="M51.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,12.5c-0.8-0.2-0.8,3,0,2.8C49.9,15.5,49.9,12.3,49.1,12.5z"/>
		<path class="st41" d="M48.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,13.2c-0.8-0.2-0.8,3,0,2.8C51,16.2,51,13,50.1,13.2z"/>
		<path class="st41" d="M49.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,12.5c-0.8-0.2-0.8,3,0,2.8C47.9,15.5,47.9,12.3,47.1,12.5z"/>
		<path class="st41" d="M46.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,13.2c-0.8-0.2-0.8,3,0,2.8C49,16.2,49,13,48.1,13.2z"/>
		<path class="st41" d="M47.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,12.5c-0.8-0.2-0.8,3,0,2.8C45.9,15.5,45.9,12.3,45.1,12.5z"/>
		<path class="st41" d="M44.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,13.2c-0.8-0.2-0.8,3,0,2.8C47,16.2,47,13,46.2,13.2z"/>
		<path class="st41" d="M45.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,12.5c-0.8-0.2-0.8,3,0,2.8C43.9,15.5,43.9,12.3,43.1,12.5z"/>
		<path class="st41" d="M42.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,13.2c-0.8-0.2-0.8,3,0,2.8C45,16.2,45,13,44.2,13.2z"/>
		<path class="st41" d="M43.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,12.5c-0.8-0.2-0.8,3,0,2.8C42,15.5,42,12.3,41.1,12.5z"/>
		<path class="st41" d="M40.8,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,13.2c-0.8-0.2-0.8,3,0,2.8C43,16.2,43,13,42.2,13.2z"/>
		<path class="st41" d="M41.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,12.5c-0.8-0.2-0.8,3,0,2.8C40,15.5,40,12.3,39.1,12.5z"/>
		<path class="st41" d="M38.9,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,13.2c-0.8-0.2-0.8,3,0,2.8C41,16.2,41,13,40.2,13.2z"/>
		<path class="st41" d="M39.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,12.5c-0.8-0.2-0.8,3,0,2.8C38,15.5,38,12.3,37.2,12.5z"/>
		<path class="st41" d="M36.9,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,13.2c-0.8-0.2-0.8,3,0,2.8C39,16.2,39,13,38.2,13.2z"/>
		<path class="st41" d="M37.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,12.5c-0.8-0.2-0.8,3,0,2.8C36,15.5,36,12.3,35.2,12.5z"/>
		<path class="st41" d="M34.9,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,13.2c-0.8-0.2-0.8,3,0,2.8C37,16.2,37,13,36.2,13.2z"/>
		<path class="st41" d="M35.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,12.5c-0.8-0.2-0.8,3,0,2.8C34.1,15.5,34.1,12.3,33.3,12.5z"/>
		<path class="st41" d="M33,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,13.2c-0.8-0.2-0.8,3,0,2.8C35.2,16.2,35.2,13,34.3,13.2z"/>
		<path class="st41" d="M34.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,12.5c-0.8-0.2-0.8,3,0,2.8C32.1,15.5,32.1,12.3,31.3,12.5z"/>
		<path class="st41" d="M31,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,13.2c-0.8-0.2-0.8,3,0,2.8C33.2,16.2,33.2,13,32.4,13.2z"/>
		<path class="st41" d="M32.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,12.5c-0.8-0.2-0.8,3,0,2.8C30.1,15.5,30.2,12.3,29.3,12.5z"/>
		<path class="st41" d="M29,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,13.2c-0.8-0.2-0.8,3,0,2.8C31.2,16.2,31.2,13,30.4,13.2z"/>
		<path class="st41" d="M30.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,12.5c-0.8-0.2-0.8,3,0,2.8C28.2,15.5,28.2,12.3,27.3,12.5z"/>
		<path class="st41" d="M27,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,13.2c-0.8-0.2-0.8,3,0,2.8C29.2,16.2,29.2,13,28.4,13.2z"/>
		<path class="st41" d="M28.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,12.5c-0.8-0.2-0.8,3,0,2.8C26.2,15.5,26.2,12.3,25.3,12.5z"/>
		<path class="st41" d="M25.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,13.2c-0.8-0.2-0.8,3,0,2.8C27.2,16.2,27.2,13,26.4,13.2z"/>
		<path class="st41" d="M26.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,12.5c-0.8-0.2-0.8,3,0,2.8C24.2,15.5,24.2,12.3,23.4,12.5z"/>
		<path class="st41" d="M23.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,13.2c-0.8-0.2-0.8,3,0,2.8C25.2,16.2,25.2,13,24.4,13.2z"/>
		<path class="st41" d="M24.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,12.5c-0.8-0.2-0.8,3,0,2.8C22.2,15.5,22.2,12.3,21.4,12.5z"/>
		<path class="st41" d="M21.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,13.2c-0.8-0.2-0.8,3,0,2.8C23.3,16.2,23.3,13,22.4,13.2z"/>
		<path class="st41" d="M22.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,12.5c-0.8-0.2-0.8,3,0,2.8C20.2,15.5,20.2,12.3,19.4,12.5z"/>
		<path class="st41" d="M19.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,13.2c-0.8-0.2-0.8,3,0,2.8C21.3,16.2,21.3,13,20.4,13.2z"/>
		<path class="st41" d="M20.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,12.5c-0.8-0.2-0.8,3,0,2.8C18.2,15.5,18.2,12.3,17.4,12.5z"/>
		<path class="st41" d="M17.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,13.2c-0.8-0.2-0.8,3,0,2.8C19.3,16.2,19.3,13,18.4,13.2z"/>
		<path class="st41" d="M18.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,12.5c-0.8-0.2-0.8,3,0,2.8C16.2,15.5,16.2,12.3,15.4,12.5z"/>
		<path class="st41" d="M15.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,13.2c-0.8-0.2-0.8,3,0,2.8C17.3,16.2,17.3,13,16.5,13.2z"/>
		<path class="st41" d="M16.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,12.5c-0.8-0.2-0.8,3,0,2.8C14.3,15.5,14.3,12.3,13.4,12.5z"/>
		<path class="st41" d="M13.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,13.2c-0.8-0.2-0.8,3,0,2.8C15.3,16.2,15.3,13,14.5,13.2z"/>
		<path class="st41" d="M14.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,12.5c-0.8-0.2-0.8,3,0,2.8C12.3,15.5,12.3,12.3,11.4,12.5z"/>
		<path class="st41" d="M11.1,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,13.2c-0.8-0.2-0.8,3,0,2.8C13.3,16.2,13.3,13,12.5,13.2z"/>
		<path class="st41" d="M12.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,12.5c-0.8-0.2-0.8,3,0,2.8C10.3,15.5,10.3,12.3,9.5,12.5z"/>
		<path class="st41" d="M9.2,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,13.2c-0.8-0.2-0.8,3,0,2.8C11.3,16.2,11.3,13,10.5,13.2z"/>
		<path class="st41" d="M10.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,12.5c-0.8-0.2-0.8,3,0,2.8C8.3,15.5,8.3,12.3,7.5,12.5z"/>
		<path class="st41" d="M7.2,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,13.2c-0.8-0.2-0.8,3,0,2.8C9.3,16.2,9.3,13,8.5,13.2z"/>
		<path class="st41" d="M8.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,12.5c-0.8-0.2-0.8,3,0,2.8C6.3,15.5,6.3,12.3,5.5,12.5z"/>
		<path class="st41" d="M5.2,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,13.2c-0.8-0.2-0.8,3,0,2.8C7.4,16.2,7.4,13,6.5,13.2z"/>
		<path class="st41" d="M6.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,12.5c-0.8-0.2-0.8,3,0,2.8C4.3,15.5,4.3,12.3,3.5,12.5z"/>
		<path class="st41" d="M3.2,13.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,13.2c-0.8-0.2-0.8,3,0,2.8C5.4,16.2,5.4,13,4.5,13.2z"/>
		<path class="st41" d="M4.3,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,14.1c-0.8-0.2-0.8,3,0,2.8C65.8,17.1,65.8,14,65,14.1z"/>
		<path class="st41" d="M64.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,14.8c-0.8-0.2-0.8,3,0,2.8C66.9,17.8,66.9,14.6,66,14.8z"/>
		<path class="st41" d="M65.7,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,14.1c-0.8-0.2-0.8,3,0,2.8C63.8,17.1,63.8,14,63,14.1z"/>
		<path class="st41" d="M62.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,14.8c-0.8-0.2-0.8,3,0,2.8C64.9,17.8,64.9,14.6,64,14.8z"/>
		<path class="st41" d="M63.7,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,14.1c-0.8-0.2-0.8,3,0,2.8C61.8,17.1,61.8,14,61,14.1z"/>
		<path class="st41" d="M60.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,14.8c-0.8-0.2-0.8,3,0,2.8C62.9,17.8,62.9,14.6,62,14.8z"/>
		<path class="st41" d="M61.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,14.1c-0.8-0.2-0.8,3,0,2.8C59.8,17.1,59.8,14,59,14.1z"/>
		<path class="st41" d="M58.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,14.8c-0.8-0.2-0.8,3,0,2.8C60.9,17.8,60.9,14.6,60.1,14.8z"/>
		<path class="st41" d="M59.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,14.1c-0.8-0.2-0.8,3,0,2.8C57.9,17.1,57.9,14,57,14.1z"/>
		<path class="st41" d="M56.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,14.8c-0.8-0.2-0.8,3,0,2.8C58.9,17.8,58.9,14.6,58.1,14.8z"/>
		<path class="st41" d="M57.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,14.1c-0.8-0.2-0.8,3,0,2.8C55.9,17.1,55.9,14,55,14.1z"/>
		<path class="st41" d="M54.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,14.8c-0.8-0.2-0.8,3,0,2.8C56.9,17.8,56.9,14.6,56.1,14.8z"/>
		<path class="st41" d="M55.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,14.1c-0.8-0.2-0.8,3,0,2.8C53.9,17.1,53.9,14,53.1,14.1z"/>
		<path class="st41" d="M52.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,14.8c-0.8-0.2-0.8,3,0,2.8C54.9,17.8,54.9,14.6,54.1,14.8z"/>
		<path class="st41" d="M53.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,14.1c-0.8-0.2-0.8,3,0,2.8C51.9,17.1,51.9,14,51.1,14.1z"/>
		<path class="st41" d="M50.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,14.8c-0.8-0.2-0.8,3,0,2.8C52.9,17.8,52.9,14.6,52.1,14.8z"/>
		<path class="st41" d="M51.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,14.1c-0.8-0.2-0.8,3,0,2.8C49.9,17.1,49.9,14,49.1,14.1z"/>
		<path class="st41" d="M48.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,14.8c-0.8-0.2-0.8,3,0,2.8C51,17.8,51,14.6,50.1,14.8z"/>
		<path class="st41" d="M49.8,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,14.1c-0.8-0.2-0.8,3,0,2.8C47.9,17.1,47.9,14,47.1,14.1z"/>
		<path class="st41" d="M46.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,14.8c-0.8-0.2-0.8,3,0,2.8C49,17.8,49,14.6,48.1,14.8z"/>
		<path class="st41" d="M47.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,14.1c-0.8-0.2-0.8,3,0,2.8C45.9,17.1,45.9,14,45.1,14.1z"/>
		<path class="st41" d="M44.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,14.8c-0.8-0.2-0.8,3,0,2.8C47,17.8,47,14.6,46.2,14.8z"/>
		<path class="st41" d="M45.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,14.1c-0.8-0.2-0.8,3,0,2.8C43.9,17.1,43.9,14,43.1,14.1z"/>
		<path class="st41" d="M42.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,14.8c-0.8-0.2-0.8,3,0,2.8C45,17.8,45,14.6,44.2,14.8z"/>
		<path class="st41" d="M43.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,14.1c-0.8-0.2-0.8,3,0,2.8C42,17.1,42,14,41.1,14.1z"/>
		<path class="st41" d="M40.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,14.8c-0.8-0.2-0.8,3,0,2.8C43,17.8,43,14.6,42.2,14.8z"/>
		<path class="st41" d="M41.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,14.1c-0.8-0.2-0.8,3,0,2.8C40,17.1,40,14,39.1,14.1z"/>
		<path class="st41" d="M38.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,14.8c-0.8-0.2-0.8,3,0,2.8C41,17.8,41,14.6,40.2,14.8z"/>
		<path class="st41" d="M39.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,14.1c-0.8-0.2-0.8,3,0,2.8C38,17.1,38,14,37.2,14.1z"/>
		<path class="st41" d="M36.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,14.8c-0.8-0.2-0.8,3,0,2.8C39,17.8,39,14.6,38.2,14.8z"/>
		<path class="st41" d="M37.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,14.1c-0.8-0.2-0.8,3,0,2.8C36,17.1,36,14,35.2,14.1z"/>
		<path class="st41" d="M34.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,14.8c-0.8-0.2-0.8,3,0,2.8C37,17.8,37,14.6,36.2,14.8z"/>
		<path class="st41" d="M35.9,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,14.1c-0.8-0.2-0.8,3,0,2.8C34.1,17.1,34.1,14,33.3,14.1z"/>
		<path class="st41" d="M33,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,14.8c-0.8-0.2-0.8,3,0,2.8C35.2,17.8,35.2,14.6,34.3,14.8z"/>
		<path class="st41" d="M34.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,14.1c-0.8-0.2-0.8,3,0,2.8C32.1,17.1,32.1,14,31.3,14.1z"/>
		<path class="st41" d="M31,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,14.8c-0.8-0.2-0.8,3,0,2.8C33.2,17.8,33.2,14.6,32.4,14.8z"/>
		<path class="st41" d="M32.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,14.1c-0.8-0.2-0.8,3,0,2.8C30.1,17.1,30.2,14,29.3,14.1z"/>
		<path class="st41" d="M29,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,14.8c-0.8-0.2-0.8,3,0,2.8C31.2,17.8,31.2,14.6,30.4,14.8z"/>
		<path class="st41" d="M30.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,14.1c-0.8-0.2-0.8,3,0,2.8C28.2,17.1,28.2,14,27.3,14.1z"/>
		<path class="st41" d="M27,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,14.8c-0.8-0.2-0.8,3,0,2.8C29.2,17.8,29.2,14.6,28.4,14.8z"/>
		<path class="st41" d="M28.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,14.1c-0.8-0.2-0.8,3,0,2.8C26.2,17.1,26.2,14,25.3,14.1z"/>
		<path class="st41" d="M25.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,14.8c-0.8-0.2-0.8,3,0,2.8C27.2,17.8,27.2,14.6,26.4,14.8z"/>
		<path class="st41" d="M26.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,14.1c-0.8-0.2-0.8,3,0,2.8C24.2,17.1,24.2,14,23.4,14.1z"/>
		<path class="st41" d="M23.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,14.8c-0.8-0.2-0.8,3,0,2.8C25.2,17.8,25.2,14.6,24.4,14.8z"/>
		<path class="st41" d="M24.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,14.1c-0.8-0.2-0.8,3,0,2.8C22.2,17.1,22.2,14,21.4,14.1z"/>
		<path class="st41" d="M21.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,14.8c-0.8-0.2-0.8,3,0,2.8C23.3,17.8,23.3,14.6,22.4,14.8z"/>
		<path class="st41" d="M22.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,14.1c-0.8-0.2-0.8,3,0,2.8C20.2,17.1,20.2,14,19.4,14.1z"/>
		<path class="st41" d="M19.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,14.8c-0.8-0.2-0.8,3,0,2.8C21.3,17.8,21.3,14.6,20.4,14.8z"/>
		<path class="st41" d="M20.1,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,14.1c-0.8-0.2-0.8,3,0,2.8C18.2,17.1,18.2,14,17.4,14.1z"/>
		<path class="st41" d="M17.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,14.8c-0.8-0.2-0.8,3,0,2.8C19.3,17.8,19.3,14.6,18.4,14.8z"/>
		<path class="st41" d="M18.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,14.1c-0.8-0.2-0.8,3,0,2.8C16.2,17.1,16.2,14,15.4,14.1z"/>
		<path class="st41" d="M15.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,14.8c-0.8-0.2-0.8,3,0,2.8C17.3,17.8,17.3,14.6,16.5,14.8z"/>
		<path class="st41" d="M16.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,14.1c-0.8-0.2-0.8,3,0,2.8C14.3,17.1,14.3,14,13.4,14.1z"/>
		<path class="st41" d="M13.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,14.8c-0.8-0.2-0.8,3,0,2.8C15.3,17.8,15.3,14.6,14.5,14.8z"/>
		<path class="st41" d="M14.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,14.1c-0.8-0.2-0.8,3,0,2.8C12.3,17.1,12.3,14,11.4,14.1z"/>
		<path class="st41" d="M11.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,14.8c-0.8-0.2-0.8,3,0,2.8C13.3,17.8,13.3,14.6,12.5,14.8z"/>
		<path class="st41" d="M12.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,14.1c-0.8-0.2-0.8,3,0,2.8C10.3,17.1,10.3,14,9.5,14.1z"/>
		<path class="st41" d="M9.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,14.8c-0.8-0.2-0.8,3,0,2.8C11.3,17.8,11.3,14.6,10.5,14.8z"/>
		<path class="st41" d="M10.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,14.1c-0.8-0.2-0.8,3,0,2.8C8.3,17.1,8.3,14,7.5,14.1z"/>
		<path class="st41" d="M7.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,14.8c-0.8-0.2-0.8,3,0,2.8C9.3,17.8,9.3,14.6,8.5,14.8z"/>
		<path class="st41" d="M8.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,14.1c-0.8-0.2-0.8,3,0,2.8C6.3,17.1,6.3,14,5.5,14.1z"/>
		<path class="st41" d="M5.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,14.8c-0.8-0.2-0.8,3,0,2.8C7.4,17.8,7.4,14.6,6.5,14.8z"/>
		<path class="st41" d="M6.2,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,14.1c-0.8-0.2-0.8,3,0,2.8C4.3,17.1,4.3,14,3.5,14.1z"/>
		<path class="st41" d="M3.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,14.8c-0.8-0.2-0.8,3,0,2.8C5.4,17.8,5.4,14.6,4.5,14.8z"/>
		<path class="st41" d="M4.3,15.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,15.5c-0.8-0.2-0.8,3,0,2.8C65.8,18.5,65.8,15.4,65,15.5z"/>
		<path class="st41" d="M64.7,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,16.2c-0.8-0.2-0.8,3,0,2.8C66.9,19.2,66.9,16,66,16.2z"/>
		<path class="st41" d="M65.7,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,15.5c-0.8-0.2-0.8,3,0,2.8C63.8,18.5,63.8,15.4,63,15.5z"/>
		<path class="st41" d="M62.7,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,16.2c-0.8-0.2-0.8,3,0,2.8C64.9,19.2,64.9,16,64,16.2z"/>
		<path class="st41" d="M63.7,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,15.5c-0.8-0.2-0.8,3,0,2.8C61.8,18.5,61.8,15.4,61,15.5z"/>
		<path class="st41" d="M60.7,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,16.2c-0.8-0.2-0.8,3,0,2.8C62.9,19.2,62.9,16,62,16.2z"/>
		<path class="st41" d="M61.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,15.5c-0.8-0.2-0.8,3,0,2.8C59.8,18.5,59.8,15.4,59,15.5z"/>
		<path class="st41" d="M58.7,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,16.2c-0.8-0.2-0.8,3,0,2.8C60.9,19.2,60.9,16,60.1,16.2z"/>
		<path class="st41" d="M59.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,15.5c-0.8-0.2-0.8,3,0,2.8C57.9,18.5,57.9,15.4,57,15.5z"/>
		<path class="st41" d="M56.7,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,16.2c-0.8-0.2-0.8,3,0,2.8C58.9,19.2,58.9,16,58.1,16.2z"/>
		<path class="st41" d="M57.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,15.5c-0.8-0.2-0.8,3,0,2.8C55.9,18.5,55.9,15.4,55,15.5z"/>
		<path class="st41" d="M54.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,16.2c-0.8-0.2-0.8,3,0,2.8C56.9,19.2,56.9,16,56.1,16.2z"/>
		<path class="st41" d="M55.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,15.5c-0.8-0.2-0.8,3,0,2.8C53.9,18.5,53.9,15.4,53.1,15.5z"/>
		<path class="st41" d="M52.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,16.2c-0.8-0.2-0.8,3,0,2.8C54.9,19.2,54.9,16,54.1,16.2z"/>
		<path class="st41" d="M53.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,15.5c-0.8-0.2-0.8,3,0,2.8C51.9,18.5,51.9,15.4,51.1,15.5z"/>
		<path class="st41" d="M50.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,16.2c-0.8-0.2-0.8,3,0,2.8C52.9,19.2,52.9,16,52.1,16.2z"/>
		<path class="st41" d="M51.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,15.5c-0.8-0.2-0.8,3,0,2.8C49.9,18.5,49.9,15.4,49.1,15.5z"/>
		<path class="st41" d="M48.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,16.2c-0.8-0.2-0.8,3,0,2.8C51,19.2,51,16,50.1,16.2z"/>
		<path class="st41" d="M49.8,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,15.5c-0.8-0.2-0.8,3,0,2.8C47.9,18.5,47.9,15.4,47.1,15.5z"/>
		<path class="st41" d="M46.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,16.2c-0.8-0.2-0.8,3,0,2.8C49,19.2,49,16,48.1,16.2z"/>
		<path class="st41" d="M47.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,15.5c-0.8-0.2-0.8,3,0,2.8C45.9,18.5,45.9,15.4,45.1,15.5z"/>
		<path class="st41" d="M44.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,16.2c-0.8-0.2-0.8,3,0,2.8C47,19.2,47,16,46.2,16.2z"/>
		<path class="st41" d="M45.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,15.5c-0.8-0.2-0.8,3,0,2.8C43.9,18.5,43.9,15.4,43.1,15.5z"/>
		<path class="st41" d="M42.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,16.2c-0.8-0.2-0.8,3,0,2.8C45,19.2,45,16,44.2,16.2z"/>
		<path class="st41" d="M43.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,15.5c-0.8-0.2-0.8,3,0,2.8C42,18.5,42,15.4,41.1,15.5z"/>
		<path class="st41" d="M40.8,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,16.2c-0.8-0.2-0.8,3,0,2.8C43,19.2,43,16,42.2,16.2z"/>
		<path class="st41" d="M41.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,15.5c-0.8-0.2-0.8,3,0,2.8C40,18.5,40,15.4,39.1,15.5z"/>
		<path class="st41" d="M38.9,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,16.2c-0.8-0.2-0.8,3,0,2.8C41,19.2,41,16,40.2,16.2z"/>
		<path class="st41" d="M39.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,15.5c-0.8-0.2-0.8,3,0,2.8C38,18.5,38,15.4,37.2,15.5z"/>
		<path class="st41" d="M36.9,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,16.2c-0.8-0.2-0.8,3,0,2.8C39,19.2,39,16,38.2,16.2z"/>
		<path class="st41" d="M37.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,15.5c-0.8-0.2-0.8,3,0,2.8C36,18.5,36,15.4,35.2,15.5z"/>
		<path class="st41" d="M34.9,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,16.2c-0.8-0.2-0.8,3,0,2.8C37,19.2,37,16,36.2,16.2z"/>
		<path class="st41" d="M35.9,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,15.5c-0.8-0.2-0.8,3,0,2.8C34.1,18.5,34.1,15.4,33.3,15.5z"/>
		<path class="st41" d="M33,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,16.2c-0.8-0.2-0.8,3,0,2.8C35.2,19.2,35.2,16,34.3,16.2z"/>
		<path class="st41" d="M34.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,15.5c-0.8-0.2-0.8,3,0,2.8C32.1,18.5,32.1,15.4,31.3,15.5z"/>
		<path class="st41" d="M31,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,16.2c-0.8-0.2-0.8,3,0,2.8C33.2,19.2,33.2,16,32.4,16.2z"/>
		<path class="st41" d="M32.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,15.5c-0.8-0.2-0.8,3,0,2.8C30.1,18.5,30.2,15.4,29.3,15.5z"/>
		<path class="st41" d="M29,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,16.2c-0.8-0.2-0.8,3,0,2.8C31.2,19.2,31.2,16,30.4,16.2z"/>
		<path class="st41" d="M30.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,15.5c-0.8-0.2-0.8,3,0,2.8C28.2,18.5,28.2,15.4,27.3,15.5z"/>
		<path class="st41" d="M27,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,16.2c-0.8-0.2-0.8,3,0,2.8C29.2,19.2,29.2,16,28.4,16.2z"/>
		<path class="st41" d="M28.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,15.5c-0.8-0.2-0.8,3,0,2.8C26.2,18.5,26.2,15.4,25.3,15.5z"/>
		<path class="st41" d="M25.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,16.2c-0.8-0.2-0.8,3,0,2.8C27.2,19.2,27.2,16,26.4,16.2z"/>
		<path class="st41" d="M26.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,15.5c-0.8-0.2-0.8,3,0,2.8C24.2,18.5,24.2,15.4,23.4,15.5z"/>
		<path class="st41" d="M23.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,16.2c-0.8-0.2-0.8,3,0,2.8C25.2,19.2,25.2,16,24.4,16.2z"/>
		<path class="st41" d="M24.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,15.5c-0.8-0.2-0.8,3,0,2.8C22.2,18.5,22.2,15.4,21.4,15.5z"/>
		<path class="st41" d="M21.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,16.2c-0.8-0.2-0.8,3,0,2.8C23.3,19.2,23.3,16,22.4,16.2z"/>
		<path class="st41" d="M22.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,15.5c-0.8-0.2-0.8,3,0,2.8C20.2,18.5,20.2,15.4,19.4,15.5z"/>
		<path class="st41" d="M19.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,16.2c-0.8-0.2-0.8,3,0,2.8C21.3,19.2,21.3,16,20.4,16.2z"/>
		<path class="st41" d="M20.1,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,15.5c-0.8-0.2-0.8,3,0,2.8C18.2,18.5,18.2,15.4,17.4,15.5z"/>
		<path class="st41" d="M17.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,16.2c-0.8-0.2-0.8,3,0,2.8C19.3,19.2,19.3,16,18.4,16.2z"/>
		<path class="st41" d="M18.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,15.5c-0.8-0.2-0.8,3,0,2.8C16.2,18.5,16.2,15.4,15.4,15.5z"/>
		<path class="st41" d="M15.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,16.2c-0.8-0.2-0.8,3,0,2.8C17.3,19.2,17.3,16,16.5,16.2z"/>
		<path class="st41" d="M16.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,15.5c-0.8-0.2-0.8,3,0,2.8C14.3,18.5,14.3,15.4,13.4,15.5z"/>
		<path class="st41" d="M13.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,16.2c-0.8-0.2-0.8,3,0,2.8C15.3,19.2,15.3,16,14.5,16.2z"/>
		<path class="st41" d="M14.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,15.5c-0.8-0.2-0.8,3,0,2.8C12.3,18.5,12.3,15.4,11.4,15.5z"/>
		<path class="st41" d="M11.1,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,16.2c-0.8-0.2-0.8,3,0,2.8C13.3,19.2,13.3,16,12.5,16.2z"/>
		<path class="st41" d="M12.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,15.5c-0.8-0.2-0.8,3,0,2.8C10.3,18.5,10.3,15.4,9.5,15.5z"/>
		<path class="st41" d="M9.2,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,16.2c-0.8-0.2-0.8,3,0,2.8C11.3,19.2,11.3,16,10.5,16.2z"/>
		<path class="st41" d="M10.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,15.5c-0.8-0.2-0.8,3,0,2.8C8.3,18.5,8.3,15.4,7.5,15.5z"/>
		<path class="st41" d="M7.2,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,16.2c-0.8-0.2-0.8,3,0,2.8C9.3,19.2,9.3,16,8.5,16.2z"/>
		<path class="st41" d="M8.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,15.5c-0.8-0.2-0.8,3,0,2.8C6.3,18.5,6.3,15.4,5.5,15.5z"/>
		<path class="st41" d="M5.2,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,16.2c-0.8-0.2-0.8,3,0,2.8C7.4,19.2,7.4,16,6.5,16.2z"/>
		<path class="st41" d="M6.2,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,15.5c-0.8-0.2-0.8,3,0,2.8C4.3,18.5,4.3,15.4,3.5,15.5z"/>
		<path class="st41" d="M3.2,16.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,16.2c-0.8-0.2-0.8,3,0,2.8C5.4,19.2,5.4,16,4.5,16.2z"/>
		<path class="st41" d="M4.3,17.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,17.1c-0.8-0.2-0.8,3,0,2.8C65.8,20.1,65.8,16.9,65,17.1z"/>
		<path class="st41" d="M64.7,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,17.7c-0.8-0.2-0.8,3,0,2.8C66.9,20.7,66.9,17.6,66,17.7z"/>
		<path class="st41" d="M65.7,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,17.1c-0.8-0.2-0.8,3,0,2.8C63.8,20.1,63.8,16.9,63,17.1z"/>
		<path class="st41" d="M62.7,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,17.7c-0.8-0.2-0.8,3,0,2.8C64.9,20.7,64.9,17.6,64,17.7z"/>
		<path class="st41" d="M63.7,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,17.1c-0.8-0.2-0.8,3,0,2.8C61.8,20.1,61.8,16.9,61,17.1z"/>
		<path class="st41" d="M60.7,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,17.7c-0.8-0.2-0.8,3,0,2.8C62.9,20.7,62.9,17.6,62,17.7z"/>
		<path class="st41" d="M61.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,17.1c-0.8-0.2-0.8,3,0,2.8C59.8,20.1,59.8,16.9,59,17.1z"/>
		<path class="st41" d="M58.7,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,17.7c-0.8-0.2-0.8,3,0,2.8C60.9,20.7,60.9,17.6,60.1,17.7z"/>
		<path class="st41" d="M59.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,17.1c-0.8-0.2-0.8,3,0,2.8C57.9,20.1,57.9,16.9,57,17.1z"/>
		<path class="st41" d="M56.7,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,17.7c-0.8-0.2-0.8,3,0,2.8C58.9,20.7,58.9,17.6,58.1,17.7z"/>
		<path class="st41" d="M57.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,17.1c-0.8-0.2-0.8,3,0,2.8C55.9,20.1,55.9,16.9,55,17.1z"/>
		<path class="st41" d="M54.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,17.7c-0.8-0.2-0.8,3,0,2.8C56.9,20.7,56.9,17.6,56.1,17.7z"/>
		<path class="st41" d="M55.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,17.1c-0.8-0.2-0.8,3,0,2.8C53.9,20.1,53.9,16.9,53.1,17.1z"/>
		<path class="st41" d="M52.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,17.7c-0.8-0.2-0.8,3,0,2.8C54.9,20.7,54.9,17.6,54.1,17.7z"/>
		<path class="st41" d="M53.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,17.1c-0.8-0.2-0.8,3,0,2.8C51.9,20.1,51.9,16.9,51.1,17.1z"/>
		<path class="st41" d="M50.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,17.7c-0.8-0.2-0.8,3,0,2.8C52.9,20.7,52.9,17.6,52.1,17.7z"/>
		<path class="st41" d="M51.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,17.1c-0.8-0.2-0.8,3,0,2.8C49.9,20.1,49.9,16.9,49.1,17.1z"/>
		<path class="st41" d="M48.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,17.7c-0.8-0.2-0.8,3,0,2.8C51,20.7,51,17.6,50.1,17.7z"/>
		<path class="st41" d="M49.8,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,17.1c-0.8-0.2-0.8,3,0,2.8C47.9,20.1,47.9,16.9,47.1,17.1z"/>
		<path class="st41" d="M46.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,17.7c-0.8-0.2-0.8,3,0,2.8C49,20.7,49,17.6,48.1,17.7z"/>
		<path class="st41" d="M47.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,17.1c-0.8-0.2-0.8,3,0,2.8C45.9,20.1,45.9,16.9,45.1,17.1z"/>
		<path class="st41" d="M44.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,17.7c-0.8-0.2-0.8,3,0,2.8C47,20.7,47,17.6,46.2,17.7z"/>
		<path class="st41" d="M45.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,17.1c-0.8-0.2-0.8,3,0,2.8C43.9,20.1,43.9,16.9,43.1,17.1z"/>
		<path class="st41" d="M42.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,17.7c-0.8-0.2-0.8,3,0,2.8C45,20.7,45,17.6,44.2,17.7z"/>
		<path class="st41" d="M43.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,17.1c-0.8-0.2-0.8,3,0,2.8C42,20.1,42,16.9,41.1,17.1z"/>
		<path class="st41" d="M40.8,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,17.7c-0.8-0.2-0.8,3,0,2.8C43,20.7,43,17.6,42.2,17.7z"/>
		<path class="st41" d="M41.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,17.1c-0.8-0.2-0.8,3,0,2.8C40,20.1,40,16.9,39.1,17.1z"/>
		<path class="st41" d="M38.9,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,17.7c-0.8-0.2-0.8,3,0,2.8C41,20.7,41,17.6,40.2,17.7z"/>
		<path class="st41" d="M39.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,17.1c-0.8-0.2-0.8,3,0,2.8C38,20.1,38,16.9,37.2,17.1z"/>
		<path class="st41" d="M36.9,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,17.7c-0.8-0.2-0.8,3,0,2.8C39,20.7,39,17.6,38.2,17.7z"/>
		<path class="st41" d="M37.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,17.1c-0.8-0.2-0.8,3,0,2.8C36,20.1,36,16.9,35.2,17.1z"/>
		<path class="st41" d="M34.9,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,17.7c-0.8-0.2-0.8,3,0,2.8C37,20.7,37,17.6,36.2,17.7z"/>
		<path class="st41" d="M35.9,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,17.1c-0.8-0.2-0.8,3,0,2.8C34.1,20.1,34.1,16.9,33.3,17.1z"/>
		<path class="st41" d="M33,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,17.7c-0.8-0.2-0.8,3,0,2.8C35.2,20.7,35.2,17.6,34.3,17.7z"/>
		<path class="st41" d="M34.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,17.1c-0.8-0.2-0.8,3,0,2.8C32.1,20.1,32.1,16.9,31.3,17.1z"/>
		<path class="st41" d="M31,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,17.7c-0.8-0.2-0.8,3,0,2.8C33.2,20.7,33.2,17.6,32.4,17.7z"/>
		<path class="st41" d="M32.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,17.1c-0.8-0.2-0.8,3,0,2.8C30.1,20.1,30.2,16.9,29.3,17.1z"/>
		<path class="st41" d="M29,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,17.7c-0.8-0.2-0.8,3,0,2.8C31.2,20.7,31.2,17.6,30.4,17.7z"/>
		<path class="st41" d="M30.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,17.1c-0.8-0.2-0.8,3,0,2.8C28.2,20.1,28.2,16.9,27.3,17.1z"/>
		<path class="st41" d="M27,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,17.7c-0.8-0.2-0.8,3,0,2.8C29.2,20.7,29.2,17.6,28.4,17.7z"/>
		<path class="st41" d="M28.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,17.1c-0.8-0.2-0.8,3,0,2.8C26.2,20.1,26.2,16.9,25.3,17.1z"/>
		<path class="st41" d="M25.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,17.7c-0.8-0.2-0.8,3,0,2.8C27.2,20.7,27.2,17.6,26.4,17.7z"/>
		<path class="st41" d="M26.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,17.1c-0.8-0.2-0.8,3,0,2.8C24.2,20.1,24.2,16.9,23.4,17.1z"/>
		<path class="st41" d="M23.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,17.7c-0.8-0.2-0.8,3,0,2.8C25.2,20.7,25.2,17.6,24.4,17.7z"/>
		<path class="st41" d="M24.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,17.1c-0.8-0.2-0.8,3,0,2.8C22.2,20.1,22.2,16.9,21.4,17.1z"/>
		<path class="st41" d="M21.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,17.7c-0.8-0.2-0.8,3,0,2.8C23.3,20.7,23.3,17.6,22.4,17.7z"/>
		<path class="st41" d="M22.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,17.1c-0.8-0.2-0.8,3,0,2.8C20.2,20.1,20.2,16.9,19.4,17.1z"/>
		<path class="st41" d="M19.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,17.7c-0.8-0.2-0.8,3,0,2.8C21.3,20.7,21.3,17.6,20.4,17.7z"/>
		<path class="st41" d="M20.1,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,17.1c-0.8-0.2-0.8,3,0,2.8C18.2,20.1,18.2,16.9,17.4,17.1z"/>
		<path class="st41" d="M17.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,17.7c-0.8-0.2-0.8,3,0,2.8C19.3,20.7,19.3,17.6,18.4,17.7z"/>
		<path class="st41" d="M18.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,17.1c-0.8-0.2-0.8,3,0,2.8C16.2,20.1,16.2,16.9,15.4,17.1z"/>
		<path class="st41" d="M15.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,17.7c-0.8-0.2-0.8,3,0,2.8C17.3,20.7,17.3,17.6,16.5,17.7z"/>
		<path class="st41" d="M16.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,17.1c-0.8-0.2-0.8,3,0,2.8C14.3,20.1,14.3,16.9,13.4,17.1z"/>
		<path class="st41" d="M13.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,17.7c-0.8-0.2-0.8,3,0,2.8C15.3,20.7,15.3,17.6,14.5,17.7z"/>
		<path class="st41" d="M14.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,17.1c-0.8-0.2-0.8,3,0,2.8C12.3,20.1,12.3,16.9,11.4,17.1z"/>
		<path class="st41" d="M11.1,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,17.7c-0.8-0.2-0.8,3,0,2.8C13.3,20.7,13.3,17.6,12.5,17.7z"/>
		<path class="st41" d="M12.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,17.1c-0.8-0.2-0.8,3,0,2.8C10.3,20.1,10.3,16.9,9.5,17.1z"/>
		<path class="st41" d="M9.2,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,17.7c-0.8-0.2-0.8,3,0,2.8C11.3,20.7,11.3,17.6,10.5,17.7z"/>
		<path class="st41" d="M10.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,17.1c-0.8-0.2-0.8,3,0,2.8C8.3,20.1,8.3,16.9,7.5,17.1z"/>
		<path class="st41" d="M7.2,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,17.7c-0.8-0.2-0.8,3,0,2.8C9.3,20.7,9.3,17.6,8.5,17.7z"/>
		<path class="st41" d="M8.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,17.1c-0.8-0.2-0.8,3,0,2.8C6.3,20.1,6.3,16.9,5.5,17.1z"/>
		<path class="st41" d="M5.2,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,17.7c-0.8-0.2-0.8,3,0,2.8C7.4,20.7,7.4,17.6,6.5,17.7z"/>
		<path class="st41" d="M6.2,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,17.1c-0.8-0.2-0.8,3,0,2.8C4.3,20.1,4.3,16.9,3.5,17.1z"/>
		<path class="st41" d="M3.2,17.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,17.7c-0.8-0.2-0.8,3,0,2.8C5.4,20.7,5.4,17.6,4.5,17.7z"/>
		<path class="st41" d="M4.3,18.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,18.5c-0.8-0.2-0.8,3,0,2.8C65.8,21.5,65.8,18.3,65,18.5z"/>
		<path class="st41" d="M64.7,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,19.1c-0.8-0.2-0.8,3,0,2.8C66.9,22.2,66.9,19,66,19.1z"/>
		<path class="st41" d="M65.7,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,18.5c-0.8-0.2-0.8,3,0,2.8C63.8,21.5,63.8,18.3,63,18.5z"/>
		<path class="st41" d="M62.7,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,19.1c-0.8-0.2-0.8,3,0,2.8C64.9,22.2,64.9,19,64,19.1z"/>
		<path class="st41" d="M63.7,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,18.5c-0.8-0.2-0.8,3,0,2.8C61.8,21.5,61.8,18.3,61,18.5z"/>
		<path class="st41" d="M60.7,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,19.1c-0.8-0.2-0.8,3,0,2.8C62.9,22.2,62.9,19,62,19.1z"/>
		<path class="st41" d="M61.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,18.5c-0.8-0.2-0.8,3,0,2.8C59.8,21.5,59.8,18.3,59,18.5z"/>
		<path class="st41" d="M58.7,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,19.1c-0.8-0.2-0.8,3,0,2.8C60.9,22.2,60.9,19,60.1,19.1z"/>
		<path class="st41" d="M59.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,18.5c-0.8-0.2-0.8,3,0,2.8C57.9,21.5,57.9,18.3,57,18.5z"/>
		<path class="st41" d="M56.7,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,19.1c-0.8-0.2-0.8,3,0,2.8C58.9,22.2,58.9,19,58.1,19.1z"/>
		<path class="st41" d="M57.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,18.5c-0.8-0.2-0.8,3,0,2.8C55.9,21.5,55.9,18.3,55,18.5z"/>
		<path class="st41" d="M54.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,19.1c-0.8-0.2-0.8,3,0,2.8C56.9,22.2,56.9,19,56.1,19.1z"/>
		<path class="st41" d="M55.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,18.5c-0.8-0.2-0.8,3,0,2.8C53.9,21.5,53.9,18.3,53.1,18.5z"/>
		<path class="st41" d="M52.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,19.1c-0.8-0.2-0.8,3,0,2.8C54.9,22.2,54.9,19,54.1,19.1z"/>
		<path class="st41" d="M53.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,18.5c-0.8-0.2-0.8,3,0,2.8C51.9,21.5,51.9,18.3,51.1,18.5z"/>
		<path class="st41" d="M50.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,19.1c-0.8-0.2-0.8,3,0,2.8C52.9,22.2,52.9,19,52.1,19.1z"/>
		<path class="st41" d="M51.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,18.5c-0.8-0.2-0.8,3,0,2.8C49.9,21.5,49.9,18.3,49.1,18.5z"/>
		<path class="st41" d="M48.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,19.1c-0.8-0.2-0.8,3,0,2.8C51,22.2,51,19,50.1,19.1z"/>
		<path class="st41" d="M49.8,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,18.5c-0.8-0.2-0.8,3,0,2.8C47.9,21.5,47.9,18.3,47.1,18.5z"/>
		<path class="st41" d="M46.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,19.1c-0.8-0.2-0.8,3,0,2.8C49,22.2,49,19,48.1,19.1z"/>
		<path class="st41" d="M47.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,18.5c-0.8-0.2-0.8,3,0,2.8C45.9,21.5,45.9,18.3,45.1,18.5z"/>
		<path class="st41" d="M44.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,19.1c-0.8-0.2-0.8,3,0,2.8C47,22.2,47,19,46.2,19.1z"/>
		<path class="st41" d="M45.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,18.5c-0.8-0.2-0.8,3,0,2.8C43.9,21.5,43.9,18.3,43.1,18.5z"/>
		<path class="st41" d="M42.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,19.1c-0.8-0.2-0.8,3,0,2.8C45,22.2,45,19,44.2,19.1z"/>
		<path class="st41" d="M43.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,18.5c-0.8-0.2-0.8,3,0,2.8C42,21.5,42,18.3,41.1,18.5z"/>
		<path class="st41" d="M40.8,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,19.1c-0.8-0.2-0.8,3,0,2.8C43,22.2,43,19,42.2,19.1z"/>
		<path class="st41" d="M41.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,18.5c-0.8-0.2-0.8,3,0,2.8C40,21.5,40,18.3,39.1,18.5z"/>
		<path class="st41" d="M38.9,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,19.1c-0.8-0.2-0.8,3,0,2.8C41,22.2,41,19,40.2,19.1z"/>
		<path class="st41" d="M39.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,18.5c-0.8-0.2-0.8,3,0,2.8C38,21.5,38,18.3,37.2,18.5z"/>
		<path class="st41" d="M36.9,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,19.1c-0.8-0.2-0.8,3,0,2.8C39,22.2,39,19,38.2,19.1z"/>
		<path class="st41" d="M37.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,18.5c-0.8-0.2-0.8,3,0,2.8C36,21.5,36,18.3,35.2,18.5z"/>
		<path class="st41" d="M34.9,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,19.1c-0.8-0.2-0.8,3,0,2.8C37,22.2,37,19,36.2,19.1z"/>
		<path class="st41" d="M35.9,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,18.5c-0.8-0.2-0.8,3,0,2.8C34.1,21.5,34.1,18.3,33.3,18.5z"/>
		<path class="st41" d="M33,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,19.1c-0.8-0.2-0.8,3,0,2.8C35.2,22.2,35.2,19,34.3,19.1z"/>
		<path class="st41" d="M34.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,18.5c-0.8-0.2-0.8,3,0,2.8C32.1,21.5,32.1,18.3,31.3,18.5z"/>
		<path class="st41" d="M31,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,19.1c-0.8-0.2-0.8,3,0,2.8C33.2,22.2,33.2,19,32.4,19.1z"/>
		<path class="st41" d="M32.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,18.5c-0.8-0.2-0.8,3,0,2.8C30.1,21.5,30.2,18.3,29.3,18.5z"/>
		<path class="st41" d="M29,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,19.1c-0.8-0.2-0.8,3,0,2.8C31.2,22.2,31.2,19,30.4,19.1z"/>
		<path class="st41" d="M30.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,18.5c-0.8-0.2-0.8,3,0,2.8C28.2,21.5,28.2,18.3,27.3,18.5z"/>
		<path class="st41" d="M27,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,19.1c-0.8-0.2-0.8,3,0,2.8C29.2,22.2,29.2,19,28.4,19.1z"/>
		<path class="st41" d="M28.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,18.5c-0.8-0.2-0.8,3,0,2.8C26.2,21.5,26.2,18.3,25.3,18.5z"/>
		<path class="st41" d="M25.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,19.1c-0.8-0.2-0.8,3,0,2.8C27.2,22.2,27.2,19,26.4,19.1z"/>
		<path class="st41" d="M26.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,18.5c-0.8-0.2-0.8,3,0,2.8C24.2,21.5,24.2,18.3,23.4,18.5z"/>
		<path class="st41" d="M23.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,19.1c-0.8-0.2-0.8,3,0,2.8C25.2,22.2,25.2,19,24.4,19.1z"/>
		<path class="st41" d="M24.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,18.5c-0.8-0.2-0.8,3,0,2.8C22.2,21.5,22.2,18.3,21.4,18.5z"/>
		<path class="st41" d="M21.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,19.1c-0.8-0.2-0.8,3,0,2.8C23.3,22.2,23.3,19,22.4,19.1z"/>
		<path class="st41" d="M22.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,18.5c-0.8-0.2-0.8,3,0,2.8C20.2,21.5,20.2,18.3,19.4,18.5z"/>
		<path class="st41" d="M19.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,19.1c-0.8-0.2-0.8,3,0,2.8C21.3,22.2,21.3,19,20.4,19.1z"/>
		<path class="st41" d="M20.1,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,18.5c-0.8-0.2-0.8,3,0,2.8C18.2,21.5,18.2,18.3,17.4,18.5z"/>
		<path class="st41" d="M17.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,19.1c-0.8-0.2-0.8,3,0,2.8C19.3,22.2,19.3,19,18.4,19.1z"/>
		<path class="st41" d="M18.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,18.5c-0.8-0.2-0.8,3,0,2.8C16.2,21.5,16.2,18.3,15.4,18.5z"/>
		<path class="st41" d="M15.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,19.1c-0.8-0.2-0.8,3,0,2.8C17.3,22.2,17.3,19,16.5,19.1z"/>
		<path class="st41" d="M16.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,18.5c-0.8-0.2-0.8,3,0,2.8C14.3,21.5,14.3,18.3,13.4,18.5z"/>
		<path class="st41" d="M13.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,19.1c-0.8-0.2-0.8,3,0,2.8C15.3,22.2,15.3,19,14.5,19.1z"/>
		<path class="st41" d="M14.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,18.5c-0.8-0.2-0.8,3,0,2.8C12.3,21.5,12.3,18.3,11.4,18.5z"/>
		<path class="st41" d="M11.1,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,19.1c-0.8-0.2-0.8,3,0,2.8C13.3,22.2,13.3,19,12.5,19.1z"/>
		<path class="st41" d="M12.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,18.5c-0.8-0.2-0.8,3,0,2.8C10.3,21.5,10.3,18.3,9.5,18.5z"/>
		<path class="st41" d="M9.2,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,19.1c-0.8-0.2-0.8,3,0,2.8C11.3,22.2,11.3,19,10.5,19.1z"/>
		<path class="st41" d="M10.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,18.5c-0.8-0.2-0.8,3,0,2.8C8.3,21.5,8.3,18.3,7.5,18.5z"/>
		<path class="st41" d="M7.2,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,19.1c-0.8-0.2-0.8,3,0,2.8C9.3,22.2,9.3,19,8.5,19.1z"/>
		<path class="st41" d="M8.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,18.5c-0.8-0.2-0.8,3,0,2.8C6.3,21.5,6.3,18.3,5.5,18.5z"/>
		<path class="st41" d="M5.2,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,19.1c-0.8-0.2-0.8,3,0,2.8C7.4,22.2,7.4,19,6.5,19.1z"/>
		<path class="st41" d="M6.2,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,18.5c-0.8-0.2-0.8,3,0,2.8C4.3,21.5,4.3,18.3,3.5,18.5z"/>
		<path class="st41" d="M3.2,19.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,19.1c-0.8-0.2-0.8,3,0,2.8C5.4,22.2,5.4,19,4.5,19.1z"/>
		<path class="st41" d="M4.3,20c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M65,20.1c-0.8-0.2-0.8,3,0,2.8C65.8,23.1,65.8,19.9,65,20.1z"/>
		<path class="st41" d="M64.7,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,20.1c-0.8-0.2-0.8,3,0,2.8C61.8,23.1,61.8,19.9,61,20.1z"/>
		<path class="st41" d="M60.7,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,20.1c-0.8-0.2-0.8,3,0,2.8C59.8,23.1,59.8,19.9,59,20.1z"/>
		<path class="st41" d="M58.7,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,20.1c-0.8-0.2-0.8,3,0,2.8C57.9,23.1,57.9,19.9,57,20.1z"/>
		<path class="st41" d="M56.7,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,20.1c-0.8-0.2-0.8,3,0,2.8C55.9,23.1,55.9,19.9,55,20.1z"/>
		<path class="st41" d="M54.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,20.1c-0.8-0.2-0.8,3,0,2.8C53.9,23.1,53.9,19.9,53.1,20.1z"/>
		<path class="st41" d="M52.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,20.1c-0.8-0.2-0.8,3,0,2.8C51.9,23.1,51.9,19.9,51.1,20.1z"/>
		<path class="st41" d="M50.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,20.1c-0.8-0.2-0.8,3,0,2.8C49.9,23.1,49.9,19.9,49.1,20.1z"/>
		<path class="st41" d="M48.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,20.1c-0.8-0.2-0.8,3,0,2.8C47.9,23.1,47.9,19.9,47.1,20.1z"/>
		<path class="st41" d="M46.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,20.1c-0.8-0.2-0.8,3,0,2.8C45.9,23.1,45.9,19.9,45.1,20.1z"/>
		<path class="st41" d="M44.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,20.1c-0.8-0.2-0.8,3,0,2.8C43.9,23.1,43.9,19.9,43.1,20.1z"/>
		<path class="st41" d="M42.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,20.1c-0.8-0.2-0.8,3,0,2.8C42,23.1,42,19.9,41.1,20.1z"/>
		<path class="st41" d="M40.8,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,20.1c-0.8-0.2-0.8,3,0,2.8C40,23.1,40,19.9,39.1,20.1z"/>
		<path class="st41" d="M38.9,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,20.1c-0.8-0.2-0.8,3,0,2.8C38,23.1,38,19.9,37.2,20.1z"/>
		<path class="st41" d="M36.9,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,20.1c-0.8-0.2-0.8,3,0,2.8C36,23.1,36,19.9,35.2,20.1z"/>
		<path class="st41" d="M34.9,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,20.1c-0.8-0.2-0.8,3,0,2.8C34.1,23.1,34.1,19.9,33.3,20.1z"/>
		<path class="st41" d="M33,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,20.1c-0.8-0.2-0.8,3,0,2.8C32.1,23.1,32.1,19.9,31.3,20.1z"/>
		<path class="st41" d="M31,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,20.1c-0.8-0.2-0.8,3,0,2.8C30.1,23.1,30.2,19.9,29.3,20.1z"/>
		<path class="st41" d="M29,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,20.1c-0.8-0.2-0.8,3,0,2.8C28.2,23.1,28.2,19.9,27.3,20.1z"/>
		<path class="st41" d="M27,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,20.1c-0.8-0.2-0.8,3,0,2.8C26.2,23.1,26.2,19.9,25.3,20.1z"/>
		<path class="st41" d="M25.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,20.1c-0.8-0.2-0.8,3,0,2.8C24.2,23.1,24.2,19.9,23.4,20.1z"/>
		<path class="st41" d="M23.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,20.1c-0.8-0.2-0.8,3,0,2.8C22.2,23.1,22.2,19.9,21.4,20.1z"/>
		<path class="st41" d="M21.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,20.1c-0.8-0.2-0.8,3,0,2.8C20.2,23.1,20.2,19.9,19.4,20.1z"/>
		<path class="st41" d="M19.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,20.1c-0.8-0.2-0.8,3,0,2.8C18.2,23.1,18.2,19.9,17.4,20.1z"/>
		<path class="st41" d="M17.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,20.1c-0.8-0.2-0.8,3,0,2.8C16.2,23.1,16.2,19.9,15.4,20.1z"/>
		<path class="st41" d="M15.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,20.1c-0.8-0.2-0.8,3,0,2.8C14.3,23.1,14.3,19.9,13.4,20.1z"/>
		<path class="st41" d="M13.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,20.1c-0.8-0.2-0.8,3,0,2.8C12.3,23.1,12.3,19.9,11.4,20.1z"/>
		<path class="st41" d="M11.1,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,20.1c-0.8-0.2-0.8,3,0,2.8C10.3,23.1,10.3,19.9,9.5,20.1z"/>
		<path class="st41" d="M9.2,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,20.1c-0.8-0.2-0.8,3,0,2.8C8.3,23.1,8.3,19.9,7.5,20.1z"/>
		<path class="st41" d="M7.2,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,20.1c-0.8-0.2-0.8,3,0,2.8C6.3,23.1,6.3,19.9,5.5,20.1z"/>
		<path class="st41" d="M5.2,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,20.1c-0.8-0.2-0.8,3,0,2.8C4.3,23.1,4.3,19.9,3.5,20.1z"/>
		<path class="st41" d="M3.2,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M65.9,21c-0.8-0.2-0.8,3,0,2.8C66.7,24,66.7,20.9,65.9,21z"/>
		<path class="st41" d="M65.6,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M60,21c-0.8-0.2-0.8,3,0,2.8C60.8,24,60.8,20.9,60,21z"/>
		<path class="st41" d="M59.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M58,21c-0.8-0.2-0.8,3,0,2.8C58.8,24,58.8,20.9,58,21z"/>
		<path class="st41" d="M57.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M56,21c-0.8-0.2-0.8,3,0,2.8C56.8,24,56.8,20.9,56,21z"/>
		<path class="st41" d="M55.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M54,21c-0.8-0.2-0.8,3,0,2.8C54.8,24,54.8,20.9,54,21z"/>
		<path class="st41" d="M53.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M52,21c-0.8-0.2-0.8,3,0,2.8C52.8,24,52.8,20.9,52,21z"/>
		<path class="st41" d="M51.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M50,21c-0.8-0.2-0.8,3,0,2.8C50.8,24,50.8,20.9,50,21z"/>
		<path class="st41" d="M49.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M48,21c-0.8-0.2-0.8,3,0,2.8C48.9,24,48.9,20.9,48,21z"/>
		<path class="st41" d="M47.7,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M46,21c-0.8-0.2-0.8,3,0,2.8C46.9,24,46.9,20.9,46,21z"/>
		<path class="st41" d="M45.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M44.1,21c-0.8-0.2-0.8,3,0,2.8C44.9,24,44.9,20.9,44.1,21z"/>
		<path class="st41" d="M43.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M42.1,21c-0.8-0.2-0.8,3,0,2.8C42.9,24,42.9,20.9,42.1,21z"/>
		<path class="st41" d="M41.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M40.1,21c-0.8-0.2-0.8,3,0,2.8C40.9,24,40.9,20.9,40.1,21z"/>
		<path class="st41" d="M39.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M38.1,21c-0.8-0.2-0.8,3,0,2.8C38.9,24,38.9,20.9,38.1,21z"/>
		<path class="st41" d="M37.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M36.1,21c-0.8-0.2-0.8,3,0,2.8C36.9,24,36.9,20.9,36.1,21z"/>
		<path class="st41" d="M35.8,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M34.2,21c-0.8-0.2-0.8,3,0,2.8C35.1,24,35.1,20.9,34.2,21z"/>
		<path class="st41" d="M33.9,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M32.2,21c-0.8-0.2-0.8,3,0,2.8C33.1,24,33.1,20.9,32.2,21z"/>
		<path class="st41" d="M32,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M30.3,21c-0.8-0.2-0.8,3,0,2.8C31.1,24,31.1,20.9,30.3,21z"/>
		<path class="st41" d="M30,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M28.3,21c-0.8-0.2-0.8,3,0,2.8C29.1,24,29.1,20.9,28.3,21z"/>
		<path class="st41" d="M28,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M26.3,21c-0.8-0.2-0.8,3,0,2.8C27.1,24,27.1,20.9,26.3,21z"/>
		<path class="st42" d="M26,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M24.3,21c-0.8-0.2-0.8,3,0,2.8C25.1,24,25.1,20.9,24.3,21z"/>
		<path class="st42" d="M24,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M22.3,21c-0.8-0.2-0.8,3,0,2.8C23.1,24,23.1,20.9,22.3,21z"/>
		<path class="st42" d="M22,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M20.3,21c-0.8-0.2-0.8,3,0,2.8C21.2,24,21.2,20.9,20.3,21z"/>
		<path class="st42" d="M20,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M18.3,21c-0.8-0.2-0.8,3,0,2.8C19.2,24,19.2,20.9,18.3,21z"/>
		<path class="st42" d="M18,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M16.3,21c-0.8-0.2-0.8,3,0,2.8C17.2,24,17.2,20.9,16.3,21z"/>
		<path class="st42" d="M16.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M14.4,21c-0.8-0.2-0.8,3,0,2.8C15.2,24,15.2,20.9,14.4,21z"/>
		<path class="st42" d="M14.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M12.4,21c-0.8-0.2-0.8,3,0,2.8C13.2,24,13.2,20.9,12.4,21z"/>
		<path class="st42" d="M12.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M10.4,21c-0.8-0.2-0.8,3,0,2.8C11.2,24,11.2,20.9,10.4,21z"/>
		<path class="st42" d="M10.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M8.4,21c-0.8-0.2-0.8,3,0,2.8C9.2,24,9.2,20.9,8.4,21z"/>
		<path class="st42" d="M8.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M6.4,21c-0.8-0.2-0.8,3,0,2.8C7.2,24,7.2,20.9,6.4,21z"/>
		<path class="st42" d="M6.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M4.4,21c-0.8-0.2-0.8,3,0,2.8C5.3,24,5.3,20.9,4.4,21z"/>
		<path class="st42" d="M4.1,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M21.3,21.9c-0.8-0.2-0.8,3,0,2.8C22.1,24.9,22.1,21.8,21.3,21.9z"/>
		<path class="st42" d="M21,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M19.3,21.9c-0.8-0.2-0.8,3,0,2.8C20.2,24.9,20.2,21.8,19.3,21.9z"/>
		<path class="st42" d="M19,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M17.3,21.9c-0.8-0.2-0.8,3,0,2.8C18.2,24.9,18.2,21.8,17.3,21.9z"/>
		<path class="st42" d="M17.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M15.4,21.9c-0.8-0.2-0.8,3,0,2.8C16.2,24.9,16.2,21.8,15.4,21.9z"/>
		<path class="st42" d="M15.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M13.4,21.9c-0.8-0.2-0.8,3,0,2.8C14.2,24.9,14.2,21.8,13.4,21.9z"/>
		<path class="st42" d="M13.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M11.4,21.9c-0.8-0.2-0.8,3,0,2.8C12.2,24.9,12.2,21.8,11.4,21.9z"/>
		<path class="st42" d="M11.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M9.4,21.9c-0.8-0.2-0.8,3,0,2.8C10.2,24.9,10.2,21.8,9.4,21.9z"/>
		<path class="st42" d="M9.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M7.4,21.9c-0.8-0.2-0.8,3,0,2.8C8.2,24.9,8.2,21.8,7.4,21.9z"/>
		<path class="st42" d="M7.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M5.4,21.9c-0.8-0.2-0.8,3,0,2.8C6.3,24.9,6.3,21.8,5.4,21.9z"/>
		<path class="st42" d="M5.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.4,21.9c-0.8-0.2-0.8,3,0,2.8C4.3,24.9,4.3,21.8,3.4,21.9z"/>
		<path class="st42" d="M3.1,22.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M63,20.1c-0.8-0.2-0.8,3,0,2.8C63.8,23.1,63.8,19.9,63,20.1z"/>
		<path class="st41" d="M62.7,21c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M63.9,21c-0.8-0.2-0.8,3,0,2.8C64.8,24,64.8,20.9,63.9,21z"/>
		<path class="st41" d="M63.6,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M61.9,21c-0.8-0.2-0.8,3,0,2.8C62.8,24,62.8,20.9,61.9,21z"/>
		<path class="st41" d="M61.6,21.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M65,22c-0.8-0.2-0.8,3,0,2.8C65.8,25,65.8,21.8,65,22z"/>
		<path class="st42" d="M64.7,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M63,22c-0.8-0.2-0.8,3,0,2.8C63.8,25,63.8,21.8,63,22z"/>
		<path class="st42" d="M62.7,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M61,22c-0.8-0.2-0.8,3,0,2.8C61.8,25,61.8,21.8,61,22z"/>
		<path class="st42" d="M60.7,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M59,22c-0.8-0.2-0.8,3,0,2.8C59.8,25,59.8,21.8,59,22z"/>
		<path class="st42" d="M58.7,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M57,22c-0.8-0.2-0.8,3,0,2.8C57.9,25,57.9,21.8,57,22z"/>
		<path class="st42" d="M56.7,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M55,22c-0.8-0.2-0.8,3,0,2.8C55.9,25,55.9,21.8,55,22z"/>
		<path class="st42" d="M54.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M53.1,22c-0.8-0.2-0.8,3,0,2.8C53.9,25,53.9,21.8,53.1,22z"/>
		<path class="st42" d="M52.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M51.1,22c-0.8-0.2-0.8,3,0,2.8C51.9,25,51.9,21.8,51.1,22z"/>
		<path class="st42" d="M50.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M49.1,22c-0.8-0.2-0.8,3,0,2.8C49.9,25,49.9,21.8,49.1,22z"/>
		<path class="st42" d="M48.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M47.1,22c-0.8-0.2-0.8,3,0,2.8C47.9,25,47.9,21.8,47.1,22z"/>
		<path class="st42" d="M46.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M45.1,22c-0.8-0.2-0.8,3,0,2.8C45.9,25,45.9,21.8,45.1,22z"/>
		<path class="st42" d="M44.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M43.1,22c-0.8-0.2-0.8,3,0,2.8C43.9,25,43.9,21.8,43.1,22z"/>
		<path class="st42" d="M42.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M41.1,22c-0.8-0.2-0.8,3,0,2.8C42,25,42,21.8,41.1,22z"/>
		<path class="st42" d="M40.8,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M39.1,22c-0.8-0.2-0.8,3,0,2.8C40,25,40,21.8,39.1,22z"/>
		<path class="st42" d="M38.9,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M37.2,22c-0.8-0.2-0.8,3,0,2.8C38,25,38,21.8,37.2,22z"/>
		<path class="st42" d="M36.9,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M35.2,22c-0.8-0.2-0.8,3,0,2.8C36,25,36,21.8,35.2,22z"/>
		<path class="st42" d="M34.9,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M33.3,22c-0.8-0.2-0.8,3,0,2.8C34.1,25,34.1,21.8,33.3,22z"/>
		<path class="st42" d="M33,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M31.3,22c-0.8-0.2-0.8,3,0,2.8C32.1,25,32.1,21.8,31.3,22z"/>
		<path class="st42" d="M31,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M29.3,22c-0.8-0.2-0.8,3,0,2.8C30.1,25,30.2,21.8,29.3,22z"/>
		<path class="st42" d="M29,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M27.3,22c-0.8-0.2-0.8,3,0,2.8C28.2,25,28.2,21.8,27.3,22z"/>
		<path class="st42" d="M27,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M25.3,22c-0.8-0.2-0.8,3,0,2.8C26.2,25,26.2,21.8,25.3,22z"/>
		<path class="st42" d="M25.1,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M23.4,22c-0.8-0.2-0.8,3,0,2.8C24.2,25,24.2,21.8,23.4,22z"/>
		<path class="st42" d="M23.1,22.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st41" d="M10.5,204.4c-0.8-0.2-0.8,3,0,2.8C11.3,207.4,11.3,204.2,10.5,204.4z"/>
		<path class="st41" d="M10.2,205.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M8.5,204.4c-0.8-0.2-0.8,3,0,2.8C9.3,207.4,9.3,204.2,8.5,204.4z"/>
		<path class="st41" d="M8.2,205.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M6.5,204.4c-0.8-0.2-0.8,3,0,2.8C7.4,207.4,7.4,204.2,6.5,204.4z"/>
		<path class="st41" d="M6.2,205.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M4.5,204.4c-0.8-0.2-0.8,3,0,2.8C5.4,207.4,5.4,204.2,4.5,204.4z"/>
		<path class="st41" d="M4.3,205.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st41" d="M12.5,204.4c-0.8-0.2-0.8,3,0,2.8C13.3,207.4,13.3,204.2,12.5,204.4z"/>
		<path class="st41" d="M12.2,205.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,205.1c-0.8-0.2-0.8,3,0,2.8C65.8,208.1,65.8,205,65,205.1z"/>
		<path class="st41" d="M64.7,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,205.8c-0.8-0.2-0.8,3,0,2.8C66.9,208.8,66.9,205.6,66,205.8z"/>
		<path class="st41" d="M65.7,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,205.1c-0.8-0.2-0.8,3,0,2.8C63.8,208.1,63.8,205,63,205.1z"/>
		<path class="st41" d="M62.7,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,205.8c-0.8-0.2-0.8,3,0,2.8C64.9,208.8,64.9,205.6,64,205.8z"/>
		<path class="st41" d="M63.7,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,205.1c-0.8-0.2-0.8,3,0,2.8C61.8,208.1,61.8,205,61,205.1z"/>
		<path class="st41" d="M60.7,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,205.8c-0.8-0.2-0.8,3,0,2.8C62.9,208.8,62.9,205.6,62,205.8z"/>
		<path class="st41" d="M61.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,205.1c-0.8-0.2-0.8,3,0,2.8C59.8,208.1,59.8,205,59,205.1z"/>
		<path class="st41" d="M58.7,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,205.8c-0.8-0.2-0.8,3,0,2.8C60.9,208.8,60.9,205.6,60.1,205.8z"/>
		<path class="st41" d="M59.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,205.1c-0.8-0.2-0.8,3,0,2.8C57.9,208.1,57.9,205,57,205.1z"/>
		<path class="st41" d="M56.7,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,205.8c-0.8-0.2-0.8,3,0,2.8C58.9,208.8,58.9,205.6,58.1,205.8z"/>
		<path class="st41" d="M57.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,205.1c-0.8-0.2-0.8,3,0,2.8C55.9,208.1,55.9,205,55,205.1z"/>
		<path class="st41" d="M54.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,205.8c-0.8-0.2-0.8,3,0,2.8C56.9,208.8,56.9,205.6,56.1,205.8z"/>
		<path class="st41" d="M55.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,205.1c-0.8-0.2-0.8,3,0,2.8C53.9,208.1,53.9,205,53.1,205.1z"/>
		<path class="st41" d="M52.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,205.8c-0.8-0.2-0.8,3,0,2.8C54.9,208.8,54.9,205.6,54.1,205.8z"/>
		<path class="st41" d="M53.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,205.1c-0.8-0.2-0.8,3,0,2.8C51.9,208.1,51.9,205,51.1,205.1z"/>
		<path class="st41" d="M50.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,205.8c-0.8-0.2-0.8,3,0,2.8C52.9,208.8,52.9,205.6,52.1,205.8z"/>
		<path class="st41" d="M51.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,205.1c-0.8-0.2-0.8,3,0,2.8C49.9,208.1,49.9,205,49.1,205.1z"/>
		<path class="st41" d="M48.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,205.8c-0.8-0.2-0.8,3,0,2.8C51,208.8,51,205.6,50.1,205.8z"/>
		<path class="st41" d="M49.8,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,205.1c-0.8-0.2-0.8,3,0,2.8C47.9,208.1,47.9,205,47.1,205.1z"/>
		<path class="st41" d="M46.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,205.8c-0.8-0.2-0.8,3,0,2.8C49,208.8,49,205.6,48.1,205.8z"/>
		<path class="st41" d="M47.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,205.1c-0.8-0.2-0.8,3,0,2.8C45.9,208.1,45.9,205,45.1,205.1z"/>
		<path class="st41" d="M44.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,205.8c-0.8-0.2-0.8,3,0,2.8C47,208.8,47,205.6,46.2,205.8z"/>
		<path class="st41" d="M45.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,205.1c-0.8-0.2-0.8,3,0,2.8C43.9,208.1,43.9,205,43.1,205.1z"/>
		<path class="st41" d="M42.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,205.8c-0.8-0.2-0.8,3,0,2.8C45,208.8,45,205.6,44.2,205.8z"/>
		<path class="st41" d="M43.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,205.1c-0.8-0.2-0.8,3,0,2.8C42,208.1,42,205,41.1,205.1z"/>
		<path class="st41" d="M40.8,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,205.8c-0.8-0.2-0.8,3,0,2.8C43,208.8,43,205.6,42.2,205.8z"/>
		<path class="st41" d="M41.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,205.1c-0.8-0.2-0.8,3,0,2.8C40,208.1,40,205,39.1,205.1z"/>
		<path class="st41" d="M38.9,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,205.8c-0.8-0.2-0.8,3,0,2.8C41,208.8,41,205.6,40.2,205.8z"/>
		<path class="st41" d="M39.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,205.1c-0.8-0.2-0.8,3,0,2.8C38,208.1,38,205,37.2,205.1z"/>
		<path class="st41" d="M36.9,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,205.8c-0.8-0.2-0.8,3,0,2.8C39,208.8,39,205.6,38.2,205.8z"/>
		<path class="st41" d="M37.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,205.1c-0.8-0.2-0.8,3,0,2.8C36,208.1,36,205,35.2,205.1z"/>
		<path class="st41" d="M34.9,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,205.8c-0.8-0.2-0.8,3,0,2.8C37,208.8,37,205.6,36.2,205.8z"/>
		<path class="st41" d="M35.9,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,205.1c-0.8-0.2-0.8,3,0,2.8C34.1,208.1,34.1,205,33.3,205.1z"/>
		<path class="st41" d="M33,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,205.8c-0.8-0.2-0.8,3,0,2.8C35.2,208.8,35.2,205.6,34.3,205.8z"/>
		<path class="st41" d="M34.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,205.1c-0.8-0.2-0.8,3,0,2.8C32.1,208.1,32.1,205,31.3,205.1z"/>
		<path class="st41" d="M31,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,205.8c-0.8-0.2-0.8,3,0,2.8C33.2,208.8,33.2,205.6,32.4,205.8z"/>
		<path class="st41" d="M32.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,205.1c-0.8-0.2-0.8,3,0,2.8C30.1,208.1,30.2,205,29.3,205.1z"/>
		<path class="st41" d="M29,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,205.8c-0.8-0.2-0.8,3,0,2.8C31.2,208.8,31.2,205.6,30.4,205.8z"/>
		<path class="st41" d="M30.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,205.1c-0.8-0.2-0.8,3,0,2.8C28.2,208.1,28.2,205,27.3,205.1z"/>
		<path class="st41" d="M27,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,205.8c-0.8-0.2-0.8,3,0,2.8C29.2,208.8,29.2,205.6,28.4,205.8z"/>
		<path class="st41" d="M28.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,205.1c-0.8-0.2-0.8,3,0,2.8C26.2,208.1,26.2,205,25.3,205.1z"/>
		<path class="st41" d="M25.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,205.8c-0.8-0.2-0.8,3,0,2.8C27.2,208.8,27.2,205.6,26.4,205.8z"/>
		<path class="st41" d="M26.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,205.1c-0.8-0.2-0.8,3,0,2.8C24.2,208.1,24.2,205,23.4,205.1z"/>
		<path class="st41" d="M23.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,205.8c-0.8-0.2-0.8,3,0,2.8C25.2,208.8,25.2,205.6,24.4,205.8z"/>
		<path class="st41" d="M24.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,205.1c-0.8-0.2-0.8,3,0,2.8C22.2,208.1,22.2,205,21.4,205.1z"/>
		<path class="st41" d="M21.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,205.8c-0.8-0.2-0.8,3,0,2.8C23.3,208.8,23.3,205.6,22.4,205.8z"/>
		<path class="st41" d="M22.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,205.1c-0.8-0.2-0.8,3,0,2.8C20.2,208.1,20.2,205,19.4,205.1z"/>
		<path class="st41" d="M19.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,205.8c-0.8-0.2-0.8,3,0,2.8C21.3,208.8,21.3,205.6,20.4,205.8z"/>
		<path class="st41" d="M20.1,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,205.1c-0.8-0.2-0.8,3,0,2.8C18.2,208.1,18.2,205,17.4,205.1z"/>
		<path class="st41" d="M17.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,205.8c-0.8-0.2-0.8,3,0,2.8C19.3,208.8,19.3,205.6,18.4,205.8z"/>
		<path class="st41" d="M18.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,205.1c-0.8-0.2-0.8,3,0,2.8C16.2,208.1,16.2,205,15.4,205.1z"/>
		<path class="st41" d="M15.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,205.8c-0.8-0.2-0.8,3,0,2.8C17.3,208.8,17.3,205.6,16.5,205.8z"/>
		<path class="st41" d="M16.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,205.1c-0.8-0.2-0.8,3,0,2.8C14.3,208.1,14.3,205,13.4,205.1z"/>
		<path class="st41" d="M13.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,205.8c-0.8-0.2-0.8,3,0,2.8C15.3,208.8,15.3,205.6,14.5,205.8z"/>
		<path class="st41" d="M14.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,205.1c-0.8-0.2-0.8,3,0,2.8C12.3,208.1,12.3,205,11.4,205.1z"/>
		<path class="st41" d="M11.1,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,205.8c-0.8-0.2-0.8,3,0,2.8C13.3,208.8,13.3,205.6,12.5,205.8z"/>
		<path class="st41" d="M12.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,205.1c-0.8-0.2-0.8,3,0,2.8C10.3,208.1,10.3,205,9.5,205.1z"/>
		<path class="st41" d="M9.2,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,205.8c-0.8-0.2-0.8,3,0,2.8C11.3,208.8,11.3,205.6,10.5,205.8z"/>
		<path class="st41" d="M10.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,205.1c-0.8-0.2-0.8,3,0,2.8C8.3,208.1,8.3,205,7.5,205.1z"/>
		<path class="st41" d="M7.2,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,205.8c-0.8-0.2-0.8,3,0,2.8C9.3,208.8,9.3,205.6,8.5,205.8z"/>
		<path class="st41" d="M8.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,205.1c-0.8-0.2-0.8,3,0,2.8C6.3,208.1,6.3,205,5.5,205.1z"/>
		<path class="st41" d="M5.2,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,205.8c-0.8-0.2-0.8,3,0,2.8C7.4,208.8,7.4,205.6,6.5,205.8z"/>
		<path class="st41" d="M6.2,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,205.1c-0.8-0.2-0.8,3,0,2.8C4.3,208.1,4.3,205,3.5,205.1z"/>
		<path class="st41" d="M3.2,206c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,205.8c-0.8-0.2-0.8,3,0,2.8C5.4,208.8,5.4,205.6,4.5,205.8z"/>
		<path class="st41" d="M4.3,206.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,206.6c-0.8-0.2-0.8,3,0,2.8C65.8,209.6,65.8,206.4,65,206.6z"/>
		<path class="st41" d="M64.7,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,207.2c-0.8-0.2-0.8,3,0,2.8C66.9,210.2,66.9,207.1,66,207.2z"/>
		<path class="st41" d="M65.7,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,206.6c-0.8-0.2-0.8,3,0,2.8C63.8,209.6,63.8,206.4,63,206.6z"/>
		<path class="st41" d="M62.7,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,207.2c-0.8-0.2-0.8,3,0,2.8C64.9,210.2,64.9,207.1,64,207.2z"/>
		<path class="st41" d="M63.7,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,206.6c-0.8-0.2-0.8,3,0,2.8C61.8,209.6,61.8,206.4,61,206.6z"/>
		<path class="st41" d="M60.7,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,207.2c-0.8-0.2-0.8,3,0,2.8C62.9,210.2,62.9,207.1,62,207.2z"/>
		<path class="st41" d="M61.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,206.6c-0.8-0.2-0.8,3,0,2.8C59.8,209.6,59.8,206.4,59,206.6z"/>
		<path class="st41" d="M58.7,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,207.2c-0.8-0.2-0.8,3,0,2.8C60.9,210.2,60.9,207.1,60.1,207.2z"/>
		<path class="st41" d="M59.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,206.6c-0.8-0.2-0.8,3,0,2.8C57.9,209.6,57.9,206.4,57,206.6z"/>
		<path class="st41" d="M56.7,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,207.2c-0.8-0.2-0.8,3,0,2.8C58.9,210.2,58.9,207.1,58.1,207.2z"/>
		<path class="st41" d="M57.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,206.6c-0.8-0.2-0.8,3,0,2.8C55.9,209.6,55.9,206.4,55,206.6z"/>
		<path class="st41" d="M54.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,207.2c-0.8-0.2-0.8,3,0,2.8C56.9,210.2,56.9,207.1,56.1,207.2z"/>
		<path class="st41" d="M55.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,206.6c-0.8-0.2-0.8,3,0,2.8C53.9,209.6,53.9,206.4,53.1,206.6z"/>
		<path class="st41" d="M52.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,207.2c-0.8-0.2-0.8,3,0,2.8C54.9,210.2,54.9,207.1,54.1,207.2z"/>
		<path class="st41" d="M53.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,206.6c-0.8-0.2-0.8,3,0,2.8C51.9,209.6,51.9,206.4,51.1,206.6z"/>
		<path class="st41" d="M50.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,207.2c-0.8-0.2-0.8,3,0,2.8C52.9,210.2,52.9,207.1,52.1,207.2z"/>
		<path class="st41" d="M51.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,206.6c-0.8-0.2-0.8,3,0,2.8C49.9,209.6,49.9,206.4,49.1,206.6z"/>
		<path class="st41" d="M48.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,207.2c-0.8-0.2-0.8,3,0,2.8C51,210.2,51,207.1,50.1,207.2z"/>
		<path class="st41" d="M49.8,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,206.6c-0.8-0.2-0.8,3,0,2.8C47.9,209.6,47.9,206.4,47.1,206.6z"/>
		<path class="st41" d="M46.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,207.2c-0.8-0.2-0.8,3,0,2.8C49,210.2,49,207.1,48.1,207.2z"/>
		<path class="st41" d="M47.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,206.6c-0.8-0.2-0.8,3,0,2.8C45.9,209.6,45.9,206.4,45.1,206.6z"/>
		<path class="st41" d="M44.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,207.2c-0.8-0.2-0.8,3,0,2.8C47,210.2,47,207.1,46.2,207.2z"/>
		<path class="st41" d="M45.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,206.6c-0.8-0.2-0.8,3,0,2.8C43.9,209.6,43.9,206.4,43.1,206.6z"/>
		<path class="st41" d="M42.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,207.2c-0.8-0.2-0.8,3,0,2.8C45,210.2,45,207.1,44.2,207.2z"/>
		<path class="st41" d="M43.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,206.6c-0.8-0.2-0.8,3,0,2.8C42,209.6,42,206.4,41.1,206.6z"/>
		<path class="st41" d="M40.8,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,207.2c-0.8-0.2-0.8,3,0,2.8C43,210.2,43,207.1,42.2,207.2z"/>
		<path class="st41" d="M41.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,206.6c-0.8-0.2-0.8,3,0,2.8C40,209.6,40,206.4,39.1,206.6z"/>
		<path class="st41" d="M38.9,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,207.2c-0.8-0.2-0.8,3,0,2.8C41,210.2,41,207.1,40.2,207.2z"/>
		<path class="st41" d="M39.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,206.6c-0.8-0.2-0.8,3,0,2.8C38,209.6,38,206.4,37.2,206.6z"/>
		<path class="st41" d="M36.9,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,207.2c-0.8-0.2-0.8,3,0,2.8C39,210.2,39,207.1,38.2,207.2z"/>
		<path class="st41" d="M37.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,206.6c-0.8-0.2-0.8,3,0,2.8C36,209.6,36,206.4,35.2,206.6z"/>
		<path class="st41" d="M34.9,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,207.2c-0.8-0.2-0.8,3,0,2.8C37,210.2,37,207.1,36.2,207.2z"/>
		<path class="st41" d="M35.9,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,206.6c-0.8-0.2-0.8,3,0,2.8C34.1,209.6,34.1,206.4,33.3,206.6z"/>
		<path class="st41" d="M33,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,207.2c-0.8-0.2-0.8,3,0,2.8C35.2,210.2,35.2,207.1,34.3,207.2z"/>
		<path class="st41" d="M34.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,206.6c-0.8-0.2-0.8,3,0,2.8C32.1,209.6,32.1,206.4,31.3,206.6z"/>
		<path class="st41" d="M31,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,207.2c-0.8-0.2-0.8,3,0,2.8C33.2,210.2,33.2,207.1,32.4,207.2z"/>
		<path class="st41" d="M32.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,206.6c-0.8-0.2-0.8,3,0,2.8C30.1,209.6,30.2,206.4,29.3,206.6z"/>
		<path class="st41" d="M29,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,207.2c-0.8-0.2-0.8,3,0,2.8C31.2,210.2,31.2,207.1,30.4,207.2z"/>
		<path class="st41" d="M30.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,206.6c-0.8-0.2-0.8,3,0,2.8C28.2,209.6,28.2,206.4,27.3,206.6z"/>
		<path class="st41" d="M27,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,207.2c-0.8-0.2-0.8,3,0,2.8C29.2,210.2,29.2,207.1,28.4,207.2z"/>
		<path class="st41" d="M28.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,206.6c-0.8-0.2-0.8,3,0,2.8C26.2,209.6,26.2,206.4,25.3,206.6z"/>
		<path class="st41" d="M25.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,207.2c-0.8-0.2-0.8,3,0,2.8C27.2,210.2,27.2,207.1,26.4,207.2z"/>
		<path class="st41" d="M26.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,206.6c-0.8-0.2-0.8,3,0,2.8C24.2,209.6,24.2,206.4,23.4,206.6z"/>
		<path class="st41" d="M23.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,207.2c-0.8-0.2-0.8,3,0,2.8C25.2,210.2,25.2,207.1,24.4,207.2z"/>
		<path class="st41" d="M24.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,206.6c-0.8-0.2-0.8,3,0,2.8C22.2,209.6,22.2,206.4,21.4,206.6z"/>
		<path class="st41" d="M21.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,207.2c-0.8-0.2-0.8,3,0,2.8C23.3,210.2,23.3,207.1,22.4,207.2z"/>
		<path class="st41" d="M22.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,206.6c-0.8-0.2-0.8,3,0,2.8C20.2,209.6,20.2,206.4,19.4,206.6z"/>
		<path class="st41" d="M19.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,207.2c-0.8-0.2-0.8,3,0,2.8C21.3,210.2,21.3,207.1,20.4,207.2z"/>
		<path class="st41" d="M20.1,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,206.6c-0.8-0.2-0.8,3,0,2.8C18.2,209.6,18.2,206.4,17.4,206.6z"/>
		<path class="st41" d="M17.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,207.2c-0.8-0.2-0.8,3,0,2.8C19.3,210.2,19.3,207.1,18.4,207.2z"/>
		<path class="st41" d="M18.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,206.6c-0.8-0.2-0.8,3,0,2.8C16.2,209.6,16.2,206.4,15.4,206.6z"/>
		<path class="st41" d="M15.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,207.2c-0.8-0.2-0.8,3,0,2.8C17.3,210.2,17.3,207.1,16.5,207.2z"/>
		<path class="st41" d="M16.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,206.6c-0.8-0.2-0.8,3,0,2.8C14.3,209.6,14.3,206.4,13.4,206.6z"/>
		<path class="st41" d="M13.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,207.2c-0.8-0.2-0.8,3,0,2.8C15.3,210.2,15.3,207.1,14.5,207.2z"/>
		<path class="st41" d="M14.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,206.6c-0.8-0.2-0.8,3,0,2.8C12.3,209.6,12.3,206.4,11.4,206.6z"/>
		<path class="st41" d="M11.1,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,207.2c-0.8-0.2-0.8,3,0,2.8C13.3,210.2,13.3,207.1,12.5,207.2z"/>
		<path class="st41" d="M12.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,206.6c-0.8-0.2-0.8,3,0,2.8C10.3,209.6,10.3,206.4,9.5,206.6z"/>
		<path class="st41" d="M9.2,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,207.2c-0.8-0.2-0.8,3,0,2.8C11.3,210.2,11.3,207.1,10.5,207.2z"/>
		<path class="st41" d="M10.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,206.6c-0.8-0.2-0.8,3,0,2.8C8.3,209.6,8.3,206.4,7.5,206.6z"/>
		<path class="st41" d="M7.2,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,207.2c-0.8-0.2-0.8,3,0,2.8C9.3,210.2,9.3,207.1,8.5,207.2z"/>
		<path class="st41" d="M8.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,206.6c-0.8-0.2-0.8,3,0,2.8C6.3,209.6,6.3,206.4,5.5,206.6z"/>
		<path class="st41" d="M5.2,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,207.2c-0.8-0.2-0.8,3,0,2.8C7.4,210.2,7.4,207.1,6.5,207.2z"/>
		<path class="st41" d="M6.2,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,206.6c-0.8-0.2-0.8,3,0,2.8C4.3,209.6,4.3,206.4,3.5,206.6z"/>
		<path class="st41" d="M3.2,207.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,207.2c-0.8-0.2-0.8,3,0,2.8C5.4,210.2,5.4,207.1,4.5,207.2z"/>
		<path class="st41" d="M4.3,208.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,207.9c-0.8-0.2-0.8,3,0,2.8C65.8,210.9,65.8,207.7,65,207.9z"/>
		<path class="st41" d="M64.7,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M66,208.5c-0.8-0.2-0.8,3,0,2.8C66.9,211.5,66.9,208.4,66,208.5z"/>
		<path class="st41" d="M65.7,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,207.9c-0.8-0.2-0.8,3,0,2.8C63.8,210.9,63.8,207.7,63,207.9z"/>
		<path class="st41" d="M62.7,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M64,208.5c-0.8-0.2-0.8,3,0,2.8C64.9,211.5,64.9,208.4,64,208.5z"/>
		<path class="st41" d="M63.7,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,207.9c-0.8-0.2-0.8,3,0,2.8C61.8,210.9,61.8,207.7,61,207.9z"/>
		<path class="st41" d="M60.7,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M62,208.5c-0.8-0.2-0.8,3,0,2.8C62.9,211.5,62.9,208.4,62,208.5z"/>
		<path class="st41" d="M61.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,207.9c-0.8-0.2-0.8,3,0,2.8C59.8,210.9,59.8,207.7,59,207.9z"/>
		<path class="st41" d="M58.7,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M60.1,208.5c-0.8-0.2-0.8,3,0,2.8C60.9,211.5,60.9,208.4,60.1,208.5z"/>
		<path class="st41" d="M59.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,207.9c-0.8-0.2-0.8,3,0,2.8C57.9,210.9,57.9,207.7,57,207.9z"/>
		<path class="st41" d="M56.7,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M58.1,208.5c-0.8-0.2-0.8,3,0,2.8C58.9,211.5,58.9,208.4,58.1,208.5z"/>
		<path class="st41" d="M57.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,207.9c-0.8-0.2-0.8,3,0,2.8C55.9,210.9,55.9,207.7,55,207.9z"/>
		<path class="st41" d="M54.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M56.1,208.5c-0.8-0.2-0.8,3,0,2.8C56.9,211.5,56.9,208.4,56.1,208.5z"/>
		<path class="st41" d="M55.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,207.9c-0.8-0.2-0.8,3,0,2.8C53.9,210.9,53.9,207.7,53.1,207.9z"/>
		<path class="st41" d="M52.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M54.1,208.5c-0.8-0.2-0.8,3,0,2.8C54.9,211.5,54.9,208.4,54.1,208.5z"/>
		<path class="st41" d="M53.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,207.9c-0.8-0.2-0.8,3,0,2.8C51.9,210.9,51.9,207.7,51.1,207.9z"/>
		<path class="st41" d="M50.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M52.1,208.5c-0.8-0.2-0.8,3,0,2.8C52.9,211.5,52.9,208.4,52.1,208.5z"/>
		<path class="st41" d="M51.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,207.9c-0.8-0.2-0.8,3,0,2.8C49.9,210.9,49.9,207.7,49.1,207.9z"/>
		<path class="st41" d="M48.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M50.1,208.5c-0.8-0.2-0.8,3,0,2.8C51,211.5,51,208.4,50.1,208.5z"/>
		<path class="st41" d="M49.8,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,207.9c-0.8-0.2-0.8,3,0,2.8C47.9,210.9,47.9,207.7,47.1,207.9z"/>
		<path class="st41" d="M46.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M48.1,208.5c-0.8-0.2-0.8,3,0,2.8C49,211.5,49,208.4,48.1,208.5z"/>
		<path class="st41" d="M47.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,207.9c-0.8-0.2-0.8,3,0,2.8C45.9,210.9,45.9,207.7,45.1,207.9z"/>
		<path class="st41" d="M44.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M46.2,208.5c-0.8-0.2-0.8,3,0,2.8C47,211.5,47,208.4,46.2,208.5z"/>
		<path class="st41" d="M45.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,207.9c-0.8-0.2-0.8,3,0,2.8C43.9,210.9,43.9,207.7,43.1,207.9z"/>
		<path class="st41" d="M42.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M44.2,208.5c-0.8-0.2-0.8,3,0,2.8C45,211.5,45,208.4,44.2,208.5z"/>
		<path class="st41" d="M43.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,207.9c-0.8-0.2-0.8,3,0,2.8C42,210.9,42,207.7,41.1,207.9z"/>
		<path class="st41" d="M40.8,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M42.2,208.5c-0.8-0.2-0.8,3,0,2.8C43,211.5,43,208.4,42.2,208.5z"/>
		<path class="st41" d="M41.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,207.9c-0.8-0.2-0.8,3,0,2.8C40,210.9,40,207.7,39.1,207.9z"/>
		<path class="st41" d="M38.9,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M40.2,208.5c-0.8-0.2-0.8,3,0,2.8C41,211.5,41,208.4,40.2,208.5z"/>
		<path class="st41" d="M39.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,207.9c-0.8-0.2-0.8,3,0,2.8C38,210.9,38,207.7,37.2,207.9z"/>
		<path class="st41" d="M36.9,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M38.2,208.5c-0.8-0.2-0.8,3,0,2.8C39,211.5,39,208.4,38.2,208.5z"/>
		<path class="st41" d="M37.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,207.9c-0.8-0.2-0.8,3,0,2.8C36,210.9,36,207.7,35.2,207.9z"/>
		<path class="st41" d="M34.9,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M36.2,208.5c-0.8-0.2-0.8,3,0,2.8C37,211.5,37,208.4,36.2,208.5z"/>
		<path class="st41" d="M35.9,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,207.9c-0.8-0.2-0.8,3,0,2.8C34.1,210.9,34.1,207.7,33.3,207.9z"/>
		<path class="st41" d="M33,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M34.3,208.5c-0.8-0.2-0.8,3,0,2.8C35.2,211.5,35.2,208.4,34.3,208.5z"/>
		<path class="st41" d="M34.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,207.9c-0.8-0.2-0.8,3,0,2.8C32.1,210.9,32.1,207.7,31.3,207.9z"/>
		<path class="st41" d="M31,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M32.4,208.5c-0.8-0.2-0.8,3,0,2.8C33.2,211.5,33.2,208.4,32.4,208.5z"/>
		<path class="st41" d="M32.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,207.9c-0.8-0.2-0.8,3,0,2.8C30.1,210.9,30.2,207.7,29.3,207.9z"/>
		<path class="st41" d="M29,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M30.4,208.5c-0.8-0.2-0.8,3,0,2.8C31.2,211.5,31.2,208.4,30.4,208.5z"/>
		<path class="st41" d="M30.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,207.9c-0.8-0.2-0.8,3,0,2.8C28.2,210.9,28.2,207.7,27.3,207.9z"/>
		<path class="st41" d="M27,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M28.4,208.5c-0.8-0.2-0.8,3,0,2.8C29.2,211.5,29.2,208.4,28.4,208.5z"/>
		<path class="st41" d="M28.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,207.9c-0.8-0.2-0.8,3,0,2.8C26.2,210.9,26.2,207.7,25.3,207.9z"/>
		<path class="st41" d="M25.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M26.4,208.5c-0.8-0.2-0.8,3,0,2.8C27.2,211.5,27.2,208.4,26.4,208.5z"/>
		<path class="st41" d="M26.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,207.9c-0.8-0.2-0.8,3,0,2.8C24.2,210.9,24.2,207.7,23.4,207.9z"/>
		<path class="st41" d="M23.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M24.4,208.5c-0.8-0.2-0.8,3,0,2.8C25.2,211.5,25.2,208.4,24.4,208.5z"/>
		<path class="st41" d="M24.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,207.9c-0.8-0.2-0.8,3,0,2.8C22.2,210.9,22.2,207.7,21.4,207.9z"/>
		<path class="st41" d="M21.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M22.4,208.5c-0.8-0.2-0.8,3,0,2.8C23.3,211.5,23.3,208.4,22.4,208.5z"/>
		<path class="st41" d="M22.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,207.9c-0.8-0.2-0.8,3,0,2.8C20.2,210.9,20.2,207.7,19.4,207.9z"/>
		<path class="st41" d="M19.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M20.4,208.5c-0.8-0.2-0.8,3,0,2.8C21.3,211.5,21.3,208.4,20.4,208.5z"/>
		<path class="st41" d="M20.1,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,207.9c-0.8-0.2-0.8,3,0,2.8C18.2,210.9,18.2,207.7,17.4,207.9z"/>
		<path class="st41" d="M17.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M18.4,208.5c-0.8-0.2-0.8,3,0,2.8C19.3,211.5,19.3,208.4,18.4,208.5z"/>
		<path class="st41" d="M18.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,207.9c-0.8-0.2-0.8,3,0,2.8C16.2,210.9,16.2,207.7,15.4,207.9z"/>
		<path class="st41" d="M15.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M16.5,208.5c-0.8-0.2-0.8,3,0,2.8C17.3,211.5,17.3,208.4,16.5,208.5z"/>
		<path class="st41" d="M16.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,207.9c-0.8-0.2-0.8,3,0,2.8C14.3,210.9,14.3,207.7,13.4,207.9z"/>
		<path class="st41" d="M13.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M14.5,208.5c-0.8-0.2-0.8,3,0,2.8C15.3,211.5,15.3,208.4,14.5,208.5z"/>
		<path class="st41" d="M14.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,207.9c-0.8-0.2-0.8,3,0,2.8C12.3,210.9,12.3,207.7,11.4,207.9z"/>
		<path class="st41" d="M11.1,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M12.5,208.5c-0.8-0.2-0.8,3,0,2.8C13.3,211.5,13.3,208.4,12.5,208.5z"/>
		<path class="st41" d="M12.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,207.9c-0.8-0.2-0.8,3,0,2.8C10.3,210.9,10.3,207.7,9.5,207.9z"/>
		<path class="st41" d="M9.2,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M10.5,208.5c-0.8-0.2-0.8,3,0,2.8C11.3,211.5,11.3,208.4,10.5,208.5z"/>
		<path class="st41" d="M10.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,207.9c-0.8-0.2-0.8,3,0,2.8C8.3,210.9,8.3,207.7,7.5,207.9z"/>
		<path class="st41" d="M7.2,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M8.5,208.5c-0.8-0.2-0.8,3,0,2.8C9.3,211.5,9.3,208.4,8.5,208.5z"/>
		<path class="st41" d="M8.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,207.9c-0.8-0.2-0.8,3,0,2.8C6.3,210.9,6.3,207.7,5.5,207.9z"/>
		<path class="st41" d="M5.2,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M6.5,208.5c-0.8-0.2-0.8,3,0,2.8C7.4,211.5,7.4,208.4,6.5,208.5z"/>
		<path class="st41" d="M6.2,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M3.5,207.9c-0.8-0.2-0.8,3,0,2.8C4.3,210.9,4.3,207.7,3.5,207.9z"/>
		<path class="st41" d="M3.2,208.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M4.5,208.5c-0.8-0.2-0.8,3,0,2.8C5.4,211.5,5.4,208.4,4.5,208.5z"/>
		<path class="st41" d="M4.3,209.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M65,209.2c-0.8-0.2-0.8,3,0,2.8C65.8,212.2,65.8,209,65,209.2z"/>
		<path class="st41" d="M64.7,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M63,209.2c-0.8-0.2-0.8,3,0,2.8C63.8,212.2,63.8,209,63,209.2z"/>
		<path class="st41" d="M62.7,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M61,209.2c-0.8-0.2-0.8,3,0,2.8C61.8,212.2,61.8,209,61,209.2z"/>
		<path class="st41" d="M60.7,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M59,209.2c-0.8-0.2-0.8,3,0,2.8C59.8,212.2,59.8,209,59,209.2z"/>
		<path class="st41" d="M58.7,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M57,209.2c-0.8-0.2-0.8,3,0,2.8C57.9,212.2,57.9,209,57,209.2z"/>
		<path class="st41" d="M56.7,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M55,209.2c-0.8-0.2-0.8,3,0,2.8C55.9,212.2,55.9,209,55,209.2z"/>
		<path class="st41" d="M54.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M53.1,209.2c-0.8-0.2-0.8,3,0,2.8C53.9,212.2,53.9,209,53.1,209.2z"/>
		<path class="st41" d="M52.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M51.1,209.2c-0.8-0.2-0.8,3,0,2.8C51.9,212.2,51.9,209,51.1,209.2z"/>
		<path class="st41" d="M50.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M49.1,209.2c-0.8-0.2-0.8,3,0,2.8C49.9,212.2,49.9,209,49.1,209.2z"/>
		<path class="st41" d="M48.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M47.1,209.2c-0.8-0.2-0.8,3,0,2.8C47.9,212.2,47.9,209,47.1,209.2z"/>
		<path class="st41" d="M46.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M45.1,209.2c-0.8-0.2-0.8,3,0,2.8C45.9,212.2,45.9,209,45.1,209.2z"/>
		<path class="st41" d="M44.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M43.1,209.2c-0.8-0.2-0.8,3,0,2.8C43.9,212.2,43.9,209,43.1,209.2z"/>
		<path class="st41" d="M42.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M41.1,209.2c-0.8-0.2-0.8,3,0,2.8C42,212.2,42,209,41.1,209.2z"/>
		<path class="st41" d="M40.8,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M39.1,209.2c-0.8-0.2-0.8,3,0,2.8C40,212.2,40,209,39.1,209.2z"/>
		<path class="st41" d="M38.9,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M37.2,209.2c-0.8-0.2-0.8,3,0,2.8C38,212.2,38,209,37.2,209.2z"/>
		<path class="st41" d="M36.9,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M35.2,209.2c-0.8-0.2-0.8,3,0,2.8C36,212.2,36,209,35.2,209.2z"/>
		<path class="st41" d="M34.9,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M33.3,209.2c-0.8-0.2-0.8,3,0,2.8C34.1,212.2,34.1,209,33.3,209.2z"/>
		<path class="st41" d="M33,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M31.3,209.2c-0.8-0.2-0.8,3,0,2.8C32.1,212.2,32.1,209,31.3,209.2z"/>
		<path class="st41" d="M31,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M29.3,209.2c-0.8-0.2-0.8,3,0,2.8C30.1,212.2,30.2,209,29.3,209.2z"/>
		<path class="st41" d="M29,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M27.3,209.2c-0.8-0.2-0.8,3,0,2.8C28.2,212.2,28.2,209,27.3,209.2z"/>
		<path class="st41" d="M27,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M25.3,209.2c-0.8-0.2-0.8,3,0,2.8C26.2,212.2,26.2,209,25.3,209.2z"/>
		<path class="st41" d="M25.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M23.4,209.2c-0.8-0.2-0.8,3,0,2.8C24.2,212.2,24.2,209,23.4,209.2z"/>
		<path class="st41" d="M23.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M21.4,209.2c-0.8-0.2-0.8,3,0,2.8C22.2,212.2,22.2,209,21.4,209.2z"/>
		<path class="st41" d="M21.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M19.4,209.2c-0.8-0.2-0.8,3,0,2.8C20.2,212.2,20.2,209,19.4,209.2z"/>
		<path class="st41" d="M19.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M17.4,209.2c-0.8-0.2-0.8,3,0,2.8C18.2,212.2,18.2,209,17.4,209.2z"/>
		<path class="st41" d="M17.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M15.4,209.2c-0.8-0.2-0.8,3,0,2.8C16.2,212.2,16.2,209,15.4,209.2z"/>
		<path class="st41" d="M15.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M13.4,209.2c-0.8-0.2-0.8,3,0,2.8C14.3,212.2,14.3,209,13.4,209.2z"/>
		<path class="st41" d="M13.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M11.4,209.2c-0.8-0.2-0.8,3,0,2.8C12.3,212.2,12.3,209,11.4,209.2z"/>
		<path class="st41" d="M11.1,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M9.5,209.2c-0.8-0.2-0.8,3,0,2.8C10.3,212.2,10.3,209,9.5,209.2z"/>
		<path class="st41" d="M9.2,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M7.5,209.2c-0.8-0.2-0.8,3,0,2.8C8.3,212.2,8.3,209,7.5,209.2z"/>
		<path class="st41" d="M7.2,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st40" d="M5.5,209.2c-0.8-0.2-0.8,3,0,2.8C6.3,212.2,6.3,209,5.5,209.2z"/>
		<path class="st41" d="M5.2,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st42" d="M3.5,209.2c-0.8-0.2-0.8,3,0,2.8C4.3,212.2,4.3,209,3.5,209.2z"/>
		<path class="st42" d="M3.2,210.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<text class="st48" x="2.5705597" y="5.6745005" style={{fontSize:"2.2px"}}>서울 44석 / 586석</text>
<text class="st48" x="2.5705597" y="31.3989792" style={{fontSize:"2.2px"}}>경기 21석 / 594석</text>
<text class="st48" x="2.5705597" y="56.782402" style={{fontSize:"2.2px"}}>전북 5석 / 295석</text>
<text class="st48" x="2.5705597" y="75.5752487" style={{fontSize:"2.2px"}}>경북 4석 / 466석</text>
<text class="st48" x="2.5705597" y="98.0353088" style={{fontSize:"2.2px"}}>대구 4석 / 97석</text>
<text class="st48" x="2.5705597" y="112.0728607" style={{fontSize:"2.2px"}}>광주 3석 / 54석</text>
<text class="st48" x="2.5705597" y="124.6872406" style={{fontSize:"2.2px"}}>경남 2석 / 449석</text>
<text class="st48" x="2.5705597" y="146.8738098" style={{fontSize:"2.2px"}}>충남 2석 / 299석</text>
<text class="st48" x="2.5705597" y="165.5572815" style={{fontSize:"2.2px"}}>부산 2석 / 205석</text>
<text class="st48" x="2.5705597" y="181.2467194" style={{fontSize:"2.2px"}}>전남 1석 / 410석</text>
<text class="st48" x="2.5705597" y="201.912735" style={{fontSize:"2.2px"}}>강원 1석 / 229석</text>
<text class="st48" x="2.5705597" y="218.5194397" style={{fontSize:"2.2px"}}>충북 없음 / 183석</text>
<text class="st48" x="2.5705597" y="233.9203949" style={{fontSize:"2.2px"}}>인천 없음 / 89석</text>
<text class="st48" x="2.5705597" y="246.5347595" style={{fontSize:"2.2px"}}>제주 없음 / 53석</text>
<text class="st48" x="2.5705597" y="259.9025879" style={{fontSize:"2.2px"}}>대전 없음 / 43석</text>
<text class="st48" x="2.5705597" y="272.5169678" style={{fontSize:"2.2px"}}>울산 없음 / 29석</text>
<text class="st48" x="2.5705597" y="284.4616089" style={{fontSize:"2.2px"}}>세종 없음 / 2석</text>
</svg>
</div>
<div class="graph_desktop">
<svg version="1.1" id="레이어_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 136.2 297">
<g>
	<g>
		<path class="st0" d="M53.1,183.4c-0.8-0.2-0.8,3,0,2.8C53.9,186.4,53.9,183.2,53.1,183.4z"/>
		<path class="st1" d="M52.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,183.4c-0.8-0.2-0.8,3,0,2.8C51.9,186.4,51.9,183.2,51.1,183.4z"/>
		<path class="st1" d="M50.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,183.4c-0.8-0.2-0.8,3,0,2.8C49.9,186.4,49.9,183.2,49.1,183.4z"/>
		<path class="st1" d="M48.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,183.4c-0.8-0.2-0.8,3,0,2.8C47.9,186.4,47.9,183.2,47.1,183.4z"/>
		<path class="st1" d="M46.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,183.4c-0.8-0.2-0.8,3,0,2.8C45.9,186.4,45.9,183.2,45.1,183.4z"/>
		<path class="st1" d="M44.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,183.4c-0.8-0.2-0.8,3,0,2.8C43.9,186.4,43.9,183.2,43.1,183.4z"/>
		<path class="st1" d="M42.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,183.4c-0.8-0.2-0.8,3,0,2.8C42,186.4,42,183.2,41.1,183.4z"/>
		<path class="st1" d="M40.8,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,183.4c-0.8-0.2-0.8,3,0,2.8C40,186.4,40,183.2,39.1,183.4z"/>
		<path class="st1" d="M38.9,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,183.4c-0.8-0.2-0.8,3,0,2.8C38,186.4,38,183.2,37.2,183.4z"/>
		<path class="st1" d="M36.9,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,183.4c-0.8-0.2-0.8,3,0,2.8C36,186.4,36,183.2,35.2,183.4z"/>
		<path class="st1" d="M34.9,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,183.4c-0.8-0.2-0.8,3,0,2.8C34.1,186.4,34.1,183.2,33.3,183.4z"/>
		<path class="st1" d="M33,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,183.4c-0.8-0.2-0.8,3,0,2.8C32.1,186.4,32.1,183.2,31.3,183.4z"/>
		<path class="st1" d="M31,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,183.4c-0.8-0.2-0.8,3,0,2.8C30.1,186.4,30.2,183.2,29.3,183.4z"/>
		<path class="st1" d="M29,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,183.4c-0.8-0.2-0.8,3,0,2.8C28.2,186.4,28.2,183.2,27.3,183.4z"/>
		<path class="st1" d="M27,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,183.4c-0.8-0.2-0.8,3,0,2.8C26.2,186.4,26.2,183.2,25.3,183.4z"/>
		<path class="st1" d="M25.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,183.4c-0.8-0.2-0.8,3,0,2.8C24.2,186.4,24.2,183.2,23.4,183.4z"/>
		<path class="st1" d="M23.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,183.4c-0.8-0.2-0.8,3,0,2.8C22.2,186.4,22.2,183.2,21.4,183.4z"/>
		<path class="st1" d="M21.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,183.4c-0.8-0.2-0.8,3,0,2.8C20.2,186.4,20.2,183.2,19.4,183.4z"/>
		<path class="st1" d="M19.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,183.4c-0.8-0.2-0.8,3,0,2.8C18.2,186.4,18.2,183.2,17.4,183.4z"/>
		<path class="st1" d="M17.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,183.4c-0.8-0.2-0.8,3,0,2.8C16.2,186.4,16.2,183.2,15.4,183.4z"/>
		<path class="st1" d="M15.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,183.4c-0.8-0.2-0.8,3,0,2.8C14.3,186.4,14.3,183.2,13.4,183.4z"/>
		<path class="st1" d="M13.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,183.4c-0.8-0.2-0.8,3,0,2.8C12.3,186.4,12.3,183.2,11.4,183.4z"/>
		<path class="st1" d="M11.1,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,183.4c-0.8-0.2-0.8,3,0,2.8C10.3,186.4,10.3,183.2,9.5,183.4z"/>
		<path class="st1" d="M9.2,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,183.4c-0.8-0.2-0.8,3,0,2.8C8.3,186.4,8.3,183.2,7.5,183.4z"/>
		<path class="st1" d="M7.2,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,183.4c-0.8-0.2-0.8,3,0,2.8C6.3,186.4,6.3,183.2,5.5,183.4z"/>
		<path class="st1" d="M5.2,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,183.4c-0.8-0.2-0.8,3,0,2.8C4.3,186.4,4.3,183.2,3.5,183.4z"/>
		<path class="st1" d="M3.2,184.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,184.1c-0.8-0.2-0.8,3,0,2.8C66.7,187.1,66.7,184,65.9,184.1z"/>
		<path class="st1" d="M65.6,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,184.1c-0.8-0.2-0.8,3,0,2.8C64.7,187.1,64.7,184,63.9,184.1z"/>
		<path class="st1" d="M63.6,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,184.1c-0.8-0.2-0.8,3,0,2.8C62.7,187.1,62.8,184,61.9,184.1z"/>
		<path class="st1" d="M61.6,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,184.1c-0.8-0.2-0.8,3,0,2.8C60.8,187.1,60.8,184,59.9,184.1z"/>
		<path class="st1" d="M59.6,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,184.1c-0.8-0.2-0.8,3,0,2.8C58.8,187.1,58.8,184,57.9,184.1z"/>
		<path class="st1" d="M57.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,184.1c-0.8-0.2-0.8,3,0,2.8C56.8,187.1,56.8,184,56,184.1z"/>
		<path class="st1" d="M55.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,184.1c-0.8-0.2-0.8,3,0,2.8C54.8,187.1,54.8,184,54,184.1z"/>
		<path class="st1" d="M53.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,184.1c-0.8-0.2-0.8,3,0,2.8C52.8,187.1,52.8,184,52,184.1z"/>
		<path class="st1" d="M51.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,184.1c-0.8-0.2-0.8,3,0,2.8C50.8,187.1,50.8,184,50,184.1z"/>
		<path class="st1" d="M49.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,184.1c-0.8-0.2-0.8,3,0,2.8C48.8,187.1,48.8,184,48,184.1z"/>
		<path class="st1" d="M47.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,184.1c-0.8-0.2-0.8,3,0,2.8C46.9,187.1,46.9,184,46,184.1z"/>
		<path class="st1" d="M45.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,184.1c-0.8-0.2-0.8,3,0,2.8C44.9,187.1,44.9,184,44,184.1z"/>
		<path class="st1" d="M43.7,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,184.1c-0.8-0.2-0.8,3,0,2.8C42.9,187.1,42.9,184,42.1,184.1z"/>
		<path class="st1" d="M41.8,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,184.1c-0.8-0.2-0.8,3,0,2.8C40.9,187.1,40.9,184,40.1,184.1z"/>
		<path class="st1" d="M39.8,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,184.1c-0.8-0.2-0.8,3,0,2.8C38.9,187.1,38.9,184,38.1,184.1z"/>
		<path class="st1" d="M37.8,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,184.1c-0.8-0.2-0.8,3,0,2.8C36.9,187.1,36.9,184,36.1,184.1z"/>
		<path class="st1" d="M35.8,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,184.1c-0.8-0.2-0.8,3,0,2.8C35,187.1,35,184,34.2,184.1z"/>
		<path class="st1" d="M33.9,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,184.1c-0.8-0.2-0.8,3,0,2.8C33.1,187.1,33.1,184,32.2,184.1z"/>
		<path class="st1" d="M31.9,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,184.1c-0.8-0.2-0.8,3,0,2.8C31.1,187.1,31.1,184,30.2,184.1z"/>
		<path class="st1" d="M30,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,184.1c-0.8-0.2-0.8,3,0,2.8C29.1,187.1,29.1,184,28.3,184.1z"/>
		<path class="st1" d="M28,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,184.1c-0.8-0.2-0.8,3,0,2.8C27.1,187.1,27.1,184,26.3,184.1z"/>
		<path class="st1" d="M26,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,184.1c-0.8-0.2-0.8,3,0,2.8C25.1,187.1,25.1,184,24.3,184.1z"/>
		<path class="st1" d="M24,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,184.1c-0.8-0.2-0.8,3,0,2.8C23.1,187.1,23.1,184,22.3,184.1z"/>
		<path class="st1" d="M22,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,184.1c-0.8-0.2-0.8,3,0,2.8C21.1,187.1,21.1,184,20.3,184.1z"/>
		<path class="st1" d="M20,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,184.1c-0.8-0.2-0.8,3,0,2.8C19.1,187.1,19.1,184,18.3,184.1z"/>
		<path class="st1" d="M18,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,184.1c-0.8-0.2-0.8,3,0,2.8C17.2,187.1,17.2,184,16.3,184.1z"/>
		<path class="st1" d="M16,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,184.1c-0.8-0.2-0.8,3,0,2.8C15.2,187.1,15.2,184,14.3,184.1z"/>
		<path class="st1" d="M14.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,184.1c-0.8-0.2-0.8,3,0,2.8C13.2,187.1,13.2,184,12.4,184.1z"/>
		<path class="st1" d="M12.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,184.1c-0.8-0.2-0.8,3,0,2.8C11.2,187.1,11.2,184,10.4,184.1z"/>
		<path class="st1" d="M10.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,184.1c-0.8-0.2-0.8,3,0,2.8C9.2,187.1,9.2,184,8.4,184.1z"/>
		<path class="st1" d="M8.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,184.1c-0.8-0.2-0.8,3,0,2.8C7.2,187.1,7.2,184,6.4,184.1z"/>
		<path class="st1" d="M6.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,184.1c-0.8-0.2-0.8,3,0,2.8C5.2,187.1,5.2,184,4.4,184.1z"/>
		<path class="st1" d="M4.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,184.1c-0.8-0.2-0.8,3,0,2.8C130.1,187.1,130.1,184,129.3,184.1z"/>
		<path class="st1" d="M129,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,184.1c-0.8-0.2-0.8,3,0,2.8C128.2,187.1,128.2,184,127.3,184.1z"/>
		<path class="st1" d="M127,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,184.1c-0.8-0.2-0.8,3,0,2.8C126.2,187.1,126.2,184,125.3,184.1z"/>
		<path class="st1" d="M125.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,184.1c-0.8-0.2-0.8,3,0,2.8C124.2,187.1,124.2,184,123.4,184.1z"/>
		<path class="st1" d="M123.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,184.1c-0.8-0.2-0.8,3,0,2.8C122.2,187.1,122.2,184,121.4,184.1z"/>
		<path class="st1" d="M121.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,184.1c-0.8-0.2-0.8,3,0,2.8C120.2,187.1,120.2,184,119.4,184.1z"/>
		<path class="st1" d="M119.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,184.1c-0.8-0.2-0.8,3,0,2.8C118.2,187.1,118.2,184,117.4,184.1z"/>
		<path class="st1" d="M117.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,184.1c-0.8-0.2-0.8,3,0,2.8C116.2,187.1,116.2,184,115.4,184.1z"/>
		<path class="st1" d="M115.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,184.1c-0.8-0.2-0.8,3,0,2.8C114.3,187.1,114.3,184,113.4,184.1z"/>
		<path class="st1" d="M113.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,184.1c-0.8-0.2-0.8,3,0,2.8C112.3,187.1,112.3,184,111.4,184.1z"/>
		<path class="st1" d="M111.1,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,184.1c-0.8-0.2-0.8,3,0,2.8C110.3,187.1,110.3,184,109.4,184.1z"/>
		<path class="st1" d="M109.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,184.1c-0.8-0.2-0.8,3,0,2.8C108.3,187.1,108.3,184,107.5,184.1z"/>
		<path class="st1" d="M107.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,184.1c-0.8-0.2-0.8,3,0,2.8C106.3,187.1,106.3,184,105.5,184.1z"/>
		<path class="st1" d="M105.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,184.1c-0.8-0.2-0.8,3,0,2.8C104.3,187.1,104.3,184,103.5,184.1z"/>
		<path class="st1" d="M103.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,184.1c-0.8-0.2-0.8,3,0,2.8C102.3,187.1,102.3,184,101.5,184.1z"/>
		<path class="st1" d="M101.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,184.1c-0.8-0.2-0.8,3,0,2.8C100.3,187.1,100.3,184,99.5,184.1z"/>
		<path class="st1" d="M99.2,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,184.1c-0.8-0.2-0.8,3,0,2.8C98.5,187.1,98.5,184,97.6,184.1z"/>
		<path class="st1" d="M97.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,184.1c-0.8-0.2-0.8,3,0,2.8C96.5,187.1,96.5,184,95.7,184.1z"/>
		<path class="st1" d="M95.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,184.1c-0.8-0.2-0.8,3,0,2.8C94.5,187.1,94.5,184,93.7,184.1z"/>
		<path class="st1" d="M93.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,184.1c-0.8-0.2-0.8,3,0,2.8C92.5,187.1,92.5,184,91.7,184.1z"/>
		<path class="st1" d="M91.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,184.1c-0.8-0.2-0.8,3,0,2.8C90.5,187.1,90.5,184,89.7,184.1z"/>
		<path class="st1" d="M89.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,184.1c-0.8-0.2-0.8,3,0,2.8C88.5,187.1,88.5,184,87.7,184.1z"/>
		<path class="st1" d="M87.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,184.1c-0.8-0.2-0.8,3,0,2.8C86.5,187.1,86.5,184,85.7,184.1z"/>
		<path class="st1" d="M85.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,184.1c-0.8-0.2-0.8,3,0,2.8C84.6,187.1,84.6,184,83.7,184.1z"/>
		<path class="st1" d="M83.4,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,184.1c-0.8-0.2-0.8,3,0,2.8C82.6,187.1,82.6,184,81.7,184.1z"/>
		<path class="st1" d="M81.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,184.1c-0.8-0.2-0.8,3,0,2.8C80.6,187.1,80.6,184,79.8,184.1z"/>
		<path class="st1" d="M79.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,184.1c-0.8-0.2-0.8,3,0,2.8C78.6,187.1,78.6,184,77.8,184.1z"/>
		<path class="st1" d="M77.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,184.1c-0.8-0.2-0.8,3,0,2.8C76.6,187.1,76.6,184,75.8,184.1z"/>
		<path class="st1" d="M75.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,184.1c-0.8-0.2-0.8,3,0,2.8C74.6,187.1,74.6,184,73.8,184.1z"/>
		<path class="st1" d="M73.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,184.1c-0.8-0.2-0.8,3,0,2.8C72.6,187.1,72.6,184,71.8,184.1z"/>
		<path class="st1" d="M71.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,184.1c-0.8-0.2-0.8,3,0,2.8C70.7,187.1,70.7,184,69.8,184.1z"/>
		<path class="st1" d="M69.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,184.1c-0.8-0.2-0.8,3,0,2.8C68.7,187.1,68.7,184,67.8,184.1z"/>
		<path class="st1" d="M67.5,185c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,185.1c-0.8-0.2-0.8,3,0,2.8C65.8,188.1,65.8,184.9,65,185.1z"/>
		<path class="st1" d="M64.7,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,185.1c-0.8-0.2-0.8,3,0,2.8C63.8,188.1,63.8,184.9,63,185.1z"/>
		<path class="st1" d="M62.7,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,185.1c-0.8-0.2-0.8,3,0,2.8C61.8,188.1,61.8,184.9,61,185.1z"/>
		<path class="st1" d="M60.7,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,185.1c-0.8-0.2-0.8,3,0,2.8C59.8,188.1,59.8,184.9,59,185.1z"/>
		<path class="st1" d="M58.7,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,185.1c-0.8-0.2-0.8,3,0,2.8C57.9,188.1,57.9,184.9,57,185.1z"/>
		<path class="st1" d="M56.7,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,185.1c-0.8-0.2-0.8,3,0,2.8C55.9,188.1,55.9,184.9,55,185.1z"/>
		<path class="st1" d="M54.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,185.1c-0.8-0.2-0.8,3,0,2.8C53.9,188.1,53.9,184.9,53.1,185.1z"/>
		<path class="st1" d="M52.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,185.1c-0.8-0.2-0.8,3,0,2.8C51.9,188.1,51.9,184.9,51.1,185.1z"/>
		<path class="st1" d="M50.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,185.1c-0.8-0.2-0.8,3,0,2.8C49.9,188.1,49.9,184.9,49.1,185.1z"/>
		<path class="st1" d="M48.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,185.1c-0.8-0.2-0.8,3,0,2.8C47.9,188.1,47.9,184.9,47.1,185.1z"/>
		<path class="st1" d="M46.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,185.1c-0.8-0.2-0.8,3,0,2.8C45.9,188.1,45.9,184.9,45.1,185.1z"/>
		<path class="st1" d="M44.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,185.1c-0.8-0.2-0.8,3,0,2.8C43.9,188.1,43.9,184.9,43.1,185.1z"/>
		<path class="st1" d="M42.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,185.1c-0.8-0.2-0.8,3,0,2.8C42,188.1,42,184.9,41.1,185.1z"/>
		<path class="st1" d="M40.8,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,185.1c-0.8-0.2-0.8,3,0,2.8C40,188.1,40,184.9,39.1,185.1z"/>
		<path class="st1" d="M38.9,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,185.1c-0.8-0.2-0.8,3,0,2.8C38,188.1,38,184.9,37.2,185.1z"/>
		<path class="st1" d="M36.9,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,185.1c-0.8-0.2-0.8,3,0,2.8C36,188.1,36,184.9,35.2,185.1z"/>
		<path class="st1" d="M34.9,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,185.1c-0.8-0.2-0.8,3,0,2.8C34.1,188.1,34.1,184.9,33.3,185.1z"/>
		<path class="st1" d="M33,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,185.1c-0.8-0.2-0.8,3,0,2.8C32.1,188.1,32.1,184.9,31.3,185.1z"/>
		<path class="st1" d="M31,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,185.1c-0.8-0.2-0.8,3,0,2.8C30.1,188.1,30.2,184.9,29.3,185.1z"/>
		<path class="st1" d="M29,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,185.1c-0.8-0.2-0.8,3,0,2.8C28.2,188.1,28.2,184.9,27.3,185.1z"/>
		<path class="st1" d="M27,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,185.1c-0.8-0.2-0.8,3,0,2.8C26.2,188.1,26.2,184.9,25.3,185.1z"/>
		<path class="st1" d="M25.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,185.1c-0.8-0.2-0.8,3,0,2.8C24.2,188.1,24.2,184.9,23.4,185.1z"/>
		<path class="st1" d="M23.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,185.1c-0.8-0.2-0.8,3,0,2.8C22.2,188.1,22.2,184.9,21.4,185.1z"/>
		<path class="st1" d="M21.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,185.1c-0.8-0.2-0.8,3,0,2.8C20.2,188.1,20.2,184.9,19.4,185.1z"/>
		<path class="st1" d="M19.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,185.1c-0.8-0.2-0.8,3,0,2.8C18.2,188.1,18.2,184.9,17.4,185.1z"/>
		<path class="st1" d="M17.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,185.1c-0.8-0.2-0.8,3,0,2.8C16.2,188.1,16.2,184.9,15.4,185.1z"/>
		<path class="st1" d="M15.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,185.1c-0.8-0.2-0.8,3,0,2.8C14.3,188.1,14.3,184.9,13.4,185.1z"/>
		<path class="st1" d="M13.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,185.1c-0.8-0.2-0.8,3,0,2.8C12.3,188.1,12.3,184.9,11.4,185.1z"/>
		<path class="st1" d="M11.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,185.1c-0.8-0.2-0.8,3,0,2.8C10.3,188.1,10.3,184.9,9.5,185.1z"/>
		<path class="st1" d="M9.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,185.1c-0.8-0.2-0.8,3,0,2.8C8.3,188.1,8.3,184.9,7.5,185.1z"/>
		<path class="st1" d="M7.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,185.1c-0.8-0.2-0.8,3,0,2.8C6.3,188.1,6.3,184.9,5.5,185.1z"/>
		<path class="st1" d="M5.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,185.1c-0.8-0.2-0.8,3,0,2.8C4.3,188.1,4.3,184.9,3.5,185.1z"/>
		<path class="st1" d="M3.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,185.1c-0.8-0.2-0.8,3,0,2.8C129.2,188.1,129.2,184.9,128.4,185.1z"/>
		<path class="st1" d="M128.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,185.1c-0.8-0.2-0.8,3,0,2.8C127.2,188.1,127.2,184.9,126.4,185.1z"/>
		<path class="st1" d="M126.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,185.1c-0.8-0.2-0.8,3,0,2.8C125.3,188.1,125.3,184.9,124.4,185.1z"/>
		<path class="st1" d="M124.1,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,185.1c-0.8-0.2-0.8,3,0,2.8C123.3,188.1,123.3,184.9,122.4,185.1z"/>
		<path class="st1" d="M122.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,185.1c-0.8-0.2-0.8,3,0,2.8C121.3,188.1,121.3,184.9,120.5,185.1z"/>
		<path class="st1" d="M120.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,185.1c-0.8-0.2-0.8,3,0,2.8C119.3,188.1,119.3,184.9,118.5,185.1z"/>
		<path class="st1" d="M118.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,185.1c-0.8-0.2-0.8,3,0,2.8C117.3,188.1,117.3,184.9,116.5,185.1z"/>
		<path class="st1" d="M116.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,185.1c-0.8-0.2-0.8,3,0,2.8C115.3,188.1,115.3,184.9,114.5,185.1z"/>
		<path class="st1" d="M114.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,185.1c-0.8-0.2-0.8,3,0,2.8C113.3,188.1,113.3,184.9,112.5,185.1z"/>
		<path class="st1" d="M112.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,185.1c-0.8-0.2-0.8,3,0,2.8C111.3,188.1,111.3,184.9,110.5,185.1z"/>
		<path class="st1" d="M110.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,185.1c-0.8-0.2-0.8,3,0,2.8C109.4,188.1,109.4,184.9,108.5,185.1z"/>
		<path class="st1" d="M108.2,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,185.1c-0.8-0.2-0.8,3,0,2.8C107.4,188.1,107.4,184.9,106.5,185.1z"/>
		<path class="st1" d="M106.3,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,185.1c-0.8-0.2-0.8,3,0,2.8C105.4,188.1,105.4,184.9,104.6,185.1z"/>
		<path class="st1" d="M104.3,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,185.1c-0.8-0.2-0.8,3,0,2.8C103.4,188.1,103.4,184.9,102.6,185.1z"/>
		<path class="st1" d="M102.3,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,185.1c-0.8-0.2-0.8,3,0,2.8C101.4,188.1,101.4,184.9,100.6,185.1z"/>
		<path class="st1" d="M100.3,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,185.1c-0.8-0.2-0.8,3,0,2.8C99.4,188.1,99.4,184.9,98.6,185.1z"/>
		<path class="st1" d="M98.3,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,185.1c-0.8-0.2-0.8,3,0,2.8C97.5,188.1,97.6,184.9,96.7,185.1z"/>
		<path class="st1" d="M96.4,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,185.1c-0.8-0.2-0.8,3,0,2.8C95.6,188.1,95.6,184.9,94.7,185.1z"/>
		<path class="st1" d="M94.4,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,185.1c-0.8-0.2-0.8,3,0,2.8C93.6,188.1,93.6,184.9,92.7,185.1z"/>
		<path class="st1" d="M92.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,185.1c-0.8-0.2-0.8,3,0,2.8C91.6,188.1,91.6,184.9,90.8,185.1z"/>
		<path class="st1" d="M90.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,185.1c-0.8-0.2-0.8,3,0,2.8C89.6,188.1,89.6,184.9,88.8,185.1z"/>
		<path class="st1" d="M88.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,185.1c-0.8-0.2-0.8,3,0,2.8C87.6,188.1,87.6,184.9,86.8,185.1z"/>
		<path class="st1" d="M86.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,185.1c-0.8-0.2-0.8,3,0,2.8C85.6,188.1,85.6,184.9,84.8,185.1z"/>
		<path class="st1" d="M84.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,185.1c-0.8-0.2-0.8,3,0,2.8C83.6,188.1,83.6,184.9,82.8,185.1z"/>
		<path class="st1" d="M82.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,185.1c-0.8-0.2-0.8,3,0,2.8C81.7,188.1,81.7,184.9,80.8,185.1z"/>
		<path class="st1" d="M80.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,185.1c-0.8-0.2-0.8,3,0,2.8C79.7,188.1,79.7,184.9,78.8,185.1z"/>
		<path class="st1" d="M78.5,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,185.1c-0.8-0.2-0.8,3,0,2.8C77.7,188.1,77.7,184.9,76.9,185.1z"/>
		<path class="st1" d="M76.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,185.1c-0.8-0.2-0.8,3,0,2.8C75.7,188.1,75.7,184.9,74.9,185.1z"/>
		<path class="st1" d="M74.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,185.1c-0.8-0.2-0.8,3,0,2.8C73.7,188.1,73.7,184.9,72.9,185.1z"/>
		<path class="st1" d="M72.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,185.1c-0.8-0.2-0.8,3,0,2.8C71.7,188.1,71.7,184.9,70.9,185.1z"/>
		<path class="st1" d="M70.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,185.1c-0.8-0.2-0.8,3,0,2.8C69.7,188.1,69.7,184.9,68.9,185.1z"/>
		<path class="st1" d="M68.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,185.1c-0.8-0.2-0.8,3,0,2.8C67.7,188.1,67.7,184.9,66.9,185.1z"/>
		<path class="st1" d="M66.6,185.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,185.8c-0.8-0.2-0.8,3,0,2.8C66.7,188.8,66.7,185.7,65.9,185.8z"/>
		<path class="st1" d="M65.6,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,185.8c-0.8-0.2-0.8,3,0,2.8C64.7,188.8,64.7,185.7,63.9,185.8z"/>
		<path class="st1" d="M63.6,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,185.8c-0.8-0.2-0.8,3,0,2.8C62.7,188.8,62.8,185.7,61.9,185.8z"/>
		<path class="st1" d="M61.6,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,185.8c-0.8-0.2-0.8,3,0,2.8C60.8,188.8,60.8,185.7,59.9,185.8z"/>
		<path class="st1" d="M59.6,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,185.8c-0.8-0.2-0.8,3,0,2.8C58.8,188.8,58.8,185.7,57.9,185.8z"/>
		<path class="st1" d="M57.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,185.8c-0.8-0.2-0.8,3,0,2.8C56.8,188.8,56.8,185.7,56,185.8z"/>
		<path class="st1" d="M55.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,185.8c-0.8-0.2-0.8,3,0,2.8C54.8,188.8,54.8,185.7,54,185.8z"/>
		<path class="st1" d="M53.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,185.8c-0.8-0.2-0.8,3,0,2.8C52.8,188.8,52.8,185.7,52,185.8z"/>
		<path class="st1" d="M51.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,185.8c-0.8-0.2-0.8,3,0,2.8C50.8,188.8,50.8,185.7,50,185.8z"/>
		<path class="st1" d="M49.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,185.8c-0.8-0.2-0.8,3,0,2.8C48.8,188.8,48.8,185.7,48,185.8z"/>
		<path class="st1" d="M47.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,185.8c-0.8-0.2-0.8,3,0,2.8C46.9,188.8,46.9,185.7,46,185.8z"/>
		<path class="st1" d="M45.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,185.8c-0.8-0.2-0.8,3,0,2.8C44.9,188.8,44.9,185.7,44,185.8z"/>
		<path class="st1" d="M43.7,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,185.8c-0.8-0.2-0.8,3,0,2.8C42.9,188.8,42.9,185.7,42.1,185.8z"/>
		<path class="st1" d="M41.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,185.8c-0.8-0.2-0.8,3,0,2.8C40.9,188.8,40.9,185.7,40.1,185.8z"/>
		<path class="st1" d="M39.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,185.8c-0.8-0.2-0.8,3,0,2.8C38.9,188.8,38.9,185.7,38.1,185.8z"/>
		<path class="st1" d="M37.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,185.8c-0.8-0.2-0.8,3,0,2.8C36.9,188.8,36.9,185.7,36.1,185.8z"/>
		<path class="st1" d="M35.8,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,185.8c-0.8-0.2-0.8,3,0,2.8C35,188.8,35,185.7,34.2,185.8z"/>
		<path class="st1" d="M33.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,185.8c-0.8-0.2-0.8,3,0,2.8C33.1,188.8,33.1,185.7,32.2,185.8z"/>
		<path class="st1" d="M31.9,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,185.8c-0.8-0.2-0.8,3,0,2.8C31.1,188.8,31.1,185.7,30.2,185.8z"/>
		<path class="st1" d="M30,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,185.8c-0.8-0.2-0.8,3,0,2.8C29.1,188.8,29.1,185.7,28.3,185.8z"/>
		<path class="st1" d="M28,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,185.8c-0.8-0.2-0.8,3,0,2.8C27.1,188.8,27.1,185.7,26.3,185.8z"/>
		<path class="st1" d="M26,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,185.8c-0.8-0.2-0.8,3,0,2.8C25.1,188.8,25.1,185.7,24.3,185.8z"/>
		<path class="st1" d="M24,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,185.8c-0.8-0.2-0.8,3,0,2.8C23.1,188.8,23.1,185.7,22.3,185.8z"/>
		<path class="st1" d="M22,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,185.8c-0.8-0.2-0.8,3,0,2.8C21.1,188.8,21.1,185.7,20.3,185.8z"/>
		<path class="st1" d="M20,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,185.8c-0.8-0.2-0.8,3,0,2.8C19.1,188.8,19.1,185.7,18.3,185.8z"/>
		<path class="st1" d="M18,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,185.8c-0.8-0.2-0.8,3,0,2.8C17.2,188.8,17.2,185.7,16.3,185.8z"/>
		<path class="st1" d="M16,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,185.8c-0.8-0.2-0.8,3,0,2.8C15.2,188.8,15.2,185.7,14.3,185.8z"/>
		<path class="st1" d="M14.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,185.8c-0.8-0.2-0.8,3,0,2.8C13.2,188.8,13.2,185.7,12.4,185.8z"/>
		<path class="st1" d="M12.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,185.8c-0.8-0.2-0.8,3,0,2.8C11.2,188.8,11.2,185.7,10.4,185.8z"/>
		<path class="st1" d="M10.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,185.8c-0.8-0.2-0.8,3,0,2.8C9.2,188.8,9.2,185.7,8.4,185.8z"/>
		<path class="st1" d="M8.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,185.8c-0.8-0.2-0.8,3,0,2.8C7.2,188.8,7.2,185.7,6.4,185.8z"/>
		<path class="st1" d="M6.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,185.8c-0.8-0.2-0.8,3,0,2.8C5.2,188.8,5.2,185.7,4.4,185.8z"/>
		<path class="st1" d="M4.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,185.8c-0.8-0.2-0.8,3,0,2.8C130.1,188.8,130.1,185.7,129.3,185.8z"/>
		<path class="st1" d="M129,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,185.8c-0.8-0.2-0.8,3,0,2.8C128.2,188.8,128.2,185.7,127.3,185.8z"/>
		<path class="st1" d="M127,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,185.8c-0.8-0.2-0.8,3,0,2.8C126.2,188.8,126.2,185.7,125.3,185.8z"/>
		<path class="st1" d="M125.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,185.8c-0.8-0.2-0.8,3,0,2.8C124.2,188.8,124.2,185.7,123.4,185.8z"/>
		<path class="st1" d="M123.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,185.8c-0.8-0.2-0.8,3,0,2.8C122.2,188.8,122.2,185.7,121.4,185.8z"/>
		<path class="st1" d="M121.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,185.8c-0.8-0.2-0.8,3,0,2.8C120.2,188.8,120.2,185.7,119.4,185.8z"/>
		<path class="st1" d="M119.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,185.8c-0.8-0.2-0.8,3,0,2.8C118.2,188.8,118.2,185.7,117.4,185.8z"/>
		<path class="st1" d="M117.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,185.8c-0.8-0.2-0.8,3,0,2.8C116.2,188.8,116.2,185.7,115.4,185.8z"/>
		<path class="st1" d="M115.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,185.8c-0.8-0.2-0.8,3,0,2.8C114.3,188.8,114.3,185.7,113.4,185.8z"/>
		<path class="st1" d="M113.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,185.8c-0.8-0.2-0.8,3,0,2.8C112.3,188.8,112.3,185.7,111.4,185.8z"/>
		<path class="st1" d="M111.1,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,185.8c-0.8-0.2-0.8,3,0,2.8C110.3,188.8,110.3,185.7,109.4,185.8z"/>
		<path class="st1" d="M109.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,185.8c-0.8-0.2-0.8,3,0,2.8C108.3,188.8,108.3,185.7,107.5,185.8z"/>
		<path class="st1" d="M107.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,185.8c-0.8-0.2-0.8,3,0,2.8C106.3,188.8,106.3,185.7,105.5,185.8z"/>
		<path class="st1" d="M105.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,185.8c-0.8-0.2-0.8,3,0,2.8C104.3,188.8,104.3,185.7,103.5,185.8z"/>
		<path class="st1" d="M103.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,185.8c-0.8-0.2-0.8,3,0,2.8C102.3,188.8,102.3,185.7,101.5,185.8z"/>
		<path class="st1" d="M101.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,185.8c-0.8-0.2-0.8,3,0,2.8C100.3,188.8,100.3,185.7,99.5,185.8z"/>
		<path class="st1" d="M99.2,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,185.8c-0.8-0.2-0.8,3,0,2.8C98.5,188.8,98.5,185.7,97.6,185.8z"/>
		<path class="st1" d="M97.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,185.8c-0.8-0.2-0.8,3,0,2.8C96.5,188.8,96.5,185.7,95.7,185.8z"/>
		<path class="st1" d="M95.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,185.8c-0.8-0.2-0.8,3,0,2.8C94.5,188.8,94.5,185.7,93.7,185.8z"/>
		<path class="st1" d="M93.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,185.8c-0.8-0.2-0.8,3,0,2.8C92.5,188.8,92.5,185.7,91.7,185.8z"/>
		<path class="st1" d="M91.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,185.8c-0.8-0.2-0.8,3,0,2.8C90.5,188.8,90.5,185.7,89.7,185.8z"/>
		<path class="st1" d="M89.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,185.8c-0.8-0.2-0.8,3,0,2.8C88.5,188.8,88.5,185.7,87.7,185.8z"/>
		<path class="st1" d="M87.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,185.8c-0.8-0.2-0.8,3,0,2.8C86.5,188.8,86.5,185.7,85.7,185.8z"/>
		<path class="st1" d="M85.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,185.8c-0.8-0.2-0.8,3,0,2.8C84.6,188.8,84.6,185.7,83.7,185.8z"/>
		<path class="st1" d="M83.4,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,185.8c-0.8-0.2-0.8,3,0,2.8C82.6,188.8,82.6,185.7,81.7,185.8z"/>
		<path class="st1" d="M81.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,185.8c-0.8-0.2-0.8,3,0,2.8C80.6,188.8,80.6,185.7,79.8,185.8z"/>
		<path class="st1" d="M79.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,185.8c-0.8-0.2-0.8,3,0,2.8C78.6,188.8,78.6,185.7,77.8,185.8z"/>
		<path class="st1" d="M77.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,185.8c-0.8-0.2-0.8,3,0,2.8C76.6,188.8,76.6,185.7,75.8,185.8z"/>
		<path class="st1" d="M75.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,185.8c-0.8-0.2-0.8,3,0,2.8C74.6,188.8,74.6,185.7,73.8,185.8z"/>
		<path class="st1" d="M73.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,185.8c-0.8-0.2-0.8,3,0,2.8C72.6,188.8,72.6,185.7,71.8,185.8z"/>
		<path class="st1" d="M71.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,185.8c-0.8-0.2-0.8,3,0,2.8C70.7,188.8,70.7,185.7,69.8,185.8z"/>
		<path class="st1" d="M69.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,185.8c-0.8-0.2-0.8,3,0,2.8C68.7,188.8,68.7,185.7,67.8,185.8z"/>
		<path class="st1" d="M67.5,186.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,186.7c-0.8-0.2-0.8,3,0,2.8C65.8,189.7,65.8,186.6,65,186.7z"/>
		<path class="st1" d="M64.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,186.7c-0.8-0.2-0.8,3,0,2.8C63.8,189.7,63.8,186.6,63,186.7z"/>
		<path class="st1" d="M62.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,186.7c-0.8-0.2-0.8,3,0,2.8C61.8,189.7,61.8,186.6,61,186.7z"/>
		<path class="st1" d="M60.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,186.7c-0.8-0.2-0.8,3,0,2.8C59.8,189.7,59.8,186.6,59,186.7z"/>
		<path class="st1" d="M58.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,186.7c-0.8-0.2-0.8,3,0,2.8C57.9,189.7,57.9,186.6,57,186.7z"/>
		<path class="st1" d="M56.7,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,186.7c-0.8-0.2-0.8,3,0,2.8C55.9,189.7,55.9,186.6,55,186.7z"/>
		<path class="st1" d="M54.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,186.7c-0.8-0.2-0.8,3,0,2.8C53.9,189.7,53.9,186.6,53.1,186.7z"/>
		<path class="st1" d="M52.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,186.7c-0.8-0.2-0.8,3,0,2.8C51.9,189.7,51.9,186.6,51.1,186.7z"/>
		<path class="st1" d="M50.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,186.7c-0.8-0.2-0.8,3,0,2.8C49.9,189.7,49.9,186.6,49.1,186.7z"/>
		<path class="st1" d="M48.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,186.7c-0.8-0.2-0.8,3,0,2.8C47.9,189.7,47.9,186.6,47.1,186.7z"/>
		<path class="st1" d="M46.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,186.7c-0.8-0.2-0.8,3,0,2.8C45.9,189.7,45.9,186.6,45.1,186.7z"/>
		<path class="st1" d="M44.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,186.7c-0.8-0.2-0.8,3,0,2.8C43.9,189.7,43.9,186.6,43.1,186.7z"/>
		<path class="st1" d="M42.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,186.7c-0.8-0.2-0.8,3,0,2.8C42,189.7,42,186.6,41.1,186.7z"/>
		<path class="st1" d="M40.8,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,186.7c-0.8-0.2-0.8,3,0,2.8C40,189.7,40,186.6,39.1,186.7z"/>
		<path class="st1" d="M38.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,186.7c-0.8-0.2-0.8,3,0,2.8C38,189.7,38,186.6,37.2,186.7z"/>
		<path class="st1" d="M36.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,186.7c-0.8-0.2-0.8,3,0,2.8C36,189.7,36,186.6,35.2,186.7z"/>
		<path class="st1" d="M34.9,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,186.7c-0.8-0.2-0.8,3,0,2.8C34.1,189.7,34.1,186.6,33.3,186.7z"/>
		<path class="st1" d="M33,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,186.7c-0.8-0.2-0.8,3,0,2.8C32.1,189.7,32.1,186.6,31.3,186.7z"/>
		<path class="st1" d="M31,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,186.7c-0.8-0.2-0.8,3,0,2.8C30.1,189.7,30.2,186.6,29.3,186.7z"/>
		<path class="st1" d="M29,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,186.7c-0.8-0.2-0.8,3,0,2.8C28.2,189.7,28.2,186.6,27.3,186.7z"/>
		<path class="st1" d="M27,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,186.7c-0.8-0.2-0.8,3,0,2.8C26.2,189.7,26.2,186.6,25.3,186.7z"/>
		<path class="st1" d="M25.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,186.7c-0.8-0.2-0.8,3,0,2.8C24.2,189.7,24.2,186.6,23.4,186.7z"/>
		<path class="st1" d="M23.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,186.7c-0.8-0.2-0.8,3,0,2.8C22.2,189.7,22.2,186.6,21.4,186.7z"/>
		<path class="st1" d="M21.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,186.7c-0.8-0.2-0.8,3,0,2.8C20.2,189.7,20.2,186.6,19.4,186.7z"/>
		<path class="st1" d="M19.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,186.7c-0.8-0.2-0.8,3,0,2.8C18.2,189.7,18.2,186.6,17.4,186.7z"/>
		<path class="st1" d="M17.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,186.7c-0.8-0.2-0.8,3,0,2.8C16.2,189.7,16.2,186.6,15.4,186.7z"/>
		<path class="st1" d="M15.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,186.7c-0.8-0.2-0.8,3,0,2.8C14.3,189.7,14.3,186.6,13.4,186.7z"/>
		<path class="st1" d="M13.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,186.7c-0.8-0.2-0.8,3,0,2.8C12.3,189.7,12.3,186.6,11.4,186.7z"/>
		<path class="st1" d="M11.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,186.7c-0.8-0.2-0.8,3,0,2.8C10.3,189.7,10.3,186.6,9.5,186.7z"/>
		<path class="st1" d="M9.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,186.7c-0.8-0.2-0.8,3,0,2.8C8.3,189.7,8.3,186.6,7.5,186.7z"/>
		<path class="st1" d="M7.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,186.7c-0.8-0.2-0.8,3,0,2.8C6.3,189.7,6.3,186.6,5.5,186.7z"/>
		<path class="st1" d="M5.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,186.7c-0.8-0.2-0.8,3,0,2.8C4.3,189.7,4.3,186.6,3.5,186.7z"/>
		<path class="st1" d="M3.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,186.7c-0.8-0.2-0.8,3,0,2.8C129.2,189.7,129.2,186.6,128.4,186.7z"/>
		<path class="st1" d="M128.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,186.7c-0.8-0.2-0.8,3,0,2.8C127.2,189.7,127.2,186.6,126.4,186.7z"/>
		<path class="st1" d="M126.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,186.7c-0.8-0.2-0.8,3,0,2.8C125.3,189.7,125.3,186.6,124.4,186.7z"/>
		<path class="st1" d="M124.1,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,186.7c-0.8-0.2-0.8,3,0,2.8C123.3,189.7,123.3,186.6,122.4,186.7z"/>
		<path class="st1" d="M122.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,186.7c-0.8-0.2-0.8,3,0,2.8C121.3,189.7,121.3,186.6,120.5,186.7z"/>
		<path class="st1" d="M120.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,186.7c-0.8-0.2-0.8,3,0,2.8C119.3,189.7,119.3,186.6,118.5,186.7z"/>
		<path class="st1" d="M118.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,186.7c-0.8-0.2-0.8,3,0,2.8C117.3,189.7,117.3,186.6,116.5,186.7z"/>
		<path class="st1" d="M116.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,186.7c-0.8-0.2-0.8,3,0,2.8C115.3,189.7,115.3,186.6,114.5,186.7z"/>
		<path class="st1" d="M114.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,186.7c-0.8-0.2-0.8,3,0,2.8C113.3,189.7,113.3,186.6,112.5,186.7z"/>
		<path class="st1" d="M112.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,186.7c-0.8-0.2-0.8,3,0,2.8C111.3,189.7,111.3,186.6,110.5,186.7z"/>
		<path class="st1" d="M110.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,186.7c-0.8-0.2-0.8,3,0,2.8C109.4,189.7,109.4,186.6,108.5,186.7z"/>
		<path class="st1" d="M108.2,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,186.7c-0.8-0.2-0.8,3,0,2.8C107.4,189.7,107.4,186.6,106.5,186.7z"/>
		<path class="st1" d="M106.3,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,186.7c-0.8-0.2-0.8,3,0,2.8C105.4,189.7,105.4,186.6,104.6,186.7z"/>
		<path class="st1" d="M104.3,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,186.7c-0.8-0.2-0.8,3,0,2.8C103.4,189.7,103.4,186.6,102.6,186.7z"/>
		<path class="st1" d="M102.3,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,186.7c-0.8-0.2-0.8,3,0,2.8C101.4,189.7,101.4,186.6,100.6,186.7z"/>
		<path class="st1" d="M100.3,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,186.7c-0.8-0.2-0.8,3,0,2.8C99.4,189.7,99.4,186.6,98.6,186.7z"/>
		<path class="st1" d="M98.3,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,186.7c-0.8-0.2-0.8,3,0,2.8C97.5,189.7,97.6,186.6,96.7,186.7z"/>
		<path class="st1" d="M96.4,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,186.7c-0.8-0.2-0.8,3,0,2.8C95.6,189.7,95.6,186.6,94.7,186.7z"/>
		<path class="st1" d="M94.4,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,186.7c-0.8-0.2-0.8,3,0,2.8C93.6,189.7,93.6,186.6,92.7,186.7z"/>
		<path class="st1" d="M92.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,186.7c-0.8-0.2-0.8,3,0,2.8C91.6,189.7,91.6,186.6,90.8,186.7z"/>
		<path class="st1" d="M90.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,186.7c-0.8-0.2-0.8,3,0,2.8C89.6,189.7,89.6,186.6,88.8,186.7z"/>
		<path class="st1" d="M88.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,186.7c-0.8-0.2-0.8,3,0,2.8C87.6,189.7,87.6,186.6,86.8,186.7z"/>
		<path class="st1" d="M86.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,186.7c-0.8-0.2-0.8,3,0,2.8C85.6,189.7,85.6,186.6,84.8,186.7z"/>
		<path class="st1" d="M84.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,186.7c-0.8-0.2-0.8,3,0,2.8C83.6,189.7,83.6,186.6,82.8,186.7z"/>
		<path class="st1" d="M82.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,186.7c-0.8-0.2-0.8,3,0,2.8C81.7,189.7,81.7,186.6,80.8,186.7z"/>
		<path class="st1" d="M80.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,186.7c-0.8-0.2-0.8,3,0,2.8C79.7,189.7,79.7,186.6,78.8,186.7z"/>
		<path class="st1" d="M78.5,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,186.7c-0.8-0.2-0.8,3,0,2.8C77.7,189.7,77.7,186.6,76.9,186.7z"/>
		<path class="st1" d="M76.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,186.7c-0.8-0.2-0.8,3,0,2.8C75.7,189.7,75.7,186.6,74.9,186.7z"/>
		<path class="st1" d="M74.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,186.7c-0.8-0.2-0.8,3,0,2.8C73.7,189.7,73.7,186.6,72.9,186.7z"/>
		<path class="st1" d="M72.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,186.7c-0.8-0.2-0.8,3,0,2.8C71.7,189.7,71.7,186.6,70.9,186.7z"/>
		<path class="st1" d="M70.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,186.7c-0.8-0.2-0.8,3,0,2.8C69.7,189.7,69.7,186.6,68.9,186.7z"/>
		<path class="st1" d="M68.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,186.7c-0.8-0.2-0.8,3,0,2.8C67.7,189.7,67.7,186.6,66.9,186.7z"/>
		<path class="st1" d="M66.6,187.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,187.5c-0.8-0.2-0.8,3,0,2.8C66.7,190.5,66.7,187.3,65.9,187.5z"/>
		<path class="st1" d="M65.6,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,187.5c-0.8-0.2-0.8,3,0,2.8C64.7,190.5,64.7,187.3,63.9,187.5z"/>
		<path class="st1" d="M63.6,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,187.5c-0.8-0.2-0.8,3,0,2.8C62.7,190.5,62.8,187.3,61.9,187.5z"/>
		<path class="st1" d="M61.6,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,187.5c-0.8-0.2-0.8,3,0,2.8C60.8,190.5,60.8,187.3,59.9,187.5z"/>
		<path class="st1" d="M59.6,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,187.5c-0.8-0.2-0.8,3,0,2.8C58.8,190.5,58.8,187.3,57.9,187.5z"/>
		<path class="st1" d="M57.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,187.5c-0.8-0.2-0.8,3,0,2.8C56.8,190.5,56.8,187.3,56,187.5z"/>
		<path class="st1" d="M55.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,187.5c-0.8-0.2-0.8,3,0,2.8C54.8,190.5,54.8,187.3,54,187.5z"/>
		<path class="st1" d="M53.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,187.5c-0.8-0.2-0.8,3,0,2.8C52.8,190.5,52.8,187.3,52,187.5z"/>
		<path class="st1" d="M51.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,187.5c-0.8-0.2-0.8,3,0,2.8C50.8,190.5,50.8,187.3,50,187.5z"/>
		<path class="st1" d="M49.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,187.5c-0.8-0.2-0.8,3,0,2.8C48.8,190.5,48.8,187.3,48,187.5z"/>
		<path class="st1" d="M47.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,187.5c-0.8-0.2-0.8,3,0,2.8C46.9,190.5,46.9,187.3,46,187.5z"/>
		<path class="st1" d="M45.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,187.5c-0.8-0.2-0.8,3,0,2.8C44.9,190.5,44.9,187.3,44,187.5z"/>
		<path class="st1" d="M43.7,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,187.5c-0.8-0.2-0.8,3,0,2.8C42.9,190.5,42.9,187.3,42.1,187.5z"/>
		<path class="st1" d="M41.8,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,187.5c-0.8-0.2-0.8,3,0,2.8C40.9,190.5,40.9,187.3,40.1,187.5z"/>
		<path class="st1" d="M39.8,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,187.5c-0.8-0.2-0.8,3,0,2.8C38.9,190.5,38.9,187.3,38.1,187.5z"/>
		<path class="st1" d="M37.8,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,187.5c-0.8-0.2-0.8,3,0,2.8C36.9,190.5,36.9,187.3,36.1,187.5z"/>
		<path class="st1" d="M35.8,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,187.5c-0.8-0.2-0.8,3,0,2.8C35,190.5,35,187.3,34.2,187.5z"/>
		<path class="st1" d="M33.9,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,187.5c-0.8-0.2-0.8,3,0,2.8C33.1,190.5,33.1,187.3,32.2,187.5z"/>
		<path class="st1" d="M31.9,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,187.5c-0.8-0.2-0.8,3,0,2.8C31.1,190.5,31.1,187.3,30.2,187.5z"/>
		<path class="st1" d="M30,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,187.5c-0.8-0.2-0.8,3,0,2.8C29.1,190.5,29.1,187.3,28.3,187.5z"/>
		<path class="st1" d="M28,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,187.5c-0.8-0.2-0.8,3,0,2.8C27.1,190.5,27.1,187.3,26.3,187.5z"/>
		<path class="st1" d="M26,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,187.5c-0.8-0.2-0.8,3,0,2.8C25.1,190.5,25.1,187.3,24.3,187.5z"/>
		<path class="st1" d="M24,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,187.5c-0.8-0.2-0.8,3,0,2.8C23.1,190.5,23.1,187.3,22.3,187.5z"/>
		<path class="st1" d="M22,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,187.5c-0.8-0.2-0.8,3,0,2.8C21.1,190.5,21.1,187.3,20.3,187.5z"/>
		<path class="st1" d="M20,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,187.5c-0.8-0.2-0.8,3,0,2.8C19.1,190.5,19.1,187.3,18.3,187.5z"/>
		<path class="st1" d="M18,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,187.5c-0.8-0.2-0.8,3,0,2.8C17.2,190.5,17.2,187.3,16.3,187.5z"/>
		<path class="st1" d="M16,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,187.5c-0.8-0.2-0.8,3,0,2.8C15.2,190.5,15.2,187.3,14.3,187.5z"/>
		<path class="st1" d="M14.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,187.5c-0.8-0.2-0.8,3,0,2.8C13.2,190.5,13.2,187.3,12.4,187.5z"/>
		<path class="st1" d="M12.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,187.5c-0.8-0.2-0.8,3,0,2.8C11.2,190.5,11.2,187.3,10.4,187.5z"/>
		<path class="st1" d="M10.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,187.5c-0.8-0.2-0.8,3,0,2.8C9.2,190.5,9.2,187.3,8.4,187.5z"/>
		<path class="st1" d="M8.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,187.5c-0.8-0.2-0.8,3,0,2.8C7.2,190.5,7.2,187.3,6.4,187.5z"/>
		<path class="st1" d="M6.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,187.5c-0.8-0.2-0.8,3,0,2.8C5.2,190.5,5.2,187.3,4.4,187.5z"/>
		<path class="st1" d="M4.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,187.5c-0.8-0.2-0.8,3,0,2.8C130.1,190.5,130.1,187.3,129.3,187.5z"/>
		<path class="st1" d="M129,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,187.5c-0.8-0.2-0.8,3,0,2.8C128.2,190.5,128.2,187.3,127.3,187.5z"/>
		<path class="st1" d="M127,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,187.5c-0.8-0.2-0.8,3,0,2.8C126.2,190.5,126.2,187.3,125.3,187.5z"/>
		<path class="st1" d="M125.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,187.5c-0.8-0.2-0.8,3,0,2.8C124.2,190.5,124.2,187.3,123.4,187.5z"/>
		<path class="st1" d="M123.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,187.5c-0.8-0.2-0.8,3,0,2.8C122.2,190.5,122.2,187.3,121.4,187.5z"/>
		<path class="st1" d="M121.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,187.5c-0.8-0.2-0.8,3,0,2.8C120.2,190.5,120.2,187.3,119.4,187.5z"/>
		<path class="st1" d="M119.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,187.5c-0.8-0.2-0.8,3,0,2.8C118.2,190.5,118.2,187.3,117.4,187.5z"/>
		<path class="st1" d="M117.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,187.5c-0.8-0.2-0.8,3,0,2.8C116.2,190.5,116.2,187.3,115.4,187.5z"/>
		<path class="st1" d="M115.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,187.5c-0.8-0.2-0.8,3,0,2.8C114.3,190.5,114.3,187.3,113.4,187.5z"/>
		<path class="st1" d="M113.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,187.5c-0.8-0.2-0.8,3,0,2.8C112.3,190.5,112.3,187.3,111.4,187.5z"/>
		<path class="st1" d="M111.1,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,187.5c-0.8-0.2-0.8,3,0,2.8C110.3,190.5,110.3,187.3,109.4,187.5z"/>
		<path class="st1" d="M109.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,187.5c-0.8-0.2-0.8,3,0,2.8C108.3,190.5,108.3,187.3,107.5,187.5z"/>
		<path class="st1" d="M107.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,187.5c-0.8-0.2-0.8,3,0,2.8C106.3,190.5,106.3,187.3,105.5,187.5z"/>
		<path class="st1" d="M105.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,187.5c-0.8-0.2-0.8,3,0,2.8C104.3,190.5,104.3,187.3,103.5,187.5z"/>
		<path class="st1" d="M103.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,187.5c-0.8-0.2-0.8,3,0,2.8C102.3,190.5,102.3,187.3,101.5,187.5z"/>
		<path class="st1" d="M101.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,187.5c-0.8-0.2-0.8,3,0,2.8C100.3,190.5,100.3,187.3,99.5,187.5z"/>
		<path class="st1" d="M99.2,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,187.5c-0.8-0.2-0.8,3,0,2.8C98.5,190.5,98.5,187.3,97.6,187.5z"/>
		<path class="st1" d="M97.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,187.5c-0.8-0.2-0.8,3,0,2.8C96.5,190.5,96.5,187.3,95.7,187.5z"/>
		<path class="st1" d="M95.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,187.5c-0.8-0.2-0.8,3,0,2.8C94.5,190.5,94.5,187.3,93.7,187.5z"/>
		<path class="st1" d="M93.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,187.5c-0.8-0.2-0.8,3,0,2.8C92.5,190.5,92.5,187.3,91.7,187.5z"/>
		<path class="st1" d="M91.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,187.5c-0.8-0.2-0.8,3,0,2.8C90.5,190.5,90.5,187.3,89.7,187.5z"/>
		<path class="st1" d="M89.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,187.5c-0.8-0.2-0.8,3,0,2.8C88.5,190.5,88.5,187.3,87.7,187.5z"/>
		<path class="st1" d="M87.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,187.5c-0.8-0.2-0.8,3,0,2.8C86.5,190.5,86.5,187.3,85.7,187.5z"/>
		<path class="st1" d="M85.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,187.5c-0.8-0.2-0.8,3,0,2.8C84.6,190.5,84.6,187.3,83.7,187.5z"/>
		<path class="st1" d="M83.4,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,187.5c-0.8-0.2-0.8,3,0,2.8C82.6,190.5,82.6,187.3,81.7,187.5z"/>
		<path class="st1" d="M81.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,187.5c-0.8-0.2-0.8,3,0,2.8C80.6,190.5,80.6,187.3,79.8,187.5z"/>
		<path class="st1" d="M79.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,187.5c-0.8-0.2-0.8,3,0,2.8C78.6,190.5,78.6,187.3,77.8,187.5z"/>
		<path class="st1" d="M77.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,187.5c-0.8-0.2-0.8,3,0,2.8C76.6,190.5,76.6,187.3,75.8,187.5z"/>
		<path class="st1" d="M75.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,187.5c-0.8-0.2-0.8,3,0,2.8C74.6,190.5,74.6,187.3,73.8,187.5z"/>
		<path class="st1" d="M73.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,187.5c-0.8-0.2-0.8,3,0,2.8C72.6,190.5,72.6,187.3,71.8,187.5z"/>
		<path class="st1" d="M71.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,187.5c-0.8-0.2-0.8,3,0,2.8C70.7,190.5,70.7,187.3,69.8,187.5z"/>
		<path class="st1" d="M69.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,187.5c-0.8-0.2-0.8,3,0,2.8C68.7,190.5,68.7,187.3,67.8,187.5z"/>
		<path class="st1" d="M67.5,188.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,188.4c-0.8-0.2-0.8,3,0,2.8C51.9,191.4,51.9,188.3,51.1,188.4z"/>
		<path class="st1" d="M50.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,188.4c-0.8-0.2-0.8,3,0,2.8C49.9,191.4,49.9,188.3,49.1,188.4z"/>
		<path class="st1" d="M48.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,188.4c-0.8-0.2-0.8,3,0,2.8C47.9,191.4,47.9,188.3,47.1,188.4z"/>
		<path class="st1" d="M46.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,188.4c-0.8-0.2-0.8,3,0,2.8C45.9,191.4,45.9,188.3,45.1,188.4z"/>
		<path class="st1" d="M44.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,188.4c-0.8-0.2-0.8,3,0,2.8C43.9,191.4,43.9,188.3,43.1,188.4z"/>
		<path class="st1" d="M42.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,188.4c-0.8-0.2-0.8,3,0,2.8C42,191.4,42,188.3,41.1,188.4z"/>
		<path class="st1" d="M40.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,188.4c-0.8-0.2-0.8,3,0,2.8C40,191.4,40,188.3,39.1,188.4z"/>
		<path class="st1" d="M38.9,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,188.4c-0.8-0.2-0.8,3,0,2.8C38,191.4,38,188.3,37.2,188.4z"/>
		<path class="st1" d="M36.9,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,188.4c-0.8-0.2-0.8,3,0,2.8C36,191.4,36,188.3,35.2,188.4z"/>
		<path class="st1" d="M34.9,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,188.4c-0.8-0.2-0.8,3,0,2.8C34.1,191.4,34.1,188.3,33.3,188.4z"/>
		<path class="st1" d="M33,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,188.4c-0.8-0.2-0.8,3,0,2.8C32.1,191.4,32.1,188.3,31.3,188.4z"/>
		<path class="st1" d="M31,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,188.4c-0.8-0.2-0.8,3,0,2.8C30.1,191.4,30.2,188.3,29.3,188.4z"/>
		<path class="st1" d="M29,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,188.4c-0.8-0.2-0.8,3,0,2.8C28.2,191.4,28.2,188.3,27.3,188.4z"/>
		<path class="st1" d="M27,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,188.4c-0.8-0.2-0.8,3,0,2.8C26.2,191.4,26.2,188.3,25.3,188.4z"/>
		<path class="st1" d="M25.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,188.4c-0.8-0.2-0.8,3,0,2.8C24.2,191.4,24.2,188.3,23.4,188.4z"/>
		<path class="st1" d="M23.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,188.4c-0.8-0.2-0.8,3,0,2.8C22.2,191.4,22.2,188.3,21.4,188.4z"/>
		<path class="st1" d="M21.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,188.4c-0.8-0.2-0.8,3,0,2.8C20.2,191.4,20.2,188.3,19.4,188.4z"/>
		<path class="st1" d="M19.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,188.4c-0.8-0.2-0.8,3,0,2.8C18.2,191.4,18.2,188.3,17.4,188.4z"/>
		<path class="st1" d="M17.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,188.4c-0.8-0.2-0.8,3,0,2.8C16.2,191.4,16.2,188.3,15.4,188.4z"/>
		<path class="st1" d="M15.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,188.4c-0.8-0.2-0.8,3,0,2.8C14.3,191.4,14.3,188.3,13.4,188.4z"/>
		<path class="st1" d="M13.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,188.4c-0.8-0.2-0.8,3,0,2.8C12.3,191.4,12.3,188.3,11.4,188.4z"/>
		<path class="st1" d="M11.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,188.4c-0.8-0.2-0.8,3,0,2.8C10.3,191.4,10.3,188.3,9.5,188.4z"/>
		<path class="st1" d="M9.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,188.4c-0.8-0.2-0.8,3,0,2.8C8.3,191.4,8.3,188.3,7.5,188.4z"/>
		<path class="st1" d="M7.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,188.4c-0.8-0.2-0.8,3,0,2.8C6.3,191.4,6.3,188.3,5.5,188.4z"/>
		<path class="st1" d="M5.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,188.4c-0.8-0.2-0.8,3,0,2.8C4.3,191.4,4.3,188.3,3.5,188.4z"/>
		<path class="st2" d="M3.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M53.1,188.4c-0.8-0.2-0.8,3,0,2.8C53.9,191.4,53.9,188.3,53.1,188.4z"/>
		<path class="st1" d="M52.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,188.4c-0.8-0.2-0.8,3,0,2.8C65.8,191.4,65.8,188.2,65,188.4z"/>
		<path class="st1" d="M64.7,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,188.4c-0.8-0.2-0.8,3,0,2.8C63.8,191.4,63.8,188.2,63,188.4z"/>
		<path class="st1" d="M62.7,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,188.4c-0.8-0.2-0.8,3,0,2.8C61.8,191.4,61.8,188.2,61,188.4z"/>
		<path class="st1" d="M60.7,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,188.4c-0.8-0.2-0.8,3,0,2.8C59.8,191.4,59.8,188.2,59,188.4z"/>
		<path class="st1" d="M58.7,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,188.4c-0.8-0.2-0.8,3,0,2.8C57.9,191.4,57.9,188.2,57,188.4z"/>
		<path class="st1" d="M56.7,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,188.4c-0.8-0.2-0.8,3,0,2.8C55.9,191.4,55.9,188.2,55,188.4z"/>
		<path class="st1" d="M54.8,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,188.4c-0.8-0.2-0.8,3,0,2.8C129.2,191.4,129.2,188.2,128.4,188.4z"/>
		<path class="st1" d="M128.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,188.4c-0.8-0.2-0.8,3,0,2.8C127.2,191.4,127.2,188.2,126.4,188.4z"/>
		<path class="st1" d="M126.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,188.4c-0.8-0.2-0.8,3,0,2.8C125.3,191.4,125.3,188.2,124.4,188.4z"/>
		<path class="st1" d="M124.1,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,188.4c-0.8-0.2-0.8,3,0,2.8C123.3,191.4,123.3,188.2,122.4,188.4z"/>
		<path class="st1" d="M122.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,188.4c-0.8-0.2-0.8,3,0,2.8C121.3,191.4,121.3,188.2,120.5,188.4z"/>
		<path class="st1" d="M120.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,188.4c-0.8-0.2-0.8,3,0,2.8C119.3,191.4,119.3,188.2,118.5,188.4z"/>
		<path class="st1" d="M118.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,188.4c-0.8-0.2-0.8,3,0,2.8C117.3,191.4,117.3,188.2,116.5,188.4z"/>
		<path class="st1" d="M116.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,188.4c-0.8-0.2-0.8,3,0,2.8C115.3,191.4,115.3,188.2,114.5,188.4z"/>
		<path class="st1" d="M114.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,188.4c-0.8-0.2-0.8,3,0,2.8C113.3,191.4,113.3,188.2,112.5,188.4z"/>
		<path class="st1" d="M112.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,188.4c-0.8-0.2-0.8,3,0,2.8C111.3,191.4,111.3,188.2,110.5,188.4z"/>
		<path class="st1" d="M110.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,188.4c-0.8-0.2-0.8,3,0,2.8C109.4,191.4,109.4,188.2,108.5,188.4z"/>
		<path class="st1" d="M108.2,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,188.4c-0.8-0.2-0.8,3,0,2.8C107.4,191.4,107.4,188.2,106.5,188.4z"/>
		<path class="st1" d="M106.3,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,188.4c-0.8-0.2-0.8,3,0,2.8C105.4,191.4,105.4,188.2,104.6,188.4z"/>
		<path class="st1" d="M104.3,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,188.4c-0.8-0.2-0.8,3,0,2.8C103.4,191.4,103.4,188.2,102.6,188.4z"/>
		<path class="st1" d="M102.3,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,188.4c-0.8-0.2-0.8,3,0,2.8C101.4,191.4,101.4,188.2,100.6,188.4z"/>
		<path class="st1" d="M100.3,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,188.4c-0.8-0.2-0.8,3,0,2.8C99.4,191.4,99.4,188.2,98.6,188.4z"/>
		<path class="st1" d="M98.3,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,188.4c-0.8-0.2-0.8,3,0,2.8C97.5,191.4,97.6,188.2,96.7,188.4z"/>
		<path class="st1" d="M96.4,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,188.4c-0.8-0.2-0.8,3,0,2.8C95.6,191.4,95.6,188.2,94.7,188.4z"/>
		<path class="st1" d="M94.4,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,188.4c-0.8-0.2-0.8,3,0,2.8C93.6,191.4,93.6,188.2,92.7,188.4z"/>
		<path class="st1" d="M92.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,188.4c-0.8-0.2-0.8,3,0,2.8C91.6,191.4,91.6,188.2,90.8,188.4z"/>
		<path class="st1" d="M90.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,188.4c-0.8-0.2-0.8,3,0,2.8C89.6,191.4,89.6,188.2,88.8,188.4z"/>
		<path class="st1" d="M88.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,188.4c-0.8-0.2-0.8,3,0,2.8C87.6,191.4,87.6,188.2,86.8,188.4z"/>
		<path class="st1" d="M86.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,188.4c-0.8-0.2-0.8,3,0,2.8C85.6,191.4,85.6,188.2,84.8,188.4z"/>
		<path class="st1" d="M84.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,188.4c-0.8-0.2-0.8,3,0,2.8C83.6,191.4,83.6,188.2,82.8,188.4z"/>
		<path class="st1" d="M82.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,188.4c-0.8-0.2-0.8,3,0,2.8C81.7,191.4,81.7,188.2,80.8,188.4z"/>
		<path class="st1" d="M80.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,188.4c-0.8-0.2-0.8,3,0,2.8C79.7,191.4,79.7,188.2,78.8,188.4z"/>
		<path class="st1" d="M78.5,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,188.4c-0.8-0.2-0.8,3,0,2.8C77.7,191.4,77.7,188.2,76.9,188.4z"/>
		<path class="st1" d="M76.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,188.4c-0.8-0.2-0.8,3,0,2.8C75.7,191.4,75.7,188.2,74.9,188.4z"/>
		<path class="st1" d="M74.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,188.4c-0.8-0.2-0.8,3,0,2.8C73.7,191.4,73.7,188.2,72.9,188.4z"/>
		<path class="st1" d="M72.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,188.4c-0.8-0.2-0.8,3,0,2.8C71.7,191.4,71.7,188.2,70.9,188.4z"/>
		<path class="st1" d="M70.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,188.4c-0.8-0.2-0.8,3,0,2.8C69.7,191.4,69.7,188.2,68.9,188.4z"/>
		<path class="st1" d="M68.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,188.4c-0.8-0.2-0.8,3,0,2.8C67.7,191.4,67.7,188.2,66.9,188.4z"/>
		<path class="st1" d="M66.6,189.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M4.4,127.4c-0.8-0.2-0.8,3,0,2.8C5.2,130.4,5.2,127.2,4.4,127.4z"/>
		<path class="st1" d="M4.1,128.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,128.5c-0.8-0.2-0.8,3,0,2.8C65.8,131.5,65.8,128.3,65,128.5z"/>
		<path class="st1" d="M64.7,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,128.5c-0.8-0.2-0.8,3,0,2.8C63.8,131.5,63.8,128.3,63,128.5z"/>
		<path class="st1" d="M62.7,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,128.5c-0.8-0.2-0.8,3,0,2.8C61.8,131.5,61.8,128.3,61,128.5z"/>
		<path class="st1" d="M60.7,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,128.5c-0.8-0.2-0.8,3,0,2.8C59.8,131.5,59.8,128.3,59,128.5z"/>
		<path class="st1" d="M58.7,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,128.5c-0.8-0.2-0.8,3,0,2.8C57.9,131.5,57.9,128.3,57,128.5z"/>
		<path class="st1" d="M56.7,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,128.5c-0.8-0.2-0.8,3,0,2.8C55.9,131.5,55.9,128.3,55,128.5z"/>
		<path class="st1" d="M54.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,128.5c-0.8-0.2-0.8,3,0,2.8C53.9,131.5,53.9,128.3,53.1,128.5z"/>
		<path class="st1" d="M52.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,128.5c-0.8-0.2-0.8,3,0,2.8C51.9,131.5,51.9,128.3,51.1,128.5z"/>
		<path class="st1" d="M50.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,128.5c-0.8-0.2-0.8,3,0,2.8C49.9,131.5,49.9,128.3,49.1,128.5z"/>
		<path class="st1" d="M48.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,128.5c-0.8-0.2-0.8,3,0,2.8C47.9,131.5,47.9,128.3,47.1,128.5z"/>
		<path class="st1" d="M46.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,128.5c-0.8-0.2-0.8,3,0,2.8C45.9,131.5,45.9,128.3,45.1,128.5z"/>
		<path class="st1" d="M44.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,128.5c-0.8-0.2-0.8,3,0,2.8C43.9,131.5,43.9,128.3,43.1,128.5z"/>
		<path class="st1" d="M42.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,128.5c-0.8-0.2-0.8,3,0,2.8C42,131.5,42,128.3,41.1,128.5z"/>
		<path class="st1" d="M40.8,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,128.5c-0.8-0.2-0.8,3,0,2.8C40,131.5,40,128.3,39.1,128.5z"/>
		<path class="st1" d="M38.9,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,128.5c-0.8-0.2-0.8,3,0,2.8C38,131.5,38,128.3,37.2,128.5z"/>
		<path class="st1" d="M36.9,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,128.5c-0.8-0.2-0.8,3,0,2.8C36,131.5,36,128.3,35.2,128.5z"/>
		<path class="st1" d="M34.9,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,128.5c-0.8-0.2-0.8,3,0,2.8C34.1,131.5,34.1,128.3,33.3,128.5z"/>
		<path class="st1" d="M33,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,128.5c-0.8-0.2-0.8,3,0,2.8C32.1,131.5,32.1,128.3,31.3,128.5z"/>
		<path class="st1" d="M31,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,128.5c-0.8-0.2-0.8,3,0,2.8C30.1,131.5,30.2,128.3,29.3,128.5z"/>
		<path class="st1" d="M29,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,128.5c-0.8-0.2-0.8,3,0,2.8C28.2,131.5,28.2,128.3,27.3,128.5z"/>
		<path class="st1" d="M27,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,128.5c-0.8-0.2-0.8,3,0,2.8C26.2,131.5,26.2,128.3,25.3,128.5z"/>
		<path class="st1" d="M25.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,128.5c-0.8-0.2-0.8,3,0,2.8C24.2,131.5,24.2,128.3,23.4,128.5z"/>
		<path class="st1" d="M23.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,128.5c-0.8-0.2-0.8,3,0,2.8C22.2,131.5,22.2,128.3,21.4,128.5z"/>
		<path class="st1" d="M21.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,128.5c-0.8-0.2-0.8,3,0,2.8C20.2,131.5,20.2,128.3,19.4,128.5z"/>
		<path class="st1" d="M19.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,128.5c-0.8-0.2-0.8,3,0,2.8C18.2,131.5,18.2,128.3,17.4,128.5z"/>
		<path class="st1" d="M17.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,128.5c-0.8-0.2-0.8,3,0,2.8C16.2,131.5,16.2,128.3,15.4,128.5z"/>
		<path class="st1" d="M15.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,128.5c-0.8-0.2-0.8,3,0,2.8C14.3,131.5,14.3,128.3,13.4,128.5z"/>
		<path class="st1" d="M13.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,128.5c-0.8-0.2-0.8,3,0,2.8C12.3,131.5,12.3,128.3,11.4,128.5z"/>
		<path class="st1" d="M11.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,128.5c-0.8-0.2-0.8,3,0,2.8C10.3,131.5,10.3,128.3,9.5,128.5z"/>
		<path class="st1" d="M9.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,128.5c-0.8-0.2-0.8,3,0,2.8C8.3,131.5,8.3,128.3,7.5,128.5z"/>
		<path class="st1" d="M7.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,128.5c-0.8-0.2-0.8,3,0,2.8C6.3,131.5,6.3,128.3,5.5,128.5z"/>
		<path class="st1" d="M5.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,128.5c-0.8-0.2-0.8,3,0,2.8C4.3,131.5,4.3,128.3,3.5,128.5z"/>
		<path class="st1" d="M3.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,128.5c-0.8-0.2-0.8,3,0,2.8C129.2,131.5,129.2,128.3,128.4,128.5z"/>
		<path class="st1" d="M128.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,128.5c-0.8-0.2-0.8,3,0,2.8C127.2,131.5,127.2,128.3,126.4,128.5z"/>
		<path class="st1" d="M126.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,128.5c-0.8-0.2-0.8,3,0,2.8C125.3,131.5,125.3,128.3,124.4,128.5z"/>
		<path class="st1" d="M124.1,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,128.5c-0.8-0.2-0.8,3,0,2.8C123.3,131.5,123.3,128.3,122.4,128.5z"/>
		<path class="st1" d="M122.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,128.5c-0.8-0.2-0.8,3,0,2.8C121.3,131.5,121.3,128.3,120.5,128.5z"/>
		<path class="st1" d="M120.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,128.5c-0.8-0.2-0.8,3,0,2.8C119.3,131.5,119.3,128.3,118.5,128.5z"/>
		<path class="st1" d="M118.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,128.5c-0.8-0.2-0.8,3,0,2.8C117.3,131.5,117.3,128.3,116.5,128.5z"/>
		<path class="st1" d="M116.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,128.5c-0.8-0.2-0.8,3,0,2.8C115.3,131.5,115.3,128.3,114.5,128.5z"/>
		<path class="st1" d="M114.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,128.5c-0.8-0.2-0.8,3,0,2.8C113.3,131.5,113.3,128.3,112.5,128.5z"/>
		<path class="st1" d="M112.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,128.5c-0.8-0.2-0.8,3,0,2.8C111.3,131.5,111.3,128.3,110.5,128.5z"/>
		<path class="st1" d="M110.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,128.5c-0.8-0.2-0.8,3,0,2.8C109.4,131.5,109.4,128.3,108.5,128.5z"/>
		<path class="st1" d="M108.2,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,128.5c-0.8-0.2-0.8,3,0,2.8C107.4,131.5,107.4,128.3,106.5,128.5z"/>
		<path class="st1" d="M106.3,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,128.5c-0.8-0.2-0.8,3,0,2.8C105.4,131.5,105.4,128.3,104.6,128.5z"/>
		<path class="st1" d="M104.3,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,128.5c-0.8-0.2-0.8,3,0,2.8C103.4,131.5,103.4,128.3,102.6,128.5z"/>
		<path class="st1" d="M102.3,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,128.5c-0.8-0.2-0.8,3,0,2.8C101.4,131.5,101.4,128.3,100.6,128.5z"/>
		<path class="st1" d="M100.3,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,128.5c-0.8-0.2-0.8,3,0,2.8C99.4,131.5,99.4,128.3,98.6,128.5z"/>
		<path class="st1" d="M98.3,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,128.5c-0.8-0.2-0.8,3,0,2.8C97.5,131.5,97.6,128.3,96.7,128.5z"/>
		<path class="st1" d="M96.4,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,128.5c-0.8-0.2-0.8,3,0,2.8C95.6,131.5,95.6,128.3,94.7,128.5z"/>
		<path class="st1" d="M94.4,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,128.5c-0.8-0.2-0.8,3,0,2.8C93.6,131.5,93.6,128.3,92.7,128.5z"/>
		<path class="st1" d="M92.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,128.5c-0.8-0.2-0.8,3,0,2.8C91.6,131.5,91.6,128.3,90.8,128.5z"/>
		<path class="st1" d="M90.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,128.5c-0.8-0.2-0.8,3,0,2.8C89.6,131.5,89.6,128.3,88.8,128.5z"/>
		<path class="st1" d="M88.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,128.5c-0.8-0.2-0.8,3,0,2.8C87.6,131.5,87.6,128.3,86.8,128.5z"/>
		<path class="st1" d="M86.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,128.5c-0.8-0.2-0.8,3,0,2.8C85.6,131.5,85.6,128.3,84.8,128.5z"/>
		<path class="st1" d="M84.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,128.5c-0.8-0.2-0.8,3,0,2.8C83.6,131.5,83.6,128.3,82.8,128.5z"/>
		<path class="st1" d="M82.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,128.5c-0.8-0.2-0.8,3,0,2.8C81.7,131.5,81.7,128.3,80.8,128.5z"/>
		<path class="st1" d="M80.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,128.5c-0.8-0.2-0.8,3,0,2.8C79.7,131.5,79.7,128.3,78.8,128.5z"/>
		<path class="st1" d="M78.5,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,128.5c-0.8-0.2-0.8,3,0,2.8C77.7,131.5,77.7,128.3,76.9,128.5z"/>
		<path class="st1" d="M76.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,128.5c-0.8-0.2-0.8,3,0,2.8C75.7,131.5,75.7,128.3,74.9,128.5z"/>
		<path class="st1" d="M74.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,128.5c-0.8-0.2-0.8,3,0,2.8C73.7,131.5,73.7,128.3,72.9,128.5z"/>
		<path class="st1" d="M72.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,128.5c-0.8-0.2-0.8,3,0,2.8C71.7,131.5,71.7,128.3,70.9,128.5z"/>
		<path class="st1" d="M70.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,128.5c-0.8-0.2-0.8,3,0,2.8C69.7,131.5,69.7,128.3,68.9,128.5z"/>
		<path class="st1" d="M68.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,128.5c-0.8-0.2-0.8,3,0,2.8C67.7,131.5,67.7,128.3,66.9,128.5z"/>
		<path class="st1" d="M66.6,129.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,129.2c-0.8-0.2-0.8,3,0,2.8C66.7,132.2,66.7,129.1,65.9,129.2z"/>
		<path class="st1" d="M65.6,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,129.2c-0.8-0.2-0.8,3,0,2.8C64.7,132.2,64.7,129.1,63.9,129.2z"/>
		<path class="st1" d="M63.6,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,129.2c-0.8-0.2-0.8,3,0,2.8C62.7,132.2,62.8,129.1,61.9,129.2z"/>
		<path class="st1" d="M61.6,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,129.2c-0.8-0.2-0.8,3,0,2.8C60.8,132.2,60.8,129.1,59.9,129.2z"/>
		<path class="st1" d="M59.6,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,129.2c-0.8-0.2-0.8,3,0,2.8C58.8,132.2,58.8,129.1,57.9,129.2z"/>
		<path class="st1" d="M57.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,129.2c-0.8-0.2-0.8,3,0,2.8C56.8,132.2,56.8,129.1,56,129.2z"/>
		<path class="st1" d="M55.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,129.2c-0.8-0.2-0.8,3,0,2.8C54.8,132.2,54.8,129.1,54,129.2z"/>
		<path class="st1" d="M53.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,129.2c-0.8-0.2-0.8,3,0,2.8C52.8,132.2,52.8,129.1,52,129.2z"/>
		<path class="st1" d="M51.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,129.2c-0.8-0.2-0.8,3,0,2.8C50.8,132.2,50.8,129.1,50,129.2z"/>
		<path class="st1" d="M49.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,129.2c-0.8-0.2-0.8,3,0,2.8C48.8,132.2,48.8,129.1,48,129.2z"/>
		<path class="st1" d="M47.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,129.2c-0.8-0.2-0.8,3,0,2.8C46.9,132.2,46.9,129.1,46,129.2z"/>
		<path class="st1" d="M45.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,129.2c-0.8-0.2-0.8,3,0,2.8C44.9,132.2,44.9,129.1,44,129.2z"/>
		<path class="st1" d="M43.7,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,129.2c-0.8-0.2-0.8,3,0,2.8C42.9,132.2,42.9,129.1,42.1,129.2z"/>
		<path class="st1" d="M41.8,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,129.2c-0.8-0.2-0.8,3,0,2.8C40.9,132.2,40.9,129.1,40.1,129.2z"/>
		<path class="st1" d="M39.8,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,129.2c-0.8-0.2-0.8,3,0,2.8C38.9,132.2,38.9,129.1,38.1,129.2z"/>
		<path class="st1" d="M37.8,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,129.2c-0.8-0.2-0.8,3,0,2.8C36.9,132.2,36.9,129.1,36.1,129.2z"/>
		<path class="st1" d="M35.8,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,129.2c-0.8-0.2-0.8,3,0,2.8C35,132.2,35,129.1,34.2,129.2z"/>
		<path class="st1" d="M33.9,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,129.2c-0.8-0.2-0.8,3,0,2.8C33.1,132.2,33.1,129.1,32.2,129.2z"/>
		<path class="st1" d="M31.9,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,129.2c-0.8-0.2-0.8,3,0,2.8C31.1,132.2,31.1,129.1,30.2,129.2z"/>
		<path class="st1" d="M30,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,129.2c-0.8-0.2-0.8,3,0,2.8C29.1,132.2,29.1,129.1,28.3,129.2z"/>
		<path class="st1" d="M28,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,129.2c-0.8-0.2-0.8,3,0,2.8C27.1,132.2,27.1,129.1,26.3,129.2z"/>
		<path class="st1" d="M26,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,129.2c-0.8-0.2-0.8,3,0,2.8C25.1,132.2,25.1,129.1,24.3,129.2z"/>
		<path class="st1" d="M24,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,129.2c-0.8-0.2-0.8,3,0,2.8C23.1,132.2,23.1,129.1,22.3,129.2z"/>
		<path class="st1" d="M22,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,129.2c-0.8-0.2-0.8,3,0,2.8C21.1,132.2,21.1,129.1,20.3,129.2z"/>
		<path class="st1" d="M20,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,129.2c-0.8-0.2-0.8,3,0,2.8C19.1,132.2,19.1,129.1,18.3,129.2z"/>
		<path class="st1" d="M18,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,129.2c-0.8-0.2-0.8,3,0,2.8C17.2,132.2,17.2,129.1,16.3,129.2z"/>
		<path class="st1" d="M16,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,129.2c-0.8-0.2-0.8,3,0,2.8C15.2,132.2,15.2,129.1,14.3,129.2z"/>
		<path class="st1" d="M14.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,129.2c-0.8-0.2-0.8,3,0,2.8C13.2,132.2,13.2,129.1,12.4,129.2z"/>
		<path class="st1" d="M12.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,129.2c-0.8-0.2-0.8,3,0,2.8C11.2,132.2,11.2,129.1,10.4,129.2z"/>
		<path class="st1" d="M10.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,129.2c-0.8-0.2-0.8,3,0,2.8C9.2,132.2,9.2,129.1,8.4,129.2z"/>
		<path class="st1" d="M8.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,129.2c-0.8-0.2-0.8,3,0,2.8C7.2,132.2,7.2,129.1,6.4,129.2z"/>
		<path class="st1" d="M6.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,129.2c-0.8-0.2-0.8,3,0,2.8C5.2,132.2,5.2,129.1,4.4,129.2z"/>
		<path class="st1" d="M4.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,129.2c-0.8-0.2-0.8,3,0,2.8C130.1,132.2,130.1,129.1,129.3,129.2z"/>
		<path class="st1" d="M129,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,129.2c-0.8-0.2-0.8,3,0,2.8C128.2,132.2,128.2,129.1,127.3,129.2z"/>
		<path class="st1" d="M127,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,129.2c-0.8-0.2-0.8,3,0,2.8C126.2,132.2,126.2,129.1,125.3,129.2z"/>
		<path class="st1" d="M125.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,129.2c-0.8-0.2-0.8,3,0,2.8C124.2,132.2,124.2,129.1,123.4,129.2z"/>
		<path class="st1" d="M123.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,129.2c-0.8-0.2-0.8,3,0,2.8C122.2,132.2,122.2,129.1,121.4,129.2z"/>
		<path class="st1" d="M121.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,129.2c-0.8-0.2-0.8,3,0,2.8C120.2,132.2,120.2,129.1,119.4,129.2z"/>
		<path class="st1" d="M119.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,129.2c-0.8-0.2-0.8,3,0,2.8C118.2,132.2,118.2,129.1,117.4,129.2z"/>
		<path class="st1" d="M117.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,129.2c-0.8-0.2-0.8,3,0,2.8C116.2,132.2,116.2,129.1,115.4,129.2z"/>
		<path class="st1" d="M115.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,129.2c-0.8-0.2-0.8,3,0,2.8C114.3,132.2,114.3,129.1,113.4,129.2z"/>
		<path class="st1" d="M113.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,129.2c-0.8-0.2-0.8,3,0,2.8C112.3,132.2,112.3,129.1,111.4,129.2z"/>
		<path class="st1" d="M111.1,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,129.2c-0.8-0.2-0.8,3,0,2.8C110.3,132.2,110.3,129.1,109.4,129.2z"/>
		<path class="st1" d="M109.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,129.2c-0.8-0.2-0.8,3,0,2.8C108.3,132.2,108.3,129.1,107.5,129.2z"/>
		<path class="st1" d="M107.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,129.2c-0.8-0.2-0.8,3,0,2.8C106.3,132.2,106.3,129.1,105.5,129.2z"/>
		<path class="st1" d="M105.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,129.2c-0.8-0.2-0.8,3,0,2.8C104.3,132.2,104.3,129.1,103.5,129.2z"/>
		<path class="st1" d="M103.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,129.2c-0.8-0.2-0.8,3,0,2.8C102.3,132.2,102.3,129.1,101.5,129.2z"/>
		<path class="st1" d="M101.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,129.2c-0.8-0.2-0.8,3,0,2.8C100.3,132.2,100.3,129.1,99.5,129.2z"/>
		<path class="st1" d="M99.2,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,129.2c-0.8-0.2-0.8,3,0,2.8C98.5,132.2,98.5,129.1,97.6,129.2z"/>
		<path class="st1" d="M97.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,129.2c-0.8-0.2-0.8,3,0,2.8C96.5,132.2,96.5,129.1,95.7,129.2z"/>
		<path class="st1" d="M95.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,129.2c-0.8-0.2-0.8,3,0,2.8C94.5,132.2,94.5,129.1,93.7,129.2z"/>
		<path class="st1" d="M93.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,129.2c-0.8-0.2-0.8,3,0,2.8C92.5,132.2,92.5,129.1,91.7,129.2z"/>
		<path class="st1" d="M91.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,129.2c-0.8-0.2-0.8,3,0,2.8C90.5,132.2,90.5,129.1,89.7,129.2z"/>
		<path class="st1" d="M89.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,129.2c-0.8-0.2-0.8,3,0,2.8C88.5,132.2,88.5,129.1,87.7,129.2z"/>
		<path class="st1" d="M87.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,129.2c-0.8-0.2-0.8,3,0,2.8C86.5,132.2,86.5,129.1,85.7,129.2z"/>
		<path class="st1" d="M85.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,129.2c-0.8-0.2-0.8,3,0,2.8C84.6,132.2,84.6,129.1,83.7,129.2z"/>
		<path class="st1" d="M83.4,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,129.2c-0.8-0.2-0.8,3,0,2.8C82.6,132.2,82.6,129.1,81.7,129.2z"/>
		<path class="st1" d="M81.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,129.2c-0.8-0.2-0.8,3,0,2.8C80.6,132.2,80.6,129.1,79.8,129.2z"/>
		<path class="st1" d="M79.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,129.2c-0.8-0.2-0.8,3,0,2.8C78.6,132.2,78.6,129.1,77.8,129.2z"/>
		<path class="st1" d="M77.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,129.2c-0.8-0.2-0.8,3,0,2.8C76.6,132.2,76.6,129.1,75.8,129.2z"/>
		<path class="st1" d="M75.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,129.2c-0.8-0.2-0.8,3,0,2.8C74.6,132.2,74.6,129.1,73.8,129.2z"/>
		<path class="st1" d="M73.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,129.2c-0.8-0.2-0.8,3,0,2.8C72.6,132.2,72.6,129.1,71.8,129.2z"/>
		<path class="st1" d="M71.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,129.2c-0.8-0.2-0.8,3,0,2.8C70.7,132.2,70.7,129.1,69.8,129.2z"/>
		<path class="st1" d="M69.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,129.2c-0.8-0.2-0.8,3,0,2.8C68.7,132.2,68.7,129.1,67.8,129.2z"/>
		<path class="st1" d="M67.5,130.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,130.1c-0.8-0.2-0.8,3,0,2.8C65.8,133.1,65.8,130,65,130.1z"/>
		<path class="st1" d="M64.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,130.1c-0.8-0.2-0.8,3,0,2.8C63.8,133.1,63.8,130,63,130.1z"/>
		<path class="st1" d="M62.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,130.1c-0.8-0.2-0.8,3,0,2.8C61.8,133.1,61.8,130,61,130.1z"/>
		<path class="st1" d="M60.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,130.1c-0.8-0.2-0.8,3,0,2.8C59.8,133.1,59.8,130,59,130.1z"/>
		<path class="st1" d="M58.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,130.1c-0.8-0.2-0.8,3,0,2.8C57.9,133.1,57.9,130,57,130.1z"/>
		<path class="st1" d="M56.7,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,130.1c-0.8-0.2-0.8,3,0,2.8C55.9,133.1,55.9,130,55,130.1z"/>
		<path class="st1" d="M54.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,130.1c-0.8-0.2-0.8,3,0,2.8C53.9,133.1,53.9,130,53.1,130.1z"/>
		<path class="st1" d="M52.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,130.1c-0.8-0.2-0.8,3,0,2.8C51.9,133.1,51.9,130,51.1,130.1z"/>
		<path class="st1" d="M50.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,130.1c-0.8-0.2-0.8,3,0,2.8C49.9,133.1,49.9,130,49.1,130.1z"/>
		<path class="st1" d="M48.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,130.1c-0.8-0.2-0.8,3,0,2.8C47.9,133.1,47.9,130,47.1,130.1z"/>
		<path class="st1" d="M46.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,130.1c-0.8-0.2-0.8,3,0,2.8C45.9,133.1,45.9,130,45.1,130.1z"/>
		<path class="st1" d="M44.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,130.1c-0.8-0.2-0.8,3,0,2.8C43.9,133.1,43.9,130,43.1,130.1z"/>
		<path class="st1" d="M42.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,130.1c-0.8-0.2-0.8,3,0,2.8C42,133.1,42,130,41.1,130.1z"/>
		<path class="st1" d="M40.8,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,130.1c-0.8-0.2-0.8,3,0,2.8C40,133.1,40,130,39.1,130.1z"/>
		<path class="st1" d="M38.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,130.1c-0.8-0.2-0.8,3,0,2.8C38,133.1,38,130,37.2,130.1z"/>
		<path class="st1" d="M36.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,130.1c-0.8-0.2-0.8,3,0,2.8C36,133.1,36,130,35.2,130.1z"/>
		<path class="st1" d="M34.9,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,130.1c-0.8-0.2-0.8,3,0,2.8C34.1,133.1,34.1,130,33.3,130.1z"/>
		<path class="st1" d="M33,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,130.1c-0.8-0.2-0.8,3,0,2.8C32.1,133.1,32.1,130,31.3,130.1z"/>
		<path class="st1" d="M31,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,130.1c-0.8-0.2-0.8,3,0,2.8C30.1,133.1,30.2,130,29.3,130.1z"/>
		<path class="st1" d="M29,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,130.1c-0.8-0.2-0.8,3,0,2.8C28.2,133.1,28.2,130,27.3,130.1z"/>
		<path class="st1" d="M27,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,130.1c-0.8-0.2-0.8,3,0,2.8C26.2,133.1,26.2,130,25.3,130.1z"/>
		<path class="st1" d="M25.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,130.1c-0.8-0.2-0.8,3,0,2.8C24.2,133.1,24.2,130,23.4,130.1z"/>
		<path class="st1" d="M23.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,130.1c-0.8-0.2-0.8,3,0,2.8C22.2,133.1,22.2,130,21.4,130.1z"/>
		<path class="st1" d="M21.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,130.1c-0.8-0.2-0.8,3,0,2.8C20.2,133.1,20.2,130,19.4,130.1z"/>
		<path class="st1" d="M19.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,130.1c-0.8-0.2-0.8,3,0,2.8C18.2,133.1,18.2,130,17.4,130.1z"/>
		<path class="st1" d="M17.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,130.1c-0.8-0.2-0.8,3,0,2.8C16.2,133.1,16.2,130,15.4,130.1z"/>
		<path class="st1" d="M15.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,130.1c-0.8-0.2-0.8,3,0,2.8C14.3,133.1,14.3,130,13.4,130.1z"/>
		<path class="st1" d="M13.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,130.1c-0.8-0.2-0.8,3,0,2.8C12.3,133.1,12.3,130,11.4,130.1z"/>
		<path class="st1" d="M11.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,130.1c-0.8-0.2-0.8,3,0,2.8C10.3,133.1,10.3,130,9.5,130.1z"/>
		<path class="st1" d="M9.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,130.1c-0.8-0.2-0.8,3,0,2.8C8.3,133.1,8.3,130,7.5,130.1z"/>
		<path class="st1" d="M7.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,130.1c-0.8-0.2-0.8,3,0,2.8C6.3,133.1,6.3,130,5.5,130.1z"/>
		<path class="st1" d="M5.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,130.1c-0.8-0.2-0.8,3,0,2.8C4.3,133.1,4.3,130,3.5,130.1z"/>
		<path class="st1" d="M3.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,130.1c-0.8-0.2-0.8,3,0,2.8C129.2,133.1,129.2,130,128.4,130.1z"/>
		<path class="st1" d="M128.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,130.1c-0.8-0.2-0.8,3,0,2.8C127.2,133.1,127.2,130,126.4,130.1z"/>
		<path class="st1" d="M126.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,130.1c-0.8-0.2-0.8,3,0,2.8C125.3,133.1,125.3,130,124.4,130.1z"/>
		<path class="st1" d="M124.1,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,130.1c-0.8-0.2-0.8,3,0,2.8C123.3,133.1,123.3,130,122.4,130.1z"/>
		<path class="st1" d="M122.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,130.1c-0.8-0.2-0.8,3,0,2.8C121.3,133.1,121.3,130,120.5,130.1z"/>
		<path class="st1" d="M120.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,130.1c-0.8-0.2-0.8,3,0,2.8C119.3,133.1,119.3,130,118.5,130.1z"/>
		<path class="st1" d="M118.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,130.1c-0.8-0.2-0.8,3,0,2.8C117.3,133.1,117.3,130,116.5,130.1z"/>
		<path class="st1" d="M116.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,130.1c-0.8-0.2-0.8,3,0,2.8C115.3,133.1,115.3,130,114.5,130.1z"/>
		<path class="st1" d="M114.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,130.1c-0.8-0.2-0.8,3,0,2.8C113.3,133.1,113.3,130,112.5,130.1z"/>
		<path class="st1" d="M112.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,130.1c-0.8-0.2-0.8,3,0,2.8C111.3,133.1,111.3,130,110.5,130.1z"/>
		<path class="st1" d="M110.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,130.1c-0.8-0.2-0.8,3,0,2.8C109.4,133.1,109.4,130,108.5,130.1z"/>
		<path class="st1" d="M108.2,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,130.1c-0.8-0.2-0.8,3,0,2.8C107.4,133.1,107.4,130,106.5,130.1z"/>
		<path class="st1" d="M106.3,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,130.1c-0.8-0.2-0.8,3,0,2.8C105.4,133.1,105.4,130,104.6,130.1z"/>
		<path class="st1" d="M104.3,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,130.1c-0.8-0.2-0.8,3,0,2.8C103.4,133.1,103.4,130,102.6,130.1z"/>
		<path class="st1" d="M102.3,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,130.1c-0.8-0.2-0.8,3,0,2.8C101.4,133.1,101.4,130,100.6,130.1z"/>
		<path class="st1" d="M100.3,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,130.1c-0.8-0.2-0.8,3,0,2.8C99.4,133.1,99.4,130,98.6,130.1z"/>
		<path class="st1" d="M98.3,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,130.1c-0.8-0.2-0.8,3,0,2.8C97.5,133.1,97.6,130,96.7,130.1z"/>
		<path class="st1" d="M96.4,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,130.1c-0.8-0.2-0.8,3,0,2.8C95.6,133.1,95.6,130,94.7,130.1z"/>
		<path class="st1" d="M94.4,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,130.1c-0.8-0.2-0.8,3,0,2.8C93.6,133.1,93.6,130,92.7,130.1z"/>
		<path class="st1" d="M92.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,130.1c-0.8-0.2-0.8,3,0,2.8C91.6,133.1,91.6,130,90.8,130.1z"/>
		<path class="st1" d="M90.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,130.1c-0.8-0.2-0.8,3,0,2.8C89.6,133.1,89.6,130,88.8,130.1z"/>
		<path class="st1" d="M88.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,130.1c-0.8-0.2-0.8,3,0,2.8C87.6,133.1,87.6,130,86.8,130.1z"/>
		<path class="st1" d="M86.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,130.1c-0.8-0.2-0.8,3,0,2.8C85.6,133.1,85.6,130,84.8,130.1z"/>
		<path class="st1" d="M84.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,130.1c-0.8-0.2-0.8,3,0,2.8C83.6,133.1,83.6,130,82.8,130.1z"/>
		<path class="st1" d="M82.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,130.1c-0.8-0.2-0.8,3,0,2.8C81.7,133.1,81.7,130,80.8,130.1z"/>
		<path class="st1" d="M80.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,130.1c-0.8-0.2-0.8,3,0,2.8C79.7,133.1,79.7,130,78.8,130.1z"/>
		<path class="st1" d="M78.5,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,130.1c-0.8-0.2-0.8,3,0,2.8C77.7,133.1,77.7,130,76.9,130.1z"/>
		<path class="st1" d="M76.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,130.1c-0.8-0.2-0.8,3,0,2.8C75.7,133.1,75.7,130,74.9,130.1z"/>
		<path class="st1" d="M74.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,130.1c-0.8-0.2-0.8,3,0,2.8C73.7,133.1,73.7,130,72.9,130.1z"/>
		<path class="st1" d="M72.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,130.1c-0.8-0.2-0.8,3,0,2.8C71.7,133.1,71.7,130,70.9,130.1z"/>
		<path class="st1" d="M70.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,130.1c-0.8-0.2-0.8,3,0,2.8C69.7,133.1,69.7,130,68.9,130.1z"/>
		<path class="st1" d="M68.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,130.1c-0.8-0.2-0.8,3,0,2.8C67.7,133.1,67.7,130,66.9,130.1z"/>
		<path class="st1" d="M66.6,131c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,130.9c-0.8-0.2-0.8,3,0,2.8C66.7,133.9,66.7,130.7,65.9,130.9z"/>
		<path class="st1" d="M65.6,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,130.9c-0.8-0.2-0.8,3,0,2.8C64.7,133.9,64.7,130.7,63.9,130.9z"/>
		<path class="st1" d="M63.6,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,130.9c-0.8-0.2-0.8,3,0,2.8C62.7,133.9,62.8,130.7,61.9,130.9z"/>
		<path class="st1" d="M61.6,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,130.9c-0.8-0.2-0.8,3,0,2.8C60.8,133.9,60.8,130.7,59.9,130.9z"/>
		<path class="st1" d="M59.6,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,130.9c-0.8-0.2-0.8,3,0,2.8C58.8,133.9,58.8,130.7,57.9,130.9z"/>
		<path class="st1" d="M57.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,130.9c-0.8-0.2-0.8,3,0,2.8C56.8,133.9,56.8,130.7,56,130.9z"/>
		<path class="st1" d="M55.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,130.9c-0.8-0.2-0.8,3,0,2.8C54.8,133.9,54.8,130.7,54,130.9z"/>
		<path class="st1" d="M53.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,130.9c-0.8-0.2-0.8,3,0,2.8C52.8,133.9,52.8,130.7,52,130.9z"/>
		<path class="st1" d="M51.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,130.9c-0.8-0.2-0.8,3,0,2.8C50.8,133.9,50.8,130.7,50,130.9z"/>
		<path class="st1" d="M49.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,130.9c-0.8-0.2-0.8,3,0,2.8C48.8,133.9,48.8,130.7,48,130.9z"/>
		<path class="st1" d="M47.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,130.9c-0.8-0.2-0.8,3,0,2.8C46.9,133.9,46.9,130.7,46,130.9z"/>
		<path class="st1" d="M45.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,130.9c-0.8-0.2-0.8,3,0,2.8C44.9,133.9,44.9,130.7,44,130.9z"/>
		<path class="st1" d="M43.7,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,130.9c-0.8-0.2-0.8,3,0,2.8C42.9,133.9,42.9,130.7,42.1,130.9z"/>
		<path class="st1" d="M41.8,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,130.9c-0.8-0.2-0.8,3,0,2.8C40.9,133.9,40.9,130.7,40.1,130.9z"/>
		<path class="st1" d="M39.8,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,130.9c-0.8-0.2-0.8,3,0,2.8C38.9,133.9,38.9,130.7,38.1,130.9z"/>
		<path class="st1" d="M37.8,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,130.9c-0.8-0.2-0.8,3,0,2.8C36.9,133.9,36.9,130.7,36.1,130.9z"/>
		<path class="st1" d="M35.8,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,130.9c-0.8-0.2-0.8,3,0,2.8C35,133.9,35,130.7,34.2,130.9z"/>
		<path class="st1" d="M33.9,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,130.9c-0.8-0.2-0.8,3,0,2.8C33.1,133.9,33.1,130.7,32.2,130.9z"/>
		<path class="st1" d="M31.9,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,130.9c-0.8-0.2-0.8,3,0,2.8C31.1,133.9,31.1,130.7,30.2,130.9z"/>
		<path class="st1" d="M30,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,130.9c-0.8-0.2-0.8,3,0,2.8C29.1,133.9,29.1,130.7,28.3,130.9z"/>
		<path class="st1" d="M28,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,130.9c-0.8-0.2-0.8,3,0,2.8C27.1,133.9,27.1,130.7,26.3,130.9z"/>
		<path class="st1" d="M26,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,130.9c-0.8-0.2-0.8,3,0,2.8C25.1,133.9,25.1,130.7,24.3,130.9z"/>
		<path class="st1" d="M24,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,130.9c-0.8-0.2-0.8,3,0,2.8C23.1,133.9,23.1,130.7,22.3,130.9z"/>
		<path class="st1" d="M22,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,130.9c-0.8-0.2-0.8,3,0,2.8C21.1,133.9,21.1,130.7,20.3,130.9z"/>
		<path class="st1" d="M20,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,130.9c-0.8-0.2-0.8,3,0,2.8C19.1,133.9,19.1,130.7,18.3,130.9z"/>
		<path class="st1" d="M18,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,130.9c-0.8-0.2-0.8,3,0,2.8C17.2,133.9,17.2,130.7,16.3,130.9z"/>
		<path class="st1" d="M16,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,130.9c-0.8-0.2-0.8,3,0,2.8C15.2,133.9,15.2,130.7,14.3,130.9z"/>
		<path class="st1" d="M14.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,130.9c-0.8-0.2-0.8,3,0,2.8C13.2,133.9,13.2,130.7,12.4,130.9z"/>
		<path class="st1" d="M12.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,130.9c-0.8-0.2-0.8,3,0,2.8C11.2,133.9,11.2,130.7,10.4,130.9z"/>
		<path class="st1" d="M10.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,130.9c-0.8-0.2-0.8,3,0,2.8C9.2,133.9,9.2,130.7,8.4,130.9z"/>
		<path class="st1" d="M8.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,130.9c-0.8-0.2-0.8,3,0,2.8C7.2,133.9,7.2,130.7,6.4,130.9z"/>
		<path class="st1" d="M6.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,130.9c-0.8-0.2-0.8,3,0,2.8C5.2,133.9,5.2,130.7,4.4,130.9z"/>
		<path class="st1" d="M4.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,130.9c-0.8-0.2-0.8,3,0,2.8C130.1,133.9,130.1,130.7,129.3,130.9z"/>
		<path class="st1" d="M129,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,130.9c-0.8-0.2-0.8,3,0,2.8C128.2,133.9,128.2,130.7,127.3,130.9z"/>
		<path class="st1" d="M127,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,130.9c-0.8-0.2-0.8,3,0,2.8C126.2,133.9,126.2,130.7,125.3,130.9z"/>
		<path class="st1" d="M125.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,130.9c-0.8-0.2-0.8,3,0,2.8C124.2,133.9,124.2,130.7,123.4,130.9z"/>
		<path class="st1" d="M123.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,130.9c-0.8-0.2-0.8,3,0,2.8C122.2,133.9,122.2,130.7,121.4,130.9z"/>
		<path class="st1" d="M121.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,130.9c-0.8-0.2-0.8,3,0,2.8C120.2,133.9,120.2,130.7,119.4,130.9z"/>
		<path class="st1" d="M119.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,130.9c-0.8-0.2-0.8,3,0,2.8C118.2,133.9,118.2,130.7,117.4,130.9z"/>
		<path class="st1" d="M117.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,130.9c-0.8-0.2-0.8,3,0,2.8C116.2,133.9,116.2,130.7,115.4,130.9z"/>
		<path class="st1" d="M115.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,130.9c-0.8-0.2-0.8,3,0,2.8C114.3,133.9,114.3,130.7,113.4,130.9z"/>
		<path class="st1" d="M113.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,130.9c-0.8-0.2-0.8,3,0,2.8C112.3,133.9,112.3,130.7,111.4,130.9z"/>
		<path class="st1" d="M111.1,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,130.9c-0.8-0.2-0.8,3,0,2.8C110.3,133.9,110.3,130.7,109.4,130.9z"/>
		<path class="st1" d="M109.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,130.9c-0.8-0.2-0.8,3,0,2.8C108.3,133.9,108.3,130.7,107.5,130.9z"/>
		<path class="st1" d="M107.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,130.9c-0.8-0.2-0.8,3,0,2.8C106.3,133.9,106.3,130.7,105.5,130.9z"/>
		<path class="st1" d="M105.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,130.9c-0.8-0.2-0.8,3,0,2.8C104.3,133.9,104.3,130.7,103.5,130.9z"/>
		<path class="st1" d="M103.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,130.9c-0.8-0.2-0.8,3,0,2.8C102.3,133.9,102.3,130.7,101.5,130.9z"/>
		<path class="st1" d="M101.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,130.9c-0.8-0.2-0.8,3,0,2.8C100.3,133.9,100.3,130.7,99.5,130.9z"/>
		<path class="st1" d="M99.2,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,130.9c-0.8-0.2-0.8,3,0,2.8C98.5,133.9,98.5,130.7,97.6,130.9z"/>
		<path class="st1" d="M97.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,130.9c-0.8-0.2-0.8,3,0,2.8C96.5,133.9,96.5,130.7,95.7,130.9z"/>
		<path class="st1" d="M95.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,130.9c-0.8-0.2-0.8,3,0,2.8C94.5,133.9,94.5,130.7,93.7,130.9z"/>
		<path class="st1" d="M93.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,130.9c-0.8-0.2-0.8,3,0,2.8C92.5,133.9,92.5,130.7,91.7,130.9z"/>
		<path class="st1" d="M91.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,130.9c-0.8-0.2-0.8,3,0,2.8C90.5,133.9,90.5,130.7,89.7,130.9z"/>
		<path class="st1" d="M89.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,130.9c-0.8-0.2-0.8,3,0,2.8C88.5,133.9,88.5,130.7,87.7,130.9z"/>
		<path class="st1" d="M87.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,130.9c-0.8-0.2-0.8,3,0,2.8C86.5,133.9,86.5,130.7,85.7,130.9z"/>
		<path class="st1" d="M85.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,130.9c-0.8-0.2-0.8,3,0,2.8C84.6,133.9,84.6,130.7,83.7,130.9z"/>
		<path class="st1" d="M83.4,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,130.9c-0.8-0.2-0.8,3,0,2.8C82.6,133.9,82.6,130.7,81.7,130.9z"/>
		<path class="st1" d="M81.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,130.9c-0.8-0.2-0.8,3,0,2.8C80.6,133.9,80.6,130.7,79.8,130.9z"/>
		<path class="st1" d="M79.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,130.9c-0.8-0.2-0.8,3,0,2.8C78.6,133.9,78.6,130.7,77.8,130.9z"/>
		<path class="st1" d="M77.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,130.9c-0.8-0.2-0.8,3,0,2.8C76.6,133.9,76.6,130.7,75.8,130.9z"/>
		<path class="st1" d="M75.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,130.9c-0.8-0.2-0.8,3,0,2.8C74.6,133.9,74.6,130.7,73.8,130.9z"/>
		<path class="st1" d="M73.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,130.9c-0.8-0.2-0.8,3,0,2.8C72.6,133.9,72.6,130.7,71.8,130.9z"/>
		<path class="st1" d="M71.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,130.9c-0.8-0.2-0.8,3,0,2.8C70.7,133.9,70.7,130.7,69.8,130.9z"/>
		<path class="st1" d="M69.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,130.9c-0.8-0.2-0.8,3,0,2.8C68.7,133.9,68.7,130.7,67.8,130.9z"/>
		<path class="st1" d="M67.5,131.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,131.8c-0.8-0.2-0.8,3,0,2.8C65.8,134.8,65.8,131.7,65,131.8z"/>
		<path class="st1" d="M64.7,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,131.8c-0.8-0.2-0.8,3,0,2.8C63.8,134.8,63.8,131.7,63,131.8z"/>
		<path class="st1" d="M62.7,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,131.8c-0.8-0.2-0.8,3,0,2.8C61.8,134.8,61.8,131.7,61,131.8z"/>
		<path class="st1" d="M60.7,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,131.8c-0.8-0.2-0.8,3,0,2.8C59.8,134.8,59.8,131.7,59,131.8z"/>
		<path class="st1" d="M58.7,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,131.8c-0.8-0.2-0.8,3,0,2.8C57.9,134.8,57.9,131.7,57,131.8z"/>
		<path class="st1" d="M56.7,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,131.8c-0.8-0.2-0.8,3,0,2.8C55.9,134.8,55.9,131.7,55,131.8z"/>
		<path class="st1" d="M54.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,131.8c-0.8-0.2-0.8,3,0,2.8C53.9,134.8,53.9,131.7,53.1,131.8z"/>
		<path class="st1" d="M52.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,131.8c-0.8-0.2-0.8,3,0,2.8C51.9,134.8,51.9,131.7,51.1,131.8z"/>
		<path class="st1" d="M50.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,131.8c-0.8-0.2-0.8,3,0,2.8C49.9,134.8,49.9,131.7,49.1,131.8z"/>
		<path class="st1" d="M48.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,131.8c-0.8-0.2-0.8,3,0,2.8C47.9,134.8,47.9,131.7,47.1,131.8z"/>
		<path class="st1" d="M46.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,131.8c-0.8-0.2-0.8,3,0,2.8C45.9,134.8,45.9,131.7,45.1,131.8z"/>
		<path class="st1" d="M44.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,131.8c-0.8-0.2-0.8,3,0,2.8C43.9,134.8,43.9,131.7,43.1,131.8z"/>
		<path class="st1" d="M42.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,131.8c-0.8-0.2-0.8,3,0,2.8C42,134.8,42,131.7,41.1,131.8z"/>
		<path class="st1" d="M40.8,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,131.8c-0.8-0.2-0.8,3,0,2.8C40,134.8,40,131.7,39.1,131.8z"/>
		<path class="st1" d="M38.9,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,131.8c-0.8-0.2-0.8,3,0,2.8C38,134.8,38,131.7,37.2,131.8z"/>
		<path class="st1" d="M36.9,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,131.8c-0.8-0.2-0.8,3,0,2.8C36,134.8,36,131.7,35.2,131.8z"/>
		<path class="st1" d="M34.9,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,131.8c-0.8-0.2-0.8,3,0,2.8C34.1,134.8,34.1,131.7,33.3,131.8z"/>
		<path class="st1" d="M33,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,131.8c-0.8-0.2-0.8,3,0,2.8C32.1,134.8,32.1,131.7,31.3,131.8z"/>
		<path class="st1" d="M31,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,131.8c-0.8-0.2-0.8,3,0,2.8C30.1,134.8,30.2,131.7,29.3,131.8z"/>
		<path class="st1" d="M29,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,131.8c-0.8-0.2-0.8,3,0,2.8C28.2,134.8,28.2,131.7,27.3,131.8z"/>
		<path class="st1" d="M27,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,131.8c-0.8-0.2-0.8,3,0,2.8C26.2,134.8,26.2,131.7,25.3,131.8z"/>
		<path class="st1" d="M25.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,131.8c-0.8-0.2-0.8,3,0,2.8C24.2,134.8,24.2,131.7,23.4,131.8z"/>
		<path class="st1" d="M23.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,131.8c-0.8-0.2-0.8,3,0,2.8C22.2,134.8,22.2,131.7,21.4,131.8z"/>
		<path class="st1" d="M21.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,131.8c-0.8-0.2-0.8,3,0,2.8C20.2,134.8,20.2,131.7,19.4,131.8z"/>
		<path class="st1" d="M19.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,131.8c-0.8-0.2-0.8,3,0,2.8C18.2,134.8,18.2,131.7,17.4,131.8z"/>
		<path class="st1" d="M17.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,131.8c-0.8-0.2-0.8,3,0,2.8C16.2,134.8,16.2,131.7,15.4,131.8z"/>
		<path class="st1" d="M15.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,131.8c-0.8-0.2-0.8,3,0,2.8C14.3,134.8,14.3,131.7,13.4,131.8z"/>
		<path class="st1" d="M13.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,131.8c-0.8-0.2-0.8,3,0,2.8C12.3,134.8,12.3,131.7,11.4,131.8z"/>
		<path class="st1" d="M11.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,131.8c-0.8-0.2-0.8,3,0,2.8C10.3,134.8,10.3,131.7,9.5,131.8z"/>
		<path class="st1" d="M9.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,131.8c-0.8-0.2-0.8,3,0,2.8C8.3,134.8,8.3,131.7,7.5,131.8z"/>
		<path class="st1" d="M7.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,131.8c-0.8-0.2-0.8,3,0,2.8C6.3,134.8,6.3,131.7,5.5,131.8z"/>
		<path class="st1" d="M5.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,131.8c-0.8-0.2-0.8,3,0,2.8C4.3,134.8,4.3,131.7,3.5,131.8z"/>
		<path class="st1" d="M3.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,131.8c-0.8-0.2-0.8,3,0,2.8C129.2,134.8,129.2,131.7,128.4,131.8z"/>
		<path class="st1" d="M128.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,131.8c-0.8-0.2-0.8,3,0,2.8C127.2,134.8,127.2,131.7,126.4,131.8z"/>
		<path class="st1" d="M126.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,131.8c-0.8-0.2-0.8,3,0,2.8C125.3,134.8,125.3,131.7,124.4,131.8z"/>
		<path class="st1" d="M124.1,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,131.8c-0.8-0.2-0.8,3,0,2.8C123.3,134.8,123.3,131.7,122.4,131.8z"/>
		<path class="st1" d="M122.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,131.8c-0.8-0.2-0.8,3,0,2.8C121.3,134.8,121.3,131.7,120.5,131.8z"/>
		<path class="st1" d="M120.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,131.8c-0.8-0.2-0.8,3,0,2.8C119.3,134.8,119.3,131.7,118.5,131.8z"/>
		<path class="st1" d="M118.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,131.8c-0.8-0.2-0.8,3,0,2.8C117.3,134.8,117.3,131.7,116.5,131.8z"/>
		<path class="st1" d="M116.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,131.8c-0.8-0.2-0.8,3,0,2.8C115.3,134.8,115.3,131.7,114.5,131.8z"/>
		<path class="st1" d="M114.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,131.8c-0.8-0.2-0.8,3,0,2.8C113.3,134.8,113.3,131.7,112.5,131.8z"/>
		<path class="st1" d="M112.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,131.8c-0.8-0.2-0.8,3,0,2.8C111.3,134.8,111.3,131.7,110.5,131.8z"/>
		<path class="st1" d="M110.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,131.8c-0.8-0.2-0.8,3,0,2.8C109.4,134.8,109.4,131.7,108.5,131.8z"/>
		<path class="st1" d="M108.2,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,131.8c-0.8-0.2-0.8,3,0,2.8C107.4,134.8,107.4,131.7,106.5,131.8z"/>
		<path class="st1" d="M106.3,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,131.8c-0.8-0.2-0.8,3,0,2.8C105.4,134.8,105.4,131.7,104.6,131.8z"/>
		<path class="st1" d="M104.3,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,131.8c-0.8-0.2-0.8,3,0,2.8C103.4,134.8,103.4,131.7,102.6,131.8z"/>
		<path class="st1" d="M102.3,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,131.8c-0.8-0.2-0.8,3,0,2.8C101.4,134.8,101.4,131.7,100.6,131.8z"/>
		<path class="st1" d="M100.3,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,131.8c-0.8-0.2-0.8,3,0,2.8C99.4,134.8,99.4,131.7,98.6,131.8z"/>
		<path class="st1" d="M98.3,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,131.8c-0.8-0.2-0.8,3,0,2.8C97.5,134.8,97.6,131.7,96.7,131.8z"/>
		<path class="st1" d="M96.4,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,131.8c-0.8-0.2-0.8,3,0,2.8C95.6,134.8,95.6,131.7,94.7,131.8z"/>
		<path class="st1" d="M94.4,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,131.8c-0.8-0.2-0.8,3,0,2.8C93.6,134.8,93.6,131.7,92.7,131.8z"/>
		<path class="st1" d="M92.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,131.8c-0.8-0.2-0.8,3,0,2.8C91.6,134.8,91.6,131.7,90.8,131.8z"/>
		<path class="st1" d="M90.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,131.8c-0.8-0.2-0.8,3,0,2.8C89.6,134.8,89.6,131.7,88.8,131.8z"/>
		<path class="st1" d="M88.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,131.8c-0.8-0.2-0.8,3,0,2.8C87.6,134.8,87.6,131.7,86.8,131.8z"/>
		<path class="st1" d="M86.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,131.8c-0.8-0.2-0.8,3,0,2.8C85.6,134.8,85.6,131.7,84.8,131.8z"/>
		<path class="st1" d="M84.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,131.8c-0.8-0.2-0.8,3,0,2.8C83.6,134.8,83.6,131.7,82.8,131.8z"/>
		<path class="st1" d="M82.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,131.8c-0.8-0.2-0.8,3,0,2.8C81.7,134.8,81.7,131.7,80.8,131.8z"/>
		<path class="st1" d="M80.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,131.8c-0.8-0.2-0.8,3,0,2.8C79.7,134.8,79.7,131.7,78.8,131.8z"/>
		<path class="st1" d="M78.5,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,131.8c-0.8-0.2-0.8,3,0,2.8C77.7,134.8,77.7,131.7,76.9,131.8z"/>
		<path class="st1" d="M76.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,131.8c-0.8-0.2-0.8,3,0,2.8C75.7,134.8,75.7,131.7,74.9,131.8z"/>
		<path class="st1" d="M74.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,131.8c-0.8-0.2-0.8,3,0,2.8C73.7,134.8,73.7,131.7,72.9,131.8z"/>
		<path class="st1" d="M72.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,131.8c-0.8-0.2-0.8,3,0,2.8C71.7,134.8,71.7,131.7,70.9,131.8z"/>
		<path class="st1" d="M70.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,131.8c-0.8-0.2-0.8,3,0,2.8C69.7,134.8,69.7,131.7,68.9,131.8z"/>
		<path class="st1" d="M68.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,131.8c-0.8-0.2-0.8,3,0,2.8C67.7,134.8,67.7,131.7,66.9,131.8z"/>
		<path class="st1" d="M66.6,132.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,132.6c-0.8-0.2-0.8,3,0,2.8C66.7,135.6,66.7,132.4,65.9,132.6z"/>
		<path class="st1" d="M65.6,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,132.6c-0.8-0.2-0.8,3,0,2.8C64.7,135.6,64.7,132.4,63.9,132.6z"/>
		<path class="st1" d="M63.6,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,132.6c-0.8-0.2-0.8,3,0,2.8C62.7,135.6,62.8,132.4,61.9,132.6z"/>
		<path class="st1" d="M61.6,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,132.6c-0.8-0.2-0.8,3,0,2.8C60.8,135.6,60.8,132.4,59.9,132.6z"/>
		<path class="st1" d="M59.6,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,132.6c-0.8-0.2-0.8,3,0,2.8C58.8,135.6,58.8,132.4,57.9,132.6z"/>
		<path class="st1" d="M57.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,132.6c-0.8-0.2-0.8,3,0,2.8C56.8,135.6,56.8,132.4,56,132.6z"/>
		<path class="st1" d="M55.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,132.6c-0.8-0.2-0.8,3,0,2.8C54.8,135.6,54.8,132.4,54,132.6z"/>
		<path class="st1" d="M53.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,132.6c-0.8-0.2-0.8,3,0,2.8C52.8,135.6,52.8,132.4,52,132.6z"/>
		<path class="st1" d="M51.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,132.6c-0.8-0.2-0.8,3,0,2.8C50.8,135.6,50.8,132.4,50,132.6z"/>
		<path class="st1" d="M49.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,132.6c-0.8-0.2-0.8,3,0,2.8C48.8,135.6,48.8,132.4,48,132.6z"/>
		<path class="st1" d="M47.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,132.6c-0.8-0.2-0.8,3,0,2.8C46.9,135.6,46.9,132.4,46,132.6z"/>
		<path class="st1" d="M45.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,132.6c-0.8-0.2-0.8,3,0,2.8C44.9,135.6,44.9,132.4,44,132.6z"/>
		<path class="st1" d="M43.7,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,132.6c-0.8-0.2-0.8,3,0,2.8C42.9,135.6,42.9,132.4,42.1,132.6z"/>
		<path class="st1" d="M41.8,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,132.6c-0.8-0.2-0.8,3,0,2.8C40.9,135.6,40.9,132.4,40.1,132.6z"/>
		<path class="st1" d="M39.8,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,132.6c-0.8-0.2-0.8,3,0,2.8C38.9,135.6,38.9,132.4,38.1,132.6z"/>
		<path class="st1" d="M37.8,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,132.6c-0.8-0.2-0.8,3,0,2.8C36.9,135.6,36.9,132.4,36.1,132.6z"/>
		<path class="st1" d="M35.8,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,132.6c-0.8-0.2-0.8,3,0,2.8C35,135.6,35,132.4,34.2,132.6z"/>
		<path class="st1" d="M33.9,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,132.6c-0.8-0.2-0.8,3,0,2.8C33.1,135.6,33.1,132.4,32.2,132.6z"/>
		<path class="st1" d="M31.9,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,132.6c-0.8-0.2-0.8,3,0,2.8C31.1,135.6,31.1,132.4,30.2,132.6z"/>
		<path class="st1" d="M30,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,132.6c-0.8-0.2-0.8,3,0,2.8C29.1,135.6,29.1,132.4,28.3,132.6z"/>
		<path class="st1" d="M28,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,132.6c-0.8-0.2-0.8,3,0,2.8C27.1,135.6,27.1,132.4,26.3,132.6z"/>
		<path class="st1" d="M26,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,132.6c-0.8-0.2-0.8,3,0,2.8C25.1,135.6,25.1,132.4,24.3,132.6z"/>
		<path class="st1" d="M24,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,132.6c-0.8-0.2-0.8,3,0,2.8C23.1,135.6,23.1,132.4,22.3,132.6z"/>
		<path class="st1" d="M22,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,132.6c-0.8-0.2-0.8,3,0,2.8C21.1,135.6,21.1,132.4,20.3,132.6z"/>
		<path class="st1" d="M20,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,132.6c-0.8-0.2-0.8,3,0,2.8C19.1,135.6,19.1,132.4,18.3,132.6z"/>
		<path class="st1" d="M18,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,132.6c-0.8-0.2-0.8,3,0,2.8C17.2,135.6,17.2,132.4,16.3,132.6z"/>
		<path class="st1" d="M16,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,132.6c-0.8-0.2-0.8,3,0,2.8C15.2,135.6,15.2,132.4,14.3,132.6z"/>
		<path class="st1" d="M14.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,132.6c-0.8-0.2-0.8,3,0,2.8C13.2,135.6,13.2,132.4,12.4,132.6z"/>
		<path class="st1" d="M12.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,132.6c-0.8-0.2-0.8,3,0,2.8C11.2,135.6,11.2,132.4,10.4,132.6z"/>
		<path class="st1" d="M10.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,132.6c-0.8-0.2-0.8,3,0,2.8C9.2,135.6,9.2,132.4,8.4,132.6z"/>
		<path class="st1" d="M8.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,132.6c-0.8-0.2-0.8,3,0,2.8C7.2,135.6,7.2,132.4,6.4,132.6z"/>
		<path class="st1" d="M6.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,132.6c-0.8-0.2-0.8,3,0,2.8C5.2,135.6,5.2,132.4,4.4,132.6z"/>
		<path class="st1" d="M4.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,132.6c-0.8-0.2-0.8,3,0,2.8C130.1,135.6,130.1,132.4,129.3,132.6z"/>
		<path class="st1" d="M129,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,132.6c-0.8-0.2-0.8,3,0,2.8C128.2,135.6,128.2,132.4,127.3,132.6z"/>
		<path class="st1" d="M127,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,132.6c-0.8-0.2-0.8,3,0,2.8C126.2,135.6,126.2,132.4,125.3,132.6z"/>
		<path class="st1" d="M125.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,132.6c-0.8-0.2-0.8,3,0,2.8C124.2,135.6,124.2,132.4,123.4,132.6z"/>
		<path class="st1" d="M123.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,132.6c-0.8-0.2-0.8,3,0,2.8C122.2,135.6,122.2,132.4,121.4,132.6z"/>
		<path class="st1" d="M121.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,132.6c-0.8-0.2-0.8,3,0,2.8C120.2,135.6,120.2,132.4,119.4,132.6z"/>
		<path class="st1" d="M119.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,132.6c-0.8-0.2-0.8,3,0,2.8C118.2,135.6,118.2,132.4,117.4,132.6z"/>
		<path class="st1" d="M117.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,132.6c-0.8-0.2-0.8,3,0,2.8C116.2,135.6,116.2,132.4,115.4,132.6z"/>
		<path class="st1" d="M115.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,132.6c-0.8-0.2-0.8,3,0,2.8C114.3,135.6,114.3,132.4,113.4,132.6z"/>
		<path class="st1" d="M113.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,132.6c-0.8-0.2-0.8,3,0,2.8C112.3,135.6,112.3,132.4,111.4,132.6z"/>
		<path class="st1" d="M111.1,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,132.6c-0.8-0.2-0.8,3,0,2.8C110.3,135.6,110.3,132.4,109.4,132.6z"/>
		<path class="st1" d="M109.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,132.6c-0.8-0.2-0.8,3,0,2.8C108.3,135.6,108.3,132.4,107.5,132.6z"/>
		<path class="st1" d="M107.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,132.6c-0.8-0.2-0.8,3,0,2.8C106.3,135.6,106.3,132.4,105.5,132.6z"/>
		<path class="st1" d="M105.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,132.6c-0.8-0.2-0.8,3,0,2.8C104.3,135.6,104.3,132.4,103.5,132.6z"/>
		<path class="st1" d="M103.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,132.6c-0.8-0.2-0.8,3,0,2.8C102.3,135.6,102.3,132.4,101.5,132.6z"/>
		<path class="st1" d="M101.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,132.6c-0.8-0.2-0.8,3,0,2.8C100.3,135.6,100.3,132.4,99.5,132.6z"/>
		<path class="st1" d="M99.2,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,132.6c-0.8-0.2-0.8,3,0,2.8C98.5,135.6,98.5,132.4,97.6,132.6z"/>
		<path class="st1" d="M97.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,132.6c-0.8-0.2-0.8,3,0,2.8C96.5,135.6,96.5,132.4,95.7,132.6z"/>
		<path class="st1" d="M95.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,132.6c-0.8-0.2-0.8,3,0,2.8C94.5,135.6,94.5,132.4,93.7,132.6z"/>
		<path class="st1" d="M93.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,132.6c-0.8-0.2-0.8,3,0,2.8C92.5,135.6,92.5,132.4,91.7,132.6z"/>
		<path class="st1" d="M91.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,132.6c-0.8-0.2-0.8,3,0,2.8C90.5,135.6,90.5,132.4,89.7,132.6z"/>
		<path class="st1" d="M89.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,132.6c-0.8-0.2-0.8,3,0,2.8C88.5,135.6,88.5,132.4,87.7,132.6z"/>
		<path class="st1" d="M87.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,132.6c-0.8-0.2-0.8,3,0,2.8C86.5,135.6,86.5,132.4,85.7,132.6z"/>
		<path class="st1" d="M85.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,132.6c-0.8-0.2-0.8,3,0,2.8C84.6,135.6,84.6,132.4,83.7,132.6z"/>
		<path class="st1" d="M83.4,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,132.6c-0.8-0.2-0.8,3,0,2.8C82.6,135.6,82.6,132.4,81.7,132.6z"/>
		<path class="st1" d="M81.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,132.6c-0.8-0.2-0.8,3,0,2.8C80.6,135.6,80.6,132.4,79.8,132.6z"/>
		<path class="st1" d="M79.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,132.6c-0.8-0.2-0.8,3,0,2.8C78.6,135.6,78.6,132.4,77.8,132.6z"/>
		<path class="st1" d="M77.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,132.6c-0.8-0.2-0.8,3,0,2.8C76.6,135.6,76.6,132.4,75.8,132.6z"/>
		<path class="st1" d="M75.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,132.6c-0.8-0.2-0.8,3,0,2.8C74.6,135.6,74.6,132.4,73.8,132.6z"/>
		<path class="st1" d="M73.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,132.6c-0.8-0.2-0.8,3,0,2.8C72.6,135.6,72.6,132.4,71.8,132.6z"/>
		<path class="st1" d="M71.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,132.6c-0.8-0.2-0.8,3,0,2.8C70.7,135.6,70.7,132.4,69.8,132.6z"/>
		<path class="st1" d="M69.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,132.6c-0.8-0.2-0.8,3,0,2.8C68.7,135.6,68.7,132.4,67.8,132.6z"/>
		<path class="st1" d="M67.5,133.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,133.5c-0.8-0.2-0.8,3,0,2.8C65.8,136.5,65.8,133.3,65,133.5z"/>
		<path class="st1" d="M64.7,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,133.5c-0.8-0.2-0.8,3,0,2.8C63.8,136.5,63.8,133.3,63,133.5z"/>
		<path class="st1" d="M62.7,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,133.5c-0.8-0.2-0.8,3,0,2.8C61.8,136.5,61.8,133.3,61,133.5z"/>
		<path class="st1" d="M60.7,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,133.5c-0.8-0.2-0.8,3,0,2.8C59.8,136.5,59.8,133.3,59,133.5z"/>
		<path class="st1" d="M58.7,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,133.5c-0.8-0.2-0.8,3,0,2.8C57.9,136.5,57.9,133.3,57,133.5z"/>
		<path class="st1" d="M56.7,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,133.5c-0.8-0.2-0.8,3,0,2.8C55.9,136.5,55.9,133.3,55,133.5z"/>
		<path class="st1" d="M54.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,133.5c-0.8-0.2-0.8,3,0,2.8C53.9,136.5,53.9,133.3,53.1,133.5z"/>
		<path class="st1" d="M52.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,133.5c-0.8-0.2-0.8,3,0,2.8C51.9,136.5,51.9,133.3,51.1,133.5z"/>
		<path class="st1" d="M50.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,133.5c-0.8-0.2-0.8,3,0,2.8C49.9,136.5,49.9,133.3,49.1,133.5z"/>
		<path class="st1" d="M48.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,133.5c-0.8-0.2-0.8,3,0,2.8C47.9,136.5,47.9,133.3,47.1,133.5z"/>
		<path class="st1" d="M46.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,133.5c-0.8-0.2-0.8,3,0,2.8C45.9,136.5,45.9,133.3,45.1,133.5z"/>
		<path class="st1" d="M44.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,133.5c-0.8-0.2-0.8,3,0,2.8C43.9,136.5,43.9,133.3,43.1,133.5z"/>
		<path class="st1" d="M42.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,133.5c-0.8-0.2-0.8,3,0,2.8C42,136.5,42,133.3,41.1,133.5z"/>
		<path class="st1" d="M40.8,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,133.5c-0.8-0.2-0.8,3,0,2.8C40,136.5,40,133.3,39.1,133.5z"/>
		<path class="st1" d="M38.9,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,133.5c-0.8-0.2-0.8,3,0,2.8C38,136.5,38,133.3,37.2,133.5z"/>
		<path class="st1" d="M36.9,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,133.5c-0.8-0.2-0.8,3,0,2.8C36,136.5,36,133.3,35.2,133.5z"/>
		<path class="st1" d="M34.9,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,133.5c-0.8-0.2-0.8,3,0,2.8C34.1,136.5,34.1,133.3,33.3,133.5z"/>
		<path class="st1" d="M33,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,133.5c-0.8-0.2-0.8,3,0,2.8C32.1,136.5,32.1,133.3,31.3,133.5z"/>
		<path class="st1" d="M31,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,133.5c-0.8-0.2-0.8,3,0,2.8C30.1,136.5,30.2,133.3,29.3,133.5z"/>
		<path class="st1" d="M29,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,133.5c-0.8-0.2-0.8,3,0,2.8C28.2,136.5,28.2,133.3,27.3,133.5z"/>
		<path class="st1" d="M27,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,133.5c-0.8-0.2-0.8,3,0,2.8C26.2,136.5,26.2,133.3,25.3,133.5z"/>
		<path class="st1" d="M25.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,133.5c-0.8-0.2-0.8,3,0,2.8C24.2,136.5,24.2,133.3,23.4,133.5z"/>
		<path class="st1" d="M23.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,133.5c-0.8-0.2-0.8,3,0,2.8C22.2,136.5,22.2,133.3,21.4,133.5z"/>
		<path class="st1" d="M21.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,133.5c-0.8-0.2-0.8,3,0,2.8C20.2,136.5,20.2,133.3,19.4,133.5z"/>
		<path class="st1" d="M19.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,133.5c-0.8-0.2-0.8,3,0,2.8C18.2,136.5,18.2,133.3,17.4,133.5z"/>
		<path class="st1" d="M17.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,133.5c-0.8-0.2-0.8,3,0,2.8C16.2,136.5,16.2,133.3,15.4,133.5z"/>
		<path class="st1" d="M15.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,133.5c-0.8-0.2-0.8,3,0,2.8C14.3,136.5,14.3,133.3,13.4,133.5z"/>
		<path class="st1" d="M13.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,133.5c-0.8-0.2-0.8,3,0,2.8C12.3,136.5,12.3,133.3,11.4,133.5z"/>
		<path class="st1" d="M11.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,133.5c-0.8-0.2-0.8,3,0,2.8C10.3,136.5,10.3,133.3,9.5,133.5z"/>
		<path class="st1" d="M9.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,133.5c-0.8-0.2-0.8,3,0,2.8C8.3,136.5,8.3,133.3,7.5,133.5z"/>
		<path class="st1" d="M7.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,133.5c-0.8-0.2-0.8,3,0,2.8C6.3,136.5,6.3,133.3,5.5,133.5z"/>
		<path class="st2" d="M5.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,133.5c-0.8-0.2-0.8,3,0,2.8C4.3,136.5,4.3,133.3,3.5,133.5z"/>
		<path class="st2" d="M3.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,133.5c-0.8-0.2-0.8,3,0,2.8C129.2,136.5,129.2,133.3,128.4,133.5z"/>
		<path class="st1" d="M128.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,133.5c-0.8-0.2-0.8,3,0,2.8C127.2,136.5,127.2,133.3,126.4,133.5z"/>
		<path class="st1" d="M126.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,133.5c-0.8-0.2-0.8,3,0,2.8C125.3,136.5,125.3,133.3,124.4,133.5z"/>
		<path class="st1" d="M124.1,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,133.5c-0.8-0.2-0.8,3,0,2.8C123.3,136.5,123.3,133.3,122.4,133.5z"/>
		<path class="st1" d="M122.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,133.5c-0.8-0.2-0.8,3,0,2.8C121.3,136.5,121.3,133.3,120.5,133.5z"/>
		<path class="st1" d="M120.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,133.5c-0.8-0.2-0.8,3,0,2.8C119.3,136.5,119.3,133.3,118.5,133.5z"/>
		<path class="st1" d="M118.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,133.5c-0.8-0.2-0.8,3,0,2.8C117.3,136.5,117.3,133.3,116.5,133.5z"/>
		<path class="st1" d="M116.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,133.5c-0.8-0.2-0.8,3,0,2.8C115.3,136.5,115.3,133.3,114.5,133.5z"/>
		<path class="st1" d="M114.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,133.5c-0.8-0.2-0.8,3,0,2.8C113.3,136.5,113.3,133.3,112.5,133.5z"/>
		<path class="st1" d="M112.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,133.5c-0.8-0.2-0.8,3,0,2.8C111.3,136.5,111.3,133.3,110.5,133.5z"/>
		<path class="st1" d="M110.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,133.5c-0.8-0.2-0.8,3,0,2.8C109.4,136.5,109.4,133.3,108.5,133.5z"/>
		<path class="st1" d="M108.2,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,133.5c-0.8-0.2-0.8,3,0,2.8C107.4,136.5,107.4,133.3,106.5,133.5z"/>
		<path class="st1" d="M106.3,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,133.5c-0.8-0.2-0.8,3,0,2.8C105.4,136.5,105.4,133.3,104.6,133.5z"/>
		<path class="st1" d="M104.3,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,133.5c-0.8-0.2-0.8,3,0,2.8C103.4,136.5,103.4,133.3,102.6,133.5z"/>
		<path class="st1" d="M102.3,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,133.5c-0.8-0.2-0.8,3,0,2.8C101.4,136.5,101.4,133.3,100.6,133.5z"/>
		<path class="st1" d="M100.3,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,133.5c-0.8-0.2-0.8,3,0,2.8C99.4,136.5,99.4,133.3,98.6,133.5z"/>
		<path class="st1" d="M98.3,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,133.5c-0.8-0.2-0.8,3,0,2.8C97.5,136.5,97.6,133.3,96.7,133.5z"/>
		<path class="st1" d="M96.4,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,133.5c-0.8-0.2-0.8,3,0,2.8C95.6,136.5,95.6,133.3,94.7,133.5z"/>
		<path class="st1" d="M94.4,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,133.5c-0.8-0.2-0.8,3,0,2.8C93.6,136.5,93.6,133.3,92.7,133.5z"/>
		<path class="st1" d="M92.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,133.5c-0.8-0.2-0.8,3,0,2.8C91.6,136.5,91.6,133.3,90.8,133.5z"/>
		<path class="st1" d="M90.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,133.5c-0.8-0.2-0.8,3,0,2.8C89.6,136.5,89.6,133.3,88.8,133.5z"/>
		<path class="st1" d="M88.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,133.5c-0.8-0.2-0.8,3,0,2.8C87.6,136.5,87.6,133.3,86.8,133.5z"/>
		<path class="st1" d="M86.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,133.5c-0.8-0.2-0.8,3,0,2.8C85.6,136.5,85.6,133.3,84.8,133.5z"/>
		<path class="st1" d="M84.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,133.5c-0.8-0.2-0.8,3,0,2.8C83.6,136.5,83.6,133.3,82.8,133.5z"/>
		<path class="st1" d="M82.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,133.5c-0.8-0.2-0.8,3,0,2.8C81.7,136.5,81.7,133.3,80.8,133.5z"/>
		<path class="st1" d="M80.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,133.5c-0.8-0.2-0.8,3,0,2.8C79.7,136.5,79.7,133.3,78.8,133.5z"/>
		<path class="st1" d="M78.5,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,133.5c-0.8-0.2-0.8,3,0,2.8C77.7,136.5,77.7,133.3,76.9,133.5z"/>
		<path class="st1" d="M76.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,133.5c-0.8-0.2-0.8,3,0,2.8C75.7,136.5,75.7,133.3,74.9,133.5z"/>
		<path class="st1" d="M74.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,133.5c-0.8-0.2-0.8,3,0,2.8C73.7,136.5,73.7,133.3,72.9,133.5z"/>
		<path class="st1" d="M72.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,133.5c-0.8-0.2-0.8,3,0,2.8C71.7,136.5,71.7,133.3,70.9,133.5z"/>
		<path class="st1" d="M70.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,133.5c-0.8-0.2-0.8,3,0,2.8C69.7,136.5,69.7,133.3,68.9,133.5z"/>
		<path class="st1" d="M68.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,133.5c-0.8-0.2-0.8,3,0,2.8C67.7,136.5,67.7,133.3,66.9,133.5z"/>
		<path class="st1" d="M66.6,134.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,249c-0.8-0.2-0.8,3,0,2.8C65.8,252,65.8,248.8,65,249z"/>
		<path class="st1" d="M64.7,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,249c-0.8-0.2-0.8,3,0,2.8C63.8,252,63.8,248.8,63,249z"/>
		<path class="st1" d="M62.7,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,249c-0.8-0.2-0.8,3,0,2.8C61.8,252,61.8,248.8,61,249z"/>
		<path class="st1" d="M60.7,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,249c-0.8-0.2-0.8,3,0,2.8C59.8,252,59.8,248.8,59,249z"/>
		<path class="st1" d="M58.7,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,249c-0.8-0.2-0.8,3,0,2.8C57.9,252,57.9,248.8,57,249z"/>
		<path class="st1" d="M56.7,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,249c-0.8-0.2-0.8,3,0,2.8C55.9,252,55.9,248.8,55,249z"/>
		<path class="st1" d="M54.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,249c-0.8-0.2-0.8,3,0,2.8C53.9,252,53.9,248.8,53.1,249z"/>
		<path class="st1" d="M52.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,249c-0.8-0.2-0.8,3,0,2.8C51.9,252,51.9,248.8,51.1,249z"/>
		<path class="st1" d="M50.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,249c-0.8-0.2-0.8,3,0,2.8C49.9,252,49.9,248.8,49.1,249z"/>
		<path class="st1" d="M48.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,249c-0.8-0.2-0.8,3,0,2.8C47.9,252,47.9,248.8,47.1,249z"/>
		<path class="st1" d="M46.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,249c-0.8-0.2-0.8,3,0,2.8C45.9,252,45.9,248.8,45.1,249z"/>
		<path class="st1" d="M44.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,249c-0.8-0.2-0.8,3,0,2.8C43.9,252,43.9,248.8,43.1,249z"/>
		<path class="st1" d="M42.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,249c-0.8-0.2-0.8,3,0,2.8C42,252,42,248.8,41.1,249z"/>
		<path class="st1" d="M40.8,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,249c-0.8-0.2-0.8,3,0,2.8C40,252,40,248.8,39.1,249z"/>
		<path class="st1" d="M38.9,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,249c-0.8-0.2-0.8,3,0,2.8C38,252,38,248.8,37.2,249z"/>
		<path class="st1" d="M36.9,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,249c-0.8-0.2-0.8,3,0,2.8C36,252,36,248.8,35.2,249z"/>
		<path class="st1" d="M34.9,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,249c-0.8-0.2-0.8,3,0,2.8C34.1,252,34.1,248.8,33.3,249z"/>
		<path class="st1" d="M33,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,249c-0.8-0.2-0.8,3,0,2.8C32.1,252,32.1,248.8,31.3,249z"/>
		<path class="st1" d="M31,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,249c-0.8-0.2-0.8,3,0,2.8C30.1,252,30.2,248.8,29.3,249z"/>
		<path class="st1" d="M29,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,249c-0.8-0.2-0.8,3,0,2.8C28.2,252,28.2,248.8,27.3,249z"/>
		<path class="st1" d="M27,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,249c-0.8-0.2-0.8,3,0,2.8C26.2,252,26.2,248.8,25.3,249z"/>
		<path class="st1" d="M25.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,249c-0.8-0.2-0.8,3,0,2.8C24.2,252,24.2,248.8,23.4,249z"/>
		<path class="st1" d="M23.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,249c-0.8-0.2-0.8,3,0,2.8C22.2,252,22.2,248.8,21.4,249z"/>
		<path class="st1" d="M21.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,249c-0.8-0.2-0.8,3,0,2.8C20.2,252,20.2,248.8,19.4,249z"/>
		<path class="st1" d="M19.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,249c-0.8-0.2-0.8,3,0,2.8C18.2,252,18.2,248.8,17.4,249z"/>
		<path class="st1" d="M17.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,249c-0.8-0.2-0.8,3,0,2.8C16.2,252,16.2,248.8,15.4,249z"/>
		<path class="st1" d="M15.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,249c-0.8-0.2-0.8,3,0,2.8C14.3,252,14.3,248.8,13.4,249z"/>
		<path class="st1" d="M13.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,249c-0.8-0.2-0.8,3,0,2.8C12.3,252,12.3,248.8,11.4,249z"/>
		<path class="st1" d="M11.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,249c-0.8-0.2-0.8,3,0,2.8C10.3,252,10.3,248.8,9.5,249z"/>
		<path class="st1" d="M9.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,249c-0.8-0.2-0.8,3,0,2.8C8.3,252,8.3,248.8,7.5,249z"/>
		<path class="st1" d="M7.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,249c-0.8-0.2-0.8,3,0,2.8C6.3,252,6.3,248.8,5.5,249z"/>
		<path class="st1" d="M5.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,249c-0.8-0.2-0.8,3,0,2.8C4.3,252,4.3,248.8,3.5,249z"/>
		<path class="st1" d="M3.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,249c-0.8-0.2-0.8,3,0,2.8C129.2,252,129.2,248.8,128.4,249z"/>
		<path class="st1" d="M128.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,249c-0.8-0.2-0.8,3,0,2.8C127.2,252,127.2,248.8,126.4,249z"/>
		<path class="st1" d="M126.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,249c-0.8-0.2-0.8,3,0,2.8C125.3,252,125.3,248.8,124.4,249z"/>
		<path class="st1" d="M124.1,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,249c-0.8-0.2-0.8,3,0,2.8C123.3,252,123.3,248.8,122.4,249z"/>
		<path class="st1" d="M122.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,249c-0.8-0.2-0.8,3,0,2.8C121.3,252,121.3,248.8,120.5,249z"/>
		<path class="st1" d="M120.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,249c-0.8-0.2-0.8,3,0,2.8C119.3,252,119.3,248.8,118.5,249z"/>
		<path class="st1" d="M118.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,249c-0.8-0.2-0.8,3,0,2.8C117.3,252,117.3,248.8,116.5,249z"/>
		<path class="st1" d="M116.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,249c-0.8-0.2-0.8,3,0,2.8C115.3,252,115.3,248.8,114.5,249z"/>
		<path class="st1" d="M114.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,249c-0.8-0.2-0.8,3,0,2.8C113.3,252,113.3,248.8,112.5,249z"/>
		<path class="st1" d="M112.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,249c-0.8-0.2-0.8,3,0,2.8C111.3,252,111.3,248.8,110.5,249z"/>
		<path class="st1" d="M110.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,249c-0.8-0.2-0.8,3,0,2.8C109.4,252,109.4,248.8,108.5,249z"/>
		<path class="st1" d="M108.2,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,249c-0.8-0.2-0.8,3,0,2.8C107.4,252,107.4,248.8,106.5,249z"/>
		<path class="st1" d="M106.3,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,249c-0.8-0.2-0.8,3,0,2.8C105.4,252,105.4,248.8,104.6,249z"/>
		<path class="st1" d="M104.3,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,249c-0.8-0.2-0.8,3,0,2.8C103.4,252,103.4,248.8,102.6,249z"/>
		<path class="st1" d="M102.3,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,249c-0.8-0.2-0.8,3,0,2.8C101.4,252,101.4,248.8,100.6,249z"/>
		<path class="st1" d="M100.3,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,249c-0.8-0.2-0.8,3,0,2.8C99.4,252,99.4,248.8,98.6,249z"/>
		<path class="st1" d="M98.3,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,249c-0.8-0.2-0.8,3,0,2.8C97.5,252,97.6,248.8,96.7,249z"/>
		<path class="st1" d="M96.4,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,249c-0.8-0.2-0.8,3,0,2.8C95.6,252,95.6,248.8,94.7,249z"/>
		<path class="st1" d="M94.4,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,249c-0.8-0.2-0.8,3,0,2.8C93.6,252,93.6,248.8,92.7,249z"/>
		<path class="st1" d="M92.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,249c-0.8-0.2-0.8,3,0,2.8C91.6,252,91.6,248.8,90.8,249z"/>
		<path class="st1" d="M90.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,249c-0.8-0.2-0.8,3,0,2.8C89.6,252,89.6,248.8,88.8,249z"/>
		<path class="st1" d="M88.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,249c-0.8-0.2-0.8,3,0,2.8C87.6,252,87.6,248.8,86.8,249z"/>
		<path class="st1" d="M86.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,249c-0.8-0.2-0.8,3,0,2.8C85.6,252,85.6,248.8,84.8,249z"/>
		<path class="st1" d="M84.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,249c-0.8-0.2-0.8,3,0,2.8C83.6,252,83.6,248.8,82.8,249z"/>
		<path class="st1" d="M82.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,249c-0.8-0.2-0.8,3,0,2.8C81.7,252,81.7,248.8,80.8,249z"/>
		<path class="st1" d="M80.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,249c-0.8-0.2-0.8,3,0,2.8C79.7,252,79.7,248.8,78.8,249z"/>
		<path class="st1" d="M78.5,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,249c-0.8-0.2-0.8,3,0,2.8C77.7,252,77.7,248.8,76.9,249z"/>
		<path class="st1" d="M76.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,249c-0.8-0.2-0.8,3,0,2.8C75.7,252,75.7,248.8,74.9,249z"/>
		<path class="st1" d="M74.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,249c-0.8-0.2-0.8,3,0,2.8C73.7,252,73.7,248.8,72.9,249z"/>
		<path class="st1" d="M72.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,249c-0.8-0.2-0.8,3,0,2.8C71.7,252,71.7,248.8,70.9,249z"/>
		<path class="st1" d="M70.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,249c-0.8-0.2-0.8,3,0,2.8C69.7,252,69.7,248.8,68.9,249z"/>
		<path class="st1" d="M68.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,249c-0.8-0.2-0.8,3,0,2.8C67.7,252,67.7,248.8,66.9,249z"/>
		<path class="st1" d="M66.6,249.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,249.8c-0.8-0.2-0.8,3,0,2.8C52.8,252.8,52.8,249.6,52,249.8z"/>
		<path class="st1" d="M51.7,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,249.8c-0.8-0.2-0.8,3,0,2.8C50.8,252.8,50.8,249.6,50,249.8z"/>
		<path class="st1" d="M49.7,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,249.8c-0.8-0.2-0.8,3,0,2.8C48.8,252.8,48.8,249.6,48,249.8z"/>
		<path class="st1" d="M47.7,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,249.8c-0.8-0.2-0.8,3,0,2.8C46.9,252.8,46.9,249.6,46,249.8z"/>
		<path class="st1" d="M45.7,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,249.8c-0.8-0.2-0.8,3,0,2.8C44.9,252.8,44.9,249.6,44,249.8z"/>
		<path class="st1" d="M43.7,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,249.8c-0.8-0.2-0.8,3,0,2.8C42.9,252.8,42.9,249.6,42.1,249.8z"/>
		<path class="st1" d="M41.8,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,249.8c-0.8-0.2-0.8,3,0,2.8C40.9,252.8,40.9,249.6,40.1,249.8z"/>
		<path class="st1" d="M39.8,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,249.8c-0.8-0.2-0.8,3,0,2.8C38.9,252.8,38.9,249.6,38.1,249.8z"/>
		<path class="st1" d="M37.8,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,249.8c-0.8-0.2-0.8,3,0,2.8C36.9,252.8,36.9,249.6,36.1,249.8z"/>
		<path class="st1" d="M35.8,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,249.8c-0.8-0.2-0.8,3,0,2.8C35,252.8,35,249.6,34.2,249.8z"/>
		<path class="st1" d="M33.9,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,249.8c-0.8-0.2-0.8,3,0,2.8C33.1,252.8,33.1,249.6,32.2,249.8z"/>
		<path class="st1" d="M31.9,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,249.8c-0.8-0.2-0.8,3,0,2.8C31.1,252.8,31.1,249.6,30.2,249.8z"/>
		<path class="st1" d="M30,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,249.8c-0.8-0.2-0.8,3,0,2.8C29.1,252.8,29.1,249.6,28.3,249.8z"/>
		<path class="st1" d="M28,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,249.8c-0.8-0.2-0.8,3,0,2.8C27.1,252.8,27.1,249.6,26.3,249.8z"/>
		<path class="st1" d="M26,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,249.8c-0.8-0.2-0.8,3,0,2.8C25.1,252.8,25.1,249.6,24.3,249.8z"/>
		<path class="st1" d="M24,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,249.8c-0.8-0.2-0.8,3,0,2.8C23.1,252.8,23.1,249.6,22.3,249.8z"/>
		<path class="st1" d="M22,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,249.8c-0.8-0.2-0.8,3,0,2.8C21.1,252.8,21.1,249.6,20.3,249.8z"/>
		<path class="st1" d="M20,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,249.8c-0.8-0.2-0.8,3,0,2.8C19.1,252.8,19.1,249.6,18.3,249.8z"/>
		<path class="st1" d="M18,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,249.8c-0.8-0.2-0.8,3,0,2.8C17.2,252.8,17.2,249.6,16.3,249.8z"/>
		<path class="st1" d="M16,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,249.8c-0.8-0.2-0.8,3,0,2.8C15.2,252.8,15.2,249.6,14.3,249.8z"/>
		<path class="st1" d="M14.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,249.8c-0.8-0.2-0.8,3,0,2.8C13.2,252.8,13.2,249.6,12.4,249.8z"/>
		<path class="st1" d="M12.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,249.8c-0.8-0.2-0.8,3,0,2.8C11.2,252.8,11.2,249.6,10.4,249.8z"/>
		<path class="st1" d="M10.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,249.8c-0.8-0.2-0.8,3,0,2.8C9.2,252.8,9.2,249.6,8.4,249.8z"/>
		<path class="st1" d="M8.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,249.8c-0.8-0.2-0.8,3,0,2.8C7.2,252.8,7.2,249.6,6.4,249.8z"/>
		<path class="st1" d="M6.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,249.8c-0.8-0.2-0.8,3,0,2.8C5.2,252.8,5.2,249.6,4.4,249.8z"/>
		<path class="st1" d="M4.1,250.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,262.4c-0.8-0.2-0.8,3,0,2.8C65.8,265.4,65.8,262.2,65,262.4z"/>
		<path class="st1" d="M64.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,262.4c-0.8-0.2-0.8,3,0,2.8C63.8,265.4,63.8,262.2,63,262.4z"/>
		<path class="st1" d="M62.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,262.4c-0.8-0.2-0.8,3,0,2.8C61.8,265.4,61.8,262.2,61,262.4z"/>
		<path class="st1" d="M60.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,262.4c-0.8-0.2-0.8,3,0,2.8C59.8,265.4,59.8,262.2,59,262.4z"/>
		<path class="st1" d="M58.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,262.4c-0.8-0.2-0.8,3,0,2.8C57.9,265.4,57.9,262.2,57,262.4z"/>
		<path class="st1" d="M56.7,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,262.4c-0.8-0.2-0.8,3,0,2.8C55.9,265.4,55.9,262.2,55,262.4z"/>
		<path class="st1" d="M54.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,262.4c-0.8-0.2-0.8,3,0,2.8C53.9,265.4,53.9,262.2,53.1,262.4z"/>
		<path class="st1" d="M52.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,262.4c-0.8-0.2-0.8,3,0,2.8C51.9,265.4,51.9,262.2,51.1,262.4z"/>
		<path class="st1" d="M50.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,262.4c-0.8-0.2-0.8,3,0,2.8C49.9,265.4,49.9,262.2,49.1,262.4z"/>
		<path class="st1" d="M48.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,262.4c-0.8-0.2-0.8,3,0,2.8C47.9,265.4,47.9,262.2,47.1,262.4z"/>
		<path class="st1" d="M46.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,262.4c-0.8-0.2-0.8,3,0,2.8C45.9,265.4,45.9,262.2,45.1,262.4z"/>
		<path class="st1" d="M44.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,262.4c-0.8-0.2-0.8,3,0,2.8C43.9,265.4,43.9,262.2,43.1,262.4z"/>
		<path class="st1" d="M42.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,262.4c-0.8-0.2-0.8,3,0,2.8C42,265.4,42,262.2,41.1,262.4z"/>
		<path class="st1" d="M40.8,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,262.4c-0.8-0.2-0.8,3,0,2.8C40,265.4,40,262.2,39.1,262.4z"/>
		<path class="st1" d="M38.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,262.4c-0.8-0.2-0.8,3,0,2.8C38,265.4,38,262.2,37.2,262.4z"/>
		<path class="st1" d="M36.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,262.4c-0.8-0.2-0.8,3,0,2.8C36,265.4,36,262.2,35.2,262.4z"/>
		<path class="st1" d="M34.9,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,262.4c-0.8-0.2-0.8,3,0,2.8C34.1,265.4,34.1,262.2,33.3,262.4z"/>
		<path class="st1" d="M33,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,262.4c-0.8-0.2-0.8,3,0,2.8C32.1,265.4,32.1,262.2,31.3,262.4z"/>
		<path class="st1" d="M31,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,262.4c-0.8-0.2-0.8,3,0,2.8C30.1,265.4,30.2,262.2,29.3,262.4z"/>
		<path class="st1" d="M29,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,262.4c-0.8-0.2-0.8,3,0,2.8C28.2,265.4,28.2,262.2,27.3,262.4z"/>
		<path class="st1" d="M27,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,262.4c-0.8-0.2-0.8,3,0,2.8C26.2,265.4,26.2,262.2,25.3,262.4z"/>
		<path class="st1" d="M25.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,262.4c-0.8-0.2-0.8,3,0,2.8C24.2,265.4,24.2,262.2,23.4,262.4z"/>
		<path class="st1" d="M23.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,262.4c-0.8-0.2-0.8,3,0,2.8C22.2,265.4,22.2,262.2,21.4,262.4z"/>
		<path class="st1" d="M21.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,262.4c-0.8-0.2-0.8,3,0,2.8C20.2,265.4,20.2,262.2,19.4,262.4z"/>
		<path class="st1" d="M19.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,262.4c-0.8-0.2-0.8,3,0,2.8C18.2,265.4,18.2,262.2,17.4,262.4z"/>
		<path class="st1" d="M17.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,262.4c-0.8-0.2-0.8,3,0,2.8C16.2,265.4,16.2,262.2,15.4,262.4z"/>
		<path class="st1" d="M15.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,262.4c-0.8-0.2-0.8,3,0,2.8C14.3,265.4,14.3,262.2,13.4,262.4z"/>
		<path class="st1" d="M13.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,262.4c-0.8-0.2-0.8,3,0,2.8C12.3,265.4,12.3,262.2,11.4,262.4z"/>
		<path class="st1" d="M11.1,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,262.4c-0.8-0.2-0.8,3,0,2.8C10.3,265.4,10.3,262.2,9.5,262.4z"/>
		<path class="st1" d="M9.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,262.4c-0.8-0.2-0.8,3,0,2.8C8.3,265.4,8.3,262.2,7.5,262.4z"/>
		<path class="st1" d="M7.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,262.4c-0.8-0.2-0.8,3,0,2.8C6.3,265.4,6.3,262.2,5.5,262.4z"/>
		<path class="st1" d="M5.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,262.4c-0.8-0.2-0.8,3,0,2.8C4.3,265.4,4.3,262.2,3.5,262.4z"/>
		<path class="st1" d="M3.2,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,262.4c-0.8-0.2-0.8,3,0,2.8C107.4,265.4,107.4,262.2,106.5,262.4z"/>
		<path class="st1" d="M106.3,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,262.4c-0.8-0.2-0.8,3,0,2.8C105.4,265.4,105.4,262.2,104.6,262.4z"/>
		<path class="st1" d="M104.3,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,262.4c-0.8-0.2-0.8,3,0,2.8C103.4,265.4,103.4,262.2,102.6,262.4z"/>
		<path class="st1" d="M102.3,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,262.4c-0.8-0.2-0.8,3,0,2.8C101.4,265.4,101.4,262.2,100.6,262.4z"/>
		<path class="st1" d="M100.3,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,262.4c-0.8-0.2-0.8,3,0,2.8C99.4,265.4,99.4,262.2,98.6,262.4z"/>
		<path class="st1" d="M98.3,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,262.4c-0.8-0.2-0.8,3,0,2.8C97.5,265.4,97.6,262.2,96.7,262.4z"/>
		<path class="st1" d="M96.4,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,262.4c-0.8-0.2-0.8,3,0,2.8C95.6,265.4,95.6,262.2,94.7,262.4z"/>
		<path class="st1" d="M94.4,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,262.4c-0.8-0.2-0.8,3,0,2.8C93.6,265.4,93.6,262.2,92.7,262.4z"/>
		<path class="st1" d="M92.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,262.4c-0.8-0.2-0.8,3,0,2.8C91.6,265.4,91.6,262.2,90.8,262.4z"/>
		<path class="st1" d="M90.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,262.4c-0.8-0.2-0.8,3,0,2.8C89.6,265.4,89.6,262.2,88.8,262.4z"/>
		<path class="st1" d="M88.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,262.4c-0.8-0.2-0.8,3,0,2.8C87.6,265.4,87.6,262.2,86.8,262.4z"/>
		<path class="st1" d="M86.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,262.4c-0.8-0.2-0.8,3,0,2.8C85.6,265.4,85.6,262.2,84.8,262.4z"/>
		<path class="st1" d="M84.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,262.4c-0.8-0.2-0.8,3,0,2.8C83.6,265.4,83.6,262.2,82.8,262.4z"/>
		<path class="st1" d="M82.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,262.4c-0.8-0.2-0.8,3,0,2.8C81.7,265.4,81.7,262.2,80.8,262.4z"/>
		<path class="st1" d="M80.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,262.4c-0.8-0.2-0.8,3,0,2.8C79.7,265.4,79.7,262.2,78.8,262.4z"/>
		<path class="st1" d="M78.5,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,262.4c-0.8-0.2-0.8,3,0,2.8C77.7,265.4,77.7,262.2,76.9,262.4z"/>
		<path class="st1" d="M76.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,262.4c-0.8-0.2-0.8,3,0,2.8C75.7,265.4,75.7,262.2,74.9,262.4z"/>
		<path class="st1" d="M74.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,262.4c-0.8-0.2-0.8,3,0,2.8C73.7,265.4,73.7,262.2,72.9,262.4z"/>
		<path class="st1" d="M72.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,262.4c-0.8-0.2-0.8,3,0,2.8C71.7,265.4,71.7,262.2,70.9,262.4z"/>
		<path class="st1" d="M70.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,262.4c-0.8-0.2-0.8,3,0,2.8C69.7,265.4,69.7,262.2,68.9,262.4z"/>
		<path class="st1" d="M68.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,262.4c-0.8-0.2-0.8,3,0,2.8C67.7,265.4,67.7,262.2,66.9,262.4z"/>
		<path class="st1" d="M66.6,263.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,114.6c-0.8-0.2-0.8,3,0,2.8C65.8,117.6,65.8,114.4,65,114.6z"/>
		<path class="st1" d="M64.7,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,114.6c-0.8-0.2-0.8,3,0,2.8C63.8,117.6,63.8,114.4,63,114.6z"/>
		<path class="st1" d="M62.7,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,114.6c-0.8-0.2-0.8,3,0,2.8C61.8,117.6,61.8,114.4,61,114.6z"/>
		<path class="st1" d="M60.7,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,114.6c-0.8-0.2-0.8,3,0,2.8C59.8,117.6,59.8,114.4,59,114.6z"/>
		<path class="st1" d="M58.7,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,114.6c-0.8-0.2-0.8,3,0,2.8C57.9,117.6,57.9,114.4,57,114.6z"/>
		<path class="st1" d="M56.7,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,114.6c-0.8-0.2-0.8,3,0,2.8C55.9,117.6,55.9,114.4,55,114.6z"/>
		<path class="st1" d="M54.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,114.6c-0.8-0.2-0.8,3,0,2.8C53.9,117.6,53.9,114.4,53.1,114.6z"/>
		<path class="st1" d="M52.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,114.6c-0.8-0.2-0.8,3,0,2.8C51.9,117.6,51.9,114.4,51.1,114.6z"/>
		<path class="st1" d="M50.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,114.6c-0.8-0.2-0.8,3,0,2.8C49.9,117.6,49.9,114.4,49.1,114.6z"/>
		<path class="st1" d="M48.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,114.6c-0.8-0.2-0.8,3,0,2.8C47.9,117.6,47.9,114.4,47.1,114.6z"/>
		<path class="st1" d="M46.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,114.6c-0.8-0.2-0.8,3,0,2.8C45.9,117.6,45.9,114.4,45.1,114.6z"/>
		<path class="st1" d="M44.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,114.6c-0.8-0.2-0.8,3,0,2.8C43.9,117.6,43.9,114.4,43.1,114.6z"/>
		<path class="st1" d="M42.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,114.6c-0.8-0.2-0.8,3,0,2.8C42,117.6,42,114.4,41.1,114.6z"/>
		<path class="st1" d="M40.8,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,114.6c-0.8-0.2-0.8,3,0,2.8C40,117.6,40,114.4,39.1,114.6z"/>
		<path class="st1" d="M38.9,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,114.6c-0.8-0.2-0.8,3,0,2.8C38,117.6,38,114.4,37.2,114.6z"/>
		<path class="st1" d="M36.9,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,114.6c-0.8-0.2-0.8,3,0,2.8C36,117.6,36,114.4,35.2,114.6z"/>
		<path class="st1" d="M34.9,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,114.6c-0.8-0.2-0.8,3,0,2.8C34.1,117.6,34.1,114.4,33.3,114.6z"/>
		<path class="st1" d="M33,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,114.6c-0.8-0.2-0.8,3,0,2.8C32.1,117.6,32.1,114.4,31.3,114.6z"/>
		<path class="st1" d="M31,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,114.6c-0.8-0.2-0.8,3,0,2.8C30.1,117.6,30.2,114.4,29.3,114.6z"/>
		<path class="st1" d="M29,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,114.6c-0.8-0.2-0.8,3,0,2.8C28.2,117.6,28.2,114.4,27.3,114.6z"/>
		<path class="st1" d="M27,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,114.6c-0.8-0.2-0.8,3,0,2.8C26.2,117.6,26.2,114.4,25.3,114.6z"/>
		<path class="st1" d="M25.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,114.6c-0.8-0.2-0.8,3,0,2.8C24.2,117.6,24.2,114.4,23.4,114.6z"/>
		<path class="st1" d="M23.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,114.6c-0.8-0.2-0.8,3,0,2.8C22.2,117.6,22.2,114.4,21.4,114.6z"/>
		<path class="st1" d="M21.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,114.6c-0.8-0.2-0.8,3,0,2.8C20.2,117.6,20.2,114.4,19.4,114.6z"/>
		<path class="st1" d="M19.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,114.6c-0.8-0.2-0.8,3,0,2.8C18.2,117.6,18.2,114.4,17.4,114.6z"/>
		<path class="st1" d="M17.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,114.6c-0.8-0.2-0.8,3,0,2.8C16.2,117.6,16.2,114.4,15.4,114.6z"/>
		<path class="st1" d="M15.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,114.6c-0.8-0.2-0.8,3,0,2.8C14.3,117.6,14.3,114.4,13.4,114.6z"/>
		<path class="st1" d="M13.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,114.6c-0.8-0.2-0.8,3,0,2.8C12.3,117.6,12.3,114.4,11.4,114.6z"/>
		<path class="st1" d="M11.1,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,114.6c-0.8-0.2-0.8,3,0,2.8C10.3,117.6,10.3,114.4,9.5,114.6z"/>
		<path class="st1" d="M9.2,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,114.6c-0.8-0.2-0.8,3,0,2.8C8.3,117.6,8.3,114.4,7.5,114.6z"/>
		<path class="st2" d="M7.2,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,114.6c-0.8-0.2-0.8,3,0,2.8C6.3,117.6,6.3,114.4,5.5,114.6z"/>
		<path class="st2" d="M5.2,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,114.6c-0.8-0.2-0.8,3,0,2.8C4.3,117.6,4.3,114.4,3.5,114.6z"/>
		<path class="st2" d="M3.2,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M108.5,114.6c-0.8-0.2-0.8,3,0,2.8C109.4,117.6,109.4,114.4,108.5,114.6z"/>
		<path class="st1" d="M108.2,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M106.5,114.6c-0.8-0.2-0.8,3,0,2.8C107.4,117.6,107.4,114.4,106.5,114.6z"/>
		<path class="st1" d="M106.3,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M100.6,114.6c-0.8-0.2-0.8,3,0,2.8C101.4,117.6,101.4,114.4,100.6,114.6z"/>
		<path class="st1" d="M100.3,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M98.6,114.6c-0.8-0.2-0.8,3,0,2.8C99.4,117.6,99.4,114.4,98.6,114.6z"/>
		<path class="st1" d="M98.3,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M96.7,114.6c-0.8-0.2-0.8,3,0,2.8C97.5,117.6,97.6,114.4,96.7,114.6z"/>
		<path class="st1" d="M96.4,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M94.7,114.6c-0.8-0.2-0.8,3,0,2.8C95.6,117.6,95.6,114.4,94.7,114.6z"/>
		<path class="st1" d="M94.4,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M92.7,114.6c-0.8-0.2-0.8,3,0,2.8C93.6,117.6,93.6,114.4,92.7,114.6z"/>
		<path class="st1" d="M92.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M90.8,114.6c-0.8-0.2-0.8,3,0,2.8C91.6,117.6,91.6,114.4,90.8,114.6z"/>
		<path class="st1" d="M90.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M88.8,114.6c-0.8-0.2-0.8,3,0,2.8C89.6,117.6,89.6,114.4,88.8,114.6z"/>
		<path class="st1" d="M88.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,114.6c-0.8-0.2-0.8,3,0,2.8C87.6,117.6,87.6,114.4,86.8,114.6z"/>
		<path class="st1" d="M86.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,114.6c-0.8-0.2-0.8,3,0,2.8C85.6,117.6,85.6,114.4,84.8,114.6z"/>
		<path class="st1" d="M84.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,114.6c-0.8-0.2-0.8,3,0,2.8C83.6,117.6,83.6,114.4,82.8,114.6z"/>
		<path class="st1" d="M82.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,114.6c-0.8-0.2-0.8,3,0,2.8C81.7,117.6,81.7,114.4,80.8,114.6z"/>
		<path class="st1" d="M80.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,114.6c-0.8-0.2-0.8,3,0,2.8C79.7,117.6,79.7,114.4,78.8,114.6z"/>
		<path class="st1" d="M78.5,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,114.6c-0.8-0.2-0.8,3,0,2.8C77.7,117.6,77.7,114.4,76.9,114.6z"/>
		<path class="st1" d="M76.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,114.6c-0.8-0.2-0.8,3,0,2.8C75.7,117.6,75.7,114.4,74.9,114.6z"/>
		<path class="st1" d="M74.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,114.6c-0.8-0.2-0.8,3,0,2.8C73.7,117.6,73.7,114.4,72.9,114.6z"/>
		<path class="st1" d="M72.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,114.6c-0.8-0.2-0.8,3,0,2.8C71.7,117.6,71.7,114.4,70.9,114.6z"/>
		<path class="st1" d="M70.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,114.6c-0.8-0.2-0.8,3,0,2.8C69.7,117.6,69.7,114.4,68.9,114.6z"/>
		<path class="st1" d="M68.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,114.6c-0.8-0.2-0.8,3,0,2.8C67.7,117.6,67.7,114.4,66.9,114.6z"/>
		<path class="st1" d="M66.6,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M102.6,114.6c-0.8-0.2-0.8,3,0,2.8C103.4,117.6,103.4,114.4,102.6,114.6z"/>
		<path class="st1" d="M102.3,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M104.6,114.6c-0.8-0.2-0.8,3,0,2.8C105.4,117.6,105.4,114.4,104.6,114.6z"/>
		<path class="st1" d="M104.3,115.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65.9,100.6c-0.8-0.2-0.8,3,0,2.8C66.7,103.6,66.7,100.4,65.9,100.6z"/>
		<path class="st0" d="M65.6,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,100.6c-0.8-0.2-0.8,3,0,2.8C62.7,103.6,62.8,100.4,61.9,100.6z"/>
		<path class="st0" d="M61.6,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,100.6c-0.8-0.2-0.8,3,0,2.8C60.8,103.6,60.8,100.4,59.9,100.6z"/>
		<path class="st0" d="M59.6,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,100.6c-0.8-0.2-0.8,3,0,2.8C58.8,103.6,58.8,100.4,57.9,100.6z"/>
		<path class="st0" d="M57.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,100.6c-0.8-0.2-0.8,3,0,2.8C56.8,103.6,56.8,100.4,56,100.6z"/>
		<path class="st0" d="M55.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,100.6c-0.8-0.2-0.8,3,0,2.8C54.8,103.6,54.8,100.4,54,100.6z"/>
		<path class="st0" d="M53.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,100.6c-0.8-0.2-0.8,3,0,2.8C52.8,103.6,52.8,100.4,52,100.6z"/>
		<path class="st0" d="M51.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,100.6c-0.8-0.2-0.8,3,0,2.8C50.8,103.6,50.8,100.4,50,100.6z"/>
		<path class="st0" d="M49.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,100.6c-0.8-0.2-0.8,3,0,2.8C48.8,103.6,48.8,100.4,48,100.6z"/>
		<path class="st0" d="M47.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,100.6c-0.8-0.2-0.8,3,0,2.8C46.9,103.6,46.9,100.4,46,100.6z"/>
		<path class="st0" d="M45.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,100.6c-0.8-0.2-0.8,3,0,2.8C44.9,103.6,44.9,100.4,44,100.6z"/>
		<path class="st0" d="M43.7,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,100.6c-0.8-0.2-0.8,3,0,2.8C42.9,103.6,42.9,100.4,42.1,100.6z"/>
		<path class="st0" d="M41.8,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,100.6c-0.8-0.2-0.8,3,0,2.8C40.9,103.6,40.9,100.4,40.1,100.6z"/>
		<path class="st0" d="M39.8,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,100.6c-0.8-0.2-0.8,3,0,2.8C38.9,103.6,38.9,100.4,38.1,100.6z"/>
		<path class="st0" d="M37.8,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,100.6c-0.8-0.2-0.8,3,0,2.8C36.9,103.6,36.9,100.4,36.1,100.6z"/>
		<path class="st0" d="M35.8,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,100.6c-0.8-0.2-0.8,3,0,2.8C35,103.6,35,100.4,34.2,100.6z"/>
		<path class="st0" d="M33.9,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,100.6c-0.8-0.2-0.8,3,0,2.8C33.1,103.6,33.1,100.4,32.2,100.6z"/>
		<path class="st0" d="M31.9,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,100.6c-0.8-0.2-0.8,3,0,2.8C31.1,103.6,31.1,100.4,30.2,100.6z"/>
		<path class="st0" d="M30,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,100.6c-0.8-0.2-0.8,3,0,2.8C29.1,103.6,29.1,100.4,28.3,100.6z"/>
		<path class="st0" d="M28,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,100.6c-0.8-0.2-0.8,3,0,2.8C27.1,103.6,27.1,100.4,26.3,100.6z"/>
		<path class="st0" d="M26,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,100.6c-0.8-0.2-0.8,3,0,2.8C25.1,103.6,25.1,100.4,24.3,100.6z"/>
		<path class="st0" d="M24,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,100.6c-0.8-0.2-0.8,3,0,2.8C23.1,103.6,23.1,100.4,22.3,100.6z"/>
		<path class="st0" d="M22,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,100.6c-0.8-0.2-0.8,3,0,2.8C21.1,103.6,21.1,100.4,20.3,100.6z"/>
		<path class="st0" d="M20,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,100.6c-0.8-0.2-0.8,3,0,2.8C19.1,103.6,19.1,100.4,18.3,100.6z"/>
		<path class="st0" d="M18,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,100.6c-0.8-0.2-0.8,3,0,2.8C17.2,103.6,17.2,100.4,16.3,100.6z"/>
		<path class="st0" d="M16,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,100.6c-0.8-0.2-0.8,3,0,2.8C15.2,103.6,15.2,100.4,14.3,100.6z"/>
		<path class="st0" d="M14.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,100.6c-0.8-0.2-0.8,3,0,2.8C13.2,103.6,13.2,100.4,12.4,100.6z"/>
		<path class="st0" d="M12.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,100.6c-0.8-0.2-0.8,3,0,2.8C11.2,103.6,11.2,100.4,10.4,100.6z"/>
		<path class="st0" d="M10.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,100.6c-0.8-0.2-0.8,3,0,2.8C9.2,103.6,9.2,100.4,8.4,100.6z"/>
		<path class="st0" d="M8.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,100.6c-0.8-0.2-0.8,3,0,2.8C7.2,103.6,7.2,100.4,6.4,100.6z"/>
		<path class="st0" d="M6.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,100.6c-0.8-0.2-0.8,3,0,2.8C5.2,103.6,5.2,100.4,4.4,100.6z"/>
		<path class="st0" d="M4.1,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,100.6c-0.8-0.2-0.8,3,0,2.8C70.7,103.6,70.7,100.4,69.8,100.6z"/>
		<path class="st0" d="M69.5,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,100.6c-0.8-0.2-0.8,3,0,2.8C68.7,103.6,68.7,100.4,67.8,100.6z"/>
		<path class="st0" d="M67.5,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,100.6c-0.8-0.2-0.8,3,0,2.8C64.7,103.6,64.7,100.4,63.9,100.6z"/>
		<path class="st0" d="M63.6,101.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,101.5c-0.8-0.2-0.8,3,0,2.8C65.8,104.5,65.8,101.4,65,101.5z"/>
		<path class="st1" d="M64.7,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,101.5c-0.8-0.2-0.8,3,0,2.8C63.8,104.5,63.8,101.4,63,101.5z"/>
		<path class="st1" d="M62.7,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,101.5c-0.8-0.2-0.8,3,0,2.8C61.8,104.5,61.8,101.4,61,101.5z"/>
		<path class="st1" d="M60.7,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,101.5c-0.8-0.2-0.8,3,0,2.8C59.8,104.5,59.8,101.4,59,101.5z"/>
		<path class="st1" d="M58.7,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,101.5c-0.8-0.2-0.8,3,0,2.8C57.9,104.5,57.9,101.4,57,101.5z"/>
		<path class="st1" d="M56.7,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,101.5c-0.8-0.2-0.8,3,0,2.8C55.9,104.5,55.9,101.4,55,101.5z"/>
		<path class="st1" d="M54.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,101.5c-0.8-0.2-0.8,3,0,2.8C53.9,104.5,53.9,101.4,53.1,101.5z"/>
		<path class="st1" d="M52.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,101.5c-0.8-0.2-0.8,3,0,2.8C51.9,104.5,51.9,101.4,51.1,101.5z"/>
		<path class="st1" d="M50.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,101.5c-0.8-0.2-0.8,3,0,2.8C49.9,104.5,49.9,101.4,49.1,101.5z"/>
		<path class="st1" d="M48.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,101.5c-0.8-0.2-0.8,3,0,2.8C47.9,104.5,47.9,101.4,47.1,101.5z"/>
		<path class="st1" d="M46.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,101.5c-0.8-0.2-0.8,3,0,2.8C45.9,104.5,45.9,101.4,45.1,101.5z"/>
		<path class="st1" d="M44.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,101.5c-0.8-0.2-0.8,3,0,2.8C43.9,104.5,43.9,101.4,43.1,101.5z"/>
		<path class="st1" d="M42.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,101.5c-0.8-0.2-0.8,3,0,2.8C42,104.5,42,101.4,41.1,101.5z"/>
		<path class="st1" d="M40.8,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,101.5c-0.8-0.2-0.8,3,0,2.8C40,104.5,40,101.4,39.1,101.5z"/>
		<path class="st1" d="M38.9,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,101.5c-0.8-0.2-0.8,3,0,2.8C38,104.5,38,101.4,37.2,101.5z"/>
		<path class="st1" d="M36.9,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,101.5c-0.8-0.2-0.8,3,0,2.8C36,104.5,36,101.4,35.2,101.5z"/>
		<path class="st1" d="M34.9,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,101.5c-0.8-0.2-0.8,3,0,2.8C34.1,104.5,34.1,101.4,33.3,101.5z"/>
		<path class="st1" d="M33,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,101.5c-0.8-0.2-0.8,3,0,2.8C32.1,104.5,32.1,101.4,31.3,101.5z"/>
		<path class="st1" d="M31,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,101.5c-0.8-0.2-0.8,3,0,2.8C30.1,104.5,30.2,101.4,29.3,101.5z"/>
		<path class="st1" d="M29,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,101.5c-0.8-0.2-0.8,3,0,2.8C28.2,104.5,28.2,101.4,27.3,101.5z"/>
		<path class="st1" d="M27,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,101.5c-0.8-0.2-0.8,3,0,2.8C26.2,104.5,26.2,101.4,25.3,101.5z"/>
		<path class="st1" d="M25.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,101.5c-0.8-0.2-0.8,3,0,2.8C24.2,104.5,24.2,101.4,23.4,101.5z"/>
		<path class="st1" d="M23.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,101.5c-0.8-0.2-0.8,3,0,2.8C22.2,104.5,22.2,101.4,21.4,101.5z"/>
		<path class="st1" d="M21.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,101.5c-0.8-0.2-0.8,3,0,2.8C20.2,104.5,20.2,101.4,19.4,101.5z"/>
		<path class="st1" d="M19.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,101.5c-0.8-0.2-0.8,3,0,2.8C18.2,104.5,18.2,101.4,17.4,101.5z"/>
		<path class="st1" d="M17.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,101.5c-0.8-0.2-0.8,3,0,2.8C16.2,104.5,16.2,101.4,15.4,101.5z"/>
		<path class="st1" d="M15.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,101.5c-0.8-0.2-0.8,3,0,2.8C14.3,104.5,14.3,101.4,13.4,101.5z"/>
		<path class="st1" d="M13.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,101.5c-0.8-0.2-0.8,3,0,2.8C12.3,104.5,12.3,101.4,11.4,101.5z"/>
		<path class="st1" d="M11.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M9.5,101.5c-0.8-0.2-0.8,3,0,2.8C10.3,104.5,10.3,101.4,9.5,101.5z"/>
		<path class="st2" d="M9.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,101.5c-0.8-0.2-0.8,3,0,2.8C8.3,104.5,8.3,101.4,7.5,101.5z"/>
		<path class="st2" d="M7.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,101.5c-0.8-0.2-0.8,3,0,2.8C6.3,104.5,6.3,101.4,5.5,101.5z"/>
		<path class="st2" d="M5.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,101.5c-0.8-0.2-0.8,3,0,2.8C4.3,104.5,4.3,101.4,3.5,101.5z"/>
		<path class="st2" d="M3.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,101.5c-0.8-0.2-0.8,3,0,2.8C129.2,104.5,129.2,101.4,128.4,101.5z"/>
		<path class="st1" d="M128.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,101.5c-0.8-0.2-0.8,3,0,2.8C127.2,104.5,127.2,101.4,126.4,101.5z"/>
		<path class="st1" d="M126.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,101.5c-0.8-0.2-0.8,3,0,2.8C125.3,104.5,125.3,101.4,124.4,101.5z"/>
		<path class="st1" d="M124.1,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,101.5c-0.8-0.2-0.8,3,0,2.8C123.3,104.5,123.3,101.4,122.4,101.5z"/>
		<path class="st1" d="M122.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,101.5c-0.8-0.2-0.8,3,0,2.8C121.3,104.5,121.3,101.4,120.5,101.5z"/>
		<path class="st1" d="M120.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,101.5c-0.8-0.2-0.8,3,0,2.8C119.3,104.5,119.3,101.4,118.5,101.5z"/>
		<path class="st1" d="M118.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,101.5c-0.8-0.2-0.8,3,0,2.8C117.3,104.5,117.3,101.4,116.5,101.5z"/>
		<path class="st1" d="M116.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,101.5c-0.8-0.2-0.8,3,0,2.8C115.3,104.5,115.3,101.4,114.5,101.5z"/>
		<path class="st1" d="M114.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,101.5c-0.8-0.2-0.8,3,0,2.8C113.3,104.5,113.3,101.4,112.5,101.5z"/>
		<path class="st1" d="M112.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,101.5c-0.8-0.2-0.8,3,0,2.8C111.3,104.5,111.3,101.4,110.5,101.5z"/>
		<path class="st1" d="M110.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,101.5c-0.8-0.2-0.8,3,0,2.8C109.4,104.5,109.4,101.4,108.5,101.5z"/>
		<path class="st1" d="M108.2,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,101.5c-0.8-0.2-0.8,3,0,2.8C107.4,104.5,107.4,101.4,106.5,101.5z"/>
		<path class="st1" d="M106.3,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,101.5c-0.8-0.2-0.8,3,0,2.8C105.4,104.5,105.4,101.4,104.6,101.5z"/>
		<path class="st1" d="M104.3,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,101.5c-0.8-0.2-0.8,3,0,2.8C103.4,104.5,103.4,101.4,102.6,101.5z"/>
		<path class="st1" d="M102.3,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,101.5c-0.8-0.2-0.8,3,0,2.8C101.4,104.5,101.4,101.4,100.6,101.5z"/>
		<path class="st1" d="M100.3,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,101.5c-0.8-0.2-0.8,3,0,2.8C99.4,104.5,99.4,101.4,98.6,101.5z"/>
		<path class="st1" d="M98.3,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,101.5c-0.8-0.2-0.8,3,0,2.8C97.5,104.5,97.6,101.4,96.7,101.5z"/>
		<path class="st1" d="M96.4,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,101.5c-0.8-0.2-0.8,3,0,2.8C95.6,104.5,95.6,101.4,94.7,101.5z"/>
		<path class="st1" d="M94.4,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,101.5c-0.8-0.2-0.8,3,0,2.8C93.6,104.5,93.6,101.4,92.7,101.5z"/>
		<path class="st1" d="M92.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,101.5c-0.8-0.2-0.8,3,0,2.8C91.6,104.5,91.6,101.4,90.8,101.5z"/>
		<path class="st1" d="M90.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,101.5c-0.8-0.2-0.8,3,0,2.8C89.6,104.5,89.6,101.4,88.8,101.5z"/>
		<path class="st1" d="M88.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,101.5c-0.8-0.2-0.8,3,0,2.8C87.6,104.5,87.6,101.4,86.8,101.5z"/>
		<path class="st1" d="M86.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,101.5c-0.8-0.2-0.8,3,0,2.8C85.6,104.5,85.6,101.4,84.8,101.5z"/>
		<path class="st1" d="M84.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,101.5c-0.8-0.2-0.8,3,0,2.8C83.6,104.5,83.6,101.4,82.8,101.5z"/>
		<path class="st1" d="M82.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,101.5c-0.8-0.2-0.8,3,0,2.8C81.7,104.5,81.7,101.4,80.8,101.5z"/>
		<path class="st1" d="M80.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,101.5c-0.8-0.2-0.8,3,0,2.8C79.7,104.5,79.7,101.4,78.8,101.5z"/>
		<path class="st1" d="M78.5,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,101.5c-0.8-0.2-0.8,3,0,2.8C77.7,104.5,77.7,101.4,76.9,101.5z"/>
		<path class="st1" d="M76.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,101.5c-0.8-0.2-0.8,3,0,2.8C75.7,104.5,75.7,101.4,74.9,101.5z"/>
		<path class="st1" d="M74.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,101.5c-0.8-0.2-0.8,3,0,2.8C73.7,104.5,73.7,101.4,72.9,101.5z"/>
		<path class="st1" d="M72.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,101.5c-0.8-0.2-0.8,3,0,2.8C71.7,104.5,71.7,101.4,70.9,101.5z"/>
		<path class="st1" d="M70.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,101.5c-0.8-0.2-0.8,3,0,2.8C69.7,104.5,69.7,101.4,68.9,101.5z"/>
		<path class="st1" d="M68.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,101.5c-0.8-0.2-0.8,3,0,2.8C67.7,104.5,67.7,101.4,66.9,101.5z"/>
		<path class="st1" d="M66.6,102.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M59,275c-0.8-0.2-0.8,3,0,2.8C59.8,278,59.8,274.8,59,275z"/>
		<path class="st1" d="M58.7,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,275c-0.8-0.2-0.8,3,0,2.8C57.9,278,57.9,274.8,57,275z"/>
		<path class="st1" d="M56.7,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,275c-0.8-0.2-0.8,3,0,2.8C55.9,278,55.9,274.8,55,275z"/>
		<path class="st1" d="M54.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,275c-0.8-0.2-0.8,3,0,2.8C53.9,278,53.9,274.8,53.1,275z"/>
		<path class="st1" d="M52.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,275c-0.8-0.2-0.8,3,0,2.8C51.9,278,51.9,274.8,51.1,275z"/>
		<path class="st1" d="M50.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,275c-0.8-0.2-0.8,3,0,2.8C49.9,278,49.9,274.8,49.1,275z"/>
		<path class="st1" d="M48.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,275c-0.8-0.2-0.8,3,0,2.8C47.9,278,47.9,274.8,47.1,275z"/>
		<path class="st1" d="M46.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,275c-0.8-0.2-0.8,3,0,2.8C45.9,278,45.9,274.8,45.1,275z"/>
		<path class="st1" d="M44.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,275c-0.8-0.2-0.8,3,0,2.8C43.9,278,43.9,274.8,43.1,275z"/>
		<path class="st1" d="M42.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,275c-0.8-0.2-0.8,3,0,2.8C42,278,42,274.8,41.1,275z"/>
		<path class="st1" d="M40.8,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,275c-0.8-0.2-0.8,3,0,2.8C40,278,40,274.8,39.1,275z"/>
		<path class="st1" d="M38.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,275c-0.8-0.2-0.8,3,0,2.8C38,278,38,274.8,37.2,275z"/>
		<path class="st1" d="M36.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,275c-0.8-0.2-0.8,3,0,2.8C36,278,36,274.8,35.2,275z"/>
		<path class="st1" d="M34.9,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,275c-0.8-0.2-0.8,3,0,2.8C34.1,278,34.1,274.8,33.3,275z"/>
		<path class="st1" d="M33,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,275c-0.8-0.2-0.8,3,0,2.8C32.1,278,32.1,274.8,31.3,275z"/>
		<path class="st1" d="M31,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,275c-0.8-0.2-0.8,3,0,2.8C30.1,278,30.2,274.8,29.3,275z"/>
		<path class="st1" d="M29,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,275c-0.8-0.2-0.8,3,0,2.8C28.2,278,28.2,274.8,27.3,275z"/>
		<path class="st1" d="M27,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,275c-0.8-0.2-0.8,3,0,2.8C26.2,278,26.2,274.8,25.3,275z"/>
		<path class="st1" d="M25.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,275c-0.8-0.2-0.8,3,0,2.8C24.2,278,24.2,274.8,23.4,275z"/>
		<path class="st1" d="M23.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,275c-0.8-0.2-0.8,3,0,2.8C22.2,278,22.2,274.8,21.4,275z"/>
		<path class="st1" d="M21.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,275c-0.8-0.2-0.8,3,0,2.8C20.2,278,20.2,274.8,19.4,275z"/>
		<path class="st1" d="M19.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,275c-0.8-0.2-0.8,3,0,2.8C18.2,278,18.2,274.8,17.4,275z"/>
		<path class="st1" d="M17.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,275c-0.8-0.2-0.8,3,0,2.8C16.2,278,16.2,274.8,15.4,275z"/>
		<path class="st1" d="M15.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,275c-0.8-0.2-0.8,3,0,2.8C14.3,278,14.3,274.8,13.4,275z"/>
		<path class="st1" d="M13.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,275c-0.8-0.2-0.8,3,0,2.8C12.3,278,12.3,274.8,11.4,275z"/>
		<path class="st1" d="M11.1,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,275c-0.8-0.2-0.8,3,0,2.8C10.3,278,10.3,274.8,9.5,275z"/>
		<path class="st1" d="M9.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,275c-0.8-0.2-0.8,3,0,2.8C8.3,278,8.3,274.8,7.5,275z"/>
		<path class="st1" d="M7.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,275c-0.8-0.2-0.8,3,0,2.8C6.3,278,6.3,274.8,5.5,275z"/>
		<path class="st1" d="M5.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,275c-0.8-0.2-0.8,3,0,2.8C4.3,278,4.3,274.8,3.5,275z"/>
		<path class="st1" d="M3.2,275.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M5.5,286.9c-0.8-0.2-0.8,3,0,2.8C6.3,290,6.3,286.8,5.5,286.9z"/>
		<path class="st1" d="M5.2,287.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,286.9c-0.8-0.2-0.8,3,0,2.8C4.3,290,4.3,286.8,3.5,286.9z"/>
		<path class="st1" d="M3.2,287.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M18.3,8.2c-0.8-0.2-0.8,3,0,2.8C19.1,11.3,19.1,8.1,18.3,8.2z"/>
		<path class="st1" d="M18,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,8.2c-0.8-0.2-0.8,3,0,2.8C17.2,11.3,17.2,8.1,16.3,8.2z"/>
		<path class="st1" d="M16,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.4,8.2c-0.8-0.2-0.8,3,0,2.8C23.2,11.3,23.2,8.1,22.4,8.2z"/>
		<path class="st1" d="M22.1,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.4,8.2c-0.8-0.2-0.8,3,0,2.8C21.2,11.3,21.2,8.1,20.4,8.2z"/>
		<path class="st1" d="M20.1,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,8.2c-0.8-0.2-0.8,3,0,2.8C15.2,11.3,15.2,8.1,14.3,8.2z"/>
		<path class="st1" d="M14.1,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,8.2c-0.8-0.2-0.8,3,0,2.8C13.2,11.3,13.2,8.1,12.4,8.2z"/>
		<path class="st1" d="M12.1,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,8.2c-0.8-0.2-0.8,3,0,2.8C11.2,11.3,11.2,8.1,10.4,8.2z"/>
		<path class="st1" d="M10.1,9.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,8.2c-0.8-0.2-0.8,3,0,2.8C9.2,11.3,9.2,8.1,8.4,8.2z"/>
		<path class="st1" d="M8.1,9.1C7.2,8.8,7.3,7.4,8.3,7.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,8.2c-0.8-0.2-0.8,3,0,2.8C7.2,11.3,7.2,8.1,6.4,8.2z"/>
		<path class="st1" d="M6.1,9.1C5.2,8.8,5.3,7.4,6.3,7.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,8.2c-0.8-0.2-0.8,3,0,2.8C5.2,11.3,5.2,8.1,4.4,8.2z"/>
		<path class="st1" d="M4.1,9.1C3.2,8.8,3.3,7.4,4.3,7.3c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,9c-0.8-0.2-0.8,3,0,2.8C65.8,12.1,65.8,8.9,65,9z"/>
		<path class="st1" d="M64.7,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,9c-0.8-0.2-0.8,3,0,2.8C63.8,12.1,63.8,8.9,63,9z"/>
		<path class="st1" d="M62.7,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,9c-0.8-0.2-0.8,3,0,2.8C61.8,12.1,61.8,8.9,61,9z"/>
		<path class="st1" d="M60.7,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,9c-0.8-0.2-0.8,3,0,2.8C59.8,12.1,59.8,8.9,59,9z"/>
		<path class="st1" d="M58.7,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,9c-0.8-0.2-0.8,3,0,2.8C57.9,12.1,57.9,8.9,57,9z"/>
		<path class="st1" d="M56.7,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,9c-0.8-0.2-0.8,3,0,2.8C55.9,12.1,55.9,8.9,55,9z"/>
		<path class="st1" d="M54.8,9.9C53.9,9.6,54,8.2,55,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,9c-0.8-0.2-0.8,3,0,2.8C53.9,12.1,53.9,8.9,53.1,9z"/>
		<path class="st1" d="M52.8,9.9C51.9,9.6,52,8.2,53,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,9c-0.8-0.2-0.8,3,0,2.8C51.9,12.1,51.9,8.9,51.1,9z"/>
		<path class="st1" d="M50.8,9.9c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,9c-0.8-0.2-0.8,3,0,2.8C49.9,12.1,49.9,8.9,49.1,9z"/>
		<path class="st1" d="M48.8,9.9C47.9,9.7,48,8.2,49,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,9c-0.8-0.2-0.8,3,0,2.8C47.9,12.1,47.9,8.9,47.1,9z"/>
		<path class="st1" d="M46.8,9.9C45.9,9.7,46,8.2,47,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,9c-0.8-0.2-0.8,3,0,2.8C45.9,12.1,45.9,8.9,45.1,9z"/>
		<path class="st1" d="M44.8,9.9C43.9,9.7,44,8.2,45,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,9c-0.8-0.2-0.8,3,0,2.8C43.9,12.1,43.9,8.9,43.1,9z"/>
		<path class="st1" d="M42.8,9.9C41.9,9.7,42,8.2,43,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,9c-0.8-0.2-0.8,3,0,2.8C42,12.1,42,8.9,41.1,9z"/>
		<path class="st1" d="M40.8,9.9C39.9,9.7,40,8.2,41,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,9c-0.8-0.2-0.8,3,0,2.8C40,12.1,40,8.9,39.1,9z"/>
		<path class="st1" d="M38.9,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,9c-0.8-0.2-0.8,3,0,2.8C38,12.1,38,8.9,37.2,9z"/>
		<path class="st1" d="M36.9,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,9c-0.8-0.2-0.8,3,0,2.8C36,12.1,36,8.9,35.2,9z"/>
		<path class="st1" d="M34.9,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,9c-0.8-0.2-0.8,3,0,2.8C34.1,12.1,34.1,8.9,33.3,9z"/>
		<path class="st1" d="M33,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,9c-0.8-0.2-0.8,3,0,2.8C32.1,12.1,32.1,8.9,31.3,9z"/>
		<path class="st1" d="M31,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M29.3,9c-0.8-0.2-0.8,3,0,2.8C30.1,12.1,30.2,8.9,29.3,9z"/>
		<path class="st1" d="M29,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M27.3,9c-0.8-0.2-0.8,3,0,2.8C28.2,12.1,28.2,8.9,27.3,9z"/>
		<path class="st1" d="M27,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M25.3,9c-0.8-0.2-0.8,3,0,2.8C26.2,12.1,26.2,8.9,25.3,9z"/>
		<path class="st1" d="M25.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M23.4,9c-0.8-0.2-0.8,3,0,2.8C24.2,12.1,24.2,8.9,23.4,9z"/>
		<path class="st1" d="M23.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M21.4,9c-0.8-0.2-0.8,3,0,2.8C22.2,12.1,22.2,8.9,21.4,9z"/>
		<path class="st1" d="M21.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M19.4,9c-0.8-0.2-0.8,3,0,2.8C20.2,12.1,20.2,8.9,19.4,9z"/>
		<path class="st1" d="M19.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M17.4,9c-0.8-0.2-0.8,3,0,2.8C18.2,12.1,18.2,8.9,17.4,9z"/>
		<path class="st1" d="M17.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M15.4,9c-0.8-0.2-0.8,3,0,2.8C16.2,12.1,16.2,8.9,15.4,9z"/>
		<path class="st1" d="M15.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M13.4,9c-0.8-0.2-0.8,3,0,2.8C14.3,12.1,14.3,8.9,13.4,9z"/>
		<path class="st1" d="M13.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M11.4,9c-0.8-0.2-0.8,3,0,2.8C12.3,12.1,12.3,8.9,11.4,9z"/>
		<path class="st1" d="M11.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M9.5,9c-0.8-0.2-0.8,3,0,2.8C10.3,12.1,10.3,8.9,9.5,9z"/>
		<path class="st1" d="M9.2,9.9c-1-0.2-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M7.5,9c-0.8-0.2-0.8,3,0,2.8C8.3,12.1,8.3,8.9,7.5,9z"/>
		<path class="st1" d="M7.2,9.9c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M5.5,9c-0.8-0.2-0.8,3,0,2.8C6.3,12.1,6.3,8.9,5.5,9z"/>
		<path class="st1" d="M5.2,9.9C4.3,9.7,4.4,8.2,5.4,8.1c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M3.5,9c-0.8-0.2-0.8,3,0,2.8C4.3,12.1,4.3,8.9,3.5,9z"/>
		<path class="st1" d="M3.2,9.9C2.3,9.7,2.4,8.2,3.4,8.1C4.4,8,4.8,9.4,4,9.9"/>
	</g>
	<g>
		<path class="st0" d="M128.4,9c-0.8-0.2-0.8,3,0,2.8C129.2,12.1,129.2,8.9,128.4,9z"/>
		<path class="st1" d="M128.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,9c-0.8-0.2-0.8,3,0,2.8C127.2,12.1,127.2,8.9,126.4,9z"/>
		<path class="st1" d="M126.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,9c-0.8-0.2-0.8,3,0,2.8C125.3,12.1,125.3,8.9,124.4,9z"/>
		<path class="st1" d="M124.1,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,9c-0.8-0.2-0.8,3,0,2.8C123.3,12.1,123.3,8.9,122.4,9z"/>
		<path class="st1" d="M122.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,9c-0.8-0.2-0.8,3,0,2.8C121.3,12.1,121.3,8.9,120.5,9z"/>
		<path class="st1" d="M120.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,9c-0.8-0.2-0.8,3,0,2.8C119.3,12.1,119.3,8.9,118.5,9z"/>
		<path class="st1" d="M118.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,9c-0.8-0.2-0.8,3,0,2.8C117.3,12.1,117.3,8.9,116.5,9z"/>
		<path class="st1" d="M116.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,9c-0.8-0.2-0.8,3,0,2.8C115.3,12.1,115.3,8.9,114.5,9z"/>
		<path class="st1" d="M114.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,9c-0.8-0.2-0.8,3,0,2.8C113.3,12.1,113.3,8.9,112.5,9z"/>
		<path class="st1" d="M112.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,9c-0.8-0.2-0.8,3,0,2.8C111.3,12.1,111.3,8.9,110.5,9z"/>
		<path class="st1" d="M110.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,9c-0.8-0.2-0.8,3,0,2.8C109.4,12.1,109.4,8.9,108.5,9z"/>
		<path class="st1" d="M108.2,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,9c-0.8-0.2-0.8,3,0,2.8C107.4,12.1,107.4,8.9,106.5,9z"/>
		<path class="st1" d="M106.3,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,9c-0.8-0.2-0.8,3,0,2.8C105.4,12.1,105.4,8.9,104.6,9z"/>
		<path class="st1" d="M104.3,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,9c-0.8-0.2-0.8,3,0,2.8C103.4,12.1,103.4,8.9,102.6,9z"/>
		<path class="st1" d="M102.3,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,9c-0.8-0.2-0.8,3,0,2.8C101.4,12.1,101.4,8.9,100.6,9z"/>
		<path class="st1" d="M100.3,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,9c-0.8-0.2-0.8,3,0,2.8C99.4,12.1,99.4,8.9,98.6,9z"/>
		<path class="st1" d="M98.3,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,9c-0.8-0.2-0.8,3,0,2.8C97.5,12.1,97.6,8.9,96.7,9z"/>
		<path class="st1" d="M96.4,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,9c-0.8-0.2-0.8,3,0,2.8C95.6,12.1,95.6,8.9,94.7,9z"/>
		<path class="st1" d="M94.4,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,9c-0.8-0.2-0.8,3,0,2.8C93.6,12.1,93.6,8.9,92.7,9z"/>
		<path class="st1" d="M92.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,9c-0.8-0.2-0.8,3,0,2.8C91.6,12.1,91.6,8.9,90.8,9z"/>
		<path class="st1" d="M90.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,9c-0.8-0.2-0.8,3,0,2.8C89.6,12.1,89.6,8.9,88.8,9z"/>
		<path class="st1" d="M88.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,9c-0.8-0.2-0.8,3,0,2.8C87.6,12.1,87.6,8.9,86.8,9z"/>
		<path class="st1" d="M86.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,9c-0.8-0.2-0.8,3,0,2.8C85.6,12.1,85.6,8.9,84.8,9z"/>
		<path class="st1" d="M84.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,9c-0.8-0.2-0.8,3,0,2.8C83.6,12.1,83.6,8.9,82.8,9z"/>
		<path class="st1" d="M82.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,9c-0.8-0.2-0.8,3,0,2.8C81.7,12.1,81.7,8.9,80.8,9z"/>
		<path class="st1" d="M80.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,9c-0.8-0.2-0.8,3,0,2.8C79.7,12.1,79.7,8.9,78.8,9z"/>
		<path class="st1" d="M78.5,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,9c-0.8-0.2-0.8,3,0,2.8C77.7,12.1,77.7,8.9,76.9,9z"/>
		<path class="st1" d="M76.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,9c-0.8-0.2-0.8,3,0,2.8C75.7,12.1,75.7,8.9,74.9,9z"/>
		<path class="st1" d="M74.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,9c-0.8-0.2-0.8,3,0,2.8C73.7,12.1,73.7,8.9,72.9,9z"/>
		<path class="st1" d="M72.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,9c-0.8-0.2-0.8,3,0,2.8C71.7,12.1,71.7,8.9,70.9,9z"/>
		<path class="st1" d="M70.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,9c-0.8-0.2-0.8,3,0,2.8C69.7,12.1,69.7,8.9,68.9,9z"/>
		<path class="st1" d="M68.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,9c-0.8-0.2-0.8,3,0,2.8C67.7,12.1,67.7,8.9,66.9,9z"/>
		<path class="st1" d="M66.6,9.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,9.8c-0.8-0.2-0.8,3,0,2.8C66.7,12.8,66.7,9.7,65.9,9.8z"/>
		<path class="st1" d="M65.6,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,9.8c-0.8-0.2-0.8,3,0,2.8C64.7,12.8,64.7,9.7,63.9,9.8z"/>
		<path class="st1" d="M63.6,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,9.8c-0.8-0.2-0.8,3,0,2.8C62.7,12.8,62.8,9.7,61.9,9.8z"/>
		<path class="st1" d="M61.6,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,9.8c-0.8-0.2-0.8,3,0,2.8C60.8,12.8,60.8,9.7,59.9,9.8z"/>
		<path class="st1" d="M59.6,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,9.8c-0.8-0.2-0.8,3,0,2.8C58.8,12.8,58.8,9.7,57.9,9.8z"/>
		<path class="st1" d="M57.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,9.8c-0.8-0.2-0.8,3,0,2.8C56.8,12.8,56.8,9.7,56,9.8z"/>
		<path class="st1" d="M55.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,9.8c-0.8-0.2-0.8,3,0,2.8C54.8,12.8,54.8,9.7,54,9.8z"/>
		<path class="st1" d="M53.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,9.8c-0.8-0.2-0.8,3,0,2.8C52.8,12.8,52.8,9.7,52,9.8z"/>
		<path class="st1" d="M51.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,9.8c-0.8-0.2-0.8,3,0,2.8C50.8,12.8,50.8,9.7,50,9.8z"/>
		<path class="st1" d="M49.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,9.8c-0.8-0.2-0.8,3,0,2.8C48.8,12.8,48.8,9.7,48,9.8z"/>
		<path class="st1" d="M47.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,9.8c-0.8-0.2-0.8,3,0,2.8C46.9,12.8,46.9,9.7,46,9.8z"/>
		<path class="st1" d="M45.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,9.8c-0.8-0.2-0.8,3,0,2.8C44.9,12.8,44.9,9.7,44,9.8z"/>
		<path class="st1" d="M43.7,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,9.8c-0.8-0.2-0.8,3,0,2.8C42.9,12.8,42.9,9.7,42.1,9.8z"/>
		<path class="st1" d="M41.8,10.7c-1-0.3-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,9.8c-0.8-0.2-0.8,3,0,2.8C40.9,12.8,40.9,9.7,40.1,9.8z"/>
		<path class="st1" d="M39.8,10.7C38.8,10.4,39,9,40,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,9.8c-0.8-0.2-0.8,3,0,2.8C38.9,12.8,38.9,9.7,38.1,9.8z"/>
		<path class="st1" d="M37.8,10.7C36.9,10.4,37,9,38,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,9.8c-0.8-0.2-0.8,3,0,2.8C36.9,12.8,36.9,9.7,36.1,9.8z"/>
		<path class="st1" d="M35.8,10.7C34.9,10.4,35,9,36,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,9.8c-0.8-0.2-0.8,3,0,2.8C35,12.8,35,9.7,34.2,9.8z"/>
		<path class="st1" d="M33.9,10.7C33,10.4,33.1,9,34.1,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,9.8c-0.8-0.2-0.8,3,0,2.8C33.1,12.8,33.1,9.7,32.2,9.8z"/>
		<path class="st1" d="M31.9,10.7C31,10.4,31.1,9,32.1,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,9.8c-0.8-0.2-0.8,3,0,2.8C31.1,12.8,31.1,9.7,30.2,9.8z"/>
		<path class="st1" d="M30,10.7c-1-0.3-0.9-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M28.3,9.8c-0.8-0.2-0.8,3,0,2.8C29.1,12.8,29.1,9.7,28.3,9.8z"/>
		<path class="st1" d="M28,10.7c-1-0.3-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M26.3,9.8c-0.8-0.2-0.8,3,0,2.8C27.1,12.8,27.1,9.7,26.3,9.8z"/>
		<path class="st1" d="M26,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M24.3,9.8c-0.8-0.2-0.8,3,0,2.8C25.1,12.8,25.1,9.7,24.3,9.8z"/>
		<path class="st1" d="M24,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.3,9.8c-0.8-0.2-0.8,3,0,2.8C23.1,12.8,23.1,9.7,22.3,9.8z"/>
		<path class="st1" d="M22,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.3,9.8c-0.8-0.2-0.8,3,0,2.8C21.1,12.8,21.1,9.7,20.3,9.8z"/>
		<path class="st1" d="M20,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M18.3,9.8c-0.8-0.2-0.8,3,0,2.8C19.1,12.8,19.1,9.7,18.3,9.8z"/>
		<path class="st1" d="M18,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,9.8c-0.8-0.2-0.8,3,0,2.8C17.2,12.8,17.2,9.7,16.3,9.8z"/>
		<path class="st1" d="M16,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,9.8c-0.8-0.2-0.8,3,0,2.8C15.2,12.8,15.2,9.7,14.3,9.8z"/>
		<path class="st1" d="M14.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,9.8c-0.8-0.2-0.8,3,0,2.8C13.2,12.8,13.2,9.7,12.4,9.8z"/>
		<path class="st1" d="M12.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,9.8c-0.8-0.2-0.8,3,0,2.8C11.2,12.8,11.2,9.7,10.4,9.8z"/>
		<path class="st1" d="M10.1,10.7C9.2,10.4,9.3,9,10.3,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,9.8c-0.8-0.2-0.8,3,0,2.8C9.2,12.8,9.2,9.7,8.4,9.8z"/>
		<path class="st1" d="M8.1,10.7C7.2,10.4,7.3,9,8.3,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,9.8c-0.8-0.2-0.8,3,0,2.8C7.2,12.8,7.2,9.7,6.4,9.8z"/>
		<path class="st1" d="M6.1,10.7C5.2,10.4,5.3,9,6.3,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,9.8c-0.8-0.2-0.8,3,0,2.8C5.2,12.8,5.2,9.7,4.4,9.8z"/>
		<path class="st1" d="M4.1,10.7C3.2,10.4,3.3,9,4.3,8.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,9.8c-0.8-0.2-0.8,3,0,2.8C130.1,12.8,130.1,9.7,129.3,9.8z"/>
		<path class="st1" d="M129,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,9.8c-0.8-0.2-0.8,3,0,2.8C128.2,12.8,128.2,9.7,127.3,9.8z"/>
		<path class="st1" d="M127,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,9.8c-0.8-0.2-0.8,3,0,2.8C126.2,12.8,126.2,9.7,125.3,9.8z"/>
		<path class="st1" d="M125.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,9.8c-0.8-0.2-0.8,3,0,2.8C124.2,12.8,124.2,9.7,123.4,9.8z"/>
		<path class="st1" d="M123.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,9.8c-0.8-0.2-0.8,3,0,2.8C122.2,12.8,122.2,9.7,121.4,9.8z"/>
		<path class="st1" d="M121.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,9.8c-0.8-0.2-0.8,3,0,2.8C120.2,12.8,120.2,9.7,119.4,9.8z"/>
		<path class="st1" d="M119.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,9.8c-0.8-0.2-0.8,3,0,2.8C118.2,12.8,118.2,9.7,117.4,9.8z"/>
		<path class="st1" d="M117.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,9.8c-0.8-0.2-0.8,3,0,2.8C116.2,12.8,116.2,9.7,115.4,9.8z"/>
		<path class="st1" d="M115.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,9.8c-0.8-0.2-0.8,3,0,2.8C114.3,12.8,114.3,9.7,113.4,9.8z"/>
		<path class="st1" d="M113.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,9.8c-0.8-0.2-0.8,3,0,2.8C112.3,12.8,112.3,9.7,111.4,9.8z"/>
		<path class="st1" d="M111.1,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,9.8c-0.8-0.2-0.8,3,0,2.8C110.3,12.8,110.3,9.7,109.4,9.8z"/>
		<path class="st1" d="M109.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,9.8c-0.8-0.2-0.8,3,0,2.8C108.3,12.8,108.3,9.7,107.5,9.8z"/>
		<path class="st1" d="M107.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,9.8c-0.8-0.2-0.8,3,0,2.8C106.3,12.8,106.3,9.7,105.5,9.8z"/>
		<path class="st1" d="M105.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,9.8c-0.8-0.2-0.8,3,0,2.8C104.3,12.8,104.3,9.7,103.5,9.8z"/>
		<path class="st1" d="M103.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,9.8c-0.8-0.2-0.8,3,0,2.8C102.3,12.8,102.3,9.7,101.5,9.8z"/>
		<path class="st1" d="M101.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,9.8c-0.8-0.2-0.8,3,0,2.8C100.3,12.8,100.3,9.7,99.5,9.8z"/>
		<path class="st1" d="M99.2,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,9.8c-0.8-0.2-0.8,3,0,2.8C98.5,12.8,98.5,9.7,97.6,9.8z"/>
		<path class="st1" d="M97.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,9.8c-0.8-0.2-0.8,3,0,2.8C96.5,12.8,96.5,9.7,95.7,9.8z"/>
		<path class="st1" d="M95.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,9.8c-0.8-0.2-0.8,3,0,2.8C94.5,12.8,94.5,9.7,93.7,9.8z"/>
		<path class="st1" d="M93.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,9.8c-0.8-0.2-0.8,3,0,2.8C92.5,12.8,92.5,9.7,91.7,9.8z"/>
		<path class="st1" d="M91.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,9.8c-0.8-0.2-0.8,3,0,2.8C90.5,12.8,90.5,9.7,89.7,9.8z"/>
		<path class="st1" d="M89.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,9.8c-0.8-0.2-0.8,3,0,2.8C88.5,12.8,88.5,9.7,87.7,9.8z"/>
		<path class="st1" d="M87.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,9.8c-0.8-0.2-0.8,3,0,2.8C86.5,12.8,86.5,9.7,85.7,9.8z"/>
		<path class="st1" d="M85.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,9.8c-0.8-0.2-0.8,3,0,2.8C84.6,12.8,84.6,9.7,83.7,9.8z"/>
		<path class="st1" d="M83.4,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,9.8c-0.8-0.2-0.8,3,0,2.8C82.6,12.8,82.6,9.7,81.7,9.8z"/>
		<path class="st1" d="M81.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,9.8c-0.8-0.2-0.8,3,0,2.8C80.6,12.8,80.6,9.7,79.8,9.8z"/>
		<path class="st1" d="M79.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,9.8c-0.8-0.2-0.8,3,0,2.8C78.6,12.8,78.6,9.7,77.8,9.8z"/>
		<path class="st1" d="M77.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,9.8c-0.8-0.2-0.8,3,0,2.8C76.6,12.8,76.6,9.7,75.8,9.8z"/>
		<path class="st1" d="M75.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,9.8c-0.8-0.2-0.8,3,0,2.8C74.6,12.8,74.6,9.7,73.8,9.8z"/>
		<path class="st1" d="M73.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,9.8c-0.8-0.2-0.8,3,0,2.8C72.6,12.8,72.6,9.7,71.8,9.8z"/>
		<path class="st1" d="M71.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,9.8c-0.8-0.2-0.8,3,0,2.8C70.7,12.8,70.7,9.7,69.8,9.8z"/>
		<path class="st1" d="M69.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,9.8c-0.8-0.2-0.8,3,0,2.8C68.7,12.8,68.7,9.7,67.8,9.8z"/>
		<path class="st1" d="M67.5,10.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,10.7c-0.8-0.2-0.8,3,0,2.8C65.8,13.7,65.8,10.6,65,10.7z"/>
		<path class="st1" d="M64.7,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,10.7c-0.8-0.2-0.8,3,0,2.8C63.8,13.7,63.8,10.6,63,10.7z"/>
		<path class="st1" d="M62.7,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,10.7c-0.8-0.2-0.8,3,0,2.8C61.8,13.7,61.8,10.6,61,10.7z"/>
		<path class="st1" d="M60.7,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,10.7c-0.8-0.2-0.8,3,0,2.8C59.8,13.7,59.8,10.6,59,10.7z"/>
		<path class="st1" d="M58.7,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,10.7c-0.8-0.2-0.8,3,0,2.8C57.9,13.7,57.9,10.6,57,10.7z"/>
		<path class="st1" d="M56.7,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,10.7c-0.8-0.2-0.8,3,0,2.8C55.9,13.7,55.9,10.6,55,10.7z"/>
		<path class="st1" d="M54.8,11.6C53.9,11.3,54,9.9,55,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,10.7c-0.8-0.2-0.8,3,0,2.8C53.9,13.7,53.9,10.6,53.1,10.7z"/>
		<path class="st1" d="M52.8,11.6c-1-0.2-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,10.7c-0.8-0.2-0.8,3,0,2.8C51.9,13.7,51.9,10.6,51.1,10.7z"/>
		<path class="st1" d="M50.8,11.6c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,10.7c-0.8-0.2-0.8,3,0,2.8C49.9,13.7,49.9,10.6,49.1,10.7z"/>
		<path class="st1" d="M48.8,11.6C47.9,11.4,48,9.9,49,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,10.7c-0.8-0.2-0.8,3,0,2.8C47.9,13.7,47.9,10.6,47.1,10.7z"/>
		<path class="st1" d="M46.8,11.6C45.9,11.4,46,9.9,47,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,10.7c-0.8-0.2-0.8,3,0,2.8C45.9,13.7,45.9,10.6,45.1,10.7z"/>
		<path class="st1" d="M44.8,11.6C43.9,11.4,44,9.9,45,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,10.7c-0.8-0.2-0.8,3,0,2.8C43.9,13.7,43.9,10.6,43.1,10.7z"/>
		<path class="st1" d="M42.8,11.6C41.9,11.4,42,9.9,43,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,10.7c-0.8-0.2-0.8,3,0,2.8C42,13.7,42,10.6,41.1,10.7z"/>
		<path class="st1" d="M40.8,11.6C39.9,11.4,40,9.9,41,9.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,10.7c-0.8-0.2-0.8,3,0,2.8C40,13.7,40,10.6,39.1,10.7z"/>
		<path class="st1" d="M38.9,11.6c-1-0.2-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,10.7c-0.8-0.2-0.8,3,0,2.8C38,13.7,38,10.6,37.2,10.7z"/>
		<path class="st1" d="M36.9,11.6c-1-0.2-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,10.7c-0.8-0.2-0.8,3,0,2.8C36,13.7,36,10.6,35.2,10.7z"/>
		<path class="st1" d="M34.9,11.6c-0.9-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,10.7c-0.8-0.2-0.8,3,0,2.8C34.1,13.7,34.1,10.6,33.3,10.7z"/>
		<path class="st1" d="M33,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,10.7c-0.8-0.2-0.8,3,0,2.8C32.1,13.7,32.1,10.6,31.3,10.7z"/>
		<path class="st1" d="M31,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,10.7c-0.8-0.2-0.8,3,0,2.8C30.1,13.7,30.2,10.6,29.3,10.7z"/>
		<path class="st1" d="M29,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,10.7c-0.8-0.2-0.8,3,0,2.8C28.2,13.7,28.2,10.6,27.3,10.7z"/>
		<path class="st1" d="M27,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,10.7c-0.8-0.2-0.8,3,0,2.8C26.2,13.7,26.2,10.6,25.3,10.7z"/>
		<path class="st1" d="M25.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,10.7c-0.8-0.2-0.8,3,0,2.8C24.2,13.7,24.2,10.6,23.4,10.7z"/>
		<path class="st1" d="M23.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,10.7c-0.8-0.2-0.8,3,0,2.8C22.2,13.7,22.2,10.6,21.4,10.7z"/>
		<path class="st1" d="M21.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,10.7c-0.8-0.2-0.8,3,0,2.8C20.2,13.7,20.2,10.6,19.4,10.7z"/>
		<path class="st1" d="M19.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,10.7c-0.8-0.2-0.8,3,0,2.8C18.2,13.7,18.2,10.6,17.4,10.7z"/>
		<path class="st1" d="M17.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,10.7c-0.8-0.2-0.8,3,0,2.8C16.2,13.7,16.2,10.6,15.4,10.7z"/>
		<path class="st1" d="M15.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,10.7c-0.8-0.2-0.8,3,0,2.8C14.3,13.7,14.3,10.6,13.4,10.7z"/>
		<path class="st1" d="M13.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,10.7c-0.8-0.2-0.8,3,0,2.8C12.3,13.7,12.3,10.6,11.4,10.7z"/>
		<path class="st1" d="M11.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,10.7c-0.8-0.2-0.8,3,0,2.8C10.3,13.7,10.3,10.6,9.5,10.7z"/>
		<path class="st1" d="M9.2,11.6c-1-0.2-0.8-1.7,0.1-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,10.7c-0.8-0.2-0.8,3,0,2.8C8.3,13.7,8.3,10.6,7.5,10.7z"/>
		<path class="st1" d="M7.2,11.6c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,10.7c-0.8-0.2-0.8,3,0,2.8C6.3,13.7,6.3,10.6,5.5,10.7z"/>
		<path class="st1" d="M5.2,11.6c-0.9-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,10.7c-0.8-0.2-0.8,3,0,2.8C4.3,13.7,4.3,10.6,3.5,10.7z"/>
		<path class="st1" d="M3.2,11.6c-0.9-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,10.7c-0.8-0.2-0.8,3,0,2.8C129.2,13.7,129.2,10.6,128.4,10.7z"/>
		<path class="st1" d="M128.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,10.7c-0.8-0.2-0.8,3,0,2.8C127.2,13.7,127.2,10.6,126.4,10.7z"/>
		<path class="st1" d="M126.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,10.7c-0.8-0.2-0.8,3,0,2.8C125.3,13.7,125.3,10.6,124.4,10.7z"/>
		<path class="st1" d="M124.1,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,10.7c-0.8-0.2-0.8,3,0,2.8C123.3,13.7,123.3,10.6,122.4,10.7z"/>
		<path class="st1" d="M122.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,10.7c-0.8-0.2-0.8,3,0,2.8C121.3,13.7,121.3,10.6,120.5,10.7z"/>
		<path class="st1" d="M120.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,10.7c-0.8-0.2-0.8,3,0,2.8C119.3,13.7,119.3,10.6,118.5,10.7z"/>
		<path class="st1" d="M118.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,10.7c-0.8-0.2-0.8,3,0,2.8C117.3,13.7,117.3,10.6,116.5,10.7z"/>
		<path class="st1" d="M116.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,10.7c-0.8-0.2-0.8,3,0,2.8C115.3,13.7,115.3,10.6,114.5,10.7z"/>
		<path class="st1" d="M114.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,10.7c-0.8-0.2-0.8,3,0,2.8C113.3,13.7,113.3,10.6,112.5,10.7z"/>
		<path class="st1" d="M112.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,10.7c-0.8-0.2-0.8,3,0,2.8C111.3,13.7,111.3,10.6,110.5,10.7z"/>
		<path class="st1" d="M110.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,10.7c-0.8-0.2-0.8,3,0,2.8C109.4,13.7,109.4,10.6,108.5,10.7z"/>
		<path class="st1" d="M108.2,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,10.7c-0.8-0.2-0.8,3,0,2.8C107.4,13.7,107.4,10.6,106.5,10.7z"/>
		<path class="st1" d="M106.3,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,10.7c-0.8-0.2-0.8,3,0,2.8C105.4,13.7,105.4,10.6,104.6,10.7z"/>
		<path class="st1" d="M104.3,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,10.7c-0.8-0.2-0.8,3,0,2.8C103.4,13.7,103.4,10.6,102.6,10.7z"/>
		<path class="st1" d="M102.3,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,10.7c-0.8-0.2-0.8,3,0,2.8C101.4,13.7,101.4,10.6,100.6,10.7z"/>
		<path class="st1" d="M100.3,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,10.7c-0.8-0.2-0.8,3,0,2.8C99.4,13.7,99.4,10.6,98.6,10.7z"/>
		<path class="st1" d="M98.3,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,10.7c-0.8-0.2-0.8,3,0,2.8C97.5,13.7,97.6,10.6,96.7,10.7z"/>
		<path class="st1" d="M96.4,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,10.7c-0.8-0.2-0.8,3,0,2.8C95.6,13.7,95.6,10.6,94.7,10.7z"/>
		<path class="st1" d="M94.4,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,10.7c-0.8-0.2-0.8,3,0,2.8C93.6,13.7,93.6,10.6,92.7,10.7z"/>
		<path class="st1" d="M92.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,10.7c-0.8-0.2-0.8,3,0,2.8C91.6,13.7,91.6,10.6,90.8,10.7z"/>
		<path class="st1" d="M90.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,10.7c-0.8-0.2-0.8,3,0,2.8C89.6,13.7,89.6,10.6,88.8,10.7z"/>
		<path class="st1" d="M88.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,10.7c-0.8-0.2-0.8,3,0,2.8C87.6,13.7,87.6,10.6,86.8,10.7z"/>
		<path class="st1" d="M86.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,10.7c-0.8-0.2-0.8,3,0,2.8C85.6,13.7,85.6,10.6,84.8,10.7z"/>
		<path class="st1" d="M84.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,10.7c-0.8-0.2-0.8,3,0,2.8C83.6,13.7,83.6,10.6,82.8,10.7z"/>
		<path class="st1" d="M82.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,10.7c-0.8-0.2-0.8,3,0,2.8C81.7,13.7,81.7,10.6,80.8,10.7z"/>
		<path class="st1" d="M80.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,10.7c-0.8-0.2-0.8,3,0,2.8C79.7,13.7,79.7,10.6,78.8,10.7z"/>
		<path class="st1" d="M78.5,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,10.7c-0.8-0.2-0.8,3,0,2.8C77.7,13.7,77.7,10.6,76.9,10.7z"/>
		<path class="st1" d="M76.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,10.7c-0.8-0.2-0.8,3,0,2.8C75.7,13.7,75.7,10.6,74.9,10.7z"/>
		<path class="st1" d="M74.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,10.7c-0.8-0.2-0.8,3,0,2.8C73.7,13.7,73.7,10.6,72.9,10.7z"/>
		<path class="st1" d="M72.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,10.7c-0.8-0.2-0.8,3,0,2.8C71.7,13.7,71.7,10.6,70.9,10.7z"/>
		<path class="st1" d="M70.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,10.7c-0.8-0.2-0.8,3,0,2.8C69.7,13.7,69.7,10.6,68.9,10.7z"/>
		<path class="st1" d="M68.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,10.7c-0.8-0.2-0.8,3,0,2.8C67.7,13.7,67.7,10.6,66.9,10.7z"/>
		<path class="st1" d="M66.6,11.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,11.5c-0.8-0.2-0.8,3,0,2.8C66.7,14.5,66.7,11.3,65.9,11.5z"/>
		<path class="st1" d="M65.6,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,11.5c-0.8-0.2-0.8,3,0,2.8C64.7,14.5,64.7,11.3,63.9,11.5z"/>
		<path class="st1" d="M63.6,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,11.5c-0.8-0.2-0.8,3,0,2.8C62.7,14.5,62.8,11.3,61.9,11.5z"/>
		<path class="st1" d="M61.6,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,11.5c-0.8-0.2-0.8,3,0,2.8C60.8,14.5,60.8,11.3,59.9,11.5z"/>
		<path class="st1" d="M59.6,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,11.5c-0.8-0.2-0.8,3,0,2.8C58.8,14.5,58.8,11.3,57.9,11.5z"/>
		<path class="st1" d="M57.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,11.5c-0.8-0.2-0.8,3,0,2.8C56.8,14.5,56.8,11.3,56,11.5z"/>
		<path class="st1" d="M55.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,11.5c-0.8-0.2-0.8,3,0,2.8C54.8,14.5,54.8,11.3,54,11.5z"/>
		<path class="st1" d="M53.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,11.5c-0.8-0.2-0.8,3,0,2.8C52.8,14.5,52.8,11.3,52,11.5z"/>
		<path class="st1" d="M51.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,11.5c-0.8-0.2-0.8,3,0,2.8C50.8,14.5,50.8,11.3,50,11.5z"/>
		<path class="st1" d="M49.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,11.5c-0.8-0.2-0.8,3,0,2.8C48.8,14.5,48.8,11.3,48,11.5z"/>
		<path class="st1" d="M47.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,11.5c-0.8-0.2-0.8,3,0,2.8C46.9,14.5,46.9,11.3,46,11.5z"/>
		<path class="st1" d="M45.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,11.5c-0.8-0.2-0.8,3,0,2.8C44.9,14.5,44.9,11.3,44,11.5z"/>
		<path class="st1" d="M43.7,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,11.5c-0.8-0.2-0.8,3,0,2.8C42.9,14.5,42.9,11.3,42.1,11.5z"/>
		<path class="st1" d="M41.8,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,11.5c-0.8-0.2-0.8,3,0,2.8C40.9,14.5,40.9,11.3,40.1,11.5z"/>
		<path class="st1" d="M39.8,12.4c-1-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,11.5c-0.8-0.2-0.8,3,0,2.8C38.9,14.5,38.9,11.3,38.1,11.5z"/>
		<path class="st1" d="M37.8,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,11.5c-0.8-0.2-0.8,3,0,2.8C36.9,14.5,36.9,11.3,36.1,11.5z"/>
		<path class="st1" d="M35.8,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,11.5c-0.8-0.2-0.8,3,0,2.8C35,14.5,35,11.3,34.2,11.5z"/>
		<path class="st1" d="M33.9,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,11.5c-0.8-0.2-0.8,3,0,2.8C33.1,14.5,33.1,11.3,32.2,11.5z"/>
		<path class="st1" d="M31.9,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,11.5c-0.8-0.2-0.8,3,0,2.8C31.1,14.5,31.1,11.3,30.2,11.5z"/>
		<path class="st1" d="M30,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,11.5c-0.8-0.2-0.8,3,0,2.8C29.1,14.5,29.1,11.3,28.3,11.5z"/>
		<path class="st1" d="M28,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,11.5c-0.8-0.2-0.8,3,0,2.8C27.1,14.5,27.1,11.3,26.3,11.5z"/>
		<path class="st1" d="M26,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,11.5c-0.8-0.2-0.8,3,0,2.8C25.1,14.5,25.1,11.3,24.3,11.5z"/>
		<path class="st1" d="M24,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,11.5c-0.8-0.2-0.8,3,0,2.8C23.1,14.5,23.1,11.3,22.3,11.5z"/>
		<path class="st1" d="M22,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,11.5c-0.8-0.2-0.8,3,0,2.8C21.1,14.5,21.1,11.3,20.3,11.5z"/>
		<path class="st1" d="M20,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,11.5c-0.8-0.2-0.8,3,0,2.8C19.1,14.5,19.1,11.3,18.3,11.5z"/>
		<path class="st1" d="M18,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,11.5c-0.8-0.2-0.8,3,0,2.8C17.2,14.5,17.2,11.3,16.3,11.5z"/>
		<path class="st1" d="M16,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,11.5c-0.8-0.2-0.8,3,0,2.8C15.2,14.5,15.2,11.3,14.3,11.5z"/>
		<path class="st1" d="M14.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,11.5c-0.8-0.2-0.8,3,0,2.8C13.2,14.5,13.2,11.3,12.4,11.5z"/>
		<path class="st1" d="M12.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,11.5c-0.8-0.2-0.8,3,0,2.8C11.2,14.5,11.2,11.3,10.4,11.5z"/>
		<path class="st1" d="M10.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,11.5c-0.8-0.2-0.8,3,0,2.8C9.2,14.5,9.2,11.3,8.4,11.5z"/>
		<path class="st1" d="M8.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,11.5c-0.8-0.2-0.8,3,0,2.8C7.2,14.5,7.2,11.3,6.4,11.5z"/>
		<path class="st1" d="M6.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,11.5c-0.8-0.2-0.8,3,0,2.8C5.2,14.5,5.2,11.3,4.4,11.5z"/>
		<path class="st1" d="M4.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,11.5c-0.8-0.2-0.8,3,0,2.8C130.1,14.5,130.1,11.3,129.3,11.5z"/>
		<path class="st1" d="M129,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,11.5c-0.8-0.2-0.8,3,0,2.8C128.2,14.5,128.2,11.3,127.3,11.5z"/>
		<path class="st1" d="M127,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,11.5c-0.8-0.2-0.8,3,0,2.8C126.2,14.5,126.2,11.3,125.3,11.5z"/>
		<path class="st1" d="M125.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,11.5c-0.8-0.2-0.8,3,0,2.8C124.2,14.5,124.2,11.3,123.4,11.5z"/>
		<path class="st1" d="M123.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,11.5c-0.8-0.2-0.8,3,0,2.8C122.2,14.5,122.2,11.3,121.4,11.5z"/>
		<path class="st1" d="M121.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,11.5c-0.8-0.2-0.8,3,0,2.8C120.2,14.5,120.2,11.3,119.4,11.5z"/>
		<path class="st1" d="M119.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,11.5c-0.8-0.2-0.8,3,0,2.8C118.2,14.5,118.2,11.3,117.4,11.5z"/>
		<path class="st1" d="M117.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,11.5c-0.8-0.2-0.8,3,0,2.8C116.2,14.5,116.2,11.3,115.4,11.5z"/>
		<path class="st1" d="M115.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,11.5c-0.8-0.2-0.8,3,0,2.8C114.3,14.5,114.3,11.3,113.4,11.5z"/>
		<path class="st1" d="M113.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,11.5c-0.8-0.2-0.8,3,0,2.8C112.3,14.5,112.3,11.3,111.4,11.5z"/>
		<path class="st1" d="M111.1,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,11.5c-0.8-0.2-0.8,3,0,2.8C110.3,14.5,110.3,11.3,109.4,11.5z"/>
		<path class="st1" d="M109.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,11.5c-0.8-0.2-0.8,3,0,2.8C108.3,14.5,108.3,11.3,107.5,11.5z"/>
		<path class="st1" d="M107.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,11.5c-0.8-0.2-0.8,3,0,2.8C106.3,14.5,106.3,11.3,105.5,11.5z"/>
		<path class="st1" d="M105.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,11.5c-0.8-0.2-0.8,3,0,2.8C104.3,14.5,104.3,11.3,103.5,11.5z"/>
		<path class="st1" d="M103.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,11.5c-0.8-0.2-0.8,3,0,2.8C102.3,14.5,102.3,11.3,101.5,11.5z"/>
		<path class="st1" d="M101.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,11.5c-0.8-0.2-0.8,3,0,2.8C100.3,14.5,100.3,11.3,99.5,11.5z"/>
		<path class="st1" d="M99.2,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,11.5c-0.8-0.2-0.8,3,0,2.8C98.5,14.5,98.5,11.3,97.6,11.5z"/>
		<path class="st1" d="M97.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,11.5c-0.8-0.2-0.8,3,0,2.8C96.5,14.5,96.5,11.3,95.7,11.5z"/>
		<path class="st1" d="M95.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,11.5c-0.8-0.2-0.8,3,0,2.8C94.5,14.5,94.5,11.3,93.7,11.5z"/>
		<path class="st1" d="M93.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,11.5c-0.8-0.2-0.8,3,0,2.8C92.5,14.5,92.5,11.3,91.7,11.5z"/>
		<path class="st1" d="M91.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,11.5c-0.8-0.2-0.8,3,0,2.8C90.5,14.5,90.5,11.3,89.7,11.5z"/>
		<path class="st1" d="M89.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,11.5c-0.8-0.2-0.8,3,0,2.8C88.5,14.5,88.5,11.3,87.7,11.5z"/>
		<path class="st1" d="M87.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,11.5c-0.8-0.2-0.8,3,0,2.8C86.5,14.5,86.5,11.3,85.7,11.5z"/>
		<path class="st1" d="M85.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,11.5c-0.8-0.2-0.8,3,0,2.8C84.6,14.5,84.6,11.3,83.7,11.5z"/>
		<path class="st1" d="M83.4,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,11.5c-0.8-0.2-0.8,3,0,2.8C82.6,14.5,82.6,11.3,81.7,11.5z"/>
		<path class="st1" d="M81.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,11.5c-0.8-0.2-0.8,3,0,2.8C80.6,14.5,80.6,11.3,79.8,11.5z"/>
		<path class="st1" d="M79.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,11.5c-0.8-0.2-0.8,3,0,2.8C78.6,14.5,78.6,11.3,77.8,11.5z"/>
		<path class="st1" d="M77.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,11.5c-0.8-0.2-0.8,3,0,2.8C76.6,14.5,76.6,11.3,75.8,11.5z"/>
		<path class="st1" d="M75.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,11.5c-0.8-0.2-0.8,3,0,2.8C74.6,14.5,74.6,11.3,73.8,11.5z"/>
		<path class="st1" d="M73.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,11.5c-0.8-0.2-0.8,3,0,2.8C72.6,14.5,72.6,11.3,71.8,11.5z"/>
		<path class="st1" d="M71.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,11.5c-0.8-0.2-0.8,3,0,2.8C70.7,14.5,70.7,11.3,69.8,11.5z"/>
		<path class="st1" d="M69.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,11.5c-0.8-0.2-0.8,3,0,2.8C68.7,14.5,68.7,11.3,67.8,11.5z"/>
		<path class="st1" d="M67.5,12.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,12.4c-0.8-0.2-0.8,3,0,2.8C65.8,15.4,65.8,12.3,65,12.4z"/>
		<path class="st1" d="M64.7,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,12.4c-0.8-0.2-0.8,3,0,2.8C63.8,15.4,63.8,12.3,63,12.4z"/>
		<path class="st1" d="M62.7,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,12.4c-0.8-0.2-0.8,3,0,2.8C61.8,15.4,61.8,12.3,61,12.4z"/>
		<path class="st1" d="M60.7,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,12.4c-0.8-0.2-0.8,3,0,2.8C59.8,15.4,59.8,12.3,59,12.4z"/>
		<path class="st1" d="M58.7,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,12.4c-0.8-0.2-0.8,3,0,2.8C57.9,15.4,57.9,12.3,57,12.4z"/>
		<path class="st1" d="M56.7,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,12.4c-0.8-0.2-0.8,3,0,2.8C55.9,15.4,55.9,12.3,55,12.4z"/>
		<path class="st1" d="M54.8,13.3C53.9,13,54,11.6,55,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,12.4c-0.8-0.2-0.8,3,0,2.8C53.9,15.4,53.9,12.3,53.1,12.4z"/>
		<path class="st1" d="M52.8,13.3C51.9,13,52,11.6,53,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,12.4c-0.8-0.2-0.8,3,0,2.8C51.9,15.4,51.9,12.3,51.1,12.4z"/>
		<path class="st1" d="M50.8,13.3C49.9,13,50,11.6,51,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,12.4c-0.8-0.2-0.8,3,0,2.8C49.9,15.4,49.9,12.3,49.1,12.4z"/>
		<path class="st1" d="M48.8,13.3C47.9,13,48,11.6,49,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,12.4c-0.8-0.2-0.8,3,0,2.8C47.9,15.4,47.9,12.3,47.1,12.4z"/>
		<path class="st1" d="M46.8,13.3C45.9,13,46,11.6,47,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,12.4c-0.8-0.2-0.8,3,0,2.8C45.9,15.4,45.9,12.3,45.1,12.4z"/>
		<path class="st1" d="M44.8,13.3C43.9,13,44,11.6,45,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,12.4c-0.8-0.2-0.8,3,0,2.8C43.9,15.4,43.9,12.3,43.1,12.4z"/>
		<path class="st1" d="M42.8,13.3C41.9,13,42,11.6,43,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,12.4c-0.8-0.2-0.8,3,0,2.8C42,15.4,42,12.3,41.1,12.4z"/>
		<path class="st1" d="M40.8,13.3C39.9,13,40,11.6,41,11.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,12.4c-0.8-0.2-0.8,3,0,2.8C40,15.4,40,12.3,39.1,12.4z"/>
		<path class="st1" d="M38.9,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,12.4c-0.8-0.2-0.8,3,0,2.8C38,15.4,38,12.3,37.2,12.4z"/>
		<path class="st1" d="M36.9,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,12.4c-0.8-0.2-0.8,3,0,2.8C36,15.4,36,12.3,35.2,12.4z"/>
		<path class="st1" d="M34.9,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,12.4c-0.8-0.2-0.8,3,0,2.8C34.1,15.4,34.1,12.3,33.3,12.4z"/>
		<path class="st1" d="M33,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,12.4c-0.8-0.2-0.8,3,0,2.8C32.1,15.4,32.1,12.3,31.3,12.4z"/>
		<path class="st1" d="M31,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,12.4c-0.8-0.2-0.8,3,0,2.8C30.1,15.4,30.2,12.3,29.3,12.4z"/>
		<path class="st1" d="M29,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,12.4c-0.8-0.2-0.8,3,0,2.8C28.2,15.4,28.2,12.3,27.3,12.4z"/>
		<path class="st1" d="M27,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,12.4c-0.8-0.2-0.8,3,0,2.8C26.2,15.4,26.2,12.3,25.3,12.4z"/>
		<path class="st1" d="M25.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,12.4c-0.8-0.2-0.8,3,0,2.8C24.2,15.4,24.2,12.3,23.4,12.4z"/>
		<path class="st1" d="M23.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,12.4c-0.8-0.2-0.8,3,0,2.8C22.2,15.4,22.2,12.3,21.4,12.4z"/>
		<path class="st1" d="M21.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,12.4c-0.8-0.2-0.8,3,0,2.8C20.2,15.4,20.2,12.3,19.4,12.4z"/>
		<path class="st1" d="M19.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,12.4c-0.8-0.2-0.8,3,0,2.8C18.2,15.4,18.2,12.3,17.4,12.4z"/>
		<path class="st1" d="M17.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,12.4c-0.8-0.2-0.8,3,0,2.8C16.2,15.4,16.2,12.3,15.4,12.4z"/>
		<path class="st1" d="M15.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,12.4c-0.8-0.2-0.8,3,0,2.8C14.3,15.4,14.3,12.3,13.4,12.4z"/>
		<path class="st1" d="M13.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,12.4c-0.8-0.2-0.8,3,0,2.8C12.3,15.4,12.3,12.3,11.4,12.4z"/>
		<path class="st1" d="M11.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,12.4c-0.8-0.2-0.8,3,0,2.8C10.3,15.4,10.3,12.3,9.5,12.4z"/>
		<path class="st1" d="M9.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,12.4c-0.8-0.2-0.8,3,0,2.8C8.3,15.4,8.3,12.3,7.5,12.4z"/>
		<path class="st1" d="M7.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,12.4c-0.8-0.2-0.8,3,0,2.8C6.3,15.4,6.3,12.3,5.5,12.4z"/>
		<path class="st1" d="M5.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,12.4c-0.8-0.2-0.8,3,0,2.8C4.3,15.4,4.3,12.3,3.5,12.4z"/>
		<path class="st1" d="M3.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,12.4c-0.8-0.2-0.8,3,0,2.8C129.2,15.4,129.2,12.3,128.4,12.4z"/>
		<path class="st1" d="M128.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,12.4c-0.8-0.2-0.8,3,0,2.8C127.2,15.4,127.2,12.3,126.4,12.4z"/>
		<path class="st1" d="M126.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,12.4c-0.8-0.2-0.8,3,0,2.8C125.3,15.4,125.3,12.3,124.4,12.4z"/>
		<path class="st1" d="M124.1,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,12.4c-0.8-0.2-0.8,3,0,2.8C123.3,15.4,123.3,12.3,122.4,12.4z"/>
		<path class="st1" d="M122.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,12.4c-0.8-0.2-0.8,3,0,2.8C121.3,15.4,121.3,12.3,120.5,12.4z"/>
		<path class="st1" d="M120.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,12.4c-0.8-0.2-0.8,3,0,2.8C119.3,15.4,119.3,12.3,118.5,12.4z"/>
		<path class="st1" d="M118.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,12.4c-0.8-0.2-0.8,3,0,2.8C117.3,15.4,117.3,12.3,116.5,12.4z"/>
		<path class="st1" d="M116.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,12.4c-0.8-0.2-0.8,3,0,2.8C115.3,15.4,115.3,12.3,114.5,12.4z"/>
		<path class="st1" d="M114.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,12.4c-0.8-0.2-0.8,3,0,2.8C113.3,15.4,113.3,12.3,112.5,12.4z"/>
		<path class="st1" d="M112.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,12.4c-0.8-0.2-0.8,3,0,2.8C111.3,15.4,111.3,12.3,110.5,12.4z"/>
		<path class="st1" d="M110.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,12.4c-0.8-0.2-0.8,3,0,2.8C109.4,15.4,109.4,12.3,108.5,12.4z"/>
		<path class="st1" d="M108.2,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,12.4c-0.8-0.2-0.8,3,0,2.8C107.4,15.4,107.4,12.3,106.5,12.4z"/>
		<path class="st1" d="M106.3,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,12.4c-0.8-0.2-0.8,3,0,2.8C105.4,15.4,105.4,12.3,104.6,12.4z"/>
		<path class="st1" d="M104.3,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,12.4c-0.8-0.2-0.8,3,0,2.8C103.4,15.4,103.4,12.3,102.6,12.4z"/>
		<path class="st1" d="M102.3,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,12.4c-0.8-0.2-0.8,3,0,2.8C101.4,15.4,101.4,12.3,100.6,12.4z"/>
		<path class="st1" d="M100.3,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,12.4c-0.8-0.2-0.8,3,0,2.8C99.4,15.4,99.4,12.3,98.6,12.4z"/>
		<path class="st1" d="M98.3,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,12.4c-0.8-0.2-0.8,3,0,2.8C97.5,15.4,97.6,12.3,96.7,12.4z"/>
		<path class="st1" d="M96.4,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,12.4c-0.8-0.2-0.8,3,0,2.8C95.6,15.4,95.6,12.3,94.7,12.4z"/>
		<path class="st1" d="M94.4,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,12.4c-0.8-0.2-0.8,3,0,2.8C93.6,15.4,93.6,12.3,92.7,12.4z"/>
		<path class="st1" d="M92.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,12.4c-0.8-0.2-0.8,3,0,2.8C91.6,15.4,91.6,12.3,90.8,12.4z"/>
		<path class="st1" d="M90.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,12.4c-0.8-0.2-0.8,3,0,2.8C89.6,15.4,89.6,12.3,88.8,12.4z"/>
		<path class="st1" d="M88.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,12.4c-0.8-0.2-0.8,3,0,2.8C87.6,15.4,87.6,12.3,86.8,12.4z"/>
		<path class="st1" d="M86.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,12.4c-0.8-0.2-0.8,3,0,2.8C85.6,15.4,85.6,12.3,84.8,12.4z"/>
		<path class="st1" d="M84.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,12.4c-0.8-0.2-0.8,3,0,2.8C83.6,15.4,83.6,12.3,82.8,12.4z"/>
		<path class="st1" d="M82.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,12.4c-0.8-0.2-0.8,3,0,2.8C81.7,15.4,81.7,12.3,80.8,12.4z"/>
		<path class="st1" d="M80.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,12.4c-0.8-0.2-0.8,3,0,2.8C79.7,15.4,79.7,12.3,78.8,12.4z"/>
		<path class="st1" d="M78.5,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,12.4c-0.8-0.2-0.8,3,0,2.8C77.7,15.4,77.7,12.3,76.9,12.4z"/>
		<path class="st1" d="M76.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,12.4c-0.8-0.2-0.8,3,0,2.8C75.7,15.4,75.7,12.3,74.9,12.4z"/>
		<path class="st1" d="M74.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,12.4c-0.8-0.2-0.8,3,0,2.8C73.7,15.4,73.7,12.3,72.9,12.4z"/>
		<path class="st1" d="M72.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,12.4c-0.8-0.2-0.8,3,0,2.8C71.7,15.4,71.7,12.3,70.9,12.4z"/>
		<path class="st1" d="M70.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,12.4c-0.8-0.2-0.8,3,0,2.8C69.7,15.4,69.7,12.3,68.9,12.4z"/>
		<path class="st1" d="M68.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,12.4c-0.8-0.2-0.8,3,0,2.8C67.7,15.4,67.7,12.3,66.9,12.4z"/>
		<path class="st1" d="M66.6,13.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,13.2c-0.8-0.2-0.8,3,0,2.8C66.7,16.2,66.7,13,65.9,13.2z"/>
		<path class="st1" d="M65.6,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,13.2c-0.8-0.2-0.8,3,0,2.8C64.7,16.2,64.7,13,63.9,13.2z"/>
		<path class="st1" d="M63.6,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,13.2c-0.8-0.2-0.8,3,0,2.8C62.7,16.2,62.8,13,61.9,13.2z"/>
		<path class="st1" d="M61.6,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,13.2c-0.8-0.2-0.8,3,0,2.8C60.8,16.2,60.8,13,59.9,13.2z"/>
		<path class="st1" d="M59.6,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,13.2c-0.8-0.2-0.8,3,0,2.8C58.8,16.2,58.8,13,57.9,13.2z"/>
		<path class="st1" d="M57.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,13.2c-0.8-0.2-0.8,3,0,2.8C56.8,16.2,56.8,13,56,13.2z"/>
		<path class="st1" d="M55.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,13.2c-0.8-0.2-0.8,3,0,2.8C54.8,16.2,54.8,13,54,13.2z"/>
		<path class="st1" d="M53.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,13.2c-0.8-0.2-0.8,3,0,2.8C52.8,16.2,52.8,13,52,13.2z"/>
		<path class="st1" d="M51.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,13.2c-0.8-0.2-0.8,3,0,2.8C50.8,16.2,50.8,13,50,13.2z"/>
		<path class="st1" d="M49.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,13.2c-0.8-0.2-0.8,3,0,2.8C48.8,16.2,48.8,13,48,13.2z"/>
		<path class="st1" d="M47.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,13.2c-0.8-0.2-0.8,3,0,2.8C46.9,16.2,46.9,13,46,13.2z"/>
		<path class="st1" d="M45.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,13.2c-0.8-0.2-0.8,3,0,2.8C44.9,16.2,44.9,13,44,13.2z"/>
		<path class="st1" d="M43.7,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,13.2c-0.8-0.2-0.8,3,0,2.8C42.9,16.2,42.9,13,42.1,13.2z"/>
		<path class="st1" d="M41.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,13.2c-0.8-0.2-0.8,3,0,2.8C40.9,16.2,40.9,13,40.1,13.2z"/>
		<path class="st1" d="M39.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,13.2c-0.8-0.2-0.8,3,0,2.8C38.9,16.2,38.9,13,38.1,13.2z"/>
		<path class="st1" d="M37.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,13.2c-0.8-0.2-0.8,3,0,2.8C36.9,16.2,36.9,13,36.1,13.2z"/>
		<path class="st1" d="M35.8,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,13.2c-0.8-0.2-0.8,3,0,2.8C35,16.2,35,13,34.2,13.2z"/>
		<path class="st1" d="M33.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,13.2c-0.8-0.2-0.8,3,0,2.8C33.1,16.2,33.1,13,32.2,13.2z"/>
		<path class="st1" d="M31.9,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,13.2c-0.8-0.2-0.8,3,0,2.8C31.1,16.2,31.1,13,30.2,13.2z"/>
		<path class="st1" d="M30,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,13.2c-0.8-0.2-0.8,3,0,2.8C29.1,16.2,29.1,13,28.3,13.2z"/>
		<path class="st1" d="M28,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,13.2c-0.8-0.2-0.8,3,0,2.8C27.1,16.2,27.1,13,26.3,13.2z"/>
		<path class="st1" d="M26,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,13.2c-0.8-0.2-0.8,3,0,2.8C25.1,16.2,25.1,13,24.3,13.2z"/>
		<path class="st1" d="M24,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,13.2c-0.8-0.2-0.8,3,0,2.8C23.1,16.2,23.1,13,22.3,13.2z"/>
		<path class="st1" d="M22,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,13.2c-0.8-0.2-0.8,3,0,2.8C21.1,16.2,21.1,13,20.3,13.2z"/>
		<path class="st1" d="M20,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,13.2c-0.8-0.2-0.8,3,0,2.8C19.1,16.2,19.1,13,18.3,13.2z"/>
		<path class="st1" d="M18,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,13.2c-0.8-0.2-0.8,3,0,2.8C17.2,16.2,17.2,13,16.3,13.2z"/>
		<path class="st1" d="M16,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,13.2c-0.8-0.2-0.8,3,0,2.8C15.2,16.2,15.2,13,14.3,13.2z"/>
		<path class="st1" d="M14.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,13.2c-0.8-0.2-0.8,3,0,2.8C13.2,16.2,13.2,13,12.4,13.2z"/>
		<path class="st1" d="M12.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,13.2c-0.8-0.2-0.8,3,0,2.8C11.2,16.2,11.2,13,10.4,13.2z"/>
		<path class="st1" d="M10.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,13.2c-0.8-0.2-0.8,3,0,2.8C9.2,16.2,9.2,13,8.4,13.2z"/>
		<path class="st1" d="M8.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,13.2c-0.8-0.2-0.8,3,0,2.8C7.2,16.2,7.2,13,6.4,13.2z"/>
		<path class="st1" d="M6.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,13.2c-0.8-0.2-0.8,3,0,2.8C5.2,16.2,5.2,13,4.4,13.2z"/>
		<path class="st1" d="M4.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,13.2c-0.8-0.2-0.8,3,0,2.8C130.1,16.2,130.1,13,129.3,13.2z"/>
		<path class="st1" d="M129,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,13.2c-0.8-0.2-0.8,3,0,2.8C128.2,16.2,128.2,13,127.3,13.2z"/>
		<path class="st1" d="M127,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,13.2c-0.8-0.2-0.8,3,0,2.8C126.2,16.2,126.2,13,125.3,13.2z"/>
		<path class="st1" d="M125.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,13.2c-0.8-0.2-0.8,3,0,2.8C124.2,16.2,124.2,13,123.4,13.2z"/>
		<path class="st1" d="M123.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,13.2c-0.8-0.2-0.8,3,0,2.8C122.2,16.2,122.2,13,121.4,13.2z"/>
		<path class="st1" d="M121.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,13.2c-0.8-0.2-0.8,3,0,2.8C120.2,16.2,120.2,13,119.4,13.2z"/>
		<path class="st1" d="M119.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,13.2c-0.8-0.2-0.8,3,0,2.8C118.2,16.2,118.2,13,117.4,13.2z"/>
		<path class="st1" d="M117.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,13.2c-0.8-0.2-0.8,3,0,2.8C116.2,16.2,116.2,13,115.4,13.2z"/>
		<path class="st1" d="M115.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,13.2c-0.8-0.2-0.8,3,0,2.8C114.3,16.2,114.3,13,113.4,13.2z"/>
		<path class="st1" d="M113.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,13.2c-0.8-0.2-0.8,3,0,2.8C112.3,16.2,112.3,13,111.4,13.2z"/>
		<path class="st1" d="M111.1,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,13.2c-0.8-0.2-0.8,3,0,2.8C110.3,16.2,110.3,13,109.4,13.2z"/>
		<path class="st1" d="M109.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,13.2c-0.8-0.2-0.8,3,0,2.8C108.3,16.2,108.3,13,107.5,13.2z"/>
		<path class="st1" d="M107.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,13.2c-0.8-0.2-0.8,3,0,2.8C106.3,16.2,106.3,13,105.5,13.2z"/>
		<path class="st1" d="M105.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,13.2c-0.8-0.2-0.8,3,0,2.8C104.3,16.2,104.3,13,103.5,13.2z"/>
		<path class="st1" d="M103.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,13.2c-0.8-0.2-0.8,3,0,2.8C102.3,16.2,102.3,13,101.5,13.2z"/>
		<path class="st1" d="M101.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,13.2c-0.8-0.2-0.8,3,0,2.8C100.3,16.2,100.3,13,99.5,13.2z"/>
		<path class="st1" d="M99.2,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,13.2c-0.8-0.2-0.8,3,0,2.8C98.5,16.2,98.5,13,97.6,13.2z"/>
		<path class="st1" d="M97.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,13.2c-0.8-0.2-0.8,3,0,2.8C96.5,16.2,96.5,13,95.7,13.2z"/>
		<path class="st1" d="M95.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,13.2c-0.8-0.2-0.8,3,0,2.8C94.5,16.2,94.5,13,93.7,13.2z"/>
		<path class="st1" d="M93.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,13.2c-0.8-0.2-0.8,3,0,2.8C92.5,16.2,92.5,13,91.7,13.2z"/>
		<path class="st1" d="M91.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,13.2c-0.8-0.2-0.8,3,0,2.8C90.5,16.2,90.5,13,89.7,13.2z"/>
		<path class="st1" d="M89.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,13.2c-0.8-0.2-0.8,3,0,2.8C88.5,16.2,88.5,13,87.7,13.2z"/>
		<path class="st1" d="M87.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,13.2c-0.8-0.2-0.8,3,0,2.8C86.5,16.2,86.5,13,85.7,13.2z"/>
		<path class="st1" d="M85.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,13.2c-0.8-0.2-0.8,3,0,2.8C84.6,16.2,84.6,13,83.7,13.2z"/>
		<path class="st1" d="M83.4,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,13.2c-0.8-0.2-0.8,3,0,2.8C82.6,16.2,82.6,13,81.7,13.2z"/>
		<path class="st1" d="M81.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,13.2c-0.8-0.2-0.8,3,0,2.8C80.6,16.2,80.6,13,79.8,13.2z"/>
		<path class="st1" d="M79.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,13.2c-0.8-0.2-0.8,3,0,2.8C78.6,16.2,78.6,13,77.8,13.2z"/>
		<path class="st1" d="M77.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,13.2c-0.8-0.2-0.8,3,0,2.8C76.6,16.2,76.6,13,75.8,13.2z"/>
		<path class="st1" d="M75.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,13.2c-0.8-0.2-0.8,3,0,2.8C74.6,16.2,74.6,13,73.8,13.2z"/>
		<path class="st1" d="M73.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,13.2c-0.8-0.2-0.8,3,0,2.8C72.6,16.2,72.6,13,71.8,13.2z"/>
		<path class="st1" d="M71.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,13.2c-0.8-0.2-0.8,3,0,2.8C70.7,16.2,70.7,13,69.8,13.2z"/>
		<path class="st1" d="M69.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,13.2c-0.8-0.2-0.8,3,0,2.8C68.7,16.2,68.7,13,67.8,13.2z"/>
		<path class="st1" d="M67.5,14.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,14.1c-0.8-0.2-0.8,3,0,2.8C65.8,17.1,65.8,13.9,65,14.1z"/>
		<path class="st1" d="M64.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,14.1c-0.8-0.2-0.8,3,0,2.8C63.8,17.1,63.8,13.9,63,14.1z"/>
		<path class="st1" d="M62.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,14.1c-0.8-0.2-0.8,3,0,2.8C61.8,17.1,61.8,13.9,61,14.1z"/>
		<path class="st1" d="M60.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,14.1c-0.8-0.2-0.8,3,0,2.8C59.8,17.1,59.8,13.9,59,14.1z"/>
		<path class="st1" d="M58.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,14.1c-0.8-0.2-0.8,3,0,2.8C57.9,17.1,57.9,13.9,57,14.1z"/>
		<path class="st1" d="M56.7,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,14.1c-0.8-0.2-0.8,3,0,2.8C55.9,17.1,55.9,13.9,55,14.1z"/>
		<path class="st1" d="M54.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,14.1c-0.8-0.2-0.8,3,0,2.8C53.9,17.1,53.9,13.9,53.1,14.1z"/>
		<path class="st1" d="M52.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,14.1c-0.8-0.2-0.8,3,0,2.8C51.9,17.1,51.9,13.9,51.1,14.1z"/>
		<path class="st1" d="M50.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,14.1c-0.8-0.2-0.8,3,0,2.8C49.9,17.1,49.9,13.9,49.1,14.1z"/>
		<path class="st1" d="M48.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,14.1c-0.8-0.2-0.8,3,0,2.8C47.9,17.1,47.9,13.9,47.1,14.1z"/>
		<path class="st1" d="M46.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,14.1c-0.8-0.2-0.8,3,0,2.8C45.9,17.1,45.9,13.9,45.1,14.1z"/>
		<path class="st1" d="M44.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,14.1c-0.8-0.2-0.8,3,0,2.8C43.9,17.1,43.9,13.9,43.1,14.1z"/>
		<path class="st1" d="M42.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,14.1c-0.8-0.2-0.8,3,0,2.8C42,17.1,42,13.9,41.1,14.1z"/>
		<path class="st1" d="M40.8,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,14.1c-0.8-0.2-0.8,3,0,2.8C40,17.1,40,13.9,39.1,14.1z"/>
		<path class="st1" d="M38.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,14.1c-0.8-0.2-0.8,3,0,2.8C38,17.1,38,13.9,37.2,14.1z"/>
		<path class="st1" d="M36.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,14.1c-0.8-0.2-0.8,3,0,2.8C36,17.1,36,13.9,35.2,14.1z"/>
		<path class="st1" d="M34.9,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,14.1c-0.8-0.2-0.8,3,0,2.8C34.1,17.1,34.1,13.9,33.3,14.1z"/>
		<path class="st1" d="M33,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,14.1c-0.8-0.2-0.8,3,0,2.8C32.1,17.1,32.1,13.9,31.3,14.1z"/>
		<path class="st1" d="M31,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,14.1c-0.8-0.2-0.8,3,0,2.8C30.1,17.1,30.2,13.9,29.3,14.1z"/>
		<path class="st1" d="M29,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,14.1c-0.8-0.2-0.8,3,0,2.8C28.2,17.1,28.2,13.9,27.3,14.1z"/>
		<path class="st1" d="M27,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,14.1c-0.8-0.2-0.8,3,0,2.8C26.2,17.1,26.2,13.9,25.3,14.1z"/>
		<path class="st1" d="M25.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,14.1c-0.8-0.2-0.8,3,0,2.8C24.2,17.1,24.2,13.9,23.4,14.1z"/>
		<path class="st1" d="M23.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,14.1c-0.8-0.2-0.8,3,0,2.8C22.2,17.1,22.2,13.9,21.4,14.1z"/>
		<path class="st1" d="M21.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,14.1c-0.8-0.2-0.8,3,0,2.8C20.2,17.1,20.2,13.9,19.4,14.1z"/>
		<path class="st1" d="M19.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,14.1c-0.8-0.2-0.8,3,0,2.8C18.2,17.1,18.2,13.9,17.4,14.1z"/>
		<path class="st1" d="M17.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,14.1c-0.8-0.2-0.8,3,0,2.8C16.2,17.1,16.2,13.9,15.4,14.1z"/>
		<path class="st1" d="M15.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,14.1c-0.8-0.2-0.8,3,0,2.8C14.3,17.1,14.3,13.9,13.4,14.1z"/>
		<path class="st1" d="M13.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,14.1c-0.8-0.2-0.8,3,0,2.8C12.3,17.1,12.3,13.9,11.4,14.1z"/>
		<path class="st1" d="M11.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,14.1c-0.8-0.2-0.8,3,0,2.8C10.3,17.1,10.3,13.9,9.5,14.1z"/>
		<path class="st1" d="M9.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,14.1c-0.8-0.2-0.8,3,0,2.8C8.3,17.1,8.3,13.9,7.5,14.1z"/>
		<path class="st1" d="M7.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,14.1c-0.8-0.2-0.8,3,0,2.8C6.3,17.1,6.3,13.9,5.5,14.1z"/>
		<path class="st1" d="M5.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,14.1c-0.8-0.2-0.8,3,0,2.8C4.3,17.1,4.3,13.9,3.5,14.1z"/>
		<path class="st1" d="M3.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,14.1c-0.8-0.2-0.8,3,0,2.8C129.2,17.1,129.2,13.9,128.4,14.1z"/>
		<path class="st1" d="M128.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,14.1c-0.8-0.2-0.8,3,0,2.8C127.2,17.1,127.2,13.9,126.4,14.1z"/>
		<path class="st1" d="M126.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,14.1c-0.8-0.2-0.8,3,0,2.8C125.3,17.1,125.3,13.9,124.4,14.1z"/>
		<path class="st1" d="M124.1,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,14.1c-0.8-0.2-0.8,3,0,2.8C123.3,17.1,123.3,13.9,122.4,14.1z"/>
		<path class="st1" d="M122.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,14.1c-0.8-0.2-0.8,3,0,2.8C121.3,17.1,121.3,13.9,120.5,14.1z"/>
		<path class="st1" d="M120.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,14.1c-0.8-0.2-0.8,3,0,2.8C119.3,17.1,119.3,13.9,118.5,14.1z"/>
		<path class="st1" d="M118.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,14.1c-0.8-0.2-0.8,3,0,2.8C117.3,17.1,117.3,13.9,116.5,14.1z"/>
		<path class="st1" d="M116.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,14.1c-0.8-0.2-0.8,3,0,2.8C115.3,17.1,115.3,13.9,114.5,14.1z"/>
		<path class="st1" d="M114.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,14.1c-0.8-0.2-0.8,3,0,2.8C113.3,17.1,113.3,13.9,112.5,14.1z"/>
		<path class="st1" d="M112.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,14.1c-0.8-0.2-0.8,3,0,2.8C111.3,17.1,111.3,13.9,110.5,14.1z"/>
		<path class="st1" d="M110.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,14.1c-0.8-0.2-0.8,3,0,2.8C109.4,17.1,109.4,13.9,108.5,14.1z"/>
		<path class="st1" d="M108.2,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,14.1c-0.8-0.2-0.8,3,0,2.8C107.4,17.1,107.4,13.9,106.5,14.1z"/>
		<path class="st1" d="M106.3,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,14.1c-0.8-0.2-0.8,3,0,2.8C105.4,17.1,105.4,13.9,104.6,14.1z"/>
		<path class="st1" d="M104.3,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,14.1c-0.8-0.2-0.8,3,0,2.8C103.4,17.1,103.4,13.9,102.6,14.1z"/>
		<path class="st1" d="M102.3,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,14.1c-0.8-0.2-0.8,3,0,2.8C101.4,17.1,101.4,13.9,100.6,14.1z"/>
		<path class="st1" d="M100.3,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,14.1c-0.8-0.2-0.8,3,0,2.8C99.4,17.1,99.4,13.9,98.6,14.1z"/>
		<path class="st1" d="M98.3,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,14.1c-0.8-0.2-0.8,3,0,2.8C97.5,17.1,97.6,13.9,96.7,14.1z"/>
		<path class="st1" d="M96.4,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,14.1c-0.8-0.2-0.8,3,0,2.8C95.6,17.1,95.6,13.9,94.7,14.1z"/>
		<path class="st1" d="M94.4,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,14.1c-0.8-0.2-0.8,3,0,2.8C93.6,17.1,93.6,13.9,92.7,14.1z"/>
		<path class="st1" d="M92.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,14.1c-0.8-0.2-0.8,3,0,2.8C91.6,17.1,91.6,13.9,90.8,14.1z"/>
		<path class="st1" d="M90.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,14.1c-0.8-0.2-0.8,3,0,2.8C89.6,17.1,89.6,13.9,88.8,14.1z"/>
		<path class="st1" d="M88.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,14.1c-0.8-0.2-0.8,3,0,2.8C87.6,17.1,87.6,13.9,86.8,14.1z"/>
		<path class="st1" d="M86.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,14.1c-0.8-0.2-0.8,3,0,2.8C85.6,17.1,85.6,13.9,84.8,14.1z"/>
		<path class="st1" d="M84.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,14.1c-0.8-0.2-0.8,3,0,2.8C83.6,17.1,83.6,13.9,82.8,14.1z"/>
		<path class="st1" d="M82.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,14.1c-0.8-0.2-0.8,3,0,2.8C81.7,17.1,81.7,13.9,80.8,14.1z"/>
		<path class="st1" d="M80.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,14.1c-0.8-0.2-0.8,3,0,2.8C79.7,17.1,79.7,13.9,78.8,14.1z"/>
		<path class="st1" d="M78.5,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,14.1c-0.8-0.2-0.8,3,0,2.8C77.7,17.1,77.7,13.9,76.9,14.1z"/>
		<path class="st1" d="M76.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,14.1c-0.8-0.2-0.8,3,0,2.8C75.7,17.1,75.7,13.9,74.9,14.1z"/>
		<path class="st1" d="M74.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,14.1c-0.8-0.2-0.8,3,0,2.8C73.7,17.1,73.7,13.9,72.9,14.1z"/>
		<path class="st1" d="M72.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,14.1c-0.8-0.2-0.8,3,0,2.8C71.7,17.1,71.7,13.9,70.9,14.1z"/>
		<path class="st1" d="M70.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,14.1c-0.8-0.2-0.8,3,0,2.8C69.7,17.1,69.7,13.9,68.9,14.1z"/>
		<path class="st1" d="M68.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,14.1c-0.8-0.2-0.8,3,0,2.8C67.7,17.1,67.7,13.9,66.9,14.1z"/>
		<path class="st1" d="M66.6,15c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,14.9c-0.8-0.2-0.8,3,0,2.8C66.7,17.9,66.7,14.7,65.9,14.9z"/>
		<path class="st1" d="M65.6,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,14.9c-0.8-0.2-0.8,3,0,2.8C64.7,17.9,64.7,14.7,63.9,14.9z"/>
		<path class="st1" d="M63.6,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,14.9c-0.8-0.2-0.8,3,0,2.8C62.7,17.9,62.8,14.7,61.9,14.9z"/>
		<path class="st1" d="M61.6,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,14.9c-0.8-0.2-0.8,3,0,2.8C60.8,17.9,60.8,14.7,59.9,14.9z"/>
		<path class="st1" d="M59.6,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,14.9c-0.8-0.2-0.8,3,0,2.8C58.8,17.9,58.8,14.7,57.9,14.9z"/>
		<path class="st1" d="M57.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,14.9c-0.8-0.2-0.8,3,0,2.8C56.8,17.9,56.8,14.7,56,14.9z"/>
		<path class="st1" d="M55.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,14.9c-0.8-0.2-0.8,3,0,2.8C54.8,17.9,54.8,14.7,54,14.9z"/>
		<path class="st1" d="M53.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,14.9c-0.8-0.2-0.8,3,0,2.8C52.8,17.9,52.8,14.7,52,14.9z"/>
		<path class="st1" d="M51.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,14.9c-0.8-0.2-0.8,3,0,2.8C50.8,17.9,50.8,14.7,50,14.9z"/>
		<path class="st1" d="M49.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,14.9c-0.8-0.2-0.8,3,0,2.8C48.8,17.9,48.8,14.7,48,14.9z"/>
		<path class="st1" d="M47.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,14.9c-0.8-0.2-0.8,3,0,2.8C46.9,17.9,46.9,14.7,46,14.9z"/>
		<path class="st1" d="M45.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,14.9c-0.8-0.2-0.8,3,0,2.8C44.9,17.9,44.9,14.7,44,14.9z"/>
		<path class="st1" d="M43.7,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,14.9c-0.8-0.2-0.8,3,0,2.8C42.9,17.9,42.9,14.7,42.1,14.9z"/>
		<path class="st1" d="M41.8,15.8C40.9,15.5,41,14.1,42,14c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,14.9c-0.8-0.2-0.8,3,0,2.8C40.9,17.9,40.9,14.7,40.1,14.9z"/>
		<path class="st1" d="M39.8,15.8c-1-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,14.9c-0.8-0.2-0.8,3,0,2.8C38.9,17.9,38.9,14.7,38.1,14.9z"/>
		<path class="st1" d="M37.8,15.8C36.9,15.5,37,14,38,13.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,14.9c-0.8-0.2-0.8,3,0,2.8C36.9,17.9,36.9,14.7,36.1,14.9z"/>
		<path class="st1" d="M35.8,15.8C34.9,15.5,35,14,36,13.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,14.9c-0.8-0.2-0.8,3,0,2.8C35,17.9,35,14.7,34.2,14.9z"/>
		<path class="st1" d="M33.9,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,14.9c-0.8-0.2-0.8,3,0,2.8C33.1,17.9,33.1,14.7,32.2,14.9z"/>
		<path class="st1" d="M31.9,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,14.9c-0.8-0.2-0.8,3,0,2.8C31.1,17.9,31.1,14.7,30.2,14.9z"/>
		<path class="st1" d="M30,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,14.9c-0.8-0.2-0.8,3,0,2.8C29.1,17.9,29.1,14.7,28.3,14.9z"/>
		<path class="st1" d="M28,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,14.9c-0.8-0.2-0.8,3,0,2.8C27.1,17.9,27.1,14.7,26.3,14.9z"/>
		<path class="st1" d="M26,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,14.9c-0.8-0.2-0.8,3,0,2.8C25.1,17.9,25.1,14.7,24.3,14.9z"/>
		<path class="st1" d="M24,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,14.9c-0.8-0.2-0.8,3,0,2.8C23.1,17.9,23.1,14.7,22.3,14.9z"/>
		<path class="st1" d="M22,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,14.9c-0.8-0.2-0.8,3,0,2.8C21.1,17.9,21.1,14.7,20.3,14.9z"/>
		<path class="st1" d="M20,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,14.9c-0.8-0.2-0.8,3,0,2.8C19.1,17.9,19.1,14.7,18.3,14.9z"/>
		<path class="st1" d="M18,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,14.9c-0.8-0.2-0.8,3,0,2.8C17.2,17.9,17.2,14.7,16.3,14.9z"/>
		<path class="st1" d="M16,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,14.9c-0.8-0.2-0.8,3,0,2.8C15.2,17.9,15.2,14.7,14.3,14.9z"/>
		<path class="st1" d="M14.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,14.9c-0.8-0.2-0.8,3,0,2.8C13.2,17.9,13.2,14.7,12.4,14.9z"/>
		<path class="st1" d="M12.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,14.9c-0.8-0.2-0.8,3,0,2.8C11.2,17.9,11.2,14.7,10.4,14.9z"/>
		<path class="st1" d="M10.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,14.9c-0.8-0.2-0.8,3,0,2.8C9.2,17.9,9.2,14.7,8.4,14.9z"/>
		<path class="st1" d="M8.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,14.9c-0.8-0.2-0.8,3,0,2.8C7.2,17.9,7.2,14.7,6.4,14.9z"/>
		<path class="st1" d="M6.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,14.9c-0.8-0.2-0.8,3,0,2.8C5.2,17.9,5.2,14.7,4.4,14.9z"/>
		<path class="st1" d="M4.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,14.9c-0.8-0.2-0.8,3,0,2.8C130.1,17.9,130.1,14.7,129.3,14.9z"/>
		<path class="st1" d="M129,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,14.9c-0.8-0.2-0.8,3,0,2.8C128.2,17.9,128.2,14.7,127.3,14.9z"/>
		<path class="st1" d="M127,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,14.9c-0.8-0.2-0.8,3,0,2.8C126.2,17.9,126.2,14.7,125.3,14.9z"/>
		<path class="st1" d="M125.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,14.9c-0.8-0.2-0.8,3,0,2.8C124.2,17.9,124.2,14.7,123.4,14.9z"/>
		<path class="st1" d="M123.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,14.9c-0.8-0.2-0.8,3,0,2.8C122.2,17.9,122.2,14.7,121.4,14.9z"/>
		<path class="st1" d="M121.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,14.9c-0.8-0.2-0.8,3,0,2.8C120.2,17.9,120.2,14.7,119.4,14.9z"/>
		<path class="st1" d="M119.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,14.9c-0.8-0.2-0.8,3,0,2.8C118.2,17.9,118.2,14.7,117.4,14.9z"/>
		<path class="st1" d="M117.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,14.9c-0.8-0.2-0.8,3,0,2.8C116.2,17.9,116.2,14.7,115.4,14.9z"/>
		<path class="st1" d="M115.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,14.9c-0.8-0.2-0.8,3,0,2.8C114.3,17.9,114.3,14.7,113.4,14.9z"/>
		<path class="st1" d="M113.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,14.9c-0.8-0.2-0.8,3,0,2.8C112.3,17.9,112.3,14.7,111.4,14.9z"/>
		<path class="st1" d="M111.1,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,14.9c-0.8-0.2-0.8,3,0,2.8C110.3,17.9,110.3,14.7,109.4,14.9z"/>
		<path class="st1" d="M109.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,14.9c-0.8-0.2-0.8,3,0,2.8C108.3,17.9,108.3,14.7,107.5,14.9z"/>
		<path class="st1" d="M107.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,14.9c-0.8-0.2-0.8,3,0,2.8C106.3,17.9,106.3,14.7,105.5,14.9z"/>
		<path class="st1" d="M105.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,14.9c-0.8-0.2-0.8,3,0,2.8C104.3,17.9,104.3,14.7,103.5,14.9z"/>
		<path class="st1" d="M103.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,14.9c-0.8-0.2-0.8,3,0,2.8C102.3,17.9,102.3,14.7,101.5,14.9z"/>
		<path class="st1" d="M101.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,14.9c-0.8-0.2-0.8,3,0,2.8C100.3,17.9,100.3,14.7,99.5,14.9z"/>
		<path class="st1" d="M99.2,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,14.9c-0.8-0.2-0.8,3,0,2.8C98.5,17.9,98.5,14.7,97.6,14.9z"/>
		<path class="st1" d="M97.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,14.9c-0.8-0.2-0.8,3,0,2.8C96.5,17.9,96.5,14.7,95.7,14.9z"/>
		<path class="st1" d="M95.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,14.9c-0.8-0.2-0.8,3,0,2.8C94.5,17.9,94.5,14.7,93.7,14.9z"/>
		<path class="st1" d="M93.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,14.9c-0.8-0.2-0.8,3,0,2.8C92.5,17.9,92.5,14.7,91.7,14.9z"/>
		<path class="st1" d="M91.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,14.9c-0.8-0.2-0.8,3,0,2.8C90.5,17.9,90.5,14.7,89.7,14.9z"/>
		<path class="st1" d="M89.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,14.9c-0.8-0.2-0.8,3,0,2.8C88.5,17.9,88.5,14.7,87.7,14.9z"/>
		<path class="st1" d="M87.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,14.9c-0.8-0.2-0.8,3,0,2.8C86.5,17.9,86.5,14.7,85.7,14.9z"/>
		<path class="st1" d="M85.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,14.9c-0.8-0.2-0.8,3,0,2.8C84.6,17.9,84.6,14.7,83.7,14.9z"/>
		<path class="st1" d="M83.4,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,14.9c-0.8-0.2-0.8,3,0,2.8C82.6,17.9,82.6,14.7,81.7,14.9z"/>
		<path class="st1" d="M81.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,14.9c-0.8-0.2-0.8,3,0,2.8C80.6,17.9,80.6,14.7,79.8,14.9z"/>
		<path class="st1" d="M79.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,14.9c-0.8-0.2-0.8,3,0,2.8C78.6,17.9,78.6,14.7,77.8,14.9z"/>
		<path class="st1" d="M77.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,14.9c-0.8-0.2-0.8,3,0,2.8C76.6,17.9,76.6,14.7,75.8,14.9z"/>
		<path class="st1" d="M75.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,14.9c-0.8-0.2-0.8,3,0,2.8C74.6,17.9,74.6,14.7,73.8,14.9z"/>
		<path class="st1" d="M73.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,14.9c-0.8-0.2-0.8,3,0,2.8C72.6,17.9,72.6,14.7,71.8,14.9z"/>
		<path class="st1" d="M71.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,14.9c-0.8-0.2-0.8,3,0,2.8C70.7,17.9,70.7,14.7,69.8,14.9z"/>
		<path class="st1" d="M69.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,14.9c-0.8-0.2-0.8,3,0,2.8C68.7,17.9,68.7,14.7,67.8,14.9z"/>
		<path class="st1" d="M67.5,15.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M65,15.8c-0.8-0.2-0.8,3,0,2.8C65.8,18.8,65.8,15.6,65,15.8z"/>
		<path class="st2" d="M64.7,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M61,15.8c-0.8-0.2-0.8,3,0,2.8C61.8,18.8,61.8,15.6,61,15.8z"/>
		<path class="st2" d="M60.7,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M59,15.8c-0.8-0.2-0.8,3,0,2.8C59.8,18.8,59.8,15.6,59,15.8z"/>
		<path class="st2" d="M58.7,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M57,15.8c-0.8-0.2-0.8,3,0,2.8C57.9,18.8,57.9,15.6,57,15.8z"/>
		<path class="st2" d="M56.7,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M55,15.8c-0.8-0.2-0.8,3,0,2.8C55.9,18.8,55.9,15.6,55,15.8z"/>
		<path class="st2" d="M54.8,16.7C53.9,16.4,54,15,55,14.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M53.1,15.8c-0.8-0.2-0.8,3,0,2.8C53.9,18.8,53.9,15.6,53.1,15.8z"/>
		<path class="st2" d="M52.8,16.7C51.9,16.4,52,15,53,14.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M51.1,15.8c-0.8-0.2-0.8,3,0,2.8C51.9,18.8,51.9,15.6,51.1,15.8z"/>
		<path class="st2" d="M50.8,16.7c-1-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M49.1,15.8c-0.8-0.2-0.8,3,0,2.8C49.9,18.8,49.9,15.6,49.1,15.8z"/>
		<path class="st2" d="M48.8,16.7c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M47.1,15.8c-0.8-0.2-0.8,3,0,2.8C47.9,18.8,47.9,15.6,47.1,15.8z"/>
		<path class="st2" d="M46.8,16.7c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M45.1,15.8c-0.8-0.2-0.8,3,0,2.8C45.9,18.8,45.9,15.6,45.1,15.8z"/>
		<path class="st2" d="M44.8,16.7c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M43.1,15.8c-0.8-0.2-0.8,3,0,2.8C43.9,18.8,43.9,15.6,43.1,15.8z"/>
		<path class="st2" d="M42.8,16.7c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M41.1,15.8c-0.8-0.2-0.8,3,0,2.8C42,18.8,42,15.6,41.1,15.8z"/>
		<path class="st2" d="M40.8,16.7c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M39.1,15.8c-0.8-0.2-0.8,3,0,2.8C40,18.8,40,15.6,39.1,15.8z"/>
		<path class="st2" d="M38.9,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M37.2,15.8c-0.8-0.2-0.8,3,0,2.8C38,18.8,38,15.6,37.2,15.8z"/>
		<path class="st2" d="M36.9,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M35.2,15.8c-0.8-0.2-0.8,3,0,2.8C36,18.8,36,15.6,35.2,15.8z"/>
		<path class="st2" d="M34.9,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M33.3,15.8c-0.8-0.2-0.8,3,0,2.8C34.1,18.8,34.1,15.6,33.3,15.8z"/>
		<path class="st2" d="M33,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M31.3,15.8c-0.8-0.2-0.8,3,0,2.8C32.1,18.8,32.1,15.6,31.3,15.8z"/>
		<path class="st2" d="M31,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M29.3,15.8c-0.8-0.2-0.8,3,0,2.8C30.1,18.8,30.2,15.6,29.3,15.8z"/>
		<path class="st2" d="M29,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M27.3,15.8c-0.8-0.2-0.8,3,0,2.8C28.2,18.8,28.2,15.6,27.3,15.8z"/>
		<path class="st2" d="M27,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M25.3,15.8c-0.8-0.2-0.8,3,0,2.8C26.2,18.8,26.2,15.6,25.3,15.8z"/>
		<path class="st2" d="M25.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M23.4,15.8c-0.8-0.2-0.8,3,0,2.8C24.2,18.8,24.2,15.6,23.4,15.8z"/>
		<path class="st2" d="M23.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M21.4,15.8c-0.8-0.2-0.8,3,0,2.8C22.2,18.8,22.2,15.6,21.4,15.8z"/>
		<path class="st2" d="M21.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M19.4,15.8c-0.8-0.2-0.8,3,0,2.8C20.2,18.8,20.2,15.6,19.4,15.8z"/>
		<path class="st2" d="M19.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M17.4,15.8c-0.8-0.2-0.8,3,0,2.8C18.2,18.8,18.2,15.6,17.4,15.8z"/>
		<path class="st2" d="M17.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M15.4,15.8c-0.8-0.2-0.8,3,0,2.8C16.2,18.8,16.2,15.6,15.4,15.8z"/>
		<path class="st2" d="M15.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M13.4,15.8c-0.8-0.2-0.8,3,0,2.8C14.3,18.8,14.3,15.6,13.4,15.8z"/>
		<path class="st2" d="M13.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M11.4,15.8c-0.8-0.2-0.8,3,0,2.8C12.3,18.8,12.3,15.6,11.4,15.8z"/>
		<path class="st2" d="M11.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M9.5,15.8c-0.8-0.2-0.8,3,0,2.8C10.3,18.8,10.3,15.6,9.5,15.8z"/>
		<path class="st2" d="M9.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,15.8c-0.8-0.2-0.8,3,0,2.8C8.3,18.8,8.3,15.6,7.5,15.8z"/>
		<path class="st2" d="M7.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,15.8c-0.8-0.2-0.8,3,0,2.8C6.3,18.8,6.3,15.6,5.5,15.8z"/>
		<path class="st2" d="M5.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,15.8c-0.8-0.2-0.8,3,0,2.8C4.3,18.8,4.3,15.6,3.5,15.8z"/>
		<path class="st2" d="M3.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M128.4,15.8c-0.8-0.2-0.8,3,0,2.8C129.2,18.8,129.2,15.6,128.4,15.8z"/>
		<path class="st1" d="M128.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M126.4,15.8c-0.8-0.2-0.8,3,0,2.8C127.2,18.8,127.2,15.6,126.4,15.8z"/>
		<path class="st1" d="M126.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M124.4,15.8c-0.8-0.2-0.8,3,0,2.8C125.3,18.8,125.3,15.6,124.4,15.8z"/>
		<path class="st1" d="M124.1,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M122.4,15.8c-0.8-0.2-0.8,3,0,2.8C123.3,18.8,123.3,15.6,122.4,15.8z"/>
		<path class="st1" d="M122.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M120.5,15.8c-0.8-0.2-0.8,3,0,2.8C121.3,18.8,121.3,15.6,120.5,15.8z"/>
		<path class="st1" d="M120.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M118.5,15.8c-0.8-0.2-0.8,3,0,2.8C119.3,18.8,119.3,15.6,118.5,15.8z"/>
		<path class="st1" d="M118.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M116.5,15.8c-0.8-0.2-0.8,3,0,2.8C117.3,18.8,117.3,15.6,116.5,15.8z"/>
		<path class="st1" d="M116.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M114.5,15.8c-0.8-0.2-0.8,3,0,2.8C115.3,18.8,115.3,15.6,114.5,15.8z"/>
		<path class="st1" d="M114.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M112.5,15.8c-0.8-0.2-0.8,3,0,2.8C113.3,18.8,113.3,15.6,112.5,15.8z"/>
		<path class="st1" d="M112.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M110.5,15.8c-0.8-0.2-0.8,3,0,2.8C111.3,18.8,111.3,15.6,110.5,15.8z"/>
		<path class="st1" d="M110.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M108.5,15.8c-0.8-0.2-0.8,3,0,2.8C109.4,18.8,109.4,15.6,108.5,15.8z"/>
		<path class="st1" d="M108.2,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M106.5,15.8c-0.8-0.2-0.8,3,0,2.8C107.4,18.8,107.4,15.6,106.5,15.8z"/>
		<path class="st1" d="M106.3,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M104.6,15.8c-0.8-0.2-0.8,3,0,2.8C105.4,18.8,105.4,15.6,104.6,15.8z"/>
		<path class="st1" d="M104.3,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M102.6,15.8c-0.8-0.2-0.8,3,0,2.8C103.4,18.8,103.4,15.6,102.6,15.8z"/>
		<path class="st1" d="M102.3,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M100.6,15.8c-0.8-0.2-0.8,3,0,2.8C101.4,18.8,101.4,15.6,100.6,15.8z"/>
		<path class="st1" d="M100.3,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M98.6,15.8c-0.8-0.2-0.8,3,0,2.8C99.4,18.8,99.4,15.6,98.6,15.8z"/>
		<path class="st1" d="M98.3,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M96.7,15.8c-0.8-0.2-0.8,3,0,2.8C97.5,18.8,97.6,15.6,96.7,15.8z"/>
		<path class="st1" d="M96.4,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M94.7,15.8c-0.8-0.2-0.8,3,0,2.8C95.6,18.8,95.6,15.6,94.7,15.8z"/>
		<path class="st1" d="M94.4,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M92.7,15.8c-0.8-0.2-0.8,3,0,2.8C93.6,18.8,93.6,15.6,92.7,15.8z"/>
		<path class="st1" d="M92.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M90.8,15.8c-0.8-0.2-0.8,3,0,2.8C91.6,18.8,91.6,15.6,90.8,15.8z"/>
		<path class="st1" d="M90.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M88.8,15.8c-0.8-0.2-0.8,3,0,2.8C89.6,18.8,89.6,15.6,88.8,15.8z"/>
		<path class="st2" d="M88.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M86.8,15.8c-0.8-0.2-0.8,3,0,2.8C87.6,18.8,87.6,15.6,86.8,15.8z"/>
		<path class="st2" d="M86.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M84.8,15.8c-0.8-0.2-0.8,3,0,2.8C85.6,18.8,85.6,15.6,84.8,15.8z"/>
		<path class="st2" d="M84.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M82.8,15.8c-0.8-0.2-0.8,3,0,2.8C83.6,18.8,83.6,15.6,82.8,15.8z"/>
		<path class="st2" d="M82.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M80.8,15.8c-0.8-0.2-0.8,3,0,2.8C81.7,18.8,81.7,15.6,80.8,15.8z"/>
		<path class="st2" d="M80.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M78.8,15.8c-0.8-0.2-0.8,3,0,2.8C79.7,18.8,79.7,15.6,78.8,15.8z"/>
		<path class="st2" d="M78.5,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M76.9,15.8c-0.8-0.2-0.8,3,0,2.8C77.7,18.8,77.7,15.6,76.9,15.8z"/>
		<path class="st2" d="M76.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M74.9,15.8c-0.8-0.2-0.8,3,0,2.8C75.7,18.8,75.7,15.6,74.9,15.8z"/>
		<path class="st2" d="M74.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M72.9,15.8c-0.8-0.2-0.8,3,0,2.8C73.7,18.8,73.7,15.6,72.9,15.8z"/>
		<path class="st2" d="M72.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M70.9,15.8c-0.8-0.2-0.8,3,0,2.8C71.7,18.8,71.7,15.6,70.9,15.8z"/>
		<path class="st2" d="M70.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M68.9,15.8c-0.8-0.2-0.8,3,0,2.8C69.7,18.8,69.7,15.6,68.9,15.8z"/>
		<path class="st2" d="M68.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M66.9,15.8c-0.8-0.2-0.8,3,0,2.8C67.7,18.8,67.7,15.6,66.9,15.8z"/>
		<path class="st2" d="M66.6,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M63,15.8c-0.8-0.2-0.8,3,0,2.8C63.8,18.8,63.8,15.6,63,15.8z"/>
		<path class="st2" d="M62.7,16.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M18.3,33.9c-0.8-0.2-0.8,3,0,2.8C19.1,36.9,19.1,33.8,18.3,33.9z"/>
		<path class="st1" d="M18,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,33.9c-0.8-0.2-0.8,3,0,2.8C17.2,36.9,17.2,33.8,16.3,33.9z"/>
		<path class="st1" d="M16,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.4,33.9c-0.8-0.2-0.8,3,0,2.8C23.2,36.9,23.2,33.8,22.4,33.9z"/>
		<path class="st1" d="M22.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.4,33.9c-0.8-0.2-0.8,3,0,2.8C21.2,36.9,21.2,33.8,20.4,33.9z"/>
		<path class="st1" d="M20.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,33.9c-0.8-0.2-0.8,3,0,2.8C15.2,36.9,15.2,33.8,14.3,33.9z"/>
		<path class="st1" d="M14.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,33.9c-0.8-0.2-0.8,3,0,2.8C13.2,36.9,13.2,33.8,12.4,33.9z"/>
		<path class="st1" d="M12.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,33.9c-0.8-0.2-0.8,3,0,2.8C11.2,36.9,11.2,33.8,10.4,33.9z"/>
		<path class="st1" d="M10.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,33.9c-0.8-0.2-0.8,3,0,2.8C9.2,36.9,9.2,33.8,8.4,33.9z"/>
		<path class="st1" d="M8.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M34.2,33.9c-0.8-0.2-0.8,3,0,2.8C35,36.9,35,33.8,34.2,33.9z"/>
		<path class="st1" d="M33.9,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M32.2,33.9c-0.8-0.2-0.8,3,0,2.8C33.1,36.9,33.1,33.8,32.2,33.9z"/>
		<path class="st1" d="M31.9,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M38.3,33.9c-0.8-0.2-0.8,3,0,2.8C39.1,36.9,39.1,33.8,38.3,33.9z"/>
		<path class="st1" d="M38,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M36.3,33.9c-0.8-0.2-0.8,3,0,2.8C37.1,36.9,37.1,33.8,36.3,33.9z"/>
		<path class="st1" d="M36,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M30.2,33.9c-0.8-0.2-0.8,3,0,2.8C31.1,36.9,31.1,33.8,30.2,33.9z"/>
		<path class="st1" d="M30,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M28.3,33.9c-0.8-0.2-0.8,3,0,2.8C29.1,36.9,29.1,33.8,28.3,33.9z"/>
		<path class="st1" d="M28,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M26.3,33.9c-0.8-0.2-0.8,3,0,2.8C27.1,36.9,27.1,33.8,26.3,33.9z"/>
		<path class="st1" d="M26,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M24.3,33.9c-0.8-0.2-0.8,3,0,2.8C25.1,36.9,25.1,33.8,24.3,33.9z"/>
		<path class="st1" d="M24,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,33.9c-0.8-0.2-0.8,3,0,2.8C7.2,36.9,7.2,33.8,6.4,33.9z"/>
		<path class="st1" d="M6.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,33.9c-0.8-0.2-0.8,3,0,2.8C5.2,36.9,5.2,33.8,4.4,33.9z"/>
		<path class="st1" d="M4.1,34.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,34.8c-0.8-0.2-0.8,3,0,2.8C65.8,37.8,65.8,34.7,65,34.8z"/>
		<path class="st1" d="M64.7,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,34.8c-0.8-0.2-0.8,3,0,2.8C63.8,37.8,63.8,34.7,63,34.8z"/>
		<path class="st1" d="M62.7,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,34.8c-0.8-0.2-0.8,3,0,2.8C61.8,37.8,61.8,34.7,61,34.8z"/>
		<path class="st1" d="M60.7,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,34.8c-0.8-0.2-0.8,3,0,2.8C59.8,37.8,59.8,34.7,59,34.8z"/>
		<path class="st1" d="M58.7,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,34.8c-0.8-0.2-0.8,3,0,2.8C57.9,37.8,57.9,34.7,57,34.8z"/>
		<path class="st1" d="M56.7,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,34.8c-0.8-0.2-0.8,3,0,2.8C55.9,37.8,55.9,34.7,55,34.8z"/>
		<path class="st1" d="M54.8,35.7C53.9,35.4,54,34,55,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,34.8c-0.8-0.2-0.8,3,0,2.8C53.9,37.8,53.9,34.7,53.1,34.8z"/>
		<path class="st1" d="M52.8,35.7C51.9,35.4,52,34,53,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,34.8c-0.8-0.2-0.8,3,0,2.8C51.9,37.8,51.9,34.7,51.1,34.8z"/>
		<path class="st1" d="M50.8,35.7c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,34.8c-0.8-0.2-0.8,3,0,2.8C49.9,37.8,49.9,34.7,49.1,34.8z"/>
		<path class="st1" d="M48.8,35.7C47.9,35.5,48,34,49,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M47.1,34.8c-0.8-0.2-0.8,3,0,2.8C47.9,37.8,47.9,34.7,47.1,34.8z"/>
		<path class="st1" d="M46.8,35.7C45.9,35.5,46,34,47,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M45.1,34.8c-0.8-0.2-0.8,3,0,2.8C45.9,37.8,45.9,34.7,45.1,34.8z"/>
		<path class="st1" d="M44.8,35.7C43.9,35.5,44,34,45,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M43.1,34.8c-0.8-0.2-0.8,3,0,2.8C43.9,37.8,43.9,34.7,43.1,34.8z"/>
		<path class="st1" d="M42.8,35.7C41.9,35.5,42,34,43,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M41.1,34.8c-0.8-0.2-0.8,3,0,2.8C42,37.8,42,34.7,41.1,34.8z"/>
		<path class="st1" d="M40.8,35.7C39.9,35.5,40,34,41,33.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M39.1,34.8c-0.8-0.2-0.8,3,0,2.8C40,37.8,40,34.7,39.1,34.8z"/>
		<path class="st1" d="M38.9,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M37.2,34.8c-0.8-0.2-0.8,3,0,2.8C38,37.8,38,34.7,37.2,34.8z"/>
		<path class="st1" d="M36.9,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M35.2,34.8c-0.8-0.2-0.8,3,0,2.8C36,37.8,36,34.7,35.2,34.8z"/>
		<path class="st1" d="M34.9,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M33.3,34.8c-0.8-0.2-0.8,3,0,2.8C34.1,37.8,34.1,34.7,33.3,34.8z"/>
		<path class="st1" d="M33,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M31.3,34.8c-0.8-0.2-0.8,3,0,2.8C32.1,37.8,32.1,34.7,31.3,34.8z"/>
		<path class="st1" d="M31,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M29.3,34.8c-0.8-0.2-0.8,3,0,2.8C30.1,37.8,30.2,34.7,29.3,34.8z"/>
		<path class="st1" d="M29,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M27.3,34.8c-0.8-0.2-0.8,3,0,2.8C28.2,37.8,28.2,34.7,27.3,34.8z"/>
		<path class="st1" d="M27,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M25.3,34.8c-0.8-0.2-0.8,3,0,2.8C26.2,37.8,26.2,34.7,25.3,34.8z"/>
		<path class="st1" d="M25.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M23.4,34.8c-0.8-0.2-0.8,3,0,2.8C24.2,37.8,24.2,34.7,23.4,34.8z"/>
		<path class="st1" d="M23.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M21.4,34.8c-0.8-0.2-0.8,3,0,2.8C22.2,37.8,22.2,34.7,21.4,34.8z"/>
		<path class="st1" d="M21.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M19.4,34.8c-0.8-0.2-0.8,3,0,2.8C20.2,37.8,20.2,34.7,19.4,34.8z"/>
		<path class="st1" d="M19.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M17.4,34.8c-0.8-0.2-0.8,3,0,2.8C18.2,37.8,18.2,34.7,17.4,34.8z"/>
		<path class="st1" d="M17.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M15.4,34.8c-0.8-0.2-0.8,3,0,2.8C16.2,37.8,16.2,34.7,15.4,34.8z"/>
		<path class="st1" d="M15.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M13.4,34.8c-0.8-0.2-0.8,3,0,2.8C14.3,37.8,14.3,34.7,13.4,34.8z"/>
		<path class="st1" d="M13.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M11.4,34.8c-0.8-0.2-0.8,3,0,2.8C12.3,37.8,12.3,34.7,11.4,34.8z"/>
		<path class="st1" d="M11.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M9.5,34.8c-0.8-0.2-0.8,3,0,2.8C10.3,37.8,10.3,34.7,9.5,34.8z"/>
		<path class="st1" d="M9.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M7.5,34.8c-0.8-0.2-0.8,3,0,2.8C8.3,37.8,8.3,34.7,7.5,34.8z"/>
		<path class="st1" d="M7.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M5.5,34.8c-0.8-0.2-0.8,3,0,2.8C6.3,37.8,6.3,34.7,5.5,34.8z"/>
		<path class="st1" d="M5.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M3.5,34.8c-0.8-0.2-0.8,3,0,2.8C4.3,37.8,4.3,34.7,3.5,34.8z"/>
		<path class="st1" d="M3.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,34.8c-0.8-0.2-0.8,3,0,2.8C129.2,37.8,129.2,34.7,128.4,34.8z"/>
		<path class="st1" d="M128.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,34.8c-0.8-0.2-0.8,3,0,2.8C127.2,37.8,127.2,34.7,126.4,34.8z"/>
		<path class="st1" d="M126.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,34.8c-0.8-0.2-0.8,3,0,2.8C125.3,37.8,125.3,34.7,124.4,34.8z"/>
		<path class="st1" d="M124.1,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,34.8c-0.8-0.2-0.8,3,0,2.8C123.3,37.8,123.3,34.7,122.4,34.8z"/>
		<path class="st1" d="M122.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,34.8c-0.8-0.2-0.8,3,0,2.8C121.3,37.8,121.3,34.7,120.5,34.8z"/>
		<path class="st1" d="M120.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,34.8c-0.8-0.2-0.8,3,0,2.8C119.3,37.8,119.3,34.7,118.5,34.8z"/>
		<path class="st1" d="M118.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,34.8c-0.8-0.2-0.8,3,0,2.8C117.3,37.8,117.3,34.7,116.5,34.8z"/>
		<path class="st1" d="M116.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,34.8c-0.8-0.2-0.8,3,0,2.8C115.3,37.8,115.3,34.7,114.5,34.8z"/>
		<path class="st1" d="M114.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,34.8c-0.8-0.2-0.8,3,0,2.8C113.3,37.8,113.3,34.7,112.5,34.8z"/>
		<path class="st1" d="M112.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,34.8c-0.8-0.2-0.8,3,0,2.8C111.3,37.8,111.3,34.7,110.5,34.8z"/>
		<path class="st1" d="M110.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,34.8c-0.8-0.2-0.8,3,0,2.8C109.4,37.8,109.4,34.7,108.5,34.8z"/>
		<path class="st1" d="M108.2,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,34.8c-0.8-0.2-0.8,3,0,2.8C107.4,37.8,107.4,34.7,106.5,34.8z"/>
		<path class="st1" d="M106.3,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,34.8c-0.8-0.2-0.8,3,0,2.8C105.4,37.8,105.4,34.7,104.6,34.8z"/>
		<path class="st1" d="M104.3,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,34.8c-0.8-0.2-0.8,3,0,2.8C103.4,37.8,103.4,34.7,102.6,34.8z"/>
		<path class="st1" d="M102.3,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,34.8c-0.8-0.2-0.8,3,0,2.8C101.4,37.8,101.4,34.7,100.6,34.8z"/>
		<path class="st1" d="M100.3,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,34.8c-0.8-0.2-0.8,3,0,2.8C99.4,37.8,99.4,34.7,98.6,34.8z"/>
		<path class="st1" d="M98.3,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,34.8c-0.8-0.2-0.8,3,0,2.8C97.5,37.8,97.6,34.7,96.7,34.8z"/>
		<path class="st1" d="M96.4,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,34.8c-0.8-0.2-0.8,3,0,2.8C95.6,37.8,95.6,34.7,94.7,34.8z"/>
		<path class="st1" d="M94.4,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,34.8c-0.8-0.2-0.8,3,0,2.8C93.6,37.8,93.6,34.7,92.7,34.8z"/>
		<path class="st1" d="M92.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,34.8c-0.8-0.2-0.8,3,0,2.8C91.6,37.8,91.6,34.7,90.8,34.8z"/>
		<path class="st1" d="M90.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,34.8c-0.8-0.2-0.8,3,0,2.8C89.6,37.8,89.6,34.7,88.8,34.8z"/>
		<path class="st1" d="M88.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,34.8c-0.8-0.2-0.8,3,0,2.8C87.6,37.8,87.6,34.7,86.8,34.8z"/>
		<path class="st1" d="M86.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,34.8c-0.8-0.2-0.8,3,0,2.8C85.6,37.8,85.6,34.7,84.8,34.8z"/>
		<path class="st1" d="M84.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,34.8c-0.8-0.2-0.8,3,0,2.8C83.6,37.8,83.6,34.7,82.8,34.8z"/>
		<path class="st1" d="M82.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,34.8c-0.8-0.2-0.8,3,0,2.8C81.7,37.8,81.7,34.7,80.8,34.8z"/>
		<path class="st1" d="M80.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,34.8c-0.8-0.2-0.8,3,0,2.8C79.7,37.8,79.7,34.7,78.8,34.8z"/>
		<path class="st1" d="M78.5,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,34.8c-0.8-0.2-0.8,3,0,2.8C77.7,37.8,77.7,34.7,76.9,34.8z"/>
		<path class="st1" d="M76.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,34.8c-0.8-0.2-0.8,3,0,2.8C75.7,37.8,75.7,34.7,74.9,34.8z"/>
		<path class="st1" d="M74.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,34.8c-0.8-0.2-0.8,3,0,2.8C73.7,37.8,73.7,34.7,72.9,34.8z"/>
		<path class="st1" d="M72.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,34.8c-0.8-0.2-0.8,3,0,2.8C71.7,37.8,71.7,34.7,70.9,34.8z"/>
		<path class="st1" d="M70.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,34.8c-0.8-0.2-0.8,3,0,2.8C69.7,37.8,69.7,34.7,68.9,34.8z"/>
		<path class="st1" d="M68.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,34.8c-0.8-0.2-0.8,3,0,2.8C67.7,37.8,67.7,34.7,66.9,34.8z"/>
		<path class="st1" d="M66.6,35.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,35.6c-0.8-0.2-0.8,3,0,2.8C66.7,38.6,66.7,35.4,65.9,35.6z"/>
		<path class="st1" d="M65.6,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,35.6c-0.8-0.2-0.8,3,0,2.8C64.7,38.6,64.7,35.4,63.9,35.6z"/>
		<path class="st1" d="M63.6,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,35.6c-0.8-0.2-0.8,3,0,2.8C62.7,38.6,62.8,35.4,61.9,35.6z"/>
		<path class="st1" d="M61.6,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,35.6c-0.8-0.2-0.8,3,0,2.8C60.8,38.6,60.8,35.4,59.9,35.6z"/>
		<path class="st1" d="M59.6,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,35.6c-0.8-0.2-0.8,3,0,2.8C58.8,38.6,58.8,35.4,57.9,35.6z"/>
		<path class="st1" d="M57.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,35.6c-0.8-0.2-0.8,3,0,2.8C56.8,38.6,56.8,35.4,56,35.6z"/>
		<path class="st1" d="M55.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,35.6c-0.8-0.2-0.8,3,0,2.8C54.8,38.6,54.8,35.4,54,35.6z"/>
		<path class="st1" d="M53.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,35.6c-0.8-0.2-0.8,3,0,2.8C52.8,38.6,52.8,35.4,52,35.6z"/>
		<path class="st1" d="M51.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,35.6c-0.8-0.2-0.8,3,0,2.8C50.8,38.6,50.8,35.4,50,35.6z"/>
		<path class="st1" d="M49.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M48,35.6c-0.8-0.2-0.8,3,0,2.8C48.8,38.6,48.8,35.4,48,35.6z"/>
		<path class="st1" d="M47.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M46,35.6c-0.8-0.2-0.8,3,0,2.8C46.9,38.6,46.9,35.4,46,35.6z"/>
		<path class="st1" d="M45.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M44,35.6c-0.8-0.2-0.8,3,0,2.8C44.9,38.6,44.9,35.4,44,35.6z"/>
		<path class="st1" d="M43.7,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M42.1,35.6c-0.8-0.2-0.8,3,0,2.8C42.9,38.6,42.9,35.4,42.1,35.6z"/>
		<path class="st1" d="M41.8,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M40.1,35.6c-0.8-0.2-0.8,3,0,2.8C40.9,38.6,40.9,35.4,40.1,35.6z"/>
		<path class="st1" d="M39.8,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M38.1,35.6c-0.8-0.2-0.8,3,0,2.8C38.9,38.6,38.9,35.4,38.1,35.6z"/>
		<path class="st1" d="M37.8,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M36.1,35.6c-0.8-0.2-0.8,3,0,2.8C36.9,38.6,36.9,35.4,36.1,35.6z"/>
		<path class="st1" d="M35.8,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M34.2,35.6c-0.8-0.2-0.8,3,0,2.8C35,38.6,35,35.4,34.2,35.6z"/>
		<path class="st1" d="M33.9,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M32.2,35.6c-0.8-0.2-0.8,3,0,2.8C33.1,38.6,33.1,35.4,32.2,35.6z"/>
		<path class="st1" d="M31.9,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M30.2,35.6c-0.8-0.2-0.8,3,0,2.8C31.1,38.6,31.1,35.4,30.2,35.6z"/>
		<path class="st1" d="M30,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M28.3,35.6c-0.8-0.2-0.8,3,0,2.8C29.1,38.6,29.1,35.4,28.3,35.6z"/>
		<path class="st1" d="M28,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M26.3,35.6c-0.8-0.2-0.8,3,0,2.8C27.1,38.6,27.1,35.4,26.3,35.6z"/>
		<path class="st1" d="M26,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M24.3,35.6c-0.8-0.2-0.8,3,0,2.8C25.1,38.6,25.1,35.4,24.3,35.6z"/>
		<path class="st1" d="M24,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.3,35.6c-0.8-0.2-0.8,3,0,2.8C23.1,38.6,23.1,35.4,22.3,35.6z"/>
		<path class="st1" d="M22,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.3,35.6c-0.8-0.2-0.8,3,0,2.8C21.1,38.6,21.1,35.4,20.3,35.6z"/>
		<path class="st1" d="M20,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M18.3,35.6c-0.8-0.2-0.8,3,0,2.8C19.1,38.6,19.1,35.4,18.3,35.6z"/>
		<path class="st1" d="M18,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,35.6c-0.8-0.2-0.8,3,0,2.8C17.2,38.6,17.2,35.4,16.3,35.6z"/>
		<path class="st1" d="M16,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,35.6c-0.8-0.2-0.8,3,0,2.8C15.2,38.6,15.2,35.4,14.3,35.6z"/>
		<path class="st1" d="M14.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,35.6c-0.8-0.2-0.8,3,0,2.8C13.2,38.6,13.2,35.4,12.4,35.6z"/>
		<path class="st1" d="M12.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,35.6c-0.8-0.2-0.8,3,0,2.8C11.2,38.6,11.2,35.4,10.4,35.6z"/>
		<path class="st1" d="M10.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,35.6c-0.8-0.2-0.8,3,0,2.8C9.2,38.6,9.2,35.4,8.4,35.6z"/>
		<path class="st1" d="M8.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,35.6c-0.8-0.2-0.8,3,0,2.8C7.2,38.6,7.2,35.4,6.4,35.6z"/>
		<path class="st1" d="M6.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,35.6c-0.8-0.2-0.8,3,0,2.8C5.2,38.6,5.2,35.4,4.4,35.6z"/>
		<path class="st1" d="M4.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,35.6c-0.8-0.2-0.8,3,0,2.8C130.1,38.6,130.1,35.4,129.3,35.6z"/>
		<path class="st1" d="M129,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,35.6c-0.8-0.2-0.8,3,0,2.8C128.2,38.6,128.2,35.4,127.3,35.6z"/>
		<path class="st1" d="M127,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,35.6c-0.8-0.2-0.8,3,0,2.8C126.2,38.6,126.2,35.4,125.3,35.6z"/>
		<path class="st1" d="M125.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,35.6c-0.8-0.2-0.8,3,0,2.8C124.2,38.6,124.2,35.4,123.4,35.6z"/>
		<path class="st1" d="M123.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,35.6c-0.8-0.2-0.8,3,0,2.8C122.2,38.6,122.2,35.4,121.4,35.6z"/>
		<path class="st1" d="M121.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,35.6c-0.8-0.2-0.8,3,0,2.8C120.2,38.6,120.2,35.4,119.4,35.6z"/>
		<path class="st1" d="M119.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,35.6c-0.8-0.2-0.8,3,0,2.8C118.2,38.6,118.2,35.4,117.4,35.6z"/>
		<path class="st1" d="M117.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,35.6c-0.8-0.2-0.8,3,0,2.8C116.2,38.6,116.2,35.4,115.4,35.6z"/>
		<path class="st1" d="M115.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,35.6c-0.8-0.2-0.8,3,0,2.8C114.3,38.6,114.3,35.4,113.4,35.6z"/>
		<path class="st1" d="M113.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,35.6c-0.8-0.2-0.8,3,0,2.8C112.3,38.6,112.3,35.4,111.4,35.6z"/>
		<path class="st1" d="M111.1,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,35.6c-0.8-0.2-0.8,3,0,2.8C110.3,38.6,110.3,35.4,109.4,35.6z"/>
		<path class="st1" d="M109.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,35.6c-0.8-0.2-0.8,3,0,2.8C108.3,38.6,108.3,35.4,107.5,35.6z"/>
		<path class="st1" d="M107.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,35.6c-0.8-0.2-0.8,3,0,2.8C106.3,38.6,106.3,35.4,105.5,35.6z"/>
		<path class="st1" d="M105.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,35.6c-0.8-0.2-0.8,3,0,2.8C104.3,38.6,104.3,35.4,103.5,35.6z"/>
		<path class="st1" d="M103.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,35.6c-0.8-0.2-0.8,3,0,2.8C102.3,38.6,102.3,35.4,101.5,35.6z"/>
		<path class="st1" d="M101.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,35.6c-0.8-0.2-0.8,3,0,2.8C100.3,38.6,100.3,35.4,99.5,35.6z"/>
		<path class="st1" d="M99.2,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,35.6c-0.8-0.2-0.8,3,0,2.8C98.5,38.6,98.5,35.4,97.6,35.6z"/>
		<path class="st1" d="M97.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,35.6c-0.8-0.2-0.8,3,0,2.8C96.5,38.6,96.5,35.4,95.7,35.6z"/>
		<path class="st1" d="M95.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,35.6c-0.8-0.2-0.8,3,0,2.8C94.5,38.6,94.5,35.4,93.7,35.6z"/>
		<path class="st1" d="M93.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,35.6c-0.8-0.2-0.8,3,0,2.8C92.5,38.6,92.5,35.4,91.7,35.6z"/>
		<path class="st1" d="M91.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,35.6c-0.8-0.2-0.8,3,0,2.8C90.5,38.6,90.5,35.4,89.7,35.6z"/>
		<path class="st1" d="M89.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,35.6c-0.8-0.2-0.8,3,0,2.8C88.5,38.6,88.5,35.4,87.7,35.6z"/>
		<path class="st1" d="M87.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,35.6c-0.8-0.2-0.8,3,0,2.8C86.5,38.6,86.5,35.4,85.7,35.6z"/>
		<path class="st1" d="M85.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,35.6c-0.8-0.2-0.8,3,0,2.8C84.6,38.6,84.6,35.4,83.7,35.6z"/>
		<path class="st1" d="M83.4,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,35.6c-0.8-0.2-0.8,3,0,2.8C82.6,38.6,82.6,35.4,81.7,35.6z"/>
		<path class="st1" d="M81.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,35.6c-0.8-0.2-0.8,3,0,2.8C80.6,38.6,80.6,35.4,79.8,35.6z"/>
		<path class="st1" d="M79.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,35.6c-0.8-0.2-0.8,3,0,2.8C78.6,38.6,78.6,35.4,77.8,35.6z"/>
		<path class="st1" d="M77.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,35.6c-0.8-0.2-0.8,3,0,2.8C76.6,38.6,76.6,35.4,75.8,35.6z"/>
		<path class="st1" d="M75.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,35.6c-0.8-0.2-0.8,3,0,2.8C74.6,38.6,74.6,35.4,73.8,35.6z"/>
		<path class="st1" d="M73.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,35.6c-0.8-0.2-0.8,3,0,2.8C72.6,38.6,72.6,35.4,71.8,35.6z"/>
		<path class="st1" d="M71.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,35.6c-0.8-0.2-0.8,3,0,2.8C70.7,38.6,70.7,35.4,69.8,35.6z"/>
		<path class="st1" d="M69.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,35.6c-0.8-0.2-0.8,3,0,2.8C68.7,38.6,68.7,35.4,67.8,35.6z"/>
		<path class="st1" d="M67.5,36.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,36.5c-0.8-0.2-0.8,3,0,2.8C65.8,39.5,65.8,36.4,65,36.5z"/>
		<path class="st1" d="M64.7,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,36.5c-0.8-0.2-0.8,3,0,2.8C63.8,39.5,63.8,36.4,63,36.5z"/>
		<path class="st1" d="M62.7,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,36.5c-0.8-0.2-0.8,3,0,2.8C61.8,39.5,61.8,36.4,61,36.5z"/>
		<path class="st1" d="M60.7,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,36.5c-0.8-0.2-0.8,3,0,2.8C59.8,39.5,59.8,36.4,59,36.5z"/>
		<path class="st1" d="M58.7,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,36.5c-0.8-0.2-0.8,3,0,2.8C57.9,39.5,57.9,36.4,57,36.5z"/>
		<path class="st1" d="M56.7,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,36.5c-0.8-0.2-0.8,3,0,2.8C55.9,39.5,55.9,36.4,55,36.5z"/>
		<path class="st1" d="M54.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,36.5c-0.8-0.2-0.8,3,0,2.8C53.9,39.5,53.9,36.4,53.1,36.5z"/>
		<path class="st1" d="M52.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,36.5c-0.8-0.2-0.8,3,0,2.8C51.9,39.5,51.9,36.4,51.1,36.5z"/>
		<path class="st1" d="M50.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,36.5c-0.8-0.2-0.8,3,0,2.8C49.9,39.5,49.9,36.4,49.1,36.5z"/>
		<path class="st1" d="M48.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M47.1,36.5c-0.8-0.2-0.8,3,0,2.8C47.9,39.5,47.9,36.4,47.1,36.5z"/>
		<path class="st1" d="M46.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M45.1,36.5c-0.8-0.2-0.8,3,0,2.8C45.9,39.5,45.9,36.4,45.1,36.5z"/>
		<path class="st1" d="M44.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M43.1,36.5c-0.8-0.2-0.8,3,0,2.8C43.9,39.5,43.9,36.4,43.1,36.5z"/>
		<path class="st1" d="M42.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M41.1,36.5c-0.8-0.2-0.8,3,0,2.8C42,39.5,42,36.4,41.1,36.5z"/>
		<path class="st1" d="M40.8,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M39.1,36.5c-0.8-0.2-0.8,3,0,2.8C40,39.5,40,36.4,39.1,36.5z"/>
		<path class="st1" d="M38.9,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M37.2,36.5c-0.8-0.2-0.8,3,0,2.8C38,39.5,38,36.4,37.2,36.5z"/>
		<path class="st1" d="M36.9,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M35.2,36.5c-0.8-0.2-0.8,3,0,2.8C36,39.5,36,36.4,35.2,36.5z"/>
		<path class="st1" d="M34.9,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M33.3,36.5c-0.8-0.2-0.8,3,0,2.8C34.1,39.5,34.1,36.4,33.3,36.5z"/>
		<path class="st1" d="M33,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M31.3,36.5c-0.8-0.2-0.8,3,0,2.8C32.1,39.5,32.1,36.4,31.3,36.5z"/>
		<path class="st1" d="M31,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M29.3,36.5c-0.8-0.2-0.8,3,0,2.8C30.1,39.5,30.2,36.4,29.3,36.5z"/>
		<path class="st1" d="M29,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M27.3,36.5c-0.8-0.2-0.8,3,0,2.8C28.2,39.5,28.2,36.4,27.3,36.5z"/>
		<path class="st1" d="M27,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M25.3,36.5c-0.8-0.2-0.8,3,0,2.8C26.2,39.5,26.2,36.4,25.3,36.5z"/>
		<path class="st1" d="M25.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M23.4,36.5c-0.8-0.2-0.8,3,0,2.8C24.2,39.5,24.2,36.4,23.4,36.5z"/>
		<path class="st1" d="M23.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M21.4,36.5c-0.8-0.2-0.8,3,0,2.8C22.2,39.5,22.2,36.4,21.4,36.5z"/>
		<path class="st1" d="M21.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M19.4,36.5c-0.8-0.2-0.8,3,0,2.8C20.2,39.5,20.2,36.4,19.4,36.5z"/>
		<path class="st1" d="M19.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M17.4,36.5c-0.8-0.2-0.8,3,0,2.8C18.2,39.5,18.2,36.4,17.4,36.5z"/>
		<path class="st1" d="M17.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M15.4,36.5c-0.8-0.2-0.8,3,0,2.8C16.2,39.5,16.2,36.4,15.4,36.5z"/>
		<path class="st1" d="M15.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M13.4,36.5c-0.8-0.2-0.8,3,0,2.8C14.3,39.5,14.3,36.4,13.4,36.5z"/>
		<path class="st1" d="M13.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M11.4,36.5c-0.8-0.2-0.8,3,0,2.8C12.3,39.5,12.3,36.4,11.4,36.5z"/>
		<path class="st1" d="M11.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M9.5,36.5c-0.8-0.2-0.8,3,0,2.8C10.3,39.5,10.3,36.4,9.5,36.5z"/>
		<path class="st1" d="M9.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M7.5,36.5c-0.8-0.2-0.8,3,0,2.8C8.3,39.5,8.3,36.4,7.5,36.5z"/>
		<path class="st1" d="M7.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M5.5,36.5c-0.8-0.2-0.8,3,0,2.8C6.3,39.5,6.3,36.4,5.5,36.5z"/>
		<path class="st1" d="M5.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M3.5,36.5c-0.8-0.2-0.8,3,0,2.8C4.3,39.5,4.3,36.4,3.5,36.5z"/>
		<path class="st1" d="M3.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,36.5c-0.8-0.2-0.8,3,0,2.8C129.2,39.5,129.2,36.4,128.4,36.5z"/>
		<path class="st1" d="M128.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,36.5c-0.8-0.2-0.8,3,0,2.8C127.2,39.5,127.2,36.4,126.4,36.5z"/>
		<path class="st1" d="M126.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,36.5c-0.8-0.2-0.8,3,0,2.8C125.3,39.5,125.3,36.4,124.4,36.5z"/>
		<path class="st1" d="M124.1,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,36.5c-0.8-0.2-0.8,3,0,2.8C123.3,39.5,123.3,36.4,122.4,36.5z"/>
		<path class="st1" d="M122.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,36.5c-0.8-0.2-0.8,3,0,2.8C121.3,39.5,121.3,36.4,120.5,36.5z"/>
		<path class="st1" d="M120.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,36.5c-0.8-0.2-0.8,3,0,2.8C119.3,39.5,119.3,36.4,118.5,36.5z"/>
		<path class="st1" d="M118.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,36.5c-0.8-0.2-0.8,3,0,2.8C117.3,39.5,117.3,36.4,116.5,36.5z"/>
		<path class="st1" d="M116.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,36.5c-0.8-0.2-0.8,3,0,2.8C115.3,39.5,115.3,36.4,114.5,36.5z"/>
		<path class="st1" d="M114.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,36.5c-0.8-0.2-0.8,3,0,2.8C113.3,39.5,113.3,36.4,112.5,36.5z"/>
		<path class="st1" d="M112.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,36.5c-0.8-0.2-0.8,3,0,2.8C111.3,39.5,111.3,36.4,110.5,36.5z"/>
		<path class="st1" d="M110.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,36.5c-0.8-0.2-0.8,3,0,2.8C109.4,39.5,109.4,36.4,108.5,36.5z"/>
		<path class="st1" d="M108.2,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,36.5c-0.8-0.2-0.8,3,0,2.8C107.4,39.5,107.4,36.4,106.5,36.5z"/>
		<path class="st1" d="M106.3,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,36.5c-0.8-0.2-0.8,3,0,2.8C105.4,39.5,105.4,36.4,104.6,36.5z"/>
		<path class="st1" d="M104.3,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,36.5c-0.8-0.2-0.8,3,0,2.8C103.4,39.5,103.4,36.4,102.6,36.5z"/>
		<path class="st1" d="M102.3,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,36.5c-0.8-0.2-0.8,3,0,2.8C101.4,39.5,101.4,36.4,100.6,36.5z"/>
		<path class="st1" d="M100.3,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,36.5c-0.8-0.2-0.8,3,0,2.8C99.4,39.5,99.4,36.4,98.6,36.5z"/>
		<path class="st1" d="M98.3,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,36.5c-0.8-0.2-0.8,3,0,2.8C97.5,39.5,97.6,36.4,96.7,36.5z"/>
		<path class="st1" d="M96.4,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,36.5c-0.8-0.2-0.8,3,0,2.8C95.6,39.5,95.6,36.4,94.7,36.5z"/>
		<path class="st1" d="M94.4,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,36.5c-0.8-0.2-0.8,3,0,2.8C93.6,39.5,93.6,36.4,92.7,36.5z"/>
		<path class="st1" d="M92.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,36.5c-0.8-0.2-0.8,3,0,2.8C91.6,39.5,91.6,36.4,90.8,36.5z"/>
		<path class="st1" d="M90.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,36.5c-0.8-0.2-0.8,3,0,2.8C89.6,39.5,89.6,36.4,88.8,36.5z"/>
		<path class="st1" d="M88.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,36.5c-0.8-0.2-0.8,3,0,2.8C87.6,39.5,87.6,36.4,86.8,36.5z"/>
		<path class="st1" d="M86.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,36.5c-0.8-0.2-0.8,3,0,2.8C85.6,39.5,85.6,36.4,84.8,36.5z"/>
		<path class="st1" d="M84.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,36.5c-0.8-0.2-0.8,3,0,2.8C83.6,39.5,83.6,36.4,82.8,36.5z"/>
		<path class="st1" d="M82.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,36.5c-0.8-0.2-0.8,3,0,2.8C81.7,39.5,81.7,36.4,80.8,36.5z"/>
		<path class="st1" d="M80.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,36.5c-0.8-0.2-0.8,3,0,2.8C79.7,39.5,79.7,36.4,78.8,36.5z"/>
		<path class="st1" d="M78.5,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,36.5c-0.8-0.2-0.8,3,0,2.8C77.7,39.5,77.7,36.4,76.9,36.5z"/>
		<path class="st1" d="M76.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,36.5c-0.8-0.2-0.8,3,0,2.8C75.7,39.5,75.7,36.4,74.9,36.5z"/>
		<path class="st1" d="M74.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,36.5c-0.8-0.2-0.8,3,0,2.8C73.7,39.5,73.7,36.4,72.9,36.5z"/>
		<path class="st1" d="M72.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,36.5c-0.8-0.2-0.8,3,0,2.8C71.7,39.5,71.7,36.4,70.9,36.5z"/>
		<path class="st1" d="M70.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,36.5c-0.8-0.2-0.8,3,0,2.8C69.7,39.5,69.7,36.4,68.9,36.5z"/>
		<path class="st1" d="M68.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,36.5c-0.8-0.2-0.8,3,0,2.8C67.7,39.5,67.7,36.4,66.9,36.5z"/>
		<path class="st1" d="M66.6,37.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,37.3c-0.8-0.2-0.8,3,0,2.8C66.7,40.3,66.7,37.1,65.9,37.3z"/>
		<path class="st1" d="M65.6,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,37.3c-0.8-0.2-0.8,3,0,2.8C64.7,40.3,64.7,37.1,63.9,37.3z"/>
		<path class="st1" d="M63.6,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,37.3c-0.8-0.2-0.8,3,0,2.8C62.7,40.3,62.8,37.1,61.9,37.3z"/>
		<path class="st1" d="M61.6,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,37.3c-0.8-0.2-0.8,3,0,2.8C60.8,40.3,60.8,37.1,59.9,37.3z"/>
		<path class="st1" d="M59.6,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,37.3c-0.8-0.2-0.8,3,0,2.8C58.8,40.3,58.8,37.1,57.9,37.3z"/>
		<path class="st1" d="M57.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,37.3c-0.8-0.2-0.8,3,0,2.8C56.8,40.3,56.8,37.1,56,37.3z"/>
		<path class="st1" d="M55.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,37.3c-0.8-0.2-0.8,3,0,2.8C54.8,40.3,54.8,37.1,54,37.3z"/>
		<path class="st1" d="M53.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,37.3c-0.8-0.2-0.8,3,0,2.8C52.8,40.3,52.8,37.1,52,37.3z"/>
		<path class="st1" d="M51.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,37.3c-0.8-0.2-0.8,3,0,2.8C50.8,40.3,50.8,37.1,50,37.3z"/>
		<path class="st1" d="M49.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,37.3c-0.8-0.2-0.8,3,0,2.8C48.8,40.3,48.8,37.1,48,37.3z"/>
		<path class="st1" d="M47.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,37.3c-0.8-0.2-0.8,3,0,2.8C46.9,40.3,46.9,37.1,46,37.3z"/>
		<path class="st1" d="M45.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,37.3c-0.8-0.2-0.8,3,0,2.8C44.9,40.3,44.9,37.1,44,37.3z"/>
		<path class="st1" d="M43.7,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,37.3c-0.8-0.2-0.8,3,0,2.8C42.9,40.3,42.9,37.1,42.1,37.3z"/>
		<path class="st1" d="M41.8,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,37.3c-0.8-0.2-0.8,3,0,2.8C40.9,40.3,40.9,37.1,40.1,37.3z"/>
		<path class="st1" d="M39.8,38.2c-1-0.3-0.8-1.8,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,37.3c-0.8-0.2-0.8,3,0,2.8C38.9,40.3,38.9,37.1,38.1,37.3z"/>
		<path class="st1" d="M37.8,38.2c-0.9-0.3-0.8-1.8,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,37.3c-0.8-0.2-0.8,3,0,2.8C36.9,40.3,36.9,37.1,36.1,37.3z"/>
		<path class="st1" d="M35.8,38.2c-0.9-0.3-0.8-1.8,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,37.3c-0.8-0.2-0.8,3,0,2.8C35,40.3,35,37.1,34.2,37.3z"/>
		<path class="st1" d="M33.9,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,37.3c-0.8-0.2-0.8,3,0,2.8C33.1,40.3,33.1,37.1,32.2,37.3z"/>
		<path class="st1" d="M31.9,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,37.3c-0.8-0.2-0.8,3,0,2.8C31.1,40.3,31.1,37.1,30.2,37.3z"/>
		<path class="st1" d="M30,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,37.3c-0.8-0.2-0.8,3,0,2.8C29.1,40.3,29.1,37.1,28.3,37.3z"/>
		<path class="st1" d="M28,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,37.3c-0.8-0.2-0.8,3,0,2.8C27.1,40.3,27.1,37.1,26.3,37.3z"/>
		<path class="st1" d="M26,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,37.3c-0.8-0.2-0.8,3,0,2.8C25.1,40.3,25.1,37.1,24.3,37.3z"/>
		<path class="st1" d="M24,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,37.3c-0.8-0.2-0.8,3,0,2.8C23.1,40.3,23.1,37.1,22.3,37.3z"/>
		<path class="st1" d="M22,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,37.3c-0.8-0.2-0.8,3,0,2.8C21.1,40.3,21.1,37.1,20.3,37.3z"/>
		<path class="st1" d="M20,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,37.3c-0.8-0.2-0.8,3,0,2.8C19.1,40.3,19.1,37.1,18.3,37.3z"/>
		<path class="st1" d="M18,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,37.3c-0.8-0.2-0.8,3,0,2.8C17.2,40.3,17.2,37.1,16.3,37.3z"/>
		<path class="st1" d="M16,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,37.3c-0.8-0.2-0.8,3,0,2.8C15.2,40.3,15.2,37.1,14.3,37.3z"/>
		<path class="st1" d="M14.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,37.3c-0.8-0.2-0.8,3,0,2.8C13.2,40.3,13.2,37.1,12.4,37.3z"/>
		<path class="st1" d="M12.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,37.3c-0.8-0.2-0.8,3,0,2.8C11.2,40.3,11.2,37.1,10.4,37.3z"/>
		<path class="st1" d="M10.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,37.3c-0.8-0.2-0.8,3,0,2.8C9.2,40.3,9.2,37.1,8.4,37.3z"/>
		<path class="st1" d="M8.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,37.3c-0.8-0.2-0.8,3,0,2.8C7.2,40.3,7.2,37.1,6.4,37.3z"/>
		<path class="st1" d="M6.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,37.3c-0.8-0.2-0.8,3,0,2.8C5.2,40.3,5.2,37.1,4.4,37.3z"/>
		<path class="st1" d="M4.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,37.3c-0.8-0.2-0.8,3,0,2.8C130.1,40.3,130.1,37.1,129.3,37.3z"/>
		<path class="st1" d="M129,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,37.3c-0.8-0.2-0.8,3,0,2.8C128.2,40.3,128.2,37.1,127.3,37.3z"/>
		<path class="st1" d="M127,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,37.3c-0.8-0.2-0.8,3,0,2.8C126.2,40.3,126.2,37.1,125.3,37.3z"/>
		<path class="st1" d="M125.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,37.3c-0.8-0.2-0.8,3,0,2.8C124.2,40.3,124.2,37.1,123.4,37.3z"/>
		<path class="st1" d="M123.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,37.3c-0.8-0.2-0.8,3,0,2.8C122.2,40.3,122.2,37.1,121.4,37.3z"/>
		<path class="st1" d="M121.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,37.3c-0.8-0.2-0.8,3,0,2.8C120.2,40.3,120.2,37.1,119.4,37.3z"/>
		<path class="st1" d="M119.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,37.3c-0.8-0.2-0.8,3,0,2.8C118.2,40.3,118.2,37.1,117.4,37.3z"/>
		<path class="st1" d="M117.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,37.3c-0.8-0.2-0.8,3,0,2.8C116.2,40.3,116.2,37.1,115.4,37.3z"/>
		<path class="st1" d="M115.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,37.3c-0.8-0.2-0.8,3,0,2.8C114.3,40.3,114.3,37.1,113.4,37.3z"/>
		<path class="st1" d="M113.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,37.3c-0.8-0.2-0.8,3,0,2.8C112.3,40.3,112.3,37.1,111.4,37.3z"/>
		<path class="st1" d="M111.1,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,37.3c-0.8-0.2-0.8,3,0,2.8C110.3,40.3,110.3,37.1,109.4,37.3z"/>
		<path class="st1" d="M109.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,37.3c-0.8-0.2-0.8,3,0,2.8C108.3,40.3,108.3,37.1,107.5,37.3z"/>
		<path class="st1" d="M107.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,37.3c-0.8-0.2-0.8,3,0,2.8C106.3,40.3,106.3,37.1,105.5,37.3z"/>
		<path class="st1" d="M105.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,37.3c-0.8-0.2-0.8,3,0,2.8C104.3,40.3,104.3,37.1,103.5,37.3z"/>
		<path class="st1" d="M103.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,37.3c-0.8-0.2-0.8,3,0,2.8C102.3,40.3,102.3,37.1,101.5,37.3z"/>
		<path class="st1" d="M101.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,37.3c-0.8-0.2-0.8,3,0,2.8C100.3,40.3,100.3,37.1,99.5,37.3z"/>
		<path class="st1" d="M99.2,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,37.3c-0.8-0.2-0.8,3,0,2.8C98.5,40.3,98.5,37.1,97.6,37.3z"/>
		<path class="st1" d="M97.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,37.3c-0.8-0.2-0.8,3,0,2.8C96.5,40.3,96.5,37.1,95.7,37.3z"/>
		<path class="st1" d="M95.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,37.3c-0.8-0.2-0.8,3,0,2.8C94.5,40.3,94.5,37.1,93.7,37.3z"/>
		<path class="st1" d="M93.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,37.3c-0.8-0.2-0.8,3,0,2.8C92.5,40.3,92.5,37.1,91.7,37.3z"/>
		<path class="st1" d="M91.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,37.3c-0.8-0.2-0.8,3,0,2.8C90.5,40.3,90.5,37.1,89.7,37.3z"/>
		<path class="st1" d="M89.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,37.3c-0.8-0.2-0.8,3,0,2.8C88.5,40.3,88.5,37.1,87.7,37.3z"/>
		<path class="st1" d="M87.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,37.3c-0.8-0.2-0.8,3,0,2.8C86.5,40.3,86.5,37.1,85.7,37.3z"/>
		<path class="st1" d="M85.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,37.3c-0.8-0.2-0.8,3,0,2.8C84.6,40.3,84.6,37.1,83.7,37.3z"/>
		<path class="st1" d="M83.4,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,37.3c-0.8-0.2-0.8,3,0,2.8C82.6,40.3,82.6,37.1,81.7,37.3z"/>
		<path class="st1" d="M81.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,37.3c-0.8-0.2-0.8,3,0,2.8C80.6,40.3,80.6,37.1,79.8,37.3z"/>
		<path class="st1" d="M79.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,37.3c-0.8-0.2-0.8,3,0,2.8C78.6,40.3,78.6,37.1,77.8,37.3z"/>
		<path class="st1" d="M77.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,37.3c-0.8-0.2-0.8,3,0,2.8C76.6,40.3,76.6,37.1,75.8,37.3z"/>
		<path class="st1" d="M75.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,37.3c-0.8-0.2-0.8,3,0,2.8C74.6,40.3,74.6,37.1,73.8,37.3z"/>
		<path class="st1" d="M73.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,37.3c-0.8-0.2-0.8,3,0,2.8C72.6,40.3,72.6,37.1,71.8,37.3z"/>
		<path class="st1" d="M71.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,37.3c-0.8-0.2-0.8,3,0,2.8C70.7,40.3,70.7,37.1,69.8,37.3z"/>
		<path class="st1" d="M69.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,37.3c-0.8-0.2-0.8,3,0,2.8C68.7,40.3,68.7,37.1,67.8,37.3z"/>
		<path class="st1" d="M67.5,38.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,38.2c-0.8-0.2-0.8,3,0,2.8C65.8,41.2,65.8,38,65,38.2z"/>
		<path class="st1" d="M64.7,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,38.2c-0.8-0.2-0.8,3,0,2.8C63.8,41.2,63.8,38,63,38.2z"/>
		<path class="st1" d="M62.7,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,38.2c-0.8-0.2-0.8,3,0,2.8C61.8,41.2,61.8,38,61,38.2z"/>
		<path class="st1" d="M60.7,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,38.2c-0.8-0.2-0.8,3,0,2.8C59.8,41.2,59.8,38,59,38.2z"/>
		<path class="st1" d="M58.7,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,38.2c-0.8-0.2-0.8,3,0,2.8C57.9,41.2,57.9,38,57,38.2z"/>
		<path class="st1" d="M56.7,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,38.2c-0.8-0.2-0.8,3,0,2.8C55.9,41.2,55.9,38,55,38.2z"/>
		<path class="st1" d="M54.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,38.2c-0.8-0.2-0.8,3,0,2.8C53.9,41.2,53.9,38,53.1,38.2z"/>
		<path class="st1" d="M52.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,38.2c-0.8-0.2-0.8,3,0,2.8C51.9,41.2,51.9,38,51.1,38.2z"/>
		<path class="st1" d="M50.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,38.2c-0.8-0.2-0.8,3,0,2.8C49.9,41.2,49.9,38,49.1,38.2z"/>
		<path class="st1" d="M48.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,38.2c-0.8-0.2-0.8,3,0,2.8C47.9,41.2,47.9,38,47.1,38.2z"/>
		<path class="st1" d="M46.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,38.2c-0.8-0.2-0.8,3,0,2.8C45.9,41.2,45.9,38,45.1,38.2z"/>
		<path class="st1" d="M44.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,38.2c-0.8-0.2-0.8,3,0,2.8C43.9,41.2,43.9,38,43.1,38.2z"/>
		<path class="st1" d="M42.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,38.2c-0.8-0.2-0.8,3,0,2.8C42,41.2,42,38,41.1,38.2z"/>
		<path class="st1" d="M40.8,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,38.2c-0.8-0.2-0.8,3,0,2.8C40,41.2,40,38,39.1,38.2z"/>
		<path class="st1" d="M38.9,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,38.2c-0.8-0.2-0.8,3,0,2.8C38,41.2,38,38,37.2,38.2z"/>
		<path class="st1" d="M36.9,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,38.2c-0.8-0.2-0.8,3,0,2.8C36,41.2,36,38,35.2,38.2z"/>
		<path class="st1" d="M34.9,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,38.2c-0.8-0.2-0.8,3,0,2.8C34.1,41.2,34.1,38,33.3,38.2z"/>
		<path class="st1" d="M33,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,38.2c-0.8-0.2-0.8,3,0,2.8C32.1,41.2,32.1,38,31.3,38.2z"/>
		<path class="st1" d="M31,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,38.2c-0.8-0.2-0.8,3,0,2.8C30.1,41.2,30.2,38,29.3,38.2z"/>
		<path class="st1" d="M29,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,38.2c-0.8-0.2-0.8,3,0,2.8C28.2,41.2,28.2,38,27.3,38.2z"/>
		<path class="st1" d="M27,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,38.2c-0.8-0.2-0.8,3,0,2.8C26.2,41.2,26.2,38,25.3,38.2z"/>
		<path class="st1" d="M25.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,38.2c-0.8-0.2-0.8,3,0,2.8C24.2,41.2,24.2,38,23.4,38.2z"/>
		<path class="st1" d="M23.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,38.2c-0.8-0.2-0.8,3,0,2.8C22.2,41.2,22.2,38,21.4,38.2z"/>
		<path class="st1" d="M21.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,38.2c-0.8-0.2-0.8,3,0,2.8C20.2,41.2,20.2,38,19.4,38.2z"/>
		<path class="st1" d="M19.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,38.2c-0.8-0.2-0.8,3,0,2.8C18.2,41.2,18.2,38,17.4,38.2z"/>
		<path class="st1" d="M17.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,38.2c-0.8-0.2-0.8,3,0,2.8C16.2,41.2,16.2,38,15.4,38.2z"/>
		<path class="st1" d="M15.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,38.2c-0.8-0.2-0.8,3,0,2.8C14.3,41.2,14.3,38,13.4,38.2z"/>
		<path class="st1" d="M13.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,38.2c-0.8-0.2-0.8,3,0,2.8C12.3,41.2,12.3,38,11.4,38.2z"/>
		<path class="st1" d="M11.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,38.2c-0.8-0.2-0.8,3,0,2.8C10.3,41.2,10.3,38,9.5,38.2z"/>
		<path class="st1" d="M9.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,38.2c-0.8-0.2-0.8,3,0,2.8C8.3,41.2,8.3,38,7.5,38.2z"/>
		<path class="st1" d="M7.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,38.2c-0.8-0.2-0.8,3,0,2.8C6.3,41.2,6.3,38,5.5,38.2z"/>
		<path class="st1" d="M5.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,38.2c-0.8-0.2-0.8,3,0,2.8C4.3,41.2,4.3,38,3.5,38.2z"/>
		<path class="st1" d="M3.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M128.4,38.2c-0.8-0.2-0.8,3,0,2.8C129.2,41.2,129.2,38,128.4,38.2z"/>
		<path class="st1" d="M128.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M126.4,38.2c-0.8-0.2-0.8,3,0,2.8C127.2,41.2,127.2,38,126.4,38.2z"/>
		<path class="st1" d="M126.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M124.4,38.2c-0.8-0.2-0.8,3,0,2.8C125.3,41.2,125.3,38,124.4,38.2z"/>
		<path class="st1" d="M124.1,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M122.4,38.2c-0.8-0.2-0.8,3,0,2.8C123.3,41.2,123.3,38,122.4,38.2z"/>
		<path class="st1" d="M122.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M120.5,38.2c-0.8-0.2-0.8,3,0,2.8C121.3,41.2,121.3,38,120.5,38.2z"/>
		<path class="st1" d="M120.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M118.5,38.2c-0.8-0.2-0.8,3,0,2.8C119.3,41.2,119.3,38,118.5,38.2z"/>
		<path class="st1" d="M118.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M116.5,38.2c-0.8-0.2-0.8,3,0,2.8C117.3,41.2,117.3,38,116.5,38.2z"/>
		<path class="st1" d="M116.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,38.2c-0.8-0.2-0.8,3,0,2.8C115.3,41.2,115.3,38,114.5,38.2z"/>
		<path class="st1" d="M114.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,38.2c-0.8-0.2-0.8,3,0,2.8C113.3,41.2,113.3,38,112.5,38.2z"/>
		<path class="st1" d="M112.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,38.2c-0.8-0.2-0.8,3,0,2.8C111.3,41.2,111.3,38,110.5,38.2z"/>
		<path class="st1" d="M110.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,38.2c-0.8-0.2-0.8,3,0,2.8C109.4,41.2,109.4,38,108.5,38.2z"/>
		<path class="st1" d="M108.2,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,38.2c-0.8-0.2-0.8,3,0,2.8C107.4,41.2,107.4,38,106.5,38.2z"/>
		<path class="st1" d="M106.3,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,38.2c-0.8-0.2-0.8,3,0,2.8C105.4,41.2,105.4,38,104.6,38.2z"/>
		<path class="st1" d="M104.3,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,38.2c-0.8-0.2-0.8,3,0,2.8C103.4,41.2,103.4,38,102.6,38.2z"/>
		<path class="st1" d="M102.3,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,38.2c-0.8-0.2-0.8,3,0,2.8C101.4,41.2,101.4,38,100.6,38.2z"/>
		<path class="st1" d="M100.3,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,38.2c-0.8-0.2-0.8,3,0,2.8C99.4,41.2,99.4,38,98.6,38.2z"/>
		<path class="st1" d="M98.3,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,38.2c-0.8-0.2-0.8,3,0,2.8C97.5,41.2,97.6,38,96.7,38.2z"/>
		<path class="st1" d="M96.4,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,38.2c-0.8-0.2-0.8,3,0,2.8C95.6,41.2,95.6,38,94.7,38.2z"/>
		<path class="st1" d="M94.4,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,38.2c-0.8-0.2-0.8,3,0,2.8C93.6,41.2,93.6,38,92.7,38.2z"/>
		<path class="st1" d="M92.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,38.2c-0.8-0.2-0.8,3,0,2.8C91.6,41.2,91.6,38,90.8,38.2z"/>
		<path class="st1" d="M90.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,38.2c-0.8-0.2-0.8,3,0,2.8C89.6,41.2,89.6,38,88.8,38.2z"/>
		<path class="st1" d="M88.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,38.2c-0.8-0.2-0.8,3,0,2.8C87.6,41.2,87.6,38,86.8,38.2z"/>
		<path class="st1" d="M86.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,38.2c-0.8-0.2-0.8,3,0,2.8C85.6,41.2,85.6,38,84.8,38.2z"/>
		<path class="st1" d="M84.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,38.2c-0.8-0.2-0.8,3,0,2.8C83.6,41.2,83.6,38,82.8,38.2z"/>
		<path class="st1" d="M82.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,38.2c-0.8-0.2-0.8,3,0,2.8C81.7,41.2,81.7,38,80.8,38.2z"/>
		<path class="st1" d="M80.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,38.2c-0.8-0.2-0.8,3,0,2.8C79.7,41.2,79.7,38,78.8,38.2z"/>
		<path class="st1" d="M78.5,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,38.2c-0.8-0.2-0.8,3,0,2.8C77.7,41.2,77.7,38,76.9,38.2z"/>
		<path class="st1" d="M76.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,38.2c-0.8-0.2-0.8,3,0,2.8C75.7,41.2,75.7,38,74.9,38.2z"/>
		<path class="st1" d="M74.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,38.2c-0.8-0.2-0.8,3,0,2.8C73.7,41.2,73.7,38,72.9,38.2z"/>
		<path class="st1" d="M72.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,38.2c-0.8-0.2-0.8,3,0,2.8C71.7,41.2,71.7,38,70.9,38.2z"/>
		<path class="st1" d="M70.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,38.2c-0.8-0.2-0.8,3,0,2.8C69.7,41.2,69.7,38,68.9,38.2z"/>
		<path class="st1" d="M68.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,38.2c-0.8-0.2-0.8,3,0,2.8C67.7,41.2,67.7,38,66.9,38.2z"/>
		<path class="st1" d="M66.6,39.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,39c-0.8-0.2-0.8,3,0,2.8C66.7,42,66.7,38.8,65.9,39z"/>
		<path class="st1" d="M65.6,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,39c-0.8-0.2-0.8,3,0,2.8C64.7,42,64.7,38.8,63.9,39z"/>
		<path class="st1" d="M63.6,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,39c-0.8-0.2-0.8,3,0,2.8C62.7,42,62.8,38.8,61.9,39z"/>
		<path class="st1" d="M61.6,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,39c-0.8-0.2-0.8,3,0,2.8C60.8,42,60.8,38.8,59.9,39z"/>
		<path class="st1" d="M59.6,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,39c-0.8-0.2-0.8,3,0,2.8C58.8,42,58.8,38.8,57.9,39z"/>
		<path class="st1" d="M57.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,39c-0.8-0.2-0.8,3,0,2.8C56.8,42,56.8,38.8,56,39z"/>
		<path class="st1" d="M55.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,39c-0.8-0.2-0.8,3,0,2.8C54.8,42,54.8,38.8,54,39z"/>
		<path class="st1" d="M53.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,39c-0.8-0.2-0.8,3,0,2.8C52.8,42,52.8,38.8,52,39z"/>
		<path class="st1" d="M51.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,39c-0.8-0.2-0.8,3,0,2.8C50.8,42,50.8,38.8,50,39z"/>
		<path class="st1" d="M49.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,39c-0.8-0.2-0.8,3,0,2.8C48.8,42,48.8,38.8,48,39z"/>
		<path class="st1" d="M47.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,39c-0.8-0.2-0.8,3,0,2.8C46.9,42,46.9,38.8,46,39z"/>
		<path class="st1" d="M45.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,39c-0.8-0.2-0.8,3,0,2.8C44.9,42,44.9,38.8,44,39z"/>
		<path class="st1" d="M43.7,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,39c-0.8-0.2-0.8,3,0,2.8C42.9,42,42.9,38.8,42.1,39z"/>
		<path class="st1" d="M41.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,39c-0.8-0.2-0.8,3,0,2.8C40.9,42,40.9,38.8,40.1,39z"/>
		<path class="st1" d="M39.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,39c-0.8-0.2-0.8,3,0,2.8C38.9,42,38.9,38.8,38.1,39z"/>
		<path class="st1" d="M37.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,39c-0.8-0.2-0.8,3,0,2.8C36.9,42,36.9,38.8,36.1,39z"/>
		<path class="st1" d="M35.8,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,39c-0.8-0.2-0.8,3,0,2.8C35,42,35,38.8,34.2,39z"/>
		<path class="st1" d="M33.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,39c-0.8-0.2-0.8,3,0,2.8C33.1,42,33.1,38.8,32.2,39z"/>
		<path class="st1" d="M31.9,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,39c-0.8-0.2-0.8,3,0,2.8C31.1,42,31.1,38.8,30.2,39z"/>
		<path class="st1" d="M30,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,39c-0.8-0.2-0.8,3,0,2.8C29.1,42,29.1,38.8,28.3,39z"/>
		<path class="st1" d="M28,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,39c-0.8-0.2-0.8,3,0,2.8C27.1,42,27.1,38.8,26.3,39z"/>
		<path class="st1" d="M26,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,39c-0.8-0.2-0.8,3,0,2.8C25.1,42,25.1,38.8,24.3,39z"/>
		<path class="st1" d="M24,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,39c-0.8-0.2-0.8,3,0,2.8C23.1,42,23.1,38.8,22.3,39z"/>
		<path class="st1" d="M22,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,39c-0.8-0.2-0.8,3,0,2.8C21.1,42,21.1,38.8,20.3,39z"/>
		<path class="st1" d="M20,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,39c-0.8-0.2-0.8,3,0,2.8C19.1,42,19.1,38.8,18.3,39z"/>
		<path class="st1" d="M18,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,39c-0.8-0.2-0.8,3,0,2.8C17.2,42,17.2,38.8,16.3,39z"/>
		<path class="st1" d="M16,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,39c-0.8-0.2-0.8,3,0,2.8C15.2,42,15.2,38.8,14.3,39z"/>
		<path class="st1" d="M14.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,39c-0.8-0.2-0.8,3,0,2.8C13.2,42,13.2,38.8,12.4,39z"/>
		<path class="st1" d="M12.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,39c-0.8-0.2-0.8,3,0,2.8C11.2,42,11.2,38.8,10.4,39z"/>
		<path class="st1" d="M10.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,39c-0.8-0.2-0.8,3,0,2.8C9.2,42,9.2,38.8,8.4,39z"/>
		<path class="st1" d="M8.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,39c-0.8-0.2-0.8,3,0,2.8C7.2,42,7.2,38.8,6.4,39z"/>
		<path class="st1" d="M6.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,39c-0.8-0.2-0.8,3,0,2.8C5.2,42,5.2,38.8,4.4,39z"/>
		<path class="st1" d="M4.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M129.3,39c-0.8-0.2-0.8,3,0,2.8C130.1,42,130.1,38.8,129.3,39z"/>
		<path class="st1" d="M129,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M127.3,39c-0.8-0.2-0.8,3,0,2.8C128.2,42,128.2,38.8,127.3,39z"/>
		<path class="st1" d="M127,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M125.3,39c-0.8-0.2-0.8,3,0,2.8C126.2,42,126.2,38.8,125.3,39z"/>
		<path class="st1" d="M125.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M123.4,39c-0.8-0.2-0.8,3,0,2.8C124.2,42,124.2,38.8,123.4,39z"/>
		<path class="st1" d="M123.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M121.4,39c-0.8-0.2-0.8,3,0,2.8C122.2,42,122.2,38.8,121.4,39z"/>
		<path class="st1" d="M121.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M119.4,39c-0.8-0.2-0.8,3,0,2.8C120.2,42,120.2,38.8,119.4,39z"/>
		<path class="st1" d="M119.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M117.4,39c-0.8-0.2-0.8,3,0,2.8C118.2,42,118.2,38.8,117.4,39z"/>
		<path class="st1" d="M117.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M115.4,39c-0.8-0.2-0.8,3,0,2.8C116.2,42,116.2,38.8,115.4,39z"/>
		<path class="st1" d="M115.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,39c-0.8-0.2-0.8,3,0,2.8C114.3,42,114.3,38.8,113.4,39z"/>
		<path class="st1" d="M113.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,39c-0.8-0.2-0.8,3,0,2.8C112.3,42,112.3,38.8,111.4,39z"/>
		<path class="st1" d="M111.1,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,39c-0.8-0.2-0.8,3,0,2.8C110.3,42,110.3,38.8,109.4,39z"/>
		<path class="st1" d="M109.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,39c-0.8-0.2-0.8,3,0,2.8C108.3,42,108.3,38.8,107.5,39z"/>
		<path class="st1" d="M107.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,39c-0.8-0.2-0.8,3,0,2.8C106.3,42,106.3,38.8,105.5,39z"/>
		<path class="st1" d="M105.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,39c-0.8-0.2-0.8,3,0,2.8C104.3,42,104.3,38.8,103.5,39z"/>
		<path class="st1" d="M103.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,39c-0.8-0.2-0.8,3,0,2.8C102.3,42,102.3,38.8,101.5,39z"/>
		<path class="st1" d="M101.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,39c-0.8-0.2-0.8,3,0,2.8C100.3,42,100.3,38.8,99.5,39z"/>
		<path class="st1" d="M99.2,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,39c-0.8-0.2-0.8,3,0,2.8C98.5,42,98.5,38.8,97.6,39z"/>
		<path class="st1" d="M97.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,39c-0.8-0.2-0.8,3,0,2.8C96.5,42,96.5,38.8,95.7,39z"/>
		<path class="st1" d="M95.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,39c-0.8-0.2-0.8,3,0,2.8C94.5,42,94.5,38.8,93.7,39z"/>
		<path class="st1" d="M93.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,39c-0.8-0.2-0.8,3,0,2.8C92.5,42,92.5,38.8,91.7,39z"/>
		<path class="st1" d="M91.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,39c-0.8-0.2-0.8,3,0,2.8C90.5,42,90.5,38.8,89.7,39z"/>
		<path class="st1" d="M89.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,39c-0.8-0.2-0.8,3,0,2.8C88.5,42,88.5,38.8,87.7,39z"/>
		<path class="st1" d="M87.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,39c-0.8-0.2-0.8,3,0,2.8C86.5,42,86.5,38.8,85.7,39z"/>
		<path class="st1" d="M85.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,39c-0.8-0.2-0.8,3,0,2.8C84.6,42,84.6,38.8,83.7,39z"/>
		<path class="st1" d="M83.4,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,39c-0.8-0.2-0.8,3,0,2.8C82.6,42,82.6,38.8,81.7,39z"/>
		<path class="st1" d="M81.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,39c-0.8-0.2-0.8,3,0,2.8C80.6,42,80.6,38.8,79.8,39z"/>
		<path class="st1" d="M79.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,39c-0.8-0.2-0.8,3,0,2.8C78.6,42,78.6,38.8,77.8,39z"/>
		<path class="st1" d="M77.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,39c-0.8-0.2-0.8,3,0,2.8C76.6,42,76.6,38.8,75.8,39z"/>
		<path class="st1" d="M75.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,39c-0.8-0.2-0.8,3,0,2.8C74.6,42,74.6,38.8,73.8,39z"/>
		<path class="st1" d="M73.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,39c-0.8-0.2-0.8,3,0,2.8C72.6,42,72.6,38.8,71.8,39z"/>
		<path class="st1" d="M71.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,39c-0.8-0.2-0.8,3,0,2.8C70.7,42,70.7,38.8,69.8,39z"/>
		<path class="st1" d="M69.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,39c-0.8-0.2-0.8,3,0,2.8C68.7,42,68.7,38.8,67.8,39z"/>
		<path class="st1" d="M67.5,39.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,39.9c-0.8-0.2-0.8,3,0,2.8C65.8,42.9,65.8,39.7,65,39.9z"/>
		<path class="st1" d="M64.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,39.9c-0.8-0.2-0.8,3,0,2.8C63.8,42.9,63.8,39.7,63,39.9z"/>
		<path class="st1" d="M62.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,39.9c-0.8-0.2-0.8,3,0,2.8C61.8,42.9,61.8,39.7,61,39.9z"/>
		<path class="st1" d="M60.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,39.9c-0.8-0.2-0.8,3,0,2.8C59.8,42.9,59.8,39.7,59,39.9z"/>
		<path class="st1" d="M58.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,39.9c-0.8-0.2-0.8,3,0,2.8C57.9,42.9,57.9,39.7,57,39.9z"/>
		<path class="st1" d="M56.7,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,39.9c-0.8-0.2-0.8,3,0,2.8C55.9,42.9,55.9,39.7,55,39.9z"/>
		<path class="st1" d="M54.8,40.8C53.9,40.5,54,39.1,55,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,39.9c-0.8-0.2-0.8,3,0,2.8C53.9,42.9,53.9,39.7,53.1,39.9z"/>
		<path class="st1" d="M52.8,40.8C51.9,40.5,52,39.1,53,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,39.9c-0.8-0.2-0.8,3,0,2.8C51.9,42.9,51.9,39.7,51.1,39.9z"/>
		<path class="st1" d="M50.8,40.8C49.9,40.5,50,39.1,51,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,39.9c-0.8-0.2-0.8,3,0,2.8C49.9,42.9,49.9,39.7,49.1,39.9z"/>
		<path class="st1" d="M48.8,40.8C47.9,40.5,48,39.1,49,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,39.9c-0.8-0.2-0.8,3,0,2.8C47.9,42.9,47.9,39.7,47.1,39.9z"/>
		<path class="st1" d="M46.8,40.8C45.9,40.5,46,39.1,47,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,39.9c-0.8-0.2-0.8,3,0,2.8C45.9,42.9,45.9,39.7,45.1,39.9z"/>
		<path class="st1" d="M44.8,40.8C43.9,40.5,44,39.1,45,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,39.9c-0.8-0.2-0.8,3,0,2.8C43.9,42.9,43.9,39.7,43.1,39.9z"/>
		<path class="st1" d="M42.8,40.8C41.9,40.5,42,39.1,43,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,39.9c-0.8-0.2-0.8,3,0,2.8C42,42.9,42,39.7,41.1,39.9z"/>
		<path class="st1" d="M40.8,40.8C39.9,40.5,40,39.1,41,39c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,39.9c-0.8-0.2-0.8,3,0,2.8C40,42.9,40,39.7,39.1,39.9z"/>
		<path class="st1" d="M38.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,39.9c-0.8-0.2-0.8,3,0,2.8C38,42.9,38,39.7,37.2,39.9z"/>
		<path class="st1" d="M36.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,39.9c-0.8-0.2-0.8,3,0,2.8C36,42.9,36,39.7,35.2,39.9z"/>
		<path class="st1" d="M34.9,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,39.9c-0.8-0.2-0.8,3,0,2.8C34.1,42.9,34.1,39.7,33.3,39.9z"/>
		<path class="st1" d="M33,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,39.9c-0.8-0.2-0.8,3,0,2.8C32.1,42.9,32.1,39.7,31.3,39.9z"/>
		<path class="st1" d="M31,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,39.9c-0.8-0.2-0.8,3,0,2.8C30.1,42.9,30.2,39.7,29.3,39.9z"/>
		<path class="st1" d="M29,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,39.9c-0.8-0.2-0.8,3,0,2.8C28.2,42.9,28.2,39.7,27.3,39.9z"/>
		<path class="st1" d="M27,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,39.9c-0.8-0.2-0.8,3,0,2.8C26.2,42.9,26.2,39.7,25.3,39.9z"/>
		<path class="st1" d="M25.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,39.9c-0.8-0.2-0.8,3,0,2.8C24.2,42.9,24.2,39.7,23.4,39.9z"/>
		<path class="st1" d="M23.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,39.9c-0.8-0.2-0.8,3,0,2.8C22.2,42.9,22.2,39.7,21.4,39.9z"/>
		<path class="st1" d="M21.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,39.9c-0.8-0.2-0.8,3,0,2.8C20.2,42.9,20.2,39.7,19.4,39.9z"/>
		<path class="st1" d="M19.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,39.9c-0.8-0.2-0.8,3,0,2.8C18.2,42.9,18.2,39.7,17.4,39.9z"/>
		<path class="st1" d="M17.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,39.9c-0.8-0.2-0.8,3,0,2.8C16.2,42.9,16.2,39.7,15.4,39.9z"/>
		<path class="st1" d="M15.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,39.9c-0.8-0.2-0.8,3,0,2.8C14.3,42.9,14.3,39.7,13.4,39.9z"/>
		<path class="st1" d="M13.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,39.9c-0.8-0.2-0.8,3,0,2.8C12.3,42.9,12.3,39.7,11.4,39.9z"/>
		<path class="st1" d="M11.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,39.9c-0.8-0.2-0.8,3,0,2.8C10.3,42.9,10.3,39.7,9.5,39.9z"/>
		<path class="st1" d="M9.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,39.9c-0.8-0.2-0.8,3,0,2.8C8.3,42.9,8.3,39.7,7.5,39.9z"/>
		<path class="st1" d="M7.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,39.9c-0.8-0.2-0.8,3,0,2.8C6.3,42.9,6.3,39.7,5.5,39.9z"/>
		<path class="st1" d="M5.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,39.9c-0.8-0.2-0.8,3,0,2.8C4.3,42.9,4.3,39.7,3.5,39.9z"/>
		<path class="st1" d="M3.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M128.4,39.9c-0.8-0.2-0.8,3,0,2.8C129.2,42.9,129.2,39.7,128.4,39.9z"/>
		<path class="st1" d="M128.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M126.4,39.9c-0.8-0.2-0.8,3,0,2.8C127.2,42.9,127.2,39.7,126.4,39.9z"/>
		<path class="st1" d="M126.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M124.4,39.9c-0.8-0.2-0.8,3,0,2.8C125.3,42.9,125.3,39.7,124.4,39.9z"/>
		<path class="st1" d="M124.1,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M122.4,39.9c-0.8-0.2-0.8,3,0,2.8C123.3,42.9,123.3,39.7,122.4,39.9z"/>
		<path class="st1" d="M122.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M120.5,39.9c-0.8-0.2-0.8,3,0,2.8C121.3,42.9,121.3,39.7,120.5,39.9z"/>
		<path class="st1" d="M120.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M118.5,39.9c-0.8-0.2-0.8,3,0,2.8C119.3,42.9,119.3,39.7,118.5,39.9z"/>
		<path class="st1" d="M118.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M116.5,39.9c-0.8-0.2-0.8,3,0,2.8C117.3,42.9,117.3,39.7,116.5,39.9z"/>
		<path class="st1" d="M116.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,39.9c-0.8-0.2-0.8,3,0,2.8C115.3,42.9,115.3,39.7,114.5,39.9z"/>
		<path class="st1" d="M114.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,39.9c-0.8-0.2-0.8,3,0,2.8C113.3,42.9,113.3,39.7,112.5,39.9z"/>
		<path class="st1" d="M112.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,39.9c-0.8-0.2-0.8,3,0,2.8C111.3,42.9,111.3,39.7,110.5,39.9z"/>
		<path class="st1" d="M110.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,39.9c-0.8-0.2-0.8,3,0,2.8C109.4,42.9,109.4,39.7,108.5,39.9z"/>
		<path class="st1" d="M108.2,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,39.9c-0.8-0.2-0.8,3,0,2.8C107.4,42.9,107.4,39.7,106.5,39.9z"/>
		<path class="st1" d="M106.3,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,39.9c-0.8-0.2-0.8,3,0,2.8C105.4,42.9,105.4,39.7,104.6,39.9z"/>
		<path class="st1" d="M104.3,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,39.9c-0.8-0.2-0.8,3,0,2.8C103.4,42.9,103.4,39.7,102.6,39.9z"/>
		<path class="st1" d="M102.3,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,39.9c-0.8-0.2-0.8,3,0,2.8C101.4,42.9,101.4,39.7,100.6,39.9z"/>
		<path class="st1" d="M100.3,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,39.9c-0.8-0.2-0.8,3,0,2.8C99.4,42.9,99.4,39.7,98.6,39.9z"/>
		<path class="st1" d="M98.3,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,39.9c-0.8-0.2-0.8,3,0,2.8C97.5,42.9,97.6,39.7,96.7,39.9z"/>
		<path class="st1" d="M96.4,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,39.9c-0.8-0.2-0.8,3,0,2.8C95.6,42.9,95.6,39.7,94.7,39.9z"/>
		<path class="st1" d="M94.4,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,39.9c-0.8-0.2-0.8,3,0,2.8C93.6,42.9,93.6,39.7,92.7,39.9z"/>
		<path class="st1" d="M92.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,39.9c-0.8-0.2-0.8,3,0,2.8C91.6,42.9,91.6,39.7,90.8,39.9z"/>
		<path class="st1" d="M90.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,39.9c-0.8-0.2-0.8,3,0,2.8C89.6,42.9,89.6,39.7,88.8,39.9z"/>
		<path class="st1" d="M88.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,39.9c-0.8-0.2-0.8,3,0,2.8C87.6,42.9,87.6,39.7,86.8,39.9z"/>
		<path class="st1" d="M86.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,39.9c-0.8-0.2-0.8,3,0,2.8C85.6,42.9,85.6,39.7,84.8,39.9z"/>
		<path class="st1" d="M84.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,39.9c-0.8-0.2-0.8,3,0,2.8C83.6,42.9,83.6,39.7,82.8,39.9z"/>
		<path class="st1" d="M82.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,39.9c-0.8-0.2-0.8,3,0,2.8C81.7,42.9,81.7,39.7,80.8,39.9z"/>
		<path class="st1" d="M80.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,39.9c-0.8-0.2-0.8,3,0,2.8C79.7,42.9,79.7,39.7,78.8,39.9z"/>
		<path class="st1" d="M78.5,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,39.9c-0.8-0.2-0.8,3,0,2.8C77.7,42.9,77.7,39.7,76.9,39.9z"/>
		<path class="st1" d="M76.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,39.9c-0.8-0.2-0.8,3,0,2.8C75.7,42.9,75.7,39.7,74.9,39.9z"/>
		<path class="st1" d="M74.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,39.9c-0.8-0.2-0.8,3,0,2.8C73.7,42.9,73.7,39.7,72.9,39.9z"/>
		<path class="st1" d="M72.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,39.9c-0.8-0.2-0.8,3,0,2.8C71.7,42.9,71.7,39.7,70.9,39.9z"/>
		<path class="st1" d="M70.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,39.9c-0.8-0.2-0.8,3,0,2.8C69.7,42.9,69.7,39.7,68.9,39.9z"/>
		<path class="st1" d="M68.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,39.9c-0.8-0.2-0.8,3,0,2.8C67.7,42.9,67.7,39.7,66.9,39.9z"/>
		<path class="st1" d="M66.6,40.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,40.7c-0.8-0.2-0.8,3,0,2.8C66.7,43.7,66.7,40.5,65.9,40.7z"/>
		<path class="st1" d="M65.6,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,40.7c-0.8-0.2-0.8,3,0,2.8C64.7,43.7,64.7,40.5,63.9,40.7z"/>
		<path class="st1" d="M63.6,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,40.7c-0.8-0.2-0.8,3,0,2.8C62.7,43.7,62.8,40.5,61.9,40.7z"/>
		<path class="st1" d="M61.6,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,40.7c-0.8-0.2-0.8,3,0,2.8C60.8,43.7,60.8,40.5,59.9,40.7z"/>
		<path class="st1" d="M59.6,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,40.7c-0.8-0.2-0.8,3,0,2.8C58.8,43.7,58.8,40.5,57.9,40.7z"/>
		<path class="st1" d="M57.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,40.7c-0.8-0.2-0.8,3,0,2.8C56.8,43.7,56.8,40.5,56,40.7z"/>
		<path class="st1" d="M55.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,40.7c-0.8-0.2-0.8,3,0,2.8C54.8,43.7,54.8,40.5,54,40.7z"/>
		<path class="st1" d="M53.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,40.7c-0.8-0.2-0.8,3,0,2.8C52.8,43.7,52.8,40.5,52,40.7z"/>
		<path class="st1" d="M51.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,40.7c-0.8-0.2-0.8,3,0,2.8C50.8,43.7,50.8,40.5,50,40.7z"/>
		<path class="st1" d="M49.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,40.7c-0.8-0.2-0.8,3,0,2.8C48.8,43.7,48.8,40.5,48,40.7z"/>
		<path class="st1" d="M47.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,40.7c-0.8-0.2-0.8,3,0,2.8C46.9,43.7,46.9,40.5,46,40.7z"/>
		<path class="st1" d="M45.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,40.7c-0.8-0.2-0.8,3,0,2.8C44.9,43.7,44.9,40.5,44,40.7z"/>
		<path class="st1" d="M43.7,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,40.7c-0.8-0.2-0.8,3,0,2.8C42.9,43.7,42.9,40.5,42.1,40.7z"/>
		<path class="st1" d="M41.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,40.7c-0.8-0.2-0.8,3,0,2.8C40.9,43.7,40.9,40.5,40.1,40.7z"/>
		<path class="st1" d="M39.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,40.7c-0.8-0.2-0.8,3,0,2.8C38.9,43.7,38.9,40.5,38.1,40.7z"/>
		<path class="st1" d="M37.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,40.7c-0.8-0.2-0.8,3,0,2.8C36.9,43.7,36.9,40.5,36.1,40.7z"/>
		<path class="st1" d="M35.8,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,40.7c-0.8-0.2-0.8,3,0,2.8C35,43.7,35,40.5,34.2,40.7z"/>
		<path class="st1" d="M33.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,40.7c-0.8-0.2-0.8,3,0,2.8C33.1,43.7,33.1,40.5,32.2,40.7z"/>
		<path class="st1" d="M31.9,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,40.7c-0.8-0.2-0.8,3,0,2.8C31.1,43.7,31.1,40.5,30.2,40.7z"/>
		<path class="st1" d="M30,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,40.7c-0.8-0.2-0.8,3,0,2.8C29.1,43.7,29.1,40.5,28.3,40.7z"/>
		<path class="st1" d="M28,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,40.7c-0.8-0.2-0.8,3,0,2.8C27.1,43.7,27.1,40.5,26.3,40.7z"/>
		<path class="st1" d="M26,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,40.7c-0.8-0.2-0.8,3,0,2.8C25.1,43.7,25.1,40.5,24.3,40.7z"/>
		<path class="st1" d="M24,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,40.7c-0.8-0.2-0.8,3,0,2.8C23.1,43.7,23.1,40.5,22.3,40.7z"/>
		<path class="st1" d="M22,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,40.7c-0.8-0.2-0.8,3,0,2.8C21.1,43.7,21.1,40.5,20.3,40.7z"/>
		<path class="st1" d="M20,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,40.7c-0.8-0.2-0.8,3,0,2.8C19.1,43.7,19.1,40.5,18.3,40.7z"/>
		<path class="st1" d="M18,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,40.7c-0.8-0.2-0.8,3,0,2.8C17.2,43.7,17.2,40.5,16.3,40.7z"/>
		<path class="st1" d="M16,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,40.7c-0.8-0.2-0.8,3,0,2.8C15.2,43.7,15.2,40.5,14.3,40.7z"/>
		<path class="st1" d="M14.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,40.7c-0.8-0.2-0.8,3,0,2.8C13.2,43.7,13.2,40.5,12.4,40.7z"/>
		<path class="st1" d="M12.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,40.7c-0.8-0.2-0.8,3,0,2.8C11.2,43.7,11.2,40.5,10.4,40.7z"/>
		<path class="st1" d="M10.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,40.7c-0.8-0.2-0.8,3,0,2.8C9.2,43.7,9.2,40.5,8.4,40.7z"/>
		<path class="st1" d="M8.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,40.7c-0.8-0.2-0.8,3,0,2.8C7.2,43.7,7.2,40.5,6.4,40.7z"/>
		<path class="st1" d="M6.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,40.7c-0.8-0.2-0.8,3,0,2.8C5.2,43.7,5.2,40.5,4.4,40.7z"/>
		<path class="st1" d="M4.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M129.3,40.7c-0.8-0.2-0.8,3,0,2.8C130.1,43.7,130.1,40.5,129.3,40.7z"/>
		<path class="st1" d="M129,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M127.3,40.7c-0.8-0.2-0.8,3,0,2.8C128.2,43.7,128.2,40.5,127.3,40.7z"/>
		<path class="st1" d="M127,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M125.3,40.7c-0.8-0.2-0.8,3,0,2.8C126.2,43.7,126.2,40.5,125.3,40.7z"/>
		<path class="st1" d="M125.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M123.4,40.7c-0.8-0.2-0.8,3,0,2.8C124.2,43.7,124.2,40.5,123.4,40.7z"/>
		<path class="st1" d="M123.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M121.4,40.7c-0.8-0.2-0.8,3,0,2.8C122.2,43.7,122.2,40.5,121.4,40.7z"/>
		<path class="st1" d="M121.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M119.4,40.7c-0.8-0.2-0.8,3,0,2.8C120.2,43.7,120.2,40.5,119.4,40.7z"/>
		<path class="st1" d="M119.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M117.4,40.7c-0.8-0.2-0.8,3,0,2.8C118.2,43.7,118.2,40.5,117.4,40.7z"/>
		<path class="st1" d="M117.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M115.4,40.7c-0.8-0.2-0.8,3,0,2.8C116.2,43.7,116.2,40.5,115.4,40.7z"/>
		<path class="st1" d="M115.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,40.7c-0.8-0.2-0.8,3,0,2.8C114.3,43.7,114.3,40.5,113.4,40.7z"/>
		<path class="st1" d="M113.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,40.7c-0.8-0.2-0.8,3,0,2.8C112.3,43.7,112.3,40.5,111.4,40.7z"/>
		<path class="st1" d="M111.1,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,40.7c-0.8-0.2-0.8,3,0,2.8C110.3,43.7,110.3,40.5,109.4,40.7z"/>
		<path class="st1" d="M109.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,40.7c-0.8-0.2-0.8,3,0,2.8C108.3,43.7,108.3,40.5,107.5,40.7z"/>
		<path class="st1" d="M107.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,40.7c-0.8-0.2-0.8,3,0,2.8C106.3,43.7,106.3,40.5,105.5,40.7z"/>
		<path class="st1" d="M105.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,40.7c-0.8-0.2-0.8,3,0,2.8C104.3,43.7,104.3,40.5,103.5,40.7z"/>
		<path class="st1" d="M103.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,40.7c-0.8-0.2-0.8,3,0,2.8C102.3,43.7,102.3,40.5,101.5,40.7z"/>
		<path class="st1" d="M101.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,40.7c-0.8-0.2-0.8,3,0,2.8C100.3,43.7,100.3,40.5,99.5,40.7z"/>
		<path class="st1" d="M99.2,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,40.7c-0.8-0.2-0.8,3,0,2.8C98.5,43.7,98.5,40.5,97.6,40.7z"/>
		<path class="st1" d="M97.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,40.7c-0.8-0.2-0.8,3,0,2.8C96.5,43.7,96.5,40.5,95.7,40.7z"/>
		<path class="st1" d="M95.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,40.7c-0.8-0.2-0.8,3,0,2.8C94.5,43.7,94.5,40.5,93.7,40.7z"/>
		<path class="st1" d="M93.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,40.7c-0.8-0.2-0.8,3,0,2.8C92.5,43.7,92.5,40.5,91.7,40.7z"/>
		<path class="st1" d="M91.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,40.7c-0.8-0.2-0.8,3,0,2.8C90.5,43.7,90.5,40.5,89.7,40.7z"/>
		<path class="st1" d="M89.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,40.7c-0.8-0.2-0.8,3,0,2.8C88.5,43.7,88.5,40.5,87.7,40.7z"/>
		<path class="st1" d="M87.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,40.7c-0.8-0.2-0.8,3,0,2.8C86.5,43.7,86.5,40.5,85.7,40.7z"/>
		<path class="st1" d="M85.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,40.7c-0.8-0.2-0.8,3,0,2.8C84.6,43.7,84.6,40.5,83.7,40.7z"/>
		<path class="st1" d="M83.4,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,40.7c-0.8-0.2-0.8,3,0,2.8C82.6,43.7,82.6,40.5,81.7,40.7z"/>
		<path class="st1" d="M81.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,40.7c-0.8-0.2-0.8,3,0,2.8C80.6,43.7,80.6,40.5,79.8,40.7z"/>
		<path class="st1" d="M79.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,40.7c-0.8-0.2-0.8,3,0,2.8C78.6,43.7,78.6,40.5,77.8,40.7z"/>
		<path class="st1" d="M77.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,40.7c-0.8-0.2-0.8,3,0,2.8C76.6,43.7,76.6,40.5,75.8,40.7z"/>
		<path class="st1" d="M75.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,40.7c-0.8-0.2-0.8,3,0,2.8C74.6,43.7,74.6,40.5,73.8,40.7z"/>
		<path class="st1" d="M73.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,40.7c-0.8-0.2-0.8,3,0,2.8C72.6,43.7,72.6,40.5,71.8,40.7z"/>
		<path class="st1" d="M71.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,40.7c-0.8-0.2-0.8,3,0,2.8C70.7,43.7,70.7,40.5,69.8,40.7z"/>
		<path class="st1" d="M69.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,40.7c-0.8-0.2-0.8,3,0,2.8C68.7,43.7,68.7,40.5,67.8,40.7z"/>
		<path class="st1" d="M67.5,41.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M65,41.6c-0.8-0.2-0.8,3,0,2.8C65.8,44.6,65.8,41.4,65,41.6z"/>
		<path class="st1" d="M64.7,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63,41.6c-0.8-0.2-0.8,3,0,2.8C63.8,44.6,63.8,41.4,63,41.6z"/>
		<path class="st1" d="M62.7,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,41.6c-0.8-0.2-0.8,3,0,2.8C61.8,44.6,61.8,41.4,61,41.6z"/>
		<path class="st1" d="M60.7,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,41.6c-0.8-0.2-0.8,3,0,2.8C59.8,44.6,59.8,41.4,59,41.6z"/>
		<path class="st1" d="M58.7,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,41.6c-0.8-0.2-0.8,3,0,2.8C57.9,44.6,57.9,41.4,57,41.6z"/>
		<path class="st1" d="M56.7,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,41.6c-0.8-0.2-0.8,3,0,2.8C55.9,44.6,55.9,41.4,55,41.6z"/>
		<path class="st1" d="M54.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,41.6c-0.8-0.2-0.8,3,0,2.8C53.9,44.6,53.9,41.4,53.1,41.6z"/>
		<path class="st1" d="M52.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,41.6c-0.8-0.2-0.8,3,0,2.8C51.9,44.6,51.9,41.4,51.1,41.6z"/>
		<path class="st1" d="M50.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,41.6c-0.8-0.2-0.8,3,0,2.8C49.9,44.6,49.9,41.4,49.1,41.6z"/>
		<path class="st1" d="M48.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,41.6c-0.8-0.2-0.8,3,0,2.8C47.9,44.6,47.9,41.4,47.1,41.6z"/>
		<path class="st1" d="M46.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,41.6c-0.8-0.2-0.8,3,0,2.8C45.9,44.6,45.9,41.4,45.1,41.6z"/>
		<path class="st1" d="M44.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M43.1,41.6c-0.8-0.2-0.8,3,0,2.8C43.9,44.6,43.9,41.4,43.1,41.6z"/>
		<path class="st2" d="M42.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M41.1,41.6c-0.8-0.2-0.8,3,0,2.8C42,44.6,42,41.4,41.1,41.6z"/>
		<path class="st2" d="M40.8,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M39.1,41.6c-0.8-0.2-0.8,3,0,2.8C40,44.6,40,41.4,39.1,41.6z"/>
		<path class="st2" d="M38.9,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M37.2,41.6c-0.8-0.2-0.8,3,0,2.8C38,44.6,38,41.4,37.2,41.6z"/>
		<path class="st2" d="M36.9,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M35.2,41.6c-0.8-0.2-0.8,3,0,2.8C36,44.6,36,41.4,35.2,41.6z"/>
		<path class="st2" d="M34.9,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M33.3,41.6c-0.8-0.2-0.8,3,0,2.8C34.1,44.6,34.1,41.4,33.3,41.6z"/>
		<path class="st2" d="M33,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M31.3,41.6c-0.8-0.2-0.8,3,0,2.8C32.1,44.6,32.1,41.4,31.3,41.6z"/>
		<path class="st2" d="M31,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M29.3,41.6c-0.8-0.2-0.8,3,0,2.8C30.1,44.6,30.2,41.4,29.3,41.6z"/>
		<path class="st2" d="M29,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M27.3,41.6c-0.8-0.2-0.8,3,0,2.8C28.2,44.6,28.2,41.4,27.3,41.6z"/>
		<path class="st2" d="M27,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M25.3,41.6c-0.8-0.2-0.8,3,0,2.8C26.2,44.6,26.2,41.4,25.3,41.6z"/>
		<path class="st2" d="M25.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M23.4,41.6c-0.8-0.2-0.8,3,0,2.8C24.2,44.6,24.2,41.4,23.4,41.6z"/>
		<path class="st2" d="M23.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M21.4,41.6c-0.8-0.2-0.8,3,0,2.8C22.2,44.6,22.2,41.4,21.4,41.6z"/>
		<path class="st2" d="M21.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M19.4,41.6c-0.8-0.2-0.8,3,0,2.8C20.2,44.6,20.2,41.4,19.4,41.6z"/>
		<path class="st2" d="M19.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M17.4,41.6c-0.8-0.2-0.8,3,0,2.8C18.2,44.6,18.2,41.4,17.4,41.6z"/>
		<path class="st2" d="M17.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M15.4,41.6c-0.8-0.2-0.8,3,0,2.8C16.2,44.6,16.2,41.4,15.4,41.6z"/>
		<path class="st2" d="M15.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M13.4,41.6c-0.8-0.2-0.8,3,0,2.8C14.3,44.6,14.3,41.4,13.4,41.6z"/>
		<path class="st2" d="M13.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M11.4,41.6c-0.8-0.2-0.8,3,0,2.8C12.3,44.6,12.3,41.4,11.4,41.6z"/>
		<path class="st2" d="M11.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M9.5,41.6c-0.8-0.2-0.8,3,0,2.8C10.3,44.6,10.3,41.4,9.5,41.6z"/>
		<path class="st2" d="M9.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,41.6c-0.8-0.2-0.8,3,0,2.8C8.3,44.6,8.3,41.4,7.5,41.6z"/>
		<path class="st2" d="M7.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,41.6c-0.8-0.2-0.8,3,0,2.8C6.3,44.6,6.3,41.4,5.5,41.6z"/>
		<path class="st2" d="M5.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,41.6c-0.8-0.2-0.8,3,0,2.8C4.3,44.6,4.3,41.4,3.5,41.6z"/>
		<path class="st2" d="M3.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M128.4,41.6c-0.8-0.2-0.8,3,0,2.8C129.2,44.6,129.2,41.4,128.4,41.6z"/>
		<path class="st1" d="M128.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M126.4,41.6c-0.8-0.2-0.8,3,0,2.8C127.2,44.6,127.2,41.4,126.4,41.6z"/>
		<path class="st1" d="M126.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M122.4,41.6c-0.8-0.2-0.8,3,0,2.8C123.3,44.6,123.3,41.4,122.4,41.6z"/>
		<path class="st1" d="M122.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M120.5,41.6c-0.8-0.2-0.8,3,0,2.8C121.3,44.6,121.3,41.4,120.5,41.6z"/>
		<path class="st1" d="M120.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M118.5,41.6c-0.8-0.2-0.8,3,0,2.8C119.3,44.6,119.3,41.4,118.5,41.6z"/>
		<path class="st1" d="M118.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M116.5,41.6c-0.8-0.2-0.8,3,0,2.8C117.3,44.6,117.3,41.4,116.5,41.6z"/>
		<path class="st1" d="M116.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M114.5,41.6c-0.8-0.2-0.8,3,0,2.8C115.3,44.6,115.3,41.4,114.5,41.6z"/>
		<path class="st1" d="M114.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M112.5,41.6c-0.8-0.2-0.8,3,0,2.8C113.3,44.6,113.3,41.4,112.5,41.6z"/>
		<path class="st1" d="M112.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M110.5,41.6c-0.8-0.2-0.8,3,0,2.8C111.3,44.6,111.3,41.4,110.5,41.6z"/>
		<path class="st1" d="M110.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M108.5,41.6c-0.8-0.2-0.8,3,0,2.8C109.4,44.6,109.4,41.4,108.5,41.6z"/>
		<path class="st1" d="M108.2,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M106.5,41.6c-0.8-0.2-0.8,3,0,2.8C107.4,44.6,107.4,41.4,106.5,41.6z"/>
		<path class="st1" d="M106.3,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M104.6,41.6c-0.8-0.2-0.8,3,0,2.8C105.4,44.6,105.4,41.4,104.6,41.6z"/>
		<path class="st1" d="M104.3,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M102.6,41.6c-0.8-0.2-0.8,3,0,2.8C103.4,44.6,103.4,41.4,102.6,41.6z"/>
		<path class="st1" d="M102.3,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M100.6,41.6c-0.8-0.2-0.8,3,0,2.8C101.4,44.6,101.4,41.4,100.6,41.6z"/>
		<path class="st1" d="M100.3,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M98.6,41.6c-0.8-0.2-0.8,3,0,2.8C99.4,44.6,99.4,41.4,98.6,41.6z"/>
		<path class="st1" d="M98.3,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M96.7,41.6c-0.8-0.2-0.8,3,0,2.8C97.5,44.6,97.6,41.4,96.7,41.6z"/>
		<path class="st1" d="M96.4,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M94.7,41.6c-0.8-0.2-0.8,3,0,2.8C95.6,44.6,95.6,41.4,94.7,41.6z"/>
		<path class="st1" d="M94.4,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M92.7,41.6c-0.8-0.2-0.8,3,0,2.8C93.6,44.6,93.6,41.4,92.7,41.6z"/>
		<path class="st1" d="M92.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M90.8,41.6c-0.8-0.2-0.8,3,0,2.8C91.6,44.6,91.6,41.4,90.8,41.6z"/>
		<path class="st1" d="M90.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M88.8,41.6c-0.8-0.2-0.8,3,0,2.8C89.6,44.6,89.6,41.4,88.8,41.6z"/>
		<path class="st1" d="M88.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M86.8,41.6c-0.8-0.2-0.8,3,0,2.8C87.6,44.6,87.6,41.4,86.8,41.6z"/>
		<path class="st1" d="M86.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M84.8,41.6c-0.8-0.2-0.8,3,0,2.8C85.6,44.6,85.6,41.4,84.8,41.6z"/>
		<path class="st1" d="M84.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M82.8,41.6c-0.8-0.2-0.8,3,0,2.8C83.6,44.6,83.6,41.4,82.8,41.6z"/>
		<path class="st1" d="M82.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M80.8,41.6c-0.8-0.2-0.8,3,0,2.8C81.7,44.6,81.7,41.4,80.8,41.6z"/>
		<path class="st1" d="M80.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M78.8,41.6c-0.8-0.2-0.8,3,0,2.8C79.7,44.6,79.7,41.4,78.8,41.6z"/>
		<path class="st1" d="M78.5,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M76.9,41.6c-0.8-0.2-0.8,3,0,2.8C77.7,44.6,77.7,41.4,76.9,41.6z"/>
		<path class="st1" d="M76.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M74.9,41.6c-0.8-0.2-0.8,3,0,2.8C75.7,44.6,75.7,41.4,74.9,41.6z"/>
		<path class="st1" d="M74.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M72.9,41.6c-0.8-0.2-0.8,3,0,2.8C73.7,44.6,73.7,41.4,72.9,41.6z"/>
		<path class="st1" d="M72.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M70.9,41.6c-0.8-0.2-0.8,3,0,2.8C71.7,44.6,71.7,41.4,70.9,41.6z"/>
		<path class="st1" d="M70.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M68.9,41.6c-0.8-0.2-0.8,3,0,2.8C69.7,44.6,69.7,41.4,68.9,41.6z"/>
		<path class="st1" d="M68.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M66.9,41.6c-0.8-0.2-0.8,3,0,2.8C67.7,44.6,67.7,41.4,66.9,41.6z"/>
		<path class="st1" d="M66.6,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M124.4,41.6c-0.8-0.2-0.8,3,0,2.8C125.3,44.6,125.3,41.4,124.4,41.6z"/>
		<path class="st1" d="M124.1,42.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M38.1,78.1c-0.8-0.2-0.8,3,0,2.8C38.9,81.1,38.9,77.9,38.1,78.1z"/>
		<path class="st1" d="M37.8,79c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M36.1,78.1c-0.8-0.2-0.8,3,0,2.8C36.9,81.1,36.9,77.9,36.1,78.1z"/>
		<path class="st1" d="M35.8,79c-0.9-0.3-0.8-1.8,0.2-1.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M34.2,78.1c-0.8-0.2-0.8,3,0,2.8C35,81.1,35,77.9,34.2,78.1z"/>
		<path class="st1" d="M33.9,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M30.2,78.1c-0.8-0.2-0.8,3,0,2.8C31.1,81.1,31.1,77.9,30.2,78.1z"/>
		<path class="st1" d="M30,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M28.3,78.1c-0.8-0.2-0.8,3,0,2.8C29.1,81.1,29.1,77.9,28.3,78.1z"/>
		<path class="st1" d="M28,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M26.3,78.1c-0.8-0.2-0.8,3,0,2.8C27.1,81.1,27.1,77.9,26.3,78.1z"/>
		<path class="st1" d="M26,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M24.3,78.1c-0.8-0.2-0.8,3,0,2.8C25.1,81.1,25.1,77.9,24.3,78.1z"/>
		<path class="st1" d="M24,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.3,78.1c-0.8-0.2-0.8,3,0,2.8C23.1,81.1,23.1,77.9,22.3,78.1z"/>
		<path class="st1" d="M22,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.3,78.1c-0.8-0.2-0.8,3,0,2.8C21.1,81.1,21.1,77.9,20.3,78.1z"/>
		<path class="st1" d="M20,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M18.3,78.1c-0.8-0.2-0.8,3,0,2.8C19.1,81.1,19.1,77.9,18.3,78.1z"/>
		<path class="st1" d="M18,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,78.1c-0.8-0.2-0.8,3,0,2.8C17.2,81.1,17.2,77.9,16.3,78.1z"/>
		<path class="st1" d="M16,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,78.1c-0.8-0.2-0.8,3,0,2.8C15.2,81.1,15.2,77.9,14.3,78.1z"/>
		<path class="st1" d="M14.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,78.1c-0.8-0.2-0.8,3,0,2.8C13.2,81.1,13.2,77.9,12.4,78.1z"/>
		<path class="st1" d="M12.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,78.1c-0.8-0.2-0.8,3,0,2.8C11.2,81.1,11.2,77.9,10.4,78.1z"/>
		<path class="st1" d="M10.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,78.1c-0.8-0.2-0.8,3,0,2.8C9.2,81.1,9.2,77.9,8.4,78.1z"/>
		<path class="st1" d="M8.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,78.1c-0.8-0.2-0.8,3,0,2.8C7.2,81.1,7.2,77.9,6.4,78.1z"/>
		<path class="st1" d="M6.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,78.1c-0.8-0.2-0.8,3,0,2.8C5.2,81.1,5.2,77.9,4.4,78.1z"/>
		<path class="st1" d="M4.1,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M32.2,78.1c-0.8-0.2-0.8,3,0,2.8C33.1,81.1,33.1,77.9,32.2,78.1z"/>
		<path class="st1" d="M31.9,79c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,79c-0.8-0.2-0.8,3,0,2.8C65.8,82,65.8,78.8,65,79z"/>
		<path class="st1" d="M64.7,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,79c-0.8-0.2-0.8,3,0,2.8C63.8,82,63.8,78.8,63,79z"/>
		<path class="st1" d="M62.7,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,79c-0.8-0.2-0.8,3,0,2.8C61.8,82,61.8,78.8,61,79z"/>
		<path class="st1" d="M60.7,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,79c-0.8-0.2-0.8,3,0,2.8C59.8,82,59.8,78.8,59,79z"/>
		<path class="st1" d="M58.7,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,79c-0.8-0.2-0.8,3,0,2.8C57.9,82,57.9,78.8,57,79z"/>
		<path class="st1" d="M56.7,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,79c-0.8-0.2-0.8,3,0,2.8C55.9,82,55.9,78.8,55,79z"/>
		<path class="st1" d="M54.8,79.8C53.9,79.5,54,78.1,55,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,79c-0.8-0.2-0.8,3,0,2.8C53.9,82,53.9,78.8,53.1,79z"/>
		<path class="st1" d="M52.8,79.8C51.9,79.5,52,78.1,53,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,79c-0.8-0.2-0.8,3,0,2.8C51.9,82,51.9,78.8,51.1,79z"/>
		<path class="st1" d="M50.8,79.8C49.9,79.5,50,78.1,51,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,79c-0.8-0.2-0.8,3,0,2.8C49.9,82,49.9,78.8,49.1,79z"/>
		<path class="st1" d="M48.8,79.8C47.9,79.5,48,78.1,49,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,79c-0.8-0.2-0.8,3,0,2.8C47.9,82,47.9,78.8,47.1,79z"/>
		<path class="st1" d="M46.8,79.8C45.9,79.5,46,78.1,47,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,79c-0.8-0.2-0.8,3,0,2.8C45.9,82,45.9,78.8,45.1,79z"/>
		<path class="st1" d="M44.8,79.8C43.9,79.5,44,78.1,45,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,79c-0.8-0.2-0.8,3,0,2.8C43.9,82,43.9,78.8,43.1,79z"/>
		<path class="st1" d="M42.8,79.8C41.9,79.5,42,78.1,43,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,79c-0.8-0.2-0.8,3,0,2.8C42,82,42,78.8,41.1,79z"/>
		<path class="st1" d="M40.8,79.8C39.9,79.5,40,78.1,41,78c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,79c-0.8-0.2-0.8,3,0,2.8C40,82,40,78.8,39.1,79z"/>
		<path class="st1" d="M38.9,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,79c-0.8-0.2-0.8,3,0,2.8C38,82,38,78.8,37.2,79z"/>
		<path class="st1" d="M36.9,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,79c-0.8-0.2-0.8,3,0,2.8C36,82,36,78.8,35.2,79z"/>
		<path class="st1" d="M34.9,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,79c-0.8-0.2-0.8,3,0,2.8C34.1,82,34.1,78.8,33.3,79z"/>
		<path class="st1" d="M33,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,79c-0.8-0.2-0.8,3,0,2.8C32.1,82,32.1,78.8,31.3,79z"/>
		<path class="st1" d="M31,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,79c-0.8-0.2-0.8,3,0,2.8C30.1,82,30.2,78.8,29.3,79z"/>
		<path class="st1" d="M29,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,79c-0.8-0.2-0.8,3,0,2.8C28.2,82,28.2,78.8,27.3,79z"/>
		<path class="st1" d="M27,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,79c-0.8-0.2-0.8,3,0,2.8C26.2,82,26.2,78.8,25.3,79z"/>
		<path class="st1" d="M25.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,79c-0.8-0.2-0.8,3,0,2.8C24.2,82,24.2,78.8,23.4,79z"/>
		<path class="st1" d="M23.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,79c-0.8-0.2-0.8,3,0,2.8C22.2,82,22.2,78.8,21.4,79z"/>
		<path class="st1" d="M21.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,79c-0.8-0.2-0.8,3,0,2.8C20.2,82,20.2,78.8,19.4,79z"/>
		<path class="st1" d="M19.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,79c-0.8-0.2-0.8,3,0,2.8C18.2,82,18.2,78.8,17.4,79z"/>
		<path class="st1" d="M17.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,79c-0.8-0.2-0.8,3,0,2.8C16.2,82,16.2,78.8,15.4,79z"/>
		<path class="st1" d="M15.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,79c-0.8-0.2-0.8,3,0,2.8C14.3,82,14.3,78.8,13.4,79z"/>
		<path class="st1" d="M13.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,79c-0.8-0.2-0.8,3,0,2.8C12.3,82,12.3,78.8,11.4,79z"/>
		<path class="st1" d="M11.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,79c-0.8-0.2-0.8,3,0,2.8C10.3,82,10.3,78.8,9.5,79z"/>
		<path class="st1" d="M9.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,79c-0.8-0.2-0.8,3,0,2.8C8.3,82,8.3,78.8,7.5,79z"/>
		<path class="st1" d="M7.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,79c-0.8-0.2-0.8,3,0,2.8C6.3,82,6.3,78.8,5.5,79z"/>
		<path class="st1" d="M5.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,79c-0.8-0.2-0.8,3,0,2.8C4.3,82,4.3,78.8,3.5,79z"/>
		<path class="st1" d="M3.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,79c-0.8-0.2-0.8,3,0,2.8C129.2,82,129.2,78.8,128.4,79z"/>
		<path class="st1" d="M128.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,79c-0.8-0.2-0.8,3,0,2.8C127.2,82,127.2,78.8,126.4,79z"/>
		<path class="st1" d="M126.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,79c-0.8-0.2-0.8,3,0,2.8C125.3,82,125.3,78.8,124.4,79z"/>
		<path class="st1" d="M124.1,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,79c-0.8-0.2-0.8,3,0,2.8C123.3,82,123.3,78.8,122.4,79z"/>
		<path class="st1" d="M122.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,79c-0.8-0.2-0.8,3,0,2.8C121.3,82,121.3,78.8,120.5,79z"/>
		<path class="st1" d="M120.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,79c-0.8-0.2-0.8,3,0,2.8C119.3,82,119.3,78.8,118.5,79z"/>
		<path class="st1" d="M118.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,79c-0.8-0.2-0.8,3,0,2.8C117.3,82,117.3,78.8,116.5,79z"/>
		<path class="st1" d="M116.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,79c-0.8-0.2-0.8,3,0,2.8C115.3,82,115.3,78.8,114.5,79z"/>
		<path class="st1" d="M114.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,79c-0.8-0.2-0.8,3,0,2.8C113.3,82,113.3,78.8,112.5,79z"/>
		<path class="st1" d="M112.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,79c-0.8-0.2-0.8,3,0,2.8C111.3,82,111.3,78.8,110.5,79z"/>
		<path class="st1" d="M110.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,79c-0.8-0.2-0.8,3,0,2.8C109.4,82,109.4,78.8,108.5,79z"/>
		<path class="st1" d="M108.2,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,79c-0.8-0.2-0.8,3,0,2.8C107.4,82,107.4,78.8,106.5,79z"/>
		<path class="st1" d="M106.3,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,79c-0.8-0.2-0.8,3,0,2.8C105.4,82,105.4,78.8,104.6,79z"/>
		<path class="st1" d="M104.3,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,79c-0.8-0.2-0.8,3,0,2.8C103.4,82,103.4,78.8,102.6,79z"/>
		<path class="st1" d="M102.3,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,79c-0.8-0.2-0.8,3,0,2.8C101.4,82,101.4,78.8,100.6,79z"/>
		<path class="st1" d="M100.3,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,79c-0.8-0.2-0.8,3,0,2.8C99.4,82,99.4,78.8,98.6,79z"/>
		<path class="st1" d="M98.3,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,79c-0.8-0.2-0.8,3,0,2.8C97.5,82,97.6,78.8,96.7,79z"/>
		<path class="st1" d="M96.4,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,79c-0.8-0.2-0.8,3,0,2.8C95.6,82,95.6,78.8,94.7,79z"/>
		<path class="st1" d="M94.4,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,79c-0.8-0.2-0.8,3,0,2.8C93.6,82,93.6,78.8,92.7,79z"/>
		<path class="st1" d="M92.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,79c-0.8-0.2-0.8,3,0,2.8C91.6,82,91.6,78.8,90.8,79z"/>
		<path class="st1" d="M90.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,79c-0.8-0.2-0.8,3,0,2.8C89.6,82,89.6,78.8,88.8,79z"/>
		<path class="st1" d="M88.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,79c-0.8-0.2-0.8,3,0,2.8C87.6,82,87.6,78.8,86.8,79z"/>
		<path class="st1" d="M86.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,79c-0.8-0.2-0.8,3,0,2.8C85.6,82,85.6,78.8,84.8,79z"/>
		<path class="st1" d="M84.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,79c-0.8-0.2-0.8,3,0,2.8C83.6,82,83.6,78.8,82.8,79z"/>
		<path class="st1" d="M82.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,79c-0.8-0.2-0.8,3,0,2.8C81.7,82,81.7,78.8,80.8,79z"/>
		<path class="st1" d="M80.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,79c-0.8-0.2-0.8,3,0,2.8C79.7,82,79.7,78.8,78.8,79z"/>
		<path class="st1" d="M78.5,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,79c-0.8-0.2-0.8,3,0,2.8C77.7,82,77.7,78.8,76.9,79z"/>
		<path class="st1" d="M76.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,79c-0.8-0.2-0.8,3,0,2.8C75.7,82,75.7,78.8,74.9,79z"/>
		<path class="st1" d="M74.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,79c-0.8-0.2-0.8,3,0,2.8C73.7,82,73.7,78.8,72.9,79z"/>
		<path class="st1" d="M72.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,79c-0.8-0.2-0.8,3,0,2.8C71.7,82,71.7,78.8,70.9,79z"/>
		<path class="st1" d="M70.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,79c-0.8-0.2-0.8,3,0,2.8C69.7,82,69.7,78.8,68.9,79z"/>
		<path class="st1" d="M68.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,79c-0.8-0.2-0.8,3,0,2.8C67.7,82,67.7,78.8,66.9,79z"/>
		<path class="st1" d="M66.6,79.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,79.7c-0.8-0.2-0.8,3,0,2.8C66.7,82.7,66.7,79.6,65.9,79.7z"/>
		<path class="st1" d="M65.6,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,79.7c-0.8-0.2-0.8,3,0,2.8C64.7,82.7,64.7,79.6,63.9,79.7z"/>
		<path class="st1" d="M63.6,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,79.7c-0.8-0.2-0.8,3,0,2.8C62.7,82.7,62.8,79.6,61.9,79.7z"/>
		<path class="st1" d="M61.6,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,79.7c-0.8-0.2-0.8,3,0,2.8C60.8,82.7,60.8,79.6,59.9,79.7z"/>
		<path class="st1" d="M59.6,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,79.7c-0.8-0.2-0.8,3,0,2.8C58.8,82.7,58.8,79.6,57.9,79.7z"/>
		<path class="st1" d="M57.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,79.7c-0.8-0.2-0.8,3,0,2.8C56.8,82.7,56.8,79.6,56,79.7z"/>
		<path class="st1" d="M55.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,79.7c-0.8-0.2-0.8,3,0,2.8C54.8,82.7,54.8,79.6,54,79.7z"/>
		<path class="st1" d="M53.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,79.7c-0.8-0.2-0.8,3,0,2.8C52.8,82.7,52.8,79.6,52,79.7z"/>
		<path class="st1" d="M51.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,79.7c-0.8-0.2-0.8,3,0,2.8C50.8,82.7,50.8,79.6,50,79.7z"/>
		<path class="st1" d="M49.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,79.7c-0.8-0.2-0.8,3,0,2.8C48.8,82.7,48.8,79.6,48,79.7z"/>
		<path class="st1" d="M47.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,79.7c-0.8-0.2-0.8,3,0,2.8C46.9,82.7,46.9,79.6,46,79.7z"/>
		<path class="st1" d="M45.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,79.7c-0.8-0.2-0.8,3,0,2.8C44.9,82.7,44.9,79.6,44,79.7z"/>
		<path class="st1" d="M43.7,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,79.7c-0.8-0.2-0.8,3,0,2.8C42.9,82.7,42.9,79.6,42.1,79.7z"/>
		<path class="st1" d="M41.8,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,79.7c-0.8-0.2-0.8,3,0,2.8C40.9,82.7,40.9,79.6,40.1,79.7z"/>
		<path class="st1" d="M39.8,80.6c-1-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,79.7c-0.8-0.2-0.8,3,0,2.8C38.9,82.7,38.9,79.6,38.1,79.7z"/>
		<path class="st1" d="M37.8,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,79.7c-0.8-0.2-0.8,3,0,2.8C36.9,82.7,36.9,79.6,36.1,79.7z"/>
		<path class="st1" d="M35.8,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,79.7c-0.8-0.2-0.8,3,0,2.8C35,82.7,35,79.6,34.2,79.7z"/>
		<path class="st1" d="M33.9,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,79.7c-0.8-0.2-0.8,3,0,2.8C33.1,82.7,33.1,79.6,32.2,79.7z"/>
		<path class="st1" d="M31.9,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,79.7c-0.8-0.2-0.8,3,0,2.8C31.1,82.7,31.1,79.6,30.2,79.7z"/>
		<path class="st1" d="M30,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,79.7c-0.8-0.2-0.8,3,0,2.8C29.1,82.7,29.1,79.6,28.3,79.7z"/>
		<path class="st1" d="M28,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,79.7c-0.8-0.2-0.8,3,0,2.8C27.1,82.7,27.1,79.6,26.3,79.7z"/>
		<path class="st1" d="M26,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,79.7c-0.8-0.2-0.8,3,0,2.8C25.1,82.7,25.1,79.6,24.3,79.7z"/>
		<path class="st1" d="M24,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,79.7c-0.8-0.2-0.8,3,0,2.8C23.1,82.7,23.1,79.6,22.3,79.7z"/>
		<path class="st1" d="M22,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,79.7c-0.8-0.2-0.8,3,0,2.8C21.1,82.7,21.1,79.6,20.3,79.7z"/>
		<path class="st1" d="M20,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,79.7c-0.8-0.2-0.8,3,0,2.8C19.1,82.7,19.1,79.6,18.3,79.7z"/>
		<path class="st1" d="M18,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,79.7c-0.8-0.2-0.8,3,0,2.8C17.2,82.7,17.2,79.6,16.3,79.7z"/>
		<path class="st1" d="M16,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,79.7c-0.8-0.2-0.8,3,0,2.8C15.2,82.7,15.2,79.6,14.3,79.7z"/>
		<path class="st1" d="M14.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,79.7c-0.8-0.2-0.8,3,0,2.8C13.2,82.7,13.2,79.6,12.4,79.7z"/>
		<path class="st1" d="M12.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,79.7c-0.8-0.2-0.8,3,0,2.8C11.2,82.7,11.2,79.6,10.4,79.7z"/>
		<path class="st1" d="M10.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,79.7c-0.8-0.2-0.8,3,0,2.8C9.2,82.7,9.2,79.6,8.4,79.7z"/>
		<path class="st1" d="M8.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,79.7c-0.8-0.2-0.8,3,0,2.8C7.2,82.7,7.2,79.6,6.4,79.7z"/>
		<path class="st1" d="M6.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,79.7c-0.8-0.2-0.8,3,0,2.8C5.2,82.7,5.2,79.6,4.4,79.7z"/>
		<path class="st1" d="M4.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,79.7c-0.8-0.2-0.8,3,0,2.8C130.1,82.7,130.1,79.6,129.3,79.7z"/>
		<path class="st1" d="M129,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,79.7c-0.8-0.2-0.8,3,0,2.8C128.2,82.7,128.2,79.6,127.3,79.7z"/>
		<path class="st1" d="M127,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,79.7c-0.8-0.2-0.8,3,0,2.8C126.2,82.7,126.2,79.6,125.3,79.7z"/>
		<path class="st1" d="M125.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,79.7c-0.8-0.2-0.8,3,0,2.8C124.2,82.7,124.2,79.6,123.4,79.7z"/>
		<path class="st1" d="M123.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,79.7c-0.8-0.2-0.8,3,0,2.8C122.2,82.7,122.2,79.6,121.4,79.7z"/>
		<path class="st1" d="M121.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,79.7c-0.8-0.2-0.8,3,0,2.8C120.2,82.7,120.2,79.6,119.4,79.7z"/>
		<path class="st1" d="M119.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,79.7c-0.8-0.2-0.8,3,0,2.8C118.2,82.7,118.2,79.6,117.4,79.7z"/>
		<path class="st1" d="M117.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,79.7c-0.8-0.2-0.8,3,0,2.8C116.2,82.7,116.2,79.6,115.4,79.7z"/>
		<path class="st1" d="M115.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,79.7c-0.8-0.2-0.8,3,0,2.8C114.3,82.7,114.3,79.6,113.4,79.7z"/>
		<path class="st1" d="M113.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,79.7c-0.8-0.2-0.8,3,0,2.8C112.3,82.7,112.3,79.6,111.4,79.7z"/>
		<path class="st1" d="M111.1,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,79.7c-0.8-0.2-0.8,3,0,2.8C110.3,82.7,110.3,79.6,109.4,79.7z"/>
		<path class="st1" d="M109.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,79.7c-0.8-0.2-0.8,3,0,2.8C108.3,82.7,108.3,79.6,107.5,79.7z"/>
		<path class="st1" d="M107.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,79.7c-0.8-0.2-0.8,3,0,2.8C106.3,82.7,106.3,79.6,105.5,79.7z"/>
		<path class="st1" d="M105.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,79.7c-0.8-0.2-0.8,3,0,2.8C104.3,82.7,104.3,79.6,103.5,79.7z"/>
		<path class="st1" d="M103.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,79.7c-0.8-0.2-0.8,3,0,2.8C102.3,82.7,102.3,79.6,101.5,79.7z"/>
		<path class="st1" d="M101.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,79.7c-0.8-0.2-0.8,3,0,2.8C100.3,82.7,100.3,79.6,99.5,79.7z"/>
		<path class="st1" d="M99.2,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,79.7c-0.8-0.2-0.8,3,0,2.8C98.5,82.7,98.5,79.6,97.6,79.7z"/>
		<path class="st1" d="M97.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,79.7c-0.8-0.2-0.8,3,0,2.8C96.5,82.7,96.5,79.6,95.7,79.7z"/>
		<path class="st1" d="M95.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,79.7c-0.8-0.2-0.8,3,0,2.8C94.5,82.7,94.5,79.6,93.7,79.7z"/>
		<path class="st1" d="M93.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,79.7c-0.8-0.2-0.8,3,0,2.8C92.5,82.7,92.5,79.6,91.7,79.7z"/>
		<path class="st1" d="M91.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,79.7c-0.8-0.2-0.8,3,0,2.8C90.5,82.7,90.5,79.6,89.7,79.7z"/>
		<path class="st1" d="M89.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,79.7c-0.8-0.2-0.8,3,0,2.8C88.5,82.7,88.5,79.6,87.7,79.7z"/>
		<path class="st1" d="M87.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,79.7c-0.8-0.2-0.8,3,0,2.8C86.5,82.7,86.5,79.6,85.7,79.7z"/>
		<path class="st1" d="M85.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,79.7c-0.8-0.2-0.8,3,0,2.8C84.6,82.7,84.6,79.6,83.7,79.7z"/>
		<path class="st1" d="M83.4,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,79.7c-0.8-0.2-0.8,3,0,2.8C82.6,82.7,82.6,79.6,81.7,79.7z"/>
		<path class="st1" d="M81.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,79.7c-0.8-0.2-0.8,3,0,2.8C80.6,82.7,80.6,79.6,79.8,79.7z"/>
		<path class="st1" d="M79.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,79.7c-0.8-0.2-0.8,3,0,2.8C78.6,82.7,78.6,79.6,77.8,79.7z"/>
		<path class="st1" d="M77.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,79.7c-0.8-0.2-0.8,3,0,2.8C76.6,82.7,76.6,79.6,75.8,79.7z"/>
		<path class="st1" d="M75.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,79.7c-0.8-0.2-0.8,3,0,2.8C74.6,82.7,74.6,79.6,73.8,79.7z"/>
		<path class="st1" d="M73.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,79.7c-0.8-0.2-0.8,3,0,2.8C72.6,82.7,72.6,79.6,71.8,79.7z"/>
		<path class="st1" d="M71.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,79.7c-0.8-0.2-0.8,3,0,2.8C70.7,82.7,70.7,79.6,69.8,79.7z"/>
		<path class="st1" d="M69.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,79.7c-0.8-0.2-0.8,3,0,2.8C68.7,82.7,68.7,79.6,67.8,79.7z"/>
		<path class="st1" d="M67.5,80.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,80.6c-0.8-0.2-0.8,3,0,2.8C65.8,83.6,65.8,80.5,65,80.6z"/>
		<path class="st1" d="M64.7,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,80.6c-0.8-0.2-0.8,3,0,2.8C63.8,83.6,63.8,80.5,63,80.6z"/>
		<path class="st1" d="M62.7,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,80.6c-0.8-0.2-0.8,3,0,2.8C61.8,83.6,61.8,80.5,61,80.6z"/>
		<path class="st1" d="M60.7,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,80.6c-0.8-0.2-0.8,3,0,2.8C59.8,83.6,59.8,80.5,59,80.6z"/>
		<path class="st1" d="M58.7,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,80.6c-0.8-0.2-0.8,3,0,2.8C57.9,83.6,57.9,80.5,57,80.6z"/>
		<path class="st1" d="M56.7,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,80.6c-0.8-0.2-0.8,3,0,2.8C55.9,83.6,55.9,80.5,55,80.6z"/>
		<path class="st1" d="M54.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,80.6c-0.8-0.2-0.8,3,0,2.8C53.9,83.6,53.9,80.5,53.1,80.6z"/>
		<path class="st1" d="M52.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,80.6c-0.8-0.2-0.8,3,0,2.8C51.9,83.6,51.9,80.5,51.1,80.6z"/>
		<path class="st1" d="M50.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,80.6c-0.8-0.2-0.8,3,0,2.8C49.9,83.6,49.9,80.5,49.1,80.6z"/>
		<path class="st1" d="M48.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,80.6c-0.8-0.2-0.8,3,0,2.8C47.9,83.6,47.9,80.5,47.1,80.6z"/>
		<path class="st1" d="M46.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,80.6c-0.8-0.2-0.8,3,0,2.8C45.9,83.6,45.9,80.5,45.1,80.6z"/>
		<path class="st1" d="M44.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,80.6c-0.8-0.2-0.8,3,0,2.8C43.9,83.6,43.9,80.5,43.1,80.6z"/>
		<path class="st1" d="M42.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,80.6c-0.8-0.2-0.8,3,0,2.8C42,83.6,42,80.5,41.1,80.6z"/>
		<path class="st1" d="M40.8,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,80.6c-0.8-0.2-0.8,3,0,2.8C40,83.6,40,80.5,39.1,80.6z"/>
		<path class="st1" d="M38.9,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,80.6c-0.8-0.2-0.8,3,0,2.8C38,83.6,38,80.5,37.2,80.6z"/>
		<path class="st1" d="M36.9,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,80.6c-0.8-0.2-0.8,3,0,2.8C36,83.6,36,80.5,35.2,80.6z"/>
		<path class="st1" d="M34.9,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,80.6c-0.8-0.2-0.8,3,0,2.8C34.1,83.6,34.1,80.5,33.3,80.6z"/>
		<path class="st1" d="M33,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,80.6c-0.8-0.2-0.8,3,0,2.8C32.1,83.6,32.1,80.5,31.3,80.6z"/>
		<path class="st1" d="M31,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,80.6c-0.8-0.2-0.8,3,0,2.8C30.1,83.6,30.2,80.5,29.3,80.6z"/>
		<path class="st1" d="M29,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,80.6c-0.8-0.2-0.8,3,0,2.8C28.2,83.6,28.2,80.5,27.3,80.6z"/>
		<path class="st1" d="M27,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,80.6c-0.8-0.2-0.8,3,0,2.8C26.2,83.6,26.2,80.5,25.3,80.6z"/>
		<path class="st1" d="M25.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,80.6c-0.8-0.2-0.8,3,0,2.8C24.2,83.6,24.2,80.5,23.4,80.6z"/>
		<path class="st1" d="M23.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,80.6c-0.8-0.2-0.8,3,0,2.8C22.2,83.6,22.2,80.5,21.4,80.6z"/>
		<path class="st1" d="M21.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,80.6c-0.8-0.2-0.8,3,0,2.8C20.2,83.6,20.2,80.5,19.4,80.6z"/>
		<path class="st1" d="M19.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,80.6c-0.8-0.2-0.8,3,0,2.8C18.2,83.6,18.2,80.5,17.4,80.6z"/>
		<path class="st1" d="M17.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,80.6c-0.8-0.2-0.8,3,0,2.8C16.2,83.6,16.2,80.5,15.4,80.6z"/>
		<path class="st1" d="M15.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,80.6c-0.8-0.2-0.8,3,0,2.8C14.3,83.6,14.3,80.5,13.4,80.6z"/>
		<path class="st1" d="M13.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,80.6c-0.8-0.2-0.8,3,0,2.8C12.3,83.6,12.3,80.5,11.4,80.6z"/>
		<path class="st1" d="M11.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,80.6c-0.8-0.2-0.8,3,0,2.8C10.3,83.6,10.3,80.5,9.5,80.6z"/>
		<path class="st1" d="M9.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,80.6c-0.8-0.2-0.8,3,0,2.8C8.3,83.6,8.3,80.5,7.5,80.6z"/>
		<path class="st1" d="M7.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,80.6c-0.8-0.2-0.8,3,0,2.8C6.3,83.6,6.3,80.5,5.5,80.6z"/>
		<path class="st1" d="M5.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,80.6c-0.8-0.2-0.8,3,0,2.8C4.3,83.6,4.3,80.5,3.5,80.6z"/>
		<path class="st1" d="M3.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,80.6c-0.8-0.2-0.8,3,0,2.8C129.2,83.6,129.2,80.5,128.4,80.6z"/>
		<path class="st1" d="M128.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,80.6c-0.8-0.2-0.8,3,0,2.8C127.2,83.6,127.2,80.5,126.4,80.6z"/>
		<path class="st1" d="M126.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,80.6c-0.8-0.2-0.8,3,0,2.8C125.3,83.6,125.3,80.5,124.4,80.6z"/>
		<path class="st1" d="M124.1,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,80.6c-0.8-0.2-0.8,3,0,2.8C123.3,83.6,123.3,80.5,122.4,80.6z"/>
		<path class="st1" d="M122.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,80.6c-0.8-0.2-0.8,3,0,2.8C121.3,83.6,121.3,80.5,120.5,80.6z"/>
		<path class="st1" d="M120.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,80.6c-0.8-0.2-0.8,3,0,2.8C119.3,83.6,119.3,80.5,118.5,80.6z"/>
		<path class="st1" d="M118.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,80.6c-0.8-0.2-0.8,3,0,2.8C117.3,83.6,117.3,80.5,116.5,80.6z"/>
		<path class="st1" d="M116.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,80.6c-0.8-0.2-0.8,3,0,2.8C115.3,83.6,115.3,80.5,114.5,80.6z"/>
		<path class="st1" d="M114.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,80.6c-0.8-0.2-0.8,3,0,2.8C113.3,83.6,113.3,80.5,112.5,80.6z"/>
		<path class="st1" d="M112.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,80.6c-0.8-0.2-0.8,3,0,2.8C111.3,83.6,111.3,80.5,110.5,80.6z"/>
		<path class="st1" d="M110.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,80.6c-0.8-0.2-0.8,3,0,2.8C109.4,83.6,109.4,80.5,108.5,80.6z"/>
		<path class="st1" d="M108.2,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,80.6c-0.8-0.2-0.8,3,0,2.8C107.4,83.6,107.4,80.5,106.5,80.6z"/>
		<path class="st1" d="M106.3,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,80.6c-0.8-0.2-0.8,3,0,2.8C105.4,83.6,105.4,80.5,104.6,80.6z"/>
		<path class="st1" d="M104.3,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,80.6c-0.8-0.2-0.8,3,0,2.8C103.4,83.6,103.4,80.5,102.6,80.6z"/>
		<path class="st1" d="M102.3,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,80.6c-0.8-0.2-0.8,3,0,2.8C101.4,83.6,101.4,80.5,100.6,80.6z"/>
		<path class="st1" d="M100.3,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,80.6c-0.8-0.2-0.8,3,0,2.8C99.4,83.6,99.4,80.5,98.6,80.6z"/>
		<path class="st1" d="M98.3,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,80.6c-0.8-0.2-0.8,3,0,2.8C97.5,83.6,97.6,80.5,96.7,80.6z"/>
		<path class="st1" d="M96.4,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,80.6c-0.8-0.2-0.8,3,0,2.8C95.6,83.6,95.6,80.5,94.7,80.6z"/>
		<path class="st1" d="M94.4,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,80.6c-0.8-0.2-0.8,3,0,2.8C93.6,83.6,93.6,80.5,92.7,80.6z"/>
		<path class="st1" d="M92.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,80.6c-0.8-0.2-0.8,3,0,2.8C91.6,83.6,91.6,80.5,90.8,80.6z"/>
		<path class="st1" d="M90.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,80.6c-0.8-0.2-0.8,3,0,2.8C89.6,83.6,89.6,80.5,88.8,80.6z"/>
		<path class="st1" d="M88.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,80.6c-0.8-0.2-0.8,3,0,2.8C87.6,83.6,87.6,80.5,86.8,80.6z"/>
		<path class="st1" d="M86.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,80.6c-0.8-0.2-0.8,3,0,2.8C85.6,83.6,85.6,80.5,84.8,80.6z"/>
		<path class="st1" d="M84.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,80.6c-0.8-0.2-0.8,3,0,2.8C83.6,83.6,83.6,80.5,82.8,80.6z"/>
		<path class="st1" d="M82.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,80.6c-0.8-0.2-0.8,3,0,2.8C81.7,83.6,81.7,80.5,80.8,80.6z"/>
		<path class="st1" d="M80.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,80.6c-0.8-0.2-0.8,3,0,2.8C79.7,83.6,79.7,80.5,78.8,80.6z"/>
		<path class="st1" d="M78.5,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,80.6c-0.8-0.2-0.8,3,0,2.8C77.7,83.6,77.7,80.5,76.9,80.6z"/>
		<path class="st1" d="M76.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,80.6c-0.8-0.2-0.8,3,0,2.8C75.7,83.6,75.7,80.5,74.9,80.6z"/>
		<path class="st1" d="M74.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,80.6c-0.8-0.2-0.8,3,0,2.8C73.7,83.6,73.7,80.5,72.9,80.6z"/>
		<path class="st1" d="M72.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,80.6c-0.8-0.2-0.8,3,0,2.8C71.7,83.6,71.7,80.5,70.9,80.6z"/>
		<path class="st1" d="M70.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,80.6c-0.8-0.2-0.8,3,0,2.8C69.7,83.6,69.7,80.5,68.9,80.6z"/>
		<path class="st1" d="M68.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,80.6c-0.8-0.2-0.8,3,0,2.8C67.7,83.6,67.7,80.5,66.9,80.6z"/>
		<path class="st1" d="M66.6,81.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,81.4c-0.8-0.2-0.8,3,0,2.8C66.7,84.4,66.7,81.2,65.9,81.4z"/>
		<path class="st1" d="M65.6,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,81.4c-0.8-0.2-0.8,3,0,2.8C64.7,84.4,64.7,81.2,63.9,81.4z"/>
		<path class="st1" d="M63.6,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,81.4c-0.8-0.2-0.8,3,0,2.8C62.7,84.4,62.8,81.2,61.9,81.4z"/>
		<path class="st1" d="M61.6,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,81.4c-0.8-0.2-0.8,3,0,2.8C60.8,84.4,60.8,81.2,59.9,81.4z"/>
		<path class="st1" d="M59.6,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,81.4c-0.8-0.2-0.8,3,0,2.8C58.8,84.4,58.8,81.2,57.9,81.4z"/>
		<path class="st1" d="M57.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,81.4c-0.8-0.2-0.8,3,0,2.8C56.8,84.4,56.8,81.2,56,81.4z"/>
		<path class="st1" d="M55.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,81.4c-0.8-0.2-0.8,3,0,2.8C54.8,84.4,54.8,81.2,54,81.4z"/>
		<path class="st1" d="M53.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,81.4c-0.8-0.2-0.8,3,0,2.8C52.8,84.4,52.8,81.2,52,81.4z"/>
		<path class="st1" d="M51.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,81.4c-0.8-0.2-0.8,3,0,2.8C50.8,84.4,50.8,81.2,50,81.4z"/>
		<path class="st1" d="M49.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,81.4c-0.8-0.2-0.8,3,0,2.8C48.8,84.4,48.8,81.2,48,81.4z"/>
		<path class="st1" d="M47.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,81.4c-0.8-0.2-0.8,3,0,2.8C46.9,84.4,46.9,81.2,46,81.4z"/>
		<path class="st1" d="M45.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,81.4c-0.8-0.2-0.8,3,0,2.8C44.9,84.4,44.9,81.2,44,81.4z"/>
		<path class="st1" d="M43.7,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,81.4c-0.8-0.2-0.8,3,0,2.8C42.9,84.4,42.9,81.2,42.1,81.4z"/>
		<path class="st1" d="M41.8,82.3C40.9,82,41,80.6,42,80.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,81.4c-0.8-0.2-0.8,3,0,2.8C40.9,84.4,40.9,81.2,40.1,81.4z"/>
		<path class="st1" d="M39.8,82.3C38.9,82,39,80.6,40,80.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,81.4c-0.8-0.2-0.8,3,0,2.8C38.9,84.4,38.9,81.2,38.1,81.4z"/>
		<path class="st1" d="M37.8,82.3C36.9,82,37,80.6,38,80.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,81.4c-0.8-0.2-0.8,3,0,2.8C36.9,84.4,36.9,81.2,36.1,81.4z"/>
		<path class="st1" d="M35.8,82.3C34.9,82,35,80.6,36,80.5c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,81.4c-0.8-0.2-0.8,3,0,2.8C35,84.4,35,81.2,34.2,81.4z"/>
		<path class="st1" d="M33.9,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,81.4c-0.8-0.2-0.8,3,0,2.8C33.1,84.4,33.1,81.2,32.2,81.4z"/>
		<path class="st1" d="M31.9,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,81.4c-0.8-0.2-0.8,3,0,2.8C31.1,84.4,31.1,81.2,30.2,81.4z"/>
		<path class="st1" d="M30,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,81.4c-0.8-0.2-0.8,3,0,2.8C29.1,84.4,29.1,81.2,28.3,81.4z"/>
		<path class="st1" d="M28,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,81.4c-0.8-0.2-0.8,3,0,2.8C27.1,84.4,27.1,81.2,26.3,81.4z"/>
		<path class="st1" d="M26,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,81.4c-0.8-0.2-0.8,3,0,2.8C25.1,84.4,25.1,81.2,24.3,81.4z"/>
		<path class="st1" d="M24,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,81.4c-0.8-0.2-0.8,3,0,2.8C23.1,84.4,23.1,81.2,22.3,81.4z"/>
		<path class="st1" d="M22,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,81.4c-0.8-0.2-0.8,3,0,2.8C21.1,84.4,21.1,81.2,20.3,81.4z"/>
		<path class="st1" d="M20,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,81.4c-0.8-0.2-0.8,3,0,2.8C19.1,84.4,19.1,81.2,18.3,81.4z"/>
		<path class="st1" d="M18,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,81.4c-0.8-0.2-0.8,3,0,2.8C17.2,84.4,17.2,81.2,16.3,81.4z"/>
		<path class="st1" d="M16,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,81.4c-0.8-0.2-0.8,3,0,2.8C15.2,84.4,15.2,81.2,14.3,81.4z"/>
		<path class="st1" d="M14.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,81.4c-0.8-0.2-0.8,3,0,2.8C13.2,84.4,13.2,81.2,12.4,81.4z"/>
		<path class="st1" d="M12.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,81.4c-0.8-0.2-0.8,3,0,2.8C11.2,84.4,11.2,81.2,10.4,81.4z"/>
		<path class="st1" d="M10.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,81.4c-0.8-0.2-0.8,3,0,2.8C9.2,84.4,9.2,81.2,8.4,81.4z"/>
		<path class="st1" d="M8.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,81.4c-0.8-0.2-0.8,3,0,2.8C7.2,84.4,7.2,81.2,6.4,81.4z"/>
		<path class="st1" d="M6.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,81.4c-0.8-0.2-0.8,3,0,2.8C5.2,84.4,5.2,81.2,4.4,81.4z"/>
		<path class="st1" d="M4.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,81.4c-0.8-0.2-0.8,3,0,2.8C130.1,84.4,130.1,81.2,129.3,81.4z"/>
		<path class="st1" d="M129,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,81.4c-0.8-0.2-0.8,3,0,2.8C128.2,84.4,128.2,81.2,127.3,81.4z"/>
		<path class="st1" d="M127,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,81.4c-0.8-0.2-0.8,3,0,2.8C126.2,84.4,126.2,81.2,125.3,81.4z"/>
		<path class="st1" d="M125.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,81.4c-0.8-0.2-0.8,3,0,2.8C124.2,84.4,124.2,81.2,123.4,81.4z"/>
		<path class="st1" d="M123.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,81.4c-0.8-0.2-0.8,3,0,2.8C122.2,84.4,122.2,81.2,121.4,81.4z"/>
		<path class="st1" d="M121.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,81.4c-0.8-0.2-0.8,3,0,2.8C120.2,84.4,120.2,81.2,119.4,81.4z"/>
		<path class="st1" d="M119.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,81.4c-0.8-0.2-0.8,3,0,2.8C118.2,84.4,118.2,81.2,117.4,81.4z"/>
		<path class="st1" d="M117.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,81.4c-0.8-0.2-0.8,3,0,2.8C116.2,84.4,116.2,81.2,115.4,81.4z"/>
		<path class="st1" d="M115.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,81.4c-0.8-0.2-0.8,3,0,2.8C114.3,84.4,114.3,81.2,113.4,81.4z"/>
		<path class="st1" d="M113.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,81.4c-0.8-0.2-0.8,3,0,2.8C112.3,84.4,112.3,81.2,111.4,81.4z"/>
		<path class="st1" d="M111.1,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,81.4c-0.8-0.2-0.8,3,0,2.8C110.3,84.4,110.3,81.2,109.4,81.4z"/>
		<path class="st1" d="M109.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,81.4c-0.8-0.2-0.8,3,0,2.8C108.3,84.4,108.3,81.2,107.5,81.4z"/>
		<path class="st1" d="M107.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,81.4c-0.8-0.2-0.8,3,0,2.8C106.3,84.4,106.3,81.2,105.5,81.4z"/>
		<path class="st1" d="M105.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,81.4c-0.8-0.2-0.8,3,0,2.8C104.3,84.4,104.3,81.2,103.5,81.4z"/>
		<path class="st1" d="M103.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,81.4c-0.8-0.2-0.8,3,0,2.8C102.3,84.4,102.3,81.2,101.5,81.4z"/>
		<path class="st1" d="M101.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,81.4c-0.8-0.2-0.8,3,0,2.8C100.3,84.4,100.3,81.2,99.5,81.4z"/>
		<path class="st1" d="M99.2,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,81.4c-0.8-0.2-0.8,3,0,2.8C98.5,84.4,98.5,81.2,97.6,81.4z"/>
		<path class="st1" d="M97.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,81.4c-0.8-0.2-0.8,3,0,2.8C96.5,84.4,96.5,81.2,95.7,81.4z"/>
		<path class="st1" d="M95.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,81.4c-0.8-0.2-0.8,3,0,2.8C94.5,84.4,94.5,81.2,93.7,81.4z"/>
		<path class="st1" d="M93.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,81.4c-0.8-0.2-0.8,3,0,2.8C92.5,84.4,92.5,81.2,91.7,81.4z"/>
		<path class="st1" d="M91.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,81.4c-0.8-0.2-0.8,3,0,2.8C90.5,84.4,90.5,81.2,89.7,81.4z"/>
		<path class="st1" d="M89.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,81.4c-0.8-0.2-0.8,3,0,2.8C88.5,84.4,88.5,81.2,87.7,81.4z"/>
		<path class="st1" d="M87.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,81.4c-0.8-0.2-0.8,3,0,2.8C86.5,84.4,86.5,81.2,85.7,81.4z"/>
		<path class="st1" d="M85.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,81.4c-0.8-0.2-0.8,3,0,2.8C84.6,84.4,84.6,81.2,83.7,81.4z"/>
		<path class="st1" d="M83.4,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,81.4c-0.8-0.2-0.8,3,0,2.8C82.6,84.4,82.6,81.2,81.7,81.4z"/>
		<path class="st1" d="M81.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,81.4c-0.8-0.2-0.8,3,0,2.8C80.6,84.4,80.6,81.2,79.8,81.4z"/>
		<path class="st1" d="M79.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,81.4c-0.8-0.2-0.8,3,0,2.8C78.6,84.4,78.6,81.2,77.8,81.4z"/>
		<path class="st1" d="M77.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,81.4c-0.8-0.2-0.8,3,0,2.8C76.6,84.4,76.6,81.2,75.8,81.4z"/>
		<path class="st1" d="M75.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,81.4c-0.8-0.2-0.8,3,0,2.8C74.6,84.4,74.6,81.2,73.8,81.4z"/>
		<path class="st1" d="M73.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,81.4c-0.8-0.2-0.8,3,0,2.8C72.6,84.4,72.6,81.2,71.8,81.4z"/>
		<path class="st1" d="M71.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,81.4c-0.8-0.2-0.8,3,0,2.8C70.7,84.4,70.7,81.2,69.8,81.4z"/>
		<path class="st1" d="M69.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,81.4c-0.8-0.2-0.8,3,0,2.8C68.7,84.4,68.7,81.2,67.8,81.4z"/>
		<path class="st1" d="M67.5,82.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,82.3c-0.8-0.2-0.8,3,0,2.8C65.8,85.3,65.8,82.2,65,82.3z"/>
		<path class="st1" d="M64.7,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,82.3c-0.8-0.2-0.8,3,0,2.8C63.8,85.3,63.8,82.2,63,82.3z"/>
		<path class="st1" d="M62.7,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,82.3c-0.8-0.2-0.8,3,0,2.8C61.8,85.3,61.8,82.2,61,82.3z"/>
		<path class="st1" d="M60.7,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,82.3c-0.8-0.2-0.8,3,0,2.8C59.8,85.3,59.8,82.2,59,82.3z"/>
		<path class="st1" d="M58.7,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,82.3c-0.8-0.2-0.8,3,0,2.8C57.9,85.3,57.9,82.2,57,82.3z"/>
		<path class="st1" d="M56.7,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,82.3c-0.8-0.2-0.8,3,0,2.8C55.9,85.3,55.9,82.2,55,82.3z"/>
		<path class="st1" d="M54.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,82.3c-0.8-0.2-0.8,3,0,2.8C53.9,85.3,53.9,82.2,53.1,82.3z"/>
		<path class="st1" d="M52.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,82.3c-0.8-0.2-0.8,3,0,2.8C51.9,85.3,51.9,82.2,51.1,82.3z"/>
		<path class="st1" d="M50.8,83.2c-1-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,82.3c-0.8-0.2-0.8,3,0,2.8C49.9,85.3,49.9,82.2,49.1,82.3z"/>
		<path class="st1" d="M48.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,82.3c-0.8-0.2-0.8,3,0,2.8C47.9,85.3,47.9,82.2,47.1,82.3z"/>
		<path class="st1" d="M46.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,82.3c-0.8-0.2-0.8,3,0,2.8C45.9,85.3,45.9,82.2,45.1,82.3z"/>
		<path class="st1" d="M44.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,82.3c-0.8-0.2-0.8,3,0,2.8C43.9,85.3,43.9,82.2,43.1,82.3z"/>
		<path class="st1" d="M42.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,82.3c-0.8-0.2-0.8,3,0,2.8C42,85.3,42,82.2,41.1,82.3z"/>
		<path class="st1" d="M40.8,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,82.3c-0.8-0.2-0.8,3,0,2.8C40,85.3,40,82.2,39.1,82.3z"/>
		<path class="st1" d="M38.9,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,82.3c-0.8-0.2-0.8,3,0,2.8C38,85.3,38,82.2,37.2,82.3z"/>
		<path class="st1" d="M36.9,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,82.3c-0.8-0.2-0.8,3,0,2.8C36,85.3,36,82.2,35.2,82.3z"/>
		<path class="st1" d="M34.9,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,82.3c-0.8-0.2-0.8,3,0,2.8C34.1,85.3,34.1,82.2,33.3,82.3z"/>
		<path class="st1" d="M33,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,82.3c-0.8-0.2-0.8,3,0,2.8C32.1,85.3,32.1,82.2,31.3,82.3z"/>
		<path class="st1" d="M31,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,82.3c-0.8-0.2-0.8,3,0,2.8C30.1,85.3,30.2,82.2,29.3,82.3z"/>
		<path class="st1" d="M29,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,82.3c-0.8-0.2-0.8,3,0,2.8C28.2,85.3,28.2,82.2,27.3,82.3z"/>
		<path class="st1" d="M27,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,82.3c-0.8-0.2-0.8,3,0,2.8C26.2,85.3,26.2,82.2,25.3,82.3z"/>
		<path class="st1" d="M25.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,82.3c-0.8-0.2-0.8,3,0,2.8C24.2,85.3,24.2,82.2,23.4,82.3z"/>
		<path class="st1" d="M23.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,82.3c-0.8-0.2-0.8,3,0,2.8C22.2,85.3,22.2,82.2,21.4,82.3z"/>
		<path class="st1" d="M21.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,82.3c-0.8-0.2-0.8,3,0,2.8C20.2,85.3,20.2,82.2,19.4,82.3z"/>
		<path class="st1" d="M19.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,82.3c-0.8-0.2-0.8,3,0,2.8C18.2,85.3,18.2,82.2,17.4,82.3z"/>
		<path class="st1" d="M17.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,82.3c-0.8-0.2-0.8,3,0,2.8C16.2,85.3,16.2,82.2,15.4,82.3z"/>
		<path class="st1" d="M15.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,82.3c-0.8-0.2-0.8,3,0,2.8C14.3,85.3,14.3,82.2,13.4,82.3z"/>
		<path class="st1" d="M13.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,82.3c-0.8-0.2-0.8,3,0,2.8C12.3,85.3,12.3,82.2,11.4,82.3z"/>
		<path class="st1" d="M11.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,82.3c-0.8-0.2-0.8,3,0,2.8C10.3,85.3,10.3,82.2,9.5,82.3z"/>
		<path class="st1" d="M9.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,82.3c-0.8-0.2-0.8,3,0,2.8C8.3,85.3,8.3,82.2,7.5,82.3z"/>
		<path class="st1" d="M7.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,82.3c-0.8-0.2-0.8,3,0,2.8C6.3,85.3,6.3,82.2,5.5,82.3z"/>
		<path class="st1" d="M5.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,82.3c-0.8-0.2-0.8,3,0,2.8C4.3,85.3,4.3,82.2,3.5,82.3z"/>
		<path class="st1" d="M3.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,82.3c-0.8-0.2-0.8,3,0,2.8C129.2,85.3,129.2,82.2,128.4,82.3z"/>
		<path class="st1" d="M128.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,82.3c-0.8-0.2-0.8,3,0,2.8C127.2,85.3,127.2,82.2,126.4,82.3z"/>
		<path class="st1" d="M126.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,82.3c-0.8-0.2-0.8,3,0,2.8C125.3,85.3,125.3,82.2,124.4,82.3z"/>
		<path class="st1" d="M124.1,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,82.3c-0.8-0.2-0.8,3,0,2.8C123.3,85.3,123.3,82.2,122.4,82.3z"/>
		<path class="st1" d="M122.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,82.3c-0.8-0.2-0.8,3,0,2.8C121.3,85.3,121.3,82.2,120.5,82.3z"/>
		<path class="st1" d="M120.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,82.3c-0.8-0.2-0.8,3,0,2.8C119.3,85.3,119.3,82.2,118.5,82.3z"/>
		<path class="st1" d="M118.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,82.3c-0.8-0.2-0.8,3,0,2.8C117.3,85.3,117.3,82.2,116.5,82.3z"/>
		<path class="st1" d="M116.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,82.3c-0.8-0.2-0.8,3,0,2.8C115.3,85.3,115.3,82.2,114.5,82.3z"/>
		<path class="st1" d="M114.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,82.3c-0.8-0.2-0.8,3,0,2.8C113.3,85.3,113.3,82.2,112.5,82.3z"/>
		<path class="st1" d="M112.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,82.3c-0.8-0.2-0.8,3,0,2.8C111.3,85.3,111.3,82.2,110.5,82.3z"/>
		<path class="st1" d="M110.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,82.3c-0.8-0.2-0.8,3,0,2.8C109.4,85.3,109.4,82.2,108.5,82.3z"/>
		<path class="st1" d="M108.2,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,82.3c-0.8-0.2-0.8,3,0,2.8C107.4,85.3,107.4,82.2,106.5,82.3z"/>
		<path class="st1" d="M106.3,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,82.3c-0.8-0.2-0.8,3,0,2.8C105.4,85.3,105.4,82.2,104.6,82.3z"/>
		<path class="st1" d="M104.3,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,82.3c-0.8-0.2-0.8,3,0,2.8C103.4,85.3,103.4,82.2,102.6,82.3z"/>
		<path class="st1" d="M102.3,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,82.3c-0.8-0.2-0.8,3,0,2.8C101.4,85.3,101.4,82.2,100.6,82.3z"/>
		<path class="st1" d="M100.3,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,82.3c-0.8-0.2-0.8,3,0,2.8C99.4,85.3,99.4,82.2,98.6,82.3z"/>
		<path class="st1" d="M98.3,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,82.3c-0.8-0.2-0.8,3,0,2.8C97.5,85.3,97.6,82.2,96.7,82.3z"/>
		<path class="st1" d="M96.4,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,82.3c-0.8-0.2-0.8,3,0,2.8C95.6,85.3,95.6,82.2,94.7,82.3z"/>
		<path class="st1" d="M94.4,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,82.3c-0.8-0.2-0.8,3,0,2.8C93.6,85.3,93.6,82.2,92.7,82.3z"/>
		<path class="st1" d="M92.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,82.3c-0.8-0.2-0.8,3,0,2.8C91.6,85.3,91.6,82.2,90.8,82.3z"/>
		<path class="st1" d="M90.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,82.3c-0.8-0.2-0.8,3,0,2.8C89.6,85.3,89.6,82.2,88.8,82.3z"/>
		<path class="st1" d="M88.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,82.3c-0.8-0.2-0.8,3,0,2.8C87.6,85.3,87.6,82.2,86.8,82.3z"/>
		<path class="st1" d="M86.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,82.3c-0.8-0.2-0.8,3,0,2.8C85.6,85.3,85.6,82.2,84.8,82.3z"/>
		<path class="st1" d="M84.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,82.3c-0.8-0.2-0.8,3,0,2.8C83.6,85.3,83.6,82.2,82.8,82.3z"/>
		<path class="st1" d="M82.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,82.3c-0.8-0.2-0.8,3,0,2.8C81.7,85.3,81.7,82.2,80.8,82.3z"/>
		<path class="st1" d="M80.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,82.3c-0.8-0.2-0.8,3,0,2.8C79.7,85.3,79.7,82.2,78.8,82.3z"/>
		<path class="st1" d="M78.5,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,82.3c-0.8-0.2-0.8,3,0,2.8C77.7,85.3,77.7,82.2,76.9,82.3z"/>
		<path class="st1" d="M76.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,82.3c-0.8-0.2-0.8,3,0,2.8C75.7,85.3,75.7,82.2,74.9,82.3z"/>
		<path class="st1" d="M74.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,82.3c-0.8-0.2-0.8,3,0,2.8C73.7,85.3,73.7,82.2,72.9,82.3z"/>
		<path class="st1" d="M72.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,82.3c-0.8-0.2-0.8,3,0,2.8C71.7,85.3,71.7,82.2,70.9,82.3z"/>
		<path class="st1" d="M70.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,82.3c-0.8-0.2-0.8,3,0,2.8C69.7,85.3,69.7,82.2,68.9,82.3z"/>
		<path class="st1" d="M68.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,82.3c-0.8-0.2-0.8,3,0,2.8C67.7,85.3,67.7,82.2,66.9,82.3z"/>
		<path class="st1" d="M66.6,83.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,83.1c-0.8-0.2-0.8,3,0,2.8C66.7,86.1,66.7,82.9,65.9,83.1z"/>
		<path class="st1" d="M65.6,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,83.1c-0.8-0.2-0.8,3,0,2.8C64.7,86.1,64.7,82.9,63.9,83.1z"/>
		<path class="st1" d="M63.6,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,83.1c-0.8-0.2-0.8,3,0,2.8C62.7,86.1,62.8,82.9,61.9,83.1z"/>
		<path class="st1" d="M61.6,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,83.1c-0.8-0.2-0.8,3,0,2.8C60.8,86.1,60.8,82.9,59.9,83.1z"/>
		<path class="st1" d="M59.6,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,83.1c-0.8-0.2-0.8,3,0,2.8C58.8,86.1,58.8,82.9,57.9,83.1z"/>
		<path class="st1" d="M57.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,83.1c-0.8-0.2-0.8,3,0,2.8C56.8,86.1,56.8,82.9,56,83.1z"/>
		<path class="st1" d="M55.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,83.1c-0.8-0.2-0.8,3,0,2.8C54.8,86.1,54.8,82.9,54,83.1z"/>
		<path class="st1" d="M53.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,83.1c-0.8-0.2-0.8,3,0,2.8C52.8,86.1,52.8,82.9,52,83.1z"/>
		<path class="st1" d="M51.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,83.1c-0.8-0.2-0.8,3,0,2.8C50.8,86.1,50.8,82.9,50,83.1z"/>
		<path class="st1" d="M49.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,83.1c-0.8-0.2-0.8,3,0,2.8C48.8,86.1,48.8,82.9,48,83.1z"/>
		<path class="st1" d="M47.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,83.1c-0.8-0.2-0.8,3,0,2.8C46.9,86.1,46.9,82.9,46,83.1z"/>
		<path class="st1" d="M45.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,83.1c-0.8-0.2-0.8,3,0,2.8C44.9,86.1,44.9,82.9,44,83.1z"/>
		<path class="st1" d="M43.7,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,83.1c-0.8-0.2-0.8,3,0,2.8C42.9,86.1,42.9,82.9,42.1,83.1z"/>
		<path class="st1" d="M41.8,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,83.1c-0.8-0.2-0.8,3,0,2.8C40.9,86.1,40.9,82.9,40.1,83.1z"/>
		<path class="st1" d="M39.8,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,83.1c-0.8-0.2-0.8,3,0,2.8C38.9,86.1,38.9,82.9,38.1,83.1z"/>
		<path class="st1" d="M37.8,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,83.1c-0.8-0.2-0.8,3,0,2.8C36.9,86.1,36.9,82.9,36.1,83.1z"/>
		<path class="st1" d="M35.8,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,83.1c-0.8-0.2-0.8,3,0,2.8C35,86.1,35,82.9,34.2,83.1z"/>
		<path class="st1" d="M33.9,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,83.1c-0.8-0.2-0.8,3,0,2.8C33.1,86.1,33.1,82.9,32.2,83.1z"/>
		<path class="st1" d="M31.9,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,83.1c-0.8-0.2-0.8,3,0,2.8C31.1,86.1,31.1,82.9,30.2,83.1z"/>
		<path class="st1" d="M30,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,83.1c-0.8-0.2-0.8,3,0,2.8C29.1,86.1,29.1,82.9,28.3,83.1z"/>
		<path class="st1" d="M28,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,83.1c-0.8-0.2-0.8,3,0,2.8C27.1,86.1,27.1,82.9,26.3,83.1z"/>
		<path class="st1" d="M26,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,83.1c-0.8-0.2-0.8,3,0,2.8C25.1,86.1,25.1,82.9,24.3,83.1z"/>
		<path class="st1" d="M24,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,83.1c-0.8-0.2-0.8,3,0,2.8C23.1,86.1,23.1,82.9,22.3,83.1z"/>
		<path class="st1" d="M22,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,83.1c-0.8-0.2-0.8,3,0,2.8C21.1,86.1,21.1,82.9,20.3,83.1z"/>
		<path class="st1" d="M20,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,83.1c-0.8-0.2-0.8,3,0,2.8C19.1,86.1,19.1,82.9,18.3,83.1z"/>
		<path class="st1" d="M18,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,83.1c-0.8-0.2-0.8,3,0,2.8C17.2,86.1,17.2,82.9,16.3,83.1z"/>
		<path class="st1" d="M16,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,83.1c-0.8-0.2-0.8,3,0,2.8C15.2,86.1,15.2,82.9,14.3,83.1z"/>
		<path class="st1" d="M14.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,83.1c-0.8-0.2-0.8,3,0,2.8C13.2,86.1,13.2,82.9,12.4,83.1z"/>
		<path class="st1" d="M12.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,83.1c-0.8-0.2-0.8,3,0,2.8C11.2,86.1,11.2,82.9,10.4,83.1z"/>
		<path class="st1" d="M10.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,83.1c-0.8-0.2-0.8,3,0,2.8C9.2,86.1,9.2,82.9,8.4,83.1z"/>
		<path class="st1" d="M8.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,83.1c-0.8-0.2-0.8,3,0,2.8C7.2,86.1,7.2,82.9,6.4,83.1z"/>
		<path class="st1" d="M6.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,83.1c-0.8-0.2-0.8,3,0,2.8C5.2,86.1,5.2,82.9,4.4,83.1z"/>
		<path class="st1" d="M4.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,83.1c-0.8-0.2-0.8,3,0,2.8C130.1,86.1,130.1,82.9,129.3,83.1z"/>
		<path class="st1" d="M129,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,83.1c-0.8-0.2-0.8,3,0,2.8C128.2,86.1,128.2,82.9,127.3,83.1z"/>
		<path class="st1" d="M127,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,83.1c-0.8-0.2-0.8,3,0,2.8C126.2,86.1,126.2,82.9,125.3,83.1z"/>
		<path class="st1" d="M125.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,83.1c-0.8-0.2-0.8,3,0,2.8C124.2,86.1,124.2,82.9,123.4,83.1z"/>
		<path class="st1" d="M123.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,83.1c-0.8-0.2-0.8,3,0,2.8C122.2,86.1,122.2,82.9,121.4,83.1z"/>
		<path class="st1" d="M121.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,83.1c-0.8-0.2-0.8,3,0,2.8C120.2,86.1,120.2,82.9,119.4,83.1z"/>
		<path class="st1" d="M119.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,83.1c-0.8-0.2-0.8,3,0,2.8C118.2,86.1,118.2,82.9,117.4,83.1z"/>
		<path class="st1" d="M117.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,83.1c-0.8-0.2-0.8,3,0,2.8C116.2,86.1,116.2,82.9,115.4,83.1z"/>
		<path class="st1" d="M115.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,83.1c-0.8-0.2-0.8,3,0,2.8C114.3,86.1,114.3,82.9,113.4,83.1z"/>
		<path class="st1" d="M113.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,83.1c-0.8-0.2-0.8,3,0,2.8C112.3,86.1,112.3,82.9,111.4,83.1z"/>
		<path class="st1" d="M111.1,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,83.1c-0.8-0.2-0.8,3,0,2.8C110.3,86.1,110.3,82.9,109.4,83.1z"/>
		<path class="st1" d="M109.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,83.1c-0.8-0.2-0.8,3,0,2.8C108.3,86.1,108.3,82.9,107.5,83.1z"/>
		<path class="st1" d="M107.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,83.1c-0.8-0.2-0.8,3,0,2.8C106.3,86.1,106.3,82.9,105.5,83.1z"/>
		<path class="st1" d="M105.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,83.1c-0.8-0.2-0.8,3,0,2.8C104.3,86.1,104.3,82.9,103.5,83.1z"/>
		<path class="st1" d="M103.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,83.1c-0.8-0.2-0.8,3,0,2.8C102.3,86.1,102.3,82.9,101.5,83.1z"/>
		<path class="st1" d="M101.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,83.1c-0.8-0.2-0.8,3,0,2.8C100.3,86.1,100.3,82.9,99.5,83.1z"/>
		<path class="st1" d="M99.2,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,83.1c-0.8-0.2-0.8,3,0,2.8C98.5,86.1,98.5,82.9,97.6,83.1z"/>
		<path class="st1" d="M97.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,83.1c-0.8-0.2-0.8,3,0,2.8C96.5,86.1,96.5,82.9,95.7,83.1z"/>
		<path class="st1" d="M95.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,83.1c-0.8-0.2-0.8,3,0,2.8C94.5,86.1,94.5,82.9,93.7,83.1z"/>
		<path class="st1" d="M93.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,83.1c-0.8-0.2-0.8,3,0,2.8C92.5,86.1,92.5,82.9,91.7,83.1z"/>
		<path class="st1" d="M91.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,83.1c-0.8-0.2-0.8,3,0,2.8C90.5,86.1,90.5,82.9,89.7,83.1z"/>
		<path class="st1" d="M89.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,83.1c-0.8-0.2-0.8,3,0,2.8C88.5,86.1,88.5,82.9,87.7,83.1z"/>
		<path class="st1" d="M87.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,83.1c-0.8-0.2-0.8,3,0,2.8C86.5,86.1,86.5,82.9,85.7,83.1z"/>
		<path class="st1" d="M85.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,83.1c-0.8-0.2-0.8,3,0,2.8C84.6,86.1,84.6,82.9,83.7,83.1z"/>
		<path class="st1" d="M83.4,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,83.1c-0.8-0.2-0.8,3,0,2.8C82.6,86.1,82.6,82.9,81.7,83.1z"/>
		<path class="st1" d="M81.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,83.1c-0.8-0.2-0.8,3,0,2.8C80.6,86.1,80.6,82.9,79.8,83.1z"/>
		<path class="st1" d="M79.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,83.1c-0.8-0.2-0.8,3,0,2.8C78.6,86.1,78.6,82.9,77.8,83.1z"/>
		<path class="st1" d="M77.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,83.1c-0.8-0.2-0.8,3,0,2.8C76.6,86.1,76.6,82.9,75.8,83.1z"/>
		<path class="st1" d="M75.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,83.1c-0.8-0.2-0.8,3,0,2.8C74.6,86.1,74.6,82.9,73.8,83.1z"/>
		<path class="st1" d="M73.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,83.1c-0.8-0.2-0.8,3,0,2.8C72.6,86.1,72.6,82.9,71.8,83.1z"/>
		<path class="st1" d="M71.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,83.1c-0.8-0.2-0.8,3,0,2.8C70.7,86.1,70.7,82.9,69.8,83.1z"/>
		<path class="st1" d="M69.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,83.1c-0.8-0.2-0.8,3,0,2.8C68.7,86.1,68.7,82.9,67.8,83.1z"/>
		<path class="st1" d="M67.5,84c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,84c-0.8-0.2-0.8,3,0,2.8C65.8,87,65.8,83.8,65,84z"/>
		<path class="st1" d="M64.7,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,84c-0.8-0.2-0.8,3,0,2.8C63.8,87,63.8,83.8,63,84z"/>
		<path class="st1" d="M62.7,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,84c-0.8-0.2-0.8,3,0,2.8C61.8,87,61.8,83.8,61,84z"/>
		<path class="st1" d="M60.7,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,84c-0.8-0.2-0.8,3,0,2.8C59.8,87,59.8,83.8,59,84z"/>
		<path class="st1" d="M58.7,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,84c-0.8-0.2-0.8,3,0,2.8C57.9,87,57.9,83.8,57,84z"/>
		<path class="st1" d="M56.7,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,84c-0.8-0.2-0.8,3,0,2.8C55.9,87,55.9,83.8,55,84z"/>
		<path class="st1" d="M54.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,84c-0.8-0.2-0.8,3,0,2.8C53.9,87,53.9,83.8,53.1,84z"/>
		<path class="st1" d="M52.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,84c-0.8-0.2-0.8,3,0,2.8C51.9,87,51.9,83.8,51.1,84z"/>
		<path class="st1" d="M50.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,84c-0.8-0.2-0.8,3,0,2.8C49.9,87,49.9,83.8,49.1,84z"/>
		<path class="st1" d="M48.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,84c-0.8-0.2-0.8,3,0,2.8C47.9,87,47.9,83.8,47.1,84z"/>
		<path class="st1" d="M46.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,84c-0.8-0.2-0.8,3,0,2.8C45.9,87,45.9,83.8,45.1,84z"/>
		<path class="st1" d="M44.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,84c-0.8-0.2-0.8,3,0,2.8C43.9,87,43.9,83.8,43.1,84z"/>
		<path class="st1" d="M42.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,84c-0.8-0.2-0.8,3,0,2.8C42,87,42,83.8,41.1,84z"/>
		<path class="st1" d="M40.8,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,84c-0.8-0.2-0.8,3,0,2.8C40,87,40,83.8,39.1,84z"/>
		<path class="st1" d="M38.9,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,84c-0.8-0.2-0.8,3,0,2.8C38,87,38,83.8,37.2,84z"/>
		<path class="st1" d="M36.9,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,84c-0.8-0.2-0.8,3,0,2.8C36,87,36,83.8,35.2,84z"/>
		<path class="st1" d="M34.9,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,84c-0.8-0.2-0.8,3,0,2.8C34.1,87,34.1,83.8,33.3,84z"/>
		<path class="st1" d="M33,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,84c-0.8-0.2-0.8,3,0,2.8C32.1,87,32.1,83.8,31.3,84z"/>
		<path class="st1" d="M31,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,84c-0.8-0.2-0.8,3,0,2.8C30.1,87,30.2,83.8,29.3,84z"/>
		<path class="st1" d="M29,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,84c-0.8-0.2-0.8,3,0,2.8C28.2,87,28.2,83.8,27.3,84z"/>
		<path class="st1" d="M27,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,84c-0.8-0.2-0.8,3,0,2.8C26.2,87,26.2,83.8,25.3,84z"/>
		<path class="st1" d="M25.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,84c-0.8-0.2-0.8,3,0,2.8C24.2,87,24.2,83.8,23.4,84z"/>
		<path class="st1" d="M23.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,84c-0.8-0.2-0.8,3,0,2.8C22.2,87,22.2,83.8,21.4,84z"/>
		<path class="st1" d="M21.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,84c-0.8-0.2-0.8,3,0,2.8C20.2,87,20.2,83.8,19.4,84z"/>
		<path class="st1" d="M19.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,84c-0.8-0.2-0.8,3,0,2.8C18.2,87,18.2,83.8,17.4,84z"/>
		<path class="st1" d="M17.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,84c-0.8-0.2-0.8,3,0,2.8C16.2,87,16.2,83.8,15.4,84z"/>
		<path class="st1" d="M15.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,84c-0.8-0.2-0.8,3,0,2.8C14.3,87,14.3,83.8,13.4,84z"/>
		<path class="st1" d="M13.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,84c-0.8-0.2-0.8,3,0,2.8C12.3,87,12.3,83.8,11.4,84z"/>
		<path class="st1" d="M11.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M9.5,84c-0.8-0.2-0.8,3,0,2.8C10.3,87,10.3,83.8,9.5,84z"/>
		<path class="st2" d="M9.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,84c-0.8-0.2-0.8,3,0,2.8C8.3,87,8.3,83.8,7.5,84z"/>
		<path class="st2" d="M7.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,84c-0.8-0.2-0.8,3,0,2.8C6.3,87,6.3,83.8,5.5,84z"/>
		<path class="st2" d="M5.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,84c-0.8-0.2-0.8,3,0,2.8C4.3,87,4.3,83.8,3.5,84z"/>
		<path class="st2" d="M3.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,84c-0.8-0.2-0.8,3,0,2.8C129.2,87,129.2,83.8,128.4,84z"/>
		<path class="st1" d="M128.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,84c-0.8-0.2-0.8,3,0,2.8C127.2,87,127.2,83.8,126.4,84z"/>
		<path class="st1" d="M126.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,84c-0.8-0.2-0.8,3,0,2.8C125.3,87,125.3,83.8,124.4,84z"/>
		<path class="st1" d="M124.1,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,84c-0.8-0.2-0.8,3,0,2.8C123.3,87,123.3,83.8,122.4,84z"/>
		<path class="st1" d="M122.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,84c-0.8-0.2-0.8,3,0,2.8C121.3,87,121.3,83.8,120.5,84z"/>
		<path class="st1" d="M120.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,84c-0.8-0.2-0.8,3,0,2.8C119.3,87,119.3,83.8,118.5,84z"/>
		<path class="st1" d="M118.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,84c-0.8-0.2-0.8,3,0,2.8C117.3,87,117.3,83.8,116.5,84z"/>
		<path class="st1" d="M116.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,84c-0.8-0.2-0.8,3,0,2.8C115.3,87,115.3,83.8,114.5,84z"/>
		<path class="st1" d="M114.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,84c-0.8-0.2-0.8,3,0,2.8C113.3,87,113.3,83.8,112.5,84z"/>
		<path class="st1" d="M112.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,84c-0.8-0.2-0.8,3,0,2.8C111.3,87,111.3,83.8,110.5,84z"/>
		<path class="st1" d="M110.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,84c-0.8-0.2-0.8,3,0,2.8C109.4,87,109.4,83.8,108.5,84z"/>
		<path class="st1" d="M108.2,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,84c-0.8-0.2-0.8,3,0,2.8C107.4,87,107.4,83.8,106.5,84z"/>
		<path class="st1" d="M106.3,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,84c-0.8-0.2-0.8,3,0,2.8C105.4,87,105.4,83.8,104.6,84z"/>
		<path class="st1" d="M104.3,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,84c-0.8-0.2-0.8,3,0,2.8C103.4,87,103.4,83.8,102.6,84z"/>
		<path class="st1" d="M102.3,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,84c-0.8-0.2-0.8,3,0,2.8C101.4,87,101.4,83.8,100.6,84z"/>
		<path class="st1" d="M100.3,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,84c-0.8-0.2-0.8,3,0,2.8C99.4,87,99.4,83.8,98.6,84z"/>
		<path class="st1" d="M98.3,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,84c-0.8-0.2-0.8,3,0,2.8C97.5,87,97.6,83.8,96.7,84z"/>
		<path class="st1" d="M96.4,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,84c-0.8-0.2-0.8,3,0,2.8C95.6,87,95.6,83.8,94.7,84z"/>
		<path class="st1" d="M94.4,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,84c-0.8-0.2-0.8,3,0,2.8C93.6,87,93.6,83.8,92.7,84z"/>
		<path class="st1" d="M92.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,84c-0.8-0.2-0.8,3,0,2.8C91.6,87,91.6,83.8,90.8,84z"/>
		<path class="st1" d="M90.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,84c-0.8-0.2-0.8,3,0,2.8C89.6,87,89.6,83.8,88.8,84z"/>
		<path class="st1" d="M88.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,84c-0.8-0.2-0.8,3,0,2.8C87.6,87,87.6,83.8,86.8,84z"/>
		<path class="st1" d="M86.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,84c-0.8-0.2-0.8,3,0,2.8C85.6,87,85.6,83.8,84.8,84z"/>
		<path class="st1" d="M84.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,84c-0.8-0.2-0.8,3,0,2.8C83.6,87,83.6,83.8,82.8,84z"/>
		<path class="st1" d="M82.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,84c-0.8-0.2-0.8,3,0,2.8C81.7,87,81.7,83.8,80.8,84z"/>
		<path class="st1" d="M80.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,84c-0.8-0.2-0.8,3,0,2.8C79.7,87,79.7,83.8,78.8,84z"/>
		<path class="st1" d="M78.5,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,84c-0.8-0.2-0.8,3,0,2.8C77.7,87,77.7,83.8,76.9,84z"/>
		<path class="st1" d="M76.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,84c-0.8-0.2-0.8,3,0,2.8C75.7,87,75.7,83.8,74.9,84z"/>
		<path class="st1" d="M74.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,84c-0.8-0.2-0.8,3,0,2.8C73.7,87,73.7,83.8,72.9,84z"/>
		<path class="st1" d="M72.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,84c-0.8-0.2-0.8,3,0,2.8C71.7,87,71.7,83.8,70.9,84z"/>
		<path class="st1" d="M70.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,84c-0.8-0.2-0.8,3,0,2.8C69.7,87,69.7,83.8,68.9,84z"/>
		<path class="st1" d="M68.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,84c-0.8-0.2-0.8,3,0,2.8C67.7,87,67.7,83.8,66.9,84z"/>
		<path class="st1" d="M66.6,84.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M65,59.5c-0.8-0.2-0.8,3,0,2.8C65.8,62.5,65.8,59.3,65,59.5z"/>
		<path class="st1" d="M64.7,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63,59.5c-0.8-0.2-0.8,3,0,2.8C63.8,62.5,63.8,59.3,63,59.5z"/>
		<path class="st1" d="M62.7,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M61,59.5c-0.8-0.2-0.8,3,0,2.8C61.8,62.5,61.8,59.3,61,59.5z"/>
		<path class="st1" d="M60.7,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,59.5c-0.8-0.2-0.8,3,0,2.8C59.8,62.5,59.8,59.3,59,59.5z"/>
		<path class="st1" d="M58.7,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,59.5c-0.8-0.2-0.8,3,0,2.8C57.9,62.5,57.9,59.3,57,59.5z"/>
		<path class="st1" d="M56.7,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,59.5c-0.8-0.2-0.8,3,0,2.8C55.9,62.5,55.9,59.3,55,59.5z"/>
		<path class="st1" d="M54.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,59.5c-0.8-0.2-0.8,3,0,2.8C53.9,62.5,53.9,59.3,53.1,59.5z"/>
		<path class="st1" d="M52.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,59.5c-0.8-0.2-0.8,3,0,2.8C51.9,62.5,51.9,59.3,51.1,59.5z"/>
		<path class="st1" d="M50.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,59.5c-0.8-0.2-0.8,3,0,2.8C49.9,62.5,49.9,59.3,49.1,59.5z"/>
		<path class="st1" d="M48.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,59.5c-0.8-0.2-0.8,3,0,2.8C47.9,62.5,47.9,59.3,47.1,59.5z"/>
		<path class="st1" d="M46.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,59.5c-0.8-0.2-0.8,3,0,2.8C45.9,62.5,45.9,59.3,45.1,59.5z"/>
		<path class="st1" d="M44.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,59.5c-0.8-0.2-0.8,3,0,2.8C43.9,62.5,43.9,59.3,43.1,59.5z"/>
		<path class="st1" d="M42.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,59.5c-0.8-0.2-0.8,3,0,2.8C42,62.5,42,59.3,41.1,59.5z"/>
		<path class="st1" d="M40.8,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,59.5c-0.8-0.2-0.8,3,0,2.8C40,62.5,40,59.3,39.1,59.5z"/>
		<path class="st1" d="M38.9,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,59.5c-0.8-0.2-0.8,3,0,2.8C38,62.5,38,59.3,37.2,59.5z"/>
		<path class="st1" d="M36.9,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,59.5c-0.8-0.2-0.8,3,0,2.8C36,62.5,36,59.3,35.2,59.5z"/>
		<path class="st1" d="M34.9,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,59.5c-0.8-0.2-0.8,3,0,2.8C34.1,62.5,34.1,59.3,33.3,59.5z"/>
		<path class="st1" d="M33,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,59.5c-0.8-0.2-0.8,3,0,2.8C32.1,62.5,32.1,59.3,31.3,59.5z"/>
		<path class="st1" d="M31,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,59.5c-0.8-0.2-0.8,3,0,2.8C30.1,62.5,30.2,59.3,29.3,59.5z"/>
		<path class="st1" d="M29,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,59.5c-0.8-0.2-0.8,3,0,2.8C28.2,62.5,28.2,59.3,27.3,59.5z"/>
		<path class="st1" d="M27,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,59.5c-0.8-0.2-0.8,3,0,2.8C26.2,62.5,26.2,59.3,25.3,59.5z"/>
		<path class="st1" d="M25.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,59.5c-0.8-0.2-0.8,3,0,2.8C24.2,62.5,24.2,59.3,23.4,59.5z"/>
		<path class="st1" d="M23.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,59.5c-0.8-0.2-0.8,3,0,2.8C22.2,62.5,22.2,59.3,21.4,59.5z"/>
		<path class="st1" d="M21.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,59.5c-0.8-0.2-0.8,3,0,2.8C20.2,62.5,20.2,59.3,19.4,59.5z"/>
		<path class="st1" d="M19.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,59.5c-0.8-0.2-0.8,3,0,2.8C18.2,62.5,18.2,59.3,17.4,59.5z"/>
		<path class="st1" d="M17.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,59.5c-0.8-0.2-0.8,3,0,2.8C16.2,62.5,16.2,59.3,15.4,59.5z"/>
		<path class="st1" d="M15.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,59.5c-0.8-0.2-0.8,3,0,2.8C14.3,62.5,14.3,59.3,13.4,59.5z"/>
		<path class="st1" d="M13.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,59.5c-0.8-0.2-0.8,3,0,2.8C12.3,62.5,12.3,59.3,11.4,59.5z"/>
		<path class="st1" d="M11.1,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,59.5c-0.8-0.2-0.8,3,0,2.8C10.3,62.5,10.3,59.3,9.5,59.5z"/>
		<path class="st1" d="M9.2,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,59.5c-0.8-0.2-0.8,3,0,2.8C8.3,62.5,8.3,59.3,7.5,59.5z"/>
		<path class="st1" d="M7.2,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,59.5c-0.8-0.2-0.8,3,0,2.8C6.3,62.5,6.3,59.3,5.5,59.5z"/>
		<path class="st1" d="M5.2,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,59.5c-0.8-0.2-0.8,3,0,2.8C4.3,62.5,4.3,59.3,3.5,59.5z"/>
		<path class="st1" d="M3.2,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M78.8,59.5c-0.8-0.2-0.8,3,0,2.8C79.7,62.5,79.7,59.3,78.8,59.5z"/>
		<path class="st1" d="M78.5,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M76.9,59.5c-0.8-0.2-0.8,3,0,2.8C77.7,62.5,77.7,59.3,76.9,59.5z"/>
		<path class="st1" d="M76.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M74.9,59.5c-0.8-0.2-0.8,3,0,2.8C75.7,62.5,75.7,59.3,74.9,59.5z"/>
		<path class="st1" d="M74.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M72.9,59.5c-0.8-0.2-0.8,3,0,2.8C73.7,62.5,73.7,59.3,72.9,59.5z"/>
		<path class="st1" d="M72.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M70.9,59.5c-0.8-0.2-0.8,3,0,2.8C71.7,62.5,71.7,59.3,70.9,59.5z"/>
		<path class="st1" d="M70.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M68.9,59.5c-0.8-0.2-0.8,3,0,2.8C69.7,62.5,69.7,59.3,68.9,59.5z"/>
		<path class="st1" d="M68.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M66.9,59.5c-0.8-0.2-0.8,3,0,2.8C67.7,62.5,67.7,59.3,66.9,59.5z"/>
		<path class="st1" d="M66.6,60.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M65.9,60.2c-0.8-0.2-0.8,3,0,2.8C66.7,63.2,66.7,60.1,65.9,60.2z"/>
		<path class="st1" d="M65.6,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63.9,60.2c-0.8-0.2-0.8,3,0,2.8C64.7,63.2,64.7,60.1,63.9,60.2z"/>
		<path class="st1" d="M63.6,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M61.9,60.2c-0.8-0.2-0.8,3,0,2.8C62.7,63.2,62.8,60.1,61.9,60.2z"/>
		<path class="st1" d="M61.6,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M59.9,60.2c-0.8-0.2-0.8,3,0,2.8C60.8,63.2,60.8,60.1,59.9,60.2z"/>
		<path class="st1" d="M59.6,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,60.2c-0.8-0.2-0.8,3,0,2.8C58.8,63.2,58.8,60.1,57.9,60.2z"/>
		<path class="st1" d="M57.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,60.2c-0.8-0.2-0.8,3,0,2.8C56.8,63.2,56.8,60.1,56,60.2z"/>
		<path class="st1" d="M55.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,60.2c-0.8-0.2-0.8,3,0,2.8C54.8,63.2,54.8,60.1,54,60.2z"/>
		<path class="st1" d="M53.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,60.2c-0.8-0.2-0.8,3,0,2.8C52.8,63.2,52.8,60.1,52,60.2z"/>
		<path class="st1" d="M51.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,60.2c-0.8-0.2-0.8,3,0,2.8C50.8,63.2,50.8,60.1,50,60.2z"/>
		<path class="st1" d="M49.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,60.2c-0.8-0.2-0.8,3,0,2.8C48.8,63.2,48.8,60.1,48,60.2z"/>
		<path class="st1" d="M47.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,60.2c-0.8-0.2-0.8,3,0,2.8C46.9,63.2,46.9,60.1,46,60.2z"/>
		<path class="st1" d="M45.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,60.2c-0.8-0.2-0.8,3,0,2.8C44.9,63.2,44.9,60.1,44,60.2z"/>
		<path class="st1" d="M43.7,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,60.2c-0.8-0.2-0.8,3,0,2.8C42.9,63.2,42.9,60.1,42.1,60.2z"/>
		<path class="st1" d="M41.8,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,60.2c-0.8-0.2-0.8,3,0,2.8C40.9,63.2,40.9,60.1,40.1,60.2z"/>
		<path class="st1" d="M39.8,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,60.2c-0.8-0.2-0.8,3,0,2.8C38.9,63.2,38.9,60.1,38.1,60.2z"/>
		<path class="st1" d="M37.8,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,60.2c-0.8-0.2-0.8,3,0,2.8C36.9,63.2,36.9,60.1,36.1,60.2z"/>
		<path class="st1" d="M35.8,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,60.2c-0.8-0.2-0.8,3,0,2.8C35,63.2,35,60.1,34.2,60.2z"/>
		<path class="st1" d="M33.9,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,60.2c-0.8-0.2-0.8,3,0,2.8C33.1,63.2,33.1,60.1,32.2,60.2z"/>
		<path class="st1" d="M31.9,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,60.2c-0.8-0.2-0.8,3,0,2.8C31.1,63.2,31.1,60.1,30.2,60.2z"/>
		<path class="st1" d="M30,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,60.2c-0.8-0.2-0.8,3,0,2.8C29.1,63.2,29.1,60.1,28.3,60.2z"/>
		<path class="st1" d="M28,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,60.2c-0.8-0.2-0.8,3,0,2.8C27.1,63.2,27.1,60.1,26.3,60.2z"/>
		<path class="st1" d="M26,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,60.2c-0.8-0.2-0.8,3,0,2.8C25.1,63.2,25.1,60.1,24.3,60.2z"/>
		<path class="st1" d="M24,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,60.2c-0.8-0.2-0.8,3,0,2.8C23.1,63.2,23.1,60.1,22.3,60.2z"/>
		<path class="st1" d="M22,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,60.2c-0.8-0.2-0.8,3,0,2.8C21.1,63.2,21.1,60.1,20.3,60.2z"/>
		<path class="st1" d="M20,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,60.2c-0.8-0.2-0.8,3,0,2.8C19.1,63.2,19.1,60.1,18.3,60.2z"/>
		<path class="st1" d="M18,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,60.2c-0.8-0.2-0.8,3,0,2.8C17.2,63.2,17.2,60.1,16.3,60.2z"/>
		<path class="st1" d="M16,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,60.2c-0.8-0.2-0.8,3,0,2.8C15.2,63.2,15.2,60.1,14.3,60.2z"/>
		<path class="st1" d="M14.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,60.2c-0.8-0.2-0.8,3,0,2.8C13.2,63.2,13.2,60.1,12.4,60.2z"/>
		<path class="st1" d="M12.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,60.2c-0.8-0.2-0.8,3,0,2.8C11.2,63.2,11.2,60.1,10.4,60.2z"/>
		<path class="st1" d="M10.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,60.2c-0.8-0.2-0.8,3,0,2.8C9.2,63.2,9.2,60.1,8.4,60.2z"/>
		<path class="st1" d="M8.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,60.2c-0.8-0.2-0.8,3,0,2.8C7.2,63.2,7.2,60.1,6.4,60.2z"/>
		<path class="st1" d="M6.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,60.2c-0.8-0.2-0.8,3,0,2.8C5.2,63.2,5.2,60.1,4.4,60.2z"/>
		<path class="st1" d="M4.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,60.2c-0.8-0.2-0.8,3,0,2.8C130.1,63.2,130.1,60.1,129.3,60.2z"/>
		<path class="st1" d="M129,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,60.2c-0.8-0.2-0.8,3,0,2.8C128.2,63.2,128.2,60.1,127.3,60.2z"/>
		<path class="st1" d="M127,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,60.2c-0.8-0.2-0.8,3,0,2.8C126.2,63.2,126.2,60.1,125.3,60.2z"/>
		<path class="st1" d="M125.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,60.2c-0.8-0.2-0.8,3,0,2.8C124.2,63.2,124.2,60.1,123.4,60.2z"/>
		<path class="st1" d="M123.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,60.2c-0.8-0.2-0.8,3,0,2.8C122.2,63.2,122.2,60.1,121.4,60.2z"/>
		<path class="st1" d="M121.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,60.2c-0.8-0.2-0.8,3,0,2.8C120.2,63.2,120.2,60.1,119.4,60.2z"/>
		<path class="st1" d="M119.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,60.2c-0.8-0.2-0.8,3,0,2.8C118.2,63.2,118.2,60.1,117.4,60.2z"/>
		<path class="st1" d="M117.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,60.2c-0.8-0.2-0.8,3,0,2.8C116.2,63.2,116.2,60.1,115.4,60.2z"/>
		<path class="st1" d="M115.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,60.2c-0.8-0.2-0.8,3,0,2.8C114.3,63.2,114.3,60.1,113.4,60.2z"/>
		<path class="st1" d="M113.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,60.2c-0.8-0.2-0.8,3,0,2.8C112.3,63.2,112.3,60.1,111.4,60.2z"/>
		<path class="st1" d="M111.1,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,60.2c-0.8-0.2-0.8,3,0,2.8C110.3,63.2,110.3,60.1,109.4,60.2z"/>
		<path class="st1" d="M109.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,60.2c-0.8-0.2-0.8,3,0,2.8C108.3,63.2,108.3,60.1,107.5,60.2z"/>
		<path class="st1" d="M107.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,60.2c-0.8-0.2-0.8,3,0,2.8C106.3,63.2,106.3,60.1,105.5,60.2z"/>
		<path class="st1" d="M105.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,60.2c-0.8-0.2-0.8,3,0,2.8C104.3,63.2,104.3,60.1,103.5,60.2z"/>
		<path class="st1" d="M103.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,60.2c-0.8-0.2-0.8,3,0,2.8C102.3,63.2,102.3,60.1,101.5,60.2z"/>
		<path class="st1" d="M101.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,60.2c-0.8-0.2-0.8,3,0,2.8C100.3,63.2,100.3,60.1,99.5,60.2z"/>
		<path class="st1" d="M99.2,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,60.2c-0.8-0.2-0.8,3,0,2.8C98.5,63.2,98.5,60.1,97.6,60.2z"/>
		<path class="st1" d="M97.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,60.2c-0.8-0.2-0.8,3,0,2.8C96.5,63.2,96.5,60.1,95.7,60.2z"/>
		<path class="st1" d="M95.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,60.2c-0.8-0.2-0.8,3,0,2.8C94.5,63.2,94.5,60.1,93.7,60.2z"/>
		<path class="st1" d="M93.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,60.2c-0.8-0.2-0.8,3,0,2.8C92.5,63.2,92.5,60.1,91.7,60.2z"/>
		<path class="st1" d="M91.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,60.2c-0.8-0.2-0.8,3,0,2.8C90.5,63.2,90.5,60.1,89.7,60.2z"/>
		<path class="st1" d="M89.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,60.2c-0.8-0.2-0.8,3,0,2.8C88.5,63.2,88.5,60.1,87.7,60.2z"/>
		<path class="st1" d="M87.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,60.2c-0.8-0.2-0.8,3,0,2.8C86.5,63.2,86.5,60.1,85.7,60.2z"/>
		<path class="st1" d="M85.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,60.2c-0.8-0.2-0.8,3,0,2.8C84.6,63.2,84.6,60.1,83.7,60.2z"/>
		<path class="st1" d="M83.4,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,60.2c-0.8-0.2-0.8,3,0,2.8C82.6,63.2,82.6,60.1,81.7,60.2z"/>
		<path class="st1" d="M81.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M79.8,60.2c-0.8-0.2-0.8,3,0,2.8C80.6,63.2,80.6,60.1,79.8,60.2z"/>
		<path class="st1" d="M79.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M77.8,60.2c-0.8-0.2-0.8,3,0,2.8C78.6,63.2,78.6,60.1,77.8,60.2z"/>
		<path class="st1" d="M77.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M75.8,60.2c-0.8-0.2-0.8,3,0,2.8C76.6,63.2,76.6,60.1,75.8,60.2z"/>
		<path class="st1" d="M75.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M73.8,60.2c-0.8-0.2-0.8,3,0,2.8C74.6,63.2,74.6,60.1,73.8,60.2z"/>
		<path class="st1" d="M73.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M71.8,60.2c-0.8-0.2-0.8,3,0,2.8C72.6,63.2,72.6,60.1,71.8,60.2z"/>
		<path class="st1" d="M71.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M69.8,60.2c-0.8-0.2-0.8,3,0,2.8C70.7,63.2,70.7,60.1,69.8,60.2z"/>
		<path class="st1" d="M69.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M67.8,60.2c-0.8-0.2-0.8,3,0,2.8C68.7,63.2,68.7,60.1,67.8,60.2z"/>
		<path class="st1" d="M67.5,61.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M65,61.2c-0.8-0.2-0.8,3,0,2.8C65.8,64.2,65.8,61,65,61.2z"/>
		<path class="st1" d="M64.7,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63,61.2c-0.8-0.2-0.8,3,0,2.8C63.8,64.2,63.8,61,63,61.2z"/>
		<path class="st1" d="M62.7,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M61,61.2c-0.8-0.2-0.8,3,0,2.8C61.8,64.2,61.8,61,61,61.2z"/>
		<path class="st1" d="M60.7,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,61.2c-0.8-0.2-0.8,3,0,2.8C59.8,64.2,59.8,61,59,61.2z"/>
		<path class="st1" d="M58.7,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,61.2c-0.8-0.2-0.8,3,0,2.8C57.9,64.2,57.9,61,57,61.2z"/>
		<path class="st1" d="M56.7,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,61.2c-0.8-0.2-0.8,3,0,2.8C55.9,64.2,55.9,61,55,61.2z"/>
		<path class="st1" d="M54.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,61.2c-0.8-0.2-0.8,3,0,2.8C53.9,64.2,53.9,61,53.1,61.2z"/>
		<path class="st1" d="M52.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,61.2c-0.8-0.2-0.8,3,0,2.8C51.9,64.2,51.9,61,51.1,61.2z"/>
		<path class="st1" d="M50.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,61.2c-0.8-0.2-0.8,3,0,2.8C49.9,64.2,49.9,61,49.1,61.2z"/>
		<path class="st1" d="M48.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,61.2c-0.8-0.2-0.8,3,0,2.8C47.9,64.2,47.9,61,47.1,61.2z"/>
		<path class="st1" d="M46.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,61.2c-0.8-0.2-0.8,3,0,2.8C45.9,64.2,45.9,61,45.1,61.2z"/>
		<path class="st1" d="M44.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,61.2c-0.8-0.2-0.8,3,0,2.8C43.9,64.2,43.9,61,43.1,61.2z"/>
		<path class="st1" d="M42.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,61.2c-0.8-0.2-0.8,3,0,2.8C42,64.2,42,61,41.1,61.2z"/>
		<path class="st1" d="M40.8,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,61.2c-0.8-0.2-0.8,3,0,2.8C40,64.2,40,61,39.1,61.2z"/>
		<path class="st1" d="M38.9,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,61.2c-0.8-0.2-0.8,3,0,2.8C38,64.2,38,61,37.2,61.2z"/>
		<path class="st1" d="M36.9,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,61.2c-0.8-0.2-0.8,3,0,2.8C36,64.2,36,61,35.2,61.2z"/>
		<path class="st1" d="M34.9,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,61.2c-0.8-0.2-0.8,3,0,2.8C34.1,64.2,34.1,61,33.3,61.2z"/>
		<path class="st1" d="M33,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,61.2c-0.8-0.2-0.8,3,0,2.8C32.1,64.2,32.1,61,31.3,61.2z"/>
		<path class="st1" d="M31,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,61.2c-0.8-0.2-0.8,3,0,2.8C30.1,64.2,30.2,61,29.3,61.2z"/>
		<path class="st1" d="M29,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,61.2c-0.8-0.2-0.8,3,0,2.8C28.2,64.2,28.2,61,27.3,61.2z"/>
		<path class="st1" d="M27,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,61.2c-0.8-0.2-0.8,3,0,2.8C26.2,64.2,26.2,61,25.3,61.2z"/>
		<path class="st1" d="M25.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,61.2c-0.8-0.2-0.8,3,0,2.8C24.2,64.2,24.2,61,23.4,61.2z"/>
		<path class="st1" d="M23.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,61.2c-0.8-0.2-0.8,3,0,2.8C22.2,64.2,22.2,61,21.4,61.2z"/>
		<path class="st1" d="M21.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,61.2c-0.8-0.2-0.8,3,0,2.8C20.2,64.2,20.2,61,19.4,61.2z"/>
		<path class="st1" d="M19.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,61.2c-0.8-0.2-0.8,3,0,2.8C18.2,64.2,18.2,61,17.4,61.2z"/>
		<path class="st1" d="M17.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,61.2c-0.8-0.2-0.8,3,0,2.8C16.2,64.2,16.2,61,15.4,61.2z"/>
		<path class="st1" d="M15.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,61.2c-0.8-0.2-0.8,3,0,2.8C14.3,64.2,14.3,61,13.4,61.2z"/>
		<path class="st1" d="M13.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,61.2c-0.8-0.2-0.8,3,0,2.8C12.3,64.2,12.3,61,11.4,61.2z"/>
		<path class="st1" d="M11.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,61.2c-0.8-0.2-0.8,3,0,2.8C10.3,64.2,10.3,61,9.5,61.2z"/>
		<path class="st1" d="M9.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,61.2c-0.8-0.2-0.8,3,0,2.8C8.3,64.2,8.3,61,7.5,61.2z"/>
		<path class="st1" d="M7.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,61.2c-0.8-0.2-0.8,3,0,2.8C6.3,64.2,6.3,61,5.5,61.2z"/>
		<path class="st1" d="M5.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,61.2c-0.8-0.2-0.8,3,0,2.8C4.3,64.2,4.3,61,3.5,61.2z"/>
		<path class="st1" d="M3.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,61.2c-0.8-0.2-0.8,3,0,2.8C129.2,64.2,129.2,61,128.4,61.2z"/>
		<path class="st1" d="M128.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,61.2c-0.8-0.2-0.8,3,0,2.8C127.2,64.2,127.2,61,126.4,61.2z"/>
		<path class="st1" d="M126.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,61.2c-0.8-0.2-0.8,3,0,2.8C125.3,64.2,125.3,61,124.4,61.2z"/>
		<path class="st1" d="M124.1,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,61.2c-0.8-0.2-0.8,3,0,2.8C123.3,64.2,123.3,61,122.4,61.2z"/>
		<path class="st1" d="M122.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,61.2c-0.8-0.2-0.8,3,0,2.8C121.3,64.2,121.3,61,120.5,61.2z"/>
		<path class="st1" d="M120.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,61.2c-0.8-0.2-0.8,3,0,2.8C119.3,64.2,119.3,61,118.5,61.2z"/>
		<path class="st1" d="M118.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,61.2c-0.8-0.2-0.8,3,0,2.8C117.3,64.2,117.3,61,116.5,61.2z"/>
		<path class="st1" d="M116.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,61.2c-0.8-0.2-0.8,3,0,2.8C115.3,64.2,115.3,61,114.5,61.2z"/>
		<path class="st1" d="M114.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,61.2c-0.8-0.2-0.8,3,0,2.8C113.3,64.2,113.3,61,112.5,61.2z"/>
		<path class="st1" d="M112.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,61.2c-0.8-0.2-0.8,3,0,2.8C111.3,64.2,111.3,61,110.5,61.2z"/>
		<path class="st1" d="M110.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,61.2c-0.8-0.2-0.8,3,0,2.8C109.4,64.2,109.4,61,108.5,61.2z"/>
		<path class="st1" d="M108.2,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,61.2c-0.8-0.2-0.8,3,0,2.8C107.4,64.2,107.4,61,106.5,61.2z"/>
		<path class="st1" d="M106.3,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,61.2c-0.8-0.2-0.8,3,0,2.8C105.4,64.2,105.4,61,104.6,61.2z"/>
		<path class="st1" d="M104.3,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,61.2c-0.8-0.2-0.8,3,0,2.8C103.4,64.2,103.4,61,102.6,61.2z"/>
		<path class="st1" d="M102.3,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,61.2c-0.8-0.2-0.8,3,0,2.8C101.4,64.2,101.4,61,100.6,61.2z"/>
		<path class="st1" d="M100.3,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,61.2c-0.8-0.2-0.8,3,0,2.8C99.4,64.2,99.4,61,98.6,61.2z"/>
		<path class="st1" d="M98.3,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,61.2c-0.8-0.2-0.8,3,0,2.8C97.5,64.2,97.6,61,96.7,61.2z"/>
		<path class="st1" d="M96.4,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,61.2c-0.8-0.2-0.8,3,0,2.8C95.6,64.2,95.6,61,94.7,61.2z"/>
		<path class="st1" d="M94.4,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,61.2c-0.8-0.2-0.8,3,0,2.8C93.6,64.2,93.6,61,92.7,61.2z"/>
		<path class="st1" d="M92.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,61.2c-0.8-0.2-0.8,3,0,2.8C91.6,64.2,91.6,61,90.8,61.2z"/>
		<path class="st1" d="M90.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,61.2c-0.8-0.2-0.8,3,0,2.8C89.6,64.2,89.6,61,88.8,61.2z"/>
		<path class="st1" d="M88.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,61.2c-0.8-0.2-0.8,3,0,2.8C87.6,64.2,87.6,61,86.8,61.2z"/>
		<path class="st1" d="M86.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,61.2c-0.8-0.2-0.8,3,0,2.8C85.6,64.2,85.6,61,84.8,61.2z"/>
		<path class="st1" d="M84.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,61.2c-0.8-0.2-0.8,3,0,2.8C83.6,64.2,83.6,61,82.8,61.2z"/>
		<path class="st1" d="M82.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M80.8,61.2c-0.8-0.2-0.8,3,0,2.8C81.7,64.2,81.7,61,80.8,61.2z"/>
		<path class="st1" d="M80.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M78.8,61.2c-0.8-0.2-0.8,3,0,2.8C79.7,64.2,79.7,61,78.8,61.2z"/>
		<path class="st1" d="M78.5,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M76.9,61.2c-0.8-0.2-0.8,3,0,2.8C77.7,64.2,77.7,61,76.9,61.2z"/>
		<path class="st1" d="M76.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M74.9,61.2c-0.8-0.2-0.8,3,0,2.8C75.7,64.2,75.7,61,74.9,61.2z"/>
		<path class="st1" d="M74.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M72.9,61.2c-0.8-0.2-0.8,3,0,2.8C73.7,64.2,73.7,61,72.9,61.2z"/>
		<path class="st1" d="M72.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M70.9,61.2c-0.8-0.2-0.8,3,0,2.8C71.7,64.2,71.7,61,70.9,61.2z"/>
		<path class="st1" d="M70.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M68.9,61.2c-0.8-0.2-0.8,3,0,2.8C69.7,64.2,69.7,61,68.9,61.2z"/>
		<path class="st1" d="M68.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M66.9,61.2c-0.8-0.2-0.8,3,0,2.8C67.7,64.2,67.7,61,66.9,61.2z"/>
		<path class="st1" d="M66.6,62c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M65.9,61.9c-0.8-0.2-0.8,3,0,2.8C66.7,64.9,66.7,61.8,65.9,61.9z"/>
		<path class="st1" d="M65.6,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63.9,61.9c-0.8-0.2-0.8,3,0,2.8C64.7,64.9,64.7,61.8,63.9,61.9z"/>
		<path class="st1" d="M63.6,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M61.9,61.9c-0.8-0.2-0.8,3,0,2.8C62.7,64.9,62.8,61.8,61.9,61.9z"/>
		<path class="st1" d="M61.6,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M59.9,61.9c-0.8-0.2-0.8,3,0,2.8C60.8,64.9,60.8,61.8,59.9,61.9z"/>
		<path class="st1" d="M59.6,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,61.9c-0.8-0.2-0.8,3,0,2.8C58.8,64.9,58.8,61.8,57.9,61.9z"/>
		<path class="st1" d="M57.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,61.9c-0.8-0.2-0.8,3,0,2.8C56.8,64.9,56.8,61.8,56,61.9z"/>
		<path class="st1" d="M55.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,61.9c-0.8-0.2-0.8,3,0,2.8C54.8,64.9,54.8,61.8,54,61.9z"/>
		<path class="st1" d="M53.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,61.9c-0.8-0.2-0.8,3,0,2.8C52.8,64.9,52.8,61.8,52,61.9z"/>
		<path class="st1" d="M51.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,61.9c-0.8-0.2-0.8,3,0,2.8C50.8,64.9,50.8,61.8,50,61.9z"/>
		<path class="st1" d="M49.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,61.9c-0.8-0.2-0.8,3,0,2.8C48.8,64.9,48.8,61.8,48,61.9z"/>
		<path class="st1" d="M47.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,61.9c-0.8-0.2-0.8,3,0,2.8C46.9,64.9,46.9,61.8,46,61.9z"/>
		<path class="st1" d="M45.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,61.9c-0.8-0.2-0.8,3,0,2.8C44.9,64.9,44.9,61.8,44,61.9z"/>
		<path class="st1" d="M43.7,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,61.9c-0.8-0.2-0.8,3,0,2.8C42.9,64.9,42.9,61.8,42.1,61.9z"/>
		<path class="st1" d="M41.8,62.8C40.9,62.5,41,61.1,42,61c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,61.9c-0.8-0.2-0.8,3,0,2.8C40.9,64.9,40.9,61.8,40.1,61.9z"/>
		<path class="st1" d="M39.8,62.8c-1-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,61.9c-0.8-0.2-0.8,3,0,2.8C38.9,64.9,38.9,61.8,38.1,61.9z"/>
		<path class="st1" d="M37.8,62.8C36.9,62.5,37,61.1,38,61c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,61.9c-0.8-0.2-0.8,3,0,2.8C36.9,64.9,36.9,61.8,36.1,61.9z"/>
		<path class="st1" d="M35.8,62.8C34.9,62.5,35,61.1,36,61c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,61.9c-0.8-0.2-0.8,3,0,2.8C35,64.9,35,61.8,34.2,61.9z"/>
		<path class="st1" d="M33.9,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,61.9c-0.8-0.2-0.8,3,0,2.8C33.1,64.9,33.1,61.8,32.2,61.9z"/>
		<path class="st1" d="M31.9,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,61.9c-0.8-0.2-0.8,3,0,2.8C31.1,64.9,31.1,61.8,30.2,61.9z"/>
		<path class="st1" d="M30,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,61.9c-0.8-0.2-0.8,3,0,2.8C29.1,64.9,29.1,61.8,28.3,61.9z"/>
		<path class="st1" d="M28,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,61.9c-0.8-0.2-0.8,3,0,2.8C27.1,64.9,27.1,61.8,26.3,61.9z"/>
		<path class="st1" d="M26,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,61.9c-0.8-0.2-0.8,3,0,2.8C25.1,64.9,25.1,61.8,24.3,61.9z"/>
		<path class="st1" d="M24,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,61.9c-0.8-0.2-0.8,3,0,2.8C23.1,64.9,23.1,61.8,22.3,61.9z"/>
		<path class="st1" d="M22,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,61.9c-0.8-0.2-0.8,3,0,2.8C21.1,64.9,21.1,61.8,20.3,61.9z"/>
		<path class="st1" d="M20,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,61.9c-0.8-0.2-0.8,3,0,2.8C19.1,64.9,19.1,61.8,18.3,61.9z"/>
		<path class="st1" d="M18,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,61.9c-0.8-0.2-0.8,3,0,2.8C17.2,64.9,17.2,61.8,16.3,61.9z"/>
		<path class="st1" d="M16,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,61.9c-0.8-0.2-0.8,3,0,2.8C15.2,64.9,15.2,61.8,14.3,61.9z"/>
		<path class="st1" d="M14.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,61.9c-0.8-0.2-0.8,3,0,2.8C13.2,64.9,13.2,61.8,12.4,61.9z"/>
		<path class="st1" d="M12.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,61.9c-0.8-0.2-0.8,3,0,2.8C11.2,64.9,11.2,61.8,10.4,61.9z"/>
		<path class="st1" d="M10.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,61.9c-0.8-0.2-0.8,3,0,2.8C9.2,64.9,9.2,61.8,8.4,61.9z"/>
		<path class="st1" d="M8.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,61.9c-0.8-0.2-0.8,3,0,2.8C7.2,64.9,7.2,61.8,6.4,61.9z"/>
		<path class="st1" d="M6.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,61.9c-0.8-0.2-0.8,3,0,2.8C5.2,64.9,5.2,61.8,4.4,61.9z"/>
		<path class="st1" d="M4.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,61.9c-0.8-0.2-0.8,3,0,2.8C130.1,64.9,130.1,61.8,129.3,61.9z"/>
		<path class="st1" d="M129,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,61.9c-0.8-0.2-0.8,3,0,2.8C128.2,64.9,128.2,61.8,127.3,61.9z"/>
		<path class="st1" d="M127,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,61.9c-0.8-0.2-0.8,3,0,2.8C126.2,64.9,126.2,61.8,125.3,61.9z"/>
		<path class="st1" d="M125.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,61.9c-0.8-0.2-0.8,3,0,2.8C124.2,64.9,124.2,61.8,123.4,61.9z"/>
		<path class="st1" d="M123.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,61.9c-0.8-0.2-0.8,3,0,2.8C122.2,64.9,122.2,61.8,121.4,61.9z"/>
		<path class="st1" d="M121.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,61.9c-0.8-0.2-0.8,3,0,2.8C120.2,64.9,120.2,61.8,119.4,61.9z"/>
		<path class="st1" d="M119.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,61.9c-0.8-0.2-0.8,3,0,2.8C118.2,64.9,118.2,61.8,117.4,61.9z"/>
		<path class="st1" d="M117.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,61.9c-0.8-0.2-0.8,3,0,2.8C116.2,64.9,116.2,61.8,115.4,61.9z"/>
		<path class="st1" d="M115.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,61.9c-0.8-0.2-0.8,3,0,2.8C114.3,64.9,114.3,61.8,113.4,61.9z"/>
		<path class="st1" d="M113.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,61.9c-0.8-0.2-0.8,3,0,2.8C112.3,64.9,112.3,61.8,111.4,61.9z"/>
		<path class="st1" d="M111.1,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,61.9c-0.8-0.2-0.8,3,0,2.8C110.3,64.9,110.3,61.8,109.4,61.9z"/>
		<path class="st1" d="M109.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,61.9c-0.8-0.2-0.8,3,0,2.8C108.3,64.9,108.3,61.8,107.5,61.9z"/>
		<path class="st1" d="M107.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,61.9c-0.8-0.2-0.8,3,0,2.8C106.3,64.9,106.3,61.8,105.5,61.9z"/>
		<path class="st1" d="M105.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,61.9c-0.8-0.2-0.8,3,0,2.8C104.3,64.9,104.3,61.8,103.5,61.9z"/>
		<path class="st1" d="M103.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,61.9c-0.8-0.2-0.8,3,0,2.8C102.3,64.9,102.3,61.8,101.5,61.9z"/>
		<path class="st1" d="M101.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,61.9c-0.8-0.2-0.8,3,0,2.8C100.3,64.9,100.3,61.8,99.5,61.9z"/>
		<path class="st1" d="M99.2,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,61.9c-0.8-0.2-0.8,3,0,2.8C98.5,64.9,98.5,61.8,97.6,61.9z"/>
		<path class="st1" d="M97.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,61.9c-0.8-0.2-0.8,3,0,2.8C96.5,64.9,96.5,61.8,95.7,61.9z"/>
		<path class="st1" d="M95.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,61.9c-0.8-0.2-0.8,3,0,2.8C94.5,64.9,94.5,61.8,93.7,61.9z"/>
		<path class="st1" d="M93.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,61.9c-0.8-0.2-0.8,3,0,2.8C92.5,64.9,92.5,61.8,91.7,61.9z"/>
		<path class="st1" d="M91.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,61.9c-0.8-0.2-0.8,3,0,2.8C90.5,64.9,90.5,61.8,89.7,61.9z"/>
		<path class="st1" d="M89.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,61.9c-0.8-0.2-0.8,3,0,2.8C88.5,64.9,88.5,61.8,87.7,61.9z"/>
		<path class="st1" d="M87.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,61.9c-0.8-0.2-0.8,3,0,2.8C86.5,64.9,86.5,61.8,85.7,61.9z"/>
		<path class="st1" d="M85.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,61.9c-0.8-0.2-0.8,3,0,2.8C84.6,64.9,84.6,61.8,83.7,61.9z"/>
		<path class="st1" d="M83.4,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,61.9c-0.8-0.2-0.8,3,0,2.8C82.6,64.9,82.6,61.8,81.7,61.9z"/>
		<path class="st1" d="M81.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M79.8,61.9c-0.8-0.2-0.8,3,0,2.8C80.6,64.9,80.6,61.8,79.8,61.9z"/>
		<path class="st1" d="M79.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M77.8,61.9c-0.8-0.2-0.8,3,0,2.8C78.6,64.9,78.6,61.8,77.8,61.9z"/>
		<path class="st1" d="M77.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M75.8,61.9c-0.8-0.2-0.8,3,0,2.8C76.6,64.9,76.6,61.8,75.8,61.9z"/>
		<path class="st1" d="M75.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M73.8,61.9c-0.8-0.2-0.8,3,0,2.8C74.6,64.9,74.6,61.8,73.8,61.9z"/>
		<path class="st1" d="M73.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M71.8,61.9c-0.8-0.2-0.8,3,0,2.8C72.6,64.9,72.6,61.8,71.8,61.9z"/>
		<path class="st1" d="M71.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M69.8,61.9c-0.8-0.2-0.8,3,0,2.8C70.7,64.9,70.7,61.8,69.8,61.9z"/>
		<path class="st1" d="M69.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M67.8,61.9c-0.8-0.2-0.8,3,0,2.8C68.7,64.9,68.7,61.8,67.8,61.9z"/>
		<path class="st1" d="M67.5,62.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M65,62.8c-0.8-0.2-0.8,3,0,2.8C65.8,65.8,65.8,62.7,65,62.8z"/>
		<path class="st1" d="M64.7,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M63,62.8c-0.8-0.2-0.8,3,0,2.8C63.8,65.8,63.8,62.7,63,62.8z"/>
		<path class="st1" d="M62.7,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M61,62.8c-0.8-0.2-0.8,3,0,2.8C61.8,65.8,61.8,62.7,61,62.8z"/>
		<path class="st1" d="M60.7,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,62.8c-0.8-0.2-0.8,3,0,2.8C59.8,65.8,59.8,62.7,59,62.8z"/>
		<path class="st1" d="M58.7,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,62.8c-0.8-0.2-0.8,3,0,2.8C57.9,65.8,57.9,62.7,57,62.8z"/>
		<path class="st1" d="M56.7,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,62.8c-0.8-0.2-0.8,3,0,2.8C55.9,65.8,55.9,62.7,55,62.8z"/>
		<path class="st1" d="M54.8,63.7C53.9,63.4,54,62,55,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,62.8c-0.8-0.2-0.8,3,0,2.8C53.9,65.8,53.9,62.7,53.1,62.8z"/>
		<path class="st1" d="M52.8,63.7C51.9,63.4,52,62,53,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,62.8c-0.8-0.2-0.8,3,0,2.8C51.9,65.8,51.9,62.7,51.1,62.8z"/>
		<path class="st1" d="M50.8,63.7c-1-0.2-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,62.8c-0.8-0.2-0.8,3,0,2.8C49.9,65.8,49.9,62.7,49.1,62.8z"/>
		<path class="st1" d="M48.8,63.7C47.9,63.5,48,62,49,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,62.8c-0.8-0.2-0.8,3,0,2.8C47.9,65.8,47.9,62.7,47.1,62.8z"/>
		<path class="st1" d="M46.8,63.7C45.9,63.5,46,62,47,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,62.8c-0.8-0.2-0.8,3,0,2.8C45.9,65.8,45.9,62.7,45.1,62.8z"/>
		<path class="st1" d="M44.8,63.7C43.9,63.5,44,62,45,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,62.8c-0.8-0.2-0.8,3,0,2.8C43.9,65.8,43.9,62.7,43.1,62.8z"/>
		<path class="st1" d="M42.8,63.7C41.9,63.5,42,62,43,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,62.8c-0.8-0.2-0.8,3,0,2.8C42,65.8,42,62.7,41.1,62.8z"/>
		<path class="st1" d="M40.8,63.7C39.9,63.5,40,62,41,61.9c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,62.8c-0.8-0.2-0.8,3,0,2.8C40,65.8,40,62.7,39.1,62.8z"/>
		<path class="st1" d="M38.9,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,62.8c-0.8-0.2-0.8,3,0,2.8C38,65.8,38,62.7,37.2,62.8z"/>
		<path class="st1" d="M36.9,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,62.8c-0.8-0.2-0.8,3,0,2.8C36,65.8,36,62.7,35.2,62.8z"/>
		<path class="st1" d="M34.9,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,62.8c-0.8-0.2-0.8,3,0,2.8C34.1,65.8,34.1,62.7,33.3,62.8z"/>
		<path class="st1" d="M33,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,62.8c-0.8-0.2-0.8,3,0,2.8C32.1,65.8,32.1,62.7,31.3,62.8z"/>
		<path class="st1" d="M31,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,62.8c-0.8-0.2-0.8,3,0,2.8C30.1,65.8,30.2,62.7,29.3,62.8z"/>
		<path class="st1" d="M29,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,62.8c-0.8-0.2-0.8,3,0,2.8C28.2,65.8,28.2,62.7,27.3,62.8z"/>
		<path class="st1" d="M27,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,62.8c-0.8-0.2-0.8,3,0,2.8C26.2,65.8,26.2,62.7,25.3,62.8z"/>
		<path class="st1" d="M25.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,62.8c-0.8-0.2-0.8,3,0,2.8C24.2,65.8,24.2,62.7,23.4,62.8z"/>
		<path class="st1" d="M23.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,62.8c-0.8-0.2-0.8,3,0,2.8C22.2,65.8,22.2,62.7,21.4,62.8z"/>
		<path class="st1" d="M21.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,62.8c-0.8-0.2-0.8,3,0,2.8C20.2,65.8,20.2,62.7,19.4,62.8z"/>
		<path class="st1" d="M19.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,62.8c-0.8-0.2-0.8,3,0,2.8C18.2,65.8,18.2,62.7,17.4,62.8z"/>
		<path class="st1" d="M17.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,62.8c-0.8-0.2-0.8,3,0,2.8C16.2,65.8,16.2,62.7,15.4,62.8z"/>
		<path class="st1" d="M15.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,62.8c-0.8-0.2-0.8,3,0,2.8C14.3,65.8,14.3,62.7,13.4,62.8z"/>
		<path class="st1" d="M13.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M11.4,62.8c-0.8-0.2-0.8,3,0,2.8C12.3,65.8,12.3,62.7,11.4,62.8z"/>
		<path class="st2" d="M11.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M9.5,62.8c-0.8-0.2-0.8,3,0,2.8C10.3,65.8,10.3,62.7,9.5,62.8z"/>
		<path class="st2" d="M9.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M7.5,62.8c-0.8-0.2-0.8,3,0,2.8C8.3,65.8,8.3,62.7,7.5,62.8z"/>
		<path class="st2" d="M7.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,62.8c-0.8-0.2-0.8,3,0,2.8C6.3,65.8,6.3,62.7,5.5,62.8z"/>
		<path class="st2" d="M5.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,62.8c-0.8-0.2-0.8,3,0,2.8C4.3,65.8,4.3,62.7,3.5,62.8z"/>
		<path class="st2" d="M3.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M78.8,62.8c-0.8-0.2-0.8,3,0,2.8C79.7,65.8,79.7,62.7,78.8,62.8z"/>
		<path class="st1" d="M78.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M76.9,62.8c-0.8-0.2-0.8,3,0,2.8C77.7,65.8,77.7,62.7,76.9,62.8z"/>
		<path class="st1" d="M76.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M74.9,62.8c-0.8-0.2-0.8,3,0,2.8C75.7,65.8,75.7,62.7,74.9,62.8z"/>
		<path class="st1" d="M74.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M72.9,62.8c-0.8-0.2-0.8,3,0,2.8C73.7,65.8,73.7,62.7,72.9,62.8z"/>
		<path class="st1" d="M72.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M68.9,62.8c-0.8-0.2-0.8,3,0,2.8C69.7,65.8,69.7,62.7,68.9,62.8z"/>
		<path class="st1" d="M68.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M66.9,62.8c-0.8-0.2-0.8,3,0,2.8C67.7,65.8,67.7,62.7,66.9,62.8z"/>
		<path class="st1" d="M66.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M70.9,62.8c-0.8-0.2-0.8,3,0,2.8C71.7,65.8,71.7,62.7,70.9,62.8z"/>
		<path class="st1" d="M70.6,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,62.8c-0.8-0.2-0.8,3,0,2.8C129.2,65.8,129.2,62.6,128.4,62.8z"/>
		<path class="st1" d="M128.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,62.8c-0.8-0.2-0.8,3,0,2.8C127.2,65.8,127.2,62.6,126.4,62.8z"/>
		<path class="st1" d="M126.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,62.8c-0.8-0.2-0.8,3,0,2.8C125.3,65.8,125.3,62.6,124.4,62.8z"/>
		<path class="st1" d="M124.1,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,62.8c-0.8-0.2-0.8,3,0,2.8C123.3,65.8,123.3,62.6,122.4,62.8z"/>
		<path class="st1" d="M122.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,62.8c-0.8-0.2-0.8,3,0,2.8C121.3,65.8,121.3,62.6,120.5,62.8z"/>
		<path class="st1" d="M120.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,62.8c-0.8-0.2-0.8,3,0,2.8C119.3,65.8,119.3,62.6,118.5,62.8z"/>
		<path class="st1" d="M118.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,62.8c-0.8-0.2-0.8,3,0,2.8C117.3,65.8,117.3,62.6,116.5,62.8z"/>
		<path class="st1" d="M116.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,62.8c-0.8-0.2-0.8,3,0,2.8C115.3,65.8,115.3,62.6,114.5,62.8z"/>
		<path class="st1" d="M114.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,62.8c-0.8-0.2-0.8,3,0,2.8C113.3,65.8,113.3,62.6,112.5,62.8z"/>
		<path class="st1" d="M112.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,62.8c-0.8-0.2-0.8,3,0,2.8C111.3,65.8,111.3,62.6,110.5,62.8z"/>
		<path class="st1" d="M110.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,62.8c-0.8-0.2-0.8,3,0,2.8C109.4,65.8,109.4,62.6,108.5,62.8z"/>
		<path class="st1" d="M108.2,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,62.8c-0.8-0.2-0.8,3,0,2.8C107.4,65.8,107.4,62.6,106.5,62.8z"/>
		<path class="st1" d="M106.3,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,62.8c-0.8-0.2-0.8,3,0,2.8C105.4,65.8,105.4,62.6,104.6,62.8z"/>
		<path class="st1" d="M104.3,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,62.8c-0.8-0.2-0.8,3,0,2.8C103.4,65.8,103.4,62.6,102.6,62.8z"/>
		<path class="st1" d="M102.3,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,62.8c-0.8-0.2-0.8,3,0,2.8C101.4,65.8,101.4,62.6,100.6,62.8z"/>
		<path class="st1" d="M100.3,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,62.8c-0.8-0.2-0.8,3,0,2.8C99.4,65.8,99.4,62.6,98.6,62.8z"/>
		<path class="st1" d="M98.3,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,62.8c-0.8-0.2-0.8,3,0,2.8C97.5,65.8,97.6,62.6,96.7,62.8z"/>
		<path class="st1" d="M96.4,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,62.8c-0.8-0.2-0.8,3,0,2.8C95.6,65.8,95.6,62.6,94.7,62.8z"/>
		<path class="st1" d="M94.4,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,62.8c-0.8-0.2-0.8,3,0,2.8C93.6,65.8,93.6,62.6,92.7,62.8z"/>
		<path class="st1" d="M92.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,62.8c-0.8-0.2-0.8,3,0,2.8C91.6,65.8,91.6,62.6,90.8,62.8z"/>
		<path class="st1" d="M90.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,62.8c-0.8-0.2-0.8,3,0,2.8C89.6,65.8,89.6,62.6,88.8,62.8z"/>
		<path class="st1" d="M88.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,62.8c-0.8-0.2-0.8,3,0,2.8C87.6,65.8,87.6,62.6,86.8,62.8z"/>
		<path class="st1" d="M86.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,62.8c-0.8-0.2-0.8,3,0,2.8C85.6,65.8,85.6,62.6,84.8,62.8z"/>
		<path class="st1" d="M84.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,62.8c-0.8-0.2-0.8,3,0,2.8C83.6,65.8,83.6,62.6,82.8,62.8z"/>
		<path class="st1" d="M82.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M80.8,62.8c-0.8-0.2-0.8,3,0,2.8C81.7,65.8,81.7,62.6,80.8,62.8z"/>
		<path class="st1" d="M80.5,63.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,146.2c-0.8-0.2-0.8,3,0,2.8C65.8,149.2,65.8,146,65,146.2z"/>
		<path class="st1" d="M64.7,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,146.2c-0.8-0.2-0.8,3,0,2.8C63.8,149.2,63.8,146,63,146.2z"/>
		<path class="st1" d="M62.7,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,146.2c-0.8-0.2-0.8,3,0,2.8C61.8,149.2,61.8,146,61,146.2z"/>
		<path class="st1" d="M60.7,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,146.2c-0.8-0.2-0.8,3,0,2.8C59.8,149.2,59.8,146,59,146.2z"/>
		<path class="st1" d="M58.7,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,146.2c-0.8-0.2-0.8,3,0,2.8C57.9,149.2,57.9,146,57,146.2z"/>
		<path class="st1" d="M56.7,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,146.2c-0.8-0.2-0.8,3,0,2.8C55.9,149.2,55.9,146,55,146.2z"/>
		<path class="st1" d="M54.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,146.2c-0.8-0.2-0.8,3,0,2.8C53.9,149.2,53.9,146,53.1,146.2z"/>
		<path class="st1" d="M52.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,146.2c-0.8-0.2-0.8,3,0,2.8C51.9,149.2,51.9,146,51.1,146.2z"/>
		<path class="st1" d="M50.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,146.2c-0.8-0.2-0.8,3,0,2.8C49.9,149.2,49.9,146,49.1,146.2z"/>
		<path class="st1" d="M48.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,146.2c-0.8-0.2-0.8,3,0,2.8C47.9,149.2,47.9,146,47.1,146.2z"/>
		<path class="st1" d="M46.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,146.2c-0.8-0.2-0.8,3,0,2.8C45.9,149.2,45.9,146,45.1,146.2z"/>
		<path class="st1" d="M44.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,146.2c-0.8-0.2-0.8,3,0,2.8C43.9,149.2,43.9,146,43.1,146.2z"/>
		<path class="st1" d="M42.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,146.2c-0.8-0.2-0.8,3,0,2.8C42,149.2,42,146,41.1,146.2z"/>
		<path class="st1" d="M40.8,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,146.2c-0.8-0.2-0.8,3,0,2.8C40,149.2,40,146,39.1,146.2z"/>
		<path class="st1" d="M38.9,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,146.2c-0.8-0.2-0.8,3,0,2.8C38,149.2,38,146,37.2,146.2z"/>
		<path class="st1" d="M36.9,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,146.2c-0.8-0.2-0.8,3,0,2.8C36,149.2,36,146,35.2,146.2z"/>
		<path class="st1" d="M34.9,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,146.2c-0.8-0.2-0.8,3,0,2.8C34.1,149.2,34.1,146,33.3,146.2z"/>
		<path class="st1" d="M33,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,146.2c-0.8-0.2-0.8,3,0,2.8C32.1,149.2,32.1,146,31.3,146.2z"/>
		<path class="st1" d="M31,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,146.2c-0.8-0.2-0.8,3,0,2.8C30.1,149.2,30.2,146,29.3,146.2z"/>
		<path class="st1" d="M29,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,146.2c-0.8-0.2-0.8,3,0,2.8C28.2,149.2,28.2,146,27.3,146.2z"/>
		<path class="st1" d="M27,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,146.2c-0.8-0.2-0.8,3,0,2.8C26.2,149.2,26.2,146,25.3,146.2z"/>
		<path class="st1" d="M25.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,146.2c-0.8-0.2-0.8,3,0,2.8C24.2,149.2,24.2,146,23.4,146.2z"/>
		<path class="st1" d="M23.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,146.2c-0.8-0.2-0.8,3,0,2.8C22.2,149.2,22.2,146,21.4,146.2z"/>
		<path class="st1" d="M21.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,146.2c-0.8-0.2-0.8,3,0,2.8C20.2,149.2,20.2,146,19.4,146.2z"/>
		<path class="st1" d="M19.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,146.2c-0.8-0.2-0.8,3,0,2.8C18.2,149.2,18.2,146,17.4,146.2z"/>
		<path class="st1" d="M17.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,146.2c-0.8-0.2-0.8,3,0,2.8C16.2,149.2,16.2,146,15.4,146.2z"/>
		<path class="st1" d="M15.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,146.2c-0.8-0.2-0.8,3,0,2.8C14.3,149.2,14.3,146,13.4,146.2z"/>
		<path class="st1" d="M13.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,146.2c-0.8-0.2-0.8,3,0,2.8C12.3,149.2,12.3,146,11.4,146.2z"/>
		<path class="st1" d="M11.1,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,146.2c-0.8-0.2-0.8,3,0,2.8C10.3,149.2,10.3,146,9.5,146.2z"/>
		<path class="st1" d="M9.2,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,146.2c-0.8-0.2-0.8,3,0,2.8C8.3,149.2,8.3,146,7.5,146.2z"/>
		<path class="st1" d="M7.2,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,146.2c-0.8-0.2-0.8,3,0,2.8C6.3,149.2,6.3,146,5.5,146.2z"/>
		<path class="st1" d="M5.2,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,146.2c-0.8-0.2-0.8,3,0,2.8C4.3,149.2,4.3,146,3.5,146.2z"/>
		<path class="st1" d="M3.2,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,146.2c-0.8-0.2-0.8,3,0,2.8C87.6,149.2,87.6,146,86.8,146.2z"/>
		<path class="st1" d="M86.5,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,146.2c-0.8-0.2-0.8,3,0,2.8C85.6,149.2,85.6,146,84.8,146.2z"/>
		<path class="st1" d="M84.5,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,146.2c-0.8-0.2-0.8,3,0,2.8C83.6,149.2,83.6,146,82.8,146.2z"/>
		<path class="st1" d="M82.5,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,146.2c-0.8-0.2-0.8,3,0,2.8C81.7,149.2,81.7,146,80.8,146.2z"/>
		<path class="st1" d="M80.5,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,146.2c-0.8-0.2-0.8,3,0,2.8C79.7,149.2,79.7,146,78.8,146.2z"/>
		<path class="st1" d="M78.5,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,146.2c-0.8-0.2-0.8,3,0,2.8C77.7,149.2,77.7,146,76.9,146.2z"/>
		<path class="st1" d="M76.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,146.2c-0.8-0.2-0.8,3,0,2.8C75.7,149.2,75.7,146,74.9,146.2z"/>
		<path class="st1" d="M74.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,146.2c-0.8-0.2-0.8,3,0,2.8C73.7,149.2,73.7,146,72.9,146.2z"/>
		<path class="st1" d="M72.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,146.2c-0.8-0.2-0.8,3,0,2.8C71.7,149.2,71.7,146,70.9,146.2z"/>
		<path class="st1" d="M70.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,146.2c-0.8-0.2-0.8,3,0,2.8C69.7,149.2,69.7,146,68.9,146.2z"/>
		<path class="st1" d="M68.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,146.2c-0.8-0.2-0.8,3,0,2.8C67.7,149.2,67.7,146,66.9,146.2z"/>
		<path class="st1" d="M66.6,147.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,147c-0.8-0.2-0.8,3,0,2.8C66.7,150,66.7,146.8,65.9,147z"/>
		<path class="st1" d="M65.6,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,147c-0.8-0.2-0.8,3,0,2.8C64.7,150,64.7,146.8,63.9,147z"/>
		<path class="st1" d="M63.6,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,147c-0.8-0.2-0.8,3,0,2.8C62.7,150,62.8,146.8,61.9,147z"/>
		<path class="st1" d="M61.6,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,147c-0.8-0.2-0.8,3,0,2.8C60.8,150,60.8,146.8,59.9,147z"/>
		<path class="st1" d="M59.6,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,147c-0.8-0.2-0.8,3,0,2.8C58.8,150,58.8,146.8,57.9,147z"/>
		<path class="st1" d="M57.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,147c-0.8-0.2-0.8,3,0,2.8C56.8,150,56.8,146.8,56,147z"/>
		<path class="st1" d="M55.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,147c-0.8-0.2-0.8,3,0,2.8C54.8,150,54.8,146.8,54,147z"/>
		<path class="st1" d="M53.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,147c-0.8-0.2-0.8,3,0,2.8C52.8,150,52.8,146.8,52,147z"/>
		<path class="st1" d="M51.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,147c-0.8-0.2-0.8,3,0,2.8C50.8,150,50.8,146.8,50,147z"/>
		<path class="st1" d="M49.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,147c-0.8-0.2-0.8,3,0,2.8C48.8,150,48.8,146.8,48,147z"/>
		<path class="st1" d="M47.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,147c-0.8-0.2-0.8,3,0,2.8C46.9,150,46.9,146.8,46,147z"/>
		<path class="st1" d="M45.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,147c-0.8-0.2-0.8,3,0,2.8C44.9,150,44.9,146.8,44,147z"/>
		<path class="st1" d="M43.7,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,147c-0.8-0.2-0.8,3,0,2.8C42.9,150,42.9,146.8,42.1,147z"/>
		<path class="st1" d="M41.8,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,147c-0.8-0.2-0.8,3,0,2.8C40.9,150,40.9,146.8,40.1,147z"/>
		<path class="st1" d="M39.8,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,147c-0.8-0.2-0.8,3,0,2.8C38.9,150,38.9,146.8,38.1,147z"/>
		<path class="st1" d="M37.8,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,147c-0.8-0.2-0.8,3,0,2.8C36.9,150,36.9,146.8,36.1,147z"/>
		<path class="st1" d="M35.8,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,147c-0.8-0.2-0.8,3,0,2.8C35,150,35,146.8,34.2,147z"/>
		<path class="st1" d="M33.9,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,147c-0.8-0.2-0.8,3,0,2.8C33.1,150,33.1,146.8,32.2,147z"/>
		<path class="st1" d="M31.9,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,147c-0.8-0.2-0.8,3,0,2.8C31.1,150,31.1,146.8,30.2,147z"/>
		<path class="st1" d="M30,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,147c-0.8-0.2-0.8,3,0,2.8C29.1,150,29.1,146.8,28.3,147z"/>
		<path class="st1" d="M28,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,147c-0.8-0.2-0.8,3,0,2.8C27.1,150,27.1,146.8,26.3,147z"/>
		<path class="st1" d="M26,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,147c-0.8-0.2-0.8,3,0,2.8C25.1,150,25.1,146.8,24.3,147z"/>
		<path class="st1" d="M24,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,147c-0.8-0.2-0.8,3,0,2.8C23.1,150,23.1,146.8,22.3,147z"/>
		<path class="st1" d="M22,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,147c-0.8-0.2-0.8,3,0,2.8C21.1,150,21.1,146.8,20.3,147z"/>
		<path class="st1" d="M20,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,147c-0.8-0.2-0.8,3,0,2.8C19.1,150,19.1,146.8,18.3,147z"/>
		<path class="st1" d="M18,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,147c-0.8-0.2-0.8,3,0,2.8C17.2,150,17.2,146.8,16.3,147z"/>
		<path class="st1" d="M16,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,147c-0.8-0.2-0.8,3,0,2.8C15.2,150,15.2,146.8,14.3,147z"/>
		<path class="st1" d="M14.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,147c-0.8-0.2-0.8,3,0,2.8C13.2,150,13.2,146.8,12.4,147z"/>
		<path class="st1" d="M12.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,147c-0.8-0.2-0.8,3,0,2.8C11.2,150,11.2,146.8,10.4,147z"/>
		<path class="st1" d="M10.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,147c-0.8-0.2-0.8,3,0,2.8C9.2,150,9.2,146.8,8.4,147z"/>
		<path class="st1" d="M8.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,147c-0.8-0.2-0.8,3,0,2.8C7.2,150,7.2,146.8,6.4,147z"/>
		<path class="st1" d="M6.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,147c-0.8-0.2-0.8,3,0,2.8C5.2,150,5.2,146.8,4.4,147z"/>
		<path class="st1" d="M4.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,147c-0.8-0.2-0.8,3,0,2.8C130.1,150,130.1,146.8,129.3,147z"/>
		<path class="st1" d="M129,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,147c-0.8-0.2-0.8,3,0,2.8C128.2,150,128.2,146.8,127.3,147z"/>
		<path class="st1" d="M127,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,147c-0.8-0.2-0.8,3,0,2.8C126.2,150,126.2,146.8,125.3,147z"/>
		<path class="st1" d="M125.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,147c-0.8-0.2-0.8,3,0,2.8C124.2,150,124.2,146.8,123.4,147z"/>
		<path class="st1" d="M123.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,147c-0.8-0.2-0.8,3,0,2.8C122.2,150,122.2,146.8,121.4,147z"/>
		<path class="st1" d="M121.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,147c-0.8-0.2-0.8,3,0,2.8C120.2,150,120.2,146.8,119.4,147z"/>
		<path class="st1" d="M119.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,147c-0.8-0.2-0.8,3,0,2.8C118.2,150,118.2,146.8,117.4,147z"/>
		<path class="st1" d="M117.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,147c-0.8-0.2-0.8,3,0,2.8C116.2,150,116.2,146.8,115.4,147z"/>
		<path class="st1" d="M115.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,147c-0.8-0.2-0.8,3,0,2.8C114.3,150,114.3,146.8,113.4,147z"/>
		<path class="st1" d="M113.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,147c-0.8-0.2-0.8,3,0,2.8C112.3,150,112.3,146.8,111.4,147z"/>
		<path class="st1" d="M111.1,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,147c-0.8-0.2-0.8,3,0,2.8C110.3,150,110.3,146.8,109.4,147z"/>
		<path class="st1" d="M109.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,147c-0.8-0.2-0.8,3,0,2.8C108.3,150,108.3,146.8,107.5,147z"/>
		<path class="st1" d="M107.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,147c-0.8-0.2-0.8,3,0,2.8C106.3,150,106.3,146.8,105.5,147z"/>
		<path class="st1" d="M105.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,147c-0.8-0.2-0.8,3,0,2.8C104.3,150,104.3,146.8,103.5,147z"/>
		<path class="st1" d="M103.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,147c-0.8-0.2-0.8,3,0,2.8C102.3,150,102.3,146.8,101.5,147z"/>
		<path class="st1" d="M101.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,147c-0.8-0.2-0.8,3,0,2.8C100.3,150,100.3,146.8,99.5,147z"/>
		<path class="st1" d="M99.2,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,147c-0.8-0.2-0.8,3,0,2.8C98.5,150,98.5,146.8,97.6,147z"/>
		<path class="st1" d="M97.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,147c-0.8-0.2-0.8,3,0,2.8C96.5,150,96.5,146.8,95.7,147z"/>
		<path class="st1" d="M95.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,147c-0.8-0.2-0.8,3,0,2.8C94.5,150,94.5,146.8,93.7,147z"/>
		<path class="st1" d="M93.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,147c-0.8-0.2-0.8,3,0,2.8C92.5,150,92.5,146.8,91.7,147z"/>
		<path class="st1" d="M91.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,147c-0.8-0.2-0.8,3,0,2.8C90.5,150,90.5,146.8,89.7,147z"/>
		<path class="st1" d="M89.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,147c-0.8-0.2-0.8,3,0,2.8C88.5,150,88.5,146.8,87.7,147z"/>
		<path class="st1" d="M87.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,147c-0.8-0.2-0.8,3,0,2.8C86.5,150,86.5,146.8,85.7,147z"/>
		<path class="st1" d="M85.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,147c-0.8-0.2-0.8,3,0,2.8C84.6,150,84.6,146.8,83.7,147z"/>
		<path class="st1" d="M83.4,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,147c-0.8-0.2-0.8,3,0,2.8C82.6,150,82.6,146.8,81.7,147z"/>
		<path class="st1" d="M81.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,147c-0.8-0.2-0.8,3,0,2.8C80.6,150,80.6,146.8,79.8,147z"/>
		<path class="st1" d="M79.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,147c-0.8-0.2-0.8,3,0,2.8C78.6,150,78.6,146.8,77.8,147z"/>
		<path class="st1" d="M77.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,147c-0.8-0.2-0.8,3,0,2.8C76.6,150,76.6,146.8,75.8,147z"/>
		<path class="st1" d="M75.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,147c-0.8-0.2-0.8,3,0,2.8C74.6,150,74.6,146.8,73.8,147z"/>
		<path class="st1" d="M73.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,147c-0.8-0.2-0.8,3,0,2.8C72.6,150,72.6,146.8,71.8,147z"/>
		<path class="st1" d="M71.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,147c-0.8-0.2-0.8,3,0,2.8C70.7,150,70.7,146.8,69.8,147z"/>
		<path class="st1" d="M69.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,147c-0.8-0.2-0.8,3,0,2.8C68.7,150,68.7,146.8,67.8,147z"/>
		<path class="st1" d="M67.5,147.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,147.9c-0.8-0.2-0.8,3,0,2.8C65.8,150.9,65.8,147.7,65,147.9z"/>
		<path class="st1" d="M64.7,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,147.9c-0.8-0.2-0.8,3,0,2.8C63.8,150.9,63.8,147.7,63,147.9z"/>
		<path class="st1" d="M62.7,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,147.9c-0.8-0.2-0.8,3,0,2.8C61.8,150.9,61.8,147.7,61,147.9z"/>
		<path class="st1" d="M60.7,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,147.9c-0.8-0.2-0.8,3,0,2.8C59.8,150.9,59.8,147.7,59,147.9z"/>
		<path class="st1" d="M58.7,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,147.9c-0.8-0.2-0.8,3,0,2.8C57.9,150.9,57.9,147.7,57,147.9z"/>
		<path class="st1" d="M56.7,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,147.9c-0.8-0.2-0.8,3,0,2.8C55.9,150.9,55.9,147.7,55,147.9z"/>
		<path class="st1" d="M54.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,147.9c-0.8-0.2-0.8,3,0,2.8C53.9,150.9,53.9,147.7,53.1,147.9z"/>
		<path class="st1" d="M52.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,147.9c-0.8-0.2-0.8,3,0,2.8C51.9,150.9,51.9,147.7,51.1,147.9z"/>
		<path class="st1" d="M50.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,147.9c-0.8-0.2-0.8,3,0,2.8C49.9,150.9,49.9,147.7,49.1,147.9z"/>
		<path class="st1" d="M48.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,147.9c-0.8-0.2-0.8,3,0,2.8C47.9,150.9,47.9,147.7,47.1,147.9z"/>
		<path class="st1" d="M46.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,147.9c-0.8-0.2-0.8,3,0,2.8C45.9,150.9,45.9,147.7,45.1,147.9z"/>
		<path class="st1" d="M44.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,147.9c-0.8-0.2-0.8,3,0,2.8C43.9,150.9,43.9,147.7,43.1,147.9z"/>
		<path class="st1" d="M42.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,147.9c-0.8-0.2-0.8,3,0,2.8C42,150.9,42,147.7,41.1,147.9z"/>
		<path class="st1" d="M40.8,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,147.9c-0.8-0.2-0.8,3,0,2.8C40,150.9,40,147.7,39.1,147.9z"/>
		<path class="st1" d="M38.9,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,147.9c-0.8-0.2-0.8,3,0,2.8C38,150.9,38,147.7,37.2,147.9z"/>
		<path class="st1" d="M36.9,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,147.9c-0.8-0.2-0.8,3,0,2.8C36,150.9,36,147.7,35.2,147.9z"/>
		<path class="st1" d="M34.9,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,147.9c-0.8-0.2-0.8,3,0,2.8C34.1,150.9,34.1,147.7,33.3,147.9z"/>
		<path class="st1" d="M33,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,147.9c-0.8-0.2-0.8,3,0,2.8C32.1,150.9,32.1,147.7,31.3,147.9z"/>
		<path class="st1" d="M31,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,147.9c-0.8-0.2-0.8,3,0,2.8C30.1,150.9,30.2,147.7,29.3,147.9z"/>
		<path class="st1" d="M29,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,147.9c-0.8-0.2-0.8,3,0,2.8C28.2,150.9,28.2,147.7,27.3,147.9z"/>
		<path class="st1" d="M27,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,147.9c-0.8-0.2-0.8,3,0,2.8C26.2,150.9,26.2,147.7,25.3,147.9z"/>
		<path class="st1" d="M25.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,147.9c-0.8-0.2-0.8,3,0,2.8C24.2,150.9,24.2,147.7,23.4,147.9z"/>
		<path class="st1" d="M23.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,147.9c-0.8-0.2-0.8,3,0,2.8C22.2,150.9,22.2,147.7,21.4,147.9z"/>
		<path class="st1" d="M21.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,147.9c-0.8-0.2-0.8,3,0,2.8C20.2,150.9,20.2,147.7,19.4,147.9z"/>
		<path class="st1" d="M19.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,147.9c-0.8-0.2-0.8,3,0,2.8C18.2,150.9,18.2,147.7,17.4,147.9z"/>
		<path class="st1" d="M17.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,147.9c-0.8-0.2-0.8,3,0,2.8C16.2,150.9,16.2,147.7,15.4,147.9z"/>
		<path class="st1" d="M15.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,147.9c-0.8-0.2-0.8,3,0,2.8C14.3,150.9,14.3,147.7,13.4,147.9z"/>
		<path class="st1" d="M13.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,147.9c-0.8-0.2-0.8,3,0,2.8C12.3,150.9,12.3,147.7,11.4,147.9z"/>
		<path class="st1" d="M11.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,147.9c-0.8-0.2-0.8,3,0,2.8C10.3,150.9,10.3,147.7,9.5,147.9z"/>
		<path class="st1" d="M9.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,147.9c-0.8-0.2-0.8,3,0,2.8C8.3,150.9,8.3,147.7,7.5,147.9z"/>
		<path class="st1" d="M7.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,147.9c-0.8-0.2-0.8,3,0,2.8C6.3,150.9,6.3,147.7,5.5,147.9z"/>
		<path class="st1" d="M5.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,147.9c-0.8-0.2-0.8,3,0,2.8C4.3,150.9,4.3,147.7,3.5,147.9z"/>
		<path class="st1" d="M3.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,147.9c-0.8-0.2-0.8,3,0,2.8C129.2,150.9,129.2,147.7,128.4,147.9z"/>
		<path class="st1" d="M128.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,147.9c-0.8-0.2-0.8,3,0,2.8C127.2,150.9,127.2,147.7,126.4,147.9z"/>
		<path class="st1" d="M126.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,147.9c-0.8-0.2-0.8,3,0,2.8C125.3,150.9,125.3,147.7,124.4,147.9z"/>
		<path class="st1" d="M124.1,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,147.9c-0.8-0.2-0.8,3,0,2.8C123.3,150.9,123.3,147.7,122.4,147.9z"/>
		<path class="st1" d="M122.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,147.9c-0.8-0.2-0.8,3,0,2.8C121.3,150.9,121.3,147.7,120.5,147.9z"/>
		<path class="st1" d="M120.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,147.9c-0.8-0.2-0.8,3,0,2.8C119.3,150.9,119.3,147.7,118.5,147.9z"/>
		<path class="st1" d="M118.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,147.9c-0.8-0.2-0.8,3,0,2.8C117.3,150.9,117.3,147.7,116.5,147.9z"/>
		<path class="st1" d="M116.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,147.9c-0.8-0.2-0.8,3,0,2.8C115.3,150.9,115.3,147.7,114.5,147.9z"/>
		<path class="st1" d="M114.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,147.9c-0.8-0.2-0.8,3,0,2.8C113.3,150.9,113.3,147.7,112.5,147.9z"/>
		<path class="st1" d="M112.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,147.9c-0.8-0.2-0.8,3,0,2.8C111.3,150.9,111.3,147.7,110.5,147.9z"/>
		<path class="st1" d="M110.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,147.9c-0.8-0.2-0.8,3,0,2.8C109.4,150.9,109.4,147.7,108.5,147.9z"/>
		<path class="st1" d="M108.2,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,147.9c-0.8-0.2-0.8,3,0,2.8C107.4,150.9,107.4,147.7,106.5,147.9z"/>
		<path class="st1" d="M106.3,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,147.9c-0.8-0.2-0.8,3,0,2.8C105.4,150.9,105.4,147.7,104.6,147.9z"/>
		<path class="st1" d="M104.3,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,147.9c-0.8-0.2-0.8,3,0,2.8C103.4,150.9,103.4,147.7,102.6,147.9z"/>
		<path class="st1" d="M102.3,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,147.9c-0.8-0.2-0.8,3,0,2.8C101.4,150.9,101.4,147.7,100.6,147.9z"/>
		<path class="st1" d="M100.3,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,147.9c-0.8-0.2-0.8,3,0,2.8C99.4,150.9,99.4,147.7,98.6,147.9z"/>
		<path class="st1" d="M98.3,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,147.9c-0.8-0.2-0.8,3,0,2.8C97.5,150.9,97.6,147.7,96.7,147.9z"/>
		<path class="st1" d="M96.4,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,147.9c-0.8-0.2-0.8,3,0,2.8C95.6,150.9,95.6,147.7,94.7,147.9z"/>
		<path class="st1" d="M94.4,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,147.9c-0.8-0.2-0.8,3,0,2.8C93.6,150.9,93.6,147.7,92.7,147.9z"/>
		<path class="st1" d="M92.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,147.9c-0.8-0.2-0.8,3,0,2.8C91.6,150.9,91.6,147.7,90.8,147.9z"/>
		<path class="st1" d="M90.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,147.9c-0.8-0.2-0.8,3,0,2.8C89.6,150.9,89.6,147.7,88.8,147.9z"/>
		<path class="st1" d="M88.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,147.9c-0.8-0.2-0.8,3,0,2.8C87.6,150.9,87.6,147.7,86.8,147.9z"/>
		<path class="st1" d="M86.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,147.9c-0.8-0.2-0.8,3,0,2.8C85.6,150.9,85.6,147.7,84.8,147.9z"/>
		<path class="st1" d="M84.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,147.9c-0.8-0.2-0.8,3,0,2.8C83.6,150.9,83.6,147.7,82.8,147.9z"/>
		<path class="st1" d="M82.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,147.9c-0.8-0.2-0.8,3,0,2.8C81.7,150.9,81.7,147.7,80.8,147.9z"/>
		<path class="st1" d="M80.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,147.9c-0.8-0.2-0.8,3,0,2.8C79.7,150.9,79.7,147.7,78.8,147.9z"/>
		<path class="st1" d="M78.5,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,147.9c-0.8-0.2-0.8,3,0,2.8C77.7,150.9,77.7,147.7,76.9,147.9z"/>
		<path class="st1" d="M76.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,147.9c-0.8-0.2-0.8,3,0,2.8C75.7,150.9,75.7,147.7,74.9,147.9z"/>
		<path class="st1" d="M74.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,147.9c-0.8-0.2-0.8,3,0,2.8C73.7,150.9,73.7,147.7,72.9,147.9z"/>
		<path class="st1" d="M72.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,147.9c-0.8-0.2-0.8,3,0,2.8C71.7,150.9,71.7,147.7,70.9,147.9z"/>
		<path class="st1" d="M70.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,147.9c-0.8-0.2-0.8,3,0,2.8C69.7,150.9,69.7,147.7,68.9,147.9z"/>
		<path class="st1" d="M68.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,147.9c-0.8-0.2-0.8,3,0,2.8C67.7,150.9,67.7,147.7,66.9,147.9z"/>
		<path class="st1" d="M66.6,148.8c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,148.7c-0.8-0.2-0.8,3,0,2.8C66.7,151.7,66.7,148.5,65.9,148.7z"/>
		<path class="st1" d="M65.6,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,148.7c-0.8-0.2-0.8,3,0,2.8C64.7,151.7,64.7,148.5,63.9,148.7z"/>
		<path class="st1" d="M63.6,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,148.7c-0.8-0.2-0.8,3,0,2.8C62.7,151.7,62.8,148.5,61.9,148.7z"/>
		<path class="st1" d="M61.6,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,148.7c-0.8-0.2-0.8,3,0,2.8C60.8,151.7,60.8,148.5,59.9,148.7z"/>
		<path class="st1" d="M59.6,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,148.7c-0.8-0.2-0.8,3,0,2.8C58.8,151.7,58.8,148.5,57.9,148.7z"/>
		<path class="st1" d="M57.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,148.7c-0.8-0.2-0.8,3,0,2.8C56.8,151.7,56.8,148.5,56,148.7z"/>
		<path class="st1" d="M55.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,148.7c-0.8-0.2-0.8,3,0,2.8C54.8,151.7,54.8,148.5,54,148.7z"/>
		<path class="st1" d="M53.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,148.7c-0.8-0.2-0.8,3,0,2.8C52.8,151.7,52.8,148.5,52,148.7z"/>
		<path class="st1" d="M51.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,148.7c-0.8-0.2-0.8,3,0,2.8C50.8,151.7,50.8,148.5,50,148.7z"/>
		<path class="st1" d="M49.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,148.7c-0.8-0.2-0.8,3,0,2.8C48.8,151.7,48.8,148.5,48,148.7z"/>
		<path class="st1" d="M47.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,148.7c-0.8-0.2-0.8,3,0,2.8C46.9,151.7,46.9,148.5,46,148.7z"/>
		<path class="st1" d="M45.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,148.7c-0.8-0.2-0.8,3,0,2.8C44.9,151.7,44.9,148.5,44,148.7z"/>
		<path class="st1" d="M43.7,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,148.7c-0.8-0.2-0.8,3,0,2.8C42.9,151.7,42.9,148.5,42.1,148.7z"/>
		<path class="st1" d="M41.8,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,148.7c-0.8-0.2-0.8,3,0,2.8C40.9,151.7,40.9,148.5,40.1,148.7z"/>
		<path class="st1" d="M39.8,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,148.7c-0.8-0.2-0.8,3,0,2.8C38.9,151.7,38.9,148.5,38.1,148.7z"/>
		<path class="st1" d="M37.8,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,148.7c-0.8-0.2-0.8,3,0,2.8C36.9,151.7,36.9,148.5,36.1,148.7z"/>
		<path class="st1" d="M35.8,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,148.7c-0.8-0.2-0.8,3,0,2.8C35,151.7,35,148.5,34.2,148.7z"/>
		<path class="st1" d="M33.9,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,148.7c-0.8-0.2-0.8,3,0,2.8C33.1,151.7,33.1,148.5,32.2,148.7z"/>
		<path class="st1" d="M31.9,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,148.7c-0.8-0.2-0.8,3,0,2.8C31.1,151.7,31.1,148.5,30.2,148.7z"/>
		<path class="st1" d="M30,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,148.7c-0.8-0.2-0.8,3,0,2.8C29.1,151.7,29.1,148.5,28.3,148.7z"/>
		<path class="st1" d="M28,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,148.7c-0.8-0.2-0.8,3,0,2.8C27.1,151.7,27.1,148.5,26.3,148.7z"/>
		<path class="st1" d="M26,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,148.7c-0.8-0.2-0.8,3,0,2.8C25.1,151.7,25.1,148.5,24.3,148.7z"/>
		<path class="st1" d="M24,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,148.7c-0.8-0.2-0.8,3,0,2.8C23.1,151.7,23.1,148.5,22.3,148.7z"/>
		<path class="st1" d="M22,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,148.7c-0.8-0.2-0.8,3,0,2.8C21.1,151.7,21.1,148.5,20.3,148.7z"/>
		<path class="st1" d="M20,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,148.7c-0.8-0.2-0.8,3,0,2.8C19.1,151.7,19.1,148.5,18.3,148.7z"/>
		<path class="st1" d="M18,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,148.7c-0.8-0.2-0.8,3,0,2.8C17.2,151.7,17.2,148.5,16.3,148.7z"/>
		<path class="st1" d="M16,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,148.7c-0.8-0.2-0.8,3,0,2.8C15.2,151.7,15.2,148.5,14.3,148.7z"/>
		<path class="st1" d="M14.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,148.7c-0.8-0.2-0.8,3,0,2.8C13.2,151.7,13.2,148.5,12.4,148.7z"/>
		<path class="st1" d="M12.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,148.7c-0.8-0.2-0.8,3,0,2.8C11.2,151.7,11.2,148.5,10.4,148.7z"/>
		<path class="st1" d="M10.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,148.7c-0.8-0.2-0.8,3,0,2.8C9.2,151.7,9.2,148.5,8.4,148.7z"/>
		<path class="st1" d="M8.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,148.7c-0.8-0.2-0.8,3,0,2.8C7.2,151.7,7.2,148.5,6.4,148.7z"/>
		<path class="st1" d="M6.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,148.7c-0.8-0.2-0.8,3,0,2.8C5.2,151.7,5.2,148.5,4.4,148.7z"/>
		<path class="st1" d="M4.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,148.7c-0.8-0.2-0.8,3,0,2.8C130.1,151.7,130.1,148.5,129.3,148.7z"/>
		<path class="st1" d="M129,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,148.7c-0.8-0.2-0.8,3,0,2.8C128.2,151.7,128.2,148.5,127.3,148.7z"/>
		<path class="st1" d="M127,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,148.7c-0.8-0.2-0.8,3,0,2.8C126.2,151.7,126.2,148.5,125.3,148.7z"/>
		<path class="st1" d="M125.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,148.7c-0.8-0.2-0.8,3,0,2.8C124.2,151.7,124.2,148.5,123.4,148.7z"/>
		<path class="st1" d="M123.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,148.7c-0.8-0.2-0.8,3,0,2.8C122.2,151.7,122.2,148.5,121.4,148.7z"/>
		<path class="st1" d="M121.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,148.7c-0.8-0.2-0.8,3,0,2.8C120.2,151.7,120.2,148.5,119.4,148.7z"/>
		<path class="st1" d="M119.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,148.7c-0.8-0.2-0.8,3,0,2.8C118.2,151.7,118.2,148.5,117.4,148.7z"/>
		<path class="st1" d="M117.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,148.7c-0.8-0.2-0.8,3,0,2.8C116.2,151.7,116.2,148.5,115.4,148.7z"/>
		<path class="st1" d="M115.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,148.7c-0.8-0.2-0.8,3,0,2.8C114.3,151.7,114.3,148.5,113.4,148.7z"/>
		<path class="st1" d="M113.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,148.7c-0.8-0.2-0.8,3,0,2.8C112.3,151.7,112.3,148.5,111.4,148.7z"/>
		<path class="st1" d="M111.1,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,148.7c-0.8-0.2-0.8,3,0,2.8C110.3,151.7,110.3,148.5,109.4,148.7z"/>
		<path class="st1" d="M109.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,148.7c-0.8-0.2-0.8,3,0,2.8C108.3,151.7,108.3,148.5,107.5,148.7z"/>
		<path class="st1" d="M107.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,148.7c-0.8-0.2-0.8,3,0,2.8C106.3,151.7,106.3,148.5,105.5,148.7z"/>
		<path class="st1" d="M105.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,148.7c-0.8-0.2-0.8,3,0,2.8C104.3,151.7,104.3,148.5,103.5,148.7z"/>
		<path class="st1" d="M103.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,148.7c-0.8-0.2-0.8,3,0,2.8C102.3,151.7,102.3,148.5,101.5,148.7z"/>
		<path class="st1" d="M101.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,148.7c-0.8-0.2-0.8,3,0,2.8C100.3,151.7,100.3,148.5,99.5,148.7z"/>
		<path class="st1" d="M99.2,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,148.7c-0.8-0.2-0.8,3,0,2.8C98.5,151.7,98.5,148.5,97.6,148.7z"/>
		<path class="st1" d="M97.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,148.7c-0.8-0.2-0.8,3,0,2.8C96.5,151.7,96.5,148.5,95.7,148.7z"/>
		<path class="st1" d="M95.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,148.7c-0.8-0.2-0.8,3,0,2.8C94.5,151.7,94.5,148.5,93.7,148.7z"/>
		<path class="st1" d="M93.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,148.7c-0.8-0.2-0.8,3,0,2.8C92.5,151.7,92.5,148.5,91.7,148.7z"/>
		<path class="st1" d="M91.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,148.7c-0.8-0.2-0.8,3,0,2.8C90.5,151.7,90.5,148.5,89.7,148.7z"/>
		<path class="st1" d="M89.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,148.7c-0.8-0.2-0.8,3,0,2.8C88.5,151.7,88.5,148.5,87.7,148.7z"/>
		<path class="st1" d="M87.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,148.7c-0.8-0.2-0.8,3,0,2.8C86.5,151.7,86.5,148.5,85.7,148.7z"/>
		<path class="st1" d="M85.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,148.7c-0.8-0.2-0.8,3,0,2.8C84.6,151.7,84.6,148.5,83.7,148.7z"/>
		<path class="st1" d="M83.4,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,148.7c-0.8-0.2-0.8,3,0,2.8C82.6,151.7,82.6,148.5,81.7,148.7z"/>
		<path class="st1" d="M81.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,148.7c-0.8-0.2-0.8,3,0,2.8C80.6,151.7,80.6,148.5,79.8,148.7z"/>
		<path class="st1" d="M79.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,148.7c-0.8-0.2-0.8,3,0,2.8C78.6,151.7,78.6,148.5,77.8,148.7z"/>
		<path class="st1" d="M77.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,148.7c-0.8-0.2-0.8,3,0,2.8C76.6,151.7,76.6,148.5,75.8,148.7z"/>
		<path class="st1" d="M75.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,148.7c-0.8-0.2-0.8,3,0,2.8C74.6,151.7,74.6,148.5,73.8,148.7z"/>
		<path class="st1" d="M73.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,148.7c-0.8-0.2-0.8,3,0,2.8C72.6,151.7,72.6,148.5,71.8,148.7z"/>
		<path class="st1" d="M71.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,148.7c-0.8-0.2-0.8,3,0,2.8C70.7,151.7,70.7,148.5,69.8,148.7z"/>
		<path class="st1" d="M69.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,148.7c-0.8-0.2-0.8,3,0,2.8C68.7,151.7,68.7,148.5,67.8,148.7z"/>
		<path class="st1" d="M67.5,149.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,149.6c-0.8-0.2-0.8,3,0,2.8C65.8,152.6,65.8,149.4,65,149.6z"/>
		<path class="st1" d="M64.7,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,149.6c-0.8-0.2-0.8,3,0,2.8C63.8,152.6,63.8,149.4,63,149.6z"/>
		<path class="st1" d="M62.7,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,149.6c-0.8-0.2-0.8,3,0,2.8C61.8,152.6,61.8,149.4,61,149.6z"/>
		<path class="st1" d="M60.7,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,149.6c-0.8-0.2-0.8,3,0,2.8C59.8,152.6,59.8,149.4,59,149.6z"/>
		<path class="st1" d="M58.7,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,149.6c-0.8-0.2-0.8,3,0,2.8C57.9,152.6,57.9,149.4,57,149.6z"/>
		<path class="st1" d="M56.7,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,149.6c-0.8-0.2-0.8,3,0,2.8C55.9,152.6,55.9,149.4,55,149.6z"/>
		<path class="st1" d="M54.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,149.6c-0.8-0.2-0.8,3,0,2.8C53.9,152.6,53.9,149.4,53.1,149.6z"/>
		<path class="st1" d="M52.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,149.6c-0.8-0.2-0.8,3,0,2.8C51.9,152.6,51.9,149.4,51.1,149.6z"/>
		<path class="st1" d="M50.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,149.6c-0.8-0.2-0.8,3,0,2.8C49.9,152.6,49.9,149.4,49.1,149.6z"/>
		<path class="st1" d="M48.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,149.6c-0.8-0.2-0.8,3,0,2.8C47.9,152.6,47.9,149.4,47.1,149.6z"/>
		<path class="st1" d="M46.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,149.6c-0.8-0.2-0.8,3,0,2.8C45.9,152.6,45.9,149.4,45.1,149.6z"/>
		<path class="st1" d="M44.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,149.6c-0.8-0.2-0.8,3,0,2.8C43.9,152.6,43.9,149.4,43.1,149.6z"/>
		<path class="st1" d="M42.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,149.6c-0.8-0.2-0.8,3,0,2.8C42,152.6,42,149.4,41.1,149.6z"/>
		<path class="st1" d="M40.8,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,149.6c-0.8-0.2-0.8,3,0,2.8C40,152.6,40,149.4,39.1,149.6z"/>
		<path class="st1" d="M38.9,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,149.6c-0.8-0.2-0.8,3,0,2.8C38,152.6,38,149.4,37.2,149.6z"/>
		<path class="st1" d="M36.9,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,149.6c-0.8-0.2-0.8,3,0,2.8C36,152.6,36,149.4,35.2,149.6z"/>
		<path class="st1" d="M34.9,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,149.6c-0.8-0.2-0.8,3,0,2.8C34.1,152.6,34.1,149.4,33.3,149.6z"/>
		<path class="st1" d="M33,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,149.6c-0.8-0.2-0.8,3,0,2.8C32.1,152.6,32.1,149.4,31.3,149.6z"/>
		<path class="st1" d="M31,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,149.6c-0.8-0.2-0.8,3,0,2.8C30.1,152.6,30.2,149.4,29.3,149.6z"/>
		<path class="st1" d="M29,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,149.6c-0.8-0.2-0.8,3,0,2.8C28.2,152.6,28.2,149.4,27.3,149.6z"/>
		<path class="st1" d="M27,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,149.6c-0.8-0.2-0.8,3,0,2.8C26.2,152.6,26.2,149.4,25.3,149.6z"/>
		<path class="st1" d="M25.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,149.6c-0.8-0.2-0.8,3,0,2.8C24.2,152.6,24.2,149.4,23.4,149.6z"/>
		<path class="st1" d="M23.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,149.6c-0.8-0.2-0.8,3,0,2.8C22.2,152.6,22.2,149.4,21.4,149.6z"/>
		<path class="st1" d="M21.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,149.6c-0.8-0.2-0.8,3,0,2.8C20.2,152.6,20.2,149.4,19.4,149.6z"/>
		<path class="st1" d="M19.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,149.6c-0.8-0.2-0.8,3,0,2.8C18.2,152.6,18.2,149.4,17.4,149.6z"/>
		<path class="st1" d="M17.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,149.6c-0.8-0.2-0.8,3,0,2.8C16.2,152.6,16.2,149.4,15.4,149.6z"/>
		<path class="st1" d="M15.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,149.6c-0.8-0.2-0.8,3,0,2.8C14.3,152.6,14.3,149.4,13.4,149.6z"/>
		<path class="st1" d="M13.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,149.6c-0.8-0.2-0.8,3,0,2.8C12.3,152.6,12.3,149.4,11.4,149.6z"/>
		<path class="st1" d="M11.1,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,149.6c-0.8-0.2-0.8,3,0,2.8C10.3,152.6,10.3,149.4,9.5,149.6z"/>
		<path class="st1" d="M9.2,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,149.6c-0.8-0.2-0.8,3,0,2.8C8.3,152.6,8.3,149.4,7.5,149.6z"/>
		<path class="st1" d="M7.2,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,149.6c-0.8-0.2-0.8,3,0,2.8C6.3,152.6,6.3,149.4,5.5,149.6z"/>
		<path class="st2" d="M5.2,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,149.6c-0.8-0.2-0.8,3,0,2.8C4.3,152.6,4.3,149.4,3.5,149.6z"/>
		<path class="st2" d="M3.2,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,149.6c-0.8-0.2-0.8,3,0,2.8C79.7,152.6,79.7,149.4,78.8,149.6z"/>
		<path class="st0" d="M78.5,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,149.6c-0.8-0.2-0.8,3,0,2.8C77.7,152.6,77.7,149.4,76.9,149.6z"/>
		<path class="st0" d="M76.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,149.6c-0.8-0.2-0.8,3,0,2.8C75.7,152.6,75.7,149.4,74.9,149.6z"/>
		<path class="st0" d="M74.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,149.6c-0.8-0.2-0.8,3,0,2.8C73.7,152.6,73.7,149.4,72.9,149.6z"/>
		<path class="st0" d="M72.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M86.8,149.6c-0.8-0.2-0.8,3,0,2.8C87.6,152.6,87.6,149.4,86.8,149.6z"/>
		<path class="st1" d="M86.5,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.7,149.6c-0.8-0.2-0.8,3,0,2.8C83.6,152.6,83.6,149.4,82.7,149.6z"/>
		<path class="st0" d="M82.5,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,149.6c-0.8-0.2-0.8,3,0,2.8C81.6,152.6,81.6,149.4,80.8,149.6z"/>
		<path class="st0" d="M80.5,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,149.6c-0.8-0.2-0.8,3,0,2.8C69.7,152.6,69.7,149.4,68.9,149.6z"/>
		<path class="st1" d="M68.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,149.6c-0.8-0.2-0.8,3,0,2.8C67.7,152.6,67.7,149.4,66.9,149.6z"/>
		<path class="st1" d="M66.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,149.6c-0.8-0.2-0.8,3,0,2.8C71.7,152.6,71.7,149.4,70.9,149.6z"/>
		<path class="st0" d="M70.6,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M84.7,149.6c-0.8-0.2-0.8,3,0,2.8C85.6,152.6,85.6,149.4,84.7,149.6z"/>
		<path class="st1" d="M84.4,150.5c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,149.5c-0.8-0.2-0.8,3,0,2.8C129.2,152.5,129.2,149.3,128.4,149.5z"/>
		<path class="st1" d="M128.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,149.5c-0.8-0.2-0.8,3,0,2.8C127.2,152.5,127.2,149.3,126.4,149.5z"/>
		<path class="st1" d="M126.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,149.5c-0.8-0.2-0.8,3,0,2.8C125.3,152.5,125.3,149.3,124.4,149.5z"/>
		<path class="st1" d="M124.1,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,149.5c-0.8-0.2-0.8,3,0,2.8C123.3,152.5,123.3,149.3,122.4,149.5z"/>
		<path class="st1" d="M122.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,149.5c-0.8-0.2-0.8,3,0,2.8C121.3,152.5,121.3,149.3,120.5,149.5z"/>
		<path class="st1" d="M120.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,149.5c-0.8-0.2-0.8,3,0,2.8C119.3,152.5,119.3,149.3,118.5,149.5z"/>
		<path class="st1" d="M118.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,149.5c-0.8-0.2-0.8,3,0,2.8C117.3,152.5,117.3,149.3,116.5,149.5z"/>
		<path class="st1" d="M116.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,149.5c-0.8-0.2-0.8,3,0,2.8C115.3,152.5,115.3,149.3,114.5,149.5z"/>
		<path class="st1" d="M114.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,149.5c-0.8-0.2-0.8,3,0,2.8C113.3,152.5,113.3,149.3,112.5,149.5z"/>
		<path class="st1" d="M112.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,149.5c-0.8-0.2-0.8,3,0,2.8C111.3,152.5,111.3,149.3,110.5,149.5z"/>
		<path class="st1" d="M110.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,149.5c-0.8-0.2-0.8,3,0,2.8C109.4,152.5,109.4,149.3,108.5,149.5z"/>
		<path class="st1" d="M108.2,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,149.5c-0.8-0.2-0.8,3,0,2.8C107.4,152.5,107.4,149.3,106.5,149.5z"/>
		<path class="st1" d="M106.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,149.5c-0.8-0.2-0.8,3,0,2.8C105.4,152.5,105.4,149.3,104.6,149.5z"/>
		<path class="st1" d="M104.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,149.5c-0.8-0.2-0.8,3,0,2.8C103.4,152.5,103.4,149.3,102.6,149.5z"/>
		<path class="st1" d="M102.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,149.5c-0.8-0.2-0.8,3,0,2.8C101.4,152.5,101.4,149.3,100.6,149.5z"/>
		<path class="st1" d="M100.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,149.5c-0.8-0.2-0.8,3,0,2.8C99.4,152.5,99.4,149.3,98.6,149.5z"/>
		<path class="st1" d="M98.3,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,149.5c-0.8-0.2-0.8,3,0,2.8C97.5,152.5,97.6,149.3,96.7,149.5z"/>
		<path class="st1" d="M96.4,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,149.5c-0.8-0.2-0.8,3,0,2.8C95.6,152.5,95.6,149.3,94.7,149.5z"/>
		<path class="st1" d="M94.4,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,149.5c-0.8-0.2-0.8,3,0,2.8C93.6,152.5,93.6,149.3,92.7,149.5z"/>
		<path class="st1" d="M92.5,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,149.5c-0.8-0.2-0.8,3,0,2.8C91.6,152.5,91.6,149.3,90.8,149.5z"/>
		<path class="st1" d="M90.5,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,149.5c-0.8-0.2-0.8,3,0,2.8C89.6,152.5,89.6,149.3,88.8,149.5z"/>
		<path class="st1" d="M88.5,150.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65.9,202.3c-0.8-0.2-0.8,3,0,2.8C66.7,205.3,66.7,202.1,65.9,202.3z"/>
		<path class="st1" d="M65.6,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,202.3c-0.8-0.2-0.8,3,0,2.8C64.7,205.3,64.7,202.1,63.9,202.3z"/>
		<path class="st1" d="M63.6,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,202.3c-0.8-0.2-0.8,3,0,2.8C62.7,205.3,62.8,202.1,61.9,202.3z"/>
		<path class="st1" d="M61.6,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,202.3c-0.8-0.2-0.8,3,0,2.8C60.8,205.3,60.8,202.1,59.9,202.3z"/>
		<path class="st1" d="M59.6,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,202.3c-0.8-0.2-0.8,3,0,2.8C58.8,205.3,58.8,202.1,57.9,202.3z"/>
		<path class="st1" d="M57.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,202.3c-0.8-0.2-0.8,3,0,2.8C56.8,205.3,56.8,202.1,56,202.3z"/>
		<path class="st1" d="M55.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,202.3c-0.8-0.2-0.8,3,0,2.8C54.8,205.3,54.8,202.1,54,202.3z"/>
		<path class="st1" d="M53.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,202.3c-0.8-0.2-0.8,3,0,2.8C52.8,205.3,52.8,202.1,52,202.3z"/>
		<path class="st1" d="M51.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,202.3c-0.8-0.2-0.8,3,0,2.8C50.8,205.3,50.8,202.1,50,202.3z"/>
		<path class="st1" d="M49.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,202.3c-0.8-0.2-0.8,3,0,2.8C48.8,205.3,48.8,202.1,48,202.3z"/>
		<path class="st1" d="M47.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,202.3c-0.8-0.2-0.8,3,0,2.8C46.9,205.3,46.9,202.1,46,202.3z"/>
		<path class="st1" d="M45.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,202.3c-0.8-0.2-0.8,3,0,2.8C44.9,205.3,44.9,202.1,44,202.3z"/>
		<path class="st1" d="M43.7,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,202.3c-0.8-0.2-0.8,3,0,2.8C42.9,205.3,42.9,202.1,42.1,202.3z"/>
		<path class="st1" d="M41.8,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,202.3c-0.8-0.2-0.8,3,0,2.8C40.9,205.3,40.9,202.1,40.1,202.3z"/>
		<path class="st1" d="M39.8,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,202.3c-0.8-0.2-0.8,3,0,2.8C38.9,205.3,38.9,202.1,38.1,202.3z"/>
		<path class="st1" d="M37.8,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,202.3c-0.8-0.2-0.8,3,0,2.8C36.9,205.3,36.9,202.1,36.1,202.3z"/>
		<path class="st1" d="M35.8,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,202.3c-0.8-0.2-0.8,3,0,2.8C35,205.3,35,202.1,34.2,202.3z"/>
		<path class="st1" d="M33.9,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,202.3c-0.8-0.2-0.8,3,0,2.8C33.1,205.3,33.1,202.1,32.2,202.3z"/>
		<path class="st1" d="M31.9,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,202.3c-0.8-0.2-0.8,3,0,2.8C31.1,205.3,31.1,202.1,30.2,202.3z"/>
		<path class="st1" d="M30,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,202.3c-0.8-0.2-0.8,3,0,2.8C29.1,205.3,29.1,202.1,28.3,202.3z"/>
		<path class="st1" d="M28,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,202.3c-0.8-0.2-0.8,3,0,2.8C27.1,205.3,27.1,202.1,26.3,202.3z"/>
		<path class="st1" d="M26,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,202.3c-0.8-0.2-0.8,3,0,2.8C25.1,205.3,25.1,202.1,24.3,202.3z"/>
		<path class="st1" d="M24,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,202.3c-0.8-0.2-0.8,3,0,2.8C23.1,205.3,23.1,202.1,22.3,202.3z"/>
		<path class="st1" d="M22,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,202.3c-0.8-0.2-0.8,3,0,2.8C21.1,205.3,21.1,202.1,20.3,202.3z"/>
		<path class="st1" d="M20,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,202.3c-0.8-0.2-0.8,3,0,2.8C19.1,205.3,19.1,202.1,18.3,202.3z"/>
		<path class="st1" d="M18,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,202.3c-0.8-0.2-0.8,3,0,2.8C17.2,205.3,17.2,202.1,16.3,202.3z"/>
		<path class="st1" d="M16,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,202.3c-0.8-0.2-0.8,3,0,2.8C15.2,205.3,15.2,202.1,14.3,202.3z"/>
		<path class="st1" d="M14.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,202.3c-0.8-0.2-0.8,3,0,2.8C13.2,205.3,13.2,202.1,12.4,202.3z"/>
		<path class="st1" d="M12.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,202.3c-0.8-0.2-0.8,3,0,2.8C11.2,205.3,11.2,202.1,10.4,202.3z"/>
		<path class="st1" d="M10.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,202.3c-0.8-0.2-0.8,3,0,2.8C9.2,205.3,9.2,202.1,8.4,202.3z"/>
		<path class="st1" d="M8.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,202.3c-0.8-0.2-0.8,3,0,2.8C7.2,205.3,7.2,202.1,6.4,202.3z"/>
		<path class="st1" d="M6.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,202.3c-0.8-0.2-0.8,3,0,2.8C5.2,205.3,5.2,202.1,4.4,202.3z"/>
		<path class="st1" d="M4.1,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,202.3c-0.8-0.2-0.8,3,0,2.8C74.6,205.3,74.6,202.1,73.8,202.3z"/>
		<path class="st1" d="M73.5,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,202.3c-0.8-0.2-0.8,3,0,2.8C72.6,205.3,72.6,202.1,71.8,202.3z"/>
		<path class="st1" d="M71.5,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,202.3c-0.8-0.2-0.8,3,0,2.8C70.7,205.3,70.7,202.1,69.8,202.3z"/>
		<path class="st1" d="M69.5,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,202.3c-0.8-0.2-0.8,3,0,2.8C68.7,205.3,68.7,202.1,67.8,202.3z"/>
		<path class="st1" d="M67.5,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M75.8,202.3c-0.8-0.2-0.8,3,0,2.8C76.6,205.3,76.6,202.1,75.8,202.3z"/>
		<path class="st1" d="M75.5,203.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,203.3c-0.8-0.2-0.8,3,0,2.8C65.8,206.3,65.8,203.2,65,203.3z"/>
		<path class="st1" d="M64.7,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,203.3c-0.8-0.2-0.8,3,0,2.8C63.8,206.3,63.8,203.2,63,203.3z"/>
		<path class="st1" d="M62.7,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,203.3c-0.8-0.2-0.8,3,0,2.8C61.8,206.3,61.8,203.2,61,203.3z"/>
		<path class="st1" d="M60.7,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,203.3c-0.8-0.2-0.8,3,0,2.8C59.8,206.3,59.8,203.2,59,203.3z"/>
		<path class="st1" d="M58.7,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,203.3c-0.8-0.2-0.8,3,0,2.8C57.9,206.3,57.9,203.2,57,203.3z"/>
		<path class="st1" d="M56.7,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,203.3c-0.8-0.2-0.8,3,0,2.8C55.9,206.3,55.9,203.2,55,203.3z"/>
		<path class="st1" d="M54.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,203.3c-0.8-0.2-0.8,3,0,2.8C53.9,206.3,53.9,203.2,53.1,203.3z"/>
		<path class="st1" d="M52.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,203.3c-0.8-0.2-0.8,3,0,2.8C51.9,206.3,51.9,203.2,51.1,203.3z"/>
		<path class="st1" d="M50.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,203.3c-0.8-0.2-0.8,3,0,2.8C49.9,206.3,49.9,203.2,49.1,203.3z"/>
		<path class="st1" d="M48.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,203.3c-0.8-0.2-0.8,3,0,2.8C47.9,206.3,47.9,203.2,47.1,203.3z"/>
		<path class="st1" d="M46.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,203.3c-0.8-0.2-0.8,3,0,2.8C45.9,206.3,45.9,203.2,45.1,203.3z"/>
		<path class="st1" d="M44.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,203.3c-0.8-0.2-0.8,3,0,2.8C43.9,206.3,43.9,203.2,43.1,203.3z"/>
		<path class="st1" d="M42.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,203.3c-0.8-0.2-0.8,3,0,2.8C42,206.3,42,203.2,41.1,203.3z"/>
		<path class="st1" d="M40.8,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,203.3c-0.8-0.2-0.8,3,0,2.8C40,206.3,40,203.2,39.1,203.3z"/>
		<path class="st1" d="M38.9,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,203.3c-0.8-0.2-0.8,3,0,2.8C38,206.3,38,203.2,37.2,203.3z"/>
		<path class="st1" d="M36.9,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,203.3c-0.8-0.2-0.8,3,0,2.8C36,206.3,36,203.2,35.2,203.3z"/>
		<path class="st1" d="M34.9,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,203.3c-0.8-0.2-0.8,3,0,2.8C34.1,206.3,34.1,203.2,33.3,203.3z"/>
		<path class="st1" d="M33,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,203.3c-0.8-0.2-0.8,3,0,2.8C32.1,206.3,32.1,203.2,31.3,203.3z"/>
		<path class="st1" d="M31,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,203.3c-0.8-0.2-0.8,3,0,2.8C30.1,206.3,30.2,203.2,29.3,203.3z"/>
		<path class="st1" d="M29,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,203.3c-0.8-0.2-0.8,3,0,2.8C28.2,206.3,28.2,203.2,27.3,203.3z"/>
		<path class="st1" d="M27,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,203.3c-0.8-0.2-0.8,3,0,2.8C26.2,206.3,26.2,203.2,25.3,203.3z"/>
		<path class="st1" d="M25.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,203.3c-0.8-0.2-0.8,3,0,2.8C24.2,206.3,24.2,203.2,23.4,203.3z"/>
		<path class="st1" d="M23.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,203.3c-0.8-0.2-0.8,3,0,2.8C22.2,206.3,22.2,203.2,21.4,203.3z"/>
		<path class="st1" d="M21.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,203.3c-0.8-0.2-0.8,3,0,2.8C20.2,206.3,20.2,203.2,19.4,203.3z"/>
		<path class="st1" d="M19.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,203.3c-0.8-0.2-0.8,3,0,2.8C18.2,206.3,18.2,203.2,17.4,203.3z"/>
		<path class="st1" d="M17.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,203.3c-0.8-0.2-0.8,3,0,2.8C16.2,206.3,16.2,203.2,15.4,203.3z"/>
		<path class="st1" d="M15.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,203.3c-0.8-0.2-0.8,3,0,2.8C14.3,206.3,14.3,203.2,13.4,203.3z"/>
		<path class="st1" d="M13.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,203.3c-0.8-0.2-0.8,3,0,2.8C12.3,206.3,12.3,203.2,11.4,203.3z"/>
		<path class="st1" d="M11.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,203.3c-0.8-0.2-0.8,3,0,2.8C10.3,206.3,10.3,203.2,9.5,203.3z"/>
		<path class="st1" d="M9.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,203.3c-0.8-0.2-0.8,3,0,2.8C8.3,206.3,8.3,203.2,7.5,203.3z"/>
		<path class="st1" d="M7.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,203.3c-0.8-0.2-0.8,3,0,2.8C6.3,206.3,6.3,203.2,5.5,203.3z"/>
		<path class="st1" d="M5.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,203.3c-0.8-0.2-0.8,3,0,2.8C4.3,206.3,4.3,203.2,3.5,203.3z"/>
		<path class="st1" d="M3.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,203.3c-0.8-0.2-0.8,3,0,2.8C129.2,206.3,129.2,203.2,128.4,203.3z"/>
		<path class="st1" d="M128.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,203.3c-0.8-0.2-0.8,3,0,2.8C127.2,206.3,127.2,203.2,126.4,203.3z"/>
		<path class="st1" d="M126.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,203.3c-0.8-0.2-0.8,3,0,2.8C125.3,206.3,125.3,203.2,124.4,203.3z"/>
		<path class="st1" d="M124.1,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,203.3c-0.8-0.2-0.8,3,0,2.8C123.3,206.3,123.3,203.2,122.4,203.3z"/>
		<path class="st1" d="M122.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,203.3c-0.8-0.2-0.8,3,0,2.8C121.3,206.3,121.3,203.2,120.5,203.3z"/>
		<path class="st1" d="M120.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,203.3c-0.8-0.2-0.8,3,0,2.8C119.3,206.3,119.3,203.2,118.5,203.3z"/>
		<path class="st1" d="M118.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,203.3c-0.8-0.2-0.8,3,0,2.8C117.3,206.3,117.3,203.2,116.5,203.3z"/>
		<path class="st1" d="M116.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,203.3c-0.8-0.2-0.8,3,0,2.8C115.3,206.3,115.3,203.2,114.5,203.3z"/>
		<path class="st1" d="M114.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,203.3c-0.8-0.2-0.8,3,0,2.8C113.3,206.3,113.3,203.2,112.5,203.3z"/>
		<path class="st1" d="M112.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,203.3c-0.8-0.2-0.8,3,0,2.8C111.3,206.3,111.3,203.2,110.5,203.3z"/>
		<path class="st1" d="M110.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,203.3c-0.8-0.2-0.8,3,0,2.8C109.4,206.3,109.4,203.2,108.5,203.3z"/>
		<path class="st1" d="M108.2,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,203.3c-0.8-0.2-0.8,3,0,2.8C107.4,206.3,107.4,203.2,106.5,203.3z"/>
		<path class="st1" d="M106.3,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,203.3c-0.8-0.2-0.8,3,0,2.8C105.4,206.3,105.4,203.2,104.6,203.3z"/>
		<path class="st1" d="M104.3,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,203.3c-0.8-0.2-0.8,3,0,2.8C103.4,206.3,103.4,203.2,102.6,203.3z"/>
		<path class="st1" d="M102.3,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,203.3c-0.8-0.2-0.8,3,0,2.8C101.4,206.3,101.4,203.2,100.6,203.3z"/>
		<path class="st1" d="M100.3,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,203.3c-0.8-0.2-0.8,3,0,2.8C99.4,206.3,99.4,203.2,98.6,203.3z"/>
		<path class="st1" d="M98.3,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,203.3c-0.8-0.2-0.8,3,0,2.8C97.5,206.3,97.6,203.2,96.7,203.3z"/>
		<path class="st1" d="M96.4,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,203.3c-0.8-0.2-0.8,3,0,2.8C95.6,206.3,95.6,203.2,94.7,203.3z"/>
		<path class="st1" d="M94.4,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,203.3c-0.8-0.2-0.8,3,0,2.8C93.6,206.3,93.6,203.2,92.7,203.3z"/>
		<path class="st1" d="M92.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,203.3c-0.8-0.2-0.8,3,0,2.8C91.6,206.3,91.6,203.2,90.8,203.3z"/>
		<path class="st1" d="M90.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,203.3c-0.8-0.2-0.8,3,0,2.8C89.6,206.3,89.6,203.2,88.8,203.3z"/>
		<path class="st1" d="M88.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,203.3c-0.8-0.2-0.8,3,0,2.8C87.6,206.3,87.6,203.2,86.8,203.3z"/>
		<path class="st1" d="M86.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,203.3c-0.8-0.2-0.8,3,0,2.8C85.6,206.3,85.6,203.2,84.8,203.3z"/>
		<path class="st1" d="M84.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,203.3c-0.8-0.2-0.8,3,0,2.8C83.6,206.3,83.6,203.2,82.8,203.3z"/>
		<path class="st1" d="M82.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,203.3c-0.8-0.2-0.8,3,0,2.8C81.7,206.3,81.7,203.2,80.8,203.3z"/>
		<path class="st1" d="M80.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,203.3c-0.8-0.2-0.8,3,0,2.8C79.7,206.3,79.7,203.2,78.8,203.3z"/>
		<path class="st1" d="M78.5,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,203.3c-0.8-0.2-0.8,3,0,2.8C77.7,206.3,77.7,203.2,76.9,203.3z"/>
		<path class="st1" d="M76.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,203.3c-0.8-0.2-0.8,3,0,2.8C75.7,206.3,75.7,203.2,74.9,203.3z"/>
		<path class="st1" d="M74.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,203.3c-0.8-0.2-0.8,3,0,2.8C73.7,206.3,73.7,203.2,72.9,203.3z"/>
		<path class="st1" d="M72.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,203.3c-0.8-0.2-0.8,3,0,2.8C71.7,206.3,71.7,203.2,70.9,203.3z"/>
		<path class="st1" d="M70.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,203.3c-0.8-0.2-0.8,3,0,2.8C69.7,206.3,69.7,203.2,68.9,203.3z"/>
		<path class="st1" d="M68.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,203.3c-0.8-0.2-0.8,3,0,2.8C67.7,206.3,67.7,203.2,66.9,203.3z"/>
		<path class="st1" d="M66.6,204.2c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,204.1c-0.8-0.2-0.8,3,0,2.8C66.7,207.1,66.7,203.9,65.9,204.1z"/>
		<path class="st1" d="M65.6,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,204.1c-0.8-0.2-0.8,3,0,2.8C64.7,207.1,64.7,203.9,63.9,204.1z"/>
		<path class="st1" d="M63.6,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,204.1c-0.8-0.2-0.8,3,0,2.8C62.7,207.1,62.8,203.9,61.9,204.1z"/>
		<path class="st1" d="M61.6,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,204.1c-0.8-0.2-0.8,3,0,2.8C60.8,207.1,60.8,203.9,59.9,204.1z"/>
		<path class="st1" d="M59.6,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,204.1c-0.8-0.2-0.8,3,0,2.8C58.8,207.1,58.8,203.9,57.9,204.1z"/>
		<path class="st1" d="M57.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,204.1c-0.8-0.2-0.8,3,0,2.8C56.8,207.1,56.8,203.9,56,204.1z"/>
		<path class="st1" d="M55.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,204.1c-0.8-0.2-0.8,3,0,2.8C54.8,207.1,54.8,203.9,54,204.1z"/>
		<path class="st1" d="M53.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,204.1c-0.8-0.2-0.8,3,0,2.8C52.8,207.1,52.8,203.9,52,204.1z"/>
		<path class="st1" d="M51.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,204.1c-0.8-0.2-0.8,3,0,2.8C50.8,207.1,50.8,203.9,50,204.1z"/>
		<path class="st1" d="M49.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,204.1c-0.8-0.2-0.8,3,0,2.8C48.8,207.1,48.8,203.9,48,204.1z"/>
		<path class="st1" d="M47.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,204.1c-0.8-0.2-0.8,3,0,2.8C46.9,207.1,46.9,203.9,46,204.1z"/>
		<path class="st1" d="M45.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,204.1c-0.8-0.2-0.8,3,0,2.8C44.9,207.1,44.9,203.9,44,204.1z"/>
		<path class="st1" d="M43.7,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,204.1c-0.8-0.2-0.8,3,0,2.8C42.9,207.1,42.9,203.9,42.1,204.1z"/>
		<path class="st1" d="M41.8,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,204.1c-0.8-0.2-0.8,3,0,2.8C40.9,207.1,40.9,203.9,40.1,204.1z"/>
		<path class="st1" d="M39.8,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,204.1c-0.8-0.2-0.8,3,0,2.8C38.9,207.1,38.9,203.9,38.1,204.1z"/>
		<path class="st1" d="M37.8,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,204.1c-0.8-0.2-0.8,3,0,2.8C36.9,207.1,36.9,203.9,36.1,204.1z"/>
		<path class="st1" d="M35.8,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,204.1c-0.8-0.2-0.8,3,0,2.8C35,207.1,35,203.9,34.2,204.1z"/>
		<path class="st1" d="M33.9,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,204.1c-0.8-0.2-0.8,3,0,2.8C33.1,207.1,33.1,203.9,32.2,204.1z"/>
		<path class="st1" d="M31.9,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,204.1c-0.8-0.2-0.8,3,0,2.8C31.1,207.1,31.1,203.9,30.2,204.1z"/>
		<path class="st1" d="M30,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,204.1c-0.8-0.2-0.8,3,0,2.8C29.1,207.1,29.1,203.9,28.3,204.1z"/>
		<path class="st1" d="M28,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,204.1c-0.8-0.2-0.8,3,0,2.8C27.1,207.1,27.1,203.9,26.3,204.1z"/>
		<path class="st1" d="M26,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,204.1c-0.8-0.2-0.8,3,0,2.8C25.1,207.1,25.1,203.9,24.3,204.1z"/>
		<path class="st1" d="M24,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,204.1c-0.8-0.2-0.8,3,0,2.8C23.1,207.1,23.1,203.9,22.3,204.1z"/>
		<path class="st1" d="M22,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,204.1c-0.8-0.2-0.8,3,0,2.8C21.1,207.1,21.1,203.9,20.3,204.1z"/>
		<path class="st1" d="M20,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,204.1c-0.8-0.2-0.8,3,0,2.8C19.1,207.1,19.1,203.9,18.3,204.1z"/>
		<path class="st1" d="M18,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,204.1c-0.8-0.2-0.8,3,0,2.8C17.2,207.1,17.2,203.9,16.3,204.1z"/>
		<path class="st1" d="M16,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,204.1c-0.8-0.2-0.8,3,0,2.8C15.2,207.1,15.2,203.9,14.3,204.1z"/>
		<path class="st1" d="M14.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,204.1c-0.8-0.2-0.8,3,0,2.8C13.2,207.1,13.2,203.9,12.4,204.1z"/>
		<path class="st1" d="M12.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,204.1c-0.8-0.2-0.8,3,0,2.8C11.2,207.1,11.2,203.9,10.4,204.1z"/>
		<path class="st1" d="M10.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,204.1c-0.8-0.2-0.8,3,0,2.8C9.2,207.1,9.2,203.9,8.4,204.1z"/>
		<path class="st1" d="M8.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,204.1c-0.8-0.2-0.8,3,0,2.8C7.2,207.1,7.2,203.9,6.4,204.1z"/>
		<path class="st1" d="M6.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,204.1c-0.8-0.2-0.8,3,0,2.8C5.2,207.1,5.2,203.9,4.4,204.1z"/>
		<path class="st1" d="M4.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,204.1c-0.8-0.2-0.8,3,0,2.8C130.1,207.1,130.1,203.9,129.3,204.1z"/>
		<path class="st1" d="M129,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,204.1c-0.8-0.2-0.8,3,0,2.8C128.2,207.1,128.2,203.9,127.3,204.1z"/>
		<path class="st1" d="M127,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,204.1c-0.8-0.2-0.8,3,0,2.8C126.2,207.1,126.2,203.9,125.3,204.1z"/>
		<path class="st1" d="M125.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,204.1c-0.8-0.2-0.8,3,0,2.8C124.2,207.1,124.2,203.9,123.4,204.1z"/>
		<path class="st1" d="M123.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,204.1c-0.8-0.2-0.8,3,0,2.8C122.2,207.1,122.2,203.9,121.4,204.1z"/>
		<path class="st1" d="M121.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,204.1c-0.8-0.2-0.8,3,0,2.8C120.2,207.1,120.2,203.9,119.4,204.1z"/>
		<path class="st1" d="M119.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,204.1c-0.8-0.2-0.8,3,0,2.8C118.2,207.1,118.2,203.9,117.4,204.1z"/>
		<path class="st1" d="M117.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,204.1c-0.8-0.2-0.8,3,0,2.8C116.2,207.1,116.2,203.9,115.4,204.1z"/>
		<path class="st1" d="M115.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,204.1c-0.8-0.2-0.8,3,0,2.8C114.3,207.1,114.3,203.9,113.4,204.1z"/>
		<path class="st1" d="M113.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,204.1c-0.8-0.2-0.8,3,0,2.8C112.3,207.1,112.3,203.9,111.4,204.1z"/>
		<path class="st1" d="M111.1,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,204.1c-0.8-0.2-0.8,3,0,2.8C110.3,207.1,110.3,203.9,109.4,204.1z"/>
		<path class="st1" d="M109.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,204.1c-0.8-0.2-0.8,3,0,2.8C108.3,207.1,108.3,203.9,107.5,204.1z"/>
		<path class="st1" d="M107.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,204.1c-0.8-0.2-0.8,3,0,2.8C106.3,207.1,106.3,203.9,105.5,204.1z"/>
		<path class="st1" d="M105.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,204.1c-0.8-0.2-0.8,3,0,2.8C104.3,207.1,104.3,203.9,103.5,204.1z"/>
		<path class="st1" d="M103.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,204.1c-0.8-0.2-0.8,3,0,2.8C102.3,207.1,102.3,203.9,101.5,204.1z"/>
		<path class="st1" d="M101.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,204.1c-0.8-0.2-0.8,3,0,2.8C100.3,207.1,100.3,203.9,99.5,204.1z"/>
		<path class="st1" d="M99.2,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,204.1c-0.8-0.2-0.8,3,0,2.8C98.5,207.1,98.5,203.9,97.6,204.1z"/>
		<path class="st1" d="M97.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,204.1c-0.8-0.2-0.8,3,0,2.8C96.5,207.1,96.5,203.9,95.7,204.1z"/>
		<path class="st1" d="M95.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,204.1c-0.8-0.2-0.8,3,0,2.8C94.5,207.1,94.5,203.9,93.7,204.1z"/>
		<path class="st1" d="M93.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,204.1c-0.8-0.2-0.8,3,0,2.8C92.5,207.1,92.5,203.9,91.7,204.1z"/>
		<path class="st1" d="M91.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,204.1c-0.8-0.2-0.8,3,0,2.8C90.5,207.1,90.5,203.9,89.7,204.1z"/>
		<path class="st1" d="M89.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,204.1c-0.8-0.2-0.8,3,0,2.8C88.5,207.1,88.5,203.9,87.7,204.1z"/>
		<path class="st1" d="M87.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,204.1c-0.8-0.2-0.8,3,0,2.8C86.5,207.1,86.5,203.9,85.7,204.1z"/>
		<path class="st1" d="M85.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,204.1c-0.8-0.2-0.8,3,0,2.8C84.6,207.1,84.6,203.9,83.7,204.1z"/>
		<path class="st1" d="M83.4,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,204.1c-0.8-0.2-0.8,3,0,2.8C82.6,207.1,82.6,203.9,81.7,204.1z"/>
		<path class="st1" d="M81.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,204.1c-0.8-0.2-0.8,3,0,2.8C80.6,207.1,80.6,203.9,79.8,204.1z"/>
		<path class="st1" d="M79.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,204.1c-0.8-0.2-0.8,3,0,2.8C78.6,207.1,78.6,203.9,77.8,204.1z"/>
		<path class="st1" d="M77.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,204.1c-0.8-0.2-0.8,3,0,2.8C76.6,207.1,76.6,203.9,75.8,204.1z"/>
		<path class="st1" d="M75.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,204.1c-0.8-0.2-0.8,3,0,2.8C74.6,207.1,74.6,203.9,73.8,204.1z"/>
		<path class="st1" d="M73.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,204.1c-0.8-0.2-0.8,3,0,2.8C72.6,207.1,72.6,203.9,71.8,204.1z"/>
		<path class="st1" d="M71.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,204.1c-0.8-0.2-0.8,3,0,2.8C70.7,207.1,70.7,203.9,69.8,204.1z"/>
		<path class="st1" d="M69.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,204.1c-0.8-0.2-0.8,3,0,2.8C68.7,207.1,68.7,203.9,67.8,204.1z"/>
		<path class="st1" d="M67.5,205c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,205c-0.8-0.2-0.8,3,0,2.8C65.8,208,65.8,204.9,65,205z"/>
		<path class="st1" d="M64.7,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,205c-0.8-0.2-0.8,3,0,2.8C63.8,208,63.8,204.9,63,205z"/>
		<path class="st1" d="M62.7,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,205c-0.8-0.2-0.8,3,0,2.8C61.8,208,61.8,204.9,61,205z"/>
		<path class="st1" d="M60.7,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,205c-0.8-0.2-0.8,3,0,2.8C59.8,208,59.8,204.9,59,205z"/>
		<path class="st1" d="M58.7,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,205c-0.8-0.2-0.8,3,0,2.8C57.9,208,57.9,204.9,57,205z"/>
		<path class="st1" d="M56.7,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,205c-0.8-0.2-0.8,3,0,2.8C55.9,208,55.9,204.9,55,205z"/>
		<path class="st1" d="M54.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,205c-0.8-0.2-0.8,3,0,2.8C53.9,208,53.9,204.9,53.1,205z"/>
		<path class="st1" d="M52.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,205c-0.8-0.2-0.8,3,0,2.8C51.9,208,51.9,204.9,51.1,205z"/>
		<path class="st1" d="M50.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,205c-0.8-0.2-0.8,3,0,2.8C49.9,208,49.9,204.9,49.1,205z"/>
		<path class="st1" d="M48.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,205c-0.8-0.2-0.8,3,0,2.8C47.9,208,47.9,204.9,47.1,205z"/>
		<path class="st1" d="M46.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,205c-0.8-0.2-0.8,3,0,2.8C45.9,208,45.9,204.9,45.1,205z"/>
		<path class="st1" d="M44.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,205c-0.8-0.2-0.8,3,0,2.8C43.9,208,43.9,204.9,43.1,205z"/>
		<path class="st1" d="M42.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,205c-0.8-0.2-0.8,3,0,2.8C42,208,42,204.9,41.1,205z"/>
		<path class="st1" d="M40.8,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,205c-0.8-0.2-0.8,3,0,2.8C40,208,40,204.9,39.1,205z"/>
		<path class="st1" d="M38.9,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,205c-0.8-0.2-0.8,3,0,2.8C38,208,38,204.9,37.2,205z"/>
		<path class="st1" d="M36.9,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,205c-0.8-0.2-0.8,3,0,2.8C36,208,36,204.9,35.2,205z"/>
		<path class="st1" d="M34.9,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,205c-0.8-0.2-0.8,3,0,2.8C34.1,208,34.1,204.9,33.3,205z"/>
		<path class="st1" d="M33,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,205c-0.8-0.2-0.8,3,0,2.8C32.1,208,32.1,204.9,31.3,205z"/>
		<path class="st1" d="M31,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,205c-0.8-0.2-0.8,3,0,2.8C30.1,208,30.2,204.9,29.3,205z"/>
		<path class="st1" d="M29,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,205c-0.8-0.2-0.8,3,0,2.8C28.2,208,28.2,204.9,27.3,205z"/>
		<path class="st1" d="M27,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,205c-0.8-0.2-0.8,3,0,2.8C26.2,208,26.2,204.9,25.3,205z"/>
		<path class="st1" d="M25.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,205c-0.8-0.2-0.8,3,0,2.8C24.2,208,24.2,204.9,23.4,205z"/>
		<path class="st1" d="M23.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,205c-0.8-0.2-0.8,3,0,2.8C22.2,208,22.2,204.9,21.4,205z"/>
		<path class="st1" d="M21.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,205c-0.8-0.2-0.8,3,0,2.8C20.2,208,20.2,204.9,19.4,205z"/>
		<path class="st1" d="M19.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,205c-0.8-0.2-0.8,3,0,2.8C18.2,208,18.2,204.9,17.4,205z"/>
		<path class="st1" d="M17.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,205c-0.8-0.2-0.8,3,0,2.8C16.2,208,16.2,204.9,15.4,205z"/>
		<path class="st1" d="M15.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,205c-0.8-0.2-0.8,3,0,2.8C14.3,208,14.3,204.9,13.4,205z"/>
		<path class="st1" d="M13.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,205c-0.8-0.2-0.8,3,0,2.8C12.3,208,12.3,204.9,11.4,205z"/>
		<path class="st1" d="M11.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,205c-0.8-0.2-0.8,3,0,2.8C10.3,208,10.3,204.9,9.5,205z"/>
		<path class="st1" d="M9.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,205c-0.8-0.2-0.8,3,0,2.8C8.3,208,8.3,204.9,7.5,205z"/>
		<path class="st1" d="M7.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,205c-0.8-0.2-0.8,3,0,2.8C6.3,208,6.3,204.9,5.5,205z"/>
		<path class="st1" d="M5.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,205c-0.8-0.2-0.8,3,0,2.8C4.3,208,4.3,204.9,3.5,205z"/>
		<path class="st2" d="M3.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,205c-0.8-0.2-0.8,3,0,2.8C129.2,208,129.2,204.9,128.4,205z"/>
		<path class="st1" d="M128.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,205c-0.8-0.2-0.8,3,0,2.8C127.2,208,127.2,204.9,126.4,205z"/>
		<path class="st1" d="M126.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,205c-0.8-0.2-0.8,3,0,2.8C125.3,208,125.3,204.9,124.4,205z"/>
		<path class="st1" d="M124.1,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,205c-0.8-0.2-0.8,3,0,2.8C123.3,208,123.3,204.9,122.4,205z"/>
		<path class="st1" d="M122.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,205c-0.8-0.2-0.8,3,0,2.8C121.3,208,121.3,204.9,120.5,205z"/>
		<path class="st1" d="M120.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,205c-0.8-0.2-0.8,3,0,2.8C119.3,208,119.3,204.9,118.5,205z"/>
		<path class="st1" d="M118.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,205c-0.8-0.2-0.8,3,0,2.8C117.3,208,117.3,204.9,116.5,205z"/>
		<path class="st1" d="M116.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,205c-0.8-0.2-0.8,3,0,2.8C115.3,208,115.3,204.9,114.5,205z"/>
		<path class="st1" d="M114.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,205c-0.8-0.2-0.8,3,0,2.8C113.3,208,113.3,204.9,112.5,205z"/>
		<path class="st1" d="M112.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,205c-0.8-0.2-0.8,3,0,2.8C111.3,208,111.3,204.9,110.5,205z"/>
		<path class="st1" d="M110.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,205c-0.8-0.2-0.8,3,0,2.8C109.4,208,109.4,204.9,108.5,205z"/>
		<path class="st1" d="M108.2,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,205c-0.8-0.2-0.8,3,0,2.8C107.4,208,107.4,204.9,106.5,205z"/>
		<path class="st1" d="M106.3,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,205c-0.8-0.2-0.8,3,0,2.8C105.4,208,105.4,204.9,104.6,205z"/>
		<path class="st1" d="M104.3,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,205c-0.8-0.2-0.8,3,0,2.8C103.4,208,103.4,204.9,102.6,205z"/>
		<path class="st1" d="M102.3,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,205c-0.8-0.2-0.8,3,0,2.8C101.4,208,101.4,204.9,100.6,205z"/>
		<path class="st1" d="M100.3,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,205c-0.8-0.2-0.8,3,0,2.8C99.4,208,99.4,204.9,98.6,205z"/>
		<path class="st1" d="M98.3,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,205c-0.8-0.2-0.8,3,0,2.8C97.5,208,97.6,204.9,96.7,205z"/>
		<path class="st1" d="M96.4,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,205c-0.8-0.2-0.8,3,0,2.8C95.6,208,95.6,204.9,94.7,205z"/>
		<path class="st1" d="M94.4,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,205c-0.8-0.2-0.8,3,0,2.8C93.6,208,93.6,204.9,92.7,205z"/>
		<path class="st1" d="M92.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,205c-0.8-0.2-0.8,3,0,2.8C91.6,208,91.6,204.9,90.8,205z"/>
		<path class="st1" d="M90.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,205c-0.8-0.2-0.8,3,0,2.8C89.6,208,89.6,204.9,88.8,205z"/>
		<path class="st1" d="M88.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,205c-0.8-0.2-0.8,3,0,2.8C87.6,208,87.6,204.9,86.8,205z"/>
		<path class="st1" d="M86.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,205c-0.8-0.2-0.8,3,0,2.8C85.6,208,85.6,204.9,84.8,205z"/>
		<path class="st1" d="M84.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,205c-0.8-0.2-0.8,3,0,2.8C83.6,208,83.6,204.9,82.8,205z"/>
		<path class="st1" d="M82.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,205c-0.8-0.2-0.8,3,0,2.8C81.7,208,81.7,204.9,80.8,205z"/>
		<path class="st1" d="M80.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,205c-0.8-0.2-0.8,3,0,2.8C79.7,208,79.7,204.9,78.8,205z"/>
		<path class="st1" d="M78.5,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,205c-0.8-0.2-0.8,3,0,2.8C77.7,208,77.7,204.9,76.9,205z"/>
		<path class="st1" d="M76.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,205c-0.8-0.2-0.8,3,0,2.8C75.7,208,75.7,204.9,74.9,205z"/>
		<path class="st1" d="M74.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,205c-0.8-0.2-0.8,3,0,2.8C73.7,208,73.7,204.9,72.9,205z"/>
		<path class="st1" d="M72.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,205c-0.8-0.2-0.8,3,0,2.8C71.7,208,71.7,204.9,70.9,205z"/>
		<path class="st1" d="M70.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,205c-0.8-0.2-0.8,3,0,2.8C69.7,208,69.7,204.9,68.9,205z"/>
		<path class="st1" d="M68.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,205c-0.8-0.2-0.8,3,0,2.8C67.7,208,67.7,204.9,66.9,205z"/>
		<path class="st1" d="M66.6,205.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st1" d="M28.3,165.7c-0.8-0.2-0.8,3,0,2.8C29.1,168.7,29.1,165.6,28.3,165.7z"/>
		<path class="st1" d="M28,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M24.3,165.7c-0.8-0.2-0.8,3,0,2.8C25.1,168.7,25.1,165.6,24.3,165.7z"/>
		<path class="st1" d="M24,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M22.3,165.7c-0.8-0.2-0.8,3,0,2.8C23.1,168.7,23.1,165.6,22.3,165.7z"/>
		<path class="st1" d="M22,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M20.3,165.7c-0.8-0.2-0.8,3,0,2.8C21.1,168.7,21.1,165.6,20.3,165.7z"/>
		<path class="st1" d="M20,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M18.3,165.7c-0.8-0.2-0.8,3,0,2.8C19.1,168.7,19.1,165.6,18.3,165.7z"/>
		<path class="st1" d="M18,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M16.3,165.7c-0.8-0.2-0.8,3,0,2.8C17.2,168.7,17.2,165.6,16.3,165.7z"/>
		<path class="st1" d="M16,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M14.3,165.7c-0.8-0.2-0.8,3,0,2.8C15.2,168.7,15.2,165.6,14.3,165.7z"/>
		<path class="st1" d="M14.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M12.4,165.7c-0.8-0.2-0.8,3,0,2.8C13.2,168.7,13.2,165.6,12.4,165.7z"/>
		<path class="st1" d="M12.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M10.4,165.7c-0.8-0.2-0.8,3,0,2.8C11.2,168.7,11.2,165.6,10.4,165.7z"/>
		<path class="st1" d="M10.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M8.4,165.7c-0.8-0.2-0.8,3,0,2.8C9.2,168.7,9.2,165.6,8.4,165.7z"/>
		<path class="st1" d="M8.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M6.4,165.7c-0.8-0.2-0.8,3,0,2.8C7.2,168.7,7.2,165.6,6.4,165.7z"/>
		<path class="st1" d="M6.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M4.4,165.7c-0.8-0.2-0.8,3,0,2.8C5.2,168.7,5.2,165.6,4.4,165.7z"/>
		<path class="st1" d="M4.1,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st1" d="M26.3,165.7c-0.8-0.2-0.8,3,0,2.8C27.1,168.7,27.1,165.6,26.3,165.7z"/>
		<path class="st1" d="M26,166.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,166.5c-0.8-0.2-0.8,3,0,2.8C65.8,169.5,65.8,166.3,65,166.5z"/>
		<path class="st1" d="M64.7,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,166.5c-0.8-0.2-0.8,3,0,2.8C63.8,169.5,63.8,166.3,63,166.5z"/>
		<path class="st1" d="M62.7,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,166.5c-0.8-0.2-0.8,3,0,2.8C61.8,169.5,61.8,166.3,61,166.5z"/>
		<path class="st1" d="M60.7,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,166.5c-0.8-0.2-0.8,3,0,2.8C59.8,169.5,59.8,166.3,59,166.5z"/>
		<path class="st1" d="M58.7,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,166.5c-0.8-0.2-0.8,3,0,2.8C57.9,169.5,57.9,166.3,57,166.5z"/>
		<path class="st1" d="M56.7,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,166.5c-0.8-0.2-0.8,3,0,2.8C55.9,169.5,55.9,166.3,55,166.5z"/>
		<path class="st1" d="M54.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,166.5c-0.8-0.2-0.8,3,0,2.8C53.9,169.5,53.9,166.3,53.1,166.5z"/>
		<path class="st1" d="M52.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,166.5c-0.8-0.2-0.8,3,0,2.8C51.9,169.5,51.9,166.3,51.1,166.5z"/>
		<path class="st1" d="M50.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,166.5c-0.8-0.2-0.8,3,0,2.8C49.9,169.5,49.9,166.3,49.1,166.5z"/>
		<path class="st1" d="M48.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,166.5c-0.8-0.2-0.8,3,0,2.8C47.9,169.5,47.9,166.3,47.1,166.5z"/>
		<path class="st1" d="M46.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,166.5c-0.8-0.2-0.8,3,0,2.8C45.9,169.5,45.9,166.3,45.1,166.5z"/>
		<path class="st1" d="M44.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,166.5c-0.8-0.2-0.8,3,0,2.8C43.9,169.5,43.9,166.3,43.1,166.5z"/>
		<path class="st1" d="M42.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,166.5c-0.8-0.2-0.8,3,0,2.8C42,169.5,42,166.3,41.1,166.5z"/>
		<path class="st1" d="M40.8,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,166.5c-0.8-0.2-0.8,3,0,2.8C40,169.5,40,166.3,39.1,166.5z"/>
		<path class="st1" d="M38.9,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,166.5c-0.8-0.2-0.8,3,0,2.8C38,169.5,38,166.3,37.2,166.5z"/>
		<path class="st1" d="M36.9,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,166.5c-0.8-0.2-0.8,3,0,2.8C36,169.5,36,166.3,35.2,166.5z"/>
		<path class="st1" d="M34.9,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,166.5c-0.8-0.2-0.8,3,0,2.8C34.1,169.5,34.1,166.3,33.3,166.5z"/>
		<path class="st1" d="M33,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,166.5c-0.8-0.2-0.8,3,0,2.8C32.1,169.5,32.1,166.3,31.3,166.5z"/>
		<path class="st1" d="M31,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,166.5c-0.8-0.2-0.8,3,0,2.8C30.1,169.5,30.2,166.3,29.3,166.5z"/>
		<path class="st1" d="M29,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,166.5c-0.8-0.2-0.8,3,0,2.8C28.2,169.5,28.2,166.3,27.3,166.5z"/>
		<path class="st1" d="M27,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,166.5c-0.8-0.2-0.8,3,0,2.8C26.2,169.5,26.2,166.3,25.3,166.5z"/>
		<path class="st1" d="M25.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,166.5c-0.8-0.2-0.8,3,0,2.8C24.2,169.5,24.2,166.3,23.4,166.5z"/>
		<path class="st1" d="M23.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,166.5c-0.8-0.2-0.8,3,0,2.8C22.2,169.5,22.2,166.3,21.4,166.5z"/>
		<path class="st1" d="M21.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,166.5c-0.8-0.2-0.8,3,0,2.8C20.2,169.5,20.2,166.3,19.4,166.5z"/>
		<path class="st1" d="M19.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,166.5c-0.8-0.2-0.8,3,0,2.8C18.2,169.5,18.2,166.3,17.4,166.5z"/>
		<path class="st1" d="M17.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,166.5c-0.8-0.2-0.8,3,0,2.8C16.2,169.5,16.2,166.3,15.4,166.5z"/>
		<path class="st1" d="M15.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,166.5c-0.8-0.2-0.8,3,0,2.8C14.3,169.5,14.3,166.3,13.4,166.5z"/>
		<path class="st1" d="M13.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,166.5c-0.8-0.2-0.8,3,0,2.8C12.3,169.5,12.3,166.3,11.4,166.5z"/>
		<path class="st1" d="M11.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,166.5c-0.8-0.2-0.8,3,0,2.8C10.3,169.5,10.3,166.3,9.5,166.5z"/>
		<path class="st1" d="M9.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,166.5c-0.8-0.2-0.8,3,0,2.8C8.3,169.5,8.3,166.3,7.5,166.5z"/>
		<path class="st1" d="M7.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,166.5c-0.8-0.2-0.8,3,0,2.8C6.3,169.5,6.3,166.3,5.5,166.5z"/>
		<path class="st1" d="M5.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,166.5c-0.8-0.2-0.8,3,0,2.8C4.3,169.5,4.3,166.3,3.5,166.5z"/>
		<path class="st1" d="M3.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,166.5c-0.8-0.2-0.8,3,0,2.8C129.2,169.5,129.2,166.3,128.4,166.5z"/>
		<path class="st1" d="M128.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,166.5c-0.8-0.2-0.8,3,0,2.8C127.2,169.5,127.2,166.3,126.4,166.5z"/>
		<path class="st1" d="M126.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,166.5c-0.8-0.2-0.8,3,0,2.8C125.3,169.5,125.3,166.3,124.4,166.5z"/>
		<path class="st1" d="M124.1,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,166.5c-0.8-0.2-0.8,3,0,2.8C123.3,169.5,123.3,166.3,122.4,166.5z"/>
		<path class="st1" d="M122.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,166.5c-0.8-0.2-0.8,3,0,2.8C121.3,169.5,121.3,166.3,120.5,166.5z"/>
		<path class="st1" d="M120.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,166.5c-0.8-0.2-0.8,3,0,2.8C119.3,169.5,119.3,166.3,118.5,166.5z"/>
		<path class="st1" d="M118.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,166.5c-0.8-0.2-0.8,3,0,2.8C117.3,169.5,117.3,166.3,116.5,166.5z"/>
		<path class="st1" d="M116.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,166.5c-0.8-0.2-0.8,3,0,2.8C115.3,169.5,115.3,166.3,114.5,166.5z"/>
		<path class="st1" d="M114.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,166.5c-0.8-0.2-0.8,3,0,2.8C113.3,169.5,113.3,166.3,112.5,166.5z"/>
		<path class="st1" d="M112.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,166.5c-0.8-0.2-0.8,3,0,2.8C111.3,169.5,111.3,166.3,110.5,166.5z"/>
		<path class="st1" d="M110.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,166.5c-0.8-0.2-0.8,3,0,2.8C109.4,169.5,109.4,166.3,108.5,166.5z"/>
		<path class="st1" d="M108.2,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,166.5c-0.8-0.2-0.8,3,0,2.8C107.4,169.5,107.4,166.3,106.5,166.5z"/>
		<path class="st1" d="M106.3,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,166.5c-0.8-0.2-0.8,3,0,2.8C105.4,169.5,105.4,166.3,104.6,166.5z"/>
		<path class="st1" d="M104.3,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,166.5c-0.8-0.2-0.8,3,0,2.8C103.4,169.5,103.4,166.3,102.6,166.5z"/>
		<path class="st1" d="M102.3,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,166.5c-0.8-0.2-0.8,3,0,2.8C101.4,169.5,101.4,166.3,100.6,166.5z"/>
		<path class="st1" d="M100.3,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,166.5c-0.8-0.2-0.8,3,0,2.8C99.4,169.5,99.4,166.3,98.6,166.5z"/>
		<path class="st1" d="M98.3,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,166.5c-0.8-0.2-0.8,3,0,2.8C97.5,169.5,97.6,166.3,96.7,166.5z"/>
		<path class="st1" d="M96.4,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,166.5c-0.8-0.2-0.8,3,0,2.8C95.6,169.5,95.6,166.3,94.7,166.5z"/>
		<path class="st1" d="M94.4,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,166.5c-0.8-0.2-0.8,3,0,2.8C93.6,169.5,93.6,166.3,92.7,166.5z"/>
		<path class="st1" d="M92.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,166.5c-0.8-0.2-0.8,3,0,2.8C91.6,169.5,91.6,166.3,90.8,166.5z"/>
		<path class="st1" d="M90.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,166.5c-0.8-0.2-0.8,3,0,2.8C89.6,169.5,89.6,166.3,88.8,166.5z"/>
		<path class="st1" d="M88.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,166.5c-0.8-0.2-0.8,3,0,2.8C87.6,169.5,87.6,166.3,86.8,166.5z"/>
		<path class="st1" d="M86.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,166.5c-0.8-0.2-0.8,3,0,2.8C85.6,169.5,85.6,166.3,84.8,166.5z"/>
		<path class="st1" d="M84.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,166.5c-0.8-0.2-0.8,3,0,2.8C83.6,169.5,83.6,166.3,82.8,166.5z"/>
		<path class="st1" d="M82.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,166.5c-0.8-0.2-0.8,3,0,2.8C81.7,169.5,81.7,166.3,80.8,166.5z"/>
		<path class="st1" d="M80.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,166.5c-0.8-0.2-0.8,3,0,2.8C79.7,169.5,79.7,166.3,78.8,166.5z"/>
		<path class="st1" d="M78.5,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,166.5c-0.8-0.2-0.8,3,0,2.8C77.7,169.5,77.7,166.3,76.9,166.5z"/>
		<path class="st1" d="M76.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,166.5c-0.8-0.2-0.8,3,0,2.8C75.7,169.5,75.7,166.3,74.9,166.5z"/>
		<path class="st1" d="M74.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,166.5c-0.8-0.2-0.8,3,0,2.8C73.7,169.5,73.7,166.3,72.9,166.5z"/>
		<path class="st1" d="M72.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,166.5c-0.8-0.2-0.8,3,0,2.8C71.7,169.5,71.7,166.3,70.9,166.5z"/>
		<path class="st1" d="M70.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,166.5c-0.8-0.2-0.8,3,0,2.8C69.7,169.5,69.7,166.3,68.9,166.5z"/>
		<path class="st1" d="M68.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,166.5c-0.8-0.2-0.8,3,0,2.8C67.7,169.5,67.7,166.3,66.9,166.5z"/>
		<path class="st1" d="M66.6,167.4c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,167.2c-0.8-0.2-0.8,3,0,2.8C66.7,170.2,66.7,167.1,65.9,167.2z"/>
		<path class="st1" d="M65.6,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,167.2c-0.8-0.2-0.8,3,0,2.8C64.7,170.2,64.7,167.1,63.9,167.2z"/>
		<path class="st1" d="M63.6,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,167.2c-0.8-0.2-0.8,3,0,2.8C62.7,170.2,62.8,167.1,61.9,167.2z"/>
		<path class="st1" d="M61.6,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,167.2c-0.8-0.2-0.8,3,0,2.8C60.8,170.2,60.8,167.1,59.9,167.2z"/>
		<path class="st1" d="M59.6,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,167.2c-0.8-0.2-0.8,3,0,2.8C58.8,170.2,58.8,167.1,57.9,167.2z"/>
		<path class="st1" d="M57.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,167.2c-0.8-0.2-0.8,3,0,2.8C56.8,170.2,56.8,167.1,56,167.2z"/>
		<path class="st1" d="M55.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,167.2c-0.8-0.2-0.8,3,0,2.8C54.8,170.2,54.8,167.1,54,167.2z"/>
		<path class="st1" d="M53.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,167.2c-0.8-0.2-0.8,3,0,2.8C52.8,170.2,52.8,167.1,52,167.2z"/>
		<path class="st1" d="M51.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,167.2c-0.8-0.2-0.8,3,0,2.8C50.8,170.2,50.8,167.1,50,167.2z"/>
		<path class="st1" d="M49.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,167.2c-0.8-0.2-0.8,3,0,2.8C48.8,170.2,48.8,167.1,48,167.2z"/>
		<path class="st1" d="M47.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,167.2c-0.8-0.2-0.8,3,0,2.8C46.9,170.2,46.9,167.1,46,167.2z"/>
		<path class="st1" d="M45.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,167.2c-0.8-0.2-0.8,3,0,2.8C44.9,170.2,44.9,167.1,44,167.2z"/>
		<path class="st1" d="M43.7,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,167.2c-0.8-0.2-0.8,3,0,2.8C42.9,170.2,42.9,167.1,42.1,167.2z"/>
		<path class="st1" d="M41.8,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,167.2c-0.8-0.2-0.8,3,0,2.8C40.9,170.2,40.9,167.1,40.1,167.2z"/>
		<path class="st1" d="M39.8,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,167.2c-0.8-0.2-0.8,3,0,2.8C38.9,170.2,38.9,167.1,38.1,167.2z"/>
		<path class="st1" d="M37.8,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,167.2c-0.8-0.2-0.8,3,0,2.8C36.9,170.2,36.9,167.1,36.1,167.2z"/>
		<path class="st1" d="M35.8,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,167.2c-0.8-0.2-0.8,3,0,2.8C35,170.2,35,167.1,34.2,167.2z"/>
		<path class="st1" d="M33.9,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,167.2c-0.8-0.2-0.8,3,0,2.8C33.1,170.2,33.1,167.1,32.2,167.2z"/>
		<path class="st1" d="M31.9,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,167.2c-0.8-0.2-0.8,3,0,2.8C31.1,170.2,31.1,167.1,30.2,167.2z"/>
		<path class="st1" d="M30,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,167.2c-0.8-0.2-0.8,3,0,2.8C29.1,170.2,29.1,167.1,28.3,167.2z"/>
		<path class="st1" d="M28,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,167.2c-0.8-0.2-0.8,3,0,2.8C27.1,170.2,27.1,167.1,26.3,167.2z"/>
		<path class="st1" d="M26,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,167.2c-0.8-0.2-0.8,3,0,2.8C25.1,170.2,25.1,167.1,24.3,167.2z"/>
		<path class="st1" d="M24,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,167.2c-0.8-0.2-0.8,3,0,2.8C23.1,170.2,23.1,167.1,22.3,167.2z"/>
		<path class="st1" d="M22,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,167.2c-0.8-0.2-0.8,3,0,2.8C21.1,170.2,21.1,167.1,20.3,167.2z"/>
		<path class="st1" d="M20,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,167.2c-0.8-0.2-0.8,3,0,2.8C19.1,170.2,19.1,167.1,18.3,167.2z"/>
		<path class="st1" d="M18,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,167.2c-0.8-0.2-0.8,3,0,2.8C17.2,170.2,17.2,167.1,16.3,167.2z"/>
		<path class="st1" d="M16,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,167.2c-0.8-0.2-0.8,3,0,2.8C15.2,170.2,15.2,167.1,14.3,167.2z"/>
		<path class="st1" d="M14.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,167.2c-0.8-0.2-0.8,3,0,2.8C13.2,170.2,13.2,167.1,12.4,167.2z"/>
		<path class="st1" d="M12.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,167.2c-0.8-0.2-0.8,3,0,2.8C11.2,170.2,11.2,167.1,10.4,167.2z"/>
		<path class="st1" d="M10.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,167.2c-0.8-0.2-0.8,3,0,2.8C9.2,170.2,9.2,167.1,8.4,167.2z"/>
		<path class="st1" d="M8.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,167.2c-0.8-0.2-0.8,3,0,2.8C7.2,170.2,7.2,167.1,6.4,167.2z"/>
		<path class="st1" d="M6.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,167.2c-0.8-0.2-0.8,3,0,2.8C5.2,170.2,5.2,167.1,4.4,167.2z"/>
		<path class="st1" d="M4.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,167.2c-0.8-0.2-0.8,3,0,2.8C130.1,170.2,130.1,167.1,129.3,167.2z"/>
		<path class="st1" d="M129,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,167.2c-0.8-0.2-0.8,3,0,2.8C128.2,170.2,128.2,167.1,127.3,167.2z"/>
		<path class="st1" d="M127,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,167.2c-0.8-0.2-0.8,3,0,2.8C126.2,170.2,126.2,167.1,125.3,167.2z"/>
		<path class="st1" d="M125.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,167.2c-0.8-0.2-0.8,3,0,2.8C124.2,170.2,124.2,167.1,123.4,167.2z"/>
		<path class="st1" d="M123.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,167.2c-0.8-0.2-0.8,3,0,2.8C122.2,170.2,122.2,167.1,121.4,167.2z"/>
		<path class="st1" d="M121.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,167.2c-0.8-0.2-0.8,3,0,2.8C120.2,170.2,120.2,167.1,119.4,167.2z"/>
		<path class="st1" d="M119.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,167.2c-0.8-0.2-0.8,3,0,2.8C118.2,170.2,118.2,167.1,117.4,167.2z"/>
		<path class="st1" d="M117.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,167.2c-0.8-0.2-0.8,3,0,2.8C116.2,170.2,116.2,167.1,115.4,167.2z"/>
		<path class="st1" d="M115.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,167.2c-0.8-0.2-0.8,3,0,2.8C114.3,170.2,114.3,167.1,113.4,167.2z"/>
		<path class="st1" d="M113.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,167.2c-0.8-0.2-0.8,3,0,2.8C112.3,170.2,112.3,167.1,111.4,167.2z"/>
		<path class="st1" d="M111.1,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,167.2c-0.8-0.2-0.8,3,0,2.8C110.3,170.2,110.3,167.1,109.4,167.2z"/>
		<path class="st1" d="M109.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,167.2c-0.8-0.2-0.8,3,0,2.8C108.3,170.2,108.3,167.1,107.5,167.2z"/>
		<path class="st1" d="M107.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,167.2c-0.8-0.2-0.8,3,0,2.8C106.3,170.2,106.3,167.1,105.5,167.2z"/>
		<path class="st1" d="M105.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,167.2c-0.8-0.2-0.8,3,0,2.8C104.3,170.2,104.3,167.1,103.5,167.2z"/>
		<path class="st1" d="M103.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,167.2c-0.8-0.2-0.8,3,0,2.8C102.3,170.2,102.3,167.1,101.5,167.2z"/>
		<path class="st1" d="M101.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,167.2c-0.8-0.2-0.8,3,0,2.8C100.3,170.2,100.3,167.1,99.5,167.2z"/>
		<path class="st1" d="M99.2,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,167.2c-0.8-0.2-0.8,3,0,2.8C98.5,170.2,98.5,167.1,97.6,167.2z"/>
		<path class="st1" d="M97.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,167.2c-0.8-0.2-0.8,3,0,2.8C96.5,170.2,96.5,167.1,95.7,167.2z"/>
		<path class="st1" d="M95.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,167.2c-0.8-0.2-0.8,3,0,2.8C94.5,170.2,94.5,167.1,93.7,167.2z"/>
		<path class="st1" d="M93.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,167.2c-0.8-0.2-0.8,3,0,2.8C92.5,170.2,92.5,167.1,91.7,167.2z"/>
		<path class="st1" d="M91.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,167.2c-0.8-0.2-0.8,3,0,2.8C90.5,170.2,90.5,167.1,89.7,167.2z"/>
		<path class="st1" d="M89.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,167.2c-0.8-0.2-0.8,3,0,2.8C88.5,170.2,88.5,167.1,87.7,167.2z"/>
		<path class="st1" d="M87.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,167.2c-0.8-0.2-0.8,3,0,2.8C86.5,170.2,86.5,167.1,85.7,167.2z"/>
		<path class="st1" d="M85.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,167.2c-0.8-0.2-0.8,3,0,2.8C84.6,170.2,84.6,167.1,83.7,167.2z"/>
		<path class="st1" d="M83.4,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,167.2c-0.8-0.2-0.8,3,0,2.8C82.6,170.2,82.6,167.1,81.7,167.2z"/>
		<path class="st1" d="M81.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,167.2c-0.8-0.2-0.8,3,0,2.8C80.6,170.2,80.6,167.1,79.8,167.2z"/>
		<path class="st1" d="M79.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,167.2c-0.8-0.2-0.8,3,0,2.8C78.6,170.2,78.6,167.1,77.8,167.2z"/>
		<path class="st1" d="M77.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,167.2c-0.8-0.2-0.8,3,0,2.8C76.6,170.2,76.6,167.1,75.8,167.2z"/>
		<path class="st1" d="M75.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,167.2c-0.8-0.2-0.8,3,0,2.8C74.6,170.2,74.6,167.1,73.8,167.2z"/>
		<path class="st1" d="M73.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,167.2c-0.8-0.2-0.8,3,0,2.8C72.6,170.2,72.6,167.1,71.8,167.2z"/>
		<path class="st1" d="M71.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,167.2c-0.8-0.2-0.8,3,0,2.8C70.7,170.2,70.7,167.1,69.8,167.2z"/>
		<path class="st1" d="M69.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,167.2c-0.8-0.2-0.8,3,0,2.8C68.7,170.2,68.7,167.1,67.8,167.2z"/>
		<path class="st1" d="M67.5,168.1c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,168.2c-0.8-0.2-0.8,3,0,2.8C65.8,171.2,65.8,168,65,168.2z"/>
		<path class="st1" d="M64.7,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,168.2c-0.8-0.2-0.8,3,0,2.8C63.8,171.2,63.8,168,63,168.2z"/>
		<path class="st1" d="M62.7,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,168.2c-0.8-0.2-0.8,3,0,2.8C61.8,171.2,61.8,168,61,168.2z"/>
		<path class="st1" d="M60.7,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,168.2c-0.8-0.2-0.8,3,0,2.8C59.8,171.2,59.8,168,59,168.2z"/>
		<path class="st1" d="M58.7,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,168.2c-0.8-0.2-0.8,3,0,2.8C57.9,171.2,57.9,168,57,168.2z"/>
		<path class="st1" d="M56.7,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,168.2c-0.8-0.2-0.8,3,0,2.8C55.9,171.2,55.9,168,55,168.2z"/>
		<path class="st1" d="M54.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,168.2c-0.8-0.2-0.8,3,0,2.8C53.9,171.2,53.9,168,53.1,168.2z"/>
		<path class="st1" d="M52.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,168.2c-0.8-0.2-0.8,3,0,2.8C51.9,171.2,51.9,168,51.1,168.2z"/>
		<path class="st1" d="M50.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,168.2c-0.8-0.2-0.8,3,0,2.8C49.9,171.2,49.9,168,49.1,168.2z"/>
		<path class="st1" d="M48.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,168.2c-0.8-0.2-0.8,3,0,2.8C47.9,171.2,47.9,168,47.1,168.2z"/>
		<path class="st1" d="M46.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,168.2c-0.8-0.2-0.8,3,0,2.8C45.9,171.2,45.9,168,45.1,168.2z"/>
		<path class="st1" d="M44.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,168.2c-0.8-0.2-0.8,3,0,2.8C43.9,171.2,43.9,168,43.1,168.2z"/>
		<path class="st1" d="M42.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,168.2c-0.8-0.2-0.8,3,0,2.8C42,171.2,42,168,41.1,168.2z"/>
		<path class="st1" d="M40.8,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,168.2c-0.8-0.2-0.8,3,0,2.8C40,171.2,40,168,39.1,168.2z"/>
		<path class="st1" d="M38.9,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,168.2c-0.8-0.2-0.8,3,0,2.8C38,171.2,38,168,37.2,168.2z"/>
		<path class="st1" d="M36.9,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,168.2c-0.8-0.2-0.8,3,0,2.8C36,171.2,36,168,35.2,168.2z"/>
		<path class="st1" d="M34.9,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,168.2c-0.8-0.2-0.8,3,0,2.8C34.1,171.2,34.1,168,33.3,168.2z"/>
		<path class="st1" d="M33,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,168.2c-0.8-0.2-0.8,3,0,2.8C32.1,171.2,32.1,168,31.3,168.2z"/>
		<path class="st1" d="M31,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,168.2c-0.8-0.2-0.8,3,0,2.8C30.1,171.2,30.2,168,29.3,168.2z"/>
		<path class="st1" d="M29,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,168.2c-0.8-0.2-0.8,3,0,2.8C28.2,171.2,28.2,168,27.3,168.2z"/>
		<path class="st1" d="M27,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,168.2c-0.8-0.2-0.8,3,0,2.8C26.2,171.2,26.2,168,25.3,168.2z"/>
		<path class="st1" d="M25.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,168.2c-0.8-0.2-0.8,3,0,2.8C24.2,171.2,24.2,168,23.4,168.2z"/>
		<path class="st1" d="M23.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,168.2c-0.8-0.2-0.8,3,0,2.8C22.2,171.2,22.2,168,21.4,168.2z"/>
		<path class="st1" d="M21.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,168.2c-0.8-0.2-0.8,3,0,2.8C20.2,171.2,20.2,168,19.4,168.2z"/>
		<path class="st1" d="M19.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,168.2c-0.8-0.2-0.8,3,0,2.8C18.2,171.2,18.2,168,17.4,168.2z"/>
		<path class="st1" d="M17.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,168.2c-0.8-0.2-0.8,3,0,2.8C16.2,171.2,16.2,168,15.4,168.2z"/>
		<path class="st1" d="M15.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,168.2c-0.8-0.2-0.8,3,0,2.8C14.3,171.2,14.3,168,13.4,168.2z"/>
		<path class="st1" d="M13.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,168.2c-0.8-0.2-0.8,3,0,2.8C12.3,171.2,12.3,168,11.4,168.2z"/>
		<path class="st1" d="M11.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,168.2c-0.8-0.2-0.8,3,0,2.8C10.3,171.2,10.3,168,9.5,168.2z"/>
		<path class="st1" d="M9.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,168.2c-0.8-0.2-0.8,3,0,2.8C8.3,171.2,8.3,168,7.5,168.2z"/>
		<path class="st1" d="M7.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M5.5,168.2c-0.8-0.2-0.8,3,0,2.8C6.3,171.2,6.3,168,5.5,168.2z"/>
		<path class="st2" d="M5.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st2" d="M3.5,168.2c-0.8-0.2-0.8,3,0,2.8C4.3,171.2,4.3,168,3.5,168.2z"/>
		<path class="st2" d="M3.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,168.2c-0.8-0.2-0.8,3,0,2.8C129.2,171.2,129.2,168,128.4,168.2z"/>
		<path class="st1" d="M128.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,168.2c-0.8-0.2-0.8,3,0,2.8C127.2,171.2,127.2,168,126.4,168.2z"/>
		<path class="st1" d="M126.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,168.2c-0.8-0.2-0.8,3,0,2.8C125.3,171.2,125.3,168,124.4,168.2z"/>
		<path class="st1" d="M124.1,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,168.2c-0.8-0.2-0.8,3,0,2.8C123.3,171.2,123.3,168,122.4,168.2z"/>
		<path class="st1" d="M122.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,168.2c-0.8-0.2-0.8,3,0,2.8C121.3,171.2,121.3,168,120.5,168.2z"/>
		<path class="st1" d="M120.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,168.2c-0.8-0.2-0.8,3,0,2.8C119.3,171.2,119.3,168,118.5,168.2z"/>
		<path class="st1" d="M118.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,168.2c-0.8-0.2-0.8,3,0,2.8C117.3,171.2,117.3,168,116.5,168.2z"/>
		<path class="st1" d="M116.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,168.2c-0.8-0.2-0.8,3,0,2.8C115.3,171.2,115.3,168,114.5,168.2z"/>
		<path class="st1" d="M114.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,168.2c-0.8-0.2-0.8,3,0,2.8C113.3,171.2,113.3,168,112.5,168.2z"/>
		<path class="st1" d="M112.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,168.2c-0.8-0.2-0.8,3,0,2.8C111.3,171.2,111.3,168,110.5,168.2z"/>
		<path class="st1" d="M110.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,168.2c-0.8-0.2-0.8,3,0,2.8C109.4,171.2,109.4,168,108.5,168.2z"/>
		<path class="st1" d="M108.2,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,168.2c-0.8-0.2-0.8,3,0,2.8C107.4,171.2,107.4,168,106.5,168.2z"/>
		<path class="st1" d="M106.3,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,168.2c-0.8-0.2-0.8,3,0,2.8C105.4,171.2,105.4,168,104.6,168.2z"/>
		<path class="st1" d="M104.3,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,168.2c-0.8-0.2-0.8,3,0,2.8C103.4,171.2,103.4,168,102.6,168.2z"/>
		<path class="st1" d="M102.3,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,168.2c-0.8-0.2-0.8,3,0,2.8C101.4,171.2,101.4,168,100.6,168.2z"/>
		<path class="st1" d="M100.3,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,168.2c-0.8-0.2-0.8,3,0,2.8C99.4,171.2,99.4,168,98.6,168.2z"/>
		<path class="st1" d="M98.3,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,168.2c-0.8-0.2-0.8,3,0,2.8C97.5,171.2,97.6,168,96.7,168.2z"/>
		<path class="st1" d="M96.4,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,168.2c-0.8-0.2-0.8,3,0,2.8C95.6,171.2,95.6,168,94.7,168.2z"/>
		<path class="st1" d="M94.4,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,168.2c-0.8-0.2-0.8,3,0,2.8C93.6,171.2,93.6,168,92.7,168.2z"/>
		<path class="st1" d="M92.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,168.2c-0.8-0.2-0.8,3,0,2.8C91.6,171.2,91.6,168,90.8,168.2z"/>
		<path class="st1" d="M90.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,168.2c-0.8-0.2-0.8,3,0,2.8C89.6,171.2,89.6,168,88.8,168.2z"/>
		<path class="st1" d="M88.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,168.2c-0.8-0.2-0.8,3,0,2.8C87.6,171.2,87.6,168,86.8,168.2z"/>
		<path class="st1" d="M86.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,168.2c-0.8-0.2-0.8,3,0,2.8C85.6,171.2,85.6,168,84.8,168.2z"/>
		<path class="st1" d="M84.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,168.2c-0.8-0.2-0.8,3,0,2.8C83.6,171.2,83.6,168,82.8,168.2z"/>
		<path class="st1" d="M82.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,168.2c-0.8-0.2-0.8,3,0,2.8C81.7,171.2,81.7,168,80.8,168.2z"/>
		<path class="st1" d="M80.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,168.2c-0.8-0.2-0.8,3,0,2.8C79.7,171.2,79.7,168,78.8,168.2z"/>
		<path class="st1" d="M78.5,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,168.2c-0.8-0.2-0.8,3,0,2.8C77.7,171.2,77.7,168,76.9,168.2z"/>
		<path class="st1" d="M76.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,168.2c-0.8-0.2-0.8,3,0,2.8C75.7,171.2,75.7,168,74.9,168.2z"/>
		<path class="st1" d="M74.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,168.2c-0.8-0.2-0.8,3,0,2.8C73.7,171.2,73.7,168,72.9,168.2z"/>
		<path class="st1" d="M72.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,168.2c-0.8-0.2-0.8,3,0,2.8C71.7,171.2,71.7,168,70.9,168.2z"/>
		<path class="st1" d="M70.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,168.2c-0.8-0.2-0.8,3,0,2.8C69.7,171.2,69.7,168,68.9,168.2z"/>
		<path class="st1" d="M68.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,168.2c-0.8-0.2-0.8,3,0,2.8C67.7,171.2,67.7,168,66.9,168.2z"/>
		<path class="st1" d="M66.6,169c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,221c-0.8-0.2-0.8,3,0,2.8C65.8,224,65.8,220.8,65,221z"/>
		<path class="st1" d="M64.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,221c-0.8-0.2-0.8,3,0,2.8C63.8,224,63.8,220.8,63,221z"/>
		<path class="st1" d="M62.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,221c-0.8-0.2-0.8,3,0,2.8C61.8,224,61.8,220.8,61,221z"/>
		<path class="st1" d="M60.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,221c-0.8-0.2-0.8,3,0,2.8C59.8,224,59.8,220.8,59,221z"/>
		<path class="st1" d="M58.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,221c-0.8-0.2-0.8,3,0,2.8C57.9,224,57.9,220.8,57,221z"/>
		<path class="st1" d="M56.7,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,221c-0.8-0.2-0.8,3,0,2.8C55.9,224,55.9,220.8,55,221z"/>
		<path class="st1" d="M54.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,221c-0.8-0.2-0.8,3,0,2.8C53.9,224,53.9,220.8,53.1,221z"/>
		<path class="st1" d="M52.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,221c-0.8-0.2-0.8,3,0,2.8C51.9,224,51.9,220.8,51.1,221z"/>
		<path class="st1" d="M50.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,221c-0.8-0.2-0.8,3,0,2.8C49.9,224,49.9,220.8,49.1,221z"/>
		<path class="st1" d="M48.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,221c-0.8-0.2-0.8,3,0,2.8C47.9,224,47.9,220.8,47.1,221z"/>
		<path class="st1" d="M46.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,221c-0.8-0.2-0.8,3,0,2.8C45.9,224,45.9,220.8,45.1,221z"/>
		<path class="st1" d="M44.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,221c-0.8-0.2-0.8,3,0,2.8C43.9,224,43.9,220.8,43.1,221z"/>
		<path class="st1" d="M42.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,221c-0.8-0.2-0.8,3,0,2.8C42,224,42,220.8,41.1,221z"/>
		<path class="st1" d="M40.8,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,221c-0.8-0.2-0.8,3,0,2.8C40,224,40,220.8,39.1,221z"/>
		<path class="st1" d="M38.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,221c-0.8-0.2-0.8,3,0,2.8C38,224,38,220.8,37.2,221z"/>
		<path class="st1" d="M36.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,221c-0.8-0.2-0.8,3,0,2.8C36,224,36,220.8,35.2,221z"/>
		<path class="st1" d="M34.9,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,221c-0.8-0.2-0.8,3,0,2.8C34.1,224,34.1,220.8,33.3,221z"/>
		<path class="st1" d="M33,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,221c-0.8-0.2-0.8,3,0,2.8C32.1,224,32.1,220.8,31.3,221z"/>
		<path class="st1" d="M31,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,221c-0.8-0.2-0.8,3,0,2.8C30.1,224,30.2,220.8,29.3,221z"/>
		<path class="st1" d="M29,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,221c-0.8-0.2-0.8,3,0,2.8C28.2,224,28.2,220.8,27.3,221z"/>
		<path class="st1" d="M27,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,221c-0.8-0.2-0.8,3,0,2.8C26.2,224,26.2,220.8,25.3,221z"/>
		<path class="st1" d="M25.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,221c-0.8-0.2-0.8,3,0,2.8C24.2,224,24.2,220.8,23.4,221z"/>
		<path class="st1" d="M23.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,221c-0.8-0.2-0.8,3,0,2.8C22.2,224,22.2,220.8,21.4,221z"/>
		<path class="st1" d="M21.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,221c-0.8-0.2-0.8,3,0,2.8C20.2,224,20.2,220.8,19.4,221z"/>
		<path class="st1" d="M19.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,221c-0.8-0.2-0.8,3,0,2.8C18.2,224,18.2,220.8,17.4,221z"/>
		<path class="st1" d="M17.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,221c-0.8-0.2-0.8,3,0,2.8C16.2,224,16.2,220.8,15.4,221z"/>
		<path class="st1" d="M15.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,221c-0.8-0.2-0.8,3,0,2.8C14.3,224,14.3,220.8,13.4,221z"/>
		<path class="st1" d="M13.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,221c-0.8-0.2-0.8,3,0,2.8C12.3,224,12.3,220.8,11.4,221z"/>
		<path class="st1" d="M11.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,221c-0.8-0.2-0.8,3,0,2.8C10.3,224,10.3,220.8,9.5,221z"/>
		<path class="st1" d="M9.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,221c-0.8-0.2-0.8,3,0,2.8C8.3,224,8.3,220.8,7.5,221z"/>
		<path class="st1" d="M7.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,221c-0.8-0.2-0.8,3,0,2.8C6.3,224,6.3,220.8,5.5,221z"/>
		<path class="st1" d="M5.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,221c-0.8-0.2-0.8,3,0,2.8C4.3,224,4.3,220.8,3.5,221z"/>
		<path class="st1" d="M3.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M128.4,221c-0.8-0.2-0.8,3,0,2.8C129.2,224,129.2,220.8,128.4,221z"/>
		<path class="st1" d="M128.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M126.4,221c-0.8-0.2-0.8,3,0,2.8C127.2,224,127.2,220.8,126.4,221z"/>
		<path class="st1" d="M126.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M124.4,221c-0.8-0.2-0.8,3,0,2.8C125.3,224,125.3,220.8,124.4,221z"/>
		<path class="st1" d="M124.1,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M122.4,221c-0.8-0.2-0.8,3,0,2.8C123.3,224,123.3,220.8,122.4,221z"/>
		<path class="st1" d="M122.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M120.5,221c-0.8-0.2-0.8,3,0,2.8C121.3,224,121.3,220.8,120.5,221z"/>
		<path class="st1" d="M120.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M118.5,221c-0.8-0.2-0.8,3,0,2.8C119.3,224,119.3,220.8,118.5,221z"/>
		<path class="st1" d="M118.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M116.5,221c-0.8-0.2-0.8,3,0,2.8C117.3,224,117.3,220.8,116.5,221z"/>
		<path class="st1" d="M116.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M114.5,221c-0.8-0.2-0.8,3,0,2.8C115.3,224,115.3,220.8,114.5,221z"/>
		<path class="st1" d="M114.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M112.5,221c-0.8-0.2-0.8,3,0,2.8C113.3,224,113.3,220.8,112.5,221z"/>
		<path class="st1" d="M112.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,221c-0.8-0.2-0.8,3,0,2.8C111.3,224,111.3,220.8,110.5,221z"/>
		<path class="st1" d="M110.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,221c-0.8-0.2-0.8,3,0,2.8C109.4,224,109.4,220.8,108.5,221z"/>
		<path class="st1" d="M108.2,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,221c-0.8-0.2-0.8,3,0,2.8C107.4,224,107.4,220.8,106.5,221z"/>
		<path class="st1" d="M106.3,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,221c-0.8-0.2-0.8,3,0,2.8C105.4,224,105.4,220.8,104.6,221z"/>
		<path class="st1" d="M104.3,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,221c-0.8-0.2-0.8,3,0,2.8C103.4,224,103.4,220.8,102.6,221z"/>
		<path class="st1" d="M102.3,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,221c-0.8-0.2-0.8,3,0,2.8C101.4,224,101.4,220.8,100.6,221z"/>
		<path class="st1" d="M100.3,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,221c-0.8-0.2-0.8,3,0,2.8C99.4,224,99.4,220.8,98.6,221z"/>
		<path class="st1" d="M98.3,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,221c-0.8-0.2-0.8,3,0,2.8C97.5,224,97.6,220.8,96.7,221z"/>
		<path class="st1" d="M96.4,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,221c-0.8-0.2-0.8,3,0,2.8C95.6,224,95.6,220.8,94.7,221z"/>
		<path class="st1" d="M94.4,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,221c-0.8-0.2-0.8,3,0,2.8C93.6,224,93.6,220.8,92.7,221z"/>
		<path class="st1" d="M92.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,221c-0.8-0.2-0.8,3,0,2.8C91.6,224,91.6,220.8,90.8,221z"/>
		<path class="st1" d="M90.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,221c-0.8-0.2-0.8,3,0,2.8C89.6,224,89.6,220.8,88.8,221z"/>
		<path class="st1" d="M88.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,221c-0.8-0.2-0.8,3,0,2.8C87.6,224,87.6,220.8,86.8,221z"/>
		<path class="st1" d="M86.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,221c-0.8-0.2-0.8,3,0,2.8C85.6,224,85.6,220.8,84.8,221z"/>
		<path class="st1" d="M84.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,221c-0.8-0.2-0.8,3,0,2.8C83.6,224,83.6,220.8,82.8,221z"/>
		<path class="st1" d="M82.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,221c-0.8-0.2-0.8,3,0,2.8C81.7,224,81.7,220.8,80.8,221z"/>
		<path class="st1" d="M80.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,221c-0.8-0.2-0.8,3,0,2.8C79.7,224,79.7,220.8,78.8,221z"/>
		<path class="st1" d="M78.5,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,221c-0.8-0.2-0.8,3,0,2.8C77.7,224,77.7,220.8,76.9,221z"/>
		<path class="st1" d="M76.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,221c-0.8-0.2-0.8,3,0,2.8C75.7,224,75.7,220.8,74.9,221z"/>
		<path class="st1" d="M74.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,221c-0.8-0.2-0.8,3,0,2.8C73.7,224,73.7,220.8,72.9,221z"/>
		<path class="st1" d="M72.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,221c-0.8-0.2-0.8,3,0,2.8C71.7,224,71.7,220.8,70.9,221z"/>
		<path class="st1" d="M70.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,221c-0.8-0.2-0.8,3,0,2.8C69.7,224,69.7,220.8,68.9,221z"/>
		<path class="st1" d="M68.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,221c-0.8-0.2-0.8,3,0,2.8C67.7,224,67.7,220.8,66.9,221z"/>
		<path class="st1" d="M66.6,221.9c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65.9,221.8c-0.8-0.2-0.8,3,0,2.8C66.7,224.8,66.7,221.6,65.9,221.8z"/>
		<path class="st1" d="M65.6,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63.9,221.8c-0.8-0.2-0.8,3,0,2.8C64.7,224.8,64.7,221.6,63.9,221.8z"/>
		<path class="st1" d="M63.6,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61.9,221.8c-0.8-0.2-0.8,3,0,2.8C62.7,224.8,62.8,221.6,61.9,221.8z"/>
		<path class="st1" d="M61.6,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59.9,221.8c-0.8-0.2-0.8,3,0,2.8C60.8,224.8,60.8,221.6,59.9,221.8z"/>
		<path class="st1" d="M59.6,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57.9,221.8c-0.8-0.2-0.8,3,0,2.8C58.8,224.8,58.8,221.6,57.9,221.8z"/>
		<path class="st1" d="M57.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M56,221.8c-0.8-0.2-0.8,3,0,2.8C56.8,224.8,56.8,221.6,56,221.8z"/>
		<path class="st1" d="M55.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M54,221.8c-0.8-0.2-0.8,3,0,2.8C54.8,224.8,54.8,221.6,54,221.8z"/>
		<path class="st1" d="M53.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M52,221.8c-0.8-0.2-0.8,3,0,2.8C52.8,224.8,52.8,221.6,52,221.8z"/>
		<path class="st1" d="M51.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M50,221.8c-0.8-0.2-0.8,3,0,2.8C50.8,224.8,50.8,221.6,50,221.8z"/>
		<path class="st1" d="M49.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M48,221.8c-0.8-0.2-0.8,3,0,2.8C48.8,224.8,48.8,221.6,48,221.8z"/>
		<path class="st1" d="M47.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M46,221.8c-0.8-0.2-0.8,3,0,2.8C46.9,224.8,46.9,221.6,46,221.8z"/>
		<path class="st1" d="M45.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M44,221.8c-0.8-0.2-0.8,3,0,2.8C44.9,224.8,44.9,221.6,44,221.8z"/>
		<path class="st1" d="M43.7,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M42.1,221.8c-0.8-0.2-0.8,3,0,2.8C42.9,224.8,42.9,221.6,42.1,221.8z"/>
		<path class="st1" d="M41.8,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M40.1,221.8c-0.8-0.2-0.8,3,0,2.8C40.9,224.8,40.9,221.6,40.1,221.8z"/>
		<path class="st1" d="M39.8,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M38.1,221.8c-0.8-0.2-0.8,3,0,2.8C38.9,224.8,38.9,221.6,38.1,221.8z"/>
		<path class="st1" d="M37.8,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M36.1,221.8c-0.8-0.2-0.8,3,0,2.8C36.9,224.8,36.9,221.6,36.1,221.8z"/>
		<path class="st1" d="M35.8,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M34.2,221.8c-0.8-0.2-0.8,3,0,2.8C35,224.8,35,221.6,34.2,221.8z"/>
		<path class="st1" d="M33.9,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M32.2,221.8c-0.8-0.2-0.8,3,0,2.8C33.1,224.8,33.1,221.6,32.2,221.8z"/>
		<path class="st1" d="M31.9,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M30.2,221.8c-0.8-0.2-0.8,3,0,2.8C31.1,224.8,31.1,221.6,30.2,221.8z"/>
		<path class="st1" d="M30,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M28.3,221.8c-0.8-0.2-0.8,3,0,2.8C29.1,224.8,29.1,221.6,28.3,221.8z"/>
		<path class="st1" d="M28,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M26.3,221.8c-0.8-0.2-0.8,3,0,2.8C27.1,224.8,27.1,221.6,26.3,221.8z"/>
		<path class="st1" d="M26,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M24.3,221.8c-0.8-0.2-0.8,3,0,2.8C25.1,224.8,25.1,221.6,24.3,221.8z"/>
		<path class="st1" d="M24,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M22.3,221.8c-0.8-0.2-0.8,3,0,2.8C23.1,224.8,23.1,221.6,22.3,221.8z"/>
		<path class="st1" d="M22,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M20.3,221.8c-0.8-0.2-0.8,3,0,2.8C21.1,224.8,21.1,221.6,20.3,221.8z"/>
		<path class="st1" d="M20,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M18.3,221.8c-0.8-0.2-0.8,3,0,2.8C19.1,224.8,19.1,221.6,18.3,221.8z"/>
		<path class="st1" d="M18,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M16.3,221.8c-0.8-0.2-0.8,3,0,2.8C17.2,224.8,17.2,221.6,16.3,221.8z"/>
		<path class="st1" d="M16,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M14.3,221.8c-0.8-0.2-0.8,3,0,2.8C15.2,224.8,15.2,221.6,14.3,221.8z"/>
		<path class="st1" d="M14.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M12.4,221.8c-0.8-0.2-0.8,3,0,2.8C13.2,224.8,13.2,221.6,12.4,221.8z"/>
		<path class="st1" d="M12.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M10.4,221.8c-0.8-0.2-0.8,3,0,2.8C11.2,224.8,11.2,221.6,10.4,221.8z"/>
		<path class="st1" d="M10.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M8.4,221.8c-0.8-0.2-0.8,3,0,2.8C9.2,224.8,9.2,221.6,8.4,221.8z"/>
		<path class="st1" d="M8.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M6.4,221.8c-0.8-0.2-0.8,3,0,2.8C7.2,224.8,7.2,221.6,6.4,221.8z"/>
		<path class="st1" d="M6.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M4.4,221.8c-0.8-0.2-0.8,3,0,2.8C5.2,224.8,5.2,221.6,4.4,221.8z"/>
		<path class="st1" d="M4.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M129.3,221.8c-0.8-0.2-0.8,3,0,2.8C130.1,224.8,130.1,221.6,129.3,221.8z"/>
		<path class="st1" d="M129,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M127.3,221.8c-0.8-0.2-0.8,3,0,2.8C128.2,224.8,128.2,221.6,127.3,221.8z"/>
		<path class="st1" d="M127,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M125.3,221.8c-0.8-0.2-0.8,3,0,2.8C126.2,224.8,126.2,221.6,125.3,221.8z"/>
		<path class="st1" d="M125.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M123.4,221.8c-0.8-0.2-0.8,3,0,2.8C124.2,224.8,124.2,221.6,123.4,221.8z"/>
		<path class="st1" d="M123.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M121.4,221.8c-0.8-0.2-0.8,3,0,2.8C122.2,224.8,122.2,221.6,121.4,221.8z"/>
		<path class="st1" d="M121.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M119.4,221.8c-0.8-0.2-0.8,3,0,2.8C120.2,224.8,120.2,221.6,119.4,221.8z"/>
		<path class="st1" d="M119.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M117.4,221.8c-0.8-0.2-0.8,3,0,2.8C118.2,224.8,118.2,221.6,117.4,221.8z"/>
		<path class="st1" d="M117.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M115.4,221.8c-0.8-0.2-0.8,3,0,2.8C116.2,224.8,116.2,221.6,115.4,221.8z"/>
		<path class="st1" d="M115.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M113.4,221.8c-0.8-0.2-0.8,3,0,2.8C114.3,224.8,114.3,221.6,113.4,221.8z"/>
		<path class="st1" d="M113.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M111.4,221.8c-0.8-0.2-0.8,3,0,2.8C112.3,224.8,112.3,221.6,111.4,221.8z"/>
		<path class="st1" d="M111.1,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M109.4,221.8c-0.8-0.2-0.8,3,0,2.8C110.3,224.8,110.3,221.6,109.4,221.8z"/>
		<path class="st1" d="M109.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M107.5,221.8c-0.8-0.2-0.8,3,0,2.8C108.3,224.8,108.3,221.6,107.5,221.8z"/>
		<path class="st1" d="M107.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M105.5,221.8c-0.8-0.2-0.8,3,0,2.8C106.3,224.8,106.3,221.6,105.5,221.8z"/>
		<path class="st1" d="M105.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M103.5,221.8c-0.8-0.2-0.8,3,0,2.8C104.3,224.8,104.3,221.6,103.5,221.8z"/>
		<path class="st1" d="M103.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M101.5,221.8c-0.8-0.2-0.8,3,0,2.8C102.3,224.8,102.3,221.6,101.5,221.8z"/>
		<path class="st1" d="M101.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M99.5,221.8c-0.8-0.2-0.8,3,0,2.8C100.3,224.8,100.3,221.6,99.5,221.8z"/>
		<path class="st1" d="M99.2,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M97.6,221.8c-0.8-0.2-0.8,3,0,2.8C98.5,224.8,98.5,221.6,97.6,221.8z"/>
		<path class="st1" d="M97.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M95.7,221.8c-0.8-0.2-0.8,3,0,2.8C96.5,224.8,96.5,221.6,95.7,221.8z"/>
		<path class="st1" d="M95.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M93.7,221.8c-0.8-0.2-0.8,3,0,2.8C94.5,224.8,94.5,221.6,93.7,221.8z"/>
		<path class="st1" d="M93.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M91.7,221.8c-0.8-0.2-0.8,3,0,2.8C92.5,224.8,92.5,221.6,91.7,221.8z"/>
		<path class="st1" d="M91.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M89.7,221.8c-0.8-0.2-0.8,3,0,2.8C90.5,224.8,90.5,221.6,89.7,221.8z"/>
		<path class="st1" d="M89.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M87.7,221.8c-0.8-0.2-0.8,3,0,2.8C88.5,224.8,88.5,221.6,87.7,221.8z"/>
		<path class="st1" d="M87.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M85.7,221.8c-0.8-0.2-0.8,3,0,2.8C86.5,224.8,86.5,221.6,85.7,221.8z"/>
		<path class="st1" d="M85.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M83.7,221.8c-0.8-0.2-0.8,3,0,2.8C84.6,224.8,84.6,221.6,83.7,221.8z"/>
		<path class="st1" d="M83.4,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M81.7,221.8c-0.8-0.2-0.8,3,0,2.8C82.6,224.8,82.6,221.6,81.7,221.8z"/>
		<path class="st1" d="M81.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M79.8,221.8c-0.8-0.2-0.8,3,0,2.8C80.6,224.8,80.6,221.6,79.8,221.8z"/>
		<path class="st1" d="M79.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M77.8,221.8c-0.8-0.2-0.8,3,0,2.8C78.6,224.8,78.6,221.6,77.8,221.8z"/>
		<path class="st1" d="M77.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M75.8,221.8c-0.8-0.2-0.8,3,0,2.8C76.6,224.8,76.6,221.6,75.8,221.8z"/>
		<path class="st1" d="M75.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M73.8,221.8c-0.8-0.2-0.8,3,0,2.8C74.6,224.8,74.6,221.6,73.8,221.8z"/>
		<path class="st1" d="M73.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M71.8,221.8c-0.8-0.2-0.8,3,0,2.8C72.6,224.8,72.6,221.6,71.8,221.8z"/>
		<path class="st1" d="M71.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M69.8,221.8c-0.8-0.2-0.8,3,0,2.8C70.7,224.8,70.7,221.6,69.8,221.8z"/>
		<path class="st1" d="M69.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M67.8,221.8c-0.8-0.2-0.8,3,0,2.8C68.7,224.8,68.7,221.6,67.8,221.8z"/>
		<path class="st1" d="M67.5,222.7c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M65,222.7c-0.8-0.2-0.8,3,0,2.8C65.8,225.7,65.8,222.5,65,222.7z"/>
		<path class="st1" d="M64.7,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,222.7c-0.8-0.2-0.8,3,0,2.8C63.8,225.7,63.8,222.5,63,222.7z"/>
		<path class="st1" d="M62.7,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,222.7c-0.8-0.2-0.8,3,0,2.8C61.8,225.7,61.8,222.5,61,222.7z"/>
		<path class="st1" d="M60.7,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,222.7c-0.8-0.2-0.8,3,0,2.8C59.8,225.7,59.8,222.5,59,222.7z"/>
		<path class="st1" d="M58.7,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,222.7c-0.8-0.2-0.8,3,0,2.8C57.9,225.7,57.9,222.5,57,222.7z"/>
		<path class="st1" d="M56.7,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,222.7c-0.8-0.2-0.8,3,0,2.8C55.9,225.7,55.9,222.5,55,222.7z"/>
		<path class="st1" d="M54.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,222.7c-0.8-0.2-0.8,3,0,2.8C53.9,225.7,53.9,222.5,53.1,222.7z"/>
		<path class="st1" d="M52.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,222.7c-0.8-0.2-0.8,3,0,2.8C51.9,225.7,51.9,222.5,51.1,222.7z"/>
		<path class="st1" d="M50.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,222.7c-0.8-0.2-0.8,3,0,2.8C49.9,225.7,49.9,222.5,49.1,222.7z"/>
		<path class="st1" d="M48.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,222.7c-0.8-0.2-0.8,3,0,2.8C47.9,225.7,47.9,222.5,47.1,222.7z"/>
		<path class="st1" d="M46.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,222.7c-0.8-0.2-0.8,3,0,2.8C45.9,225.7,45.9,222.5,45.1,222.7z"/>
		<path class="st1" d="M44.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,222.7c-0.8-0.2-0.8,3,0,2.8C43.9,225.7,43.9,222.5,43.1,222.7z"/>
		<path class="st1" d="M42.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,222.7c-0.8-0.2-0.8,3,0,2.8C42,225.7,42,222.5,41.1,222.7z"/>
		<path class="st1" d="M40.8,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,222.7c-0.8-0.2-0.8,3,0,2.8C40,225.7,40,222.5,39.1,222.7z"/>
		<path class="st1" d="M38.9,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,222.7c-0.8-0.2-0.8,3,0,2.8C38,225.7,38,222.5,37.2,222.7z"/>
		<path class="st1" d="M36.9,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,222.7c-0.8-0.2-0.8,3,0,2.8C36,225.7,36,222.5,35.2,222.7z"/>
		<path class="st1" d="M34.9,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,222.7c-0.8-0.2-0.8,3,0,2.8C34.1,225.7,34.1,222.5,33.3,222.7z"/>
		<path class="st1" d="M33,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,222.7c-0.8-0.2-0.8,3,0,2.8C32.1,225.7,32.1,222.5,31.3,222.7z"/>
		<path class="st1" d="M31,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,222.7c-0.8-0.2-0.8,3,0,2.8C30.1,225.7,30.2,222.5,29.3,222.7z"/>
		<path class="st1" d="M29,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,222.7c-0.8-0.2-0.8,3,0,2.8C28.2,225.7,28.2,222.5,27.3,222.7z"/>
		<path class="st1" d="M27,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,222.7c-0.8-0.2-0.8,3,0,2.8C26.2,225.7,26.2,222.5,25.3,222.7z"/>
		<path class="st1" d="M25.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,222.7c-0.8-0.2-0.8,3,0,2.8C24.2,225.7,24.2,222.5,23.4,222.7z"/>
		<path class="st1" d="M23.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,222.7c-0.8-0.2-0.8,3,0,2.8C22.2,225.7,22.2,222.5,21.4,222.7z"/>
		<path class="st1" d="M21.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,222.7c-0.8-0.2-0.8,3,0,2.8C20.2,225.7,20.2,222.5,19.4,222.7z"/>
		<path class="st1" d="M19.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,222.7c-0.8-0.2-0.8,3,0,2.8C18.2,225.7,18.2,222.5,17.4,222.7z"/>
		<path class="st1" d="M17.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,222.7c-0.8-0.2-0.8,3,0,2.8C16.2,225.7,16.2,222.5,15.4,222.7z"/>
		<path class="st1" d="M15.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,222.7c-0.8-0.2-0.8,3,0,2.8C14.3,225.7,14.3,222.5,13.4,222.7z"/>
		<path class="st1" d="M13.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,222.7c-0.8-0.2-0.8,3,0,2.8C12.3,225.7,12.3,222.5,11.4,222.7z"/>
		<path class="st1" d="M11.1,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,222.7c-0.8-0.2-0.8,3,0,2.8C10.3,225.7,10.3,222.5,9.5,222.7z"/>
		<path class="st1" d="M9.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,222.7c-0.8-0.2-0.8,3,0,2.8C8.3,225.7,8.3,222.5,7.5,222.7z"/>
		<path class="st1" d="M7.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,222.7c-0.8-0.2-0.8,3,0,2.8C6.3,225.7,6.3,222.5,5.5,222.7z"/>
		<path class="st1" d="M5.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,222.7c-0.8-0.2-0.8,3,0,2.8C4.3,225.7,4.3,222.5,3.5,222.7z"/>
		<path class="st1" d="M3.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M110.5,222.7c-0.8-0.2-0.8,3,0,2.8C111.3,225.7,111.3,222.5,110.5,222.7z"/>
		<path class="st1" d="M110.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M108.5,222.7c-0.8-0.2-0.8,3,0,2.8C109.4,225.7,109.4,222.5,108.5,222.7z"/>
		<path class="st1" d="M108.2,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M106.5,222.7c-0.8-0.2-0.8,3,0,2.8C107.4,225.7,107.4,222.5,106.5,222.7z"/>
		<path class="st1" d="M106.3,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M104.6,222.7c-0.8-0.2-0.8,3,0,2.8C105.4,225.7,105.4,222.5,104.6,222.7z"/>
		<path class="st1" d="M104.3,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M102.6,222.7c-0.8-0.2-0.8,3,0,2.8C103.4,225.7,103.4,222.5,102.6,222.7z"/>
		<path class="st1" d="M102.3,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M100.6,222.7c-0.8-0.2-0.8,3,0,2.8C101.4,225.7,101.4,222.5,100.6,222.7z"/>
		<path class="st1" d="M100.3,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M98.6,222.7c-0.8-0.2-0.8,3,0,2.8C99.4,225.7,99.4,222.5,98.6,222.7z"/>
		<path class="st1" d="M98.3,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M96.7,222.7c-0.8-0.2-0.8,3,0,2.8C97.5,225.7,97.6,222.5,96.7,222.7z"/>
		<path class="st1" d="M96.4,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M94.7,222.7c-0.8-0.2-0.8,3,0,2.8C95.6,225.7,95.6,222.5,94.7,222.7z"/>
		<path class="st1" d="M94.4,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M92.7,222.7c-0.8-0.2-0.8,3,0,2.8C93.6,225.7,93.6,222.5,92.7,222.7z"/>
		<path class="st1" d="M92.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M90.8,222.7c-0.8-0.2-0.8,3,0,2.8C91.6,225.7,91.6,222.5,90.8,222.7z"/>
		<path class="st1" d="M90.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M88.8,222.7c-0.8-0.2-0.8,3,0,2.8C89.6,225.7,89.6,222.5,88.8,222.7z"/>
		<path class="st1" d="M88.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,222.7c-0.8-0.2-0.8,3,0,2.8C87.6,225.7,87.6,222.5,86.8,222.7z"/>
		<path class="st1" d="M86.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,222.7c-0.8-0.2-0.8,3,0,2.8C85.6,225.7,85.6,222.5,84.8,222.7z"/>
		<path class="st1" d="M84.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,222.7c-0.8-0.2-0.8,3,0,2.8C83.6,225.7,83.6,222.5,82.8,222.7z"/>
		<path class="st1" d="M82.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,222.7c-0.8-0.2-0.8,3,0,2.8C81.7,225.7,81.7,222.5,80.8,222.7z"/>
		<path class="st1" d="M80.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,222.7c-0.8-0.2-0.8,3,0,2.8C79.7,225.7,79.7,222.5,78.8,222.7z"/>
		<path class="st1" d="M78.5,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,222.7c-0.8-0.2-0.8,3,0,2.8C77.7,225.7,77.7,222.5,76.9,222.7z"/>
		<path class="st1" d="M76.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,222.7c-0.8-0.2-0.8,3,0,2.8C75.7,225.7,75.7,222.5,74.9,222.7z"/>
		<path class="st1" d="M74.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,222.7c-0.8-0.2-0.8,3,0,2.8C73.7,225.7,73.7,222.5,72.9,222.7z"/>
		<path class="st1" d="M72.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,222.7c-0.8-0.2-0.8,3,0,2.8C71.7,225.7,71.7,222.5,70.9,222.7z"/>
		<path class="st1" d="M70.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,222.7c-0.8-0.2-0.8,3,0,2.8C69.7,225.7,69.7,222.5,68.9,222.7z"/>
		<path class="st1" d="M68.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,222.7c-0.8-0.2-0.8,3,0,2.8C67.7,225.7,67.7,222.5,66.9,222.7z"/>
		<path class="st1" d="M66.6,223.6c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<g>
	<g>
		<path class="st0" d="M65,236.4c-0.8-0.2-0.8,3,0,2.8C65.8,239.4,65.8,236.2,65,236.4z"/>
		<path class="st1" d="M64.7,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M63,236.4c-0.8-0.2-0.8,3,0,2.8C63.8,239.4,63.8,236.2,63,236.4z"/>
		<path class="st1" d="M62.7,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M61,236.4c-0.8-0.2-0.8,3,0,2.8C61.8,239.4,61.8,236.2,61,236.4z"/>
		<path class="st1" d="M60.7,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M59,236.4c-0.8-0.2-0.8,3,0,2.8C59.8,239.4,59.8,236.2,59,236.4z"/>
		<path class="st1" d="M58.7,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M57,236.4c-0.8-0.2-0.8,3,0,2.8C57.9,239.4,57.9,236.2,57,236.4z"/>
		<path class="st1" d="M56.7,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M55,236.4c-0.8-0.2-0.8,3,0,2.8C55.9,239.4,55.9,236.2,55,236.4z"/>
		<path class="st1" d="M54.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M53.1,236.4c-0.8-0.2-0.8,3,0,2.8C53.9,239.4,53.9,236.2,53.1,236.4z"/>
		<path class="st1" d="M52.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M51.1,236.4c-0.8-0.2-0.8,3,0,2.8C51.9,239.4,51.9,236.2,51.1,236.4z"/>
		<path class="st1" d="M50.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M49.1,236.4c-0.8-0.2-0.8,3,0,2.8C49.9,239.4,49.9,236.2,49.1,236.4z"/>
		<path class="st1" d="M48.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M47.1,236.4c-0.8-0.2-0.8,3,0,2.8C47.9,239.4,47.9,236.2,47.1,236.4z"/>
		<path class="st1" d="M46.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M45.1,236.4c-0.8-0.2-0.8,3,0,2.8C45.9,239.4,45.9,236.2,45.1,236.4z"/>
		<path class="st1" d="M44.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M43.1,236.4c-0.8-0.2-0.8,3,0,2.8C43.9,239.4,43.9,236.2,43.1,236.4z"/>
		<path class="st1" d="M42.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M41.1,236.4c-0.8-0.2-0.8,3,0,2.8C42,239.4,42,236.2,41.1,236.4z"/>
		<path class="st1" d="M40.8,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M39.1,236.4c-0.8-0.2-0.8,3,0,2.8C40,239.4,40,236.2,39.1,236.4z"/>
		<path class="st1" d="M38.9,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M37.2,236.4c-0.8-0.2-0.8,3,0,2.8C38,239.4,38,236.2,37.2,236.4z"/>
		<path class="st1" d="M36.9,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M35.2,236.4c-0.8-0.2-0.8,3,0,2.8C36,239.4,36,236.2,35.2,236.4z"/>
		<path class="st1" d="M34.9,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M33.3,236.4c-0.8-0.2-0.8,3,0,2.8C34.1,239.4,34.1,236.2,33.3,236.4z"/>
		<path class="st1" d="M33,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M31.3,236.4c-0.8-0.2-0.8,3,0,2.8C32.1,239.4,32.1,236.2,31.3,236.4z"/>
		<path class="st1" d="M31,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M29.3,236.4c-0.8-0.2-0.8,3,0,2.8C30.1,239.4,30.2,236.2,29.3,236.4z"/>
		<path class="st1" d="M29,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M27.3,236.4c-0.8-0.2-0.8,3,0,2.8C28.2,239.4,28.2,236.2,27.3,236.4z"/>
		<path class="st1" d="M27,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M25.3,236.4c-0.8-0.2-0.8,3,0,2.8C26.2,239.4,26.2,236.2,25.3,236.4z"/>
		<path class="st1" d="M25.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M23.4,236.4c-0.8-0.2-0.8,3,0,2.8C24.2,239.4,24.2,236.2,23.4,236.4z"/>
		<path class="st1" d="M23.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M21.4,236.4c-0.8-0.2-0.8,3,0,2.8C22.2,239.4,22.2,236.2,21.4,236.4z"/>
		<path class="st1" d="M21.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M19.4,236.4c-0.8-0.2-0.8,3,0,2.8C20.2,239.4,20.2,236.2,19.4,236.4z"/>
		<path class="st1" d="M19.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M17.4,236.4c-0.8-0.2-0.8,3,0,2.8C18.2,239.4,18.2,236.2,17.4,236.4z"/>
		<path class="st1" d="M17.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M15.4,236.4c-0.8-0.2-0.8,3,0,2.8C16.2,239.4,16.2,236.2,15.4,236.4z"/>
		<path class="st1" d="M15.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M13.4,236.4c-0.8-0.2-0.8,3,0,2.8C14.3,239.4,14.3,236.2,13.4,236.4z"/>
		<path class="st1" d="M13.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M11.4,236.4c-0.8-0.2-0.8,3,0,2.8C12.3,239.4,12.3,236.2,11.4,236.4z"/>
		<path class="st1" d="M11.1,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M9.5,236.4c-0.8-0.2-0.8,3,0,2.8C10.3,239.4,10.3,236.2,9.5,236.4z"/>
		<path class="st1" d="M9.2,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M7.5,236.4c-0.8-0.2-0.8,3,0,2.8C8.3,239.4,8.3,236.2,7.5,236.4z"/>
		<path class="st1" d="M7.2,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M5.5,236.4c-0.8-0.2-0.8,3,0,2.8C6.3,239.4,6.3,236.2,5.5,236.4z"/>
		<path class="st1" d="M5.2,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M3.5,236.4c-0.8-0.2-0.8,3,0,2.8C4.3,239.4,4.3,236.2,3.5,236.4z"/>
		<path class="st1" d="M3.2,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M86.8,236.4c-0.8-0.2-0.8,3,0,2.8C87.6,239.4,87.6,236.2,86.8,236.4z"/>
		<path class="st1" d="M86.5,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M84.8,236.4c-0.8-0.2-0.8,3,0,2.8C85.6,239.4,85.6,236.2,84.8,236.4z"/>
		<path class="st1" d="M84.5,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M82.8,236.4c-0.8-0.2-0.8,3,0,2.8C83.6,239.4,83.6,236.2,82.8,236.4z"/>
		<path class="st1" d="M82.5,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M80.8,236.4c-0.8-0.2-0.8,3,0,2.8C81.7,239.4,81.7,236.2,80.8,236.4z"/>
		<path class="st1" d="M80.5,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M78.8,236.4c-0.8-0.2-0.8,3,0,2.8C79.7,239.4,79.7,236.2,78.8,236.4z"/>
		<path class="st1" d="M78.5,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M76.9,236.4c-0.8-0.2-0.8,3,0,2.8C77.7,239.4,77.7,236.2,76.9,236.4z"/>
		<path class="st1" d="M76.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M74.9,236.4c-0.8-0.2-0.8,3,0,2.8C75.7,239.4,75.7,236.2,74.9,236.4z"/>
		<path class="st1" d="M74.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M72.9,236.4c-0.8-0.2-0.8,3,0,2.8C73.7,239.4,73.7,236.2,72.9,236.4z"/>
		<path class="st1" d="M72.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M70.9,236.4c-0.8-0.2-0.8,3,0,2.8C71.7,239.4,71.7,236.2,70.9,236.4z"/>
		<path class="st1" d="M70.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M68.9,236.4c-0.8-0.2-0.8,3,0,2.8C69.7,239.4,69.7,236.2,68.9,236.4z"/>
		<path class="st1" d="M68.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
	<g>
		<path class="st0" d="M66.9,236.4c-0.8-0.2-0.8,3,0,2.8C67.7,239.4,67.7,236.2,66.9,236.4z"/>
		<path class="st1" d="M66.6,237.3c-0.9-0.3-0.8-1.7,0.2-1.8c1-0.2,1.4,1.3,0.6,1.7"/>
	</g>
</g>
<text class="st48" x="2.5705597" y="5.6745005" style={{fontSize:"2.2px"}}>서울 44석 / 586석</text>
<text class="st48" x="2.5705597" y="31.3989792" style={{fontSize:"2.2px"}}>경기 21석 / 594석</text>
<text class="st48" x="2.5705597" y="56.782402" style={{fontSize:"2.2px"}}>전북 5석 / 295석</text>
<text class="st48" x="2.5705597" y="75.5752487" style={{fontSize:"2.2px"}}>경북 4석 / 466석</text>
<text class="st48" x="2.5705597" y="98.0353088" style={{fontSize:"2.2px"}}>대구 4석 / 97석</text>
<text class="st48" x="2.5705597" y="112.0728607" style={{fontSize:"2.2px"}}>광주 3석 / 54석</text>
<text class="st48" x="2.5705597" y="124.6872406" style={{fontSize:"2.2px"}}>경남 2석 / 449석</text>
<text class="st48" x="2.5705597" y="143.3738098" style={{fontSize:"2.2px"}}>충남 2석 / 299석</text>
<text class="st48" x="2.5705597" y="163.0572815" style={{fontSize:"2.2px"}}>부산 2석 / 205석</text>
<text class="st48" x="2.5705597" y="180.8467194" style={{fontSize:"2.2px"}}>전남 1석 / 410석</text>
<text class="st48" x="2.5705597" y="199.912735" style={{fontSize:"2.2px"}}>강원 1석 / 229석</text>
<text class="st48" x="2.5705597" y="218.5194397" style={{fontSize:"2.2px"}}>충북 없음 / 183석</text>
<text class="st48" x="2.5705597" y="233.9203949" style={{fontSize:"2.2px"}}>인천 없음 / 89석</text>
<text class="st48" x="2.5705597" y="246.5347595" style={{fontSize:"2.2px"}}>제주 없음 / 53석</text>
<text class="st48" x="2.5705597" y="259.9025879" style={{fontSize:"2.2px"}}>대전 없음 / 43석</text>
<text class="st48" x="2.5705597" y="272.5169678" style={{fontSize:"2.2px"}}>울산 없음 / 29석</text>
<text class="st48" x="2.5705597" y="284.4616089" style={{fontSize:"2.2px"}}>세종 없음 / 2석</text>
</svg>

</div>

        </SvgDiv>
      );
    }
  }
  