import React, { PureComponent } from 'react';
import Chart from "react-google-charts";

export default class Example extends PureComponent {

  render() {
    return (
    <Chart
      width={'100%'}
      height={'500px'}
      chartType="TreeMap"
      loader={<div>Loading Chart</div>}
      data={[
        ['ID', 'Parent', 'count'],
        ['채용 직무', null, 747],
        ['사업 관리', '채용 직무', 332],
        ['홍보', '채용 직무', 79],
        ['디자인', '채용 직무', 69],
        ['기획', '채용 직무', 49],
        ['IT', '채용 직무', 34],
        ['후원', '채용 직무', 23],
        ['연구 개발', '채용 직무', 20],
        ['활동가', '채용 직무', 17],
        ['기타', '채용 직무', 16],
        ['교육-채용', '채용 직무', 16],
        ['크리에이트', '채용 직무', 14],
        ['커뮤니티', '채용 직무', 14],
        ['전문', '채용 직무', 14],
        ['CSR', '채용 직무', 13],
        ['판매 영업', '채용 직무', 11],
        ['고객 관리', '채용 직무', 9],
        ['상담', '채용 직무', 6],
        ['MD', '채용 직무', 5],
        ['물류', '채용 직무', 4],
        ['금융', '채용 직무', 2],  
        [ '사무', '사업 관리', 44 ], 
        [ '경영 지원', '사업 관리', 41 ],
        [ '회계', '사업 관리', 31 ], 
        [ '협력', '사업 관리', 28 ],
        [ '조직 특화', '사업 관리', 28 ], 
        [ '사업 관리 파트', '사업 관리', 28 ],
        [ '운영', '사업 관리', 22 ], 
        [ 'PM', '사업 관리', 21 ],
        [ '경영', '사업 관리', 17 ], 
        [ '인사', '사업 관리', 14 ],
        [ '사회적 경제', '사업 관리', 13 ], 
        [ '사업 개발', '사업 관리', 11 ],
        [ '커뮤니케이션', '사업 관리', 7 ], 
        [ '재무', '사업 관리', 6 ],
        [ '브랜드 사업 관리', '사업 관리', 5 ], 
        [ '일자리 사업', '사업 관리', 4 ],
        [ '회원', '사업 관리', 2 ], 
        [ '정책', '사업 관리', 2 ],
        [ '시설 관리', '사업 관리', 2 ], 
        [ '서비스 관리', '사업 관리', 2 ],
        [ '기관 사업', '사업 관리', 2 ], 
        [ '컨설팅', '사업 관리', 1 ],
        [ '구매', '사업 관리', 1 ], 
        [ '마케팅 파트', '홍보', 27 ], 
        [ '홍보 파트', '홍보', 19 ],
        [ '디지털 마케팅', '홍보', 11 ], 
        [ '콘텐츠 마케팅', '홍보', 4 ],
        [ '퍼포먼스 마케팅', '홍보', 3 ], 
        [ '캠페인 홍보', '홍보', 3 ],
        [ '사업 홍보', '홍보', 3 ], 
        [ '영업 마케팅', '홍보', 2],
        [ 'PR 매니저', '홍보', 1 ], 
        [ 'B2B 캠페인', '홍보', 1 ],
        [ '사무처 캠페인팀', '홍보', 1 ], 
        [ '온라인 퍼포먼스 마케팅', '홍보', 1 ],
        [ '온라인 콘텐츠 마케팅', '홍보', 1 ], 
        [ '소셜미디어 마케팅', '홍보', 1 ],
        [ '브랜드 마케팅', '홍보', 1 ], 
        ['디자인 파트', '디자인', 27],
        ['시각', '디자인', 8],
        ['UX/UI', '디자인', 6],
        ['브랜드', '디자인', 5],
        ['웹-디자인', '디자인', 4],
        ['제품', '디자인', 3],
        ['그래픽', '디자인', 3],
        ['편집', '디자인', 2],
        ['패션', '디자인', 2],
        ['공간', '디자인', 2],
        ['UX/UI 디자이너', '디자인', 1],
        ['GUI', '디자인', 1],
        ['콘텐츠-디자인', '디자인', 1],
        ['인테리어', '디자인', 1],
        ['앱-디자인', '디자인', 1],
        ['멀티미디어', '디자인', 1],
        ['교구 및 보드게임', '디자인', 1],
        ['사업', '기획', 13],
        ['콘텐츠-기획', '기획', 12],
        ['기획-기획', '기획', 7],
        ['서비스', '기획', 4],
        ['웹-기획', '기획', 3],
        ['상품', '기획', 3],
        ['공간-기획', '기획', 3],
        ['광고', '기획', 2],
        ['플랫폼-기획', '기획', 1],
        ['제품-기획', '기획', 1],
        ['프론트', 'IT', 7],
        ['웹', 'IT', 6],
        ['백엔드', 'IT', 4],
        ['개발', 'IT', 4],
        ['테스트', 'IT', 2],
        ['앱', 'IT', 2],
        ['IT 인프라', 'IT', 1],
        ['하드웨어 엔지니어링', 'IT', 1],
        ['플랫폼', 'IT', 1],
        ['풀스택', 'IT', 1],
        ['퍼블리싱', 'IT', 1],
        ['전산코디네이터', 'IT', 1],
        ['자바', 'IT', 1],
        ['유니티', 'IT', 1],
        ['서버 ', 'IT', 1],
        ['모금', '후원', 15],
        ['기금', '후원', 5],
        ['후원-', '후원', 3],
        ['연구', '연구 개발', 17],
        ['데이터', '연구 개발', 3],
        ['활동가 분야', '활동가', 15],
        ['조직&미디어분야 활동가', '활동가', 1],
        ['정보인권 활동가', '활동가', 1],
        ['Young Professional', '기타', 8],
        ['직원', '기타', 4],
        ['인턴', '기타', 3],
        ['Assistant PSP Officer', '기타', 1],
        ['교육-', '교육', 16],
        ['영상', '크리에이트', 5],
        ['교육', '크리에이트', 2],
        ['콘텐츠 분야', '크리에이트', 1],
        ['캠페인', '크리에이트', 1],
        ['제품-크리에이트', '크리에이트', 1],
        ['아카이브', '크리에이트', 1],
        ['미디어', '크리에이트', 1],
        ['로컬', '크리에이트', 1],
        ['놀이', '크리에이트', 1],
        ['커뮤니티 분야', '커뮤니티', 14],
        ['기자', '전문', 4],
        ['사회복지사', '전문', 2],
        ['편집장', '전문', 1],
        ['지휘자', '전문', 1],
        ['아키비스트', '전문', 1],
        ['변호사', '전문', 1],
        ['법무', '전문', 1],
        ['번역가', '전문', 1],
        ['공인노무사', '전문', 1],
        ['간호사', '전문', 1],
        ['사회공헌', 'CSR', 10],
        ['사회공헌 컨설팅', 'CSR', 3],
        ['판매', '판매 영업', 8],
        ['영업', '판매 영업', 3],
        ['CS', '고객 관리', 5],
        ['CRM', '고객 관리', 3],
        ['고객 관리 분야', '고객 관리', 1],
        ['피해 상담', '상담', 2],
        ['NPO 상담', '상담', 1],
        ['전화 상담', '상담', 1],
        ['육아 상담', '상담', 1],
        ['대관 상담', '상담', 1],
        ['MD 분야', 'MD', 2],
        ['패션 MD', 'MD', 1],
        ['상품기획MD', 'MD', 1],
        ['로컬 큐레이터(MD)', 'MD', 1],
        ['배송', '물류', 2],
        ['물류 분야', '물류', 2],
        ['금융 분야', '금융', 2],
    ]}
      options={{
        highlightOnMouseOver: true,
        maxDepth: 1,
        maxPostDepth: 2,
        minHighlightColor: '#FBF3E5',
        midHighlightColor: '#F39E65',
        maxHighlightColor: '#f15a22',
        minColor: '#FEF1E6',
        midColor: '#F8C097',
        maxColor: '#f15a22',
        headerHeight: 15,
        showScale: false,
        height: 500,
        zIndex: 1000,
        useWeightedAverageForAggregation: true,
        showTooltips: false,
        generateTooltip: (row, size, value) => {
          return (
            '<div style="font-size:0.8em; background:#fff; padding:10px;"> 공고:' +
            size +
            '건 </div>'    
           )
        },
      }}
      rootProps={{ 'data-testid': '2' }}
    />
    )
  }
}
