import React, { useState, useEffect } from "react"
import { geoMercator, geoPath } from "d3-geo"
import { feature } from "topojson-client"

const projection = geoMercator()
  .center([126.9895, 37.5651])
  .scale(100000)
  .translate([ 800 / 2, 600 / 2 ])

function SeoulMap() {
  const [geographies, setGeographies] = useState([])
  const openCount = [26,24,12,52,3,0,1,6,1,2,0,25,12,38,2,5,4,4,15,3,2,14,40,7,3]
  const seoulParts = [

      { name:"종로구", loc:[370.7329971512747,265.31159368615735], loc2:[376.7329971512747,280.31159368615735] },
      { name:"중구", loc:[411.2894356702565,310.9130846673456], loc2:[412.6894356702565,325.9130846673456] },
      { name:"용산구", loc:[370.25875286968517,374.21206297649036], loc2:[376.25875286968517,389.21206297649036] },
      { name:"성동구", loc:[480.9869384085873,335.97275310715526], loc2:[486.9869384085873,350.97275310715526] },
      { name:"광진구", loc:[555.9807236809514,350.4857996899777], loc2:[565.9807236809514,365.4857996899777] },
      { name:"동대문구", loc:[500.0542041841696,262.873683914273], loc2:[515.0542041841696,277.873683914273] },
      { name:"중랑구", loc:[570.4346274097197,227.96642350087944], loc2:[580.4346274097197,242.96642350087944] },
      { name:"성북구", loc:[440.0049958576666,230.57060173212116], loc2:[450.0049958576666,245.57060173212116] },
      { name:"강북구", loc:[425.84352736383715,140.31833254053694], loc2:[435.84352736383715,155.31833254053694] },
      { name:"도봉구", loc:[465.8155620111536,80.82225813129642], loc2:[475.8155620111536,95.82225813129642] },
      { name:"노원구", loc:[533.2835724715098,117.38716030278835], loc2:[543.2835724715098,132.38716030278835] },
      { name:"은평구", loc:[280.9709620637267,190.7846702167013], loc2:[286.9709620637267,205.7846702167013] },
      { name:"서대문구", loc:[290.97139504213374,280.05725092527393], loc2:[302.97139504213374,295.05725092527393] },
      { name:"마포구", loc:[258.2108320834124,325.7370175795614], loc2:[264.2108320834124,340.7370175795614] },
      { name:"양천구", loc:[150.09076545576156,399.72822909850987], loc2:[160.09076545576156,414.72822909850987] },
      { name:"강서구", loc:[109.0573515725328,308.49680130710226], loc2:[119.0573515725328,323.49680130710226] },
      { name:"구로구", loc:[140.5266502890611,455.5849963823363], loc2:[150.5266502890611,470.5849963823363] },
      { name:"금천구", loc:[232.21790963148916,529.9935498259586], loc2:[242.21790963148916,544.9935498259586] },
      { name:"영등포구", loc:[248.5419052964728,394.1816043490917], loc2:[261.5419052964728,409.1816043490917] },
      { name:"동작구", loc:[333.9205874216774,435.74659270569913], loc2:[343.9205874216774,450.74659270569913] },
      { name:"관악구", loc:[305.91696811290177,515.024209685828], loc2:[315.91696811290177,530.024209685828] },
      { name:"서초구", loc:[430.7844543939273,480.989460516541], loc2:[436.7844543939273,496.989460516541] },
      { name:"강남구", loc:[500.2285902594828,450.63996013433166], loc2:[506.2285902594828,465.63996013433166] },
      { name:"송파구", loc:[600.5431945597221,430.90113048508647], loc2:[610.5431945597221,445.90113048508647] },
      { name:"강동구", loc:[664.917211998503,332.2427884342045], loc2:[674.917211998503,347.2427884342045] },

  ]

  useEffect(() => {
    fetch("https://raw.githubusercontent.com/hwoo-slowalk/seoul_SIG_map/master/SIG_shape_topo.json")
      .then(response => {
        if (response.status !== 200) {
          console.log(`There was a problem: ${response.status}`)
          return
        }
        response.json().then(seoul => {
          setGeographies(feature(seoul, seoul.objects.SIG_shape_geo).features)
        })
      })
  }, [])

  return (
        // <svg className="part-Geo" width={ '100%' } height={ 450 } viewBox="0 0 600 450">
        <svg className="part-Geo" width={ '100%' } style={{maxHeight:'600', marginBottom:'50', fontSize:"10"}} viewBox="0 0 800 600">
          <g className="countries">
            { 
              geographies.map((d,i) => (
                <path
                  key={ `path-${ i }` }
                  d={ geoPath().projection(projection)(d) }
                  className={i}
                  fill={ `rgba(241, 90, 34,${ (openCount[i] + 3) / 51 })` }
                  stroke="#FFFFFF"
                  strokeWidth={ 0.5 }
                />
              ))
            },{ 
              geographies.map((d,i) => (
              <text transform={"translate(" + seoulParts[i].loc + ")"} fontSize=".6em" fontWeight="300">{seoulParts[i].name}</text>
              ))
            },{
              geographies.map((d,i) => (
                <text transform={"translate(" + seoulParts[i].loc2 + ")"} fontSize=".8em" fontWeight="600">{openCount[i]}</text>
                ))
            }
          </g>
        </svg>
  );
}

export default SeoulMap;