import React from 'react';

function Rectbar(props) {
    const widthv = 200
    const heightv = 10
    const wratio = 200*(props.ratio/100)
    const mratio = 200-wratio

    return (
        <svg width={widthv} height={heightv}>
            <rect width={wratio} height={heightv} style={{fill:"rgba(97,70,140,1)"}}/>
            <rect x={wratio} width={mratio} height={heightv} style={{fill:"#dcdddd"}}/>
        </svg>
    );
}

export default Rectbar